<app-breadcrumbs-navbar></app-breadcrumbs-navbar>
<div class="videos-dash-container">
    <div class="videos-dash-content">
        <div class="videos-dash-header">
            <h2>Findings</h2>
        </div>
        <div class="videos-dash-menu">
            <div class="videos-dash-menu-text">Analyze findings.</div>
            <div>
                <app-all-filters></app-all-filters>
            </div>
        </div>

        <!-- Table -->
        <div class="videos-dash-table-container">
            <table class="videos-dash-table">     
                <tr class="title-row">
                    <th class="quote-title" >Quote</th>
                    <th class="clip-title">Clip</th>
                    <th class="test-name-title">Tester name</th>
                    <th class="sentiment-title">Sentiment</th>
                    <th class="note-title">Note</th>
                    <!-- <th class="date-title">Date</th> -->
                    <th class="hashtag-title">Hashtags</th>
                    <th class="action-title">Action</th>
                </tr>
                <!-- <td class="quote-data"  (click)="fun1(findingsItem.transcript_text, isDataAvailable)"> -->
             
                 <ng-container *ngIf="isDataAvailable">
                    <tr class="data-row" *ngFor="let item of filteredJsonData; index as i">
                        <td class="quote-data">
                            <p  (click)="viewSelectedVideo(item.tested_test_id)">
                                {{ displayQuote(item.transcript) }}
                            </p>
                        </td>
                        <td class="clip-data">
                            <ng-container *ngIf="item.clips.length > 0">
                                <img src="assets/images/icons/purple-clip.svg" >
                            </ng-container>
                        </td>
                        <td class="test-name-data">
                            <a>
                                <ng-container *ngIf="item.tester_name">
                                    {{displayTestName(item.tester_name)}}
                                </ng-container>
                            </a>
                        </td>
                        <td class="sentiment-data">
                            <ng-container *ngIf="item.sentiments.length > 0">
                                <ng-container *ngIf="item.sentiments === 'positive' ">
                                    <img src="assets/images/smile.png"/>
                                </ng-container>
                                <ng-container *ngIf="item.sentiments === 'negative' ">
                                    <img src="assets/images/sad-face.png"/>
                                </ng-container>
                                <ng-container *ngIf="item.sentiments === 'neutral' ">
                                    <img src="assets/images/neutral-face.png"/>
                                </ng-container>
                            </ng-container>
                        </td>
                        <td class="note-data">
                            <ng-container *ngIf="item.notes.length > 0">
                                <img src="assets/images/icons/notes.svg"/>
                            </ng-container>
                            <!-- <ng-container *ngIf="item.notes.length > 0">
                                <p>{{item.notes}}</p>
                            </ng-container> -->
                        </td>
                       
                        <!-- <td class="date-data">
                            <ng-container *ngIf="item.created_at">
                                {{displayDate(item.created_at)}}
                            </ng-container>
                        </td> -->
                        <td class="hashtag-data">
                            <div class="hashtag-data-cont">
                                <!-- hashtag -->
                        
                                <ng-container *ngFor="let hashtagItem of hashtagLimit(item.hashtags)">
                                    <span (click)="sendHashtagToAllFilterComponent(hashtagItem, 'hashtag')">{{hashtagItem}}</span>
                                </ng-container>
                                <!-- hashtag btn -->
                                <span (click)="showHashtagPopup($event)" class="hashtag-count-btn" *ngIf="hashtagRemainingCount(item.hashtags)">
                                    +{{ hashtagRemainingCount(item.hashtags) }}
                                </span>
                                <!-- hashtag popup -->
                                <div class="hashtagPopupContainer" #hashtagPopupContainer>
                                    <div class="hashtagPopupContent">
                                        <h2>Hashtags linked to video</h2>
                                        <img (click)="closeHashtagPopup($event)" src="assets/images/icons/close-icon.svg" alt="icon">
                                        <p>Click on a hashtag to filter videos:</p>
                                        <div class="">
                                            <span *ngFor="let hashtagItem of item.hashtags"
                                                (click)="sendHashtagToAllFilterComponent(hashtagItem, 'hashtag'); hashtagPopupContainer.style.display = 'none'">{{hashtagItem}}</span>
                                        </div>
                                    </div>
                                </div>
                        
                        
                            </div>
                        </td>
                        <td class="action-data">
                            <img class="dash-context-menu-icon" src="assets/images/icons/menu-icon-new.svg" alt="icon">
                        </td>
                    </tr>
                 </ng-container>
            </table>
            <div *ngIf="isDataAvailable" class="pagination-cont">
                <p class="pagination-txt">{{paginateCount}} / {{queriedJsonData.length}} videos</p>
                <p class="pagination-btn" (click)="incrementPaginationCountFun(paginateCount + 10)">Load more</p>
            </div>
        </div>
        <div class="empty-data-cont" *ngIf="!isDataAvailable">
            <div class="empty-data-icon" style="margin-bottom: 25px;">
                <img src="assets/images/icons/cube-new-icon.svg" alt="cube icon">
            </div>
            <div class="empty-data-text" style="margin-bottom: 35px;">
                <p>You don't have any findings yet. </p>
                <!-- <p>Let's create a videos to get started.</p> -->
            </div>
        </div>
    </div>
</div>
<script src='assets/js/custom.js'></script>





<!-- app.component.html -->
<!-- <table>
    <thead>
      <tr>
        <th>Notes</th>
        <th>Hashtags</th>
        <th>Sentiments</th>
        <th>Clips</th>
        <th>Transcript</th>
        <th>Tester Name</th>
        <th>Tested Test ID</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of jsonData">
        <td>{{ item.notes || '-' }}</td>
        <td>
          <ul>
            <li *ngFor="let hashtag of item.hashtags">{{ hashtag }}</li>
          </ul>
        </td>
        <td>{{ item.sentiments || '-' }}</td>
        <td>
          <a *ngIf="item.clips" [href]="item.clips" target="_blank">View Clip</a>
          <span *ngIf="!item.clips">-</span>
        </td>
        <td>{{ item.transcript || '-' }}</td>
        <td>{{ item.tester_name || '-' }}</td>
        <td>{{ item.tested_test_id || '-' }}</td>
      </tr>
    </tbody>
  </table> -->
  