<div class='tests-container lg-screen-width videoinfo-wrap'>
  <div class='section videoinfo-section'>
    <div [ngClass]="arrow ? 'hide' : ''" class='video-player'>
      <app-video-player (triggredEventInfo)='triggredEventInfo($event)' *ngIf='selectedTestDetails'
                        [selectedTestDetails]='selectedTestDetails'
                        [selectedTesterProfile]='selectedTesterProfile'></app-video-player>
    </div>
    <div [ngClass]="arrow ? 'hide' : ''" class='video-info-sub'>
      <div id='tabs'>
        <div class='col-xs-12'>
          <nav class='video-info-head'>
            <div class='nav nav-tabs nav-fill' id='nav-tab' role='tablist'>
              <a (click)='iconShow()' aria-controls='nav-home' aria-selected='true' class='nav-item nav-link active'
                 data-toggle='tab' id='nav-home-tab' role='tab' style='cursor: pointer'><img
                alt='info' src='assets/images/icons/info.png'/></a>

              <a (click)='iconShow1()' aria-controls='nav-profile' aria-selected='false' class='nav-item nav-link'
                 data-toggle='tab' id='nav-profile-tab' role='tab' style='cursor: pointer'><img
                alt='info' src='assets/images/icons/task-icon.png'/></a>
                
                <a (click)='iconShow2()' aria-controls='nav-contact' aria-selected='false' class='nav-item nav-link'
                  data-toggle='tab' id='nav-contact-tab' role='tab' style='cursor: pointer'><img
                  alt='info' src='assets/images/icons/transcript-icon.png'/></a>
              
                <a (click)='iconShow3()' aria-controls='nav-contact' aria-selected='false' class='nav-item nav-link'
                 data-toggle='tab' id='nav-contact-tab' role='tab' style='cursor: pointer'><img
                alt='info' src='assets/images/icons/speech-to-text.png'/></a>

              <span (click)='arrow = !arrow' class='left-arrow' id='video-collapse'><img
                alt='angle' src='assets/images/icons/vedio-leftangle.png'/></span>
            </div>
          </nav>

          <div class='tab-content py-3 px-3 px-sm-0' id='nav-tabContent'>

            <!-- info:: video-info -->
            <div [ngClass]="icon ? 'active show' : ''" aria-labelledby='nav-home-tab' class='tab-pane fade' id='nav-home'
                 role='tabpanel'>
              <div *ngIf='selectedTesterProfile' class='info'>
                <h5>{{ selectedTestDetails.test_name }}</h5>
                <a class='info-link'>{{ selectedTestDetails.start_url }}</a>
                <div class='info-sub'>
                  <ng-template #noProfileImage>
                    <img class='tester' src='assets/images/icons/user-icon.png'/>
                  </ng-template>

                  <div *ngIf='selectedTesterProfile.images ; else noProfileImage'>
                    <img class='tester' src='{{ baseUrl }}media/{{ selectedTesterProfile.images }}'/>
                  </div>
                  <div class='info-content'>
                    <p *ngIf='selectedTesterProfile'>
                      <span *ngIf='selectedTesterProfile.full_name' class='name'>{{ selectedTesterProfile.full_name}}</span> 
                      (<span>{{ selectedTesterProfile.age }} y &#44;</span> {{ selectedTesterProfile.country}}) 777
                    </p>
                    <p (click)='rateShow()' class='rating' data-target='#tester-rating' data-toggle='modal'
                       style='cursor: pointer'>
                      <a *ngFor='let item of ratingRate; let i = index'>
                        <i class='fa fa-star'></i>
                      </a>
                      <a *ngFor='let item of remaingRating; let i = index'>
                        <i class='fa fa-star-o'></i>
                      </a>
                    </p>
                    <a (click)='rateShow()' class='tester-rating' data-target='#tester-rating' data-toggle='modal'
                       href="" style='cursor: pointer'>
                      <a>Please rate this tester</a></a>
                  </div>
                </div>

                <div class='info-sub name'>
                  <img alt='tester' class='screen' src='assets/images/icons/videoinfo-screen.png'/>
                  <div class='info-content'>
                    <p>
                      <span *ngIf='selectedTestDetails' class='name'>{{ selectedTestDetails.is_app ? 'MOBILE' :
                        'DESKTOP' }}</span> (Chrome OS&#44;
                      1024x780)
                    </p>
                  </div>
                </div>

                <div class='info-sub name'>
                  <img alt='tester' class='calender' src='assets/images/icons/calender.png'/>
                  <div class='info-content'>
                    <p *ngIf='selectedTestDetails'>
                      <span class='name'>{{
                        selectedTestDetails.date_of_tested | date: 'mediumDate'
                        }}</span>
                      @{{ selectedTestDetails.date_of_tested | date: 'shortTime' }}
                    </p>
                  </div>
                </div>
                <div *ngIf='!tabDescription'>
                  <form (ngSubmit)='addQuickNotes()' [formGroup]='addQuickNoteForm'>
                    <textarea class='info-addnotes' formControlName='note' placeholder='Add notes or hashtags to a specific timestamp'>
                    </textarea>
                    <div *ngIf="
                      (submitted &&
                      addQuickNoteForm.controls.note.errors?.required) ||
                      addQuickNoteForm.get('note').touched
                    ">
                      <div *ngIf='addQuickNoteForm.controls.note.errors?.required'>
                        <small class='text-danger'>
                          Note are required.</small>
                      </div>
                    </div>
                    <button class='add-note' type='submit'>{{quickNoteSubmitBtnTxt}}</button>
                  </form>
                </div>

                <div *ngIf='tabDescription' class='tab-pane-sub notes-box2' style='margin-top: 50px'>
                  <div class='edit-row'>
                    <div class='edit'>
                      <div (click)='editDescription()' class='icon'>
                        <img alt='edit' class='edit' src='assets/images/icons/video-edit.png'/>
                      </div>
                    </div>

                    <div (click)='showDesc()' class='right-dropdown'>
                      <div class='right-icon'>
                        <span class='dots'></span>
                        <span class='dots'></span>
                        <span class='dots'></span>
                      </div>

                      <div *ngIf='showDescrption' [style.display]='descProp' class='dropdowns'>
                        <ul>
                          <li>
                            <a (click)='editDescription()'><img alt='edit-icons'
                                                                src='assets/images/icons/edit.png'/>Edit</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p class='text'>
                    {{ description }}
                  </p>
                </div>
                
              </div>
            </div>

            <!-- info:: Test Task detials -->
            <div [ngClass]="icon1 ? 'active show' : ''" aria-labelledby='nav-profile-tab' class='tab-pane fade' id='nav-profile'
                 role='tabpanel'>
              <div class='tab-pane-sub'>
                <app-taskdetails-tab></app-taskdetails-tab>
              </div>
            </div>

            <!-- info:: notes and clips -->
            <div [ngClass]="icon2 ? 'active show' : ''" aria-labelledby='nav-contact-tab' class='tab-pane fade' id='nav-contact' role='tabpanel'>
              <div class='tab-pane-sub'>
                <app-notes-and-clips></app-notes-and-clips>
              </div>
            </div>

            <!-- info:: Speech to text added here -->
            <div [ngClass]="icon3 ? 'active show' : ''" class="speech-to-text-container" class='tab-pane fade' role='tabpanel'>
              <div class='tab-pane-sub'>
                <app-transcript></app-transcript>
              </div>
            </div>


          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- ./Tabs -->
  <div [ngClass]="rating ? 'show' : ''" [style.display]='properties' class='modal' id='tester-rating'>
    <div class='modal-dialog'>
      <div class='modal-content'>
        <!-- Modal Header -->
        <div class='modal-header'>
          <h4 class='modal-title'>{{ ratingMsg }}</h4>
          <button (click)='closeRate()' class='close' data-dismiss='modal' type='button'>
            &#10006;
          </button>
        </div>

        <!-- Modal body -->
        <div class='modal-body'>
          <div *ngIf='selectedTesterProfile' class='tester-detail'>
            <ng-template #imageNotFound>
              <img class='tester' src='assets/images/icons/user-icon.png'
                   style='width: 50px; height: 50px; border-radius: 50px'/>
            </ng-template>
            <div>

            </div>
            <div *ngIf='selectedTesterProfile.images ;else imageNotFound'>
              <img class='tester'
                   src='{{ baseUrl }}media/{{ selectedTesterProfile.images }}' style='width: 50px; height: 50px; border-radius: 50px'/>
            </div>

            <div *ngIf='selectedTesterProfile' class='tester-detail-sub'>
              <span *ngIf='selectedTesterProfile.nick_name' class='name'>{{ selectedTesterProfile.nick_name }}</span>
              (<span>{{ selectedTesterProfile.age }} y. &#44;</span><span> {{ selectedTesterProfile.country }})</span>
            </div>
          </div>
          <form (ngSubmit)='addRating1()' [formGroup]='starRating'>
            <div class='tester-rating'>
              <a (click)='setStar(i)' *ngFor='let item of starList; let i = index'>
                <i [ngClass]="{ 'fa-star-o': item, 'fa-star': !item }" class='fa'></i>
              </a>
            </div>
            <div class='tester-comment'>
              <textarea formControlName='feed_back' placeholder='Tell us your feedback.'></textarea>
            </div>
            <button #closeAddExpenseModal class='sendfeedback' type='submit'>
              Send feedback
            </button>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>
