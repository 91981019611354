<!--<mat-drawer-container class="example-container" autosize>-->
  <!-- toggle btn -->
  <div class="example-sidenav-content" id="vid">
<!--    <button (click)="drawer.toggle()" *ngIf="!drawer.opened;" class="togglebtn1 bg-light" style="border: 0;">-->
<!--      <span class="material-icons mt-1">keyboard_arrow_left</span>-->
<!--      &lt;!&ndash; <mat-icon class="material-icons">keyboard_arrow_right</mat-icon> &ndash;&gt;-->
<!--    </button>-->

    <div class="video-sub">
      <video *ngIf='selectedTestDetails' class='video-js vjs-default-skin' id='demo' preload='none'
        style='margin: auto'>
        <!--    <source src='{{baseUrl}}media/{{selectedTestDetails.file}}' type='video/mp4'/>-->
        <!--    <source src='{{baseUrl}}media/{{selectedTestDetails.file}}' type='video/webm'/>-->

        <source src="{{baseUrl}}{{selectedTestDetails.file}}" type="video/mp3" />
        <source src="{{baseUrl}}{{selectedTestDetails.file}}" type="video/webm" />

        <p class="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
        </p>
      </video>
    </div>
  </div>
<!--</mat-drawer-container>-->
