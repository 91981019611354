<article [ngClass]='{ watchVideo: videoItemDetails.watch === true }' class='filter-listing gray new-videos'>
  <div (click)='
        getTestedVideoId(
          videoItemDetails.test,
          videoItemDetails.project_name,
          videoItemDetails.id
        )
      ' [routerLink]='setVideoItemRouterLink' class='row listing-detail align-items-center'>
    <div class='col-md'>
      <div class='left-row'>
        <div *ngIf='videoItemDetails.thumbnail_image' class='videoimg__thumb'>
          <img alt='Video_thumb' class='image' height='60' src='{{ baseURL }}{{ videoItemDetails.thumbnail_image }}'
            width='92' />
          <span>{{ testerProfileList ? videoItemDetails.viedo_total_time : videoItemDetails.duration }}</span>
        </div>

        <div class='head'>
          <div class="videoItemInfoContainer">
            <h3 style="display: inline-block; margin-right: 10px;">{{ videoItemDetails.test_name }}</h3><p style="display: inline-block;" *ngIf='videoItemDetails.project_name'>Project: <span class="txt-color-blue">{{ videoItemDetails.project_name }}</span></p>
          </div>

          <p class="hashtag-container" *ngIf='videoItemDetails.hastags' style="margin-top: 4px;">
            <span *ngFor='let hashtag of hashtagsList' class="txt-color-blue" style="cursor: pointer;">
              {{ hashtag }}
            </span>
          </p>
          <!-- <p *ngIf='!testerProfileList'>
            Description:
            {{ videoItemDetails.description }}
          </p> -->
          <!-- <p *ngIf='videoItemDetails.project_name'>
            <span *ngIf='videoItemDetails.watch == false' class='new'>New!</span>
            <span class='notes'>
              Notes: {{ videoItemDetails.total_notes }}</span>
            Project:
            {{ videoItemDetails.project_name }}
          </p> -->
        </div>
      </div>
    </div>

    <div class='col-md-auto'>
      <div class='right-row'>
        <div class='name'>
          <span *ngFor='let tester of testerProfileList'>
            <p *ngIf='
                  tester.user == videoItemDetails.user && tester.nick_name
                '>
              {{ tester.nick_name }}.
            </p>
          </span>
          <span *ngIf='!testerProfileList'>
            <p>Admin</p>
          </span>
        </div>
        <div *ngIf='videoItemDetails.is_app == false' class='screen'>
          <img alt='desktop' src='assets/images/icons/desktop.png' /><span>Desktop</span>
        </div>
        <div *ngIf='videoItemDetails.is_app == true' class='screen'>
          <img alt='desktop' src='assets/images/icons/mobile-icon.png' /><span>Mobile</span>
        </div>
        <div class='date'>{{testerProfileList ? (videoItemDetails.date_of_tested | date) : videoItemDetails.created_date
          | date }}</div>
      </div>
    </div>
  </div>
</article>