<div class="all-filters-wrapper">
    <div class="all-filters-menu-cont">
        <div class="clips-menu">
            <!-- Clips filter menu -->
            <ng-container *ngIf="currentRouteVariable === '/results/clips' "> 
                <div class="default-filter-container"  (click)="showDefaultFilterContent($event)" (click)="initializeClipIdOptionsList()">
                    Clip Id <img class="default-filter-arrow-icon" src="assets/images/icons/down-arrow.svg" alt="arrow down icon">
                    <div class="default-filter-content">
                        <ng-container *ngTemplateOutlet="clipIdFilterTemplate">
                        </ng-container>
                    </div>
                </div>
                <div class="default-filter-container" (click)="showDefaultFilterContent($event)" (click)="initializeTesterSearchList()">
                    Tester <img class="default-filter-arrow-icon" src="assets/images/icons/down-arrow.svg" alt="arrow down icon">
                    <div class="default-filter-content">
                        <ng-container *ngTemplateOutlet="testerFilterTemplate">
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="currentRouteVariable === '/results/videos' "> 
                <div class="default-filter-container"  (click)="showDefaultFilterContent($event)">
                    date <img class="default-filter-arrow-icon" src="assets/images/icons/down-arrow.svg" alt="arrow down icon">
                    <div class="default-filter-content video-default-date-filter">
                        <ng-container *ngTemplateOutlet="dateFilterTemplate">
                        </ng-container>
                    </div>
                </div>
                <div class="default-filter-container"  (click)="showDefaultFilterContent($event)" (click)="initializeHashtagSearchList()">
                    Hashtag <img class="default-filter-arrow-icon" src="assets/images/icons/down-arrow.svg" alt="arrow down icon">
                    <div class="default-filter-content">
                        <ng-container *ngTemplateOutlet="hashtagFilterTemplate">
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="currentRouteVariable === '/results/findings' "> 
                <!-- <div class="default-filter-container"  (click)="showDefaultFilterContent($event)">
                    date <img class="default-filter-arrow-icon" src="assets/images/icons/down-arrow.svg" alt="arrow down icon">
                    <div class="default-filter-content video-default-date-filter">
                        <ng-container *ngTemplateOutlet="dateFilterTemplate">
                        </ng-container>
                    </div>
                </div> -->
                <div class="default-filter-container"  (click)="showDefaultFilterContent($event)" (click)="initializeHashtagSearchList()">
                    Hashtag <img class="default-filter-arrow-icon" src="assets/images/icons/down-arrow.svg" alt="arrow down icon">
                    <div class="default-filter-content">
                        <ng-container *ngTemplateOutlet="hashtagFilterTemplate">
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="currentRouteVariable === '/results/highlight-reels' "> 
                <div class="default-filter-container"  (click)="showDefaultFilterContent($event)" (click)="initializeReelNameSearchList()">
                    Reel name <img class="default-filter-arrow-icon" src="assets/images/icons/down-arrow.svg" alt="arrow down icon">
                    <div class="default-filter-content">
                        <ng-container *ngTemplateOutlet="reelNameFilterTemplate">
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="currentRouteVariable === '/research/tests' "> 
                <div class="default-filter-container"  (click)="showDefaultFilterContent($event)" (click)="initializeTestNameSearchList()">
                    Test name <img class="default-filter-arrow-icon" src="assets/images/icons/down-arrow.svg" alt="arrow down icon">
                    <div class="default-filter-content">
                        <ng-container *ngTemplateOutlet="testNameFilterTemplate">
                        </ng-container>
                    </div>
                </div>
                <div class="default-filter-container"  (click)="showDefaultFilterContent($event)">
                    Status <img class="default-filter-arrow-icon" src="assets/images/icons/down-arrow.svg" alt="arrow down icon">
                    <div class="default-filter-content">
                        <ng-container *ngTemplateOutlet="statusFilterTemplate">
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="currentRouteVariable == '/projects' "> 
                <div class="default-filter-container"  (click)="showDefaultFilterContent($event)" (click)="initializeProjectNameSearchList()">
                    Project name <img class="default-filter-arrow-icon" src="assets/images/icons/down-arrow.svg" alt="arrow down icon">
                    <div class="default-filter-content">
                        <ng-container *ngTemplateOutlet="projectNameFilterTemplate">
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="currentRouteVariable == '/reports/test-reports' "> 
                <div class="default-filter-container"  (click)="showDefaultFilterContent($event)" (click)="initializeTestNameSearchList()">
                    Report name <img class="default-filter-arrow-icon" src="assets/images/icons/down-arrow.svg" alt="arrow down icon">
                    <div class="default-filter-content">
                        <ng-container *ngTemplateOutlet="testNameFilterTemplate">
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>

        <button (click)="showSidebar = !showSidebar" (click)="getSelectedComponentData(); verifyTestStatusCheckboxAreInSync()"  class="sidebar-btn">
            All filters
            <img src="assets/images/icons/all-filters-icon.svg" alt="all filters icon">
        </button>
    </div>

    <div *ngIf="secondaryFilterConditions.length > 0" class="selected-filters-view-cont">
        <span class="selected-filter-chips" *ngFor="let filterItem of secondaryFilterConditions; index as i">
            <img (click)="removeFilterCondition(filterItem, i)" src="assets/images/icons/close-icon.svg" alt="close icon">
            <ng-container *ngIf="filterItem.queryType == 'date'">Date : {{filterItem.value.startDate}} - {{filterItem.value.endDate}}</ng-container>
            <ng-container *ngIf="filterItem.queryType == 'testName'">Test name : {{filterItem.value}}</ng-container>
            <ng-container *ngIf="filterItem.queryType == 'tester'">Tester : {{filterItem.value}}</ng-container>
            <ng-container *ngIf="filterItem.queryType == 'clipId'">Clip Id : {{filterItem.value}}</ng-container>
            <ng-container *ngIf="filterItem.queryType == 'deviceType'">Device Type : {{filterItem.value}}</ng-container>
            <ng-container *ngIf="filterItem.queryType == 'hashtag'">Hashtag : {{filterItem.value}}</ng-container>
            <ng-container *ngIf="filterItem.queryType == 'reelName'">Reel name : {{filterItem.value}}</ng-container>
            <ng-container *ngIf="filterItem.queryType == 'testStatus'">Test status : {{filterItem.value}}</ng-container>
            <ng-container *ngIf="filterItem.queryType == 'projectName'">Project name : {{filterItem.value}}</ng-container>
        </span>
    </div>          
</div>

<!-- @note Sidebar -->
<div *ngIf="showSidebar" class="all-filters-sidebar-wrapper">
    <div class="all-filters-sidebar-cont">
        <div class="all-filters-sidebar-header">
            <h4>Filters</h4>
            <img (click)="showSidebar = !showSidebar" src="assets/images/icons/close-icon.svg" alt="close icon">
        </div>
        <div class="all-filters-sidebar-body">

            <!-- @note Status -->
            <ng-container *ngIf=" currentRouteVariable == '/research/tests' ">
                <article class="device-type-filter-title filter-title" (click)="expandFilterBody($event)">
                    <span class="filter-name" id="device-type">Status</span> 
                    <img class="all-filters-right-arrow-icon" src="assets/images/icons/right-arrow.svg" alt="close icon">
                </article>
                <ng-container *ngTemplateOutlet="statusFilterTemplate">
                </ng-container>
            </ng-container>

            <!-- @note Date -->
            <ng-container *ngIf=" currentRouteVariable != '/projects' && currentRouteVariable != '/reports/test-reports' ">
                <article class="date-filter-title filter-title" (click)="expandFilterBody($event)">
                    <span class="filter-name" id="date">Date</span>
                    <img class="all-filters-right-arrow-icon" src="assets/images/icons/right-arrow.svg" alt="close icon">
                </article>
                <ng-container *ngTemplateOutlet="dateFilterTemplate">
                </ng-container>
            </ng-container>

            <!-- @note Test name-->
            <ng-container *ngIf=" currentRouteVariable != '/projects' && currentRouteVariable != '/results/highlight-reels' && currentRouteVariable != '/reports/test-reports'">
                <article class="test-name-filter-title filter-title" (click)="expandFilterBody($event)"  (click)="initializeTestNameSearchList()">
                    <span id="test-name" class="filter-name">Test name</span> 
                    <img class="all-filters-right-arrow-icon" src="assets/images/icons/right-arrow.svg" alt="close icon">
                </article>
                <ng-container *ngTemplateOutlet="testNameFilterTemplate">
                </ng-container>
            </ng-container>

            <!-- @note Project name-->
            <ng-container *ngIf=" currentRouteVariable == '/projects' ">
                <article class="test-name-filter-title filter-title" (click)="expandFilterBody($event)"  (click)="initializeProjectNameSearchList()">
                    <span id="test-name" class="filter-name">Project name</span> 
                    <img class="all-filters-right-arrow-icon" src="assets/images/icons/right-arrow.svg" alt="close icon">
                </article>
                <ng-container *ngTemplateOutlet="projectNameFilterTemplate">
                </ng-container>
            </ng-container>

            <!-- @note Report name-->
            <ng-container *ngIf=" currentRouteVariable == '/reports/test-reports' ">
                <article class="test-name-filter-title filter-title" (click)="expandFilterBody($event)"  (click)="initializeTestNameSearchList()">
                    <span id="test-name" class="filter-name">Report name</span> 
                    <img class="all-filters-right-arrow-icon" src="assets/images/icons/right-arrow.svg" alt="close icon">
                </article>
                <ng-container *ngTemplateOutlet="testNameFilterTemplate">
                </ng-container>
            </ng-container>

            <!-- @note Reel name-->
            <ng-container *ngIf=" currentRouteVariable == '/results/highlight-reels' ">
                <article class="test-name-filter-title filter-title" (click)="expandFilterBody($event)"  (click)="initializeReelNameSearchList()">
                    <span id="test-name" class="filter-name">Reel name</span> 
                    <img class="all-filters-right-arrow-icon" src="assets/images/icons/right-arrow.svg" alt="close icon">
                </article>
                <ng-container *ngTemplateOutlet="reelNameFilterTemplate">
                </ng-container>
            </ng-container>

            <!-- @note device type-->
            <ng-container *ngIf=" currentRouteVariable != '/projects' && currentRouteVariable != '/results/highlight-reels' && currentRouteVariable != '/results/findings' && currentRouteVariable != '/research/tests' && currentRouteVariable != '/reports/test-reports' ">
                <article class="device-type-filter-title filter-title" (click)="expandFilterBody($event)">
                    <span class="filter-name" id="device-type">Device type</span> 
                    <img class="all-filters-right-arrow-icon" src="assets/images/icons/right-arrow.svg" alt="close icon">
                </article>
                <ng-container *ngTemplateOutlet="deviceTypeFilterTemplate">
                </ng-container>
            </ng-container>

            <!-- @note Tester-->
            <ng-container *ngIf=" currentRouteVariable == '/results/clips' || currentRouteVariable == '/results/videos' || currentRouteVariable == '/results/findings' ">
                <article class="test-name-filter-title filter-title" (click)="expandFilterBody($event)" (click)="initializeTesterSearchList()">
                    <span class="filter-name" id="test-name">Tester</span> 
                    <img class="all-filters-right-arrow-icon" src="assets/images/icons/right-arrow.svg" alt="close icon">
                </article>
                <ng-container *ngTemplateOutlet="testerFilterTemplate">
                </ng-container>
            </ng-container>

            <!-- @note hashtag name-->
            <ng-container *ngIf=" currentRouteVariable == '/results/videos' || currentRouteVariable == '/results/findings' ">
                <article class="test-name-filter-title filter-title" (click)="expandFilterBody($event)" (click)="initializeHashtagSearchList()">
                    <span class="filter-name" id="test-name">Hashtag</span> 
                    <img class="all-filters-right-arrow-icon" src="assets/images/icons/right-arrow.svg" alt="close icon">
                </article>
                <ng-container *ngTemplateOutlet="hashtagFilterTemplate">
                </ng-container>
            </ng-container>

            <!-- @note Clip ID-->
            <ng-container *ngIf=" currentRouteVariable == '/results/clips' ">
                <article class="test-name-filter-title filter-title" (click)="expandFilterBody($event)" (click)="initializeClipIdOptionsList()">
                    <span class="filter-name" id="test-name">Clip Id</span> 
                    <img class="all-filters-right-arrow-icon" src="assets/images/icons/right-arrow.svg" alt="close icon">
                </article>
                <ng-container *ngTemplateOutlet="clipIdFilterTemplate">
                </ng-container>
            </ng-container>

        </div>

        <div class="all-filters-sidebar-footer">
            <button class="all-filters-delete-btn" (click)="handleDeleteBtn()">Delete</button>
            <button class="all-filters-view-btn btn-sty-blue" (click)="showSidebar = !showSidebar">
                View <ng-container *ngIf=" availableDataCount != 'none' ">({{ availableDataCount }})</ng-container>
            </button>
        </div>
    </div>
</div>

<!-- =============================================================================================================== -->

<!-- @note Date -->
<ng-template #dateFilterTemplate>
    <div class="date-filter-body filter-body">
        <label class="from-lable">From</label>
        <input id="start-date" type="date" [(ngModel)]="startDateTemplateVar" (ngModelChange)="handleDateFilterValueChange()">
        <label  class="to-label">To</label>
        <input id="end-date" [(ngModel)]="endDateTemplateVar" (ngModelChange)="handleDateFilterValueChange()" type="date">

        <ng-container  *ngFor="let filterItem of secondaryFilterConditions; index as i">
            <ng-container *ngIf="filterItem.queryType == 'date'">
                <span class="selected-date">
                    <img class="close-buble-icon" (click)="removeFilterCondition(filterItem, i)" (click)="resetInputValues()" src="assets/images/icons/close-icon.svg" alt="close icon">
                    <ng-container>Date : {{filterItem.value.startDate}} - {{filterItem.value.endDate}}</ng-container>
                </span>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<!-- @note Test name -->
<ng-template #testNameFilterTemplate>
    <div class="test-name-filter-body filter-body">
        <div class="test-name-input-cont">
            <img src="assets/images/icons/search-new-icon.svg" alt="search">
            <input type="text" placeholder="Search" [(ngModel)]="testNameTemplateInput" (ngModelChange)="handleTestNameValueChange($event)"/>
        </div>
        <!-- Test name available options result -->
        <div class="test-name-search-area">
            <ng-container  *ngFor="let searchItem of testNameSearchList, index as i">
                <span (click)="storeFilterCondition(searchItem,'testName')">
                    {{ searchItem }}
                </span>
            </ng-container>
        </div>
        <!-- Selected test name bubble -->
        <ng-container  *ngFor="let filterItem of secondaryFilterConditions; index as i">
            <ng-container *ngIf="filterItem.queryType == 'testName' ">
                <span class="selected-query-bubble">
                    <img class="close-buble-icon" (click)="removeFilterCondition(filterItem, i)" src="assets/images/icons/close-icon.svg" alt="close icon">
                    <ng-container>{{filterItem.value}}</ng-container>
                </span>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<!-- @note Project name -->
<ng-template #reportNameFilterTemplate>
    <div class="test-name-filter-body filter-body">
        <div class="test-name-input-cont">
            <img src="assets/images/icons/search-new-icon.svg" alt="search">
            <input type="text" placeholder="Search" [(ngModel)]="projectNameTemplateInput" (ngModelChange)="handleProjectNameValueChange($event)"/>
        </div>
        <!-- Test name available options result -->
        <div class="test-name-search-area">
            <ng-container  *ngFor="let searchItem of projectNameSearchList, index as i">
                <span (click)="storeFilterCondition(searchItem,'projectName')">
                    {{ searchItem }}
                </span>
            </ng-container>
        </div>
        <!-- Selected test name bubble -->
        <ng-container  *ngFor="let filterItem of secondaryFilterConditions; index as i">
            <ng-container *ngIf="filterItem.queryType == 'projectName' ">
                <span class="selected-query-bubble">
                    <img class="close-buble-icon" (click)="removeFilterCondition(filterItem, i)" src="assets/images/icons/close-icon.svg" alt="close icon">
                    <ng-container>{{filterItem.value}}</ng-container>
                </span>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<!-- @note reports page -->
<ng-template #projectNameFilterTemplate>
    <div class="test-name-filter-body filter-body">
        <div class="test-name-input-cont">
            <img src="assets/images/icons/search-new-icon.svg" alt="search">
            <input type="text" placeholder="Search" [(ngModel)]="projectNameTemplateInput" (ngModelChange)="handleProjectNameValueChange($event)"/>
        </div>
        <!-- Test name available options result -->
        <div class="test-name-search-area">
            <ng-container  *ngFor="let searchItem of projectNameSearchList, index as i">
                <span (click)="storeFilterCondition(searchItem,'projectName')">
                    {{ searchItem }}
                </span>
            </ng-container>
        </div>
        <!-- Selected test name bubble -->
        <ng-container  *ngFor="let filterItem of secondaryFilterConditions; index as i">
            <ng-container *ngIf="filterItem.queryType == 'projectName' ">
                <span class="selected-query-bubble">
                    <img class="close-buble-icon" (click)="removeFilterCondition(filterItem, i)" src="assets/images/icons/close-icon.svg" alt="close icon">
                    <ng-container>{{filterItem.value}}</ng-container>
                </span>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<!-- @note Reel name -->
<ng-template #reelNameFilterTemplate>
    <div class="test-name-filter-body filter-body">
        <div class="test-name-input-cont">
            <img src="assets/images/icons/search-new-icon.svg" alt="search">
            <input type="text" placeholder="Search" [(ngModel)]="reelNameTemplateInput" (ngModelChange)="handleTestNameValueChange($event)"/>
        </div>
        <!-- Test name available options result -->
        <div class="test-name-search-area">
            <ng-container  *ngFor="let searchItem of reelNameSearchList, index as i">
                <span (click)="storeFilterCondition(searchItem,'reelName')">
                    {{ searchItem }}
                </span>
            </ng-container>
        </div>
        <!-- Selected test name bubble -->
        <ng-container  *ngFor="let filterItem of secondaryFilterConditions; index as i">
            <ng-container *ngIf="filterItem.queryType == 'reelName' ">
                <span class="selected-query-bubble">
                    <img class="close-buble-icon" (click)="removeFilterCondition(filterItem, i)" src="assets/images/icons/close-icon.svg" alt="close icon">
                    <ng-container>{{filterItem.value}}</ng-container>
                </span>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<!-- @note Hashtag  top popup-->
<ng-template #hashtagFilterTemplate>
    <div class="test-name-filter-body filter-body">
        <div class="test-name-input-cont">
            <img src="assets/images/icons/search-new-icon.svg" alt="search">
            <input type="text" placeholder="Search" [(ngModel)]="hashtagTemplateInput" (ngModelChange)="handleHashtagValueChange($event)"/>
        </div>
        <!-- Test name available options result -->
        <div class="test-name-search-area" >
            <ng-container  *ngFor="let searchItem of hashtagSearchList, index as i">
                <span (click)="storeFilterCondition(searchItem,'hashtag')">
                    {{ searchItem }}
                </span>
            </ng-container>
        </div>
        <!-- Selected test name bubble -->
        <ng-container  *ngFor="let filterItem of secondaryFilterConditions; index as i">
            <ng-container *ngIf="filterItem.queryType == 'hashtag' ">
                <span class="selected-query-bubble">
                    <img class="close-buble-icon" (click)="removeFilterCondition(filterItem, i)" src="assets/images/icons/close-icon.svg" alt="close icon">
                    <ng-container>{{filterItem.value}}</ng-container>
                </span>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<!-- @note Tester -->
<ng-template #testerFilterTemplate>
    <div class="test-name-filter-body filter-body">
        <div class="test-name-input-cont">
            <img src="assets/images/icons/search-new-icon.svg" alt="search">
            <input type="text" placeholder="Search" [(ngModel)]="testerTemplateInput" (ngModelChange)="handleTesterValueChange($event)"/>
        </div>
        <!-- Tester search list -->
        <div class="test-name-search-area" >
            <ng-container  *ngFor="let searchItem of testerSearchList, index as i">
                <span (click)="storeFilterCondition(searchItem, 'tester')">
                    {{ searchItem }}
                </span>
            </ng-container>
        </div>
        <!-- Selected tester bubble -->
        <ng-container  *ngFor="let filterItem of secondaryFilterConditions; index as i">
            <ng-container *ngIf="filterItem.queryType == 'tester' ">
                <span class="selected-query-bubble">
                    <img class="close-buble-icon" (click)="removeFilterCondition(filterItem, i)" src="assets/images/icons/close-icon.svg" alt="close icon">
                    <ng-container>{{filterItem.value}}</ng-container>
                </span>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<!-- @note clip id -->
<ng-template #clipIdFilterTemplate>
    <div class="test-name-filter-body filter-body">
        <div class="test-name-input-cont">
            <img src="assets/images/icons/search-new-icon.svg" alt="search">
            <input type="text" placeholder="Search" [(ngModel)]="clipIdTemplateInput" (ngModelChange)="handleClipIdValueChange($event)"/>
        </div>
        <!-- clip Id search list -->
        <div class="test-name-search-area" >
            <ng-container  *ngFor="let searchItem of clipIdSearchList, index as i">
                <span (click)="storeFilterCondition(searchItem, 'clipId')">
                    {{ searchItem }}
                </span>
            </ng-container>
        </div>
        <!-- Selected Clip Id bubble -->
        <ng-container  *ngFor="let filterItem of secondaryFilterConditions; index as i">
            <ng-container *ngIf="filterItem.queryType == 'clipId' ">
                <span class="selected-query-bubble">
                    <img class="close-buble-icon" (click)="removeFilterCondition(filterItem, i)" src="assets/images/icons/close-icon.svg" alt="close icon">
                    <ng-container>Clip Id : {{filterItem.value}}</ng-container>
                </span>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<!-- @note Device Type -->
<ng-template #deviceTypeFilterTemplate>
    <div class="device-type-filter-body filter-body">
        <label (change)="handleCheckBoxClicked($event, 'deviceType')">
            <input value="desktop" type="checkbox" class="device-type-checkbox-input">
            Desktop
        </label>
        <label (change)="handleCheckBoxClicked($event, 'deviceType')">
            <input value="tablet" type="checkbox" class="device-type-checkbox-input">
            Tablet
        </label>
        <label (change)="handleCheckBoxClicked($event, 'deviceType')">
            <input value="smartphone" type="checkbox" class="device-type-checkbox-input">
            Smartphone
        </label>
    </div>
</ng-template>

<!-- @note Status -->
<ng-template #statusFilterTemplate>
    <div class="device-type-filter-body filter-body">
        <ng-container *ngIf=" currentRouteVariable === '/results/clips' ">
            <label>
                <input type="checkbox" class="device-type-checkbox-input">
                Active
            </label>
            <label>
                <input type="checkbox" class="device-type-checkbox-input">
                Archived
            </label>
        </ng-container>
        <ng-container *ngIf=" currentRouteVariable === '/research/tests' ">
            <label (change)="handleCheckBoxClicked($event, 'testStatus'); verifyTestStatusCheckboxAreInSync()">
                <input type="checkbox" value="0" class="test-status-checkbox-input test-status-running">
                Running
            </label>
            <label (change)="handleCheckBoxClicked($event, 'testStatus'); verifyTestStatusCheckboxAreInSync()">
                <input type="checkbox" value="2" class="test-status-checkbox-input test-status-draft">
                Draft
            </label>
            <label (change)="handleCheckBoxClicked($event, 'testStatus'); verifyTestStatusCheckboxAreInSync()">
                <input type="checkbox" value="3" class="test-status-checkbox-input test-status-finished">
                Finished
            </label>
        </ng-container>
    </div>
</ng-template>
<script src='assets/js/custom.js'></script>