<app-breadcrumbs-navbar></app-breadcrumbs-navbar>
<div class="videos-dash-container">
    <div class="videos-dash-content">
        <div class="videos-dash-header">
            <h2>Clips</h2>
            <!-- <div class="videos-dash-header-btns">
                <button (click)="createvideos()" class="btn-sty-blue">
                    <img  src="assets/images/icons/plus.svg" alt="plus icon"> Create videos
                </button>
                <a class="btn-sty-blue-outline" href="javascript: void();">View templates</a>
            </div> -->
        </div>
        <div class="videos-dash-menu">
            <div class="videos-dash-menu-text">Create clips.</div>
            <div>
                <app-all-filters></app-all-filters>
            </div>
            <!-- <div class="videos-dash-menu-icons">
                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'all' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                (click)="applyButtonFilterFun('all')">All videos ({{unfilteredJsonData.length}})</button>
                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'archive' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                (click)="applyButtonFilterFun('archive')">Archived ({{buttonFilterData.draft}})</button>
            </div> -->
        </div>

        <!-- Table -->
        <div class="videos-dash-table-container">

            <table class="videos-dash-table">
                
                <tr class="title-row">
                    <th class="video-thumbnail-title">Video</th>
                    <th class="clip-id-title">Clip-Id</th>
                    <th class="tester-title">Tester</th>
                    <th class="test-name-title">Test name</th>
                    <th class="device-title">Device</th>
                    <th class="date-title">Date</th>
                    <!-- <th class="action-title">Action</th> -->
                </tr>
                <ng-container *ngIf="isDataAvailable">
                    <tr class="data-row" *ngFor="let clipItemDetials of filteredJsonData; index as i">
                        <td class="video-thumbnail-data" >
                            <div (click)="viewSelectedClip(clipItemDetials)" class="video-thumbnail-cont" [ngStyle]="{'background-image': 'url(' + baseUrl+clipItemDetials.thumbnail + ')'}">
                                <p class="video-thumbnail-timer">{{ clipItemDetials.duration }}</p>
                            </div>
                        </td>
                        <td class="clip-id-data">
                            <div class="clip-id-cont">
                                {{clipItemDetials.testedtest_clip_id}}
                            </div>
                        </td>
                        <td class="tester-data">
                            <div class="tester-data-cont">
                                <h6>{{clipItemDetials.tester_name}}</h6>
                                <p>{{clipItemDetials.age}} &#8226; {{clipItemDetials.gender}} &#8226; {{clipItemDetials.country}}</p>
                            </div>
                        </td>
                        <td class="test-name-data">
                            <a (click)="viewSelectedClip(clipItemDetials)">
                                {{clipItemDetials.test_name}}
                                <!-- | {{clipItemDetials.testedtest_clip_id}} -->
                            </a>
                        </td>
                        <td class="device-data">
                            <div class="device-data-cont">
                                <ng-container *ngIf="clipItemDetials.device_type == 'desktop'">
                                    <img src="assets/images/icons/monitor.svg" alt="icon">
                                    <span>Desktop</span> 
                                </ng-container>
                                <ng-container *ngIf="clipItemDetials.device_type == 'smartphone'">
                                    <img src="assets/images/icons/smartphone.svg" alt="icon">
                                    <span>Smartphone</span> 
                                </ng-container>
                                <ng-container *ngIf="clipItemDetials.device_type == 'tablet'">
                                    <img src="assets/images/icons/tablet.svg" alt="icon">
                                    <span>Tablet</span> 
                                </ng-container>
                            </div>
                        </td>
                        <td class="date-data">{{clipItemDetials.created_date | date}}</td>
                        <!-- <td class="action-data"> 
                            <div class="dash-context-menu-wrapper">
                                <img (click)="showContextMenu($event)" class="dash-context-menu-icon" src="assets/images/icons/menu-icon-new.svg" alt="icon">

                                <div class="dash-context-menu-cont">
                                    <div class="dash-context-menu-content">
                                        <button class="dash-context-menu-edit">Edit</button>
                                        <button  class="dash-context-menu-archive">Archive</button>
                                        <button  class="dash-context-menu-delete">Delete</button>
                                    </div>
                                </div>
                            </div>             
                        </td> -->
                    </tr>
                </ng-container>

            </table>

            <div *ngIf="isDataAvailable" class="pagination-cont">
                <p class="pagination-txt">{{paginateCount}} / {{queriedJsonData.length}} clips <ng-container ></ng-container></p>
                <p class="pagination-btn" (click)="incrementPaginationCountFun(paginateCount + 10)">Load more</p>
            </div>


        </div>

        <div class="empty-data-cont" *ngIf="!isDataAvailable">
            <div class="empty-data-icon" style="margin-bottom: 25px;">
                <img src="assets/images/icons/cube-new-icon.svg" alt="cube icon">
            </div>

            <div class="empty-data-text" style="margin-bottom: 35px;">
                <p>You don't have any clips yet. </p>
                <!-- <p>Let's create a clips to get started.</p> -->
            </div>

            <!-- <div class="empty-data-btn" style="margin-bottom: 35px;">
                <button class="btn-sty-blue">
                    <img src="assets/images/icons/plus.svg" alt="plus icon"> Create clips
                </button>
            </div> -->

            <!-- <div class="empty-data-link">
                or <a class="a-sty" href="javascript: void();">view templates</a>
            </div> -->
        </div>

    </div>

</div>
<script src='assets/js/custom.js'></script>