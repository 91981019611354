<app-breadcrumbs-navbar></app-breadcrumbs-navbar>
<div class="reels-dash-container">
    <div class="reels-dash-content">
        <div class="reels-dash-header">
            <h2>Highlight reels</h2>
            <div class="reels-dash-header-btns">
                <button (click)="showCustomPopupBox = !showCustomPopupBox; customPopupBoxContent = 'reel-rename'; createReelInputAutoFocus()" class="btn-sty-blue">
                    <img  src="assets/images/icons/plus.svg" alt="plus icon"> Create reel
                </button>
                <!-- <a class="btn-sty-blue-outline" href="javascript: void();">View templates</a> -->
            </div>
        </div>
        <div class="reels-dash-menu">
            <div class="reels-dash-menu-text">Create reels.</div>
            <div>
                <app-all-filters></app-all-filters>
            </div>
            <!-- <div class="reels-dash-menu-icons">
                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'all' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                    (click)="applyButtonFilterFun('all')">All reels ({{unfilteredJsonData.length}})</button>
                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'draft' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                    (click)="applyButtonFilterFun('draft')">Draft ({{buttonFilterData.draft}})</button>
                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'archive' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                    (click)="applyButtonFilterFun('archive')">Archived ({{buttonFilterData.archive}})</button>
            </div> -->
        </div>

        <!-- Table -->
        <div class="reels-dash-table-container">

            <table class="reels-dash-table">
                
                <tr class="title-row">
                    <th class="video-thumbnail-title">Reel</th>
                    <th class="test-name-title">Reel name</th>
                    <!-- <th class="device-title">Device</th> -->
                    <th class="date-title">Date</th>
                    <th class="action-title">Action</th>
                </tr>
                <ng-container *ngIf="isDataAvailable">
                    <tr class="data-row" *ngFor="let reelItemDetials of filteredJsonData; index as i">
                        <td class="video-thumbnail-data" >
                            <div (click)="viewSelectedClip(reelItemDetials)" class="video-thumbnail-cont" [ngStyle]="{'background-image': 'url(' + baseUrl+reelItemDetials.thumbnail + ')'}">
                                <p class="video-thumbnail-timer">{{ reelItemDetials.total_duration }}</p>
                            </div>
                        </td>
                        <td class="test-name-data">
                            <a (click)="viewSelectedClip(reelItemDetials)" >
                                {{reelItemDetials.highlight_reel_name}}
                                <!-- | {{reelItemDetials.id}} -->
                            </a>
                            <span *ngIf="reelItemDetials.reel_status === 'INPROGRESS' ">uploading</span>
                            <span *ngIf="reelItemDetials.reel_status === 'DRAFT' ">draft</span>
                        </td>
                        <!-- <td class="device-data">
                            <div class="device-data-cont">
                                <img src="assets/images/icons/monitor.svg" alt="icon">
                                <ng-container *ngIf="reelItemDetials.device_type == 'desktop'">
                                    <img src="assets/images/icons/monitor.svg" alt="icon">
                                </ng-container>
                                <ng-container *ngIf="reelItemDetials.device_type == 'mobile'">
                                    <img src="assets/images/icons/smartphone.svg" alt="icon">
                                </ng-container>
                                <ng-container *ngIf="reelItemDetials.device_type == 'tablet'">
                                    <img src="assets/images/icons/tablet.svg" alt="icon">
                                </ng-container>
                            </div>
                        </td> -->
                        <td class="date-data">{{reelItemDetials.created_date | date}}</td>
                        <td class="action-data"> 
                            <div (click)="togglePopup(reelItemDetials.id)" class="dash-context-menu-wrapper">
                                <img class="dash-context-menu-icon" src="assets/images/icons/menu-icon-new.svg" alt="icon">

                                <div class="dash-context-menu-cont" *ngIf="reelItemDetials.id == openPopupId ">
                                    <div class="dash-context-menu-content">
                                        <button (click)="editHighlightReel(reelItemDetials)" class="dash-context-menu-edit">Edit</button>
                                        <button  class="dash-context-menu-archive" (click)="openCustomPopup('rename');"
                                        (click)="selectedReelRename(reelItemDetials)">Rename</button>
                                        <!-- <button  class="dash-context-menu-archive">Archive</button>
                                        <button  class="dash-context-menu-delete">Delete</button> -->
                                    </div>
                                </div>
                            </div>             
                        </td>
                    </tr>
                </ng-container>

            </table>

            <div *ngIf="isDataAvailable" class="pagination-cont">
                <p class="pagination-txt">{{paginateCount}} / {{queriedJsonData.length}} reels</p>
                <p class="pagination-btn" (click)="incrementPaginationCountFun(paginateCount + 10)">Load more</p>
            </div>


        </div>

        <div class="empty-data-cont" *ngIf="!isDataAvailable">
            <div class="empty-data-icon" style="margin-bottom: 25px;">
                <img src="assets/images/icons/cube-new-icon.svg" alt="cube icon">
            </div>

            <div class="empty-data-text" style="margin-bottom: 35px;">
                <p>You don't have any reels yet. </p>
                <!-- <p>Let's create a reels to get started.</p> -->
            </div>

            <!-- <div class="empty-data-btn" style="margin-bottom: 35px;">
                <button class="btn-sty-blue">
                    <img src="assets/images/icons/plus.svg" alt="plus icon"> Create reels
                </button>
            </div> -->

            <!-- <div class="empty-data-link">
                or <a class="a-sty" href="javascript: void();">view templates</a>
            </div> -->
        </div>

    </div>

</div>


<div *ngIf="showCustomPopupBox" class="custom-popup-backdrop">
    <div class="custom-popup-body">
        <img (click)="showCustomPopupBox = !showCustomPopupBox" class="custom-popup-close-icon" src="assets/images/icons/close-icon.svg" alt="close icon">

        <div class="custom-popup-content">

            <!-- reel rename -->
            <div class="reel-rename-cont" *ngIf=" customPopupBoxContent === 'reel-rename' ">

            <section class="header">
                <h1>Create Highlight reel</h1>
            </section>

            <main style="margin-top: 80px; margin-bottom: 60px">
                <p>Highlight reel name</p>
                <input id="create-reel-input-box" type="text" class="c-h-reel-popup-input" [(ngModel)]="tempReelHeading" (ngModelChange)="reelNameValidation($event); handleRenameValueChanges($event);">
                <small style="color: var(--red); font-size: 12px;" *ngIf="checkReelNameIsValid">Empty space is not allowed</small>
                <small style="color: var(--red); font-size: 12px;" *ngIf="renameValueExist"> The project name already exists</small>
            </main>

            <button (click)="CreateReel()" class="custom-popup-main-btn btn-sty-blue"
            [ngClass]="[!checkReelNameIsValid && tempReelHeading.length > 2 && !renameValueExist ? 'create-reel-btn-active' : 'create-reel-btn-inactive']">
                Create Highlight reel
            </button>

            <p style="font-size: 18px; font-family: var(--font-medium); text-align:center; margin-top: 25px;"
            (click)="showCustomPopupBox = !showCustomPopupBox" class="a-sty">Cancel</p>

            </div>
            
        </div>

    </div>
</div>


<div *ngIf="showUPCustomPopup" class="custom-popup-backdrop">
    <div class="custom-popup-body">
        <img (click)="showUPCustomPopup = !showUPCustomPopup" class="custom-popup-close-icon" src="assets/images/icons/close-icon.svg" alt="close icon">
        <div class="custom-popup-content">
            <ng-container *ngIf=" customPopupDisplayContent == 'rename' ">
                <h2 class="custom-popup-title">Rename reel</h2>

                <label style="margin-top: 50px;">Reel name</label>
                <input (ngModelChange)="handleRenameValueChanges($event); reelNameValidation($event)" [(ngModel)]="renameInputValue" type="text" class="custom-popup-text-input">
                <p *ngIf="(renameInputValue != renameExistingValue) && renameValueExist" style="margin-top:10px; color: var(--red); font-size: 14px; font-family: var(--font-regular);"> 
                    The reel name already exists 
                </p>
                <small style="color: var(--red); font-size: 12px;" *ngIf="checkReelNameIsValid">Empty space is not allowed</small>

                <button style="margin-top: 50px;" (click)="sendRenameRequest()" class="custom-popup-main-btn btn-sty-blue"
                    [ngClass]="[ (renameInputValue != renameExistingValue) && !renameValueExist && (renameInputValue.trim().length > 2) && !checkReelNameIsValid? 'up-custom-popup-btn-active' : 'up-custom-popup-btn-inactive']">
                    Rename
                </button>
            </ng-container>
        </div>
        <p style="font-size: 18px; font-family: var(--font-medium); text-align:center; margin-top: 25px;"
        (click)="showUPCustomPopup = !showUPCustomPopup" class="a-sty">Cancel</p>
    </div>
</div>

<script src='assets/js/custom.js'></script>