<div class='tests-container lg-screen-width'>
  <aside class='tests-sidebar'>
    <div class='mobile-head'>
      <span class='mobile-hamburger'>
        <i class='hamburger one'></i>
        <i class='hamburger two'></i>
        <i class='hamburger three'></i>
      </span>

      <h2 (click)='tests = !tests'>
        <span>Tests</span> <i class='fa fa-angle-down'></i>
      </h2>
    </div>
    <ul [style.display]="tests ? 'none' : 'block'" class='side-menu'>
      <li (click)='tests = !tests' [class.active]='alltest' [routerLinkActiveOptions]='{ exact: true }'
        [routerLinkActive]="['active']" class='menu-btn active' id='all-tests' routerLink='/dashboard_tests'>
        <a (click)="statusBasedFilter('')">All Tests</a>
      </li>
      <li (click)='tests = !tests' [class.active]='draft' class='menu-btn' id='draft'>
        <a (click)="statusBasedFilter('draft')" routerLink='/dashboard_tests/draft' routerLinkActive='active'>Draft</a>
      </li>
      <li (click)='tests = !tests' [class.active]='running' class='menu-btn' id='ruactiveg'>
        <a (click)="statusBasedFilter('running')" routerLink='/dashboard_tests/running'
          routerLinkActive='active'>Running</a>
      </li>
      <li (click)='tests = !tests' [class.active]='finish' class='menu-btn' id='finactived'>
        <a (click)="statusBasedFilter('finish')" routerLink='/dashboard_tests/finish'
          routerLinkActive='active'>Finished</a>
      </li>
      <li (click)='tests = !tests' [class.active]='project' class='menu-btn' id='proactives'>
        <a (click)="statusBasedFilter('project')" routerLink='/dashboard_tests/project'
          routerLinkActive='active'>Projects</a>
      </li>
      <li (click)='tests = !tests' [class.active]='archive' class='menu-btn' id='aractivee'>
        <a (click)="statusBasedFilter('archive')" class='archive' routerLink='/dashboard_tests/archive'
          routerLinkActive='active'>Archive</a>
      </li>
    </ul>
  </aside>
  <div class='section test-section' id='all-parent'>
    <div *ngIf='projectTestShow'>
      <div class='test-create-btn'>
        <div class='row create-row'>
          <div class='col create-new-column'>
            <button class='create-btn' routerLink='/dashboard_tests/create_test' routerLinkActive='active'>
              Create new test
            </button>
          </div>

          <div class='col-sm-auto create-search-column'>
            <label [ngClass]="search3 ? 'open' : 'close'" class='create-search-sub'>
              <input [(ngModel)]='searchText' placeholder='Search for projects' type='text' />
              <a (click)='search3 = !search3' class='create-search'><i class='fa fa-search'></i></a>
            </label>
          </div>

        </div>
      </div>

      <ng-template #noItems>
        <article class='test-card draft'>
          <div class='row listing-detail align-items-center'>
            <p>
              {{ noTestAvlMessage }}
              <span *ngIf='noTestAvlMessage'><a class='completedprofile-link'
                  routerLink='/dashboard_tests/create_test'>You can easily create a test here.</a>
              </span>
            </p>
          </div>
        </article>
      </ng-template>
      
      <!-- @note search bar -->
      <ng-container *ngIf='sortData.length > 0; else noItems'>
        <article *ngFor='
              let testList of sortData | searchFilter: searchText;
              let i = index
            ' class='test-card draft'>
          <app-test-item (triggredOrderTest)='triggredOrderTestCall($event)' [testListItemDetails]='testList'>
          </app-test-item>
        </article>
      </ng-container>

    </div>
    
    <div *ngIf='!projectTestShow'>
      <div class='test-create-btn'>
        <div class='row create-row'>
          <div class='col create-new-column'>
            <button class='create-btn' routerLink='/dashboard_tests/create_test' routerLinkActive='active'>
              Create new test
            </button>
          </div>
          <div class='col-sm-auto create-search-column'>
            <label [ngClass]="search4 ? 'open' : 'close'" class='create-search-sub'>
              <input [(ngModel)]='searchText1' placeholder='Search for projects' type='text' />
              <a (click)='search4 = !search4' class='create-search'><i class='fa fa-search'></i></a>
            </label>
          </div>
        </div>
      </div>
      <ng-template #noItems>
        <article class='test-card draft'>
          <div class='row listing-detail align-items-center'>
            <p>
              {{ noTestAvlMessage }}
              <span *ngIf='noTestAvlMessage'><a class='completedprofile-link'
                  routerLink='/dashboard_tests/create_test'>You can easily create test it here.</a>
              </span>
            </p>
          </div>
        </article>
      </ng-template>
      <div aria-multiselectable='true' class='accordion md-accordion userpeak-accordion test' id='accordionEx'
        role='tablist'>
        <ng-container *ngIf='allProjectList.length > 0; else noItems'>
          <div *ngFor='
              let test of allProjectList | searchProject: searchText1;
              let i = index
            ' class='card'>

            <div class='card-header' id='accordion-{{ i }}' role='tab'>
              <a (click)='showTestID(test.id, i)' aria-controls='collapseOne1' aria-expanded='true' class='collapsed'
                data-parent='#accordionEx' data-toggle='collapse' href='#collapse-{{ i }}'>
                <h5 class='mb-0'>
                  {{ test.project_name }}<span class='count'>{{ test.total_test }}</span>
                  <i class='fas rotate-icon'><img src='assets/images/arrow-bottom.png' ss /></i>
                </h5>
              </a>
            </div>

            <div *ngIf='singleIndex == i' aria-labelledby='headingOne1' class='collapse' data-parent='#accordionEx'
              id='collapse-{{ i }}' role='tabpanel'>
              <div class='card-body'>
                <article *ngFor='let testList of filterProjectList; let i = index' class='test-card draft'>
                  <app-test-item (triggredOrderTest)='triggredOrderTestCall($event)' [testListItemDetails]='testList'>
                  </app-test-item>
                </article>

              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <button class='feedback-btn'>Feedback</button>
  </div>
</div>