<div class='tests-container lg-screen-width'>
  <aside class='tests-sidebar'>
    <div class='mobile-head'>
      <span class='mobile-hamburger'>
        <i class='hamburger one'></i>
        <i class='hamburger two'></i>
        <i class='hamburger three'></i>
      </span>
      <h2>
        <span>Videos</span>
        <i (click)='tests = !tests' class='fa fa-angle-down'></i>
      </h2>
    </div>
    <ul [style.display]="tests ? 'block' : 'none'" class='side-menu' style='cursor: pointer'>
      <li (click)='tests = !tests' [class.active]='alltest' [routerLinkActiveOptions]='{ exact: true }'
        [routerLinkActive]="['active']" class='menu-btn active' id='all-tests' routerLink='/videos/all'>
        <a (click)='getAllVideo()'>All Videos</a>
      </li>
      <li (click)='tests = !tests' [class.active]='newVideo' class='menu-btn' id='new-videos'>
        <a (click)='newVideos()' [routerLinkActive]="['active']" routerLink='/videos/new'>New Videos</a>
      </li>

      <li (click)='tests = !tests' (click)='myProject()' [class.active]='finish' [routerLinkActive]="['active']"
        class='menu-btn' id='project-video' routerLink='/videos/my'>
        <a>My Projects</a>
      </li>
    </ul>
  </aside>
  <div class='section test-section videos' id='all-parent'>
    <div *ngIf='myProjectSHow'>
      <div class='row top-filter'>
        <div class='col-sm'>
          <div (myCustomEvent)='onMyCustomEvent()' appShowHideEvent class='filter'>
            <div>
              <button (click)='filterShow()' [ngClass]="ftShow ? 'active' : ''" class='filter-btn'>
                Filter Videos
              </button>
            </div>
            <div *ngIf='showFt' class='filter-dropdown' style='display: block'>
              <div class='tabs'>
                <div class='container'>
                  <div class='row'>
                    <div [ngClass]="filter ? 'filter-name' : ''" class='col-4'>
                      <ul (click)='filter = !filter' class='nav nav-pills nav-stacked flex-column'>
                        <li [ngClass]="addClass ? 'active' : ''">
                          <a (click)='filterMenu1Show()' class='active' data-toggle='pill'>By Name</a>
                        </li>
                        <li [ngClass]="addClass1 ? 'active' : ''">
                          <a (click)='filterMenu2Show()' data-toggle='pill'>By Device</a>
                        </li>
                        <li [ngClass]="addClass2 ? 'active' : ''">
                          <a (click)='filterMenu3Show()' data-toggle='pill'>By Date</a>
                        </li>
                        <li [ngClass]="addClass3 ? 'active' : ''">
                          <a (click)='filterMenu4Show()' data-toggle='pill'>By Tags</a>
                        </li>
                      </ul>
                    </div>
                    <div [ngClass]="filterMenuShow1 ? 'show' : ''" class='col-8'>
                      <div class='tab-content'>
                        <div [ngClass]="addClass ? 'active' : ''" class='tab-pane' id='tab_a'>
                          <label><input #searchP (keydown.enter)='
                                keyDownFunction(
                                  $event

                                )
                              ' class='search-name' placeholder='search' required type='text' /></label>
                        </div>
                        <div [ngClass]="addClass1 ? 'active' : ''" class='tab-pane device' id='tab_b'>
                          <label>
                            <span class='checkbox'>
                              <input (change)="
                                  onSelectAndroid(
                                    'Android',
                                    $event.target.checked
                                  )
                                " (keydown.enter)='keyDownDeviceFunction($event)' type='checkbox' />
                              <span></span>
                            </span>
                            Android</label>
                          <label>
                            <span class='checkbox'>
                              <input (change)="
                                  onSelectIphone(
                                    'iPhone',
                                    $event.target.checked
                                  )
                                " (keydown.enter)='keyDownDeviceFunction($event)' type='checkbox' />
                              <span></span>
                            </span>
                            iPhone</label>
                          <label>
                            <span class='checkbox'>
                              <input (change)="onSelectDesktop('Desktop',$event.target.checked)"
                                (keydown.enter)='keyDownDeviceFunction($event)' type='checkbox' />
                              <span></span>
                            </span>
                            Desktop</label>
                        </div>
                        <div [ngClass]="addClass2 ? ' active
                      ' : ''" class='tab-pane' id='tab_c'>
                          <span class='date-input'>
                            <label class='start'>
                              From
                              <span class='input-group date' data-date-format='dd-mm-yyyy' id='datepicker1'>
                                <input #searchP1 class='form-control' type='date' />
                                <span class='input-group-addon'><i></i></span>
                              </span>
                            </label>
                            <label class='end'>
                              To
                              <span class='input-group date' data-date-format='dd-mm-yyyy' id='datepicker2'>
                                <input #searchP2 (keydown.enter)='
                                    keyDownFunction1(searchP1.value, $event)
                                  ' class='form-control' type='date' />
                                <span class='input-group-addon'><i></i></span>
                              </span>
                            </label>
                          </span>
                        </div>
                        <div [ngClass]="addClass3 ? ' active' : ''" class='tab-pane' id='tab_d'>
                          <label><input class='search-name' placeholder='search' type='text' /></label>
                        </div>
                      </div>
                      <div class='filter-bottom-btn'>
                        <button (click)='filterShow()' class='bottom-btn cancel'>
                          Cancel
                        </button>
                        <button (click)='
                            searchVideo(
                              searchP.value,
                              searchP1.value,
                              searchP2.value,
                              deviceIphone,
                              deviceAndroid,
                              deviceDesktop
                            )
                          ' class='bottom-btn apply' type='submit'>
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='col-sm-auto create-search-column'>
          <label [ngClass]="search4 ? ' open' : 'close'" class='create-search-sub'>
            <input [(ngModel)]='searchText' placeholder='Search Test, URLs, #hashtags... ' type='text' />
            <a (click)='search4 = !search4' class='create-search'><i class='fa fa-search'></i></a>
          </label>
        </div>
      </div>
      <ng-template #noItems>
        <article *ngIf='!showMessageVideo' class='test-card draft'>
          <div class='row listing-detail align-items-center'>
            <p>
              {{ noVideoMessage }}
              <span *ngIf="noVideoMessage != ' No record found!'"><a class='completedprofile-link'
                  routerLink='/dashboard_tests/create_test'>You can easily
                  create test it here.</a>
              </span>
            </p>
          </div>
        </article>
        <ng-container *ngIf='showExampleVideo'>
          <ng-container *ngFor='let testedList of getAllExampleVideo; let i = index'>
            <app-video-item [videoItemDetails]='testedList'></app-video-item>
          </ng-container>
        </ng-container>
      </ng-template>
      <ng-container *ngIf='sortData.length > 0; else noItems'>
        <ng-container *ngFor='
      let testedList of sortData | searchFilter: searchText;
      let i = index
    '>
          <app-video-item [testerProfileList]='testerTesterProfile' [videoItemDetails]='testedList'></app-video-item>
        </ng-container>
      </ng-container>
    </div>
    <div *ngIf='!myProjectSHow'>
      <div class='row top-filter'>
        <div class='col-sm'></div>
        <div class='col-sm-auto create-search-column'>
          <label [ngClass]="search5 ? ' open' : 'close'" class='create-search-sub'>
            <input [(ngModel)]='searchText1' placeholder='Search Test, URLs, #hashtags...' type='text' />
            <a (click)='search5 = !search5' class='create-search'><i class='fa fa-search'></i></a>
          </label>
        </div>
      </div>
      <ng-template #noItems>
        <article class='test-card draft'>
          <div class='row listing-detail align-items-center'>
            <p>
              You have not create any project yet !
              <a class='completedprofile-link' routerLink='/dashboard_tests/create_test'>You can easily create project
                it here.</a>
            </p>
          </div>
        </article>
      </ng-template>

      <div aria-multiselectable='true' class='accordion md-accordion userpeak-accordion' id='accordionEx'
        role='tablist'>

        <ng-container *ngIf='allProjectList.length > 0; else noItems'>
          <div *ngFor='
              let test of allProjectList | searchProject: searchText1;
              let i = index
            ' class='card'>

            <div class='card-header' id='heading-{{ i }}' role='tab'>
              <a (click)='showVideo1(i, test.id)' aria-controls='collapseTwo2' aria-expanded='false' class='collapsed'
                data-parent='#accordionEx' data-toggle='collapse' href='#collapse-{{ i }}'>
                <h5 class='mb-0'>
                  <h5 class='mb-0'>
                    {{ test.project_name }}
                    <span class='count'>{{ test.total_videos }}</span>
                    <i class='fas rotate-icon'><img src='assets/images/arrow-bottom.png' /></i>
                  </h5>
                </h5>
              </a>
            </div>
            <div *ngIf='singleIndex == i' aria-labelledby='headingTwo2' class='collapse' data-parent='#accordionEx'
              id='collapse-{{ i }}' role='tabpanel'>
              <div class='card-body'>
                <ng-container *ngFor='
                let testedList of sortData1 | searchFilter: searchText;
                let i = index
              '>
                  <app-video-item [testerProfileList]='testerTesterProfile' [videoItemDetails]='testedList'>
                  </app-video-item>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <button class='feedback-btn'>Feedback</button>
  </div>
</div>
