import { Component, OnInit, OnDestroy } from '@angular/core';
import { CreateNewTestService } from 'src/app/shared/services/create-new-test.service';
import { CustomPopupBoxService } from 'src/app/shared/services/custom-popup-box.service';
import { TesterTestedListService } from 'src/app/shared/services/tester-tested-list.service';
import { environment } from 'src/environments/environment';
import { UpStoreSelectedVidDetialsService } from 'src/app/shared/services/up-store-selected-vid-detials.service';
import {NavigationEnd, ActivatedRoute, Router, Params} from '@angular/router';
import { SearchBoxInputService } from 'src/app/shared/services/search-box-input.service';
import { AllFiltersShareQueryService } from 'src/app/shared/services/all-filters-share-query.service';
import { GeneralApiService } from '../api-call/general-api.service';

@Component({
  selector: 'app-findings-dashboard',
  templateUrl: './findings-dashboard.component.html',
  styleUrls: ['./findings-dashboard.component.scss']
})
export class FindingsDashboardComponent implements OnInit, OnDestroy {

  filteredJsonData = [];
  unfilteredJsonData = [];
  queriedJsonData = [];

  paginateCount: number = 0;
  paginationDisplayedCount: number = 0; // dummy
  baseUrl = environment.apiUrl;
  buttonFilterData: any ={
    all: 0,
    newVideos: 0,
  };
  isDataAvailable:boolean = true;
  selectedChipFilter: string = "all";
  allFilterQueryDataObservable: any;
  quatedata:any =[]

  constructor(
    private _createnewTestServices: CreateNewTestService,
    public _customPopupBoxService: CustomPopupBoxService,
    private _testedTestServices: TesterTestedListService,
    private _upStoreSelectedVidDetialsService: UpStoreSelectedVidDetialsService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _searchBoxInputService: SearchBoxInputService,
    private _allFiltersShareQueryService: AllFiltersShareQueryService,
    private generalApiService: GeneralApiService,
  ) {
      this.allFilterQueryDataObservable = this._allFiltersShareQueryService.componentFilterQueryData.subscribe((res)=>{
        
        if(res.requestType != "none"){
          // console.log('res type is not none', res.requestType);
          if(res.component == this._router.url.split('?')[0]){
            this.processComponentFilterData(res);
          }
        }
      });
      console.log(" tempClipList allFilterQueryDataObservable",this.allFilterQueryDataObservable);
    }

  ngOnDestroy(): void {
    this.allFilterQueryDataObservable.unsubscribe();
    console.log("ngondestroy called findings page");
    
  }

  ngOnInit(): void {
    this.initializeComponentData();
    console.log("159",this.filteredJsonData)
  }
  // ngAfterViewInit() {
  //   console.log(' 159 After View Init - filteredJsonData:', this.filteredJsonData);
  // }
  
  // ngOnChanges() {
  //   console.log('159 On Changes - filteredJsonData:', this.filteredJsonData);
  // }
  

  initializeComponentData(){
    this._activatedRoute.queryParams.subscribe((params) => {
      if(params['project-id'] != undefined && params['test-id'] != undefined){
        
        this.viewTestRelatedData(params['test-id']);
      }
      else if(params['project-id'] != undefined){
        this.viewProjectRelatedVideos(params['project-id']);
      } 
      else {        
        this._router.navigate(['/project-selector']);
      }
    });
  }

  // fun1( text:any, type:boolean, data:any, fulldata:any){
  //   console.log("32147", text, type, data, "full data",fulldata );
    
  // }

  // ==============================================================
 
  arr=[]
  displayTestName(text: string, ){
    this.arr.push(text)
    if(text !== null){
      if(text.length >= 15){
        let processedText = text.slice(0, 14)
        return `${processedText}...`;
      } else {
        return text;
      }
    } else {
      return "";
    }
    
  }
  displayDate(date: string){
    if(date !== null){
      // Create a new Date object for Jan 25, 2024
      const customDate = new Date(date);
      // Format the date to the desired format: 'Jan 25, 2024'
      let options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
      const formattedDate = customDate.toLocaleDateString('en-US', options);
      return formattedDate
    } else {
      return "";
    }
  }
  // ==============================================================

  processComponentFilterData(value){
    if(value.requestType == "getDataFromParent"){
      this._allFiltersShareQueryService.sendDataParentToChild(this.unfilteredJsonData);
    }
    else if(value.requestType == "dataChildToParent"){
      let tempClipList = [] 
      this._allFiltersShareQueryService.childToParentDataVariable.subscribe((res)=>{
        tempClipList = res;
        
        console.log("tempClipList res",res);
      });
      console.log("tempClipList",tempClipList);
      
      this.queriedJsonData = tempClipList;
      this.initializePaginationFun();
    }
    else if(value.requestType == "loadOriginalData"){
      // console.log('loadOriginalData working');
      this.initializeComponentData();
    }
  }

  viewProjectRelatedVideos(projectId){
    // console.log("32147 project related fun call")
    let body = {
      "project_id": [projectId],
    }
    this.generalApiService.findingsListAPI(body).subscribe((response: any)=>{
      response.data[0].create_test_data.forEach(element => {
      element.tested_test_data.length
      this.isDataAvailableFun(element.tested_test_data.length);
     });
      this.unfilteredJsonData = [];
      this.queriedJsonData = [];
      response.data[0].create_test_data?.forEach(element => {
        element.tested_test_data?.forEach(test_data => {
          test_data.findings?.forEach(finding_data => {
            finding_data["tester_name"] = test_data.tester_name
            finding_data["created_at"] = test_data.created_at
            finding_data["tested_test_id"] = test_data.tested_test_id
            this.unfilteredJsonData.push(finding_data);
            this.queriedJsonData.push(finding_data);
          });
        });
      });


      // response.data[0].create_test_data?.forEach(element => {
      //   element.tested_test_data?.forEach(test_data => {
      //       this.unfilteredJsonData.push(test_data);
      //       this.queriedJsonData.push(test_data);
      //   });
      // });
      // response.data[0].tested_test_data.forEach((item1)=>{
      //   item1.findings.forEach((item2)=>{
      //     item2["tester_name"] = item1.tester_name
      //     item2["created_at"] = item1.created_at
      //     this.unfilteredJsonData.push(item2);
      //     this.queriedJsonData.push(item2);
      //   });
      // });

      this.validatePaginateCount();
      
      // // sort with id
      // this.queriedJsonData.sort(function(a: any, b: any){
      //   return a.id - b.id;
      // })
      // // decending/reverse order
      // this.queriedJsonData.sort().reverse();
      
      // initially show only 10 items
      this.filteredJsonData = [];
      
      for(let i = 0; i < this.paginateCount; i++){
        this.filteredJsonData.push(this.queriedJsonData[i])
        // console.log("557 quried json data",this.filteredJsonData);
        // console.log("655 filter json data",this.filteredJsonData,"quried",this.queriedJsonData);
      }
    });

    
    this.ChipsFiltersInitialize();
  }


  viewTestRelatedData(testId){
    // console.log("655 test related fun call")
    let body = {
      "test_id": [testId],
    }
    this.generalApiService.findingsListAPI(body).subscribe((response: any)=>{
      
      response.data[0].create_test_data.forEach(element => {
        element.tested_test_data.length
        this.isDataAvailableFun(element.tested_test_data.length);
       });
      this.unfilteredJsonData = [];
      this.queriedJsonData = [];
      response.data[0].create_test_data?.forEach(element => {
        element.tested_test_data?.forEach(test_data => {
          test_data.findings?.forEach(finding_data => {
            finding_data["tester_name"] = test_data.tester_name
            finding_data["created_at"] = test_data.created_at
            finding_data["tested_test_id"] = test_data.tested_test_id
            this.unfilteredJsonData.push(finding_data);
            this.queriedJsonData.push(finding_data);
            // console.log("225 item hastag arr",finding_data);
          });
        });
      });


      // console.log('unfilteredJsonData', this.unfilteredJsonData)
      // console.log('queriedJsonData', this.queriedJsonData)
      this.validatePaginateCount();
      
      // // sort with id
      // this.queriedJsonData.sort(function(a: any, b: any){
      //   return a.id - b.id;
      // })
      // // decending/reverse order
      // this.queriedJsonData.sort().reverse();

      // initially show only 10 items
      this.filteredJsonData = [];
      for(let i = 0; i < this.paginateCount; i++){
        this.filteredJsonData.push(this.queriedJsonData[i])
      }
    });


    this.ChipsFiltersInitialize();
  }


  ChipsFiltersInitialize(){
    // for future use
  }


  isDataAvailableFun(value){
    
    // if json data is empty show empty message
    if(value > 0){
      this.isDataAvailable = true;
    } else {
      this.isDataAvailable = false;
    }
  }
// display quate fun
  displayQuote(text:any){
 
    if(text !== null && text !== ""){
      
      if(text.length >= 25){
        let processedText = text.slice(0, 24)
        return `${processedText}...`;
      } else {
        return `${text}`;
      }
    } else {
      return null;
    }
  }
  
  

  validatePaginateCount(){
    if(this.queriedJsonData.length < 10){
      this.paginateCount = this.queriedJsonData.length;
    } else {
      this.paginateCount = 10;
    }
  }



  incrementPaginationCountFun(value){

    let actualQueriedDataLength = this.queriedJsonData.length;

    if(value < actualQueriedDataLength){
      this.paginateCount = value;
    } else {
      this.paginateCount = actualQueriedDataLength;
    }

    this.restrictDataCount();
  }

  applyButtonFilterFun(value){
    if(value === "all"){
      // if filter applied is "all-videos"
      this.queriedJsonData = [];
      this.selectedChipFilter = "all";
      this.unfilteredJsonData.forEach((item)=>{
          this.queriedJsonData.push(item);
      })
    }
    else if(value === "newVideos"){
      // if filter applied is "new videos"
      this.queriedJsonData = [];
      this.selectedChipFilter = "newVideos";
      this.unfilteredJsonData.forEach((item)=>{
        if(item.project_status == 9){
          this.queriedJsonData.push(item);
        }
      })
    } 
    
    this.initializePaginationFun();
    this.isDataAvailableFun(this.queriedJsonData.length)
  }


  initializePaginationFun(){
    this.validatePaginateCount();
    this.filteredJsonData = [];
    for(let i = 0; i < this.paginateCount; i++){
      this.filteredJsonData.push(this.queriedJsonData[i])
    }
  }


  restrictDataCount(){
    this.filteredJsonData = [];
    for(let i = 0; i < this.paginateCount; i++){
      this.filteredJsonData.push(this.queriedJsonData[i])
    }
  }


  showContextMenu(event){
    let tempElement = event.target.parentElement.children[1];
    if(tempElement.style.display == "none"){
      // close previous opened popup menu
      let allElements = document.getElementsByClassName("dash-context-menu-cont");
      for (let i = 0; i < allElements.length; i++) {
          allElements.item(i).setAttribute("style", "display: none");
      }
      // open popup menu on clicked card
      tempElement.style.display = "block";
    }
    else{
      // close popup menu after clicking again
      tempElement.style.display = "none";
    }
  }


  viewSelectedVideo(tested_test_id){
    this._activatedRoute.queryParams.subscribe((params) => {
      if(params['project-id'] != undefined){
        this._testedTestServices.getVideosWithProjectId(params['project-id']).subscribe((response: any)=>{
          console.log('response', response)
          let videoDataJson = {};
          let videoArray =  response.data.video_list.filter(itemInArray => itemInArray.id === tested_test_id);
          console.log('videoArray', videoArray)
          videoDataJson = videoArray[0];
          console.log('videoDataJson', videoDataJson)
          localStorage.setItem('cacheSelectedVideoDetials', JSON.stringify(videoDataJson));
          if(params['project-id'] != undefined && params['test-id'] != undefined){
            this._router.navigateByUrl("/results/videos/view-video");
          }
          else if(params['project-id'] != undefined){
            localStorage.setItem('selectedTestId', "");
            this._router.navigateByUrl("/results/videos/view-video");
          } 
        });
      } else {
        console.error("error: project id not found!!!!")
      }
    });
  }

  // hashtagLimit(item){
  //   console.log("225 item",item);
    
  //   let newArray = [];
  //   let oldArray = item;
  //   let charCount = 0;
  //   for(let i = 0; i <= oldArray; i++){
  //     let char = i;
  //     console.log("225 char",char);
      
  //     charCount = charCount + char;
  //     if(charCount <= 15){
  //       newArray.unshift(oldArray[i]);
  //     }
  //     else{
  //       break;
  //     }
  //   }
  //   return newArray;
  // }
  // hashtagLimit(item){
  //   let newArray = [];
  //   let oldArray = item;
  //   let charCount = 0;
  //   for(let i = 0; i <= oldArray.length -1; i++){
  //     let char = oldArray[i];
  //     charCount = charCount + char.length;
  //     if(charCount <= 15){
  //       newArray.unshift(oldArray[i]);
  //     }
  //     else{
  //       break;
  //     }
  //   }
  //   return newArray;
  // }

  hashtagLimit(item){
    // console.log("666 limit    ******************",this.queriedJsonData);
    
    let newArray = [];
    let oldArray = item;
    let charCount = 0;
    for(let i = 0; i <= oldArray.length -1; i++){
      let char = oldArray[i];
      
      charCount = charCount + char.length;
        if(charCount <= 15){
          newArray.unshift(oldArray[i]);
        }
        else{
          break;
        }
      }
      
      return newArray;
    }

  hashtagRemainingCount(item){
    let newArray = [];
    let oldArray = item;
    let charCount = 0;
    for(let i = 0; i <= oldArray.length -1; i++){
      let char = oldArray[i];
      charCount = charCount + char.length;
      if(charCount <= 15){
        newArray.unshift(oldArray[i]);
      } 
      else{
        break;
      }
    }
    let result = oldArray.length - newArray.length;
    return result;
  }




  showHashtagPopup(event){
    let tempElement = event.target.parentElement.lastChild;
    tempElement.style.display = "block";
  }

  closeHashtagPopup(event){
    let tempElement = event.target.parentElement.parentElement;
    tempElement.style.display = "none";
  }

  sendHashtagToAllFilterComponent(value, filterType){
    
    this._allFiltersShareQueryService.sendHashTagValue(value, filterType);
  }

  invokeMultiFilterSearch(inputValue) {
    let queryType = inputValue.queryType;
    let queryValue = inputValue.value;

    if(queryType === "test"){
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        if(item.test_name.toLowerCase().includes(queryValue)){
          this.queriedJsonData.push(item);
        }
      })
    } 
    else if(queryType === "cancel"){
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        this.queriedJsonData.push(item);
      })
    }
    else if(queryType === "tester"){
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        if(item.tester_name.toLowerCase().includes(queryValue)){
          this.queriedJsonData.push(item);
        }
      })
    }
    else if(queryType === "hashtag"){
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        console.log(" findings page hashtagvalue",this.unfilteredJsonData);
           
        item.hashtags.forEach((item2)=>{
          if(item2.toLowerCase().includes(queryValue)){
            this.queriedJsonData.push(item);
            console.log(" findings page hashtagvalue",item);
            
          }
        })
      })
    }
    else if(queryType === "device"){
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        queryValue.forEach((deviceItem)=>{
          if(item.device_type.toLowerCase().includes(deviceItem)){
            this.queriedJsonData.push(item);
          }
        })
      })
    }
    else if(queryType === "date"){
      this.queriedJsonData = [];
      let startDateFormatted = new Date(queryValue.startDate);
      let endDateFormatted = new Date(queryValue.endDate);

      this.unfilteredJsonData.forEach((item)=>{
        let newDate = new Date(item.date_of_tested);
        if(newDate >= startDateFormatted && newDate <= endDateFormatted){
          this.queriedJsonData.push(item);
        }
      })
    }

    this.initializePaginationFun();
  }


  innokeSearchBoxFilter(queryValue){

    console.log('search query invoked');

    if(queryValue != ""){
      console.log("inside condition if ", queryValue);

      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        if(item.test_name.toLowerCase().includes(queryValue)){
          this.queriedJsonData.push(item);
        }
      })
      this.selectedChipFilter = "";
    }

    if(queryValue == ""){
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        this.queriedJsonData.push(item);
      })
      this.selectedChipFilter = "all";
    }
    this.initializePaginationFun();
  }
}
        

