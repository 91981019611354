import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {CreateNewTestService} from 'src/app/shared/services/create-new-test.service';
import {CreateNewTest} from '../models/create-new-test';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AuthService} from 'src/app/shared/services/auth.service';
import { ModelBoxService } from 'src/app/shared/services/model-box.service';

@Component({
  selector: 'app-dashboard-test',
  templateUrl: './dashboard-test.component.html',
  styleUrls: ['./dashboard-test.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardTestComponent implements OnInit, OnDestroy, DoCheck {
  createTestList: CreateNewTest[] = [];
  searchText: string;
  testShow: boolean = false;
  draftStatus = [];
  showEditOption: boolean = false;
  id: any;
  test: boolean;
  testRes: any = {};
  active: boolean;
  running: boolean;
  draft: boolean;
  archive: boolean;
  cancel: boolean;
  alltest: boolean;
  testId: any;
  testId1: any;
  testId2: any;
  finish;
  project;
  search3;
  search4;
  testsProperty = 'none';
  testsShow = false;
  tests
  searchText1
  noDataShowMessage: string;
  projectTestShow: boolean = true;
  allProjectList = [];
  singleIndex: any;
  filterProjectList: any;
  filterTestNodata: boolean = false;
  totaltest: any;
  noTestAvlMessage: String;
  testType: any;
  selectedProjectId: any;
  closeAddExpenseModal: ElementRef;
  totalCredits: any;
  pageReload: boolean = false;
  @ViewChild('closeAddExpenseModal', {static: false})
  private subscription1: Subscription;

  constructor(
    private _createnewTestServices: CreateNewTestService,
    private _router: Router,
    private _route: ActivatedRoute,
    private chRef: ChangeDetectorRef,
    private _authService: AuthService,
    private _modelBoxService: ModelBoxService
  ) {
  }

  // sort array test
  get sortData() {
    return this.draftStatus.sort((a, b) => {
      return <any>new Date(b.creation_date) - <any>new Date(a.creation_date);
    });
  }

  ngOnInit() {
    this.getAllTest();
    this.getALlprojectList();
    this.subscribesChangesInCredits();
    this._route.params.subscribe((parms: Params) => {
      this.testType = parms['type'];
    });

    this.getCreatedTestList();

    // call all API, after redirect form credit-plans-component
    this._route.queryParamMap.subscribe((reqparams: Params) => {
      if (reqparams.params['creditPlansComponent']) {
        this.getAllTest();
        this.getALlprojectList();
        this.subscribesChangesInCredits();
        this.getCreatedTestList();
      }
    });

  }

  triggredOrderTestCall(event) {
    if (event) {
      this.getAllTest();
      if (this.testType == 'project') {
        this.getALlprojectList();
      }
    }
  }

  subscribesChangesInCredits() {
    this._authService.changesInCredits.subscribe((credits) => {
      this.totalCredits = credits;
    });
  }

  showTestID(project_id, i) {
    this.selectedProjectId = project_id;
    this.singleIndex = i;
    this._createnewTestServices
      .getProjectRelatededList(project_id)
      .subscribe((res) => {
        this.filterProjectList = res['data'];
        this.totaltest = res['data'].length;
        if (res['data'].length === 0) {
          this.filterTestNodata = false;
        } else {
          this.filterTestNodata = true;
        }
      });
  }

  ngDoCheck() {
    this.chRef.markForCheck();
  }

  getCreatedTestList() {
    this._route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.test = params['id'] != null;
      localStorage.setItem('testId', this.id);
      let editData = this.createTestList;
      this.testRes = editData.filter((x) => {
        return x.id === this.id;
      });
    });
  }

  getAllTest() {
    this.createTestList = [];
    this.draftStatus = [];

    this.subscription1 = this._createnewTestServices
      .getCreateTest()
      .subscribe((res) => {
        if (res.data.length > 0 && res.status == 200) {
          this.testShow = true;
        } else {
          this.testShow = false;
          this.noDataShowMessage = 'You have not created any test yet!';
        }

        this.createTestList = res.data;
        this.draftStatus = this.createTestList;

        if (this.testType == 'draft') {
          this.statusBasedFilter('draft');
        } else if (this.testType == 'running') {
          this.statusBasedFilter('running');
        } else if (this.testType == 'cancel') {
          this.statusBasedFilter('cancel');
        } else if (this.testType == 'finish') {
          this.statusBasedFilter('finish');
        } else if (this.testType == 'project') {
          this.statusBasedFilter('project');
        } else if (this.testType == 'archive') {
          this.statusBasedFilter('archive');
        } else {
          this.statusBasedFilter("");
        }

        if (res['status'] == 401) {
          this._router.navigate(['login']);
        }
      });

    this.draftStatus = this.createTestList;
  }

  // tests() {
  //   this.testsShow = !this.testsShow;
  //   this.testsProperty = 'block';
  // }

  getALlprojectList() {
    // this._createnewTestServices.createTest
    this.allProjectList = [];
    this._createnewTestServices.gelAllProjectList().subscribe((res) => {
      res.data.filter((res) => {
        if (res.total_test >= 1) {
          this.allProjectList.push(res);
        }
      });

      if (this.testType == 'project') {
        this.showTestID(this.selectedProjectId, this.singleIndex);
      }
    });
  }

  statusBasedFilter(FilterStatus) {
    this.noTestAvlMessage = "";
    this.draftStatus = [];
    this.alltest = false;
    this.project = false;
    this.cancel = false;
    this.running = false;
    this.draft = false;
    this.archive = false;
    this.finish = false;
    this.testsShow = false;
    this.showEditOption = false;

    switch (FilterStatus) {
      case 'running':
        this.running = true;
        this.projectTestShow = true;
        this.draftStatus = [];
        this.draftStatus = this.createTestList.filter((res) => {
          if (res.test_status == 0) {
            return res;
          }
        });
        this.checkAvailibiltyStatusMsg('running');
        break;
      case 'finish':
        this.projectTestShow = true;
        this.finish = true;
        this.draftStatus = this.createTestList.filter((res) => {
          if (res.test_status == 3) {
            return res;
          }
        });

        this.checkAvailibiltyStatusMsg('finished');

        break;
      case 'draft':
        this.draft = true;
        this.projectTestShow = true;
        // this.closeVideoCountTab();
        this.draftStatus = this.createTestList.filter((res) => {
          if (res.test_status == 2) {
            return res;
          }
        });

        this.checkAvailibiltyStatusMsg('draft');
        break;
      case 'archive':
        this.archive = true;
        this.projectTestShow = true;
        this.draftStatus = this.createTestList.filter((res) => {
          if (res.test_status == 5) {
            return res;
          }
        });
        this.checkAvailibiltyStatusMsg('archive');

        break;
      case 'project':
        this.project = true;
        this.projectTestShow = false;
        // this.closeVideoCountTab();
        this.checkAvailibiltyStatusMsg('project');
        break;
      default:
        this.alltest = true;
        this.projectTestShow = true;

        // this.closeVideoCountTab();
        this.draftStatus = this.createTestList.filter((res) => {
          return res;
        });
        this.checkAvailibiltyStatusMsg('created');
        break;
    }
  }

  checkAvailibiltyStatusMsg(msg) {
    if (this.draftStatus.length <= 0) {
      this.noTestAvlMessage = `You don't have any ${msg} tests yet!`;
    }
  }

  ngOnDestroy() {
    // this.subscription1.unsubscribe();
  }
}
