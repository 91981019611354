<div class="tests-container account up-acc-details-container">
  <section class="section account-detials-container " id="all-parent">
    <div class="detail-container tabing">
      <h4>Account Details</h4>
      <p class="acc-personal">Personal information</p>
      <hr class="horizontal_Personal" />
      <div class="detail-sub">
        <form (ngSubmit)="onUpdateProfile()" [formGroup]="updateProfileForm">
          <div class="get-name">
            <div class="">
              <label class="first-name">First name* </label>
              <input
                formControlName="first_name"
                placeholder="First name"
                type="text"
              />
              <div
                *ngIf="
                  (submmited &&
                    updateProfileForm.controls.first_name.errors?.required) ||
                  updateProfileForm.get('first_name').touched
                "
              >
                <div
                  *ngIf="updateProfileForm.controls.first_name.errors?.required"
                >
                  <small class="text-danger">First name is required</small>
                </div>
              </div>
            </div>
            <div class="">
              <label class="last-name">Last name* </label>
              <input
                formControlName="last_name"
                placeholder="Last name"
                type="text"
              />
              <div
                *ngIf="
                  (submmited &&
                    updateProfileForm.controls.last_name.errors?.required) ||
                  updateProfileForm.get('last_name').touched
                "
              >
                <div
                  *ngIf="updateProfileForm.controls.last_name.errors?.required"
                >
                  <small class="text-danger">Last name is required</small>
                </div>
              </div>
            </div>
          </div>

          <div class="" style="margin-top: 15px">
            <!-- <h4>Username</h4> -->
            <label>Username</label>
            <form [formGroup]="usernameForm">
              <input
                class="username_input"
                formControlName="username"
                placeholder="username"
                type="text"
                disabled
              />
              <p class="usname-cannot-change">
                Your Username can’t be changed.
              </p>
            </form>
          </div>
          <!-- <button class="update-btn" type="submit">Save changes</button> -->
          <button class="update-btn " type="submit">
            Save changes
          </button>
        </form>
      </div>

      <!-- <h4>{{isShowOtpBox ? 'Validate Passcode' : 'Email address'}}</h4>
      <div class='detail-sub'>
        <label *ngIf='!isShowOtpBox'>Your email address</label>
        <form *ngIf='!isShowOtpBox' [formGroup]='emailShow'>
          <input formControlName='email' placeholder='name@customer.de' type='email'/>
        </form>
        <div *ngIf="
                  (submmited &&
                    emailShow.controls.email.errors?.required) ||
                  emailShow.get('email').touched
                ">
          <div *ngIf='emailShow.controls.email.errors?.required'>
            <small class='text-danger'>Email is required</small>
          </div>
          <div *ngIf='emailShow.controls.email.errors?.email'>
            <small class='text-danger'>Must be a valid email address</small>
          </div>
        </div>
        <p *ngIf='!isShowOtpBox' class='email-info'>
          The email address you registered at userpeek.com
        </p>
        <p>
          <small class='text-danger'>{{updateEmailMessgaText}}</small>

        </p>
        <form [formGroup]='validatePassCodeForm'>
          <div *ngIf='isShowOtpBox' class='row'>
            <div class='col-md-3'>
              <label>Old email passcode </label>
              <input formControlName='oldPassCode' placeholder='Old Email Passcode' type='text'/>
              <div *ngIf="
              (submmited &&
                validatePassCodeForm.controls.oldPassCode.errors?.required) ||
              validatePassCodeForm.get('oldPassCode').touched
            ">
                <div *ngIf='validatePassCodeForm.controls.oldPassCode.errors?.required'>
                  <small class='text-danger'>OldPassCode is required</small>
                </div>
              </div>
            </div>

            <div class='col-md-3'>
              <label>New email passcode </label>
              <input formControlName='newPassCode' placeholder='New Email Passcode' type='text'/>
              <div *ngIf="
            (submmited &&
              validatePassCodeForm.controls.newPassCode.errors?.required) ||
            validatePassCodeForm.get('newPassCode').touched
          ">
                <div *ngIf='validatePassCodeForm.controls.newPassCode.errors?.required'>
                  <small class='text-danger'>NewPassCode is required</small>
                </div>
              </div>
            </div>

          </div>
        </form>

        <button (click)='validateUpdateEmailOTP()' *ngIf='isShowOtpBox' class='update-btn' type='button'>
          Validate Passcode
        </button>
        <button (click)='changeEmail()' *ngIf='!isShowOtpBox' class='update-btn' type='button'>
          Update email address
        </button>
      </div> -->

      <p class="acc-login-data">Login Data</p>
      <hr class="horizontal_login-data" />

      <div class="detail-sub">
        <form>
          <div class="get-mail-pwd">
            <div class="">
              <label>Email*</label>
              <form [formGroup]="emailShow">
                <div class="email-change">
                  <input
                    formControlName="email"
                    placeholder="name@customer.de"
                    type="email"
                  />
                </div>
              </form>
            </div>
            <div class="">
              <label>Password </label>
              <div class="up-change-password">
                <a
                  class="up-change-password-link"
                  href="{{ urlReset }}"
                  target="_blank"
                  >Change password</a
                >
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="detail-sub">
        <!-- <h4>{{isShowOtpBox2 ? 'Enter OTP' : ''}}</h4> -->

        <!-- <div class="row" *ngIf='!isShowOtpBox2'>
          <div class="col-md-6">
            <label>Your email address</label>
            <form [formGroup]='emailShow'>
              <input formControlName='email' placeholder='name@customer.de' type='email'/>
            </form>
          </div>
        </div> -->

        <div
          *ngIf="
            (submmited && emailShow.controls.email.errors?.required) ||
            emailShow.get('email').touched
          "
        >
          <div *ngIf="emailShow.controls.email.errors?.required">
            <small class="text-danger">Email is required</small>
          </div>
          <div *ngIf="emailShow.controls.email.errors?.email">
            <small class="text-danger">Must be a valid email address</small>
          </div>
        </div>

        <!-- <p *ngIf='!isShowOtpBox2' class='email-info'>
          The email address you registered at userpeek.com
        </p> -->

        <p>
          <small class="text-danger">{{ updateEmailMessgaText }}</small>
        </p>

        <form [formGroup]="validateOTPForm">
          <div *ngIf="isShowOtpBox2" class="row">
            <div class="col-md-3">
              <label class="verification-code">Verification code</label>
              <div class="enter-verification-change">
                <input  formControlName="otp" placeholder="" type="text" />
              </div>
              <div
                *ngIf="
                  (submmited &&
                    validateOTPForm.controls.otp.errors?.required) ||
                  validateOTPForm.get('otp').touched
                "
              >
                <div *ngIf="validateOTPForm.controls.otp.errors?.required">
                  <small class="text-danger"
                    >Verification code is required</small
                  >
                </div>
              </div>
            </div>

            <!-- <div class='col-md-3'>
              <label>New email passcode </label>
              <input formControlName='newPassCode' placeholder='New Email Passcode' type='text'/>
              <div *ngIf="
            (submmited &&
              validatePassCodeForm.controls.newPassCode.errors?.required) ||
            validatePassCodeForm.get('newPassCode').touched
          ">
                <div *ngIf='validatePassCodeForm.controls.newPassCode.errors?.required'>
                  <small class='text-danger'>NewPassCode is required</small>
                </div>
              </div>
            </div> -->
          </div>
        </form>

        <button
          (click)="validateOTP2()"
          *ngIf="isShowOtpBox2"
          class="update-btn"
          type="button"
        >
          Verify code
        </button>

        <!-- <button (click)='changeEmail()' *ngIf='!isShowOtpBox2' class='update-btn' type='button'>
          Update email address
        </button> -->
        <p
          (click)="changeEmail()"
          *ngIf="!isShowOtpBox2"
          class="update-btn update-email-address"
          type="button"
        >
          Update email address
        </p>
      </div>

      <!-- <h4>Password</h4>
      <a class='password' href='{{ urlReset }}' target='_blank'>
      <button type="button" class="btn p-2 btn_style text-white"> <span class="p-2">Change password</span> </button></a> -->
    </div>
  </section>
  <!-- <button class="feedback-btn">Feedback</button> -->
</div>
