import {Injectable} from '@angular/core';
import {HttpClient,} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from 'src/environments/environment';

import {TokenParms} from '../../core/models/token-parms';
import {User} from '../../core/models/user';
import {appApiResources} from './api.constant';
import {BaseService} from '../../core/api-call/base.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  AccessToken: String = "";
  accessData: any;
  formData: any;
  userRoleType = ['tester', 'client'];
  url = environment.apiUrl + 'client/password_reset/';
  usertype = new Subject<any>();
  role = this.usertype.asObservable();
  username = new Subject<any>();
  public changesInCredits = new BehaviorSubject<any>(0);
  public couponAvlData = new Subject<any>();
  private messageSource = new Subject();
  currentMessage = this.messageSource.asObservable();
  private currentUserSubject = new BehaviorSubject<any>(null);

  constructor(public http: HttpClient) {
    super(http);
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  changeMessage(currentMessage) {
    this.messageSource.next(currentMessage);
  }

  selectUserType(role) {
    this.usertype.next(role);
  }

  getLoadingIndicator() {
    return this.role;
  }

  checkEmailExists(email) {
    return this.get(appApiResources.checkEmailExist, {email: email});
  }

  verifyCLientEmail(oldEmail, newEmail) {
    return this.get(appApiResources.userVerifyEmail, {old_email: oldEmail, new_email: newEmail});
  }

  validateUpdateOTP(old_passcode, new_passcode, oldEmail, newEmail) {
    return this.get(appApiResources.updateEmail, {
      old_email_pass_code: old_passcode,
      new_email_pass_code: new_passcode,
      old_email: oldEmail,
      new_email: newEmail
    });
  }
  validateOTP2(body){
    return this.post(appApiResources.validateOTP2, body);
  }

  setToken(token: string) {
    return localStorage.setItem('token', token);
  }

  forgetPassword(): Observable<any> {
    return this.post(appApiResources.passwordReset, TokenParms);
  }

  logout() {
    return this.get(appApiResources.userLogout);
  }

  getClientProfile(): Observable<any> {
    return this.get(appApiResources.createTesterProfile,);
  }

  updateClientProfile(profileData) {
    return this.put(appApiResources.createTesterProfile, profileData);
  }

  loggedIn() {
    return !!localStorage.getItem('token_id');
  }

  changeEmail(body){
    return this.post(appApiResources.changeEmail, body);
  }
}
