import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';


import {VideosRoutingModule} from './videos-routing.module';
import {VideosInfoComponent} from './videos-info/videos-info.component';
import {RatingModule} from 'ng-starrating';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NgxMaskModule} from 'ngx-mask'
import {ClipTimesShowPipe} from 'src/app/shared/pipes/clip-times-show.pipe';
import {ExampleVideoComponent} from './example-video/example-video.component';
import {SharedModule} from 'src/app/shared/shared.module';
import {NotesClipBoxComponent} from './notes-clip-box/notes-clip-box.component';
import {VideoPlayerComponent} from './video-player/video-player.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTabsModule} from '@angular/material/tabs';
import {InfoTabComponent} from './example-video/info-tab/info-tab.component';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {TaskdetailsTabComponent} from './example-video/taskdetails-tab/taskdetails-tab.component';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {NotesandclipsTabComponent} from './example-video/notesandclips-tab/notesandclips-tab.component';
import {VgBufferingModule} from "@videogular/ngx-videogular/buffering";
import {VgControlsModule} from "@videogular/ngx-videogular/controls";
import {VgCoreModule} from "@videogular/ngx-videogular/core";
import {VgOverlayPlayModule} from "@videogular/ngx-videogular/overlay-play";
import {MdePopoverModule} from "@material-extended/mde";
import { TranscriptComponent } from './example-video/transcript/transcript.component';
import { NotesAndClipsComponent } from './notes-and-clips/notes-and-clips.component';
import { VideoInfoTabComponent } from './video-info-tab/video-info-tab.component';


@NgModule({
  declarations: [
    VideosInfoComponent,
    ClipTimesShowPipe,
    ExampleVideoComponent,
    NotesClipBoxComponent,
    VideoPlayerComponent,
    InfoTabComponent,
    TaskdetailsTabComponent,
    NotesandclipsTabComponent,
    TranscriptComponent,
    NotesAndClipsComponent,
    VideoInfoTabComponent
  ],
  imports: [
    CommonModule,
    VideosRoutingModule,
    VgBufferingModule,
    VgOverlayPlayModule,
    VgCoreModule,
    VgControlsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    SharedModule,
    RatingModule,
    NgxMaskModule.forRoot({showMaskTyped: true}),
    MatSidenavModule,
    MatTabsModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatIconModule,
    MdePopoverModule,

  ],
})
export class VideosModule {
}
