import {Component, NgZone} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BnNgIdleService} from 'bn-ng-idle';
import Swal from 'sweetalert2';
import {environment} from "../environments/environment";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  version = environment.appVersion;
  login: boolean = false;
  showHead: boolean;
  sample: any;

  constructor(
    private zone: NgZone,
    private router: Router,
    private bnIdle: BnNgIdleService
  ) {

    this.bnIdle.startWatching(20000).subscribe(res => {
      if (res) {
        this.router.navigate(['/login']);
        Swal.fire({
          title: 'Your session has expired!',
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        })
        // alert('session exipred');

        localStorage.removeItem('token_id');
        localStorage.clear();
      }
    });

    // router.events.forEach((event) => {

    //   // console.log(event['url'])
    //   if (event instanceof NavigationEnd) {
    //     if (true) {
    //       this.showHead = false;

    //     } else {
    //       // console.log('NU')
    //       this.showHead = true;
    //     }
    //   }
    // });

    router.events.forEach((event) => {

      if (event instanceof NavigationEnd) {
        // console.log("URL ::: ",(event['url']));
        let conditions = /^\/$/.test(event['url']) || /\/login/.test(event['url']) ||  /\/register/.test(event['url']) ||  /\/register\/complete-your-profile/.test(event['url']) || /\/activate.*$/i.test(event['url']);
        // console.log("REGEX :::  ",conditions);
        if (conditions){
          // console.log("isnide if block ");
          this.showHead = false;
        } else {
          // console.log("isnide else block ");
          // console.log('NU')
          this.showHead = true;
        }
      }
    });



  }



}
