import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators,} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import {User} from 'src/app/core/models/user';
import {AuthService} from 'src/app/shared/services/auth.service';
import {PasswordStrengthValidator} from 'src/app/constants/validators';
import Swal from 'sweetalert2';
import {UserService} from '../../../shared/services/user.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Userv2Service } from 'src/app/shared/services/userv2.service';
import { appApiResources } from 'src/app/shared/services/api.constant';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, AfterViewInit {
  regForm: FormGroup;
  massage: string;
  registerResMsg: string;
  userRoleType;
  errMsg = [];
  Error = false;
  submitted: boolean = false;
  baseUrl = environment.apiUrl;
  passwordIsValid = false;
  @ViewChild(FormGroupDirective, {static: false})
  formGroupDirective: FormGroupDirective;
  registerResEmailMsg: any;
  hideSuccessMessage: boolean = false;
  urlType: any;
  // clientSigup = false;
  fieldTextType: boolean;
  isCompleteProfileStep: boolean = false;
  emailExistsValue: any;
  // username validate on type
  usernameExistsStatus: boolean = false;
  // email validation on type
  emailExistsStatus: boolean = false;
  gmailKeywordCheck: boolean = false;
  personalEmailCheck: boolean = false;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private _router: Router,
    private _userv2: Userv2Service
  ) {
  }

  ngAfterViewInit() {
    this.regForm.get('username').valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((value) => {

        let options ={
          headers:{
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify({"username": value.toString()})

        }
          
        async function checkValidUsername(){
          const response = await fetch(appApiResources.validateUsername, options);
          const result = await response.json();
          return result;
        }

        checkValidUsername().then((res)=>{
          console.log(res);
          if(res["message"] == "True"){
            this.usernameExistsStatus = true;
          } else{
            this.usernameExistsStatus = false; 
          }
        })
    });

    this.regForm.get('email').valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((value) => {

        let options ={
          method: "GET",
        }

        let url = new URL(appApiResources.checkEmailExist);
        url.searchParams.set("email",value.toString())
        url.searchParams.set("user_type","client")

        async function checkValidUsername(){
          const response = await fetch(url.toString(), options);
          const result = await response.json();
          return result;
        }

        checkValidUsername().then((res)=>{
          console.log(res);
          if(res["message"] === "Please use your personal business email!"){
            this.personalEmailCheck = true;
          }else{
            this.personalEmailCheck = false;
            if(res["message"] == true){
              this.emailExistsStatus = true;
            } else{
              this.emailExistsStatus = false; 
            }
          }
        })

        // check @gmail.com spelling correction
        let gmailKeywordValue = value.split('@');
        if( gmailKeywordValue[1] == 'gmai.com' || gmailKeywordValue[1] == 'gail.com' ||
            gmailKeywordValue[1] == 'gamil.com' || gmailKeywordValue[1] == 'gmal.com'){
          this.gmailKeywordCheck = true;
        }else {
          this.gmailKeywordCheck = false;
        }

    });




  }

  get f() {
    return this.regForm.controls;
  }

  ngOnInit() {
    this.route.url.subscribe((url) => {
      this.urlType = url;
      // (this.urlType = url[0].path)
    });
    // if (this.urlType === 'client') {
    //   this.clientSigup = true;
    // } else {
    //   this.clientSigup = false;
    // }
    this.setRegFormState();
    this.userRoleType = this.authService.userRoleType;
    // this.authService.role.subscribe((role) => {});
  }


  passwordValid(event) {
    this.passwordIsValid = event;
  }
  
  checkRegisterStepStatus() {
    this.isCompleteProfileStep = true;
    // if(this.urlType[1] && this.urlType[1].path=='complete-your-profile' && localStorage.getItem('isEmailExists') ){
      //   this.isCompleteProfileStep = true;
      // }else{
        //   this._router.navigate(['register'])
        // }
      }
      
      toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
      }
      
      setRegFormState(): void {
        this.regForm = this.formbuilder.group({
          username:["",
          [
            Validators.required,
          ]
        ],
          email: [
            "",
            [
              Validators.required,
              Validators.email,
              Validators.pattern('^[A-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
            ],
          ],
          password: [
            "",
            Validators.compose([
              Validators.required,
              Validators.minLength(8),
              // Validators.maxLength(40),
              // Validators.pattern('^(?!.*[-_+=?><~!])(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*])(.{6,})$'),
              PasswordStrengthValidator,
            ]),
          ],
      roletype: ['client'],
      checkMarks: ["", Validators.requiredTrue],
    });
  }
  
  onSignUp() {
    let that = this;
    if (!window.navigator.onLine) {
      Swal.fire({
        text: 'Server is not responding',
      });
    } else {
      this.submitted = true;
      if (that.regForm.invalid) {
        return;
      }
      
      this.submitted = false;
        if (this.emailExistsStatus == false) {
          // localStorage.setItem('isEmailExists','true')
          if (!this.usernameExistsStatus){
            this.checkRegisterStepStatus();
          } else {
            Swal.fire(
                {
                  text: 'Username already exists. Please try another one.',
                  background: '#f9f9f9',
                  confirmButtonColor: '#1200FF',
                }
              // succ.message
              );
          }
          // this._router.navigate(['register/complete-your-profile']);
        } else {
          Swal.fire(
              {
                text: 'A user with same email address already exists. ',
                background: '#f9f9f9',
                confirmButtonColor: '#1200FF',
              }
            // succ.message
            );
          }
      }
    }
    
    reg(reg: User) {
      this.userService.register(reg).subscribe(
        (employeeUser) => {
          var succ = employeeUser;
          if (succ.message.email) {
            this.registerResEmailMsg = employeeUser.message.email;
            
            Swal.fire({
              text: succ.message.email,
            });
            
          } else {
            this.registerResMsg = employeeUser.message;
            
            Swal.fire({
              text: succ.message,
            });
          }
          
          if (succ) {
            this.regForm.reset();
            localStorage.setItem('reg', JSON.stringify(succ));
          } else {
            this.Error = true;
            this.massage = 'User ID/Password Wrong';
          }
        },
        (err) => {
          this.errMsg = err;
      }
      );
    }


  }
  