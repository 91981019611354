<app-breadcrumbs-navbar></app-breadcrumbs-navbar>
<div class="videos-dash-container">
    <div class="videos-dash-content">
        <div class="videos-dash-header">
            <h2>Videos</h2>
            <!-- <div class="videos-dash-header-btns">
                <button (click)="createvideos()" class="btn-sty-blue">
                    <img  src="assets/images/icons/plus.svg" alt="plus icon"> Create videos
                </button>
                <a class="btn-sty-blue-outline" href="javascript: void();">View templates</a>
            </div> -->
        </div>
        <div class="videos-dash-menu">
            <div class="videos-dash-menu-text">Get productive.</div>
            <div>
                <app-all-filters></app-all-filters>
            </div>
            <!-- <div class="videos-dash-menu-icons">
                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'all' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                (click)="applyButtonFilterFun('all')">All videos ({{unfilteredJsonData.length}})</button>
                
                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'newVideos' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                (click)="applyButtonFilterFun('newVideos')">New Videos (0)</button>

                <app-multi-filter-menu (filterQueryValues)="invokeMultiFilterSearch($event)"></app-multi-filter-menu>
            </div> -->
        </div>

        <!-- Table -->
        <div class="videos-dash-table-container">

            <table class="videos-dash-table">
                
                <tr class="title-row">
                    <th class="video-thumbnail-title">Video</th>
                    <th class="tester-title">Tester</th>
                    <th class="test-name-title">Test name</th>
                    <th class="hashtag-title">Hashtags</th>
                    <th class="device-title">Device</th>
                    <th class="date-title">Date</th>
                    <!-- <th class="action-title">Action</th> -->
                </tr>
                <ng-container *ngIf="isDataAvailable">
                    <tr class="data-row" *ngFor="let videoItemDetials of filteredJsonData; index as i">
                        <td class="video-thumbnail-data" >
                            <div (click)="viewSelectedVideo(videoItemDetials)" class="video-thumbnail-cont" [ngStyle]="{'background-image': 'url(' + baseUrl+videoItemDetials.thumbnail_image + ')'}">
                                <p class="video-thumbnail-timer">{{videoItemDetials.video_total_time}}</p>
                            </div>
                        </td>
                        <td class="tester-data">
                            <div class="tester-data-cont">
                                <h6>{{videoItemDetials.tester_name}}</h6>
                                <p>{{videoItemDetials.age}} &#8226; {{videoItemDetials.gender}} &#8226; {{videoItemDetials.country}}</p>
                            </div>
                        </td>



                        
                        <td class="test-name-data">
                            <!-- <a (click)="storeSelectedVideoDetials(videoItemDetials)">{{videoItemDetials.test_name}}</a> -->
                            <a (click)="viewSelectedVideo(videoItemDetials)">
                                {{videoItemDetials.test_name}}
                                <!-- | {{videoItemDetials.id}} -->
                            </a>
                        </td>
                        <td class="hashtag-data">
                            <div class="hashtag-data-cont">
                                <!-- hashtag -->
                                <ng-container *ngFor="let hashtagItem of hashtagLimit(videoItemDetials.hastags)">
                                    <span (click)="sendHashtagToAllFilterComponent(hashtagItem, 'hashtag')">{{hashtagItem}}</span>
                                </ng-container>
                                <!-- hashtag btn -->
                                <span (click)="showHashtagPopup($event)" class="hashtag-count-btn" *ngIf="hashtagRemainingCount(videoItemDetials.hastags)">
                                    +{{ hashtagRemainingCount(videoItemDetials.hastags) }}
                                </span>
                                <!-- hashtag popup -->
                                <div class="hashtagPopupContainer" #hashtagPopupContainer>
                                    <div class="hashtagPopupContent">
                                        <h2>Hashtags linked to video</h2>
                                        <img (click)="closeHashtagPopup($event)"  src="assets/images/icons/close-icon.svg" alt="icon">
                                        <p>Click on a hashtag to filter videos:</p>
                                         <div class="">
                                             <span  *ngFor="let hashtagItem of videoItemDetials.hastags" (click)="sendHashtagToAllFilterComponent(hashtagItem, 'hashtag'); hashtagPopupContainer.style.display = 'none'">{{hashtagItem}}</span>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="device-data">
                            <div class="device-data-cont">
                                <ng-container *ngIf="videoItemDetials.device_type == 'desktop'">
                                    <img src="assets/images/icons/monitor.svg" alt="icon">
                                    <span>Desktop</span> 
                                </ng-container>
                                <ng-container *ngIf="videoItemDetials.device_type == 'mobile'">
                                    <img src="assets/images/icons/smartphone.svg" alt="icon">
                                    <span>Mobile</span> 
                                </ng-container>
                                <ng-container *ngIf="videoItemDetials.device_type == 'tablet'">
                                    <img src="assets/images/icons/tablet.svg" alt="icon">
                                    <span>Tablet</span> 
                                </ng-container>
                            </div>
                        </td>   
                        <td class="date-data">{{videoItemDetials.date_of_tested | date}}</td>
                        <!-- <td class="action-data"> 
                            <div class="dash-context-menu-wrapper">
                                <img (click)="showContextMenu($event)" class="dash-context-menu-icon" src="assets/images/icons/menu-icon-new.svg" alt="icon">

                                <div class="dash-context-menu-cont">
                                    <div class="dash-context-menu-content">
                                        <button class="dash-context-menu-edit">Edit</button>
                                        <button  class="dash-context-menu-archive">Archive</button>
                                        <button  class="dash-context-menu-delete">Delete</button>
                                    </div>
                                </div>
                            </div>             
                        </td> -->
                    </tr>
                </ng-container>

            </table>

            <div *ngIf="isDataAvailable" class="pagination-cont">
                <p class="pagination-txt">{{paginateCount}} / {{queriedJsonData.length}} videos</p>
                <p class="pagination-btn" (click)="incrementPaginationCountFun(paginateCount + 10)">Load more</p>
            </div>


        </div>

        <div class="empty-data-cont" *ngIf="!isDataAvailable">
            <div class="empty-data-icon" style="margin-bottom: 25px;">
                <img src="assets/images/icons/cube-new-icon.svg" alt="cube icon">
            </div>

            <div class="empty-data-text" style="margin-bottom: 35px;">
                <p>You don't have any videos yet. </p>
                <!-- <p>Let's create a videos to get started.</p> -->
            </div>

            <!-- <div class="empty-data-btn" style="margin-bottom: 35px;">
                <button class="btn-sty-blue">
                    <img src="assets/images/icons/plus.svg" alt="plus icon"> Create videos
                </button>
            </div> -->

            <!-- <div class="empty-data-link">
                or <a class="a-sty" href="javascript: void();">view templates</a>
            </div> -->
        </div>

    </div>

</div>
<script src='assets/js/custom.js'></script>