import { Component, OnDestroy, OnInit, AfterViewInit, NgModule, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CreateNewTestService } from 'src/app/shared/services/create-new-test.service';
import { ActivatedRoute, Params } from '@angular/router';
import { ModelBoxService } from 'src/app/shared/services/model-box.service';
import { appGlobalModelMessage } from 'src/app/shared/services/api.constant';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { Socket } from 'dgram';

@Component({
  selector: 'app-create-tests',
  templateUrl: './create-tests.component.html',
  styleUrls: ['./create-tests.component.scss'],
})
export class CreateTestsComponent implements OnInit, OnDestroy, AfterViewInit {
  TestInstructions: Boolean = true;
  createNewTests: FormGroup;
  audienceData: FormGroup;
  templateShow =
    'Please remember to think out loud during your test and tell us if there’s any information missing. You can input fake information if you need to sign up or anything.  ###TASK###  Take a look at this site and tell us what you think it is. (You can scroll if you want but please don’t click on anything yet.)  -- What’s the first thing you notice? -- What can you do on this site? -- What products or services are offered on this site? -- Who is this site intended for?  ###TASK###  Now imagine the products or services offered on this site are intended for you and go ahead to do whatever you think you’re supposed to do on this site (e.g. buy a product, make a booking, create an account, etc.).  Thank you for your help! ';
  existingProjectToggle = false;
  newTestData = {};
  audienceData1 = {};
  showField: boolean = true;
  isActive = false;
  st;
  // templates = ['Default', 'E-Commerce', 'Booking', 'Saas', 'Corporate'];

  language = ['English', 'German'];
  audience = [
    'Fastest results',
    'Choose specific demographics',
    'Get certified UX and User Research Experts to take the test',
  ];
  showRadio: boolean = false;
  testerAge = ['All', '18-24', '25-34', '35-50', '51 and Other'];
  testerGender = ['All', 'Female', 'Male', 'Other'];
  testerRegion = [
    'All',
    // 'india',
    'North America',
    'South America',
    'Asia',
    'Australia',
    'Africa',
    'Europe',
    'Antarctic',
  ];
  person = 'All';
  submitted: boolean = false;
  temValue: any;
  mystyle1: string = 'none';
  allProjectList = [];
  templates: string[];
  templateData: any;
  selectedInstructionType: any;
  editCreatedTest: any[] = [];
  taskTemplatesList: any;
  projectsJsonData: any = [];
  testNameAlreadyExists: boolean = false;

  errorFields: any[] = []

  totalSortedClipsCount = 0;
  totalSortedClipsCountDisplay: any = "00:00";
  displayTotalDuration: any = "00:00";
  sortClips = [];
  taskCount: any = 0;
  isTooltipVisible = false;

  countries: string[] = [
    'All', 'Unites States', 'Canada', 'United Kingdom', 'Australia',
    'France', 'Germany', 'Brazil',
  ];
  selectedCountries: string[] = [];
  isDropdownOpen = false;
  showMore = false; // Controls visibility of extra countries
  isFocused = false;
  parentWidth: string = ''
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;



  constructor(
    private fb: FormBuilder,
    private _createTestServices: CreateNewTestService,
    private _aRoutes: ActivatedRoute,
    private _modelBoxService: ModelBoxService,
    private _router: Router,
    private toastMsgService: ToastMsgService,
    private renderer: Renderer2,
  ) {
    this.initCreateTestForm();
    this._aRoutes.queryParams.subscribe(params => {
      const currentPage = params['currentPage'];
      if (currentPage === 'page1') {
        this.TestInstructions = true;
      } else if (currentPage === 'page2') {
        this.TestInstructions = false;
      }
    });


  }



  setFocus(focus: boolean) {

    this.isFocused = focus;
  }


  ngAfterViewInit() {
    this.createNewTests.get("test_name").valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((value) => {
      let auth_token = localStorage.getItem("token_id");
      let options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${auth_token}`
        },
        method: "POST",
        body: JSON.stringify({ "test_name": value.toString() })
      }

      async function checkTestNameAlreadyExists() {
        const response = await fetch(`${environment.apiUrl}test/is_test_exists/`, options);
        const result = await response.json();
        return result;
      }

      checkTestNameAlreadyExists().then((apiResponse) => {
        if (apiResponse.message.toLowerCase() === "test already exists.") {
          this.testNameAlreadyExists = true;
        } else {
          this.testNameAlreadyExists = false;
        }
      })
    });
    // if (this.toggleButton && this.menu) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        this.isDropdownOpen &&
        this.toggleButton &&
        this.menu &&
        !this.toggleButton.nativeElement.contains(e.target) &&
        !this.menu.nativeElement.contains(e.target)
      ) {
        this.isFocused = false;
        this.isTooltipVisible=false
        this.isDropdownOpen = false;
      }
    });
    // }

  }

  ngOnInit() {
    this._router.navigate([], { queryParams: { "currentPage": "page1" }, queryParamsHandling: 'merge', });
    this.getSelectedTestDeatils();
    this.audienceData = this.fb.group({
      participants_audience: [""],
    });
    this.getProjectList();
    this.getTaskTemplates();

    // view template section directly
    setTimeout(() => {
      this._aRoutes.queryParams.subscribe((params) => {
        if (params['show-templates'] != undefined) {
          document.getElementById("openTemplateSection").click();
        }
      });

      // pre-select existing project while navigate from projects page
      this._aRoutes.queryParams.subscribe((params) => {
        if (params['pre-selected-project'] != undefined) {
          this.existingProjectToggle = true;
          if (this.createNewTests.value.project_name == "") {
            this._createTestServices.gelAllProjectList().subscribe((res) => {
              res.data.forEach((projectItem) => {
                if (Number(projectItem['id']) === Number(params['pre-selected-project'])) {
                  this.createNewTests.patchValue({ project_name: projectItem['project_name'] });
                }
              });
            });
          }
        }
      });

    }, 500);

  }
  get testInstructions(): FormArray {
    return this.createNewTests.get('test_instructions') as FormArray;
  }


  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;


  }

  drop(event: CdkDragDrop<FormArray>): void {
    const formArray = this.testInstructions;
    const item = formArray.at(event.previousIndex);
    formArray.removeAt(event.previousIndex);
    formArray.insert(event.currentIndex, item);
  }



  getTaskTemplates() {
    this._createTestServices.templateManagement().subscribe((res: any) => {
      this.taskTemplatesList = res.data;
    });
  }


  handleTemplateChange(event) {
    let selectedTemplateData;
    let selectedTemplateName = event;

    if (selectedTemplateName === "empty") {
      this.createNewTests.patchValue({ test_introduction: "" });

      // Remove all element from test_instructions array
      while (this.createNewTests.get('test_instructions')['length'] !== 0) {
        (<FormArray>this.createNewTests.get('test_instructions')).removeAt(0);
      }
    }
    else {
      this.taskTemplatesList.forEach((item) => {
        if (item.title === selectedTemplateName) {
          selectedTemplateData = item;
        }
      });

      // Remove all element from test_instructions array
      while (this.createNewTests.get('test_instructions')['length'] !== 0) {
        (<FormArray>this.createNewTests.get('test_instructions')).removeAt(0);
      }

      this.createNewTests.patchValue({ test_introduction: selectedTemplateData.scenario });

      selectedTemplateData.tasks.forEach((text) => {
        const control = new FormControl(text, Validators.required);
        (<FormArray>this.createNewTests.get('test_instructions')).push(
          control
        );
      });
    }
  }




  // Toggles the dropdown visibility
  // Track the visibility of the tooltip

  // Toggle tooltip visibility when the info circle is clicked
  toggleTooltip() {
    this.isTooltipVisible = !this.isTooltipVisible;
  }

  // Close the tooltip when the "X" icon is clicked
  closeTooltip(event: MouseEvent) {
    event.stopPropagation(); // Prevent the click from propagating to the dropdown
    this.isTooltipVisible = false; // Set tooltip visibility to false
  }

  // Adds or removes the country based on the checkbox state
  onCountryToggle(country: string, isChecked: boolean) {
    if(this.isTooltipVisible == true){
      this.isTooltipVisible=false
    }
    if (country == 'All') {
      if (isChecked) {
        this.selectedCountries = [...this.countries.slice(1)]; 
      } else {
        this.selectedCountries = []; 
      }
    }
   else if (country != 'All') {
    if(isChecked){
      this.selectedCountries.push(country);
    } else {
      this.selectedCountries = this.selectedCountries.filter(item => item !== country);
    }
    }
  }

  // Returns a string representation of selected countries
  getSelectedCountriesDisplay(): string {
    return this.selectedCountries.length ? this.selectedCountries.join(', ') : null;
  }



  getSelectedTestDeatils() {
    this._aRoutes.params.subscribe((params: Params) => {
      let selectedId: any = +params['id'];
      // this.editCreatedTest = params['id'] != null;
      localStorage.setItem('testId', selectedId);
      this._createTestServices.getCreateTest().subscribe((getRes) => {
        if (getRes.status == 200) {
          let editData = getRes.data;
          this.editCreatedTest = editData.filter((x) => {
            if (x.id === selectedId) {
              return x;
            }
          });
        }
        // @note edit test
        if (this.editCreatedTest.length > 0) {
          let selectDetails = this.editCreatedTest[0];
          let articipants_audience = {
            articipants_type:
              selectDetails.articipants_audience.articipants_type,
            gender: selectDetails.articipants_audience.gender,
            region: selectDetails.articipants_audience.region,
            age: selectDetails.articipants_audience.age,
            device_type: selectDetails.articipants_audience.device_type,
          };
          let setSelectedValue = {
            test_name: selectDetails.test_name,
            project_name: selectDetails.project_name,
            start_url: selectDetails.start_url,
            project_flag: true,
            language: selectDetails.language,
            is_app: selectDetails.is_app,
            template_name: selectDetails.template_name,
            articipants_audience,
            test_introduction: selectDetails.test_introduction
          };

          this.createNewTests.patchValue(setSelectedValue);

          selectDetails.test_instructions.forEach((text) => {
            const control = new FormControl(text, Validators.required);
            (<FormArray>this.createNewTests.get('test_instructions')).push(
              control
            );
          });
          this.selectedInstructionType = this.editCreatedTest[0].template_name;
          // this.templateManagement();
        } else {
          // this.templateManagement(true);
        }

        // this.templateShow = this.editCreatedTest[0].template_name;
        // this.testNameView = this.createNewTests.value.test_name;
        this.createNewTests.value.test_instructions;
      });
    });
  }

  // @note form data
  initCreateTestForm() {
    this.createNewTests = this.fb.group({
      test_name: ["", Validators.required],
      project_name: ["", Validators.required],
      project_flag: [false],
      start_url: [
        "",
        [
          Validators.required,
        ],
      ],
      language: ["", Validators.required],
      // url_type:[""],
      is_app: [false],
      // articipants_audience: [""],
      test_introduction: ["", Validators.required],
      test_instructions: new FormArray([]),
      template_name: ['Default'],
      meta: 1, // for refering the test type from test_metatest (user / app / AB TEST ) an&

      articipants_audience: this.fb.group({
        articipants_type: ['Choose specific demographics'],
        gender: ['All', Validators.required],
        region: ['All', Validators.required],
        age: ['All', Validators.required],
        device_type: ['Desktop', Validators.required], //all
      }),
    });

    // '(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})'
  }

  printlog() {
    console.log('value changed', this.createNewTests.value.articipants_audience.age);
  }

  getProjectList() {
    this._createTestServices.gelAllProjectList().subscribe((res) => {

      let newFormulalist = res.data.filter(
        (v, i) =>
          res.data.findIndex((item) => item.project_name == v.project_name) ===
          i
      );
      newFormulalist.forEach((res) => {
        this.allProjectList.push(res.project_name);
      });
    });
  }

  onMyCustomEvent() {
    this.showRadio = false;
  }

  templateManagement(isEditMode?) {
    this._createTestServices.templateManagement().subscribe((res) => {
      let tempName = [];
      for (let i in res['data']) {
        tempName.push(i);
      }
      this.selectedInstructionType = tempName[1];
      this.templateData = res['data'];
      this.templates = Object.keys(this.templateData);

      if (isEditMode) {
        this.templateData[this.selectedInstructionType].forEach((text) => {
          const control = new FormControl(text, Validators.required);
          (<FormArray>this.createNewTests.get('test_instructions')).push(
            control
          );
        });
      }
    });
  }


  // @note max 15 task | add task
  onAddTests() {
    this.taskCount++;
    if (this.createNewTests.get('test_instructions')['length'] < 15) {
      const control = new FormControl(null, Validators.required);
      (<FormArray>this.createNewTests.get('test_instructions')).push(control);
    } else {

      Swal.fire({
        title: 'You reached the maximum number of tasks!',
        icon: 'warning',
        confirmButtonColor: '#1200ff',
        confirmButtonText: 'Ok',
      })

      // this._modelBoxService.showModelBox(
      //   false,
      //   appGlobalModelMessage.maximumTaskErrorMsg
      // );
    }
  }

  // @note remove task
  removeTest(indexoftest): void {
    (<FormArray>this.createNewTests.get('test_instructions')).removeAt(indexoftest);
    // Swal.fire({
    //   title: 'Delete Task?',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#1200ff',
    //   cancelButtonColor: '#5e5e5e',
    //   confirmButtonText: 'Delete',
    // }).then((result) => {
    //   if(result.value){
    //     (<FormArray>this.createNewTests.get('test_instructions')).removeAt(indexoftest);
    //   }
    // })

  }

  copyToClipboard(id) {
    // console.log('cwdqwdqWDEQWE::', content);
    var ele = document.getElementById(`test_instructions_${id}`) as HTMLTextAreaElement;
    ele.select();
    document.execCommand("copy");
    this.toastMsgService.showMsg("success", "Content copied", 2000);
    // console.log('sdfsafddfasdd  id :: ', id );
    // console.log('sdfsafddfasdd  id string :: ', `test_instructions_${id}` );
    // console.log('sdfsafddfasdd :: ', ele );
  }

  addStyle() {
    this.mystyle1 = 'block';
  }

  hideStyle() {
    this.mystyle1 = 'none';
  }

  changeTestInstructionType(testInstructionType) {
    this.selectedInstructionType = testInstructionType;

    // Remove all element from test_instructions array
    while (this.createNewTests.get('test_instructions')['length'] !== 0) {
      (<FormArray>this.createNewTests.get('test_instructions')).removeAt(0);
    }

    // Add value of selected test_instructions type in test_instructions array
    this.templateData[testInstructionType].forEach((text) => {
      let control = new FormControl(text, Validators.required);
      (<FormArray>this.createNewTests.get('test_instructions')).push(control);
    });
  }

  BindData() {
    this.showField = true;
  }


  createTests() {
    this.submitted = true;
    if (this.TestInstructions) {
      if (
        this.TestInstructions &&
        this.createNewTests.value['test_name'].trim() &&
        this.createNewTests.value['project_name'].trim() &&
        this.createNewTests.value['start_url'].trim() &&
        this.createNewTests.value['language'].trim() &&
        this.createNewTests.get('test_instructions')['length'] !== 0
      ) {
        this.TestInstructions = !this.TestInstructions;
        // navigate step 1 logic on create test page
        this.changeQueryParamsPageNavigation();
      } else {
        this.toastMsgService.showMsg("failed", "Please fill all fields in the form", 4000);
        // this._modelBoxService.showModelBox(false,appGlobalModelMessage.requiredFieldErrorMsg);
        // this.toastMsgService("failed", "Please fill all fields");
        // this.errorFields = [];
        // Object.keys(this.createNewTests.controls).forEach(controlName => {
        //   const control = this.createNewTests.get(controlName);
        //   if (control && control.invalid && (control.dirty || control.touched)) {
        //     this.errorFields.push(controlName); // Push the control name into the array
        //   }
        // });
        // console.log(' 698   Fields with errors:', this.errorFields);
        return;
      }
    } else {
      this.TestInstructions = !this.TestInstructions;
    }
  }

  onSubmit() {
    // Reset errorFields array
    this.errorFields = [];

    // Mark all controls as touched and dirty to trigger validation
    Object.keys(this.createNewTests.controls).forEach(controlName => {
      const control = this.createNewTests.get(controlName);
      if (control) {
        control.markAsTouched();
        control.markAsDirty();
      }
    });

    // Now check for errors
    if (this.createNewTests.valid) {
      console.log(this.createNewTests.value); // Form is valid
    } else {
      // Collect all invalid controls
      Object.keys(this.createNewTests.controls).forEach(controlName => {
        const control = this.createNewTests.get(controlName);
        if (control && control.invalid) {
          this.errorFields.push(controlName); // Push the control name into the array
        }
      });

      const firstErrorField = this.errorFields[0];


      if (firstErrorField) {
        const firstInvalidControl = document.querySelector(`[formControlName="${firstErrorField}"]`) as HTMLElement

        const formArrayList = this.createNewTests.get('test_introduction')
        const parentElement = firstInvalidControl.parentElement
        if (parentElement) {
          parentElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "start" });
          // firstInvalidControl.focus();
        }
      }
      console.log(' 698 Fields with errors:', this.errorFields, "first error", firstErrorField); // Log the fields with errors
    }
  }

  onAudience() {
    this.audienceData1 = this.audienceData.value;
  }

  toggle() {
    this.showRadio = !this.showRadio;
  }

  // @note on submit create new test
  onCreateNewTest(saveType) {

    // modifying json data structure
    let tempCreateTestData = this.createNewTests.value;

    let tempDeviceValue = tempCreateTestData["articipants_audience"]["device_type"];
    tempCreateTestData["articipants_audience"]["region"] = this.selectedCountries;

    delete tempCreateTestData["articipants_audience"]["device_type"];
    tempCreateTestData["device_type"] = tempDeviceValue;

    this.submitted = true;
    if (this.createNewTests.invalid) {
      this.toastMsgService.showMsg("failed", "Please fill all fields in the form", 4000);
      return;
    }
    this.submitted = true;

    if (this.editCreatedTest.length > 0) {
      let start_url_splited_array = tempCreateTestData.start_url.split("https://");
      if (start_url_splited_array.length <= 1) {
        tempCreateTestData.start_url = `https://${start_url_splited_array[0]}`;
      }
      this._createTestServices
        .updateTest(tempCreateTestData)
        .subscribe((updateRes) => {
          if (updateRes['status'] == 200) {
            this._modelBoxService.showModelBox(
              true,
              this.createNewTests.value.test_name,
              appGlobalModelMessage.testUpdateHeaderMsg
              // appGlobalModelMessage.testUpdateBodyMsg,
              // 'testCreation'
            );
            this._router.navigate(["/projects"]);
          } else {
            this._modelBoxService.showModelBox(false, updateRes.message);
          }
        });
    } else {
      //@note create test
      let start_url_splited_array = this.createNewTests.value.start_url.split("https://");
      if (start_url_splited_array.length <= 1) {
        this.createNewTests.value.start_url = `https://${start_url_splited_array[0]}`;
      }
      this._createTestServices
        .createTest(this.createNewTests.value)
        .subscribe((testRes) => {
          if (testRes['status'] == 200) {
            if (saveType == "save") {
              this._modelBoxService.showModelBox(true, this.createNewTests.value.test_name, appGlobalModelMessage.testCreateHeaderMsg);
              this._router.navigate(['/research/tests'], { queryParams: { 'project-id': testRes['data']['project_id'] } });
              // this._router.navigate(["/projects"]);
            }
            else if (saveType == "order") {
              this.orderVideos(testRes['data']['project_id'], testRes['data']['test_id']);
            }

          } else {
            // this._modelBoxService.showModelBox(false, testRes.message);
            this.toastMsgService.showMsg("failed", testRes.message, 4000)
          }
        });
    }
  }

  orderVideos(projectId, testId) {
    this._router.navigate(['/research/tests'], { queryParams: { 'project-id': projectId, 'order-test-id': testId } });
  }

  changeQueryParamsPageNavigation() {
    // console.log('change query params`');
    this._router.navigate([], { queryParams: { "currentPage": "page2" }, queryParamsHandling: 'merge', });
  }

  ngOnDestroy() {
    // this.destroyModelCurrentValue();
    this.createNewTests.reset();
  }
}


