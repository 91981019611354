<app-breadcrumbs-navbar></app-breadcrumbs-navbar>
<div class="project-dash-container">
    <div class="project-dash-content">
        <div class="project-dash-header">
            <h2>Projects</h2>
            <div class="project-dash-header-btns">
                <button (click)="createProject()" class="btn-sty-blue">
                    <img  src="assets/images/icons/plus.svg" alt="plus icon"> Create project
                </button>
                <a class="btn-sty-blue-outline" (click)="viewTemplate()">View templates</a>
            </div>
        </div>
        <div class="project-dash-menu">
            <div class="project-dash-menu-text">Get productive.</div>
            <div>
                <app-all-filters></app-all-filters>
            </div>
            <!-- <div class="project-dash-menu-icons">
                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'all' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                    (click)="applyButtonFilterFun('all')">All projects ({{unfilteredJsonData.length}})</button>
                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'active' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                    (click)="applyButtonFilterFun('active')">Active ({{buttonFilterData.active}})</button>
                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'archive' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                    (click)="applyButtonFilterFun('archive')">Archived ({{buttonFilterData.archive}})</button>
            </div> -->
        </div>

        <!-- Table -->
        <div class="project-dash-table-container">

            <table class="project-dash-table">
                
                <tr class="title-row">
                    <th class="project-name-title">Project name</th>
                    <th class="status-title">Status</th>
                    <th class="tests-title">Tests</th>
                    <th class="videos-title">Videos</th>
                    <!-- <th class="reports-title">Reports</th> -->
                    <th class="action-title">Action</th>
                </tr>
                <ng-container *ngIf="isDataAvailable">
                    <tr class="data-row" *ngFor="let projectItem of filteredJsonData; index as i">
                        <td class="project-name-data" (click)="openTestWithProjectId(projectItem, projectItem.id)" >{{ projectItem.project_name }}</td>
                        <td class="status-data">
                            <ng-container *ngIf="projectItem.project_status === 0;else second">Active</ng-container>
                            <ng-template #second>
                                <ng-container *ngIf="projectItem.project_status === 1;else third">Inactive</ng-container>
                            </ng-template>
                            <ng-template #third>
                                <ng-container *ngIf="projectItem.project_status === 5;">Archived</ng-container> 
                            </ng-template>
                        </td>
                        <td class="tests-data">{{ projectItem.total_test }}</td>
                        <td class="videos-data">{{projectItem.completed_videos}} / {{projectItem.total_videos || 0}}</td>
                        <!-- <td class="reports-data">73</td> -->
                        <td class="action-data">
                            <!-- <div (click)="showContextMenu($event,projectItem.id ); OpenPopupfun(projectItem.id)" class="dash-context-menu-wrapper" [id]=projectItem.id > -->
                            <div (click)="togglePopup(projectItem.id, $event)"  class="dash-context-menu-wrapper" [id]=projectItem.id >
                                <img class="dash-context-menu-icon" src="assets/images/icons/menu-icon-new.svg" alt="icon">
                                <div class="dash-context-menu-cont" *ngIf="projectItem.id == openPopupId "  [ngStyle]="popupStyle" >
                                    <div class="dash-context-menu-content" >
                                        <button class="dash-context-menu-edit" 
                                        (click)="openCustomPopup('rename');"
                                        (click)="renameExistingValue = projectItem.project_name; renameInputValue = projectItem.project_name"
                                    >
                                        Rename
                                    </button>
                                    <button class="dash-context-menu-edit" 
                                        (click)="openCustomPopup('delete'); deleteProjectId = projectItem.id "
                                    >
                                        Delete
                                    </button>
                                    <button class="dash-context-menu-edit" 
                                        (click)="duplicateProject(projectItem.id);"
                                    >
                                        Duplicate
                                    </button>
                                        <!-- <button  class="dash-context-menu-archive">Duplicate</button>
                                        <button  class="dash-context-menu-archive">Archive</button>
                                        <button  class="dash-context-menu-delete">Delete</button> -->
                                    </div>
                                </div>
                            </div>     
                        </td>
                    </tr>
                </ng-container>
            </table>

            <div *ngIf="isDataAvailable" class="pagination-cont">
                <p class="pagination-txt">{{paginateCount}} / {{queriedJsonData.length}} projects</p>
                <p class="pagination-btn" (click)="incrementPaginationCountFun(paginateCount + 10)">Load more</p>
            </div>

        </div>

        <div class="empty-data-cont" *ngIf="!isDataAvailable">
            <div class="empty-data-icon" style="margin-bottom: 25px;">
                <img src="assets/images/icons/cube-new-icon.svg" alt="cube icon">
            </div>

            <div class="empty-data-text" style="margin-bottom: 35px;">
                <p>You don't have any projects yet. </p>
                <p>Let's create a project to get started.</p>
            </div>

            <div class="empty-data-btn" style="margin-bottom: 35px;">
                <button (click)="createProject()" class="btn-sty-blue">
                    <img src="assets/images/icons/plus.svg" alt="plus icon"> Create project
                </button>
            </div>

            <div class="empty-data-link">
                or <a style="text-decoration: underline;" class="a-sty"  (click)="viewTemplate()">view templates</a>
            </div>
        </div>

    </div>

</div>



<div *ngIf="showUPCustomPopup" class="custom-popup-backdrop">
    <div class="custom-popup-body">
        <img (click)="showUPCustomPopup = !showUPCustomPopup" class="custom-popup-close-icon" src="assets/images/icons/close-icon.svg" alt="close icon">
        <div class="custom-popup-content">
            <ng-container *ngIf=" customPopupDisplayContent == 'rename' ">
                <h2 class="custom-popup-title">Rename project</h2>

                <label style="margin-top: 50px;"> Project name</label>
                <input (ngModelChange)="handleRenameValueChanges($event)" [(ngModel)]="renameInputValue" type="text" class="custom-popup-text-input">
                <p *ngIf="(renameInputValue != renameExistingValue) && renameValueExist" style="margin-top:10px; color: var(--red); font-size: 14px; font-family: var(--font-regular);"> 
                    The project name already exists 
                </p>

                <button style="margin-top: 50px;" (click)="sendRenameRequest()" class="custom-popup-main-btn btn-sty-blue"
                    [ngClass]="[ (renameInputValue != renameExistingValue) && !renameValueExist && (renameInputValue.trim().length > 2) ? 'up-custom-popup-btn-active' : 'up-custom-popup-btn-inactive']">
                    Rename
                </button>
            </ng-container>
            <ng-container *ngIf=" customPopupDisplayContent == 'delete' ">
                <h2 
                style="
                    font-size: 20px;
                    font-family: var(--font-semibold);
                "
                >
                    Are you sure you want to delete the project?
                </h2>
                <h3
                    style="
                        font-size: 16px;
                        font-family: var(--font-regular);
                        margin-block-start: 0px;
                        margin-block-end: 0px;
                        margin: 0px;
                        margin-top: 20px;
                    "
                >
                    This action will delete the project and all tests, results and reports permanently and
                    <span
                        style="
                            font-size: 16px;
                            font-family: var(--font-medium);
                        "                    
                    >
                        cannnot be restored.
                    </span>
                </h3>

                <label 
                    style="
                    margin-top: 40px;
                    font-size: 16px;
                    font-family: var(--font-regular);
                    "
                >
                    Type in DELETE
                </label>
                <input [(ngModel)]="deleteProjectInput" type="text" class="custom-popup-text-input">

                <button style="margin-top: 80px;" class="custom-popup-main-btn btn-sty-blue" (click)="deleteProject()"
                    [ngClass]="[ validateDeleteProject() ? 'up-custom-popup-btn-active' : 'up-custom-popup-btn-inactive']">
                    Delete project (cannot be restored)
                </button>
            </ng-container>
        </div>
        <p style="font-size: 18px; font-family: var(--font-medium); text-align:center; margin-top: 25px;"
        (click)="showUPCustomPopup = !showUPCustomPopup" class="a-sty">Cancel</p>
    </div>
</div>

<script src='assets/js/custom.js'></script>