<div class="vid-tl-container" (click)="SetClick()">
    <div class="vid-tl-title">Transcripts</div>

    <!-- task-list -->
    <div class="vid-tl-task-list" (mouseup)="OpenMenu($event)">
        <div *ngFor="let task of tasklist; let i = index">
            <div class="task-item">
                <div [ngClass]="{'font-bold': i === viewtask, 'font-regular': i !== viewtask}" class="title" (click)="ShowDescription(i)">
                    <div class="title-flex">
                        <img src="../../../../assets/images/icons/list-grey.svg" />
                        <div>Task {{i+1}}</div>
                    </div>
                    <div>{{task.start_time}}-{{task.end_time}}</div>
                </div>
                <div [ngClass]="{'view-task': i === viewtask, 'hide-task': i !== viewtask}" class="description">
                    {{task.text.transcript}}</div>
            </div>
        </div>
    </div>

    <!-- custom-context-menu -->
    <div id="contextmenu" class="vid-tl-menu-options" [style.left]="menuTopLeftPosition.x" 
    [style.top]="menuTopLeftPosition.y" >
        <button class="menubtn" (click)="CopyText()">Copy text</button>
        <button class="menubtn" (click)="createClipFromSelectedText()">Create clip</button>
        <button class="menubtn" (click)="createNotesFromSelectedText()">Quote</button>
    </div>
</div>