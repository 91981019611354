import {Injectable} from '@angular/core';
import {CreateNewTest} from 'src/app/core/models/create-new-test';
import {Observable, Subject} from 'rxjs';
import {TesterCount} from '../../core/models/testerCount';
import {BaseService} from '../../core/api-call/base.service';
import {appApiResources} from './api.constant';

@Injectable({
  providedIn: 'root'
})
export class CreateNewTestService extends BaseService {
  private testCreataData = new Subject();

  // Create Client new Test
  createTest(test: any): Observable<any> {
    return this.post(appApiResources.test, test);
  }

  // rename project
  renameTest(testDetials: any, testId: any): Observable<any> {
    return this.put(`${appApiResources.test}?id=${testId}`, testDetials);
  }

  changeMessage(currentMessage) {
    this.testCreataData.next(currentMessage);
  }

  // get All Create Test
  getCreateTest(): Observable<any> {
    return this.get(appApiResources.test);
  }

  // Get Tests Lis by TestID
  gelAllProjectList(): Observable<any> {
    return this.get(appApiResources.testProjectList);
    // return this.get("https://62457f132cfed188172199b5.mockapi.io/project-list");
  }

  // get test reports
  getTestReport(testId): Observable<any> {
    return this.get(appApiResources.testReport, {test_id: testId});
    // return this.get(appApiResources.testProjectList);
    // return this.get("https://62457f132cfed188172199b5.mockapi.io/project-list");
  }

  updateTest(test: CreateNewTest): Observable<any> {
    return this.put(appApiResources.test, test, {'id': localStorage.getItem('testId')});
  }

  //  Delete Api Call
  deleteTest(id): Observable<any> {
    return this.delete(appApiResources.test, {id: id});
  }

  // This is Fop Archive Test Api
  archieveTest(id): Observable<any> {
    return this.get(appApiResources.testArchive, {id: id});
  }

  restoreTest(id): Observable<any> {
    return this.get(appApiResources.testRestoreToDraft, {id: id})
  }

  testerCount(testerCount: TesterCount): Observable<any> {
    return this.post(appApiResources.testVideoSetup, testerCount);
  }

  deductCredit(jsonData: any): Observable<any> {
    return this.post(appApiResources.deductCredit, jsonData);
  }

  getNewVideo(id) {
    return this.get(appApiResources.testNewVideo, {id: id});
  }

  // project all test
  getProjectRelatededList(project_id) {
    return this.get(appApiResources.testByProject, {project_id: project_id});
  }

  templateManagement() {
    // return this.get("https://62457f132cfed188172199b5.mockapi.io/templates");
    return this.get(appApiResources.adminTemplateManagement);
  }

  // create project
  createProject(project: any): Observable<any> {
    return this.post(appApiResources.createProject, project);
  }

  // rename project
  renameProject(project: any): Observable<any> {
    return this.put(appApiResources.createProject, project);
  }

  getTestRelatedToProjectId(project_id: any){
    return this.get(appApiResources.testWithProjectId, {project_id: project_id});
  }

}
