import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccountComponent} from './../account/account.component';
import {AccountRoutingModule} from './account-routing.module';
import {CreditsPlansComponent} from './credits-plans/credits-plans.component';
import {BillingComponent} from './billing/billing.component';
import {InvoicesComponent} from './invoices/invoices.component';
import {AccountDetailsComponent} from './account-details/account-details.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {SharedModule} from 'src/app/shared/shared.module';
import { BillingInvoicesNewComponent } from './billing-invoices-new/billing-invoices-new.component';


@NgModule({
  declarations: [
    AccountComponent,
    CreditsPlansComponent,
    BillingComponent,
    InvoicesComponent,
    AccountDetailsComponent,
    SidebarComponent, 
    BillingInvoicesNewComponent,
  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    SharedModule,
  ]
})
export class AccountModule {

}
