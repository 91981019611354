import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import { ViewVideoDetailsComponent } from './view-video-details/view-video-details.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { VideoClipComponent } from './video-clip/video-clip.component';
import { SelectedVideoDetailsService } from './selected-video-details.service';
import { TranscriptComponent } from './transcript/transcript.component';
import { TesterTestedListService } from 'src/app/shared/services/tester-tested-list.service';
import { VideoClipListComponent } from './video-clip-list/video-clip-list.component';
import { UserpeekVideoPlayerComponent } from './userpeek-video-player/userpeek-video-player.component';

@NgModule({
  declarations: [
    ViewVideoDetailsComponent,
    VideoPlayerComponent,
    VideoClipComponent,
    TranscriptComponent,
    VideoClipListComponent,
    UserpeekVideoPlayerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  providers: [
    SelectedVideoDetailsService,
    TesterTestedListService
  ],
  exports: [
    ViewVideoDetailsComponent,

  ]
})
export class NewVideosModule { }
