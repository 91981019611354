import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {PaymentService} from 'src/app/shared/services/payment.service';
import {AuthService} from 'src/app/shared/services/auth.service';
import Swal from 'sweetalert2'
import {NavigationEnd, ActivatedRoute, Params, Router} from '@angular/router';
import { StoreOrderedTestDataService } from 'src/app/shared/services/store-ordered-test-data.service';
import { ModelBoxService } from 'src/app/shared/services/model-box.service';
import { UpStoreSelectedTestDetialsService } from 'src/app/shared/services/up-store-selected-test-detials.service';
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';
import { CreateNewTestService } from 'src/app/shared/services/create-new-test.service';
import { GeneralApiService } from 'src/app/core/api-call/general-api.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-credits-plans-new',
  templateUrl: './credits-plans-new.component.html',
  styleUrls: ['./credits-plans-new.component.scss']
})

export class CreditsPlansNewComponent implements OnInit {

  // ======= new variables =========
  videoCountToDisplay: number = 0;
  offerpercentage : number = 20;
  videoCount: any = 5;
  deviceType: any;
  selectedTestDetials: any;
  totalCreditsAvailable: number;
  showBuyCreditPopup: boolean = false;
  selectedTestId: any;
  // ======= new variables =========

  // popup variable
  showUPCreditsPopup: boolean = false;
  creditsPopupDisplayContent: string = "";

  // plan details
  userActivePlan: string = "";
  userFuturePlan: string = "";
  flexPlan: any = {};
  growPlan: any = {};
  scalePlan: any = {};
  currentPlanDetails: any = {};
  isFastSpringAccountCreated: boolean = false;
  userFastSpringAccountDetails: any = {};
  sessionId: any;
  currentUserCredit: number = 0;
  planListArray = [];
  planDetailFlexible: any = {};
  planDetailGrowYearly: any = {};
  planDetailGrowMonthly: any = {};
  planDetailGrowYearlySubscription: any = {};
  planDetailGrowMonthlySubscription: any = {};
  toggleAnnualPrice: boolean = false;
  showDowngradeButton: string = "show";
  planDowngradeDate: any = "";
  additionalCreditCurrentPlanDetials: any = {};

  // stripe subscription variables
  userAuthToken: any = "";
  currentUserId: any = "";
  subscriptionFormInputs: any = {};
  checkoutFormInputs: any = {};
  backendAPIEndpoint: any = environment.apiUrl;
  @ViewChild('subscriptionForm') subscriptionFormElement!: ElementRef;
  @ViewChild('checkoutForm') checkoutFormElement!: ElementRef;
  subscriptionId: any = "";

  isOrderVideoFlow: boolean = false;

  // plan details
  upFlexPlanDetails: any = {
    name: "flex",
    price:0,
    additionalCredits: 55
  }
  upGrowMonthlyPlanDetails: any = {
    name: "grow_monthly",
    price:265,
    additionalCredits: 49
  }
  upGrowYearlyPlanDetails: any = {
    name: "grow_yearly",
    price:211,
    additionalCredits: 39
  }
  upActivePlanDetails: any = {
    name: "",
    price:0,
    additionalCredits: 0    
  }

  constructor(
    private paymentService: PaymentService,
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    public router: Router,
    private _storeOrderedTestDataService: StoreOrderedTestDataService,
    private _modelBoxService: ModelBoxService,
    private _upStoreSelectedTestDetialsService: UpStoreSelectedTestDetialsService,
    private toastMsgService: ToastMsgService,
    public _createNewTestService: CreateNewTestService,
    public _generalApiService: GeneralApiService,
  ) {
  }

  ngOnInit() {
    this.userAuthToken = localStorage.getItem('token_id');
    this.currentUserId = localStorage.getItem('user_id');
    // this.getFastSpringAccountDetails();$
    this.getUserPaymentDetails();
    this._activatedRoute.queryParams.subscribe((params) => {
      if(params['redirect-type'] === "order-videos"){
        this.handleStripeCheckout("order-videos");
      }
    })
  }

  handleBuySubscription(plan_type, lookup_key){
    console.log('handleBuySubscription clicked')
    this.subscriptionFormInputs = {
      "plan_type": plan_type,
      "lookup_key": lookup_key
    }
    setTimeout(()=>{
      this.subscriptionFormElement.nativeElement.submit();
    }, 100)
  }

  handleStripeCheckout(flowType = ""){
    let checkoutProductName = "";
    if(flowType === "order-videos"){
      this.isOrderVideoFlow = true;

      this._activatedRoute.queryParams.subscribe((params) => {

        let planType = params['plan-type'];

        if(planType === "flex"){
          this.upActivePlanDetails = this.upFlexPlanDetails;
          this.userActivePlan = "flexible";
        } 
        else if(planType === "grow_monthly"){
          this.upActivePlanDetails = this.upGrowMonthlyPlanDetails;
          this.userActivePlan = "grow-monthly-subscription";
        }
        else if(planType === "grow_yearly"){
          this.upActivePlanDetails = this.upGrowYearlyPlanDetails;
          this.userActivePlan = "grow-yearly-subscription";
        } 

        if(this.upActivePlanDetails.name === "flex"){
          checkoutProductName = "flex_credits";
        } else if(this.upActivePlanDetails.name === "grow_monthly"){
          checkoutProductName = "grow_monthly_credits";
        } else if(this.upActivePlanDetails.name === "grow_yearly"){
          checkoutProductName = "grow_yearly_credits";
        }
    
        let creditsRequired = parseInt(params['credits-required']);
        this.checkoutFormInputs = {
          "credits": creditsRequired,
          "price": (creditsRequired * this.upActivePlanDetails.additionalCredits) * 100,
          "product_name": checkoutProductName,
          "createtest_id": params['selected-test-id'],
          "test_count": params['video-count'],
          "device_type": params['device-type'],
          "project_id": params['project-id'],
        }

      })

    } else if (flowType === "buy-credits"){
      this.isOrderVideoFlow = false;

      if(this.upActivePlanDetails.name === "flex"){
        checkoutProductName = "flex_credits";
      } else if(this.upActivePlanDetails.name === "grow_monthly"){
        checkoutProductName = "grow_monthly_credits";
      } else if(this.upActivePlanDetails.name === "grow_yearly"){
        checkoutProductName = "grow_yearly_credits";
      }
  
      this.checkoutFormInputs = {
        "credits": this.videoCount,
        "price": (this.videoCount * this.upActivePlanDetails.additionalCredits) * 100,
        "product_name": checkoutProductName
      }

    }

    setTimeout(()=>{
      // clear url params
      const url = window.location.origin + window.location.pathname;
      window.history.pushState({}, document.title, url);

      this.checkoutFormElement.nativeElement.submit();
    }, 100)
  }

  getUserPaymentDetails(){
    console.log('getUserPaymentDetails called ')
    this._generalApiService.stripeCustomer().subscribe((apiResponse)=>{
      console.log('getUserPaymentDetails', apiResponse)
      if(apiResponse["data"]){

        this.subscriptionId = apiResponse["data"]["subscription_id"];
        this.currentUserCredit = parseInt(apiResponse["data"]["current_credit"]);
        this._generalApiService.updateUserCreditScore(this.currentUserCredit);

        if(apiResponse['data']["credit_plan"] === "flexible"){
          this.upActivePlanDetails = this.upFlexPlanDetails;
          this.userActivePlan = "flexible";
        } 
        else if(apiResponse['data']["credit_plan"] === "Grow monthly"){
          this.upActivePlanDetails = this.upGrowMonthlyPlanDetails;
          this.userActivePlan = "grow-monthly-subscription";
        }
        else if(apiResponse['data']["credit_plan"] === "Grow yearly"){
          this.upActivePlanDetails = this.upGrowYearlyPlanDetails;
          this.userActivePlan = "grow-yearly-subscription";
          this.toggleAnnualPrice = true;
        } 

        this.userFuturePlan = apiResponse['data']["new_plan"];

        if(apiResponse['data']["new_plan"] != ""){
          // format plan end date as requires string
          let tempDate = new Date(apiResponse['data']["change_time"]);
          const month = tempDate.toLocaleString('default', { month: 'short' });
          const day = tempDate.getDate();
          const year = tempDate.getFullYear();
          this.planDowngradeDate = `${month}. ${day}, ${year}`;
        }

      }
    })
  }

  // get payment data
  getFastSpringAccountDetails(){
    this.paymentService.getCustomer().subscribe((res) => {
      this.userFastSpringAccountDetails = res['data'];
      this.isFastSpringAccountCreated = !!res['data'].id;

      // get user active fastspring subscription 
      if(Object.keys(res['data']).length === 0){
        // customer data not available 1st time fastspring register
        this.userActivePlan = "flexible";
        this.userFuturePlan = "";
        this.upActivePlanDetails = this.upFlexPlanDetails;
        // this.showDowngradeButton = "show";
      } 
      else {


        if(res['data']["credit_plan"] === "grow-yearly-subscription"){
          this.upActivePlanDetails = this.upGrowYearlyPlanDetails;
        } else if(res['data']["credit_plan"] === "grow-yearly-subscription"){
          this.upActivePlanDetails = this.upGrowMonthlyPlanDetails;
        }
        
        // customer data available
        this.userActivePlan = res['data']["credit_plan"];
        this.userFuturePlan = res['data']["new_plan"];
        if(this.userActivePlan == "grow-yearly-subscription"){
          this.toggleAnnualPrice = true;
        }
        if(res['data']["new_plan"] != ""){
          // this.showDowngradeButton = "not-show";
          // format plan end date as requires string
          let tempDate = new Date(res['data']["change_time"]);
          const month = tempDate.toLocaleString('default', { month: 'short' });
          const day = tempDate.getDate();
          const year = tempDate.getFullYear();
          this.planDowngradeDate = `${month}. ${day}, ${year}`;
        }
        // else{
        //   this.showDowngradeButton = "show";
        //   this.planDowngradeDate = "";
        // }
      }
      // this.userActivePlan = "grow-monthly-subscription";

      // this.getPlanDetails();

    });
  }


  getOfferPrice(price, offer){
    const reductionFactor = 1 - (offer / 100);
    const offerPrice = price * reductionFactor;
    const perMonth = Math.round(offerPrice)
    return perMonth;
  }


  downgradeToFlex(){
    if(this.subscriptionId !== ""){
      let requestBody = {
        "subscription_id": this.subscriptionId
      }
      this._generalApiService.stripeCancelSubscriptionAPI(requestBody).subscribe((response)=>{
        if(response.status == 200 || response.status == 201){
          this.showUPCreditsPopup = false;
          window.location.reload();
          this.toastMsgService.showMsg("success", "Your plan successfully downgraded", 4000);
        }
      });
    } else {
      this.toastMsgService.showMsg("failed", "subscription id not found", 4000);
    }
    // let tempJson = {
    //   "current_plan": this.userActivePlan,
    //   "new_plan":"flexible"
    // };

    // this.paymentService.downgradePlan(tempJson).subscribe((response)=>{
    //   if(response.status == 200){
    //     this.showUPCreditsPopup = false;
    //     window.location.reload();
    //     this.toastMsgService.showMsg("success", "Your plan successfully downgraded", 4000);
    //   } else{
    //     this.showUPCreditsPopup = false;
    //     window.location.reload();
    //     this.toastMsgService.showMsg("failed", "plan downgrade error", 4000);
    //   }
    // });
  }

  upgradeToYearly(){
    if(this.subscriptionId !== ""){
      let requestBody = {
        "subscription_id": this.subscriptionId
      }
      this._generalApiService.stripeUpgradeSubscriptionAPI(requestBody).subscribe((response)=>{
        if(response.status == 200 || response.status == 201){
          this.showUPCreditsPopup = false;
          window.location.reload();
          this.toastMsgService.showMsg("success", "Your plan successfully upgraded", 4000);
        }
      });
    } else {
      this.toastMsgService.showMsg("failed", "subscription id not found", 4000);
    }
  }













}

/**
 * 
 * 



  getCurrentAdditionalCreditsPlanDetails(){
    if(this.userActivePlan == "flexible"){
      this.additionalCreditCurrentPlanDetials = this.planDetailFlexible;
    }
    else if(this.userActivePlan == "grow-monthly-subscription"){
      this.additionalCreditCurrentPlanDetials = this.planDetailGrowMonthly;
    }
    else if(this.userActivePlan == "grow-yearly-subscription"){
      this.additionalCreditCurrentPlanDetials = this.planDetailGrowYearly;
    }
  }

  orderPendingTest(projectId, testId){
    let testDetials: any;
    this._upStoreSelectedTestDetialsService.testDetialsVariable.subscribe(param => { testDetials = param ;});
    let orderTestJsonData = testDetials.jsonData;

    this._createNewTestService.deductCredit(orderTestJsonData).subscribe((res) => {
      if(res['status'] == 200){
        this.router.navigate(['/research/tests'], {queryParams: {'project-id': projectId}});
        this.toastMsgService.showMsg("success", "Videos ordered", 3500);
      }
      else{
        this.router.navigate(['/research/tests'], {queryParams: {'project-id': projectId}});
        this.toastMsgService.showMsg("failed", "Videos not ordered!", 3500);
      }
    });

  }

  getPlanDetails(){
    console.log('get plan detials function started');
    this.paymentService.getNewPlanDetails().subscribe((response)=>{
      let res: any = response;
      // this.userActivePlan = res['active-plan'];
      this.planListArray = res;

      this.planListArray.forEach((planItem)=>{
        if(planItem.plan_name == this.userActivePlan){
            this.currentPlanDetails = planItem;
        }
        if(planItem.plan_name == "flexible"){
            this.planDetailFlexible = planItem;
        }
        if(planItem.plan_name == "grow-monthly-subscription"){
            this.planDetailGrowMonthlySubscription = planItem;
        }
        if(planItem.plan_name == "grow-monthly"){
            this.planDetailGrowMonthly = planItem;
        }
        if(planItem.plan_name == "grow-yearly-subscription"){
            this.planDetailGrowYearlySubscription = planItem;
        }
        if(planItem.plan_name == "grow-yearly"){
            this.planDetailGrowYearly = planItem;
        }
      })
      this.getCreditDetails();
      // this.getCreditDetails();
      // this.getFastSpringAccountDetails();

      // grow-yearly-subscription == grow-yearly | grow-monthly-subscription = grow-monthly
      this.getCurrentAdditionalCreditsPlanDetails();
    });

    console.log('this.currentPlanDetails', this.currentPlanDetails);

  }
  
  onPaymentReceived(data) {
    console.log('fastspring popup closed payment received here response >> :', data);
    let fastspringOrderDetailsJson = {
      "order_id": data["id"]
    };

    //Fastspring order v2 API
    this.paymentService.postFastspringCallback(fastspringOrderDetailsJson).subscribe((res)=>{ // add credits to users using fastspring data
      console.log('adding credit using fastspring data response here postFastspringCallback >> :', res);
      this.showUPCreditsPopup = false;
      if(res.status == 200){
        this.toastMsgService.showMsg("success", "credits added", 3500)
        this.activatedRoute.queryParams.subscribe((params)=> {
          if (params['videoCount'] == undefined ) {
            // insufficent credit logic
            this.activatedRoute.queryParams.subscribe((params) => {
              if (params['videoCount'] == undefined ) {
                window.location.reload();
              }
            });
          }
        });
        // insuffcient fund logic
        this.activatedRoute.queryParams.subscribe((params)=> {
          if (params['videoCount'] != undefined ) {
            this.orderPendingTest(params['projectId'], params['testId']);
          }
        });
      }
      else{
        this.toastMsgService.showMsg("failed", "credits not added!", 3500)
      }
      
      // get current user credits
      this.paymentService.getCustomer().subscribe((creditDetials)=>{
        if(creditDetials['data'].length <= 0){
          this.currentUserCredit = 0;
        }
        else {
          this.currentUserCredit = creditDetials['data'].current_credit;
        }
      });
    });


  }










  handleInsufficientCredit(videoCount){
    console.log('insufficient funds function started' );
    // this.getCreditDetails();
    let videoCountInteger = Number(videoCount);
    this.videoCount = (this. currentUserCredit > videoCountInteger) ? this.currentUserCredit - videoCountInteger : videoCountInteger - this.currentUserCredit;
    this.buyCredits();
  }



  buyCredits(){
    console.log('buy credit function started');
    console.log('this.currentPlanDetails', this.currentPlanDetails);

    if(this.isFastSpringAccountCreated){
      let tempJsonData: any = {};
      // flex plan post request data structure logic
      if(this.currentPlanDetails.plan_name == "flexible"){
        console.log('this is flexible plan');
        tempJsonData = {
          account: this.userFastSpringAccountDetails.account,
          items: [
            {
              product: this.currentPlanDetails.plan_name,
              quantity: this.videoCount,
              pricing: {
                price: {
                  USD: this.currentPlanDetails.price
                }
              }
            }
          ]
        };
      }
      if(this.currentPlanDetails.plan_name == "grow-monthly-subscription"){

        tempJsonData = {
          account: this.userFastSpringAccountDetails.account,
          items: [
            {
              product: this.planDetailGrowMonthly["plan_name"],
              quantity: this.videoCount,
              pricing: {
                price: {
                  USD: this.planDetailGrowMonthly["price"]
                }
              }
            }
          ]
        };

      }
      if(this.currentPlanDetails.plan_name == "grow-yearly-subscription"){

        tempJsonData = {
          account: this.userFastSpringAccountDetails.account,
          items: [
            {
              product: this.planDetailGrowYearly["plan_name"],
              quantity: this.videoCount,
              pricing: {
                price: {
                  USD: this.planDetailGrowYearly["price"]
                }
              }
            }
          ]
        };
      }
      this.paymentService.addSessionForPlan(tempJsonData).subscribe((res) => {
        this.sessionId = res['data'].id;
        window['fastspring'].builder.checkout(this.sessionId);
        },
        (err) => {});
    }
    else {
      let tempJsonData1 = {
        'reset': true,
        'products': [
          {
            'path': this.currentPlanDetails.plan_name,
            'quantity': this.videoCount,
          }
        ],
        'checkout': true
      }
      window['fastspring'].builder.push(tempJsonData1);
    }
  }



    buySubscription(subscriptionType){
  console.log("subscriptionType:", subscriptionType)

  if(this.isFastSpringAccountCreated){
    let tempJsonData: any = {};
    if(subscriptionType == "grow-monthly-subscription"){
      tempJsonData = {
        account: this.userFastSpringAccountDetails.account,
        items: [
          {
            product: this.planDetailGrowMonthlySubscription["plan_name"],
            quantity: 1,
            pricing: {
              price: {
                USD: this.planDetailGrowMonthlySubscription["price"]
              }
            }
          }
        ]
      };
    }
    else if(subscriptionType == "grow-yearly-subscription"){
      tempJsonData = {
        account: this.userFastSpringAccountDetails.account,
        items: [
          {
            product: this.planDetailGrowYearlySubscription["plan_name"],
            quantity: 1,
            pricing: {
              price: {
                USD: this.planDetailGrowYearlySubscription["price"]
              }
            }
          }
        ]
      };
    }
    console.log('tempJsonData:', tempJsonData);
    this.paymentService.addSessionForPlan(tempJsonData).subscribe((res) => {
      this.sessionId = res['data'].id;
      window['fastspring'].builder.checkout(this.sessionId);
      },
      (err) => {}
    );
  }
  else{ // fastspring account not created
    let tempJsonData1 = {};
    if(subscriptionType == "grow-monthly-subscription"){
      tempJsonData1 = {
        'reset': true,
        'products': [
          {
            'path': this.planDetailGrowMonthlySubscription["plan_name"],
            'quantity': 1,
          }
        ],
        'checkout': true
      }
    }
    else if(subscriptionType == "grow-yearly-subscription"){
      tempJsonData1 = {
        'reset': true,
        'products': [
          {
            'path': this.planDetailGrowYearlySubscription["plan_name"],
            'quantity': 1,
          }
        ],
        'checkout': true
      }
    }
    window['fastspring'].builder.push(tempJsonData1);
  }

    // if(this.userActivePlan != "grow-monthly-subscription" &&  this.userActivePlan != 'grow-yearly-subscription'){
    //   // check for any active subscription
    // }
    // else{
    //   this.toastMsgService.showMsg("failed", "Already you have active Subscription", 3500);
    // }
    // // if already subscription present give error
  }

    getCreditDetails(){
    console.log('get credit details function called');
    this.paymentService.getCustomer().subscribe((creditDetials)=>{
      if(creditDetials['data'].length <= 0){
        this.currentUserCredit = 0;
      }
      else {
        this.currentUserCredit = creditDetials['data'].current_credit;
      }

      // insufficent credit logic
      this.activatedRoute.queryParams.subscribe((params) => {
        console.log('checking route url for keywords');
        if (params['videoCount'] != undefined ) {
          this.handleInsufficientCredit(params['videoCount']);
        }
      });
    });
  }

 */