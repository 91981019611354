<div class="mt-3">
    <div class="font_weight">
        <label for="test" style="font-size: 13pt;">Tasks</label>
    </div>

    <div class="mt-3">
        <div class="font_weight">
            <label class="fontSize">Introduction</label>
        </div>
        <label>{{videoInformation.test_introduction}}</label>
        <label>site: <strong style="font-weight: 500;">{{videoInformation.start_url}}</strong></label>
    </div>

        <!-- card -->
    <div class="mt-3" *ngFor="let item of videoInformation.task_details_duration_wise.Task index as i">
        <mat-card>
            <mat-card-title>
                Task {{i + 1}}
                <span class="ml-1 text_color" style="cursor: pointer;" (click)="jumpTimestamp(item.startTime, true)">{{item.startTime}} - {{item.endTime}}</span>
            </mat-card-title>

            <mat-card-content>{{item.task}}</mat-card-content>
        </mat-card>
    </div>





</div>
