import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {CreateNewTestService} from 'src/app/shared/services/create-new-test.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {ModelBoxService} from 'src/app/shared/services/model-box.service';

@Component({
  selector: 'app-dashboard-test-edit',
  templateUrl: './dashboard-test-edit.component.html',
  styleUrls: ['./dashboard-test-edit.component.scss'],
})
export class DashboardTestEditComponent implements OnInit, OnDestroy {
  subscription1: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;
  TestInstructions: Boolean = true;
  createNewTests: FormGroup;
  audienceData: FormGroup;
  newTestData = {};
  audienceData1 = {};
  showField: boolean = true;
  isActive = false;
  // templates = ['Default', 'E-Commerce', 'Booking', 'Saas', 'Corporate'];
  language = ['English', 'German'];
  audience = [
    'Fastest results',
    'Choose specific demographics',
    'Get certified UX and User Research Experts to take the test',
  ];
  showRadio: boolean = false;
  testerAge = ['All', '18-24', '25-34', '35-50', '51 and Other'];
  testerGender = ['All', 'Female', 'Male'];
  testerRegion = [
    'All',
    // 'india',
    'North America',
    'South America',
    'Asia',
    'Australia',
    'Africa',
    'Europe',
    'Antarctic',
  ];
  submitted: boolean = false;
  temValue: any;
  mystyle1: string = 'none';
  test: any = {};
  id: any;
  templateShow: any;
  allProjectList = [];
  myVar1 = false;
  templateDataEdit: any;
  templatesEdit: string[];
  testNameView = "";
  // templeteTextShow=""
  selectedInstructionType: any;

  constructor(
    private fb: FormBuilder,
    private _createTestServices: CreateNewTestService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _modelBoxService: ModelBoxService
  ) {
  }

  ngOnInit() {
    // This is CreateNewTestsUpdate FOrm
    this.templateManagement();
    this.createNewTests = this.fb.group({
      test_name: ["", Validators.required],
      project_name: ["", Validators.required],
      project_flag: [true],
      start_url: [
        "",
        [
          Validators.required,
          Validators.pattern(
            '(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})'
          ),
        ],
      ],
      language: ["", Validators.required],
      // url_type: [""],
      is_app: [""],
      // articipants_audience: [""],
      // test_instructions: [this.temValue, Validators.required],
      test_instructions: new FormArray([]),
      template_name: ['defult', Validators.required],
      articipants_audience: this.fb.group({
        articipants_type: [""],
        gender: [""],
        region: [""],
        age: [""],
      }),
    });

    this.audienceData = this.fb.group({
      participants_audience: [""],
    });

    // this is For Get All Test List Json

    this.subscription1 = this._createTestServices
      .getCreateTest()
      .subscribe((getRes) => {
      });

    this._route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.test = params['id'] != null;
      localStorage.setItem('testId', this.id);
      this.subscription2 = this._createTestServices
        .getCreateTest()
        .subscribe((getRes) => {
          var editData = getRes.data;
          this.test = editData.filter((x) => {
            return x.id === this.id;
          });

          this.templateShow = this.test[0].template_name;
          this.testNameView = this.createNewTests.value.test_name;
          this.createNewTests.value.test_instructions;
          // this.createNewTests.setControl('test_instructions', this.setSkills(this.test[0].test_instructions))

          this.createNewTests.patchValue({
            test_name: this.test[0].test_name,
            project_name: this.test[0].project_name,
            // project_flag: this.test[0].project_flag,
            start_url: this.test[0].start_url,
            language: this.test[0].language,
            // url_type: this.test[0].url_type,
            is_app: this.test[0].is_app,
            // test_instructions: this.test[0].test_instructions,
            template_name: this.test[0].template_name,

            // articipants_type:this.test[0].articipants_audience.articipants_type,
            // gender:this.test[0].articipants_audience.gender,
            // region:this.test[0].articipants_audience.region,
            // age:this.test[0].articipants_audience.age
          });
          this.createNewTests.patchValue({
            articipants_audience: {
              articipants_type: this.test[0].articipants_audience
                .articipants_type,
              gender: this.test[0].articipants_audience.gender,
              region: this.test[0].articipants_audience.region,
              age: this.test[0].articipants_audience.age,
            },
          });

          this.selectedInstructionType = this.test[0].template_name;
          this.test[0].test_instructions.forEach((text) => {
            const control = new FormControl(text, Validators.required);
            (<FormArray>this.createNewTests.get('test_instructions')).push(
              control
            );
          });
        });
    });

    this._createTestServices.gelAllProjectList().subscribe((res) => {
      let newFormulalist = res.data.filter(
        (v, i) =>
          res.data.findIndex((item) => item.project_name == v.project_name) ===
          i
      );
      newFormulalist.forEach((res) => {
        this.allProjectList.push(res.project_name);
      });
    });
  }

  // Set The Style For Droup Down

  addStyle() {
    this.mystyle1 = 'block';
  }

  onMyCustomEvent() {
    this.showRadio = false;
  }

  setSkills(tests): FormArray {
    const formArray = new FormArray([]);
    tests.forEach((s) => {
      formArray.push(this.fb.control(s));
    });

    return formArray;
  }

  templateManagement() {
    this._createTestServices.templateManagement().subscribe((res) => {
      this.templateDataEdit = res['data'];
      this.templatesEdit = Object.keys(this.templateDataEdit);
    });
  }

  onAddTests() {
    if (this.createNewTests.get('test_instructions')['length'] < 5) {
      const control = new FormControl(null, Validators.required);
      (<FormArray>this.createNewTests.get('test_instructions')).push(control);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You can not add more than 5 Task.',
      });
    }
  }

  removeTest(indexoftest): void {
    (<FormArray>this.createNewTests.get('test_instructions')).removeAt(
      indexoftest
    );
  }

  hideStyle() {
    this.mystyle1 = 'none';
  }

  //  Template Name Value Get For this Function

  changeTestInstructionType(testInstructionType) {
    this.selectedInstructionType = testInstructionType;

    // Remove all element from test_instructions array
    while (this.createNewTests.get('test_instructions')['length'] !== 0) {
      (<FormArray>this.createNewTests.get('test_instructions')).removeAt(0);
    }

    // Add value of selected test_instructions type in test_instructions array
    this.templateDataEdit[testInstructionType].forEach((text) => {
      let control = new FormControl(text, Validators.required);
      (<FormArray>this.createNewTests.get('test_instructions')).push(control);
    });
  }

  // Show Toggle Based View

  BindData() {
    this.showField = true;
  }

  //  Show Step View

  createTests() {
    this.TestInstructions = this.TestInstructions ? false : true;
  }

  onAudience() {
    this.audienceData1 = this.audienceData.value;
    // this.newTestData.push(audienceData2)
  }

  toggle() {
    this.showRadio = !this.showRadio;
  }

  onCreateNewTest() {
    this.submitted = true;
    if (this.createNewTests.invalid) {
      return;
    }
    this.submitted = false;
    this._createTestServices
      .createTest(this.createNewTests.value)
      .subscribe((testRes) => {
        // this._router.navigate(['dashboard']);
      });
  }

  updatetest() {
    this.submitted = true;
    if (this.createNewTests.invalid) {
      return;
    }
    this.submitted = false;
    this.subscription3 = this._createTestServices
      .updateTest(this.createNewTests.value)
      .subscribe((updateRes) => {
        if (updateRes['status'] == 200) {

          this.showModelBox(true, this.createNewTests.value.test_name);
        } else {
          this.showModelBox(false, updateRes.message);
        }
      });
  }

  showModelBox(isSuccess, modelBody?) {
    if (isSuccess) {
      this._modelBoxService.modelBoxHeader = 'Test updated!';
      this._modelBoxService.modelBoxBody = modelBody + ' test name is updated.';
      this._modelBoxService.componentStatus = 'testCreation';
      this._modelBoxService.isGettingError = false;
      this._modelBoxService.isModelShow.next(true);
    } else {
      this._modelBoxService.isGettingError = true;
      this._modelBoxService.modelBoxBody = modelBody;
      this._modelBoxService.isModelShow.next(true);
    }
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }
}
