import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PaymentService} from 'src/app/shared/services/payment.service';
import {ModelBoxService} from 'src/app/shared/services/model-box.service';
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';
import { countryList } from 'src/app/shared/services/countrylist.constant';
import { GeneralApiService } from 'src/app/core/api-call/general-api.service';

@Component({
  selector: 'app-billing-invoices-new',
  templateUrl: './billing-invoices-new.component.html',
  styleUrls: ['./billing-invoices-new.component.scss']
})

export class BillingInvoicesNewComponent implements OnInit {

  public submitted: boolean = false;
  enable_button : boolean = false;  
  residences = [];
  isAccountCreate: boolean;
  tab: any = 'tab1';
  tab1: any;
  creditCustomerPlan: FormGroup;
  language: any;
  newCountryList: any = [];
  customerLanguage = {English: 'en', Arbic: 'ar', Hindi: 'hi', Spanish: 'es'};
  languagecode = [];
  showLang = [];
  isAccountCreateMsg = [];
  billingInformationAvailable: boolean = false;
  userSubscriptionHistoryList: any = [];
  userCreditHistoryList: any = [];


  constructor(
    private fb: FormBuilder,
    private _payement: PaymentService,
    private _modelBoxService: ModelBoxService,
    private toastMsgService: ToastMsgService,
    private _generalApiService: GeneralApiService,
  ) {}

  ngOnInit() {
    this.initBillingSubForm();
    this.creditCustomerPlan.patchValue({
      custom: 'ueser_id_' + localStorage.getItem('user_id'),
    });
    this.languagecode = Object.values(this.customerLanguage);

    for (let lang of this.languagecode) {
      this.showLang.push(this.languagecode[lang]);
    }
    // this.getCountryLangList();
    this.getBillingAddress();
    this.newCountryList = countryList.data;
    this.getStripeBillingHistory()
  }


  getStripeBillingHistory(){
    this._generalApiService.stripeBillHistoryAPI().subscribe((apiResponse: any)=>{
      if(apiResponse.status = 200){
        this.userSubscriptionHistoryList = apiResponse["data"]["subscription_history"]
        this.userCreditHistoryList = apiResponse["data"]["credits"]
      }
    })
  }

  modifyDate(dateInput){
    const dateStr = "2024-07-22T12:26:07Z";
    const date = new Date(dateStr);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }
  
  openLink(link){
    console.log('clicked')
    window.open(link,'_blank');
  }



  

  initBillingSubForm() {
    this.creditCustomerPlan = this.fb.group({
      first: ["", Validators.required],
      last: ["", Validators.required],
      email: [""],
      company: [""],
      phone: [""],
      language: [""],
      country: [""],
      custom: [""],
      addressLine1: [""],
      addressLine2: [""],
      postalCode: [""],
      city: [""],
      region: [""],
    });
  }

  getBillingAddress() {
    
    // console.log("creditCustomerPlan",this.creditCustomerPlan)

    this._payement.getCustomer().subscribe((res) => {

      if(res['data'].length <= 0){
        this.billingInformationAvailable = false;
      } else {
        this.billingInformationAvailable = true;
      }
      
      const data = res['data'];
      localStorage.setItem('customer_id', data.id);

      this.creditCustomerPlan.patchValue({
        country: data.country,
        language: data.language,
        first: data.contact.first,
        last: data.contact.last,
        email: data.contact.email,
        company: data.contact.company,
        phone: data.contact.phone,
        addressLine1: data.address.addressLine1,
        addressLine2: data.address.addressLine2,
        city: data.address.city,
        region: data.address.region,
        postalCode: data.address.postalCode,
      });

      // this.creditCustomerPlan.get('contact').patchValue({
      //   first: data.contact.first,
      //   last: data.contact.last,
      //   email: data.contact.email,
      //   company: data.contact.company,
      //   phone: data.contact.phone,
      // });
      // const address = data['address'];
      // this.creditCustomerPlan.get('address').patchValue({
      //   addressLine1: address.addressLine1,
      //   addressLine2: address.addressLine2,
      //   city: address.city,
      //   region: address.region,
      //   postalCode: address.postalCode,
      // });

    });
  }

  // getCountryLangList() {
  //   this._payement.getCountryList().subscribe((list) => {
  //     this.newCountryList = list.country_list;
  //     // this.residences = Object.values(list.country_code_list);
  //     // for (let country of this.residences) {
  //     //   this.newCountryList.push(this.residences[country]);
  //     // }
  //     // this.language = list.lang_list;
  //   });
  // }


  updateCustomer() {
    this.submitted = true;
    if (this.creditCustomerPlan.invalid) {
      return;
    }

    let jsonData = {
      "contact": {
        "first": this.creditCustomerPlan.get("first").value,
        "last": this.creditCustomerPlan.get("last").value,
        "email": this.creditCustomerPlan.get("email").value,
        "company": this.creditCustomerPlan.get("company").value,
        "phone": this.creditCustomerPlan.get("phone").value
      },
      "language": this.creditCustomerPlan.get("language").value,
      "country": this.creditCustomerPlan.get("country").value,
      "lookup": {
        "custom": this.creditCustomerPlan.get("custom").value
      },
      "address": {
        "addressLine1": this.creditCustomerPlan.get("addressLine1").value,
        "addressLine2": this.creditCustomerPlan.get("addressLine2").value,
        "postalCode": this.creditCustomerPlan.get("postalCode").value,
        "city": this.creditCustomerPlan.get("city").value,
        "region": this.creditCustomerPlan.get("region").value,
      }
  }

    this.submitted = false;
    this._payement.updateCustomer(jsonData).subscribe((update) => {
        console.log("res123",update)
        this.toastMsgService.showMsg("success", "content updated", 2000);
        // if(update['status'] == 200){
        // }
      });
      
  }
  
  get_input(event){
    if(event.target.value.length !== 0 ){
      this.enable_button = true
      console.log("123",this.enable_button)
    }
  }

}