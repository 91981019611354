<!-- New UI -->
<nav *ngIf="displayMinimalNavbar" class="minimal-navbar-black up-navbar-container"
  [ngStyle]="{'background-color': ( _router.url == '/results/videos/view-video' || _router.url == '/results/clips/view-clip' || _router.url == '/results/highlight-reels/view') ? 'var(--black)' : 'var(--white)'}">
  <section class="up-navbar-content">
      <div class="up-navbar-img" [ngStyle]="{'color': ( _router.url == '/results/videos/view-video' || _router.url == '/results/clips/view-clip') ? 'var(--white)' : 'var(--black)'}">
          <a routerLink='/dashboard'>
              <img src="assets/images/favicon.svg" alt="logo">
          </a>

          <div class="go-back-container">
            <a (click)="navigateBackURL()" [ngStyle]="{'color': ( _router.url == '/results/videos/view-video' || _router.url == '/results/clips/view-clip' || _router.url == '/results/clips/view-clip' || _router.url == '/results/highlight-reels/view') ? 'var(--white)' : 'var(--black)'}">
              <img *ngIf="_router.url == '/results/videos/view-video' || _router.url == '/results/clips/view-clip' || _router.url == '/results/clips/view-clip' || _router.url == '/results/highlight-reels/view'" src="assets/images/icons/arrow-left-white.svg" alt="logo">
              <img *ngIf="_router.url != '/results/videos/view-video' || _router.url != '/results/clips/view-clip' || _router.url == '/results/clips/view-clip' || _router.url != '/results/highlight-reels/view'" src="assets/images/icons/arrow-left-black.svg" alt="logo"> Back
            </a>
          </div>

          <div class="minimal-navbar-title" [ngStyle]="{'color': ( _router.url == '/results/videos/view-video' || _router.url == '/results/clips/view-clip' || _router.url == '/results/clips/view-clip' || _router.url == '/results/highlight-reels/view') ? 'var(--white)' : 'var(--black)'}">
            {{miniNavTitle}}
          </div>
          
          <!-- <div style="margin-left: 20px;" *ngIf="_router.url == '/results/videos/view-video'">
            <ng-container *ngTemplateOutlet="rateTesterTemplate"></ng-container>
          </div> -->
          
        </div>

        <section class="tester-profile-popup-container" *ngIf="_router.url == '/results/videos/view-video'">
          <!-- <p class="tester-name-title" (click)="showTesterProfilePopup = !showTesterProfilePopup">{{miniNavTesterName}}</p> -->
          <p class="tester-name-title" (click)="showTesterProfile()">{{miniNavTesterName}}</p>
          <ng-container *ngTemplateOutlet="userProfilePopupTemplate"></ng-container>
        </section>


      <div class="up-navbar-profile-section">

        <!-- help icon -->
        
        <a class="help-icon-cont" href="https://helpcenter.userpeek.com/" target="_blank"> 
          <img *ngIf="_router.url == '/results/videos/view-video' || _router.url == '/results/clips/view-clip' || _router.url == '/results/clips/view-clip' || _router.url == '/results/highlight-reels/view'" class="help-icon" src="assets/images/icons/help-circle-white.svg" alt="logo">
          <img *ngIf="_router.url != '/results/videos/view-video' || _router.url != '/results/clips/view-clip' || _router.url != '/results/clips/view-clip' || _router.url == '/results/highlight-reels/view'" class="help-icon" src="assets/images/icons/help-circle-black.svg" alt="logo">
        </a>

        <!-- profile icon -->

        <label for="profile-icon-checkbox" id="profile-icon-checkbox-label" style="margin: 0px;">
          <img id="navbar-profile-icon" class="navbar-profile-icon" src="assets/images/icons/profile-user.png" alt="logo">
        </label>

        <input type="checkbox" id="profile-icon-checkbox" class="profile-icon-checkbox">

        <div style="color: #000;"  class="profile-dropdown-cont" id="profile-dropdown-cont">
          <p routerLink='/accountDetails'   style="margin-bottom: 20px;">Account Details</p>
          <p routerLink='/credits-plans'   style="margin-bottom: 20px;">Credits & Plans</p>
          <p routerLink='/billing-invoices'   style="margin-bottom: 30px;">Billing & Invoices</p>
          <p (click)='logout()'  class="navbar-logout" ><img src="assets/images/icons/log-out.svg" alt="logo">Logout</p>
        </div>

      </div>

  </section>

</nav>


<nav *ngIf="!displayMinimalNavbar" class="up-navbar-container" style="display: block;">
  <section class="up-navbar-content">

      <div class="up-navbar-img">
          <a routerLink='/dashboard'>
              <img src="assets/images/favicon.svg" alt="logo">
          </a>
      </div>

      <div class="up-navbar-links-section">
          <p routerLink='/dashboard' routerLinkActive="highlight-link" class="up-navbar-link">
              <img src="assets/images/icons/home-new.svg" alt="logo">
              Dashboard
          </p>
          <p routerLink='/projects' routerLinkActive="highlight-link" class="up-navbar-link">
              <img src="assets/images/icons/cube-new-icon.svg" alt="logo">
              Projects
          </p>
          <p (click)="navigateTab('research')"  class="up-navbar-link"
          [ngStyle]="{'border-bottom': showSubNavLink == '/research/tests'   ? '3px solid var(--blue)' : '3px solid var(--white)' }">
              <img src="assets/images/icons/research-icon.svg" alt="logo">
              Research
          </p>
          <p (click)="navigateTab('results')" class="up-navbar-link"
          [ngStyle]="{'border-bottom': showSubNavLink == '/results/videos' || showSubNavLink == '/results/clips' || showSubNavLink == '/results/highlight-reels'  ? '3px solid var(--blue)' : '3px solid var(--white)' }">
              <img src="assets/images/icons/result-icon.svg" alt="logo">
              Results
          </p>
          <p (click)="navigateTab('reports')" class="up-navbar-link" [ngStyle]="{'border-bottom': showSubNavLink == '/reports/test-reports'  ? '3px solid var(--blue)' : '3px solid var(--white)' }">
              <img style="height: 17px;" src="assets/images/icons/report-icon.svg" alt="logo">
              Reports
          </p>
      </div>

      <div class="up-navbar-profile-section">

        <!-- search bar -->
        <!-- <div class="up-search-container"  [ngStyle]="{'left': (navbarSearchIcon) ? '-190px' : '-60px' }">
          <img class="up-search-icon" (click)="navbarSearchIcon = !navbarSearchIcon" src="assets/images/icons/search-new-icon.svg" alt="search icon"/>
          <input placeholder="Search" *ngIf="navbarSearchIcon" class="up-search-input" type="text" [ngModel]="searchInputvalue" (ngModelChange)="handleSearchBoxValueChanges($event)"/>
        </div> -->
        <p routerLink='/credits-plans' style="margin: 0px 40px 0px 0px; text-decoration: underline; font-family: var(--font-regular); font-size: 17px; cursor: pointer;">
          Credits : {{currentUserCredit}}
        </p>

        <!-- help icon -->
        <a class="help-icon-cont" href="https://helpcenter.userpeek.com/" target="_blank"> 
          <img class="help-icon" src="assets/images/icons/help-circle-black.svg" alt="logo">
        </a>

        <!-- profile icon -->
        <label for="profile-icon-checkbox" id="profile-icon-checkbox-label" style="margin: 0px;"
        [ngStyle]="{'border-bottom': showSubNavLink == '/account-details' || showSubNavLink == '/billing-invoices' || showSubNavLink == '/credits-plans'   ? '3px solid var(--blue)' : '3px solid var(--white)' }">
          <img id="navbar-profile-icon" class="navbar-profile-icon" src="assets/images/icons/profile-user.png" alt="logo">
        </label>
        <input type="checkbox" id="profile-icon-checkbox" class="profile-icon-checkbox">

        <!-- Dropdown menu -->
        <div  class="profile-dropdown-cont" id="profile-dropdown-cont">
          <p routerLink='/account-details'   style="margin-bottom: 20px;">Account Details</p>
          <p routerLink='/credits-plans'   style="margin-bottom: 20px;">Credits & Plans</p>
          <p routerLink='/billing-invoices'   style="margin-bottom: 30px;">Billing & Invoices</p>
          <p (click)='logout()'  class="navbar-logout" ><img src="assets/images/icons/log-out.svg" alt="logo">Logout</p>
        </div>

      </div>

  </section>

</nav>

<!-- Sub - Navbar -->
<nav *ngIf="showSubNavbar" class="up-sub-nav-cont">
  <section class="up-sub-nav-content"> 
    <ng-container *ngIf="showSubNavLink == '/results/videos' || showSubNavLink == '/results/clips' || showSubNavLink == '/results/highlight-reels' || showSubNavLink == '/research/tests' || showSubNavLink == '/reports/test-reports' || showSubNavLink == '/results/findings' ">
      <div class="up-sub-nav-project-menu-cont">
        <select *ngIf="showNavbarProjectMenu"  class='test-list-input' [(ngModel)]="Samplevar1" (ngModelChange)="handleProjectIdChange($event)">
          <option *ngFor='let project of projectsList' [ngValue]='project.id'>{{ project.project_name }}</option>
        </select>
      </div>
    </ng-container>
    <!-- <p class="up-sub-nav-selected-content"> {{ navbarProjectTitle }} </p> -->

      <!--  Result Tab -->
      <div *ngIf="showSubNavLink == '/results/videos' || showSubNavLink == '/results/clips' || showSubNavLink == '/results/highlight-reels' || showSubNavLink == '/results/findings' " class="up-sub-nav-links">
        <p (click)="navigateSubNavbarLink('/results/videos')" class="up-sub-nav-video" style="padding-bottom: 18px;" 
          [ngStyle]="{'border-bottom': showSubNavLink == '/results/videos' ? '3px solid var(--blue)' : '3px solid var(--white)' }">
          <img src="assets/images/icons/video.svg" alt="logo"> Videos
        </p>
        <p (click)="navigateSubNavbarLink('/results/clips')" class="up-sub-nav-clip"
          [ngStyle]="{'border-bottom': showSubNavLink == '/results/clips' ? '3px solid var(--blue)' : '3px solid var(--white)' }">
          <img src="assets/images/icons/scissors.svg" alt="logo"> Clips
        </p>
        <p (click)="navigateSubNavbarLink('/results/findings')" class="up-sub-nav-clip"
          [ngStyle]="{'border-bottom': showSubNavLink == '/results/findings' ? '3px solid var(--blue)' : '3px solid var(--white)' }">
          <img style="height: 19px; width: auto;" src="assets/images/icons/file.svg" alt="logo"> Findings
        </p>
        <p (click)="navigateSubNavbarLink('/results/highlight-reels')" class="up-sub-nav-reel"
          [ngStyle]="{'border-bottom': showSubNavLink == '/results/highlight-reels' ? '3px solid var(--blue)' : '3px solid var(--white)' }">
          <img src="assets/images/icons/play-circle-icon.svg" alt="logo"> Highlight reels
        </p>
      </div>

      <!-- Research Tab -->
      <div *ngIf="showSubNavLink == '/research/tests' " class="up-sub-nav-links">
        <p (click)="navigateSubNavbarLink('/research/tests')" class="up-sub-nav-video" style="padding-bottom: 18px;" 
          [ngStyle]="{'border-bottom': showSubNavLink == '/research/tests' ? '3px solid var(--blue)' : '3px solid var(--white)' }">
          <img src="assets/images/icons/file.svg" alt="logo">Tests
        </p>
      </div>

      <!-- Report Tab -->
      <div *ngIf="showSubNavLink == '/reports/test-reports' " class="up-sub-nav-links">
        <p (click)="navigateSubNavbarLink('/reports/test-reports')" class="up-sub-nav-video" style="padding-bottom: 18px;" 
          [ngStyle]="{'border-bottom': showSubNavLink == '/reports/test-reports' ? '3px solid var(--blue)' : '3px solid var(--white)' }">
          <img src="assets/images/icons/file.svg" alt="logo">Test reports
        </p>
      </div>

      <!-- Accounts Tab -->
      <div *ngIf="showSubNavLink == '/account-details' || showSubNavLink == '/credits-plans' || showSubNavLink == '/billing-invoices' " class="up-sub-nav-links">
        <p (click)="navigateSubNavbarLink('/account-details')" class="up-sub-nav-video" 
          [ngStyle]="{'border-bottom': showSubNavLink == '/account-details' ? '3px solid var(--blue)' : '3px solid var(--white)' }">
          <img src="assets/images/icons/person.svg" alt="logo">Account Details
        </p>
        <p (click)="navigateSubNavbarLink('/credits-plans')" class="up-sub-nav-video" 
          [ngStyle]="{'border-bottom': showSubNavLink == '/credits-plans' ? '3px solid var(--blue)' : '3px solid var(--white)' }">
          <img src="assets/images/icons/target.svg" alt="logo">Credits and Plans
        </p>
        <p (click)="navigateSubNavbarLink('/billing-invoices')" class="up-sub-nav-video" 
          [ngStyle]="{'border-bottom': showSubNavLink == '/billing-invoices' ? '3px solid var(--blue)' : '3px solid var(--white)' }">
          <img src="assets/images/icons/credit-card.svg" alt="logo">Billing and Invoices
        </p>
      </div>

  </section>
</nav>

<!-- view video page *ngIf="showTesterProfilePopup" -->
<ng-template #userProfilePopupTemplate>
  <div class="tester-profile-popup-content" id="tester-profile-popup-content" >
    <p class="test-title">{{userSelectedVideoDetails["test_name"]}}</p>
    <p class="test-url">{{userSelectedVideoDetails["start_url"]}}</p>
    <div class="meta-data">
      <div class="meta-data-row1">
        <div class="meta-data-profile">
          <img src="https://picsum.photos/200/300?random=1" alt="profile image">
          <p>{{userSelectedVideoDetails["tester_name"]}}</p>
        </div>
        <div class="meta-data-rating">
          <img src="assets/images/icons/star-yellow.svg" alt="">
          <div>
            <h6>4,9</h6>
            <p>(283 reviews)</p>
          </div>
        </div>
      </div>
      <div class="meta-data-row2">
        <p>{{userSelectedVideoDetails["age"]}}</p>
        <img src="assets/images/icons/dot-white.svg" alt="">
        <p>{{userSelectedVideoDetails["gender"]}}</p>
        <img src="assets/images/icons/dot-white.svg" alt="">
        <p>{{userSelectedVideoDetails["country"]}}</p>
        <img src="assets/images/icons/dot-white.svg" alt="">
        <p>EN</p>
      </div>
      <div class="meta-data-row3">
        <ng-container *ngTemplateOutlet="rateTesterTemplate"></ng-container>
      </div>
    </div>
    <div class="test-device">
      <ng-container *ngIf="userSelectedVideoDetails.device_type === 'desktop'">
        <img src="assets/images/icons/monitor-grey.svg" alt="">
        <p>Desktop <span>(Chrome OS, 1024x780)</span></p>
      </ng-container>
      <ng-container *ngIf="userSelectedVideoDetails.device_type == 'smartphone'">
        <img src="assets/images/icons/smartphone.svg" alt="icon">
        <span>Smartphone <span>(Chrome OS, 1024x780)</span></span> 
      </ng-container>
      <ng-container *ngIf="userSelectedVideoDetails.device_type == 'tablet'">
        <img src="assets/images/icons/tablet.svg" alt="icon">
        <span>Tablet <span>(Chrome OS, 1024x780)</span></span> 
      </ng-container>
    </div>
    <div class="test-time">
      <img src="assets/images/icons/calendar-grey.svg" alt="">
      <!-- <p>Aug 21, 2022 <span>@11:01 AM</span></p> -->
      <p>{{formatDate(userSelectedVideoDetails.date_of_tested)}}</p>
    </div>
  </div>
</ng-template>

<!-- rate tester component -->
<ng-template #rateTesterTemplate>
  <div class="tester-rating-container">
    <div class="tester-rating-stars" *ngFor="let i of ratingList">
      <img (click)="addTestRating(i)" *ngIf="i > rating || rating == 0" class="star" src="assets/images/icons/star-grey.svg" />
      <img (click)="addTestRating(i)" class="star" *ngIf="i <= rating" src="assets/images/icons/star-yellow.svg" />
    </div>
    <p class="tester-rating-text">Please rate this tester</p>
  </div>
</ng-template>


<script src='assets/js/newCustomScript.js'></script>