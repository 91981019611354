import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ToastMsgService {

  public showToast$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public iconType$: BehaviorSubject<string> = new BehaviorSubject<string>("success");
  public toastText$: BehaviorSubject<any> = new BehaviorSubject<any>("Default Toast Message");

  constructor() { }

  

  showMsg(iconType: string, toastTest: any, timeout: any = 2500){
    this.showToast$.next(true);
    this.iconType$.next(iconType);
    this.toastText$.next(toastTest);

    setTimeout(() => { 
      this.dismissToast();
    }, timeout);  
  }


  dismissToast(){
    this.showToast$.next(false);
  }


}
