import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomPopupBoxService {

  public showPopup$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public contentType$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor() { }

  showPopup(contentType: string){
    this.showPopup$.next(true);
    this.contentType$.next(contentType);
  }

  dismissPopup(){
    this.showPopup$.next(false);
  }




}
