<div class="mt-2">
  <div class="font_weight pl-3 pr-4">
    <label style="font-size: 1.3vw;">Notes & Clips</label>
  </div>

  <!-- card form-->
  <div class="mt-3 pl-3" style="padding-right: 34px;">
    <mat-card>
      <form [formGroup]="formGroup" (ngSubmit)="clipVideo()">
        <mat-card-title>
          <div class="row col-12">
            <div>
              <img alt="video-edit" src="assets/images/icons/video-edit.png">
            </div>

            <div class="col-7" formGroupName="clip">
              <input formControlName="start" name="startTime" class="border input_style mr-1" mask="00:00" [dropSpecialCharacters]="false">
              <input formControlName="end" name="endTime" class="border input_style" mask="00:00" [dropSpecialCharacters]="false" (keydown)="validate($event, 'start')">
            </div>

            <div class="col-4">
              <section class="example-section">
                <mat-checkbox class="example-margin">Make Clip
                </mat-checkbox>
              </section>
            </div>
          </div>
        </mat-card-title>

        <mat-card-content>
          <div class="mt-3">
            <textarea formControlName="notes" placeholder="Start typing here..." rows="3"></textarea>
          </div>
        </mat-card-content>

        <mat-card-footer>
          <div class="d-flex justify-content-end">
            <button type="button" class="text-white btn mr-2" style="background: #6b6b6a;" (click)="formGroup.reset()">Cancel</button>
            <button type="submit" class="text-white btn" style="background: #1200ff;">Save</button>
          </div>
        </mat-card-footer>
      </form>
    </mat-card>
  </div>

  <div style="height: calc(100vh - 420px); overflow: auto;" class="px-3 pb-2 mt-2">
    <!-- card -->
    <div class="mt-3" *ngFor="let clippedVideo of clippedVideos">
      <mat-card>
        <mat-card-title>

          <div class="d-flex bd-highlight">
            <div class="p-2 bd-highlight">
              <img alt="media-icon" src="assets/images/icons/media.png" />
            </div>
            <div class="p-2 bd-highlight text_color">
              {{clippedVideo.start_duration}} - {{clippedVideo.end_duration}}
            </div>
            <div class="ml-auto p-2 bd-highlight col-auto">
            <span class="userpeek-dropdowns-sub">

              <button class="testedit-btn userpeek-dropdowns-btn submenubtn"
                      #popoverTrigger="mdePopoverTrigger"
                      [mdePopoverTriggerFor]="appPopover"
                      mdePopoverTriggerOn="click">
                <mat-icon>more_horiz</mat-icon>
              </button>

              <!-- sub menu -->
              <mde-popover #appPopover="mdePopover" [mdePopoverArrowWidth]="0"
                           [mdePopoverOffsetX]="-10" [mdePopoverOverlapTrigger]="false">
                <div class="share-btn-popover-body">
                  <span class="userpeek-dropdowns-sub">
                    <div class='userpeek-dropdowns' style='display: block'>
                      <ul>
                        <li class='cursor-pointer'><a (click)="clickClippedNotes(clippedVideo, EDIT)">Edit</a></li>
                        <li class='cursor-pointer'><a (click)="clickClippedNotes(clippedVideo, DOWNLOAD)">Download</a> </li>
                        <li class='cursor-pointer'><a (click)="clickClippedNotes(clippedVideo, DELETE)">Delete</a></li>
                      </ul>
                    </div>
                  </span>
                </div>
              </mde-popover>
            </span>
            </div>
          </div>
        </mat-card-title>

        <mat-card-content [innerHTML]="clippedVideo.notes">
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
