<div class='tests-container lg-screen-width videoinfo-wrap'>
  <div class='section videoinfo-section w-100 h-100'>

    <mat-drawer-container class="w-100 h-100">
      <mat-drawer #drawer class="overflow-none" mode="side" position="end">

        <mat-tab-group md-no-pagination class="fixed-header">

          <!-- Tab 1 info -->
          <mat-tab label="info">
            <ng-template mat-tab-label>
              <img src="assets/images/icons/info.png" />
            </ng-template>
            <!-- info tab contant -->
            <app-info-tab [selectedTestDetails]="selectedTestDetails"></app-info-tab>
          </mat-tab>

          <!-- Tab 2  -->
          <mat-tab label="taskDetails">
            <ng-template mat-tab-label>
              <img src="assets/images/icons/task-icon.png" />
            </ng-template>
            <app-taskdetails-tab></app-taskdetails-tab>
          </mat-tab>

          <!-- Tab 3 -->
          <mat-tab label="Notes & Clips">
            <ng-template mat-tab-label>
              <img src="assets/images/icons/transcript-icon.png" />
            </ng-template>
            <app-notesandclips-tab [selectedTestDetails]="selectedTestDetails"></app-notesandclips-tab>
          </mat-tab>

          <!-- Tab 4 -->
          <!-- <mat-tab label="Speech to text">
            <ng-template mat-tab-label>
              <img src="assets/images/icons/speech-to-text.png" />
            </ng-template>
            <app-transcript></app-transcript>
          </mat-tab> -->

          <mat-tab disabled label="close sidebar">
            <ng-template mat-tab-label>
              <button type="button" mat-button (click)="drawer.toggle()" *ngIf="true">
                <span #showCross class="material-icons">chevron_right</span>
              </button>
            </ng-template>
          </mat-tab>

        </mat-tab-group>

      </mat-drawer>

      <div class="sidenav-content">

        <!-- video -->
        <div [ngClass]="arrow ? 'hide' : ''" class='video-player video-view d-flex align-items-center' id="vid">

          <!-- drawer btn after-->
          <div class="d-flex justify-content-end close_vid">
            <button type="button" mat-button (click)="drawer.toggle()" *ngIf="true">
              <span class="material-icons bg-light" *ngIf="!drawer.opened;">chevron_left</span>
            </button>
          </div>
          <app-video-player *ngIf="selectedTestDetails" [selectedTestDetails]="selectedTestDetails"
                            style="width: 100%;"
                            [transcribedData]="transcribedData" [resultData]="resultData"
                            (transcriptionDisplay)=transcriptionDisplay()></app-video-player>
        </div>

      </div>

    </mat-drawer-container>
  </div>
</div>
