import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreateTestsComponent} from './create-tests/create-tests.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from 'src/app/shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
  declarations: [CreateTestsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DragDropModule,
    MatIconModule
  ]
})
export class TestsClientModule {

}
