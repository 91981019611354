import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { AuthService } from 'src/app/shared/services/auth.service';
import { TokenParms } from 'src/app/core/models/token-parms';
import { DashboardClientService } from 'src/app/shared/services/dashboard-client.service';
import { ToastrService } from 'ngx-toastr';
import { ModelBoxService } from 'src/app/shared/services/model-box.service';
import { UserService } from '../../../shared/services/user.service';
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';
import { GeneralApiService } from '../../api-call/general-api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  error = false;
  resetURL;
  urlReset: any;
  errorMsg: string;
  responseError: any;
  submitted: boolean = false;
  urlType: string;
  notUser: string;
  fieldTextType: boolean;

  constructor(
    private _authServices: AuthService,
    private userService: UserService,
    private formbuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _dashboardClient: DashboardClientService,
    private _spinnerService: NgxSpinnerService,
    public toastr: ToastrService,
    private _modelBoxService: ModelBoxService,
    private toastMsgService: ToastMsgService,
    private generalApi: GeneralApiService,
  ) {
  }

  get f() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    this.route.url.subscribe((url) => (this.urlType = url[0].path));
    this.setFormState();
    this.urlReset = this._authServices.url;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  setFormState(): void {
    this.loginForm = this.formbuilder.group({
      // username: ['piyushrai2', [Validators.required]],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[A-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      password: ["", [Validators.required]],
    });
  }

  onLogin() {
    let that = this;
    that.submitted = true;
    if (that.loginForm.invalid) {
      return;
    }
    that.submitted = true;

    this._spinnerService.show();

    if (!window.navigator.onLine) {
      this._spinnerService.hide();
      this.responseError = 'Server is not responding';
    } else {
      window['grecaptcha'].ready(function () {
        window['grecaptcha']
          .execute('6LcXITEaAAAAAJ-PIRcGLjGH_EaZbplqPL7Xm8kX', {
            action: 'submit',
          })
          .then(function (token) {
            that.submitted = true;
            if (that.loginForm.invalid) {
              return;
            }
            that.submitted = true;
            let login = that.loginForm.value;
            login['captchaToken'] = token;
            that.login(login);
          });
      });
    }
  }

  login(login: TokenParms) {
    this.userService.login(login).subscribe((employee) => {
      console.log(employee)
      this._spinnerService.hide();
      this._dashboardClient.setData(employee);

      localStorage.setItem('data', employee.username);
      localStorage.setItem('usertype', employee.usertype);
      localStorage.setItem('user_id', employee.user_id);

      var status = employee['status'];
      var usertype = employee['usertype'];
      this.responseError = employee['message'];

      if (status == 200 && usertype == 'client') {
        this.loginForm.reset();
        localStorage.setItem('token_id', employee['key']);
        this.toastMsgService.showMsg("success", 'Login successful!');
        // Note: remove selected project local storage variable to avoid cache issue
        localStorage.removeItem("selectedProjectId");
        this.generalApi.getPrevProjectDetails().subscribe((apiResponse: any) => {
          if ((apiResponse.status = 200) || (apiResponse.status = 201)) {
            if (apiResponse.data.last_project_seen !== null) {
              // localStorage.setItem("headerProjectName", projectDetials.project_name);
              localStorage.setItem("selectedProjectId", apiResponse.data.last_project_seen);
              this.router.navigate(['/research/tests'], { queryParams: { 'project-id': apiResponse.data.last_project_seen } });
            }
            else {
              this.router.navigate(['dashboard']);
            }
          }
        })
        // this._modelBoxService.showSuccessToster('Login successful!', 'Success!');
      } else if (status == 401 || status == 400) {
        // this.responseError = this.responseError;
        // this.responseError = 'No account using these credentials found. Please check email and password';
      } else {
        this.error = true;
        this.responseError = 'No account using these credentials found. Please check email and password';
      }
    }, (err) => {
      this.errorMsg = err;
    });
  }

  //  Reset Password

  resetpass() {
    this._authServices.forgetPassword().subscribe((res) => {
      this.resetURL = res;

      this.router.navigate(['res']);
    });
  }

}
