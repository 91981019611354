

import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/api-call/base.service';
import {appApiResources} from './api.constant';
import {Observable, Subject} from 'rxjs';
import {catchError} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})

export class HighlightReelApiService extends BaseService {

  // Create highlight reel
  createHighlightReel(bodyJson) {
    return this.post(appApiResources.createhighlightReel, bodyJson);
  }
  
  // update highlight reel
  updateHighlightReel(bodyJson) {
    return this.put(appApiResources.createhighlightReel, bodyJson);
  }

  // Get all clips to create reel
  getAllClips() {
    return this.get(appApiResources.getAllClips);
  }
  
  // Get all highlight Reels List
  getAllHighlightReels(): Observable<any> {
    return this.get(appApiResources.getAllHighlightReels);
    // return this.get("https://61ece4cbf3011500174d2240.mockapi.io/testng");
  }
  
  // New APIs
  // Get highlight Reels with project id
  getHighlightReelsWithProjectId(project_id) {
    return this.get(appApiResources.highlightReelsWithProjectId, {project_id: project_id}).pipe(catchError(this.handleError));
  }

  getHighlightReelsWithProjectIdAndTestId(project_id, test_id) {
    return this.get(appApiResources.highlightReelsWithProjectId, {project_id: project_id, test_id: test_id}).pipe(catchError(this.handleError));
  }

  getHighlightReelsWithReelId(reel_id) {
    return this.get(appApiResources.highlightReelsWithProjectId, {id: reel_id}).pipe(catchError(this.handleError));
  }

  newCreateHighlightReel(bodyJson) {
    return this.post(appApiResources.newHighlightReel, bodyJson);
  }

  newUpdateHighlightReel(bodyJson) {
    return this.put(appApiResources.newHighlightReel, bodyJson);
  }

  

}


// getVideosWithProjectIdAndTestId(project_id, test_id) {
//   return this.get(appApiResources.videosWithProjectId, {project_id: project_id, test_id: test_id}).pipe(catchError(this.handleError));
// }

// getClipsWithProjectId(project_id) {
//   return this.get(appApiResources.clipsWithProjectId, {project_id: project_id}).pipe(catchError(this.handleError));
// }