<div class="up-vid-player-wrapper">
    <div #videoplayercontainer class="up-vid-container">
        <video #videoplayer
            [ngClass]="{'up-vid-player': fullscreen == false,'  up-vid-player-fullscreen': fullscreen == true}"
            (mousemove)="startTimer()"
            (click)="PlayVideo()"
            (timeupdate)="updateTimeElapsed()">
            <source [src]="video_link">
        </video>

        <!-- video-player-controlls -->
        <div id="div-hide" class="up-vid-player-controlls">
            <!-- <div> -->

                <!-- <progress [value]="PerfectValue(videocurrentbyseconds)" [max]="PerfectValue(videodurationbyseconds-1)" #progress class="progress"></progress>
                <input type="range" #range class="range" id="range"  [value]="videocurrentbyseconds" [max]="videodurationbyseconds" (mousedown)="PauseVideo($event)" (change)="PlayVideo()"  /> -->

                <div class="progess" id="progess" (click)="ChangeTimeRange($event)">
                    <div id="fill" class="fill"></div>
                </div>

            <!-- </div> -->
            <div #playercontrolls class="up-vid-player-btn"
                [ngClass]="{'up-vid-player-btn' : fullscreen == false, 'up-vid-player-btn-fullscreen' : fullscreen == true}">
                <div class="up-vid-div-one">
                    <button class="up-vid-btn" (click)="PlayVideo()">
                        <img class="up-vid-play-btn" src="assets/images/icons/play.svg"
                            *ngIf="videoplaying == false">
                        <img class="up-vid-pause-btn" src="assets/images/icons/pause.png"
                            *ngIf="videoplaying == true">
                    </button>

                    <button (click)="BackwordVideo()" class="up-vid-btn up-vid-backword-btn">
                        <img src="assets/images/icons/skip-forward.svg">
                    </button>

                    <button (click)="ForwordVideo()" class="up-vid-btn up-vid-forword-btn">
                        <img src="assets/images/icons/skip-forward.svg">
                    </button>

                    <button class="up-vid-btn up-vid-speaker-btn" (click)="SoundControll()">
                        <img src="assets/images/icons/speaker-white.svg" *ngIf="videosound == true">
                        <img src="assets/images/icons/mute.svg" *ngIf="videosound == false">
                    </button>

                    <div class="duration up-vid-duration ">
                        <time #timeelapsed>{{videocurrentbytime}}</time>
                        <span> / </span>
                        <time #duration>{{videodurationbytime}}</time>
                    </div>
                </div>

                <!-- <div class="up-vid-div-two">
                    <button class="">
                        <img src="assets/images/icons/scissors.svg">
                        <div>Create clip</div>
                    </button>
                </div> -->

                <div class="up-vid-div-three">
                    <div class="playbackspeed">
                        <button class="up-vid-btn-playbackspeed" style="color: white;" (click)="PlaybackSpeedList()">
                            {{defaultspeed.speed == "Normal" ? "1x" : defaultspeed.speed+"x"}}
                        </button>

                        <div class="up-vid-playbackspeed" *ngIf="showspeedlist == true">
                            <div *ngFor="let speed of playbackspeed" class="up-vid-playback-list">
                                <button (click)="ChangePlaybackSpeed(speed.speed == 'Normal' ? '1' : speed.speed)"
                                    [key]="speed.id">{{speed.speed == 'Normal' ? 'Normal' : speed.speed+'x'}}
                                </button>
                            </div>
                        </div>
                    </div>

                    <button class="up-vid-btn up-vid-pip-btn" (click)="PictureInPicture()">
                        <img src="assets/images/icons/square.svg">
                        <img class="up-vid-small-square" src="assets/images/icons/square-1.svg">
                    </button>

                    <button class="up-vid-btn up-vid-fullscreen" (click)="FullScreen()">
                        <img src="assets/images/icons/maximize.svg" *ngIf="fullscreen == false">
                        <img src="assets/images/icons/minimize-video.svg" *ngIf="fullscreen == true">
                    </button>

                    <!-- <button class="up-vid-btn up-vid-warn">
                        <img src="assets/images/icons/alert-triangle.svg">
                    </button> -->

                    <a href="{{video_link}}" download="{{video_link}}" (click)="DownloadVideo()" #downloadvideo
                        class="up-vid-download">
                        <img src="assets/images/icons/download.svg">
                    </a>

                    <div id="copyvideolink" style="display:none;">
                        {{video_link}}
                    </div>

                    <button  (click)="copyVideoUrlToClipboard()" class="up-vid-btn up-vid-share">
                        <img src="assets/images/icons/share-2.svg">
                    </button>
                </div>


            </div>
        </div>
    </div>
</div>