import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, Renderer2, ElementRef} from '@angular/core';
import {NavigationEnd, Router, Params, ActivatedRoute} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {PaymentService} from '../services/payment.service';
import {NgxSpinnerService} from 'ngx-spinner';
// import {ToastrService} from 'ngx-toastr';
import {ModelBoxService} from '../services/model-box.service';
import { SearchBoxInputService } from '../services/search-box-input.service';
import { HighlightReelUpdateService } from '../services/highlight-reel-update.service';
import {CreateNewTestService} from 'src/app/shared/services/create-new-test.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import { ToastMsgService } from '../services/toast-msg.service';
import { GeneralApiService } from 'src/app/core/api-call/general-api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit, OnDestroy{
  showMenuBar;
  showDropDown;
  showAccordlinks;

  showHeader: boolean = false;
  testerVideoShow;
  showSubMenu: boolean = false;
  showTesterProfilePopup: boolean = false;

  userNameShow: string;
  totalCredits = 0;
  availableCoupons: any;
  couponCheck: any;
  coupondata: any;
  subscriptionCheck: any;
  displayMinimalNavbar: boolean = false;
  
  // New Var
  isCouponAvailable = false;
  private isClickOutside = false;

  showProfileDropdown: boolean = false;

  // sub nav bar
  showSubNavbar: boolean = false;
  showSubNavLink: string = "";
  goBackUrl: any;
  miniNavTitle: any = "";
  miniNavTesterName: any = "";
  navbarProjectTitle: any;
  selectedTestId: number;

  // search bar
  navbarSearchIcon: boolean = false;
  searchInputvalue: string = "";

  // queryparams
  queryParamsData: any;
  projectsList: any;
  subNavbarSelectedProjectId: any;
  projectIdFormControl = 24;
  showNavbarProjectMenu: boolean = false;
  projectMenuForm: FormGroup;
  Samplevar1: any;
  currentUserCredit: any = 0;

  // view video page
  userSelectedVideoDetails: any = {};
  ratingList: Array<number> = [1,2,3,4,5];
  rating: number = 0;
  tempRatingStore: any;

  constructor(
    private _authService: AuthService,
    public _router: Router,
    private _payement: PaymentService,
    private _spinnerSer: NgxSpinnerService,
    // private toastr: ToastrService,
    private toastMsgService: ToastMsgService,
    private _modelBoxServ: ModelBoxService,
    private _activatedRoute: ActivatedRoute,
    private _searchBoxInputService: SearchBoxInputService,
    private _highlightReelUpdateService: HighlightReelUpdateService,
    private _createTestServices: CreateNewTestService,
    private _formBuilder: FormBuilder,
    private _generalApiService: GeneralApiService,
    private renderer: Renderer2, 
    private el: ElementRef,
    private generalApi: GeneralApiService,

  ) {
    this.userNameShow = localStorage.getItem('data');
    this.showMinimalNavbar(this._router.url);
    this.subscribesRouteChange();
    // this.subscribesChangesInCredits();
  }

  ngOnDestroy(): void {
    // localStorage.removeItem("cacheSelectedVideoDetials");
  }

  ngOnInit() {
    // get current user credit points :
    this._generalApiService.stripeCustomer().subscribe((apiResponse)=>{
      if(apiResponse['data']){
        this.currentUserCredit = apiResponse['data']["current_credit"];
      }
    });
    this._generalApiService.sharedCreditScore.subscribe((newValue)=>{
      this.currentUserCredit = newValue;
    })


    this.showProfileDropdown = false;

    // const listener = this.renderer.listen(this.el.nativeElement, 'click', (event) => {
    //   console.log('header component: ', event)
    // });



    // this.getAllCredits();
    // this.getAvailableCoupon()
    // this._payement.getAllOrder().subscribe(res=>{

    //   this.couponCheck = res[0].items[0].coupon
    // })
    // new Changes for Coupon Display start
    // this._payement.getAllOrder().subscribe(res => {
    //   if (res['length'] <= 0) {
    //     this.isCouponAvailable = true
    //   }
    // })
    // new Changes for Coupon Display End

    // this._authService.couponAvlData.subscribe(res => {

    //   this.coupondata = res
    // })

    // call API, after redirect form credit-plans-component
    // this._activatedRoute.queryParamMap.subscribe((reqparams: Params) => {
    //   if (reqparams.params['creditPlansComponent']) {
    //     this.getAllCredits();
    //   }
    // })


    // this._payement.getCustomer().subscribe((creditDetials) => {
    //   if(creditDetials['data'].length <= 0){
    //     this.currentUserCredit = 0;
    //   }
    //   else {
    //     this.currentUserCredit = creditDetials['data'].current_credit;
    //   }
    // });
  }


  // // mouse click event listener
  // @HostListener('document:click', ['$event'])
  // onClick(event: MouseEvent): void {
  //   console.log('header', event.target)
  //   // tester info popup
  //   if((event.target as HTMLElement).closest(".tester-name-title") == null){
  //     if((event.target as HTMLElement).closest(".tester-profile-popup-content") == null){
  //       console.log('event.target inner', event.target)
  //       if(this.showTesterProfilePopup === true){
  //         this.showTesterProfilePopup = false;
  //       }
  //     }
  //   }
  // }



  handleSearchBoxValueChanges(value){
    this._searchBoxInputService.sendSearchBoxValue(this._router.url, value);
  }



  subscribesRouteChange() {
    this.setHeaderOptions(this._router.url);
    this.showSubNavbarFun(this._router.url);
    this.initialzeSubNavbarProjectMenu();
    this._router.events.subscribe((event: any) => {
      
      if (event instanceof NavigationEnd) {
        this.setHeaderOptions(event.url);
        this.showSubNavbarFun(event.url);
        this.showMinimalNavbar(event.url);
        if(!event.url.includes('login') && !event.url.includes('register')){
          this.initialzeSubNavbarProjectMenu();
        }
      }
    });
  }


  // getAvailableCoupon() {
  //   this._spinnerSer.show()
  //   this._payement
  //     .getAvailableCoupon()
  //     .subscribe(res => {
  //       this._spinnerSer.hide()
  //       this.availableCoupons = res['data'] || []
  //     })
  // }

  setHeaderOptions(route) {
    if (route === '/dashboard_tests/create_test' || route.includes('/dashboard_tests/edit/')) {
      this.showHeader = true;
      this.testerVideoShow = true;
    } else if (route.includes('/videos/videos_info/')) {
      this.testerVideoShow = true;                                                                                                                                                                                                                                                                                                                                 
      this.showHeader = true;
    } else if (route.includes('/videos/example-video/')) {
      this.testerVideoShow = true;
      this.showHeader = true;
    } else if (route.includes('/highlight_reel/create_highlight_reel')) {
      this.testerVideoShow = true;
      this.showHeader = true;
    } else if (route.includes('/highlight_reel/update_highlight_reel')) {
      this.testerVideoShow = true;
      this.showHeader = true;
    }else {
      this.showHeader = false;
      this.testerVideoShow = false;
    }
  }

  // subscribesChangesInCredits() {
  //   this._authService.changesInCredits.subscribe((credits) => {
  //     this.currentUserCredit = credits;
  //   });
  // }

  
  // getAllCredits() {
  //   this._spinnerSer.show()
  //   this._payement.getCustomer().subscribe((allOrder) => {
  //     this._spinnerSer.hide()

  //     if (allOrder['data']) {
  //       this.subscriptionCheck = allOrder['data'].subscriptions
  //     }
  //     // get total credits
  //     if (allOrder['data'] && allOrder['data'].current_credit) {

  //       this.totalCredits = allOrder['data'].current_credit;
  //       this._authService.changesInCredits.next(this.totalCredits);

  //       // update total credits
  //     } else {
  //       this.totalCredits = 0;
  //     }
  //     this._authService.changesInCredits.next(this.totalCredits);

  //   });
  // }

  // @HostListener('click')
  // clickInside() {
  //   this.isClickOutside = true;
  // }

  // @HostListener('document:click')
  // clickout() {
  //   if (!this.isClickOutside) {
  //     this.showSubMenu = false;
  //   }
  //   this.isClickOutside = false;
  // }

  toggleShow() {
    this.showSubMenu = !this.showSubMenu;
  }

  callForCreditSessionFun() {
    this._router.navigate(['creditPlan'])
    // setTimeout(() => {
    //   this._payement.sendEasyStartPlan({name: 'easystart', totalCredit: 1, perCreditCost: 11, type: 'easystart'})

    //   }, 100);
  }

  logout() {
    this._authService.logout().subscribe((res) => {
      // localStorage.removeItem('token_id');
      localStorage.clear();
      this.toastMsgService.showMsg("success", 'Logout successful!');
      this._router.navigate(['login']);
    });
  }

  goBack() {
    if (window.history) {
      window.history.back();
    } else {
      this._router.navigateByUrl('/videos');
    }
  }


  // new code
  showSubNavbarFun(route){
    if(localStorage.getItem("headerProjectName") === null || localStorage.getItem("headerProjectName") === "") {
      this.navbarProjectTitle = ""
    } else{
      this.navbarProjectTitle = localStorage.getItem("headerProjectName");
    }

    if (/\/research\/tests\?project\-id=[0-9]+/i.test(route)) {
      this.showSubNavbar = true;
      this.showSubNavLink = "/research/tests";
    }
    else if (/\/results\/videos\?project\-id=[0-9]+/i.test(route) || /\/results\/videos\?project\-id=[0-9]+\&test\-id=[0-9]+/i.test(route)) {
      this.showSubNavbar = true;
      this.showSubNavLink = "/results/videos";
    }
    else if (/\/results\/clips\?project\-id=[0-9]+/i.test(route) || /\/results\/clips\?project\-id=[0-9]+\&test\-id=[0-9]+/i.test(route)) {
      this.showSubNavbar = true;
      this.showSubNavLink = "/results/clips";
    }
    else if (/\/results\/findings\?project\-id=[0-9]+/i.test(route) || /\/results\/findings\?project\-id=[0-9]+\&test\-id=[0-9]+/i.test(route)) {
      this.showSubNavbar = true;
      this.showSubNavLink = "/results/findings";
    }
    else if (/\/results\/highlight-reels\?project\-id=[0-9]+/i.test(route) || /\/results\/highlight-reels\?project\-id=[0-9]+\&test\-id=[0-9]+/i.test(route)) {
      this.showSubNavbar = true;
      this.showSubNavLink = "/results/highlight-reels";
    }
    else if (/\/reports\/test-reports\?project\-id=[0-9]+/i.test(route)) {
      this.showSubNavbar = true;
      this.showSubNavLink = "/reports/test-reports";
    }
    else if(route === "/dashboard" || route === "/projects" || route === "/project-selector"){
      this.showSubNavbar = false;
      this.showSubNavLink = "";
      this.navbarProjectTitle = "";
    }
    else if (/\/credits-plans.*/i.test(route)) {
      this.showSubNavbar = true;
      this.showSubNavLink = "/credits-plans";
    }
    else {
      this.navbarProjectTitle = "";
      this.showSubNavbar = true;
      this.showSubNavLink = route;
    }
  }


  navigateBackURL(){
    this.displayMinimalNavbar = false;
    if(/\/results\/videos\/view-video/i.test(this._router.url)){
      this.showSubNavbar = true;
      this.showSubNavLink = "/results/videos";
      this.navigateBackURLUtility("/results/videos");
    }
    else if(/\/results\/clips\/view-clip.*/i.test(this._router.url)){
      this.showSubNavbar = true;
      this.showSubNavLink = "/results/clips";
      this.navigateBackURLUtility("/results/clips");
    }
    else if(/\/results\/highlight-reels\/view.*/i.test(this._router.url)){
      this.showSubNavbar = true;
      this.showSubNavLink = "/results/highlight-reels";
      this.navigateBackURLUtility("/results/highlight-reels");
    }
    else if(/\/results\/highlight-reels\/create.*/i.test(this._router.url) || /\/results\/highlight-reels\/edit.*/i.test(this._router.url) ){
      this.showSubNavbar = true;
      this.showSubNavLink = "/results/highlight-reels";
      this.navigateBackURLUtility("/results/highlight-reels");
    }
    else if(/\/research\/tests\/create-test.*/i.test(this._router.url) ){
      let tempQueryParams = this._router.routerState.snapshot.root.queryParams;
      if(tempQueryParams.currentPage === "page1"){
        this.showSubNavbar = true;
        this.showSubNavLink = "/research/tests";
        this.navigateBackURLUtility("/research/tests");
      } else if(tempQueryParams.currentPage === "page2"){
        this._router.navigate([],{queryParams: {"currentPage": "page1"}, queryParamsHandling: 'merge', });
      } else {
        this.showSubNavbar = true;
        this.showSubNavLink = "/research/tests";
        this.navigateBackURLUtility("/research/tests");
      }
    }
  }
  navigateBackURLUtility(url){
    if (localStorage.getItem("selectedTestId") === null || localStorage.getItem("selectedTestId") === "") {
      let projectId = localStorage.getItem("selectedProjectId");
      this._router.navigate([url], {queryParams: { 'project-id': projectId}});
    } else {
      let projectId = localStorage.getItem("selectedProjectId");
      let testId = localStorage.getItem("selectedTestId") ;
      this._router.navigate([url], {queryParams: { 'project-id': projectId, 'test-id': testId}});
    }
  }


  showMinimalNavbar(value){
    if (/\/results\/videos\/view-video.*/i.test(value)) {
      this.displayMinimalNavbar = true;
      let jsonData =  JSON.parse(localStorage.getItem("cacheSelectedVideoDetials"));
      this.userSelectedVideoDetails = jsonData;
      this.miniNavTitle = jsonData.test_name;
      this.miniNavTesterName = jsonData.tester_name;
      this.getTestRating()
    } 
    else if (/\/results\/clips\/view-clip.*/i.test(value)) {
      this.displayMinimalNavbar = true;
      let jsonData =  JSON.parse(localStorage.getItem("cacheSelectedVideoDetials"));
      this.miniNavTitle = jsonData.clip_name;
      this.miniNavTesterName = jsonData.tester_name;
    } 
    else if (/\/results\/highlight-reels\/view.*/i.test(value)) {
      this.displayMinimalNavbar = true;
      let jsonData =  JSON.parse(localStorage.getItem("cacheSelectedVideoDetials"));
      this.miniNavTitle = jsonData.highlight_reel_name;
      this.miniNavTesterName = "";
    } 
    else if (/\/results\/highlight-reels\/create.*/i.test(value)) { 
      this.displayMinimalNavbar = true;
      this.miniNavTitle = "Create Highlight reel";
      this.miniNavTesterName = "";
    }
    else if (/\/results\/highlight-reels\/edit.*/i.test(value)) {
      this.displayMinimalNavbar = true;
      let reelTitle = "";
      this._highlightReelUpdateService.currentData.subscribe((newData: any)=>{
        reelTitle = newData["highlight_reel_name"];
        this.miniNavTitle = reelTitle;
      })
      this.miniNavTesterName = "";
    } 
    else if (/\/research\/tests\/create-test.*/i.test(value)) {
      this.displayMinimalNavbar = true;
      this.miniNavTitle = "";
      this.miniNavTesterName = "";
    } 
    else{
      this.displayMinimalNavbar = false;
    }
  }


  navigateTab(tabHeading){
    let tempSelectedProjectId;
    if (localStorage.getItem("selectedProjectId") === null || localStorage.getItem("selectedProjectId") === "") {
      if(tabHeading == "research"){
        localStorage.setItem("selectedRoute", '/research/tests');
      }
      else if(tabHeading == "results"){
        localStorage.setItem("selectedRoute", '/results/videos');
      }
      else if(tabHeading == "reports"){
        localStorage.setItem("selectedRoute", '/reports/test-reports');
      }
      this._router.navigate(['/project-selector']);
    }
    else {
      tempSelectedProjectId = Number(localStorage.getItem("selectedProjectId"));
      if(tabHeading == "research"){
        this._router.navigate(['/research/tests'], {queryParams: {'project-id': tempSelectedProjectId}});
      }
      else if(tabHeading == "results"){
        this._router.navigate(['/results/videos'], {queryParams: {'project-id': tempSelectedProjectId}});
      }
      else if(tabHeading == "reports"){
        this._router.navigate(['/reports/test-reports'], {queryParams: {'project-id': tempSelectedProjectId}});
      }
    }
  }


  initialzeSubNavbarProjectMenu(){
    this._createTestServices.gelAllProjectList().subscribe((res) => {
      this.projectsList = res.data;
      this.getQueryParamsInfo();
      if(this.queryParamsData.result === "projectId&testId" || this.queryParamsData.result === "projectId"){
        this.showNavbarProjectMenu = true;
        this.Samplevar1 = Number(this.queryParamsData.projectId);
      }
      else if(this.queryParamsData.result === "project-selector"){
        this.showNavbarProjectMenu = false;
      }
    });
  }

  handleProjectIdChange(event){
    localStorage.setItem("selectedProjectId", event)
    let body ={
      "last_project_seen": event
    }
    this.generalApi.updateProjectdata(body).subscribe((apiResponse)=>{
      
    });
    this._router.navigate([ this._router.url.split('?')[0] ], { queryParams: {"project-id": event}, });
  }

  navigateSubNavbarLink(link){
    this.getQueryParamsInfo();
    if(link == "/results/videos" || link == "/results/clips" || link == "/results/highlight-reels" || link == "/results/findings"){
      this.showSubNavbar = true;
      this.showSubNavLink = link;
      if(this.queryParamsData.result === "projectId&testId"){
        this._router.navigate([`${link}`], {queryParams: {'project-id': this.queryParamsData.projectId, 'test-id': this.queryParamsData.testId}});
      } 
      else if(this.queryParamsData.result === "projectId"){
        this._router.navigate([`${link}`], {queryParams: {'project-id': this.queryParamsData.projectId}});
      } 
      else if(this.queryParamsData.result === "project-selector"){
        this._router.navigate(['/project-selector']);
      }
    }
    else if(link == "/research/tests"){
      this.showSubNavbar = true;
      this.showSubNavLink = link;
      if(this.queryParamsData.result === "projectId"){
        this._router.navigate([`${link}`], {queryParams: {'project-id': this.queryParamsData.projectId}});
      } 
      else if(this.queryParamsData.result === "project-selector"){
        this._router.navigate(['/project-selector']);
      }
    }
    else if(link == "/account-details" || link == "/credits-plans" || link == "/billing-invoices"){
      this.showSubNavbar = true;
      this.showSubNavLink = link;
      this._router.navigateByUrl(link);
    }
  }


  getQueryParamsInfo(): any {
    let tempResponsedata = {};
    this._activatedRoute.queryParams.subscribe((params) => {
      if(params['project-id'] != undefined && params['test-id'] != undefined){
        tempResponsedata["result"] = "projectId&testId";
        tempResponsedata["projectId"] = params['project-id'];
        tempResponsedata["testId"] = params['test-id'];
        this.queryParamsData = tempResponsedata;
      }
      else if(params['project-id'] != undefined){
        tempResponsedata["result"] = "projectId";
        tempResponsedata["projectId"] = params['project-id'];
        this.queryParamsData = tempResponsedata;
      } 
      else {  
        tempResponsedata["result"] = "project-selector";
        this.queryParamsData = tempResponsedata;
      }
    });
  }

  // view video page

  formatDate(timestamp) {
    const date = new Date(timestamp);
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${month},${day} ${year} @${formattedHours}:${minutes} ${ampm}`;
  }

  getTestRating() {
    console.log(this.rating)
    this._generalApiService.getTestRating(this.userSelectedVideoDetails.id).subscribe((res)=>{
      this.tempRatingStore = res;
      this.rating = this.tempRatingStore.data[0].rating;
    });
  }

  addTestRating(value: number) {
    let body = {
      'feed_back': ' ',
      'id': this.userSelectedVideoDetails.id,
      'test': this.userSelectedVideoDetails.test,
      'rating': value
    }
    this._generalApiService.addTestRating(body).subscribe((res) => {
      console.log(res.status)
      if(res.status == 200) {
        this.getTestRating()
      }
    })
  }
  
  showTesterProfile(){
    let tester_profile_popup = document.getElementById("tester-profile-popup-content") as HTMLDivElement;
    if(tester_profile_popup !== null){
      if(tester_profile_popup.style.display === "block" || tester_profile_popup.style.display === ""){
        tester_profile_popup.style.display = "none";
      } else {
        tester_profile_popup.style.display = "block";
      }
    }
  }





}
