<section class="video-container" #videoplayercontainer (mouseup)="handleMouseUp()">
  <div class="video-section" id="video-element">
    <video #videoPlayer
      (click)="handlePlayVideo($event)"
      (timeupdate)="handleVideoTimeChanged()"
      [src]="player_video_link"
      (loadedmetadata)="initializeVideoTime()"
      (mousemove)="startTimer()"
    >
    </video>
  </div>
  <!-- progress bar -->
  <div  class="up-vid-progress-bar">
    <!-- <input class="up-vid-progress-bar-input" id="slider1" type="range" min="0" max="100" [value]="progressBarValue"> -->
    
    <!-- create clip trim bar -->
    <section class="trim-bar-container" *ngIf="showCreateClipPopup">
      <div class="trim-bar"
        (mousemove)="handleMouseMove($event)"
      >
        <article class="trim-start-handle"
          [style.left.%]="startPosition * 100"
          (mousedown)="handleStartDrag()"
        >
        </article>

        <div class="trim-selected-range"
          [style.left.%]="startPosition * 100"
          [style.width.%]="(endPosition - startPosition) * 100"
        >
        </div>

        <article class="trim-end-handle"
          [style.left.%]="endPosition * 100"
          (mousedown)="handleEndDrag()"
        >
        </article>
      </div>
    </section>
  </div>
  <!-- video controls -->
   <div id="div-hide">
    <div class="progess" id="progess" (click)="ChangeTimeRange($event)">
      <div #progressBarInner id="fill" class="fill"></div>
    </div>
  <div id="video-control" class="video-controls  fullscreen-controls"
 
   >
   
    <section class="video-controls-left">
        <!-- play button -->
      <div class="up-play up-icon-container" 
        (click)="handlePlayVideo()"
        style="position: relative;"  
      >
        <ng-container *ngIf="showPlayButton === true">
          <img src="assets/images/icons/play.svg" alt="icon"
            (mouseenter)="showTooltip('tooltip-play2')" 
            (mouseleave)="closeTooltip('tooltip-play2')" 
          />
          <aside class="common-toolip" id="tooltip-play2">Play</aside>
        </ng-container>
        <ng-container *ngIf="showPlayButton === false">
          <img src="assets/images/icons/pause.png" alt="icon"
            (mouseenter)="showTooltip('tooltip-pause')" 
            (mouseleave)="closeTooltip('tooltip-pause')" 
          >
          <aside class="common-toolip" id="tooltip-pause">Pause</aside>
        </ng-container>
      </div>
      <!-- skip backward ten -->
      <div class="up-skip-backward-ten up-icon-container" (click)="handleSkipTenSecondsBackward()"
        (mouseenter)="showTooltip('tooltip-backward')" 
        (mouseleave)="closeTooltip('tooltip-backward')"
        style="position: relative;"  
      >
        <img src="assets/images/icons/skip-backward-ten-sec.svg" alt="icon">
        <aside class="common-toolip" id="tooltip-backward">10s backwards</aside>
      </div>
      <!-- skip forward ten -->
      <div class="up-skip-forward-ten up-icon-container" (click)="handleSkipTenSecondsForward()"
        (mouseenter)="showTooltip('tooltip-forward')" 
        (mouseleave)="closeTooltip('tooltip-forward')"
        style="position: relative;"  
      >
        <img src="assets/images/icons/skip-forward-ten-sec.svg" alt="icon">
        <aside class="common-toolip" id="tooltip-forward">10s forwards</aside>
      </div>
      <!-- previous video -->
      <div class="up-previous-video up-icon-container" (click)="handlePlayPreviousVideo()"
        style="position: relative;"
      >
        <img src="assets/images/icons/previous-video.svg" alt="icon"
          (mouseenter)="showTooltip('tooltip-previous-video')" 
          (mouseleave)="closeTooltip('tooltip-previous-video')"
        />
        <aside class="common-toolip" id="tooltip-previous-video">Previous video</aside>
      </div>
      <!-- next video -->
      <div class="up-next-video up-icon-container" (click)="handlePlayNextVideo()"
        style="position: relative;"
      >
        <img src="assets/images/icons/next-video.svg" alt="icon"
        (mouseenter)="showTooltip('tooltip-next-video')" 
        (mouseleave)="closeTooltip('tooltip-next-video')"
        />
        <aside class="common-toolip" id="tooltip-next-video">Next video</aside>
      </div>
      <!-- video sound -->
      <div class="up-speaker up-icon-container" (click)="handleVideoSound()"
        style="position: relative;"
      >
        <ng-container *ngIf="showMuteButton === true">
          <img src="assets/images/icons/speaker-white.svg" alt="icon"
            (mouseenter)="showTooltip('tooltip-mute')" 
            (mouseleave)="closeTooltip('tooltip-mute')"
          >
          <aside class="common-toolip" id="tooltip-mute">Mute</aside>
        </ng-container>
        <ng-container *ngIf="showMuteButton === false">
          <img src="assets/images/icons/mute.svg" alt="icon"
            (mouseenter)="showTooltip('tooltip-unmute')" 
            (mouseleave)="closeTooltip('tooltip-unmute')"
          />
          <aside class="common-toolip" id="tooltip-unmute">Unmute</aside>
        </ng-container>
      </div>
      <!-- video duration -->
      <div class="up-time-duration up-icon-container">
        <section>
          {{videoCurrentDuration}} 
        </section>
        <div>
          /
        </div>
        <section style="margin-left: 5px;">
          {{videoTotalDuration}}
        </section>
      </div>
    </section>
    <section class="video-controls-right" >
      <!-- create clip -->
      <div class="up-create-clip up-icon-container"
        (click)="openCreateClipBox();changeInValue()">
        <!-- <ng-container *ngIf="showCreateClipPopup === false"> -->
        <ng-container>
          <img src="assets/images/icons/white-scissors.svg" alt="icon">
          <span id="create-clip-btn-text" style="margin-left: 5px;">Create clip</span>
        </ng-container>
        <!-- <span *ngIf="showCreateClipPopup === true">Create clip</span> -->
      </div>
      <!-- change playback speed -->
      <div class="up-speed up-icon-container"
        style="position: relative;"
      >
        <span (click)="this.showPlaybackSpeedPopup = !showPlaybackSpeedPopup"
          (mouseenter)="showTooltip('tooltip-playback-speed')" 
          (mouseleave)="closeTooltip('tooltip-playback-speed')" 
        >
          Speed: {{currentVideoSpeed["key"]}}
        </span>
          <section class="up-speed-popup" *ngIf="showPlaybackSpeedPopup">
            <ng-container *ngFor="let speedItem of playbackSpeedList">
              <article (click)="handleVideoSpeed(speedItem)">
                <img *ngIf="currentVideoSpeed['value'] === speedItem['value']" src="assets/images/icons/tick-white.svg" alt="icon">
                <div>{{speedItem["key"]}}</div>
              </article>
            </ng-container>
          </section>
          <aside class="common-toolip" id="tooltip-playback-speed">video playback speed</aside>
      </div>
      <!-- picture in picture -->
      <div class="up-picture-in-picture up-icon-container" (click)="handlePictureInPicture()"
        style="position: relative;"
      >
        <img src="assets/images/icons/picture-in-picture.svg" alt="icon"
          (mouseenter)="showTooltip('tooltip-pic-in-pic')" 
          (mouseleave)="closeTooltip('tooltip-pic-in-pic')" 
        />
        <aside class="common-toolip" id="tooltip-pic-in-pic">Picture in picture</aside>
      </div>
      <!-- fullscreen -->
      <div class="up-full-screen up-icon-container" (click)="FullScreen()">
        <img src="assets/images/icons/maximize.svg" *ngIf="fullscreen == false">
        <img src="assets/images/icons/minimize-video.svg" *ngIf="fullscreen == true">
      </div>
      <!-- report video -->
      <div class="up-report-video up-icon-container" (click)="handleReportVideo()"
        style="position: relative;"
      >
        <img src="assets/images/icons/alert-triangle.svg" alt="icon"
          (mouseenter)="showTooltip('tooltip-report')" 
          (mouseleave)="closeTooltip('tooltip-report')" 
        >
        <aside class="common-toolip" id="tooltip-report">Discard video</aside>
      </div>
      <!-- download video -->
      <div class="up-download-vid up-icon-container"
        style="position: relative;"
      >
        <a href="{{player_video_link}}" download="{{player_video_link}}" target="_blank">
          <img src="assets/images/icons/download.svg" alt="icon"
          (mouseenter)="showTooltip('tooltip-download')" 
          (mouseleave)="closeTooltip('tooltip-download')" 
          />
        </a>
        <aside class="common-toolip" id="tooltip-download"
          style="left: -200%;"
        >
          Download video
        </aside>
      </div>
      <!-- share video -->
      <div 
        class="up-share-video up-icon-container" 
        (click)="handleShareVideo()"
        style="position: relative;"
      >
        <img src="assets/images/icons/share-2.svg" alt="icon"
          (mouseenter)="showTooltip('tooltip-share')" 
          (mouseleave)="closeTooltip('tooltip-share')" 
        >
        <aside class="common-toolip" id="tooltip-share"
          style="left:-200%;"
        >
          Share video
        </aside>
      </div>
    </section>
    <!-- create clip dialog -->
    <div class="create-clip-popup" *ngIf="showCreateClipPopup">
      <div class="create-clip-popup-tooltip" id="create-clip-popup-tooltip">
        <h3>What is a clip?</h3>
        <p>A clip is a 5 to 60 seconds long segment from a video. <br> <a target="_blank" href="https://helpcenter.userpeek.com/kb/what-is-a-clip/">More information.</a></p>
        <h6 (click)="createClipDialogTooltip()">Understood</h6>
      </div>
      <div class="create-clip-header">
        <h2>Create clip</h2>
        <div>
          <img src="assets/images/icons/info-white.svg" alt="icon"
            style="margin-right: 15px;" (click)="createClipDialogTooltip()">
          <img src="assets/images/icons/close-white.svg" alt="icon"
            (click)="showCreateClipPopup = false">
        </div>
      </div>
      <!-- <div class="create-clip-body">
        <div class="create-clip-textbox">
          <input type="text" [(ngModel)]="clipStartTime" (ngModelChange)="changeInValue()" >
          <p>-</p>
          <input type="text" [(ngModel)]="clipEndTime"
          (ngModelChange)="changeInValue()" 
          (keydown)="handleKeyboard($event)" 
         (wheel)="handleMouseScroll($event)"
          >
        </div>
        <div class="create-clip-selected-duration">
          {{clipDuration}} seconds
        </div>
      </div> -->
    
      <div class="create-clip-body">
        <div class="create-clip-textbox">
          <input
            type="text"
            [(ngModel)]="clipStartTime"
            (ngModelChange)="changeInValue()"
            (keydown)="handleKeyboard($event, 'start')"
            (wheel)="handleMouseScroll($event, 'start')"
            style="font-size: 14px;"
          />
          <p>-</p>
          <input
            type="text"
            [(ngModel)]="clipEndTime"
            (ngModelChange)="changeInValue()"
            (keydown)="handleKeyboard($event, 'end')"
            (wheel)="handleMouseScroll($event, 'end')"
            style="font-size: 14px;"
          />
        </div>
        <div class="create-clip-selected-duration">
          {{ clipDurationVal }}
        </div>
      </div>
      
      <div class="create-clip-footer">
        <p class="create-clip-cancel-btn"
          (click)="showCreateClipPopup = false">
          Cancel
        </p>
        <p class="create-clip-create-btn" (click)="handleCreateClip()">Create clip</p>
      </div>
    </div>
  </div>
</div >
  <section *ngIf="showCreateClipPopup" class="create-clip-backdrop">
  </section>
</section>