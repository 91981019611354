import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// import { AuthGuard } from '../auth.guard';

const routes: Routes = [
  // {
  //   path: 'videos/videos_info/:id',
  //   // patos/h:'videos1',
  //   component: VideosInfoComponent,
  //   canActivate: [AuthGuard]
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VideosRoutingModule {
}
