import {Component, OnInit} from '@angular/core';
import {PaymentService} from 'src/app/shared/services/payment.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit {
  listOfInvoices: Object;
  noInvoice: boolean = true;

  constructor(
    private _payement: PaymentService,
  ) {
  }

  ngOnInit() {
    this.orderAllInvoices();
  }

  orderAllInvoices() {
    this._payement.getAllOrder().subscribe((res) => {
      this.listOfInvoices = res;
      if (Object.keys(this.listOfInvoices).length <= 0) {
        this.noInvoice = false;
      }
    });
  }
}
