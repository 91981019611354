import { Component, HostListener, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { UpVideoPlayerService } from 'src/app/shared/services/up-video-player.service';
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GeneralApiService } from 'src/app/core/api-call/general-api.service';
export enum KEY_CODE {
  SPACE = 'Space',
  ESC = 'Escape'
}
@Component({
  selector: 'app-userpeek-video-player',
  templateUrl: './userpeek-video-player.component.html',
  styleUrls: ['./userpeek-video-player.component.scss']
})

export class UserpeekVideoPlayerComponent implements OnInit {

  @ViewChild("videoPlayer") videoPlayer: any;
  @ViewChild("progressBarInner") progressBarInner: any;
  @ViewChild("progressBarOuter") progressBarOuter: any;
  @ViewChild("videoplayercontainer") videoplayercontainer: any;

  fullscreen: boolean = false;
  showPlaybackSpeedPopup: boolean = false;
  showPlayButton: boolean = true;
  showMuteButton: boolean = true;
  showCreateClipPopup: boolean = false;

  progressBarValue: number = 0;
  videoTotalDuration: string = "00:00";
  videoCurrentDuration: string = "00:00";
  createClipStartTime: string = "00:00";
  createClipEndTime: string = "00:00";
  clipDuration: number = 0;
  clipDurationVal: any = ''
  currentVideoSpeed: any = {
    "value": 1,
    "key": "1x"
  }
  playbackSpeedList: any = [
    {
      "value": 0.5,
      "key": "0,5x"
    },
    {
      "value": 1,
      "key": "1x"
    },
    {
      "value": 1.25,
      "key": "1,25x"
    },
    {
      "value": 1.5,
      "key": "1,5x"
    },
    {
      "value": 2,
      "key": "2x"
    },
  ]
  startPosition: any = 0;
  endPosition: any = 0;
  isDraggingStart: boolean = false;
  isDraggingEnd: boolean = false;
  clipStartTime: any = "00:00:00";
  clipEndTime: any = "00:00:00";
  startTime:any ="00:00:00";
  endTime:any ="00:00:10";
  player_video_link: string;
  selectedVideoDetials: any = {};
  videoEndTimeRange: any = 0;
  autoVideoPause: boolean = false;



  constructor(
    private _upVideoPlayerService: UpVideoPlayerService,
    private _toastMsgService: ToastMsgService,
    private _authServices: AuthService,
    private el: ElementRef,
    private _generalApiService: GeneralApiService
  ) {
    // Note: set video start time using the selected text on transcript section. content menu play button
    this._upVideoPlayerService.videoTimeRange.subscribe((time_dict) => {
      console.log('time_dict', time_dict)
      this.videoPlayer.nativeElement.currentTime = time_dict["start_time"];
      this.videoEndTimeRange = time_dict["end_time"];
      this.clipStartTime = this.secondsToTime(time_dict["start_time"])
      this.clipEndTime = this.secondsToTime(time_dict["end_time"])
      this.autoVideoPause = true;
      this.handlePlayVideo("play");
      // this.setTrimHandlesPosition(time_dict);
    });
    // when text is selected on the transcript tab, set video current time
    this._upVideoPlayerService.videoCurrentTime.subscribe((start_time) => {
      this.videoPlayer.nativeElement.currentTime = start_time;
      if (this.showPlayButton === false) {
        this.handlePlayVideo("play");
      }
    });
  }

  ngOnInit() {
    try {
      let tempJsonData = localStorage.getItem("cacheSelectedVideoDetials");
      if (tempJsonData != undefined || tempJsonData != null) {
        this.selectedVideoDetials = JSON.parse(tempJsonData);
        this.player_video_link = `${environment.apiUrl}${this.selectedVideoDetials.file}`;
        this.videoPlayer.nativeElement.load();
        this.initializeVideoTime()
        this.attachEventListeners()
      } else {
        this._toastMsgService.showMsg("failed", "video data not found!", 3000);
      }
    } catch (e) {
      console.log(e)
    }
    console.log('call function')
    this.checkResponsiveness()
  }
  ngOnDestroy() {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('fullscreenchange', this.onFullscreenChange);
  }

  // ======= Event Listeners ========
  // keyboard event listeners
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.code === 'Space') {
      this.handlePlayVideo()
    }

  }
  ngAfterViewInit() {
    document.addEventListener('fullscreenchange', this.onFullscreenChange);
  }

  // Remove fullscreenchange event listener when component is destroyed


  // Toggle fullscreen mode based on the current fullscreen state
  FullScreen() {
    if (this.fullscreen) {
      this.exitFullscreen();
    } else {
      this.enterFullscreen();
    }
  }

  private enterFullscreen() {
    const element = this.videoplayercontainer.nativeElement;

    // Enter fullscreen
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) { // Safari
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) { // Firefox
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) { // IE/Edge
      element.msRequestFullscreen();
    }

  }
  private exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).webkitExitFullscreen) { // Safari
      (document as any).webkitExitFullscreen();
    } else if ((document as any).mozCancelFullScreen) { // Firefox
      (document as any).mozCancelFullScreen();
    } else if ((document as any).msExitFullscreen) { // IE/Edge
      (document as any).msExitFullscreen();
    }
  }

  // Listen for `Escape` key to exit fullscreen
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'Escape' && this.fullscreen) {
      this.exitFullscreen();
    }
  }

  // Event handler for `fullscreenchange` to keep `fullscreen` in sync
  onFullscreenChange = () => {
    this.fullscreen = !!document.fullscreenElement;
    this.updateStyles();

  };


  private updateStyles() {
    const progressBar = document.getElementById('progess');
    const videoControlBar = document.getElementById("video-control");

    if (this.fullscreen) {
      progressBar?.classList.add('fullscreen-progress');
      videoControlBar?.classList.add('fullscreen-controls');
    } else {
      progressBar?.classList.remove('fullscreen-progress');
      videoControlBar?.classList.remove('fullscreen-controls');
    }
  }
  startTimer() {
    var value = this
    document.getElementById("div-hide").style.display = 'block'
    document.getElementById("div-hide").style.transition = 'all 0.2s ease'
    setTimeout(function () {
      if (value.fullscreen) {

        document.getElementById("div-hide").style.display = 'none'
        document.getElementById("div-hide").style.transition = 'all 0.2s ease'
      }
    }, 10000);
  }

  // mouse click event listeners
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    // playback speed popup
    if ((event.target as HTMLElement).closest(".up-speed") == null) {
      if ((event.target as HTMLElement).closest(".up-speed-popup") == null) {
        if (this.showPlaybackSpeedPopup === true) {
          this.showPlaybackSpeedPopup = false;
        }
      }
    }
  }
  // window resize event listeners
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    console.log('Window resized:', window.innerWidth);
    this.checkResponsiveness()
  }
  checkResponsiveness() {
    setTimeout(() => {
      // create clip button responsiveness
      let create_clip_btn_text = document.getElementById("create-clip-btn-text") as HTMLSpanElement;
      // console.log('checking responsiveness: ', window.innerWidth)
      if (window.innerWidth < 1530) {
        // console.log('checking responsiveness: display none',)
        create_clip_btn_text.style.display = "none";
      } else {
        // console.log('checking responsiveness: display inline',)
        create_clip_btn_text.style.display = "inline";
      }
    }, 500);
  }


  // =========== create clip, video trim bar ==============
  private attachEventListeners() {
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
  }
  handleStartDrag() {
    this.isDraggingStart = true;
  }
  handleEndDrag() {
    this.isDraggingEnd = true;
    // console.log('handleEndDrag function called, this.isDraggingEnd: ', this.isDraggingEnd )
  }
  handleMouseMove(e) {
    // console.log('handleMouseMove function called')
    if (this.isDraggingStart) {
      this.updatePosition(e.clientX, true)
    }
    if (this.isDraggingEnd) {
      // console.log('handleMouseMove function | going to call the this.updatePosition| value of this.isDraggingEnd :', this.isDraggingEnd)
      this.updatePosition(e.clientX, false)
    }
  }
  setTrimHandlesPosition(time_dict) {
    // start time
    // let temp_start_time = time_dict["start_time"];
    // let start_time_array = temp_start_time.split(":");
    // let start_time_seconds = (parseInt(start_time_array[0])*60) + parseInt(start_time_array[1]);

    const start_percentage = ((time_dict["start_time"] / this.videoPlayer.nativeElement.duration) * 100) / 100;
    const end_percentage = ((time_dict["end_time"] / this.videoPlayer.nativeElement.duration) * 100) / 100;

    console.log('start_percentage', start_percentage)
    console.log('end_percentage', end_percentage)
    this.startPosition = start_percentage;
    this.endPosition = end_percentage;

  }
  updatePosition(clientX, isStartHandle) {
    const videoDuration = this.videoPlayer.nativeElement.duration;
    // console.log('updatePosition function called | value of clientX: ', clientX)
    const { left, width } = this.videoPlayer.nativeElement.getBoundingClientRect()
    // console.log('clientX:', clientX)
    // console.log('left:', left)
    // console.log('width:', width)
    let position = (clientX - left) / width
    // console.log('position:', position)
    position = Math.max(0, Math.min(1, position)) // Clamp position between 0 and 1
    console.log('position:', position)
    if (isStartHandle) {
      const newStartPosition = Math.min(position, this.endPosition);
      if ((this.endPosition * videoDuration) - (newStartPosition * videoDuration) >= 5) {
        this.startPosition = newStartPosition
      } else {
        this.startPosition = this.startPosition;
      }
      console.log('this.startPosition', this.startPosition)
    } else {
      const newEndPosition = Math.max(position, this.startPosition);
      if ((newEndPosition * videoDuration) - (this.startPosition * videoDuration) >= 5) {
        this.endPosition = newEndPosition;
      } else {
        this.endPosition = this.endPosition;
      }
    }

    this.getVideoDuration()
  }
  handleMouseUp = () => {
    this.isDraggingStart = false;
    this.isDraggingEnd = false;
    // console.log(`handleMouseUp function called this.isDraggingStart: ${this.isDraggingStart} | this.isDraggingEnd: ${this.isDraggingEnd}`)
  }
  getVideoDuration() {
    const videoDuration = this.videoPlayer.nativeElement.duration;
    const startTime = this.startPosition * videoDuration;
    const endTime = this.endPosition * videoDuration;
    this.clipStartTime = this.secondsToTime(startTime)
    this.clipEndTime = this.secondsToTime(endTime)
    this.clipDuration = Math.round(endTime - startTime);
    // return { startTime, endTime };
    // console.log('startTime', startTime.toFixed(4))
  }

  // ========= on page load initailize the video with URL ===========
  initializeVideoTime() {
    this.videoTotalDuration = this.secondsToTime(this.videoPlayer.nativeElement.duration);
    this.createClipEndTime = this.videoTotalDuration;
    this.endPosition = 1;
    // const video = document.createElement('video');
    // video.src =  `https://stagapi.userpeek.io/media/250/167/199/159/videos/1710919488570_demo1.webm`;
    // video.preload = 'metadata';
    // console.log('video', video)
    // video.onloadedmetadata = () => {
    //   console.log('called')
    //   this.videoTotalDuration = this.secondsToTime(video.duration);
    //   this.createClipEndTime = this.videoTotalDuration;
    //   this.endPosition = 1;
    // }
  }

  secondsToTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(remainingSeconds)}`;
  }

  handleVideoTimeChanged() {
    // this.videoCurrentUpdatedTime = this.videoPlayer.nativeElement.currentTime;
    let currentDuration = this.videoPlayer.nativeElement.currentTime;
    let totalDuration = this.videoPlayer.nativeElement.duration;
    // console.log('currentDuration', Math.round(currentDuration))
    // video duration to display
    this.videoCurrentDuration = this.secondsToTime(this.videoPlayer.nativeElement.currentTime);

    // video progress bar update value dynamically
    this.progressBarValue = (currentDuration / totalDuration) * 100;
    this.progressBarInner.nativeElement.style.width = this.progressBarValue + "%";

    // console.log('currentDuration:', currentDuration)
    // console.log('this.videoEndTimeRange:', this.videoEndTimeRange)

    if (currentDuration >= totalDuration) {
      this.handlePlayVideo("pause");
    }
    // stop video on specific time range
    if (currentDuration >= this.videoEndTimeRange && this.autoVideoPause === true) {
      console.log('video paused')
      this.handlePlayVideo("pause");
      this.autoVideoPause = false;
    }
  }

  // video progress bar chnage time on click
  ChangeTimeRange(value: any) {
    var time = (value.offsetX / document.getElementById('progess').offsetWidth) * this.videoPlayer.nativeElement.duration
    this.videoPlayer.nativeElement.currentTime = time;

    var percentage = (this.videoPlayer.nativeElement.currentTime / this.videoPlayer.nativeElement.duration) * 100
    document.getElementById('fill').style.width = percentage + "%"

    if (this.showPlayButton === false) {
      // play state
      this.handlePlayVideo("play");
    }

    // this.showPlayButton = false;
    // this.videoPlayer.nativeElement.play();
    // this.showPlayButton = true;
    // document.getElementById('fill').style.transition = '0.5s'
  }



  // ======== video player controls ===============
  handlePlayVideo(event = null) {
    if (event === null) {
      // clicked on the icon
      if (this.showPlayButton === true) {
        // play state
        this.showPlayButton = false;
        this.videoPlayer.nativeElement.play();
      } else {
        // pause state
        this.showPlayButton = true;
        this.videoPlayer.nativeElement.pause();
      }
    }
    else if (event === "play") {
      // play state
      this.showPlayButton = false;
      this.videoPlayer.nativeElement.play();
    }
    else if (event === "pause") {
      // pause state
      this.showPlayButton = true;
      this.videoPlayer.nativeElement.pause();
    }
    else if (event.target.tagName.toLowerCase() === "video") {
      // clicked on the video
      if (this.showPlayButton === true) {
        // play state
        this.showPlayButton = false;
        this.videoPlayer.nativeElement.play();
      } else {
        // pause state
        this.showPlayButton = true;
        this.videoPlayer.nativeElement.pause();
      }
    }
  }

  handleSkipTenSecondsBackward() {
    var newDuration = this.videoPlayer.nativeElement.currentTime;
    newDuration -= 10;
    if (newDuration < 0) {
      this.videoPlayer.nativeElement.currentTime = 0;
      if (this.showPlayButton === false) {
        this.handlePlayVideo("play");
      }
    } else {
      this.videoPlayer.nativeElement.currentTime = newDuration;
      if (this.showPlayButton === false) {
        this.handlePlayVideo("play");
      }
    }
  }

  handleSkipTenSecondsForward() {
    var newDuration = this.videoPlayer.nativeElement.currentTime;
    var duration = this.videoPlayer.nativeElement.duration;
    newDuration += 10;
    if (newDuration > duration) {
      this.videoPlayer.nativeElement.currentTime = duration;
      if (this.showPlayButton === false) {
        this.handlePlayVideo("play");
      }
    } else {
      this.videoPlayer.nativeElement.currentTime = newDuration;
      if (this.showPlayButton === false) {
        this.handlePlayVideo("play");
      }
    }
  }

  handlePlayPreviousVideo() {
    this.switchTest(this.selectedVideoDetials.id, "previous");
  }

  handlePlayNextVideo() {
    this.switchTest(this.selectedVideoDetials.id, "next");
  }

  handleVideoSound() {
    let video_mute_state = this.videoPlayer.nativeElement.muted;
    if (video_mute_state === false) {
      this.showMuteButton = false;
      this.videoPlayer.nativeElement.muted = true;
    } else {
      this.showMuteButton = true;
      this.videoPlayer.nativeElement.muted = false;
    }
  }

  handleVideoSpeed(dict_value) {
    this.currentVideoSpeed = dict_value;
    this.videoPlayer.nativeElement.playbackRate = dict_value["value"];
    this.showPlaybackSpeedPopup = false;
  }

  handleVideoFullscreen() {
    // need to add in future
  }
  // FullScreen() {
  //   console.log("5555/",this.fullscreen);

  //   // this.showspeedlist = false;
  //   if (document.fullscreenElement) {
  //     document.exitFullscreen();
  //     this.fullscreen = false;
  //     // this.stopTimer();
  //     document.getElementById('progess').style.width = ""
  //     document.getElementById('progess').style.position = "absolute"
  //     document.getElementById('progess').style.bottom = ""
  //     document.getElementById("div-hide").style.display = "block"

  //   } else if (this.videoplayercontainer.nativeElement.webkitFullscreenElement) {
  //     this.videoplayercontainer.nativeElement.webkitFullscreenElement();
  //     this.fullscreen = true;
  //     // this.startTimer();
  //     document.getElementById('progess').style.width = "100vw"
  //     document.getElementById('progess').style.position = "fixed"
  //     document.getElementById('progess').style.bottom = "57px"

  //   } else if (this.videoplayercontainer.nativeElement.webkitRequestFullscreen) {
  //     this.videoplayercontainer.nativeElement.webkitRequestFullscreen();
  //     this.fullscreen = true;
  //     document.getElementById('progess').style.width = "100vw"
  //     document.getElementById('progess').style.position = "fixed"
  //     document.getElementById('progess').style.bottom = "57px"

  //   } else {
  //     this.videoplayercontainer.nativeElement.requestFullscreen();
  //     this.fullscreen = true;
  //     document.getElementById('progess').style.width = "100vw"
  //     document.getElementById('progess').style.position = "fixed"
  //     document.getElementById('progess').style.bottom = "57px"

  //   }
  // }


  handleReportVideo() {
    this._authServices.getClientProfile().subscribe((res) => {
      window.open(`https://userpeek.com/submit-video-for-review/?videoid=${this.selectedVideoDetials.id}&first=${res.data[0].first_name}&last=${res.data[0].last_name}&email=${res.data[0].email}`, "_blank");
    });
  }

  handleShareVideo() {
    navigator.clipboard.writeText("sample video link");
    this._toastMsgService.showMsg("success", "Video URL copied", 2000);
  }

  handlePictureInPicture() {
    this.videoPlayer.nativeElement.requestPictureInPicture();
  }

  // create clip functions
  handleCreateClip() {
    let tempDict = {
      "start_time": this.clipStartTime,
      "end_time": this.clipEndTime
    }
    this._upVideoPlayerService.createClipFromPlayer(tempDict);
    // this.showCreateClipPopup = false;
  }
  openCreateClipBox() {
    // open create clip dialog box
    this.showCreateClipPopup = !this.showCreateClipPopup;
    // pause video
    this.handlePlayVideo("pause")
    // set Start & End time in create clip dialog box
    let currentTime = this.videoPlayer.nativeElement.currentTime;
    this.clipStartTime = this.secondsToTime(currentTime);
    this.clipEndTime = this.secondsToTime(currentTime + 10);

    const start_percentage = ((currentTime / this.videoPlayer.nativeElement.duration) * 100) / 100;
    const end_percentage = (((currentTime + 10) / this.videoPlayer.nativeElement.duration) * 100) / 100;
    console.log('start_percentage', start_percentage)
    console.log('end_percentage', end_percentage)
    this.startPosition = start_percentage;
    this.endPosition = end_percentage;
  }
  // changeInValue() {
  //   var startTime = this.clipStartTime.split(":");
  //   var minutes1 = parseInt(startTime[0]);
  //   var seconds1 = parseInt(startTime[1]);
  //   var startTimeResult = (minutes1 * 60) + seconds1;
  //   console.log('startTimeResult', startTimeResult)

  //   var endTime = this.clipEndTime.split(":");
  //   var minutes = parseInt(endTime[0]);
  //   var seconds = parseInt(endTime[1]);
  //   var endTimeResult = (minutes * 60) + seconds;
  //   console.log('endTimeResult', endTimeResult)

  //   let a = endTimeResult - startTimeResult;
  //   console.log('a', a);

  //   this.clipDuration = a;
  // }

  createClipDialogTooltip() {
    let tooltipBox = document.getElementById("create-clip-popup-tooltip") as HTMLDivElement;
    if (tooltipBox.style.display == "none" || tooltipBox.style.display == "") {
      tooltipBox.style.display = "block";
    } else {
      tooltipBox.style.display = "none";
    }
  }








  // show tooltip on hover
  showTooltip(id) {
    let element = document.getElementById(id) as HTMLElement;
    element.style.display = "block"
  }
  closeTooltip(id) {
    let element = document.getElementById(id) as HTMLElement;
    element.style.display = "none"
  }

  // Move to next or previous test
  switchTest(test_id, action) {
    let tempArray = this.selectedVideoDetials.file.split("/")
    let project_id = parseInt(tempArray[2]);
    let list_of_test_array = [];
    this._generalApiService.getTestDetails(project_id, this.selectedVideoDetials.test).subscribe((res: any) => {
      list_of_test_array = res.data.video_list;
      console.log('list_of_test_array', list_of_test_array)
      let arrayIndexLength = list_of_test_array.length - 1;
      let current_index = list_of_test_array.findIndex(obj => obj.id === test_id)
      console.log('test_id', test_id)
      console.log('current_index', current_index)
      if (action === "next") {
        let nextItemIndex = current_index + 1;
        if (nextItemIndex > arrayIndexLength) {
          this._toastMsgService.showMsg("failed", "End of list", 3000);
        } else {
          console.log(list_of_test_array[nextItemIndex])
          localStorage.removeItem("cacheSelectedVideoDetials");
          localStorage.setItem('cacheSelectedVideoDetials', JSON.stringify(list_of_test_array[nextItemIndex]));
          window.location.reload();
        }
      }
      else if (action === "previous") {
        let previousItemIndex = current_index - 1;
        if (previousItemIndex < 0) {
          this._toastMsgService.showMsg("failed", "End of list", 3000);
        } else {
          localStorage.removeItem("cacheSelectedVideoDetials");
          localStorage.setItem('cacheSelectedVideoDetials', JSON.stringify(list_of_test_array[previousItemIndex]));
          window.location.reload();
        }
      }
    });
  }


  changeInValue() {
    const startTime = this.parseTime(this.clipStartTime); // Parse start time in seconds
    const endTime = this.parseTime(this.clipEndTime);     // Parse end time in seconds
  
    // Calculate the duration (endTime - clipStartTime)
    let duration = endTime - startTime;
  
    // Prevent negative duration, set it to 0 if endTime < startTime
    if (duration < 0) {
      duration = 0;
    }
  
    // Update clipDurationVal based on the calculated duration
    if (duration < 60) {
      this.clipDurationVal = `${duration} seconds`; // Less than 60 seconds
    } else if (duration >= 60 && duration < 3600) {
      const minutes = Math.floor(duration / 60);
      const seconds = duration % 60;
      this.clipDurationVal = `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}` +
        (seconds > 0 ? ` ${seconds} ${seconds === 1 ? 'second' : 'seconds'}` : ''); // Less than 1 hour
    } else {
      const hours = Math.floor(duration / 3600);
      const remainingSeconds = duration % 3600;
      const minutes = Math.floor(remainingSeconds / 60);
      const seconds = remainingSeconds % 60;
  
      // Ensure proper spacing between components (hours, minutes, seconds)
      this.clipDurationVal =
        `${hours} ${hours === 1 ? 'hour' : 'hours'}` +
        (minutes > 0 ? ` ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}` : '') +
        (seconds > 0 ? ` ${seconds} ${seconds === 1 ? 'second' : 'seconds'}` : '');
    }
  }
  
  

  // Handle keyboard events for start or end time adjustments
  handleKeyboard(event: KeyboardEvent, field: 'start' | 'end') {
    if (event.key === 'ArrowUp') {
      this.adjustTime(field, 1);
    } else if (event.key === 'ArrowDown') {
      this.adjustTime(field, -1);
    }
  }

  // Handle mouse scroll events for start or end time adjustments
  handleMouseScroll(event: WheelEvent, field: 'start' | 'end') {
    event.preventDefault();
    const delta = event.deltaY < 0 ? 1 : -1; // Scroll up is positive, scroll down is negative
    this.adjustTime(field, delta);
  }

  // Adjust time for start or end, making sure the times don't overlap incorrectly
  adjustTime(field: 'start' | 'end', delta: number) {
    const timeString = field === 'start' ? this.clipStartTime : this.clipEndTime;
    let totalSeconds = this.parseTime(timeString);

    // Add/subtract seconds based on delta
    totalSeconds += delta;

    // Prevent negative time and ensure start time is less than end time
    if (totalSeconds < 0) totalSeconds = 0;

    if (field === 'start' && totalSeconds >= this.parseTime(this.clipEndTime)) {
      return; // Don't allow start time to exceed end time
    } else if (field === 'end' && totalSeconds <= this.parseTime(this.clipStartTime)) {
      return; // Don't allow end time to be less than start time
    }

    const updatedTime = this.formatTime(totalSeconds);
    if (field === 'start') {
      this.clipStartTime = updatedTime;
    } else {
      this.clipEndTime = updatedTime;
    }

    // After time adjustment, update duration
    this.changeInValue();
  }

  // Parse time string (HH:MM:SS) into total seconds
  parseTime(time: string): number {
    const [hours, minutes, seconds] = time.split(':').map((val) => parseInt(val, 10));
    return hours * 3600 + minutes * 60 + seconds;  // Convert to total seconds
  }

  // Format total seconds into time string (HH:MM:SS)
  formatTime(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  // Helper function to ensure two digits for hours, minutes, and seconds
  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
  

  




}
