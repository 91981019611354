import { Component, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-multi-filter-menu',
  templateUrl: './multi-filter-menu.component.html',
  styleUrls: ['./multi-filter-menu.component.scss']
})
export class MultiFilterMenuComponent implements OnInit {

  showMultiFilter: boolean = false;
  showMultiFilterSubMenu: boolean = false;
  showMultiFilterValue: string = '';

  @Output() filterQueryValues = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  sendFilterQuery(queryType: any, value: any) {
    let tempData = {
      queryType: queryType,
      value: value
    }

    if(value != ""){
      this.filterQueryValues.emit(tempData);
    }

    if(queryType === "cancel"){
      this.showDropdownMenu();
    }
  }

  showDropdownMenu() {
    if (this.showMultiFilter == true) {
      this.showMultiFilterSubMenu = false
      this.showMultiFilter = false;
    } else {
      this.showMultiFilter = true;
    }
  }

  showSubMenu(e:any){
    this.openSubMenu(e);
  }

  openSubMenu(e:any){
    this.showMultiFilterValue = e
    this.showMultiFilterSubMenu = true
    // console.log(e)
  }

  getDeviceCheckboxValue(){
    let deviceCheckboxes = document.getElementsByClassName("device-checkbox") ;
    let tempArray = [];
    let finalArray = [];
    let mobileCount = 0;
    let desktopCount = 0;
    let tabletCount = 0;

    for(let i = 0; i < deviceCheckboxes.length; i++){
      
      if((deviceCheckboxes[i] as HTMLInputElement).checked){
        tempArray.unshift((deviceCheckboxes[i] as HTMLInputElement).value);
      }
    }


    tempArray.forEach((item)=>{
      if(item === "mobile"){
        mobileCount++;
        if(mobileCount <= 1){
          finalArray.unshift(item);
        }
      } 
      else if(item === "desktop"){
        desktopCount++;
        if(desktopCount <= 1){
          finalArray.unshift(item);
        }
      }
      else if(item === "tablet"){
        tabletCount++;
        if(tabletCount <= 1){
          finalArray.unshift(item);
        }
      }
    });

    if(finalArray.length > 0){
      this.sendFilterQuery("device", finalArray);
    }


  }

  getDatevalue(){
    let fromDate =  document.getElementById("from-date");
    let toDate =  document.getElementById("to-date");

    if((fromDate as HTMLInputElement).value != "" && (toDate as HTMLInputElement).value != ""){
      this.sendFilterQuery("date",
        { startDate: (fromDate as HTMLInputElement).value,
          endDate: (toDate as HTMLInputElement).value,
        });
    }
  }

}
