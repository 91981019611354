

<main class="up-login-container">

  <nav class="up-header">
    <a routerLink='/login'>
      <img src="assets/images/logo-color.svg" alt="logo">
    </a>
  </nav>

</main>



<div class='login-container lg-screen-width up-login-content'>
  <section class='section'>
    <div class='login-form-container'>
      <h1 class="title">Log in</h1>
      <p class="desc">Hey there again!</p>
      <div class='text-danger' style="width: 100%;">
        <small>{{ responseError }}</small>
        <small>{{ errorMsg }}</small>
        <!-- <small>{{ message }}</small> -->
        <small>{{notUser}}</small>

      </div>
      <form (ngSubmit)='onLogin()' [formGroup]='loginForm'>


        <label class='email'>
          <span class='name'>Email</span>
          <input formControlName='email' type='email' />
          <div *ngIf="
          (submitted && loginForm.controls.email.errors) ||
          loginForm.get('email').touched
        " class='invalidfeedback'>
            <div *ngIf='
            loginForm.controls.email.errors?.required ||
            loginForm.controls.email.errors?.email
          '>
              <small class='text-danger'> Must be a valid email address</small>
            </div>

          </div>
        </label>

        <label class='password password-eye'>

          <div class="password-label-container">
            <span class='name'>Password</span>
            <div class="show-password" *ngIf="!fieldTextType" (click)="toggleFieldTextType()">
              <img src="assets/images/icons/eye-open.svg" alt="eye open icon"> <span>Show</span>
            </div>
            <div class="show-password" *ngIf="fieldTextType" (click)="toggleFieldTextType()">
              <img src="assets/images/icons/eye-close.svg" alt="eye close icon"> <span>Hide</span>
            </div>
          </div>
          
          <input [type]="fieldTextType ? 'text' : 'password'" formControlName='password' />

        </label>
        <div *ngIf="
            submitted ||
            (loginForm.get('password').invalid &&
              (loginForm.get('password').touched ||
                loginForm.get('password').dirty))
          " class='errors'>
          <div *ngIf="loginForm.get('password').errors">
            <small class='text-danger'>
              Password is required
            </small>
          </div>
        </div>
        <label class='forget-password'>
          <a href='{{ urlReset }}' target='_blank'>Forgot my password?</a>
        </label>

        <button class='login-submit btn-sty-blue up-login-btn' type='submit' [disabled]="!loginForm.valid" [ngClass]="[loginForm.valid ? 'up-login-btn-active' : 'up-login-btn-inactive']">
          Log in
        </button>

      </form>
      <p class='registration'>
        Don't have an account?
        <a routerLink='/register' routerLinkActive='active'>Sign up</a>
      </p>

    </div>

    <!-- <button class='feedback-btn'>Feedback</button> -->
  </section>
</div>
<app-supported-by></app-supported-by>

<ngx-spinner bdColor='rgba(51, 51, 51, 0.8)' size='default' type='ball-spin-clockwise'>
  <p style='color: white'>Please Wait! </p>
</ngx-spinner>