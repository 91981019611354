<app-breadcrumbs-navbar></app-breadcrumbs-navbar>
<div class="project-dash-container">
    <div class="project-dash-content">
        <div class="project-dash-header">
            <h2>Test reports</h2>
            <!-- <div class="project-dash-header-btns">
                <button (click)="createProject()" class="btn-sty-blue">
                    <img  src="assets/images/icons/plus.svg" alt="plus icon"> Create project
                </button>
                <a class="btn-sty-blue-outline" href="javascript: void();">View templates</a>
            </div> -->
        </div>
        <div class="project-dash-menu">
            <div class="project-dash-menu-text">View test reports.</div>
            <div>
                <app-all-filters></app-all-filters>
            </div>
            <!-- <div class="project-dash-menu-icons">
                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'all' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                    (click)="applyButtonFilterFun('all')">All projects ({{unfilteredJsonData.length}})</button>
                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'active' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                    (click)="applyButtonFilterFun('active')">Active ({{buttonFilterData.active}})</button>
                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'archive' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                    (click)="applyButtonFilterFun('archive')">Archived ({{buttonFilterData.archive}})</button>
            </div> -->
        </div>

        <!-- Table -->
        <div class="project-dash-table-container">

            <table class="project-dash-table">
                
                <tr class="title-row">
                    <th class="project-name-title">Test report</th>
                    <!-- <th class="status-title">Status</th>
                    <th class="tests-title">Tests</th>
                    <th class="videos-title">Videos</th>
                    <th class="reports-title">Reports</th> -->
                    <th class="action-title">
                        <p style="margin-left: 50px;">Action</p>
                    </th>
                </tr>
                <ng-container *ngIf="isDataAvailable">
                    <tr class="data-row" *ngFor="let projectItem of filteredJsonData; index as i">
                        <td class="project-name-data" (click)="downloadTestReport(projectItem.id)" >{{ projectItem.test_name }}</td>
                        <!-- <td class="status-data">
                            <ng-container *ngIf="projectItem.project_status === 0;else second">Active</ng-container>
                            <ng-template #second>
                                <ng-container *ngIf="projectItem.project_status === 1;else third">Inactive</ng-container>
                            </ng-template>
                            <ng-template #third>
                                <ng-container *ngIf="projectItem.project_status === 5;">Archived</ng-container> 
                            </ng-template>
                        </td>
                        <td class="tests-data">{{ projectItem.total_test }}</td>
                        <td class="videos-data">{{projectItem.total_videos}} / {{projectItem.total_videos}}</td>
                        <td class="reports-data">73</td> -->
                        <td class="action-data">
                            <div (click)="togglePopup(projectItem.id)" class="dash-context-menu-wrapper">
                                <img class="dash-context-menu-icon" src="assets/images/icons/menu-icon-new.svg" alt="icon">
                                <div class="dash-context-menu-cont" *ngIf="projectItem.id == openPopupId ">
                                    <div class="dash-context-menu-content">
                                        <button (click)="downloadTestReport(projectItem.id)" class="dash-context-menu-edit">Download</button>
                                        <!-- <button  class="dash-context-menu-delete">Delete</button> -->
                                        <!-- <button  class="dash-context-menu-archive">Duplicate</button>
                                        <button  class="dash-context-menu-archive">Archive</button> -->
                                    </div>
                                </div>
                            </div>     
                        </td>
                    </tr>
                </ng-container>
            </table>

            <div *ngIf="isDataAvailable" class="pagination-cont">
                <p class="pagination-txt">{{paginateCount}} / {{queriedJsonData.length}} reports</p>
                <p class="pagination-btn" (click)="incrementPaginationCountFun(paginateCount + 10)">Load more</p>
            </div>

        </div>

        <div class="empty-data-cont" *ngIf="!isDataAvailable">
            <div class="empty-data-icon" style="margin-bottom: 25px;">
                <img src="assets/images/icons/cube-new-icon.svg" alt="cube icon">
            </div>

            <div class="empty-data-text" style="margin-bottom: 35px;">
                <p>You don't have any reports yet. </p>
                <!-- <p>Let's create a project to get started.</p> -->
            </div>

            <!-- <div class="empty-data-btn" style="margin-bottom: 35px;">
                <button class="btn-sty-blue">
                    <img src="assets/images/icons/plus.svg" alt="plus icon"> Create project
                </button>
            </div> -->

            <!-- <div class="empty-data-link">
                or <a class="a-sty" href="javascript: void();">view templates</a>
            </div> -->
        </div>

    </div>

    <div class="project-dash-sidebar">
    
    </div>
</div>
<script src='assets/js/custom.js'></script>