import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {TesterTestedListService} from 'src/app/shared/services/tester-tested-list.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CreateNewTestService} from 'src/app/shared/services/create-new-test.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss'],
})
export class VideosComponent implements OnInit {

  baseURL = environment.apiUrl;
  testedVideoList: Subscription;
  testerTestedList = [];
  searchText: string;
  searchText1: string;

  ftShow: boolean = false;
  ftStyle = 'none';
  showFt: boolean;
  filter;
  search4;
  search5;
  filterMenuShow1: boolean = false;
  filterMenuTab1: boolean = false;
  addClass: boolean = false;
  addClass1: boolean = false;
  addClass2: boolean = false;
  addClass3: boolean = false;
  testerProfileList = [];
  device: string;
  testerTesterProfile = [];
  testerFullName: any;
  filterAllVideo: any;
  headerProjectName: any;
  testedvideoShow: boolean = false;
  showNew: boolean = false;
  newData: number;
  allProjectList = [];
  showVideoSection: boolean = false;
  singleIndex: any;
  myProjectSHow: boolean = true;
  finish: boolean;
  newVideo: boolean;
  alltest: any;
  filterProjectList: any;
  testedvideoShow1: boolean;
  testerTestedList1: any;
  filterAllVideo1 = [];
  testerTesterProfile1: any;
  noVideoMessage: string;
  getAllExampleVideo: any;
  showExampleVideo: boolean = false;
  showMessageVideo: boolean = false;
  tests;
  thumnailImageFile: any;
  exampleDuration: string;
  totalTimeDuration: any = [];
  totalTimeDurationProject: any;
  deviceAndroid: any = "";
  deviceIphone: any = "";
  deviceDesktop: any = "";

  constructor(
    private _testedTestServices: TesterTestedListService,
    private _router: Router,
    private _createTestServices: CreateNewTestService,
    private _route: ActivatedRoute
  ) {
  }

  // sort array videos
  get sortData() {
    return this.filterAllVideo.sort((a, b) => {
      return <any>new Date(b.date_of_tested) - <any>new Date(a.date_of_tested);
    });
  }

  get sortData1() {
    return this.filterAllVideo1.sort((a, b) => {
      return <any>new Date(b.date_of_tested) - <any>new Date(a.date_of_tested);
    });
  }

  ngOnInit() {
    this.noVideoMessage = 'Tested video are not available yet !';
    this.getTestedVideoList();
    this._createTestServices.gelAllProjectList().subscribe((res) => {
      if (res.data) {
        res.data.filter((res) => {
          if (res.total_videos) {
            this.allProjectList.push(res);
          }
        });
      }
      // this.allProjectList = res.data;
    });

    this._route.params.subscribe((parms: Params) => {

      if (parms['type'] == 'new') {
        this.newVideos();
      } else if (parms['type'] == 'my') {
        this.myProject();
      } else {
        this.getAllVideo();
      }
    });
    // this.getExampleVideo();

  }

  onMyCustomEvent() {
    this.showFt = false;
  }

  // project related video start

  showVideo1(i, project_id) {
    this.singleIndex = i;
    this._testedTestServices
      .getProjectRelatededVideo(project_id)
      .subscribe((testedListRes) => {

        if (testedListRes['data'].video_list.length <= 0) {
          this.testedvideoShow1 = false;
        } else {
          this.testedvideoShow1 = true;
        }
        this.testerTestedList1 = testedListRes['data'].video_list;
        this.testerTesterProfile1 = testedListRes['data'].tester_profile;
        this.totalTimeDurationProject = this.testerTestedList1.map(function (el) {
          let data = el.viedo_total_time;
          var sec_num = parseInt(data, 10); // don't forget the second param
          var hours: any = Math.floor(sec_num / 3600);
          var minutes: any = Math.floor((sec_num - hours * 3600) / 60);
          var seconds: any = sec_num - hours * 3600 - minutes * 60;
          if (hours < 10) {
            hours = '0' + hours;
          }
          if (minutes < 10) {
            minutes = '0' + minutes;
          }
          if (seconds < 10) {
            seconds = '0' + seconds;
          }


          var o = Object.assign({}, el);

          if (el.viedo_total_time !== null) {
            o.total_durationProject = minutes + ':' + seconds;
          } else {
            o.total_durationProject = '00' + ':' + '00';
          }
          return o;
        });
        // this.testerTesterProfile.filter(testerProfileRes=>{
        //   this.testerFullName = testerProfileRes.full_name
        // })
        this.filterAllVideo1 = this.totalTimeDurationProject;

        this.testerTestedList1.filter((res) => {
          if (res.is_app == true) {
            this.device = 'MOBILE';
          } else {
            this.device = 'DESKTOP';
          }
        });
        // this.filterProjectList = res['data']
      });
  }

  myProject() {
    this._router.navigate(['/videos/my']);
    this.myProjectSHow = false;
    this.finish = true;
    this.alltest = false;
    this.newVideo = false;
  }

  // Project related video End
  getTestedVideoId(testId, projectName, id) {
    localStorage.setItem('testedTestId', id);
    this._testedTestServices.sendTestId(testId);
    this.headerProjectName = projectName;
    localStorage.setItem('projectName', projectName);
    this._testedTestServices.watchVideo(id).subscribe((res) => {
    });
  }

  getTestedVideoIdProjectName() {

    this._testedTestServices.sendTestIdProjectName(this.headerProjectName);
  }

  getTestedVideoList() {

    this.testedVideoList = this._testedTestServices
      .getTestedTestVideoList()
      .subscribe((testedListRes) => {
        if (testedListRes.data && testedListRes.data.video_list.length <= 0) {
          this.testedvideoShow = false;
          this.getExampleVideo()
        } else {
          this.testedvideoShow = true;
        }
        if (testedListRes.data) {
          this.testerTestedList = testedListRes.data.video_list;

          this.testerTesterProfile = testedListRes.data.tester_profile;
        }

        this.totalTimeDuration = this.testerTestedList.map(function (el) {
          let data = el.viedo_total_time;
          var sec_num = parseInt(data, 10); // don't forget the second param
          var hours: any = Math.floor(sec_num / 3600);
          var minutes: any = Math.floor((sec_num - hours * 3600) / 60);
          var seconds: any = sec_num - hours * 3600 - minutes * 60;
          if (hours < 10) {
            hours = '0' + hours;
          }
          if (minutes < 10) {
            minutes = '0' + minutes;
          }
          if (seconds < 10) {
            seconds = '0' + seconds;
          }

          var o = Object.assign({}, el);
          if (el.viedo_total_time !== null) {
            o.total_duration = minutes + ':' + seconds;
          } else {
            o.total_duration = '00' + ':' + '00';
          }
          return o;
        });
        this.filterAllVideo = this.totalTimeDuration;
      });
    this.filterAllVideo = this.testerTestedList;
  }

  getExampleVideo() {
    this._testedTestServices.getExampleVideo().subscribe((res) => {
      this.getAllExampleVideo = res;
    });
  }

  newVideos() {
    this.noVideoMessage = 'New video are not available yet !';
    this.showExampleVideo = false;
    this.showMessageVideo = false;
    this.newVideo = true;
    this.alltest = false;
    this.finish = false;
    this.myProjectSHow = true;
    this.filterAllVideo = [];
    this.totalTimeDuration.forEach((res) => {
      if (res.watch == false) {
        this.filterAllVideo.push(res);
      }
    });
  }

  // end video sorting
  keyDownFunction(event) {
    this.searchVideo(event.target.value);

    // rest of your code
  }

  keyDownFunction1(endDate, event) {
    this.searchVideo(undefined, endDate, event.target.value);
  }

  keyDownDeviceFunction(event) {
    this.searchVideo();
  }

  searchVideo(searchValue?, startDate?, endDate?, deviceIphone?, deviceAndroid?, deviceDesktop?) {
    this.showExampleVideo = false;
    if (searchValue) {
      this.filterAllVideo = [];
      this.totalTimeDuration.forEach((res) => {
        if (res.project_name.includes(searchValue)) {
          this.filterAllVideo.push(res);
          this.filterAllVideo = this.filterAllVideo.slice();
        } else {
          this.showMessageVideo = false;
          this.noVideoMessage = 'No record found!';
        }
      });
    } else if (startDate) {

      let start = +new Date(startDate);
      let end = +new Date(endDate);
      this.filterAllVideo = this.totalTimeDuration.filter((m) => {
        let date_of_tested = +new Date(m.date_of_tested);

        if (date_of_tested > start && date_of_tested < end) return m;
      });
    } else if (deviceIphone == 'iPhone' || deviceAndroid == 'Android' || deviceDesktop == 'Desktop') {
      this.filterAllVideo = [];
      let _AllDeviceType = [this.deviceIphone, this.deviceAndroid, this.deviceDesktop]
      this.filterAllVideo = this.totalTimeDuration.filter(f => _AllDeviceType.indexOf(f.device_type) != -1);
      this.deviceDesktop = ""
      this.deviceAndroid = ""
      this.deviceIphone = ""
      if (this.filterAllVideo.length == 0) {
        this.showMessageVideo = false;
        this.noVideoMessage = 'No record found!';
      }

    } else {
      this.showMessageVideo = false;
      this.noVideoMessage = 'No record found!';
    }
    this.showFt = false;
  }

  // Filter based on Device start
  onSelectAndroid(device, event) {
    this.deviceAndroid = "";
    if (event == true) {
      this.deviceAndroid = device
    } else {
      this.deviceAndroid = ""
    }


  }

  onSelectIphone(device, event) {
    this.deviceIphone = ""

    if (event == true) {
      this.deviceIphone = device
    } else {
      this.deviceIphone = ""
    }

  }

  onSelectDesktop(device, event) {
    this.deviceDesktop = ""

    if (event == true) {
      this.deviceDesktop = 'Desktop'
    } else {
      this.deviceDesktop = ""
    }

  }

  // Filter based on Device End

  getAllVideo() {
    this.noVideoMessage = 'Tested video are not available yet !';
    this.alltest = true;
    this.finish = false;
    this.showMessageVideo = true;
    this.newVideo = false;
    this.showExampleVideo = true;
    this.myProjectSHow = true;
    this.filterAllVideo = [];
    this.totalTimeDuration.forEach((res) => {
      this.filterAllVideo.push(res);
    });
  }

  filterShow() {
    this.showFt = !this.showFt;
    this.filterMenuShow1 = false;
    // this.ftShow =!this.ftShow
  }

  filterMenuShow() {
    this.filterMenuShow1 = true;
  }

  filterMenu1Show() {
    this.addClass = true;
    this.filterMenuShow1 = true;
    this.addClass2 = false;
    this.addClass1 = false;
    this.addClass3 = false;
  }

  filterMenu2Show() {
    this.filterMenuShow1 = true;
    this.addClass = false;
    this.addClass1 = true;
    this.addClass2 = false;
    this.addClass3 = false;
  }

  filterMenu3Show() {
    this.filterMenuShow1 = true;
    this.addClass = false;
    this.addClass2 = true;
    this.addClass1 = false;
    this.addClass3 = false;
  }

  filterMenu4Show() {
    this.filterMenuShow1 = true;
    this.addClass = false;
    this.addClass2 = false;
    this.addClass1 = false;
    this.addClass3 = true;
  }

  searchBy(date1, date2) {

    let start = date1; // this should be of object Date()
    let end = date2; // this should be of object Date()
    let selectedMembers = this.testerTestedList.filter((m) => {
      if (m.date_of_tested > start && m.date < end)
        // or you can cast here to Date()
        return m;
    });
    // this.showFt = false
  }


}
