import { Component, OnInit } from '@angular/core';
import { CustomPopupBoxService } from '../services/custom-popup-box.service';
import { CreateNewTestService } from '../services/create-new-test.service';

@Component({
  selector: 'app-custom-popup-box',
  templateUrl: './custom-popup-box.component.html',
  styleUrls: ['./custom-popup-box.component.scss']
})
export class CustomPopupBoxComponent implements OnInit {

  showContentType: string = '';

  // create project
  createProjectInputValue: string ="";
  projectNameList = [];
  doesProjectNameExist: boolean = false;

  // order videos popup
  
  videoCount: number = 0;
  individualVideoPrice: number = 55;


  constructor(
    public customPopupService: CustomPopupBoxService,
    public _createNewTestService: CreateNewTestService
    ) { }

  ngOnInit(): void {

    this.customPopupService.contentType$.subscribe((value)=>{
      this.showContentType = value;
      if(value === "create-project-popup"){
        this._createNewTestService.gelAllProjectList().subscribe((res) => {
          res.data.forEach(item => {
            this.projectNameList.push(item);
          });
        })    
      }
    })

  }

  closePoup(){
    this.customPopupService.dismissPopup();
  }



  popupCreateProject(){
    let tempProjectName = {
      "project_name": this.createProjectInputValue
    }
    this.createProjectInputValue = "";
    this._createNewTestService.createProject(tempProjectName).subscribe((res) =>{
      if(res.status == 200){
        window.location.reload();
      }
    })
    this.customPopupService.dismissPopup();
  }


  checkProjectNameExist(event){
    let x = this.projectNameList.find((e) =>{ return(e.project_name.toLowerCase() === event.toLowerCase())})
    if(x == undefined){
      this.doesProjectNameExist = false;
    } else {
      this.doesProjectNameExist = true;
    }
  }
  

}
