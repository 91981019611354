import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Options} from 'ng5-slider';
import {AuthService} from 'src/app/shared/services/auth.service';
import {CreateNewTestService} from 'src/app/shared/services/create-new-test.service';
import {ModelBoxService} from 'src/app/shared/services/model-box.service';
import {PaymentService} from 'src/app/shared/services/payment.service';

@Component({
  selector: 'app-test-order',
  templateUrl: './test-order.component.html',
  styleUrls: ['./test-order.component.scss']
})

export class TestOrderComponent implements OnInit {

  @Input() seletedTestOrderDetails: any;
  @Output() emitTriggredOrderData = new EventEmitter<any>();

  orderVideoCount = 0;
  testerCount: FormGroup;
  totalCredits: any;
  enbleOrderButton: boolean = false;
  averageDesktopPercentage: any = '0%';
  averageMobilePercentage: any = '0%';
  averageTabPercentage: any = '0%';
  barColorFilled1: any = '33%';
  barColorFilled2: any = '0%';
  showDetails: boolean = false;
  hideDraftbtn: boolean = true;
  testStatusToggle: any;
  testId: any;
  testId1: any;
  testId2: any;
  count1 = 0;
  count2 = 0;
  count3 = 0;
  @ViewChild('closeAddExpenseModal', {static: false})

  closeAddExpenseModal: ElementRef;
  Mobilevalue: number = 0;
  Desktopvalue: number = 0;
  value: number = 0;
  totalcradit: 0;
  options: Options = {
    floor: 0,
    ceil: 15,
  };

  constructor(private _fb: FormBuilder, private _modelBoxService: ModelBoxService, private _createnewTestServices: CreateNewTestService, private _authService: AuthService, private _payement: PaymentService) {
  }

  ngOnInit() {
    this.initOrderTestForm();

    if (this.seletedTestOrderDetails) {
      let data = this.seletedTestOrderDetails;
      this.toggle(data.id, data.test_status)
    }
  }

  // creditsValue() {
  //   this.totalcradit = this.testerCount.value.desktop_tester_count +
  //   this.testerCount.value.mobile_tester_count +
  //   this.testerCount.value.tab_tester_count;

  //   console.log(this.totalcradit);
  // }

  initOrderTestForm() {
    this.testerCount = this._fb.group({
      test_id: [""],
      desktop_tester_count: ["", Validators.required],
      mobile_tester_count: ["", Validators.required],
      tab_tester_count: ["", Validators.required],
    });
  }

  validateOrderCredits() {
    this.orderVideoCount =
      this.testerCount.value.desktop_tester_count +
      this.testerCount.value.mobile_tester_count +
      this.testerCount.value.tab_tester_count;

    if (this.orderVideoCount < 1) {
      this._modelBoxService.showModelBox(
        false,
        'Please select atleast 1 video.'
      );

    } else if (this.orderVideoCount > this.totalCredits) {
      // this.enbleOrderButton = false;
      this.enbleOrderButton = true;
    } else {
      this.enbleOrderButton = true;
      // this.enbleOrderButton = false;
    }

    return this.enbleOrderButton;
  }

  MobileonKey(e) {
    this.orderVideoCount =
      this.testerCount.value.desktop_tester_count +
      this.testerCount.value.mobile_tester_count +
      this.testerCount.value.tab_tester_count;
    this.MobilevalueChange(e.target.value);
    this.calculatePercent();
    if (this.orderVideoCount > 0) {
      this.validateOrderCredits();
    }
  }

  calculatePercent() {
    this.averageDesktopPercentage =
      (
        (this.testerCount.value.desktop_tester_count / this.options.ceil) *
        100
      ).toFixed(0) + '%';
    this.averageMobilePercentage =
      (
        (this.testerCount.value.mobile_tester_count / this.options.ceil) *
        100
      ).toFixed(0) + '%';
    this.averageTabPercentage =
      (
        (this.testerCount.value.tab_tester_count / this.options.ceil) *
        100
      ).toFixed(0) + '%';
  }

  orderTestEventTrigger(eventStatus?) {
    let status = eventStatus;
    let orderSelectedDetails = {
      status: status,
      orderData: this.testerCount.value,
      orderVideoCount: this.orderVideoCount,
      enbleOrderButton: status === 'closeOpenModel' ? '' : this.validateOrderCredits(),
      Desktopvalue : this.testerCount.value.desktop_tester_count,
      craditvalue: this.testerCount.value.desktop_tester_count +
      this.testerCount.value.mobile_tester_count +
      this.testerCount.value.tab_tester_count
    }
    // console.log(orderSelectedDetails,"orderSelectedDetails")
    this.emitTriggredOrderData.emit(orderSelectedDetails);
    // console.log(this.emitTriggredOrderData,"??")
  }

  onKey(e) {
    this.orderVideoCount =
      this.testerCount.value.desktop_tester_count +
      this.testerCount.value.mobile_tester_count +
      this.testerCount.value.tab_tester_count;
    this.calculatePercent();
    if (this.orderVideoCount > 0) {
      this.validateOrderCredits();
    }
  }

  DesktoponKey(e) {
    this.orderVideoCount =
      this.testerCount.value.desktop_tester_count +
      this.testerCount.value.mobile_tester_count +
      this.testerCount.value.tab_tester_count;
    this.calculatePercent();
    if (this.orderVideoCount > 0) {
      this.validateOrderCredits();
    }
  }

  valueChange(value: number): void {
    this.value = value;
    // this.log += `valueChanged: ${value}\n`;
  }

  DesktopvalueChange(Dvalue: number) {
    this.Desktopvalue = Dvalue;
  }

  MobilevalueChange(Mvalue: number) {
    this.Mobilevalue = Mvalue;
  }

  toggle(testid, teststatus) {
    this.orderVideoCount = 0;
    this.value = 0;
    this.Mobilevalue = 0;
    this.Desktopvalue = 0;
    this.testerCount.value.desktop_tester_count = 0;
    this.testerCount.value.mobile_tester_count = 0;
    this.testerCount.value.tab_tester_count = 0;

    // Reset percent
    this.averageDesktopPercentage = '0%';
    this.averageMobilePercentage = '0%';
    this.averageTabPercentage = '0%';
    this.testId2 = testid;
    this.showDetails = !this.showDetails;

    this.testerCount.patchValue({
      test_id: testid,
    });
    this.testStatusToggle = teststatus;
    if (this.testStatusToggle === 2) {
      this.hideDraftbtn = false;
    } else {
      this.hideDraftbtn = true;
    }
  }
}
