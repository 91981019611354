import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AllFiltersShareQueryService {
  
  // shareComponentFilterData
  public componentFilterQueryData: BehaviorSubject<any> = new BehaviorSubject({"requestType": "none", "component": ""});
  public parentToChildDataVariable: BehaviorSubject<any> = new BehaviorSubject([]);
  public childToParentDataVariable: BehaviorSubject<any> = new BehaviorSubject([]);
  public videosHashtagValue: Subject<any> = new Subject();

  constructor() { }

    sendDataParentToChild(value){
      this.parentToChildDataVariable.next(value);
    }
  
    sendDataChildtoParent(value){
      console.log(" tempClipList send datatochild",value);
      
      this.childToParentDataVariable.next(value);
    }
  
    sendFilterQueryRequest(requestType, component){
      this.componentFilterQueryData.next({"requestType": requestType, "component": component});
    }

    sendHashTagValue(value, filterType){
      console.log(" sendHashtagToAllFilterComponent service file value  console 2",value, "type",filterType);
      this.videosHashtagValue.next({"value": value, "filterType": filterType});
    }



}
