import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpVideoPlayerService {

  videoTimeRange: Subject<any>;
  createClipListenerValue: Subject<any>;
  videoCurrentTime: Subject<any>;

  constructor() {
    this.videoTimeRange = new Subject<any>();
    this.createClipListenerValue = new Subject<any>();
    this.videoCurrentTime = new Subject<any>();
  }


  setVideoTimeRange(timeDict: any) {
    this.videoTimeRange.next(timeDict);
  }

  createClipFromPlayer(timeDict: any) {
    this.createClipListenerValue.next(timeDict);
  }

  setVideoCurrentTime(time: any) {
    this.videoCurrentTime.next(time);
  }






}
