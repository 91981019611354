<section class="highlight-reels lg-screen-width">

    <aside class="h-reels-sidebar-container">
      <h6>Highlight <br/> Reels</h6>
      <div class="h-reels-sidebar">
        <a [ngClass]="{'active-link': displayUI == 'all-reels'}" (click)="handleTabSelection('all-reels')" class="h-reels-link link-hover-blue" href="javascript:void(0)">All Highlight Reels</a>
        <a [ngClass]="{'active-link': displayUI == 'my-project'}" (click)="handleTabSelection('my-project')" class="h-reels-link link-hover-blue" href="javascript:void(0)" >My Project</a>
        <a [ngClass]="{'active-link': displayUI == 'archive'}" (click)="handleTabSelection('archive')" class="h-reels-link link-hover-blue" href="javascript:void(0)">Archive</a>
      </div>
    </aside>
  
    <main class="h-reels-main">
      <!-- header -->
      <section class="h-reels-header">
        <button class="h-reels-filter-btn" type="button">Filter</button>
        <button  routerLink='/highlight_reel/create_highlight_reel' routerLinkActive='active' class="h-reels-create-btn">Create New Highlight Reel</button>
        <div class='col-sm-auto create-search-column'>
          <label [ngClass]="search3 ? 'open' : 'close'" class='create-search-sub'>
            <input [(ngModel)]='searchText' placeholder='Search for projects' type='text' />
            <a (click)='search3 = !search3' class='create-search'><i class='fa fa-search'></i></a>
          </label>
        </div>
      </section>
  
      <!-- all reels -->
      <div class="h-reels-content" *ngIf="displayUI == 'all-reels'">

        <ng-container *ngFor="let reel of reelsList; index as i">
          <article class="reel-item">
  
            <div class="reel-thumbnail" [ngStyle]="{'background-image': 'url(' + baseUrl+reel.thumbnail + ')'}">
              <p class="reel-clip-duration">
                {{ reel.total_duration }}
              </p>
            </div>
  
            <div class="reel-info">
              <div class="reel-name" (click)="onPreviewClick(i)">{{ reel.highlight_reel_name }}</div>
              <div class="reel-project">
                <p class="reel-project-name"> Project : {{ reel.project_name }} </p>
                <p class="reel-clips-count"> {{reel.clip_video_list.length}} clips</p>
              </div>
            </div>
  
            <div class="reel-right">
              <div class="reel-created">
                {{ reel.created_date }}
              </div>
    
              <div class="reel-menu">
                <div class="reel-menu-icon-container">
                  <p (click)="showPopupMenuFun($event)" class="reel-menu-icon">...</p>
                </div>
                <div class="reel-menu-option-container" id="reel-menu-option-container" [ngStyle]="{'display': showPopupMenu}">
                  <div class="reel-menu-option">
                    <img src="../../../assets/images/icons/white-triangle.png" alt="triangle" class="reel-menu-option-triangle">
                    <div class="reel-menu-option-row">
                      <img src="../../../assets/images/icons/edit.png" alt="edit icon" class="reel-menu-option-icon">
                      <p (click)="onEditClick(i)" class="reel-menu-option-text">Edit</p>
                    </div>
                    <div class="reel-menu-option-row">
                      <img src="../../../assets/images/icons/video-media.png" alt="edit icon" class="reel-menu-option-icon">
                      <p (click)="onPreviewClick(i)" class="reel-menu-option-text">Preview</p>
                    </div>
                    <div class="reel-menu-option-row">
                      <img src="../../../assets/images/icons/archive-icon.png" alt="edit icon" class="reel-menu-option-icon">
                      <p class="reel-menu-option-text">Archive</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
  
          </article>
  
        </ng-container>
      </div>

      <!-- my project -->
      <section id="my-project" *ngIf="displayUI == 'my-project'" style="margin-top: 50px;">
        <div aria-multiselectable='true' class='accordion md-accordion userpeak-accordion test' id='accordionEx'
          role='tablist'>
          <ng-container *ngIf='allProjectList.length > 0; else noItems'>
            <div *ngFor='
                let test of allProjectList | searchProject: searchText1;
                let i = index
              ' class='card'>

              <div class='card-header' id='accordion-{{ i }}' role='tab'>
                <a (click)='showTestID(test.id, i)' aria-controls='collapseOne1' aria-expanded='true' class='collapsed'
                  data-parent='#accordionEx' data-toggle='collapse' href='#collapse-{{ i }}'>
                  <h5 class='mb-0'>
                    {{ test.project_name }}<span class='count'>{{ test.total_test }}</span>
                    <i class='fas rotate-icon'><img src='assets/images/arrow-bottom.png' ss /></i>
                  </h5>
                </a>
              </div>

              <div *ngIf='singleIndex == i' aria-labelledby='headingOne1' class='collapse' data-parent='#accordionEx'
                id='collapse-{{ i }}' role='tabpanel'>
                <div class='card-body'>
                  <article *ngFor='let testList of filterProjectList; let i = index' class='test-card draft'>
                    <h3 style="background-color: #efefef; border-radius: 4px; padding: 10px;">
                      <strong>Test Name : </strong>{{testList.test_name}}
                    </h3>
                  </article>

                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </section>

    </main>

  
    <!-- popup window for video preview -->
    <div class="h-r-preview-video-popup-background" id="h-r-preview-video-popup-container" [ngStyle]="{'display' : setPreviewPopupDisplayProperty }">
      <div class="h-r-preview-video-popup-content">
        <button (click)="closeVideoPopup()" type="button" class="h-r-preview-video-popup-close-btn">&#10006;</button>
        <div class="h-r-preview-video-wrapper">
  
          <!-- video container -->
          <div class="h-r-preview-video-main" id="h-r-preview-video-main">
            <!-- <h3></h3> -->
            <!-- <video id="preview-video-player" controls>
              <source id="preview-video-player-src" [src]="setPreviewVideoData"/>
            </video> -->
          </div>
  
          <!-- sidebar -->
          <div class="h-r-preview-video-sidebar-container">
  
            <article (click)="handleClipClick(i,$event)" class="clip-list-item"  *ngFor="let item of previewClipsListData; let i = index">
  
              <div class="clip-list-thumbnail" [ngStyle]="{'background-image': 'url(' + baseUrl+item.thumbnail + ')'}">
                <div class="clip-list-clip-duration">
                  {{item.duration}}
                </div>
              </div>
    
              <div class="clip-list-info">
                <h2 class="clip-list-name">{{ item.clip_name }}</h2>
                <p class="clip-list-tester-name"> by {{ item.tester_name }} </p>
              </div>
    
              <div class="clip-list-right">
                <div class="clip-list-created">
                  {{ item.created_date }}
                </div>
      
                <div class="clip-list-menu">
                  <div class="clip-list-menu-icon-container">
                    <p class="clip-list-menu-icon">...</p>
                  </div>
                </div>
              </div>
            
    
            </article>
  
          </div>
        </div>
      </div>
    </div>
  
  </section>
  