import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpStoreSelectedVidDetialsService {

  public dataSource = new BehaviorSubject({});
  // public showPopup$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // public contentType$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  videoDetials = this.dataSource.asObservable();

  constructor() { }

  setValue(value: any){
    this.dataSource.next(value)
  }




}
