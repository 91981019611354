import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/api-call/base.service';
import {environment} from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})

export class GeneralApiService extends BaseService{

  private userCreditScoreHolder = new BehaviorSubject<number>(0);  // initial value
  sharedCreditScore = this.userCreditScoreHolder.asObservable();
  updateUserCreditScore(plan: number) {
    this.userCreditScoreHolder.next(plan);
  }

  // findings page api
  findingsPageAPI(body){
    // return this.get(`https://65ccad77dd519126b83f40cd.mockapi.io/findings`);
    return this.post(`${apiUrl}test/finding_summary_v2/`, body);
  }
  // get findings of the video
  getfindings(tested_test_id){
    return this.get(`${apiUrl}test/finding_summary/?tested_test_id=${tested_test_id}`);
  }
  // findings get api
  findingsListAPI(body){
    // return this.get(`https://65ccad77dd519126b83f40cd.mockapi.io/findings`);
    return this.post(`${apiUrl}test/result/findings/`, body);
  }
  // get transcript of an video
  getSelectedVideoTranscript(body){
    return this.post(`${apiUrl}processor/transcribe/`, body);
  }
  // Note
  saveNotes(body){
    return this.post(`${apiUrl}test/findings/note/`, body);
  }
  updateNote(body, id){
    return this.put(`${apiUrl}test/findings/note/${id}/`, body)
  }
  // sentiment
  addEmotion(body){
    return this.post(`${apiUrl}test/findings/sentiment/`, body);
  }
  // clip
  createClip(body){
    return this.post(`${apiUrl}processor/clips/`, body);
  }
  createClip2(body){
    return this.post(`${apiUrl}processor/clips/`, body);
  }
  // add hashtag
  createHashtag(body){
    return this.post(`${apiUrl}test/findings/hashtag/`, body);
  }
  addHashtag(body){
    return this.post(`${apiUrl}test/hashtag_associate/`, body);
  }
  updateHashtag(body){
    return this.put(`${apiUrl}test/hashtag_associate/`, body);
  }
  getHashtagList(test_id){
    return this.get(`${apiUrl}test/findings/hashtag/?test_id=${test_id}`);
  }
  getTestRating(id){
    return this.get(`${apiUrl}test/rating/?id=${id}`);
  }
  addTestRating(body){
    return this.post(`${apiUrl}test/rating/`, body);
  }
  // get test details
  getTestDetails(project_id, test_id){
    return this.get(`${apiUrl}test/videos/?project_id=${project_id}&test_id=${test_id}`);
  }
  deductCreditsAPI(body){
    return this.post(`${apiUrl}payment/deduct_credit/`, body);
  }

  // projects
  deleteProjectAPI(id){
    return this.delete(`${apiUrl}test/project/?id=${id}`);
  }
  duplicateProjectAPI(body){
    return this.post(`${apiUrl}test/project/duplicate/`, body);
  }
  updateProjectdata(body){
    return this.put(`${apiUrl}profile/client_profile/`, body);
  }
  getPrevProjectDetails(){
    return this.get(`${apiUrl}profile/client_profile/`);
  }

  // payment
  stripeSubscription(body){
    return this.post(`${apiUrl}payment/stripe/create-subscription/`, body);
  }
  stripeCustomer(){
    return this.get(`${apiUrl}payment/stripe/customer/`);
  }
  stripeCancelSubscriptionAPI(body){
    return this.post(`${apiUrl}payment/stripe/cancel-subscription/`, body);
  }
  stripeUpgradeSubscriptionAPI(body){
    return this.post(`${apiUrl}payment/stripe/upgrade-subscription/`, body);
  }
  stripeBillHistoryAPI(){
    return this.get(`${apiUrl}payment/stripe/invoices/`);
  }






  // --------------------------------------------------------







}
