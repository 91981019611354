import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectedVideoDetailsService } from '../selected-video-details.service';
import { TesterTestedListService } from 'src/app/shared/services/tester-tested-list.service';
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';

@Component({
  selector: 'app-video-clip',
  templateUrl: './video-clip.component.html',
  styleUrls: ['./video-clip.component.scss'],
  providers: [TesterTestedListService]
})
export class VideoClipComponent implements OnInit {


  @ViewChild("endtime") endtime: any;
  @ViewChild("makeclipform") makeclipform: any;
  @ViewChild("notes") noteselement: any;

  makeclipcheckbox: boolean = false;
  videocurrentbytime: string = '00:00';
  error1: boolean = false;
  error2: boolean = false;
  error3: boolean = false;
  selectedVideoDetails: any;
  editButton: boolean = false;
  clipsData: any;
  notescliplist: any = [];
  clips: any;
  notes: any;
  editClipPath: any = "";
  editNotesId: any;
  startTimeInputElementValue: any = "";
  endTimeInputElementValue: any = "";
  validInputStartTime: number = 0;
  validInputEndTime: number = 1;
  selectedVideoEndTime: number;
  startAndEndDurationValidFormat: boolean = false;
  currentVideoDetailsJson: any = {};
  checkVideoLengthExceed: boolean = false;
  toggleNotesAndClipsCheckbox: boolean = false;
  validEndDurationIsGreater: boolean = false;
  notesStartDurationLimitExceed: boolean = false;

  constructor(
    private dataservice: SelectedVideoDetailsService,
    private testedTestListServices: TesterTestedListService,
    public toastMsgService: ToastMsgService,
    ) {
    this.dataservice.videocurrentbytime.subscribe((sub) => {
      if (sub[1] == '0') {
        this.videocurrentbytime = sub.slice(3, sub.length);
      } else {
        this.videocurrentbytime = sub;
      }
    })

    this.dataservice.updateclip.subscribe((sub) => {
      this.editButton = sub

    })

    this.dataservice.makeclipcheckbox.subscribe((sub) => {
      this.makeclipcheckbox = sub
    })

    // if (this.editButton) {
    //   console.log("if")
    this.dataservice.clipsData.subscribe((sub) => {
      if (sub.clip) {
        this.videocurrentbytime = sub.start_duration
        this.startTimeInputElementValue = sub.start_duration
        this.noteselement.nativeElement.value = sub.notes
        this.endtime.nativeElement.value = sub.end_duration
        this.endTimeInputElementValue = sub.end_duration
        this.makeclipcheckbox = sub.clip
        this.toggleNotesAndClipsCheckbox = sub.clip
        console.log('this.toggleNotesAndClipsCheckbox', this.toggleNotesAndClipsCheckbox);
        console.log('sub.clip', sub.clip);
        let a = document.getElementById("clips_and_notes_checkbox") as HTMLInputElement;
        a.checked = true;
        this.clipsData = sub
        this.editClipPath = sub.clip_path

      } else {
        this.videocurrentbytime = sub.start
        this.startTimeInputElementValue = sub.start
        this.noteselement.nativeElement.value = sub.notes
        this.endtime.nativeElement.value = sub.end
        this.makeclipcheckbox = sub.clip
        this.toggleNotesAndClipsCheckbox = sub.clip
        this.clipsData = sub
        this.editNotesId = sub.id
        let a = document.getElementById("clips_and_notes_checkbox") as HTMLInputElement;
        a.checked = false;
      }


    })
  
    this.dataservice.videoendbytime.subscribe((sub) => {
      if (sub[1] == '0') {
        this.endtime.nativeElement.value = sub.slice(3, sub.length);
      } else {
        this.endtime.nativeElement.value = sub;
      }
    })

    this.dataservice.videotranscribenotes.subscribe((sub) => {
      this.noteselement.nativeElement.value = sub
    })
    this.editButton = false

  }

  ngOnInit(){
    // let selectedVideoDetials = JSON.parse(localStorage.getItem("cacheSelectedVideoDetials"));
    // this.endTimeInputElementValue = selectedVideoDetials["video_total_time"];
    this.currentVideoDetailsJson = JSON.parse(localStorage.getItem("cacheSelectedVideoDetials"));
  }

  makeClipOpen() {
    this.dataservice.SetVideoPlaying(false);
    if (this.makeclipcheckbox == true) {
      this.dataservice.SetMakeClipCheckBox(false);
      // this.endtime.nativeElement.setAttribute('disabled', true)
    } else {
      this.dataservice.SetMakeClipCheckBox(true);
      // this.endtime.nativeElement.removeAttribute('disabled')
    }
  }

  // testFun(){
  //   console.log('start time :', this.startTimeInputElementValue.toString().length);
  //   console.log('end time :', this.endTimeInputElementValue.toString().length);
  //   console.log('result :',(this.validInputStartTime.toString().length > 3) && (this.validInputEndTime.toString().length > 3));

  //   if((this.startTimeInputElementValue.toString().length > 3) && (this.endTimeInputElementValue.toString().length > 3)){

  //     console.log('if');
  //   } else{
  //     console.log('else');
  //   }
  // }

  ToggleMakeClipAndNotes(){
    this.toggleNotesAndClipsCheckbox = !this.toggleNotesAndClipsCheckbox;
    console.log("this.toggleNotesAndClipsCheckbox:", this.toggleNotesAndClipsCheckbox)
    if(this.notesStartDurationLimitExceed = true){
      this.notesStartDurationLimitExceed = false;
    }
  }

  AddClipAndNote(e: any) {
    e.preventDefault();
    var starttime = this.makeclipform.nativeElement.starttime.value
    var endtime = this.makeclipform.nativeElement.endtime.value
    var notes = this.makeclipform.nativeElement.notes.value
    console.log('this.toggleNotesAndClipsCheckbox', this.toggleNotesAndClipsCheckbox);
    console.log('this.startTimeInputElementValue.toString()', this.startTimeInputElementValue);
    console.log('this.endTimeInputElementValue', this.endTimeInputElementValue);

    if(this.toggleNotesAndClipsCheckbox == true){ //this.toggleNotesAndClipsCheckbox
      // start and end duration valid time format check
      if((this.startTimeInputElementValue.toString().replace(/:/g, "").length == 4) && (this.endTimeInputElementValue.toString().replace(/:/g, "").length == 4)){
        console.log('start and end duration valid time format check ==> ok if');
        this.startAndEndDurationValidFormat = false;
        // end duration must be less than video length validation
        if(Number(this.endTimeInputElementValue.replace(/:/g, "")) <= Number(this.currentVideoDetailsJson["video_total_time"].replace(/:/g, ""))){
          console.log('end duration must be less than video length validation ==> ok if');
          this.checkVideoLengthExceed = false;
          // end duration must be greater than start duration
          if((this.validInputEndTime > this.validInputStartTime)){
            console.log('end duration must be greater than start duration ==> ok if');
            this.validEndDurationIsGreater = false;

            if(this.editButton){
              this.UpdateClip(starttime, endtime, notes);
            } else{
              this.AddClip(starttime, endtime, notes);
            }

          } else{
            console.log('end duration must be greater than start duration ==> false else');
            this.validEndDurationIsGreater = true;
          }
        } else {
          console.log('end duration less than video length validation ==> false else');
          this.checkVideoLengthExceed = true;
        }
      } else {
        console.log('start and end duration valid time format check ==> false else');
        this.startAndEndDurationValidFormat = true;
      }
    } else{

      console.log('inside notes section');
      // notes start duration must be less than video length validation
      if((Number(this.startTimeInputElementValue.replace(/:/g, "")) <= Number(this.currentVideoDetailsJson["video_total_time"].replace(/:/g, ""))) && this.toggleNotesAndClipsCheckbox == false){
        console.log('start duration must be less than video length validation ==> ok if');
        this.notesStartDurationLimitExceed = false;

        if (this.editButton) {
          console.log('inside update note');
          this.UpdateNote(starttime, notes);
        } else {
          console.log('inside add note');
          this.AddNote(starttime, notes);
        }
      } else {
        console.log('start duration must be less than video length validation ==> false else');
        this.notesStartDurationLimitExceed = true;
      }

    }






  }

  UpdateClip(starttime: string, endtime: string, notes: string) {
    this.selectedVideoDetails = JSON.parse(localStorage.getItem("cacheSelectedVideoDetials"));

    if (starttime.length === 5 && endtime.length === 5 && notes.length !== 0) {

      this.error1 = false
      this.error2 = false
      this.error3 = false

      let body = {
        "video_url": this.selectedVideoDetails.file,
        "clip_path": this.editClipPath,
        "clip": {
          "start": starttime,
          "end": endtime,
          "notes": notes
        }
      }

      this.testedTestListServices.updateClip(body).subscribe((res) => {

        if (res.status == 200) {
          this.makeclipform.nativeElement.reset();
          this.startTimeInputElementValue = "";
          this.endTimeInputElementValue = "";
          this.toggleNotesAndClipsCheckbox = false;
          this.toastMsgService.showMsg("success", "Clip updated successfully", 2800);
          this.dataservice.initializeNotesAndClips("call function");
        }
      })
    } else {
      if (starttime.length < 5) {
        this.error1 = true
      } else {
        this.error1 = false
      }

      if (endtime.length < 5) {
        this.error2 = true
      } else {
        this.error2 = false
      }

      if (notes.length === 0) {
        this.error3 = true
      } else {
        this.error3 = false
      }
    }

    this.editButton = false
  }

  UpdateNote(starttime: string, notes: string) {
    this.selectedVideoDetails = JSON.parse(localStorage.getItem("cacheSelectedVideoDetials"));

    if (starttime.length === 5 && notes.length !== 0) {

      this.error1 = false
      this.error2 = false
      this.error3 = false

      let body = {
        "id": this.editNotesId,
        "test": this.selectedVideoDetails.test,
        "tested_test": this.selectedVideoDetails.id,
        "text": notes,
        "clip": false,
        "start": starttime,
        "end": ""
      }
      var noteId = this.clipsData.id
      this.testedTestListServices.updateMarker(noteId, body).subscribe((res) => {
        if (res.status == 200) {
          this.makeclipform.nativeElement.reset();
          this.startTimeInputElementValue = "";
          this.endTimeInputElementValue = "";
          this.toggleNotesAndClipsCheckbox = false;
          this.toastMsgService.showMsg("success", "Note updated successfully", 2800);
          this.dataservice.initializeNotesAndClips("call function");
        }
      })

    } else {
      if (starttime.length < 5) {
        this.error1 = true
      } else {
        this.error1 = false
      }

      if (notes.length === 0) {
        this.error3 = true
      } else {
        this.error3 = false
      }
    }
    this.editButton = false
  }

  // GetClipNotesList() {
  //   this.selectedVideoDetails = JSON.parse(localStorage.getItem("cacheSelectedVideoDetials"));
  //   let tempSelectedProjectId = JSON.parse(localStorage.getItem("selectedProjectId"));
  //   // @note project id hard coded
  //   this.testedTestListServices.getMarkerList2(tempSelectedProjectId,this.selectedVideoDetails.test).subscribe((res2) => {
  //     this.testedTestListServices.getClipList(this.selectedVideoDetails.test).subscribe((res) => {
  //       this.clips = res.data;
  //       this.notes = res2;
  //       this.notescliplist = this.notes.data;
  //       for (var i of this.clips) {
  //         if (i.clip_status !== 'FAILED') {
  //           this.notescliplist.push(i)
  //         }
  //       }
  //       const getNumber = t => +t.replace(/:/g, '')
  //       this.notescliplist.sort(({ start: a }, { start: b }) => getNumber(a) - getNumber(b))
  //       this.dataservice.SetNotesClipsList(this.notescliplist)
  //     })
  //   })
  // }

  AddClip(starttime: string, endtime: string, notes: string) {
    this.selectedVideoDetails = JSON.parse(localStorage.getItem("cacheSelectedVideoDetials"));
    if (starttime.length === 5 && endtime.length === 5 && notes.length !== 0) {
      this.error1 = false
      this.error2 = false
      this.error3 = false

      let body = {
        // <todo> => instead of video_url send testedtest_id an&
        "video_url": this.selectedVideoDetails.file,
        "clip": {
          "start": starttime,
          "end": endtime,
          "notes": notes
        }
      }

      this.testedTestListServices.addClip(body).subscribe((res) => {
        if (res.status == 200) {
          this.makeclipform.nativeElement.reset();
          this.startTimeInputElementValue = "";
          this.endTimeInputElementValue = "";
          this.toggleNotesAndClipsCheckbox = false;
          this.toastMsgService.showMsg("success", "Clip created successfully", 2800);
          this.dataservice.initializeNotesAndClips("call function");
        } else {
          this.toastMsgService.showMsg("failed", "Clip created successfully", 2800);
        }
      });

    } else {
      if (starttime.length < 5) {
        this.error1 = true
      } else {
        this.error1 = false
      }

      if (endtime.length < 5) {
        this.error2 = true
      } else {
        this.error2 = false
      }

      if (notes.length === 0) {
        this.error3 = true
      } else {
        this.error3 = false
      }
    }
    this.editButton = false
  }

  AddNote(starttime: string, notes: string) {
    this.selectedVideoDetails = JSON.parse(localStorage.getItem("cacheSelectedVideoDetials"));
    if (starttime.length === 5 && notes.length !== 0) {
      this.error1 = false
      this.error2 = false
      this.error3 = false

      let body = {
        "test": this.selectedVideoDetails.test,
        "tested_test": this.selectedVideoDetails.id,
        "text": notes,
        "clip": false,
        "start": starttime,
        "end": ""
      }

      this.testedTestListServices.addNotes(body).subscribe((res) => {
        if (res.status == 200) {
          this.makeclipform.nativeElement.reset();
          this.startTimeInputElementValue = "";
          this.endTimeInputElementValue = "";
          this.toggleNotesAndClipsCheckbox = false;
          this.toastMsgService.showMsg("success", "Note created successfully", 2800);
          // this.GetClipNotesList()
          this.dataservice.initializeNotesAndClips("call function");
        }
      })

    } else {
      if (starttime.length < 5) {
        this.error1 = true
      } else {
        this.error1 = false
      }

      if (notes.length === 0) {
        this.error3 = true
      } else {
        this.error3 = false
      }
    }
    this.editButton = false
  }

  ValidateNotes(event: any) {
    this.dataservice.SetVideoPlaying(false);
    if (event.target.value.length === 0) {
      this.error3 = true
    } else {
      this.error3 = false
    }
  }

  AddColon(event: any) {

    if (event.key === 'Backspace') {
      if (event.target.value.length === 2) {
        event.target.value = event.target.value.slice(0, 1)
      }
    } else {
      if (event.target.value.length === 2) {
        event.target.value = event.target.value + ':'
      }
    }
  }

  ValidateInput(event: any) {
    var ASCIICode = (event.which) ? event.which : event.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  testValue(){
    let startTime = Number(this.startTimeInputElementValue.replace(/:/g, ""));
    this.validInputStartTime = startTime;
    // console.log("this.validInputStartTime:", this.validInputStartTime)
    let endTime = Number(this.endTimeInputElementValue.replace(/:/g, ""));
    this.validInputEndTime = endTime;
    // console.log("this.validInputEndTime:", this.validInputEndTime)
    let selectedVideoDetials = JSON.parse(localStorage.getItem("cacheSelectedVideoDetials"));
    let videoEndTime = Number(selectedVideoDetials["video_total_time"].replace(/:/g, ""));
    this.selectedVideoEndTime = videoEndTime;

    // start and end duration valid time format check 
    if((this.startTimeInputElementValue.toString().replace(/:/g, "").length == 4) && (this.endTimeInputElementValue.toString().replace(/:/g, "").length == 4)){
      // console.log('start and end duration valid time format check ==> ok if');
      this.startAndEndDurationValidFormat = false;
    }
    // end duration less than video length validation
    if(Number(this.endTimeInputElementValue.replace(/:/g, "")) <= Number(this.currentVideoDetailsJson["video_total_time"].replace(/:/g, ""))){
      // console.log('end duration less than video length validation ==> ok if');
      this.checkVideoLengthExceed = false;
    }
    // end duration must be greater than start duration
    if(this.validInputEndTime > this.validInputStartTime){
      // console.log('end duration must be greater than start duration ==> ok if');
      this.validEndDurationIsGreater = false;
    }

    if((Number(this.startTimeInputElementValue.replace(/:/g, "")) <= Number(this.currentVideoDetailsJson["video_total_time"].replace(/:/g, ""))) && this.toggleNotesAndClipsCheckbox == false){
      // console.log('start duration must be less than video length validation ==> ok if');
      this.notesStartDurationLimitExceed = false;
    }

    

    // console.log(startTime+' '+endTime+" "+videoEndTime);
    // console.log(Number("0232"));

    // 
    // if(endTime > videoEndTime){

    // }

    // if(endTime < startTime){

    // }



    // console.log('event: ', this.startTimeInputElementValue);



    // console.log('total duration', this.selectedVideoDetails.file);
    // console.log('total duration', this.selectedVideoDetails["video_total_time"]);
  }
}
