import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'usernameShow'
})
export class UsernameShowPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    var dotPosition = value.indexOf(' ');
    return value.substring(dotPosition, dotPosition + 1);
  }

}
