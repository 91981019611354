import { Injectable, ViewChild } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { TesterTestedListService } from 'src/app/shared/services/tester-tested-list.service';

@Injectable({
  providedIn: 'root'
})
export class SelectedVideoDetailsService {

  resultData: any;


  videocurrentbytime: Subject<string>;
  videoendbytime: Subject<string>;
  videotranscribenotes: Subject<string>;
  videoplaying: Subject<boolean>;
  clipsData: Subject<any>;
  updateclip: Subject<boolean>;
  selectedTranscribeText: Subject<string>;
  makeclipcheckbox: Subject<boolean>;
  notescliplist: Subject<any>;
  fetchNotesAndClipsDetails: BehaviorSubject<any> = new BehaviorSubject<any>("");

  constructor(private testedTestListServices: TesterTestedListService) {
    this.videocurrentbytime = new Subject<string>();
    this.videoendbytime = new Subject<string>();
    this.videotranscribenotes = new Subject<string>();
    this.videoplaying = new Subject<boolean>();
    this.clipsData = new Subject<any>();
    this.notescliplist = new Subject<any>();
    this.updateclip = new Subject<boolean>();
    this.makeclipcheckbox = new Subject<boolean>();
    this.selectedTranscribeText = new Subject<string>();
  }

  SetVideoCurrentTime(time: string) {
    
    if(time != undefined) {
      // console.log(time)
      this.videocurrentbytime.next(time);
    }
  }

  SetVideoPlaying(play: boolean) {
    this.videoplaying.next(play);
  }

  SetClipsData(details: any) {
    this.clipsData.next(details);
  }

  SetUpdateClip(update: boolean) {
    this.updateclip.next(update);
  }

  SetSelectedTransibeText(text: string) {
    this.selectedTranscribeText.next(text);
  }

  SetVideoEndByTime(time: string) {
    this.videoendbytime.next(time);
  }

  SetVideoTranscribeNotes(note: string) {
    this.videotranscribenotes.next(note)
  }

  SetMakeClipCheckBox(value: boolean) {
    this.makeclipcheckbox.next(value)
  }

  SetNotesClipsList(value: any) {
    this.notescliplist.next(value)
  }

  initializeNotesAndClips(value: any){
    this.fetchNotesAndClipsDetails.next(value);
  }
}
