import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectedVideoDetailsService } from '../selected-video-details.service';
import { TesterTestedListService } from 'src/app/shared/services/tester-tested-list.service';
import { environment } from 'src/environments/environment';
import { Clipboard } from '@angular/cdk/clipboard'
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';

@Component({
  selector: 'app-video-clip-list',
  templateUrl: './video-clip-list.component.html',
  styleUrls: ['./video-clip-list.component.scss']
})
export class VideoClipListComponent implements OnInit, OnDestroy {

  notescliplist: any = [];
  clips: any;
  notes: any;
  hastags: any;
  menuView: number;
  selectedVideoDetials: any;
  baseUrl: any = environment.apiUrl;
  selectedvideodetailsServiceHolder: any;
  notesListArray: any = [];
  clipsListArray: any = [];
  clipAutoRefreshClearTimeoutId = [];


  constructor(
    private selectedvideodetailsService: SelectedVideoDetailsService,
    private testedTestListServices: TesterTestedListService,
    private clipboard: Clipboard,
    public toastMsgService: ToastMsgService,
    )
    {
      // this.hastags = selectedvideodetailsService.data.hastags
      // this.selectedvideodetailsService.notescliplist.subscribe((sub) => {
      // // this.notescliplist = sub
      // this.GetClipNotesList();
      // })
    }

  ngOnDestroy(): void {
    this.selectedvideodetailsServiceHolder.unsubscribe();
    this.clipAutoRefreshClearTimeoutId.forEach((item)=>{
      clearTimeout(item);
    });
  }

  ngOnInit(): void {
    this.GetClipNotesList();

    this.selectedvideodetailsServiceHolder = this.selectedvideodetailsService.fetchNotesAndClipsDetails.subscribe((data)=>{
      this.GetClipNotesList();
    });
  }

  // @note project id hard coded
  GetClipNotesList() {
    console.log('called api');
    this.selectedVideoDetials = JSON.parse(localStorage.getItem("cacheSelectedVideoDetials"));
    let tempSelectedProjectId = JSON.parse(localStorage.getItem("selectedProjectId"));
    this.testedTestListServices.getNotesListNew(tempSelectedProjectId, this.selectedVideoDetials.test, this.selectedVideoDetials.id).subscribe((notesResponse: any) => {
      this.testedTestListServices.getClipListNew(this.selectedVideoDetials.test, this.selectedVideoDetials.id).subscribe((clipsResponse: any) => {
        // this.clips = res.data;
        // this.notes = res2;
        this.notesListArray = notesResponse.data;
        this.clipsListArray = clipsResponse.data;


        let tempNotesSorted = notesResponse.data;
        let tempClipsSorted = clipsResponse.data;
        // let tempClipsSorted = clipsResponse.data.filter(function(item){
        //   return item.clip_status !== 'FAILED';
        // });

        // sort notes list
        tempNotesSorted.sort(function(a, b){
          // let a1 = a.start.slice(3);
          // let b1 = b.start.slice(3);
          let a1 = Number(a.start.replace(/:/g, ""));
          let b1 = Number(b.start.replace(/:/g, ""));
          return a1 - b1;
        });
        // sort clips list
        tempClipsSorted.sort(function(a, b){
          // let a1 = a.start_duration.slice(3);
          // let b1 = b.start_duration.slice(3);
          let a1 = Number(a.start_duration.replace(/:/g, ""));
          let b1 = Number(b.start_duration.replace(/:/g, ""));
          return a1 - b1;
        });
        this.notescliplist = [];
        this.notescliplist = [...tempNotesSorted,...tempClipsSorted];

        // if notes and clips status are inprogress auto refresh every 5 seconds
        this.checkNotesAndClipsStatusInProgress();

        // this.notescliplist.push(...tempNotesSorted);
        // this.notescliplist.push(...tempClipsSorted);
        // this.notescliplist = this.notes.data;
        // for (var i of this.clips) {
        //   if(i.clip_status !== 'FAILED') {
        //     this.notescliplist.push(i)
        //   }
        // }
        // this.notescliplist.sort(function(a, b){
        //   console.log("🥝",a);
        //   let a1 = a.start_duration.slice(3);
        //   let b1 = b.start_duration.slice(3);
        //   return a1 - b1;
        // });
        // const getNumber = t => +t.replace(/:/g, '')
        // this.notescliplist.sort(({ start_duration: a }, { start_duration: b }) => getNumber(a) - getNumber(b))
        // this.selectedvideodetailsService.SetNotesClipsList(this.notescliplist)
      })
    })
  }

  ChangeMenuView(id: number) {
    if(this.menuView == id) {
      this.menuView = undefined
    } else {
      this.menuView = id;
    }
  }

  DeleteClipOrNotes(item: any){
    if(item.clip === true) {
      console.log(item)
      this.testedTestListServices.deleteClip({'clip_path': item.clip_path}).subscribe((res) => {
        console.log(res)
        this.GetClipNotesList()
        this.menuView = undefined
        if (res.status == 200) {
          this.toastMsgService.showMsg("success", "clip deleted", 2800);
        }
      })
    } else {
      this.testedTestListServices.deleteMarker(item.id).subscribe((res) => {
        this.GetClipNotesList()
        this.menuView = undefined;
        if (res.status == 200) {
          this.toastMsgService.showMsg("success", "Note deleted", 2800);
        }
      })
    }
  }

  EditClipsOrNotes(item: any) {
    this.selectedvideodetailsService.SetClipsData(item)
    this.selectedvideodetailsService.SetUpdateClip(true)
    this.menuView = undefined
  }

  copyToClipboard(notes){
    this.clipboard.copy(notes);
    this.toastMsgService.showMsg("success", "Copied to clipboard", 2800);
  }


  checkNotesAndClipsStatusInProgress(){
    let that = this;
    let clipsInProgress = this.clipsListArray.some(arrayItem=>arrayItem.clip_status.toString().toLowerCase() === "inprogress");

    if(clipsInProgress == true){
        let tempVarClearTimeoutId = setTimeout(()=>{that.GetClipNotesList();},10000);
        this.clipAutoRefreshClearTimeoutId.push(tempVarClearTimeoutId);
    } else{
      this.clipAutoRefreshClearTimeoutId = [];
    }
  }

}
