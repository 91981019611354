import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HeaderComponent} from './shared/header/header.component';
import {CoreModule} from './core/core.module';
import {TestsClientModule} from './core/tests-client/tests-client.module';
import {AuthGuard} from './auth.guard';
import {DashboardModule} from './core/dashboard/dashboard.module';
import {AccountModule} from './modules/account/account.module';
import {SharedModule} from './shared/shared.module';
import {BnNgIdleService} from 'bn-ng-idle';

import {VideosModule} from './modules/videos/videos.module';
import {VideosRoutingModule} from './modules/videos/videos-routing.module';
import {AccountRoutingModule} from './modules/account/account-routing.module';
import {HttpInterceptorService} from './shared/http-interceptor.service';
import {TimeDateDirective} from './shared/directives/time-date.directive';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TransformDirective} from "./shared/directives/transform.directive";
import { ToastMsgComponent } from './modules/toast-msg/toast-msg.component';
import { NewVideosModule } from './modules/new-videos/new-videos.module';
import { DragDropModule } from '@angular/cdk/drag-drop';


@NgModule({
  declarations: [
    AppComponent,
    TransformDirective,
    HeaderComponent,
    TimeDateDirective,
    ToastMsgComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CoreModule,
    TestsClientModule,
    DashboardModule,
    AccountModule,
    AccountRoutingModule,
    SharedModule,
    // TesterModule,
    VideosModule,
    VideosRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right'
    }),
    NewVideosModule,
    DragDropModule
  ],
  providers: [
    AuthGuard,
    BnNgIdleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  
}
