import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router, Params, ActivatedRoute} from '@angular/router';
import {CreateNewTestService} from 'src/app/shared/services/create-new-test.service';

@Component({
  selector: 'app-breadcrumbs-navbar',
  templateUrl: './breadcrumbs-navbar.component.html',
  styleUrls: ['./breadcrumbs-navbar.component.scss']
})
export class BreadcrumbsNavbarComponent implements OnInit {

  showBreadCrumbs: boolean = true;
  testSelected: boolean = false;
  // route
  currentRouteVariable: any;
  // project id
  projectId: any;
  projectName: any = "loading";
  currentTestId: any;
  testId: any;
  testName: any = "loading";

  constructor(
    public _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _createTestServices: CreateNewTestService,
  ) { }

  ngOnInit(): void {
    this.currentRouteVariable = this._router.url.split('?')[0];

    this._activatedRoute.queryParams.subscribe((params) => {
      if(params['project-id'] != undefined && params['test-id'] != undefined){
        this.currentTestId = params['test-id'];
        this.getProjectInfo(params['project-id']);
        this.getTestInfo(params['project-id']);
        this.testSelected = true;
      }
      else if(params['project-id'] != undefined){
        this.getProjectInfo(params['project-id']);
        this.testSelected = false;
      } else{
        if(this.currentRouteVariable == "/research/tests" || this.currentRouteVariable == "/research/tests" || this.currentRouteVariable == "/results/clips" || this.currentRouteVariable == "/results/highlight-reels"){
          this.showBreadCrumbs = false;
        } else{
          this.showBreadCrumbs = true;
        }
      }
    });
  }


  getProjectInfo(id){
    this._createTestServices.gelAllProjectList().subscribe((res) => {
      let projectList = res.data;
      projectList.forEach((projectItem)=>{
        if(projectItem.id == Number(id)){
          this.projectId = projectItem.id;
          this.projectName = projectItem.project_name;
        }
      });
    });
  }

  getTestInfo(id){
    this._createTestServices.getTestRelatedToProjectId(id).subscribe((res: any)=>{
      let testList = res.data;
      // console.log("testList", testList)
      testList.forEach((testItem)=>{
        if(testItem.id == Number(this.currentTestId)){
          this.testId = testItem.id;
          // console.log("testItem.id", testItem.id)
          this.testName = testItem.test_name;
          // console.log("testItem.test_name", testItem.test_name)
        }
      });
    });
  }

  navigateProject(id){
    this._router.navigate(["/research/tests"], {queryParams: {'project-id': id}});
  }

  // navigateProject(id){
  //   this._router.navigate(["/research/tests"], {queryParams: {'project-id': id}});
  // }


}
