<div class='strength'>
  <ul class='strengthBar'>
    <li [style.background-color]='bar0' class='point'></li>
    <li [style.background-color]='bar1' class='point'></li>
    <li [style.background-color]='bar2' class='point'></li>
    <li [style.background-color]='bar3' class='point'></li>
  </ul>
  <p class="strength-txt" [style.color]='msgColor'>{{ msg }}</p>
  <!-- <br/> -->
</div>
