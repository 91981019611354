

<div class="up-billing-container">
  <div class="up-billing-content">
    <h1>Billing and Invoices</h1>
    <h2 class="billing-sub-title">Subscription Information</h2>
    <ng-container *ngIf="userSubscriptionHistoryList.length > 0">
      <section class="table-container">
        <div class="table-header">
          <div class="table-heading">
            Date
          </div>
          <div class="table-heading">
            Amount
          </div>
          <div class="table-heading">
            Receipt
          </div>
        </div>
        <div class="table-content">
          <div *ngFor="let subscriptionItem of userSubscriptionHistoryList" class="table-row">
            <div class="table-text">
              {{modifyDate(subscriptionItem.subscription_start_date)}}
            </div>
            <div class="table-text">
              {{subscriptionItem.amount}}
            </div>
            <ng-container *ngIf="subscriptionItem.invoice_url != null || subscriptionItem.invoice_url != '' ">
              <div (click)="openLink(subscriptionItem.invoice_url)" class="table-button">
                Open Invoice
              </div>
            </ng-container>
            <ng-container *ngIf="subscriptionItem.invoice_url === null || subscriptionItem.invoice_url === '' ">
              <div class="table-text" style="text-align: center; width: 65px;">-</div>
            </ng-container>
          </div>
        </div>
      </section>
    </ng-container>
    <ng-container *ngIf="userSubscriptionHistoryList.length <= 0">
      <div style="margin: 20px 0px 100px 0px;">
        <p
          style="
            font-family: var( --font-regular);
            font-size: 15px;
          "
        >
          Subscription purchase not made yet.
        </p>
      </div>
    </ng-container>

    <div class="invoice-container">
      <h2 class="invoices-sub-title">Credit Purchases</h2>
      <ng-container *ngIf="userCreditHistoryList.length > 0">
        <section class="table-container">
          <div class="table-header">
            <div class="table-heading">
              Date
            </div>
            <div class="table-heading">
              Provider
            </div>
            <div class="table-heading">
              Receipt
            </div>
          </div>
          <div class="table-content">
            <div *ngFor="let creditItem of userCreditHistoryList" class="table-row">
              <div class="table-text">
                {{modifyDate(creditItem.subscription_start_date)}}
              </div>
              <div class="table-text">
                {{creditItem.amount}}
              </div>
              <ng-container *ngIf="creditItem.invoice_url != null && creditItem.invoice_url != '' ">
                <div (click)="openLink(creditItem.invoice_url)" class="table-button">
                  Open Invoice
                </div>
              </ng-container>
              <ng-container *ngIf="creditItem.invoice_url === null || creditItem.invoice_url === '' ">
                <div class="table-text" style="text-align: center; width: 65px;">-</div>
              </ng-container>
            </div>
          </div>
        </section>
      </ng-container>
      <ng-container *ngIf="userCreditHistoryList.length <= 0">
        <div style="margin: 20px 0px 100px 0px;">
          <p
            style="
              font-family: var( --font-regular);
              font-size: 15px;
            "
          >
            Credits purchase not made yet.
          </p>
        </div>
      </ng-container>
    </div>

  </div>
</div>


<!-- ------------------------------------------------------------------------------------ -->



<div class="tests-container account lg-screen-width up-test-container">
  <section class="section test-section">
    <!-- <ng-template #noItems>
      <section class="section test-section p-0">
        <div class="billing-container">
          <div class="billing-sub mt-o">
            <p class="text-center">
              {{ isAccountCreateMsg[0]
              }}<a class="completedprofile-link" routerLink="/creditPlan">
                {{ isAccountCreateMsg[1] }}</a
              >
            </p>
          </div>
        </div>
      </section>
    </ng-template> -->

    <ng-container *ngIf="false">
      <form
        (ngSubmit)="updateCustomer()"
        [formGroup]="creditCustomerPlan"
        action="credit"
        class="credit-form"
      >
        <div class="billing-container">
          <h4>Billing and Invoices</h4>
          <div class="billing-sub">
            <h5>Billing Information</h5>
            <hr class="horizontal-rule" />
            <div formGroupName="contact">
              <div class="get-name-parent">
                <div class="get-name-firstchild">
                  <label>First name*</label>
                  <input
                    formControlName="first"
                    placeholder=""
                    type="text"
                  />
                  <div
                    *ngIf="
                      (submitted &&
                        creditCustomerPlan['controls'].contact['controls'].first
                          .errors) ||
                      creditCustomerPlan.get('contact.first').touched
                    "
                    class="invalidfeedback"
                  >
                    <div
                      *ngIf="
                        creditCustomerPlan['controls'].contact['controls'].first
                          .errors?.required ||
                        creditCustomerPlan['controls'].contact['controls'].first
                          .errors?.first
                      "
                    >
                      <small class="text-danger">First name is required</small>
                    </div>
                  </div>
                </div>

                <div class="get-name-secondchild">
                  <label>Last name*</label>
                  <input
                    formControlName="last"
                    placeholder=""
                    type="text"
                  />
                  <div
                    *ngIf="
                      (submitted &&
                        creditCustomerPlan['controls'].contact['controls'].last
                          .errors) ||
                      creditCustomerPlan.get('contact.last').touched
                    "
                    class="invalidfeedback"
                  >
                    <div
                      *ngIf="
                        creditCustomerPlan['controls'].contact['controls'].last
                          .errors?.required ||
                        creditCustomerPlan['controls'].contact['controls'].last
                          .errors?.last
                      "
                    >
                      <small class="text-danger">Last name is required</small>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <label>Email</label>
              <input
                formControlName="email"
                placeholder="Businesswoman"
                type="text"
              />
              <div
                *ngIf="
                  (submitted &&
                    creditCustomerPlan['controls'].contact['controls'].email
                      .errors) ||
                  creditCustomerPlan.get('contact.email').touched
                "
                class="invalidfeedback"
              >
                <div
                  *ngIf="
                    creditCustomerPlan['controls'].contact['controls'].email
                      .errors?.required ||
                    creditCustomerPlan['controls'].contact['controls'].email
                      .errors?.email
                  "
                >
                  <small class="text-danger">Email is required</small>
                </div>
              </div> -->

              <div class="get-name-parent-org">
                <div class="">
                  <label>Organization</label>
                  <input
                    formControlName="company"
                    placeholder=""
                    type="text"
                  />
                  <div
                    *ngIf="
                      (submitted &&
                        creditCustomerPlan['controls'].contact['controls']
                          .company.errors) ||
                      creditCustomerPlan.get('contact.company').touched
                    "
                    class="invalidfeedback"
                  >
                    <div
                      *ngIf="
                        creditCustomerPlan['controls'].contact['controls']
                          .company.errors?.required ||
                        creditCustomerPlan['controls'].contact['controls']
                          .company.errors?.company
                      "
                    >
                      <small class="text-danger"
                        >Company name is required</small
                      >
                    </div>
                  </div>
                </div>

                <div class="country-element">
                  <label>Country</label>
                  <select
                    class="country"
                    formControlName="country"
                    name="country"
                  >
                    <option disabled hidden value="">Select country</option>
                    <option
                      *ngFor="let residence of residences"
                      [ngValue]="residence"
                    >
                      {{ residence }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      (submitted &&
                        creditCustomerPlan['controls'].country.errors) ||
                      creditCustomerPlan.get('country').touched
                    "
                    class="invalidfeedback"
                  >
                    <div
                      *ngIf="
                        creditCustomerPlan['controls'].country.errors
                          ?.required ||
                        creditCustomerPlan['controls'].country.errors?.country
                      "
                    >
                      <small class="text-danger">country is required</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="get-name-parent-postal">
                <div>
                  <label>ZIP Code</label>
                  <!-- (keydown)="get_input($event)" -->
                  <input
                    formControlName="postalCode"
                    placeholder=""
                    type="text"
                  />
                  <div
                    *ngIf="
                      (submitted &&
                        creditCustomerPlan['controls'].address['controls']
                          .postalCode.errors) ||
                      creditCustomerPlan.get('address.postalCode').touched
                    "
                    class="invalidfeedback"
                  >
                    <div
                      *ngIf="
                        creditCustomerPlan['controls'].address['controls']
                          .postalCode.errors?.required ||
                        creditCustomerPlan['controls'].address['controls']
                          .postalCode.errors?.address['controls'].postalCode
                      "
                    >
                      <small class="text-danger">Postal code is required</small>
                    </div>

                    
                  </div>
                </div>

                <div>
                  <label>City</label>
                  <input
                    formControlName="city"
                    placeholder=""
                    type="text"
                  />
                </div>
              </div>

              <div class="address-street">
                <div class="billing-sub" formGroupName="address">
                  <label>Street / Nr.</label>
                  <input
                    formControlName="addressLine1"
                    placeholder=""
                    type="text"
                  />
                  <div
                    *ngIf="
                      (submitted &&
                        creditCustomerPlan['controls'].address['controls']
                          .addressLine1.errors) ||
                      creditCustomerPlan.get('address.addressLine1').touched
                    "
                    class="invalidfeedback"
                  >
                    <div
                      *ngIf="
                        creditCustomerPlan['controls'].address['controls']
                          .addressLine1.errors?.required ||
                        creditCustomerPlan['controls'].address['controls']
                          .addressLine1.errors?.address['controls'].addressLine1
                      "
                    >
                      <small class="text-danger">AddressLine1 is required</small>
                    </div>
                  </div>
                  </div>
              </div>

              <div  class="billing-sub">
                <button class="save update-account update-account-btn-active" data-target="#saveChange" data-toggle="modal" type="submit">
                  Update Account
                </button>
              </div>

              <div class="invoices-parent">
                <div>
                  <p class="invoices-text">Invoices</p>
                </div>
                <hr class="horizontal-rule-invoice"/>
              </div>

              <div class="view-invoice-parent">
                <div>
                  <a class="view-invioce-link" href="https://userpeek.onfastspring.com/account" target="_blank">View invoices </a>
                </div>
              </div>

              <!-- <label>Phone</label>
              <input formControlName="phone" placeholder="Phone" type="text" />

              <div
                *ngIf="
                  (submitted &&
                    creditCustomerPlan['controls'].contact['controls'].phone
                      .errors) ||
                  creditCustomerPlan.get('contact.phone').touched
                "
                class="invalidfeedback"
              >
                <div
                  *ngIf="
                    creditCustomerPlan['controls'].contact['controls'].phone
                      .errors?.required ||
                    creditCustomerPlan['controls'].contact['controls'].phone
                      .errors?.phone
                  "
                >
                  <small class="text-danger">Phone is required</small>
                </div>
              </div> -->
            </div>

            <!-- <label>Language</label>
            <select class="country" formControlName="language" name="language">
              <option disabled hidden value="">Select language</option>

              <option *ngFor="let lang of languagecode" [ngValue]="lang">
                {{ lang }}
              </option>
            </select>
            <div
              *ngIf="
                (submitted && creditCustomerPlan['controls'].language.errors) ||
                creditCustomerPlan.get('language').touched
              "
              class="invalidfeedback"
            >
              <div
                *ngIf="
                  creditCustomerPlan['controls'].language.errors?.required ||
                  creditCustomerPlan['controls'].language.errors?.language
                "
              >
                <small class="text-danger">language is required</small>
              </div>
            </div> -->
          </div>
        
        
          <!-- <div class="billing-sub" formGroupName="address">
            <h5>Billing Address</h5>
            <label>Address1</label>
            <input
              formControlName="addressLine1"
              placeholder="Testingcompany LTD"
              type="text"
            />
            <div
              *ngIf="
                (submitted &&
                  creditCustomerPlan['controls'].address['controls']
                    .addressLine1.errors) ||
                creditCustomerPlan.get('address.addressLine1').touched
              "
              class="invalidfeedback"
            >
              <div
                *ngIf="
                  creditCustomerPlan['controls'].address['controls']
                    .addressLine1.errors?.required ||
                  creditCustomerPlan['controls'].address['controls']
                    .addressLine1.errors?.address['controls'].addressLine1
                "
              >
                <small class="text-danger">AddressLine1 is required</small>
              </div>
            </div>



            <label class="optional">optional</label>
            <label>Address2</label>
            <input
              formControlName="addressLine2"
              placeholder="Business Street 123"
              type="text"
            />
  

            <label>Region</label>
            <input
              formControlName="region"
              placeholder="Enter your region"
              type="text"
            />
          </div>
-->
        </div>
      </form>
    </ng-container>
  </section>

  <!-- <button class="feedback-btn">Feedback</button> -->
</div>




