<form (ngSubmit)="createNotesAndClip()" [formGroup]="clientNotes">
  <div class="tab-pane-sub notes-box">
    <div class="edit-row">
      <div *ngIf="editSeletedNotes" class="edit" style="cursor: pointer">
        <img alt="edit" src="assets/images/icons/video-edit.png"/>
      </div>
      <div class="duration">
        <input class="start-duration" style="width: 47px;" formControlName="start" mask="00:00" placeholder="00:00" type="text"/>

        <input *ngIf="clientNotes.controls.clip.value" class="end-duration" style="width: 47px;" formControlName="end" mask="00:00"
               placeholder="00:00" type="text"/>
      </div>
      <div class="check">
        <span class="checkbox">
          <label style="display: flex; flex-flow: row-reverse; font-weight:500; align-items:center;">Make clip
            <input type="checkbox" formControlName="clip" (change)="checkClipValue($event)"/>
            <span style="margin-right: 3px;" class="clip"></span>
          </label>
        </span>

      </div>
    </div>
    <textarea class="edit-text" formControlName="text" maxlength="80" placeholder="Start typing here..."></textarea>
    <div>
      <small class="text-danger">
        <div>{{ timeErrorMessage }}</div>
      </small>
    </div>
    <div *ngIf="(submitted && clientNotes.controls.text.errors) ||clientNotes.get('text').touched"
         class="invalidfeedback">
      <div *ngIf="clientNotes.controls.text.errors?.required">
        <small class="text-danger"> Notes are required</small>
      </div>
    </div>
    <div *ngIf="(submitted && clientNotes.controls.start.errors) ||clientNotes.get('start').touched"
         class="invalidfeedback">
      <div *ngIf="clientNotes.controls.start.errors?.required">
        <small class="text-danger">Start time are required</small>
      </div>
    </div>

    <div *ngIf="(submitted && clientNotes.controls.end.errors) ||clientNotes.get('end').touched"
         class="invalidfeedback">
      <div *ngIf="clientNotes.controls.end.errors?.required">
        <small class="text-danger">End time are required</small>
      </div>
    </div>

    <div class="button-comment">
      <button *ngIf="editSeletedNotes" type="button" class="cancel" (click)="closeEditBox()">
        Cancel
      </button>
      <button class="save" type="submit">
        {{ editSeletedNotes ? "update" : "Add" }}
      </button>
    </div>
  </div>
</form>
