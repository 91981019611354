import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/shared/services/auth.service';
import {ModelBoxService} from 'src/app/shared/services/model-box.service';
import Swal from 'sweetalert2';
import {User} from '../../models/user.model';
import {UserService} from '../../../shared/services/user.service';
import { CustomPopupBoxService } from 'src/app/shared/services/custom-popup-box.service';
@Component({
  selector: 'app-complete-profile-section',
  templateUrl: './complete-profile-section.component.html',
  styleUrls: ['./complete-profile-section.component.scss'],
})
export class CompleteProfileSectionComponent implements OnInit {
  @Input() getRegisterSecondStepData: any;
  submitted: boolean;
  clientProfileForm: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private _router: Router,
    private _modelBoxServ: ModelBoxService,
    private _customPopupBoxService: CustomPopupBoxService,
  ) {
  }

  ngOnInit() {
    this.initClientProfileForm();
  }

  initClientProfileForm() {
    this.clientProfileForm = this._fb.group({
      username: [""],
      email: [""],
      password: [""],
      extra: new FormGroup({
        first_name: new FormControl("", Validators.required),
        last_name: new FormControl("", Validators.required),
      }),
    });
  }

  onCompleteRegisterForm() {
    let that = this;
    if (this.clientProfileForm.invalid) {
      this.submitted = true;
      return;
    }

    this.clientProfileForm.patchValue({
      username: this.getRegisterSecondStepData.username,
      email: this.getRegisterSecondStepData.email,
      password: this.getRegisterSecondStepData.password,
    });

    window['grecaptcha'].ready(function () {
      window['grecaptcha']
        .execute('6LcXITEaAAAAAJ-PIRcGLjGH_EaZbplqPL7Xm8kX', {
          action: 'submit',
        })
        .then(function (token) {
          that.submitted = true;
          that.submitted = true;

          if (that.clientProfileForm.invalid) {
            return;
          }
          that.submitted = false;
          let reg = that.clientProfileForm.value;
          // localStorage.setItem('role', that.urlType);
          reg['captchaToken'] = token;
          that.reg(reg);
          // that.clientProfileForm.reset();
        });
    });
  }

  reg(reg: User) {
    let that = this;
    this.userService.register(reg).subscribe(
      (employeeUser) => {
        let registerRes = employeeUser;
        if (registerRes.status == 200) {
          this.clientProfileForm.reset();
          localStorage.setItem('reg', JSON.stringify(registerRes));
          let modelBody = 'Please confirm your email address to complete the registration'
          let modelHeader = 'Account created'
          this._customPopupBoxService.showPopup("signup-confirm-email")
          // this._modelBoxServ.showModelBox(true,modelBody,modelHeader);
          that._router.navigate(['login']);
          // Swal.fire({
          //   text: registerRes.message,
          //   confirmButtonText: 'Log in to your account',
          //   background: '#f9f9f9',
          //   confirmButtonColor: '#1200FF',
          // }).then(function () {
          //   that._router.navigate(['login']);
          // });
        } else {
          this._modelBoxServ.showModelBox(false, registerRes.message, 'Error');
        }
      },
      (err) => {
        this._modelBoxServ.showErrorToster(err);
      }
    );
  }
}
