import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {RegisterComponent} from './auth/register/register.component';
import {LoginComponent} from './auth/login/login.component';
import {AuthGuard} from '../auth.guard';
import {DashboardComponent} from './dashboard/dashboard.component';
import {DashboardTestComponent} from './dashboard-test/dashboard-test.component';
import {PasswordStrengthComponent} from './password-strength/password-strength.component';
import {SharedModule} from '../shared/shared.module';
import {CoreRoutingModule} from './core-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Ng5SliderModule} from 'ng5-slider';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {DashboardTestEditComponent} from './dashboard-test/dashboard-test-edit/dashboard-test-edit.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {VideosComponent} from '../modules/videos/videos.component';
import {NgxMaskModule} from 'ngx-mask';
import {VideoItemComponent} from '../modules/videos/video-item/video-item.component';
import {TestItemComponent} from './test-item/test-item.component';
import {TestOrderComponent} from './test-order/test-order.component';
import {CompleteProfileSectionComponent} from './auth/complete-profile-section/complete-profile-section.component';
import { HighlightReelComponent } from './highlight-reel/highlight-reel.component';
import { CreateHighlightReelComponent } from './create-highlight-reel/create-highlight-reel.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ProjectDashboardComponent } from './project-dashboard/project-dashboard.component';
import { ActivateEmailLoginComponent } from './auth/activate-email-login/activate-email-login.component';
import { CreditsPlansNewComponent } from '../modules/account/credits-plans-new/credits-plans-new.component';
import { TestDashboardNewComponent } from './test-dashboard-new/test-dashboard-new.component';
import { VideosDashboardNewComponent } from './videos-dashboard-new/videos-dashboard-new.component';
import { ClipsDashboardNewComponent } from './clips-dashboard-new/clips-dashboard-new.component';
import { HighlightReelDashboardNewComponent } from './highlight-reel-dashboard-new/highlight-reel-dashboard-new.component';
import { PluginVideoPlayerComponent } from './plugin-video-player/plugin-video-player.component';
import { ViewClipWrapperComponent } from './view-clip-wrapper/view-clip-wrapper.component';
import { ReportsDashboardComponent } from './reports-dashboard/reports-dashboard.component';
import { FindingsDashboardComponent } from './findings-dashboard/findings-dashboard.component';
@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    VideosComponent,
    DashboardComponent,
    DashboardTestComponent,
    PasswordStrengthComponent,
    DashboardTestEditComponent,
    VideoItemComponent,
    TestItemComponent,
    TestOrderComponent,
    CompleteProfileSectionComponent,
    HighlightReelComponent,
    CreateHighlightReelComponent,
    ProjectDashboardComponent,
    ActivateEmailLoginComponent,
    CreditsPlansNewComponent,
    TestDashboardNewComponent,
    VideosDashboardNewComponent,
    ClipsDashboardNewComponent,
    HighlightReelDashboardNewComponent,
    PluginVideoPlayerComponent,
    ViewClipWrapperComponent,
    ReportsDashboardComponent,
    FindingsDashboardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // PasswordStrengthMeterModule,
    Ng5SliderModule,
    SharedModule,
    BrowserAnimationsModule,
    DragDropModule,

    SimpleNotificationsModule.forRoot(),
    CoreRoutingModule,
    NgxSpinnerModule,
    NgxMaskModule.forRoot({showMaskTyped: true}),
  ],
  providers: [AuthGuard],
  exports:[
    ProjectDashboardComponent,
  ],
})
export class CoreModule {
}
