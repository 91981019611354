import { Component, OnInit, OnDestroy, Renderer2  } from '@angular/core';
import { CreateNewTestService } from 'src/app/shared/services/create-new-test.service';
import { CustomPopupBoxService } from 'src/app/shared/services/custom-popup-box.service';
import {ActivatedRoute, Router, Params} from '@angular/router';
import { AllFiltersShareQueryService } from 'src/app/shared/services/all-filters-share-query.service';
import { GeneralApiService } from '../api-call/general-api.service';
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';
import { log } from 'console';

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})

export class ProjectDashboardComponent implements OnInit, OnDestroy {

  filteredJsonData = [];
  unfilteredJsonData = [];
  queriedJsonData = [];
  paginateCount: number = 0;
  buttonFilterData: any ={
    allVideos: 0,
    active: 0,
    archive: 0,
  };
  isDataAvailable:boolean = true;
  selectedChipFilter: string = "all";

  // custom popup
  showUPCustomPopup: boolean = false;
  customPopupDisplayContent: string = "";
  
  // rename
  renameValueExist:boolean = false;
  renameExistingValue: string = "";
  renameInputValue: string = "";
  allFilterQueryDataObservable: any;
  // delete project
  deleteProjectId: number = null;
  deleteProjectInput: string = "";


  openPopupId: any | null = null;  // Tracks the currently open popup's ID
  popupStyle = {}; 

  constructor(
    private _createnewTestServices: CreateNewTestService,
    public _customPopupBoxService: CustomPopupBoxService,
    private router: Router,
    private _allFiltersShareQueryService: AllFiltersShareQueryService,
    private generalApi: GeneralApiService,
    private toastMsgService: ToastMsgService,
  ) { 
    this.allFilterQueryDataObservable = this._allFiltersShareQueryService.componentFilterQueryData.subscribe((res)=>{
      if(res.requestType != "none"){
        if(res.component == this.router.url.split('?')[0]){
          this.processComponentFilterData(res);
        }
      }
    });
  }

  




  togglePopup(id: string, event: MouseEvent) {
    event.stopPropagation();
    this.openPopupId = this.openPopupId === id ? null : id;
    if (this.openPopupId) {
      this.calculatePopupPosition(event);
    } else {
      this.popupStyle = {};
    }
  }

 
  calculatePopupPosition(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const rect = target.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom;
    this.popupStyle = {
      position: 'absolute',
      top:spaceBelow < 150 ? `-140px` : `25px`
    };
  }

  ngOnDestroy(): void {
    this.allFilterQueryDataObservable.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeComponentData();

    // this._allFiltersShareQueryService.componentFilterQueryData.subscribe((res)=>{
    //   if(res.requestType != "none"){
    //     if(res.component == this.router.url.split('?')[0]){
    //       this.processComponentFilterData(res);
    //     }
    //   }
    // });
  }

  initializeComponentData(){
    this._createnewTestServices.gelAllProjectList().subscribe((res) => {

      this.isDataAvailableFun(res.data.length);

      
      this.unfilteredJsonData = [];
      this.queriedJsonData = [];
      res.data.forEach(item => {
        this.unfilteredJsonData.push(item);
        this.queriedJsonData.push(item);

        
        // // initialize chips filter data
        // if(item.project_status == 0){ // active
        //   this.buttonFilterData.active ++;
        // }
        // else if(item.project_status == 5){ // archive
        //   this.buttonFilterData.archive ++;
        // }
      });

      this.validatePaginateCount();

      // sort with id
      this.queriedJsonData.sort(function(a: any, b: any){
        return a.id - b.id;
      })
      // decending/reverse order
      this.queriedJsonData.sort().reverse();

      // initially show only 10 items
      this.filteredJsonData = [];
      for(let i = 0; i < this.paginateCount; i++){
        this.filteredJsonData.push(this.queriedJsonData[i])
      }
    })
  }

  processComponentFilterData(value){
    if(value.requestType == "getDataFromParent"){
      this._allFiltersShareQueryService.sendDataParentToChild(this.unfilteredJsonData);
    }
    else if(value.requestType == "dataChildToParent"){
      let tempClipList = [] 
      this._allFiltersShareQueryService.childToParentDataVariable.subscribe((res)=>{
        tempClipList = res;
      });
      this.queriedJsonData = tempClipList;
      this.initializePaginationFun();
    }
    else if(value.requestType == "loadOriginalData"){
      this.initializeComponentData();
    }
  }

  createProject(){
    this._customPopupBoxService.showPopup("create-project-popup");
  }

  isDataAvailableFun(value){
    // if json data is empty show empty message
    if(value > 0){
      this.isDataAvailable = true;
    } else {
      this.isDataAvailable = false;
    }
  }

  validatePaginateCount(){
    if(this.queriedJsonData.length < 10){
      this.paginateCount = this.queriedJsonData.length;
    } else {
      this.paginateCount = 10;
    }
  }

  incrementPaginationCountFun(value){
    let actualQueriedDataLength = this.queriedJsonData.length;
    if(value < actualQueriedDataLength){
      this.paginateCount = value;
    } else {
      this.paginateCount = actualQueriedDataLength;
    }
    this.restrictDataCount();
  }

  restrictDataCount(){
    this.filteredJsonData = [];
    for(let i = 0; i < this.paginateCount; i++){
      this.filteredJsonData.push(this.queriedJsonData[i])
    }
  }

  applyButtonFilterFun(value){
    // if filter applied is "active"
    if(value === "active"){
      this.selectedChipFilter = "active";
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        if(item.project_status == 0){
          this.queriedJsonData.push(item);
        }
      })
    } 
    else if(value === "all"){
      // if filter applied is "all-projects"
      this.selectedChipFilter = "all";
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
          this.queriedJsonData.push(item);
      })
    }
    else if(value === "archive"){
      // if filter applied is "archive"
      this.selectedChipFilter = "archive";
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        if(item.project_status == 5){
          this.queriedJsonData.push(item);
        }
      })
    }
    this.initializePaginationFun();
    this.isDataAvailableFun(this.queriedJsonData.length);
  }

  initializePaginationFun(){
    this.validatePaginateCount();
    this.filteredJsonData = [];
    for(let i = 0; i < this.paginateCount; i++){
      this.filteredJsonData.push(this.queriedJsonData[i])
    }
  }

  openTestWithProjectId(projectDetials, projectId){
    localStorage.setItem("headerProjectName", projectDetials.project_name);
    localStorage.setItem("selectedProjectId", projectDetials.id);
    let body ={
      "last_project_seen": projectId
    }
    this.generalApi.updateProjectdata(body).subscribe((apiResponse)=>{
      
    });

    this.router.navigate(['/research/tests'], {queryParams: {'project-id': projectDetials.id}});
  }

  

  openCustomPopup(displayContent:any){
    this.customPopupDisplayContent = displayContent;
  
    
    this.showUPCustomPopup = true;
  
  }



  handleRenameValueChanges(event){
    console.log("event", event)
    let x = this.unfilteredJsonData.find((e) =>{ return(e.project_name.toLowerCase() === event.toLowerCase())})
    if(x == undefined){
      this.renameValueExist = false;
    } else {
      this.renameValueExist = true;
    }
  }

  sendRenameRequest(){
    let jsonData = {
      "project_name": this.renameExistingValue,
      "new_project_name": this.renameInputValue
    }
    this._createnewTestServices.renameProject(jsonData).subscribe((res) =>{
      this.showUPCustomPopup = false;
      this.initializeComponentData();
    })
  }

  deleteProject(){
    this.generalApi.deleteProjectAPI(this.deleteProjectId).subscribe((apiResponse)=>{
      if(apiResponse.status === 200 || apiResponse.status === 201 ){
        this.toastMsgService.showMsg("success", "Project deleted successfully .")
      } else {
        this.toastMsgService.showMsg("failed", apiResponse.message, 3000);
      }
      this.showUPCustomPopup = false;
      this.initializeComponentData()
    });
  }
  validateDeleteProject(){
    if(this.deleteProjectInput.toLowerCase() === "delete"){
      return true;
    } else {
      return false;
    }
  }
  duplicateProject(project_id){
    let body ={
      "project_id": project_id
    }
    this.generalApi.duplicateProjectAPI(body).subscribe((apiResponse)=>{
      if(apiResponse.status === 200 || apiResponse.status === 201 ){
        this.toastMsgService.showMsg("success", "Project duplicated successfully.")
      } else {
        this.toastMsgService.showMsg("failed", apiResponse.message, 3000);
      }
      this.showUPCustomPopup = false;
      this.initializeComponentData()
    });
  }


  viewTemplate(){
    this.router.navigate(['/research/tests/create-test'], {queryParams: {'show-templates': true}});
  }


}
