<div class="create-highlight-reel-wrapper">
  <div class="create-highlight-reel-container">
  
      <!-- popup component to get reel name from user -->
      <!-- <div class="c-h-reel-popup-container" id="c-h-reel-popup-container" [ngStyle]="{'display' : popupDisplayProperty }">
        <div class="c-h-reel-popup-content">
          <button (click)="closePopup()" type="button" class="c-h-reel-popup-close-btn">&#10006;</button>
          <p class="c-h-reel-popup-heading">Please name this Highlight Reel</p>
          <input  type="text" class="c-h-reel-popup-input" [(ngModel)]="tempReelHeading">
          <button (click)="updateHeading()" class="c-h-reel-popup-save-btn">Save</button>
        </div>
      </div> -->
  
      <!-- heading logic based on new reel or existing reel -->
      <div class="c-h-reel-heading-container">
        <h1 class="c-h-reel-heading">
          {{ reelHeading }}
        </h1>
        <img class="c-h-reel-heading-edit-icon" src="assets/images/icons/edit.svg" alt="edit icon" (click)="openPopup()" />
      </div>
    
  
      <!-- Total Clips Length and Saved date -->
      <div class="c-h-reel-clips-meta-data">
          <div class="c-h-reel-clips-length">
              Total Length: {{displayTotalDuration}} (max: 10 min)
              <ng-container *ngIf="totalSortedClipsCount > 600">
                <span style="color: var(--red); font-size: 14px; margin-left: 10px;">
                  Please shorten the Highlight reel to 10 minutes or less to save it.
                </span>
              </ng-container>
          </div>
          <!-- <div class="c-h-reel-saved-date">
              &nbsp;&nbsp;&nbsp;| {{ createdDate }}
          </div> -->
      </div>
  
      <!-- Draggable container -->
  
      <div class="c-h-reel-drag-container">
  
        <!-- All clips list -->
        <div class="c-h-reel-drag-left-container">
          
          <!-- search bar -->
          <input type="text" class="c-h-reel-search-input"  [(ngModel)]="clipsFilterValue"  (ngModelChange)="handleClipsSearch($event)" placeholder="Search by clip id and tester."/>
          
          

          <!-- list of clips -->
          <div class="c-h-reel-clips-list-container">
            
            <div
            cdkDropList
            #todoList="cdkDropList"
            [cdkDropListData]="clipsList"
            [cdkDropListConnectedTo]="[doneList]"
            class="c-h-reel-clips-list"
            (cdkDropListDropped)="drop($event)">
  

              <table class="clips-dash-table">
                <tr class="title-row">
                    <th class="clip-thumbnail-title">Clip</th>
                    <th class="clip-id-title">Clip-Id</th>
                    <!-- <th class="clip-name-title">Test</th> -->
                    <th class="tester-title">Tester</th>
                    <th class="date-title">Date</th>
                    <th class="action-title">Action</th>
                </tr>
                <tr class="data-row" *ngFor="let clipItem of clipsList; let i = index" [attr.data-index]="i" cdkDrag>
                    <td class="clip-thumbnail-data" >
                        <div class="clip-thumbnail-cont" [ngStyle]="{'background-image': 'url(' + baseUrl+clipItem.thumbnail + ')'}">
                            <p class="clip-thumbnail-timer">{{clipItem.duration}}</p>
                        </div>
                    </td>
                    <td class="clip-id-data">
                        <div class="clip-id-cont">
                            {{clipItem.testedtest_clip_id}}
                        </div>
                    </td>
                    <td class="clip-name-data">
                      <div>
                        {{clipItem.tester_name}}
                      </div>
                    </td>
                    <td class="date-data">{{clipItem.created_date | date}}</td>
                    <td class="action-data"> 
                        <div class="dash-context-menu-wrapper" (click)="showContextMenu($event)">
                            <img class="dash-context-menu-icon" src="assets/images/icons/menu-dots.svg" alt="icon">
                            <div class="dash-context-menu-cont">
                                <div class="dash-context-menu-content">
                                    <!-- <button class="dash-context-menu-edit">Edit</button> -->
                                    <button (click)="addClipToList(i, clipItem)"  class="dash-context-menu-archive">Add to reel</button>
                                    <button class="dash-context-menu-delete" (click)="downloadClip(clipItem.clip_path)">Download</button>
                                </div>
                            </div>
                        </div>             
                    </td>
                </tr>
              </table>
  
            </div>
  
          </div>
  
        </div>
  
        <!-- sorted clips | new reel clips -->
        <div class="c-h-reel-drag-right-container">
          
          <!-- sorted clips -->
          <div class="c-h-reel-sort-clips-container">
  
            <div
            cdkDropList
            #doneList="cdkDropList"
            [cdkDropListData]="sortClips"
            [cdkDropListConnectedTo]="[todoList]"
            class="c-h-reel-sort-clips"
            (cdkDropListDropped)="drop($event)">
            
              <table class="clips-dash-table">
                <tr class="title-row">
                    <th class="clip-thumbnail-title">Clip</th>
                    <th class="clip-id-title">Clip-Id</th>
                    <!-- <th class="clip-name-title">Test</th> -->
                    <th class="tester-title">Tester</th>
                    <th class="date-title">Date</th>
                    <th class="action-title">Action</th>
                </tr>
                <tr class="data-row" *ngFor="let clipItem of sortClips; index as i" [attr.data-index]="i" cdkDrag>
                    <td class="clip-thumbnail-data" >
                        <div class="clip-thumbnail-cont" [ngStyle]="{'background-image': 'url(' + baseUrl+clipItem.thumbnail + ')'}">
                            <p class="clip-thumbnail-timer">{{clipItem.duration}}</p>
                        </div>
                    </td>
                    <td class="clip-id-data">
                        <div class="clip-id-cont">
                            {{clipItem.testedtest_clip_id}}
                        </div>
                    </td>
                    <td class="tester-data">
                      <div>
                        {{clipItem.tester_name}}
                      </div>
                    </td>
                    <td class="date-data">{{clipItem.created_date | date}}</td>
                    <td class="action-data"> 
                        <div class="dash-context-menu-wrapper-1">
                            <img (click)="removeClipFromList(i, clipItem)" class="remove-reels-btn dash-context-menu-icon" src="assets/images/icons/close-icon.svg" alt="icon">
                        </div>             
                    </td>
                </tr>
              </table>
  
            </div>
  
          </div>
  
        </div>
  
      </div>
  
      <div class="save-reel-btn-container">
        <a (click)="handleDiscardBtn()" class="cancel-btn">Discard</a>

        <!-- <button style="margin-right: 20px;" (click)="saveReelAsDraft()"
          class="btn-sty-blue save-reel-btn" [ngClass]="[sortClips.length && totalSortedClipsCount < 600 ? 'save-reel-btn-active' : 'save-reel-btn-inactive']">
          Save as draft
        </button> -->
        <button (click)="showCustomPopupBox = !showCustomPopupBox; customPopupBoxContent = 'save-reel' "
          class="btn-sty-blue save-reel-btn" [ngClass]="[sortClips.length && totalSortedClipsCount < 600 ? 'save-reel-btn-active' : 'save-reel-btn-inactive']">
          {{saveButtonText}}
        </button>
      </div>
      
  
  
  </div>
</div>

<div *ngIf="showCustomPopupBox" class="custom-popup-backdrop">
  <div class="custom-popup-body">
      <img (click)="showCustomPopupBox = !showCustomPopupBox" class="custom-popup-close-icon" src="assets/images/icons/close-icon.svg" alt="close icon">

      <div class="custom-popup-content">
        
        <!-- save reel -->
        <div class="save-reel-cont" *ngIf=" customPopupBoxContent === 'save-reel' ">

          <section class="header">
            <h1>Save Highlight reel</h1>
          </section>

          <main style="margin-top: 50px; margin-bottom: 80px">
            <p>Are you sure you want to save this Highlight reel?</p>
            <p>This action takes up to 5 minutes.</p>
            <p>You will get notified by email, when the Highlight reel is ready.</p>
          </main>

          <button (click)="handleSaveButton()" class="custom-popup-main-btn btn-sty-blue">Yes, save Highlight reel</button>

          <p style="font-size: 18px; font-family: var(--font-medium); text-align:center; margin-top: 25px;"
          (click)="showCustomPopupBox = !showCustomPopupBox" class="a-sty">Cancel</p>

        </div>

        <!-- reel rename -->
        <div class="reel-rename-cont" *ngIf=" customPopupBoxContent === 'reel-rename' ">

          <section class="header">
            <h1>Create Highlight reel</h1>
          </section>

          <main style="margin-top: 80px; margin-bottom: 60px">
            <p>Highlight reel name</p>
            <input  type="text" class="c-h-reel-popup-input" [(ngModel)]="tempReelHeading" (ngModelChange)="reelNameValidation($event)">
            <small style="color: var(--red); font-size: 12px;" *ngIf="checkReelNameIsValid">Empty space is not allowed</small>
          </main>

          <button (click)="updateHeading()" class="custom-popup-main-btn btn-sty-blue">Rename</button>

          <p style="font-size: 18px; font-family: var(--font-medium); text-align:center; margin-top: 25px;"
          (click)="showCustomPopupBox = !showCustomPopupBox" class="a-sty">Cancel</p>

        </div>
          
      </div>

  </div>
</div>

<script src='assets/js/custom.js'></script>