<div class='container'>
  <div class='row'>
    <div class='col-md-12'>
      <div class='error-template'>
        <h1>
          Oops!</h1>
        <h2>
          404 Not Found</h2>
        <div class='error-details'>
          The requested page could not be found.
        </div>

        <div class='error-actions'>
          <a [routerLink]="['/dashboard']" class='btn btn-primary btn-lg' routerLinkActibve='active'><span
            class='glyphicon glyphicon-home'></span>
            Go to dashboard </a>
            <!-- <a class='btn btn-default btn-lg'><span
          class='glyphicon glyphicon-envelope'></span> Contact Support </a> -->
        </div>
      </div>
    </div>
  </div>
</div>
