import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ModelBoxService} from 'src/app/shared/services/model-box.service';
import {TesterTestedListService} from 'src/app/shared/services/tester-tested-list.service';

@Component({
  selector: 'app-notes-clip-box',
  templateUrl: './notes-clip-box.component.html',
  styleUrls: ['./notes-clip-box.component.scss'],
})
export class NotesClipBoxComponent implements OnInit, OnDestroy {
  clientNotes: FormGroup;
  submitted: boolean;
  timeErrorMessage: string = null;
  currentTime: any;
  duration: number;
  makeClipCheckbox: boolean = false;
  selectedVideoDetailsVar: any;

  @Input() seletedDetailsObject: any;
  @Output() emitValuetoVideoInfo = new EventEmitter<string>();
  editSeletedNotes: any;
  quickNotesSubcription: Subscription;

  constructor(
    private fb: FormBuilder,
    private _modelBoxService: ModelBoxService,
    private _testedTestServices: TesterTestedListService,
  ) {
  }

  ngOnInit() {
    this.editSeletedNotes = this.seletedDetailsObject.editNotesClip;
    this.initCreateNoteClipForm();
    this.callforAddQuickNote();
    this.seletedDetailsObject;
    this.getSelectedVideoDetials();
  }

  getSelectedVideoDetials() {
    // get selected video detials
    this._testedTestServices.getTestedTestVideoList().subscribe(res => {
      let tempVideoDetials = res.data.video_list
      this.selectedVideoDetailsVar = tempVideoDetials.find(o => o.id == this.seletedDetailsObject.videoId);
      console.log('getting video url', this.selectedVideoDetailsVar);
    })
  }

  initCreateNoteClipForm() {
    this.clientNotes = this.fb.group({
      test: [this.editSeletedNotes ? this.editSeletedNotes.test : "", []],
      tested_test: [""],
      text: [
        this.editSeletedNotes ? this.editSeletedNotes.text : "",
        [Validators.required],
      ],
      clip: [this.editSeletedNotes ? this.editSeletedNotes.clip : false],
      start: [
        this.editSeletedNotes ? this.editSeletedNotes.start : "",
        [Validators.required],
      ],
      end: [this.editSeletedNotes ? this.editSeletedNotes.end : ""],
    });
  }

  checkClipValue(event) {
    if (!event.currentTarget.checked) {
      this.clientNotes.patchValue({
        end: "",
      });
      // if make clip checkbox unchecked false
      this.makeClipCheckbox = false;
      console.log('inside checkbox fun', this.makeClipCheckbox);
    } else {
      // if make clip checkbox unchecked true
      this.makeClipCheckbox = true;
      console.log('inside checkbox fun', this.makeClipCheckbox);
    }

  }

  callforAddQuickNote() {
    this.quickNotesSubcription = this._testedTestServices._getQuickNote.subscribe((qNotesRes) => {

      if (qNotesRes) {
        this.clientNotes;
        this.setSelectedVideoDetails(qNotesRes);
      }
    });
  }

  createNotesAndClip() {

    // info: update clip
    if(this.editSeletedNotes){
      if (this.editSeletedNotes.clip == true){
        let body ={
          "clip_path": this.editSeletedNotes.test_video_clip_name,
          "clip": {
              "start": this.clientNotes.value.start,
              "end": this.clientNotes.value.end,
              "notes": this.clientNotes.value.text
          }
        }
        this._testedTestServices
        .updateClip(body)
        .subscribe((markerRes) => {
          if (markerRes.status == 200) {
            this.emitValuetoVideoInfo.emit('update');
            this._modelBoxService.notesAndClipModelMsg(this.clientNotes.value.clip, 'are updated');
          } else {
            this._modelBoxService.showErrorToster('Somthing went wrong');
          }
        });
        return;
      }
    }


    this.timeErrorMessage = "";
    this.submitted = true;
    if (this.clientNotes.invalid) {
      return;
    }
    this.submitted = false;
    this.setSelectedVideoDetails();
    this.clientNotes.patchValue({
      start:
        this.clientNotes.value.start.substring(0, 2) +
        ':' +
        this.clientNotes.value.start.substring(2, 4),
    });

    this.clientNotes.patchValue({time: this.currentTime});
    var start =
      this.clientNotes.value.start.substring(0, 2) +
      ':' +
      this.clientNotes.value.start.substring(2, 4);
    var pieces = start.split(':');
    let result = Number(pieces[0]) * 60 + Number(pieces[1]);
    let result2;
    let start1: any = result.toFixed(3);

    if (this.clientNotes.value.end !== null) {
      var end =
        this.clientNotes.value.end.substring(0, 2) +
        ':' +
        this.clientNotes.value.end.substring(2, 4);

        console.log('🏀🏀🏀', this.clientNotes.value.end);
        
        this.clientNotes.patchValue({end:end})

      var pieces1 = end.split(':');

      result2 = Number(pieces1[0]) * 60 + Number(pieces1[1]);
      let end1 = result2.toFixed(3);
      this.duration = end1 - start1;
    }

    this.clientNotes.patchValue({duration: this.duration});
    this.clientNotes.patchValue({
      time: this._testedTestServices.player.currentTime(),
    });

    let videoDuration = parseInt(this._testedTestServices.player.duration());
    if (
      this.clientNotes.controls.clip.value &&
      !this.clientNotes.controls.end.value
    ) {
      this.timeErrorMessage = 'End Time is required.';
    } else if (this.clientNotes.value.start.substring(2, 4) > 59) {
      this.timeErrorMessage = 'You have entered an invalid start time. ';
    } else if (this.clientNotes.value.end.substring(2, 4) > 59) {
      this.timeErrorMessage = 'You have entered an invalid end time. ';
    } else if (result >= videoDuration) {
      this.timeErrorMessage =
        'Start Time can not be greater then or equal to video length.';
    } else if (this.clientNotes.controls.clip.value) {
      if (result2 <= result) {
        this.timeErrorMessage =
          'End Time can not be less then or equal to start time';
      } else if (result2 > videoDuration) {
        this.timeErrorMessage =
          'End Time can not be greater then video length.';
      }
    }
    if (!this.timeErrorMessage) {
      if (this.editSeletedNotes) {
       
        // info: update notes
        this._testedTestServices
          .updateMarker(this.editSeletedNotes.id, this.clientNotes.value)
          .subscribe((markerUpdateRes) => {
            this.emitValuetoVideoInfo.emit('update');
            this._modelBoxService.notesAndClipModelMsg(this.editSeletedNotes.clip, 'are updated')
          });

          
      } else {

        // info: create note and clip
        console.log(' on create Notes And Clip function');
        this.onSubmitAddNotes();
      }
    }




  }

  closeEditBox() {
    this.emitValuetoVideoInfo.emit('cancel');
  }

  setSelectedVideoDetails(selectQucikNote?) {

    if (selectQucikNote) {
      const {currentTime, text} = selectQucikNote;
      this.clientNotes.patchValue({
        test: this.seletedDetailsObject.testId,
        tested_test: JSON.parse(localStorage.getItem('testedTestId')),
        start: currentTime ? currentTime : '',
        text: text ? text : ''
      });
      console.log('on set seleted video detials function');
      // this.onSubmitAddNotes();
    } else {
      this.clientNotes.patchValue({
        test: this.seletedDetailsObject.testId,
        tested_test: JSON.parse(localStorage.getItem('testedTestId'))
      });
    }

  }

  onSubmitAddNotes() {
    console.log('on submit add notes function ');
    console.log('checkbox value on submit', this.makeClipCheckbox);
    if(this.makeClipCheckbox == true){
      let body ={
        "video_url": this.selectedVideoDetailsVar.file,
        "clip": {
            "start": this.clientNotes.value.start,
            "end": this.clientNotes.value.end,
            "notes": this.clientNotes.value.text
        }
      }
      console.log('******',this.clientNotes.value);
      this._testedTestServices
      .addClip(body)
      .subscribe((markerRes) => {
        if (markerRes.status == 200) {
          if (this.clientNotes.value.clip == false) {
            this.clientNotes.get('start').reset();
            this.clientNotes.get('text').reset();
          } else {
            this.clientNotes.reset();
          }
          this.emitValuetoVideoInfo.emit('create');
          this._modelBoxService.notesAndClipModelMsg(this.clientNotes.value.clip, 'are created');
        } else {
          this._modelBoxService.showErrorToster('Somthing went wrong');
        }

      });
    } else {
      this._testedTestServices
      .addMarker(this.clientNotes.value)
      .subscribe((markerRes) => {
        if (markerRes.status == 200) {
          if (this.clientNotes.value.clip == false) {
            this.clientNotes.get('start').reset();
            this.clientNotes.get('text').reset();
          } else {
            this.clientNotes.reset();
          }
          this.emitValuetoVideoInfo.emit('create');
          this._modelBoxService.notesAndClipModelMsg(this.clientNotes.value.clip, 'are created');
        } else {
          this._modelBoxService.showErrorToster('Somthing went wrong');
        }

      });
    }
  }

  ngOnDestroy() {
    this.quickNotesSubcription.unsubscribe();
  }
}
