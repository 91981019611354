import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  tab: any = 'tab1';
  tab1: any;
  account: boolean;
  billing: boolean;
  buttons = [
    {link: 'accountDetails', text: 'Account Details', isClicked: false},
    {link: 'creditPlan', text: 'Credits & Plans', isClicked: false},
    {link: 'billing', text: 'Billing', isClicked: false},
    {link: 'invoices', text: 'Invoices', isClicked: false}
  ];

  constructor() {
  }

  ngOnInit() {
  }

}
