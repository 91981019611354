import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DashboardClientService} from 'src/app/shared/services/dashboard-client.service';
import {Subscription} from 'rxjs';
import {TesterTestedListService} from 'src/app/shared/services/tester-tested-list.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  userName: any;
  error1: ArrayBuffer;
  data: any;
  testerDashboard: boolean = false;
  testerName: string;
  availableTest: any;
  testerGetData: Subscription;
  currentAvailableTest: any;
  public href: string = "";
  url: string = 'asdf';
  testedvideoShow: boolean = true;
  isVideoAvilableText: string;
  btnText: string;

  constructor(
    private _dashboardClient: DashboardClientService,
    private _router: Router,
    private _testedTestServices: TesterTestedListService,
  ) {
  }

  ngOnInit() {
    // this.getAvailableTest();
    this.getTestedVideoList();
    this.href = this._router.url;

    // this is Storre Login Api Response Usernemae in localstorage

    this._dashboardClient.apiData$.subscribe((data) => {
      if (data.usertype == 'client') {
        this.testerName = localStorage.getItem('data');
      }
      this.userName = localStorage.getItem('data');

    });

    var userType = localStorage.getItem('usertype');
    if (userType == 'client') {
      this.testerDashboard = false;
      this.testerName = localStorage.getItem('data');
    } else {
      this.testerName = localStorage.getItem('data');
      this.testerDashboard = true;
    }
  }

  // get video list start
  getTestedVideoList() {

    this._testedTestServices

      .getTestedTestVideoList()
      .subscribe((testedListRes) => {

        if (testedListRes.data.video_list.length > 0) {
          this.testedvideoShow = true;
          this.isVideoAvilableText = 'See newest videos and start optimizing';
          this.btnText = 'View videos';
        } else {
          this.testedvideoShow = false;
          this.isVideoAvilableText = 'Watch example videos to see how it works';
          this.btnText = 'View example videos';
        }
      });
  }


  viewTemplate(){
    this._router.navigate(['/research/tests/create-test'], {queryParams: {'show-templates': true}});
  }

  ngOnDestroy() {
    // this.testerGetData.unsubscribe()
  }
}
