import { Injectable } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { TesterTestedListService } from 'src/app/shared/services/tester-tested-list.service';

@Injectable({
  providedIn: 'root'
})

export class GetSelectedVideoDetialsService {

  selectedVideoDetails: any;

  constructor(
    private _testedTestServices: TesterTestedListService,
  ) {}

  public getSelectedVideoDetials(i) {

    // get selected video detials
    this._testedTestServices.getTestedTestVideoList().subscribe(res => {
      let tempVideoDetials = res.data.video_list
      this.selectedVideoDetails = tempVideoDetials.find(o => o.id == i);
    })
    
    return this.selectedVideoDetails;
  }

}
