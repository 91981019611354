import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SearchBoxInputService {
  
  public componentType$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public searchBoxValue$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor() { }

  sendSearchBoxValue(componentType: string, searchBoxValue: string){
    console.log("file: search-box-input.service.ts:16  componentType", componentType)
    this.componentType$.next(componentType);
    this.searchBoxValue$.next(searchBoxValue);
  }

  // dismissPopup(){
  //   this.showPopup$.next(false);
  // }

}
