
import { Component, OnInit, NgModule, OnDestroy } from '@angular/core';
import {NavigationEnd, ActivatedRoute, Router, Params} from '@angular/router';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { HighlightReelApiService } from 'src/app/shared/services/highlight-reel-api.service';
import { HighlightReelUpdateService } from 'src/app/shared/services/highlight-reel-update.service';
import { environment } from 'src/environments/environment';
import { TesterTestedListService } from 'src/app/shared/services/tester-tested-list.service';
import { appApiResources } from 'src/app/shared/services/api.constant';

// import { HighlightReelsService } from 'src/app/shared/services/highlight-reels.service';
// import { HighlightReelUpdateService } from '../services/highlight-reel-update.service';

@Component({
  selector: 'app-create-highlight-reel',
  templateUrl: './create-highlight-reel.component.html',
  styleUrls: ['./create-highlight-reel.component.scss']
})
export class CreateHighlightReelComponent implements OnInit, OnDestroy {


  constructor(public _router: Router,
              private _highlightReelsServices: HighlightReelApiService,
              private _highlightReelUpdateService: HighlightReelUpdateService,
              private _activatedRoute: ActivatedRoute,
              private _testedTestServices: TesterTestedListService,
              ) {}

  reelHeading: string;
  tempReelHeading: string;
  reelSavedDate: string;
  reelTotalDuration: string = "00:00";
  popupDisplayProperty: string;
  bodyJson: any;
  baseUrl: any = environment.apiUrl; 
  tempClipsArray = [];
  clipsList: any = [];
  unfilteredData: any = [];
  filteredData: any = [];
  quiredData: any = [];
  sortClips = [];
  updateClipsData = {};
  editReelunfilteredData = [];
  createdDate: string;
  routepath: string;
  showPopupMenu: string = "none";
  totalSortedClipsCount = 0;
  totalSortedClipsCountDisplay: any = "00:00";

  // new variables
  testIdAvailable: boolean = false;
  selectedProjectId: number;
  selectedTestId: number;
  saveButtonText: string = "Save Highlight reel";
  showCustomPopupBox: boolean = false;
  customPopupBoxContent: string = "";
  checkReelNameIsValid: boolean = false;
  displayTotalDuration: any = "00:00";
  clipsFilterValue: any = "";


  ngOnDestroy(): void {
    localStorage.removeItem("mytime");
  }

  ngOnInit() {
    // checking project is available
    this._activatedRoute.queryParams.subscribe((params) => {
      if(params['project-id'] != undefined && params['test-id'] != undefined){
        this.testIdAvailable = true;
        this.selectedProjectId = params['project-id'];
        this.selectedTestId = params['test-id'];
      }
      else if(params['project-id'] != undefined){
        this.testIdAvailable = false;
        this.selectedProjectId = params['project-id'];
      }
    });

    this._highlightReelUpdateService.reelTitle$.subscribe((value)=>{
      this.reelHeading = value;
    });

    this.onPageLoad(this._router.url);
    this.routepath = this._router.url;



let tag  = document.getElementById('dragitem1')
console.log("-0----", tag)
if(tag){
  tag.addEventListener('mousedown', function(event){

    tag.addEventListener('mousemove', function(e){
      // e.preventDefault()
      // return false
      tag.draggable=false
      let taginfo = e.target
      let tt = tag.getBoundingClientRect()
      console.log('event call ',taginfo, tt, taginfo)
    })
    console.log("8888888888888888", event)
  })
  tag.addEventListener('mouseup', function(ele){
    tag.removeAllListeners('mousemove')
    console.log("--------------", ele)
  })
}

  }

  onDragEnded(event) {
    console.log("-------------------", event, event.event.target)
    // event.event.target.style.transform = 'none';

    // event.source._dragRef.reset();
}
  // Execute on initial render
  onPageLoad(route: any){
    if (/\/results\/highlight-reels\/create.*/i.test(this._router.url)){
      this.createdDate = "Draft";
      this.saveButtonText = "Save Highlight reel";

      if(this.testIdAvailable == true){
        this.viewProjectAndTestRelatedData( this.selectedProjectId, this.selectedTestId );
      } else {
        this.viewProjectRelatedData(this.selectedProjectId);
      }
      
    } 
    else if(/\/results\/highlight-reels\/edit.*/i.test(this._router.url)){
      this.saveButtonText = "Update Highlight reel";
      this._highlightReelUpdateService.currentData.subscribe((newData)=>{
        if(Object.keys(newData).length <= 0){
          this.updateClipsData = JSON.parse(localStorage.getItem("selectedEditReelData"));
          this.getReelsListData();
        } else{
          this.updateClipsData = newData;
          localStorage.setItem("selectedEditReelData", JSON.stringify(newData));
          this.getReelsListData();
        }
      });
    }
  }

  getReelsListData(){
    this._highlightReelsServices.getHighlightReelsWithReelId(this.updateClipsData['id']).subscribe((res: any) => {
      this.sortClips = res.data.clip_video_list;
      this.editReelunfilteredData = res.data.clip_video_list;
      this.reelHeading = this.updateClipsData["highlight_reel_name"];
      this.tempReelHeading = this.updateClipsData["highlight_reel_name"];

      if(this.testIdAvailable == true){
        this.viewProjectAndTestRelatedData( this.selectedProjectId, this.selectedTestId );
      } else {
        this.viewProjectRelatedData(this.selectedProjectId);
      }
    });
  }

  handleSaveButton(){
    if (/\/results\/highlight-reels\/create.*/i.test(this._router.url)){
      this.createHighlightReel();
    }
    else if(/\/results\/highlight-reels\/edit.*/i.test(this._router.url)){
      this.updateHighlightReel();
    }
  }

  createHighlightReel(){
    let tempJsonData;

    if(this.testIdAvailable == true){
      tempJsonData = {
        "highlight_reel_name": this.reelHeading,
        "project_id": this.selectedProjectId,
        "test_id": this.selectedTestId,
        "draft": false,
      }
    } else {
      tempJsonData = {
        "highlight_reel_name": this.reelHeading,
        "project_id": this.selectedProjectId,
        "test_id": null,
        "draft": false,
      }
    }

    let tempClipsList = this.getSelectedReelsId(this.sortClips);
    tempJsonData["clip_list"] = tempClipsList;
    this._highlightReelsServices.newCreateHighlightReel(tempJsonData).subscribe((res) => {
      if(res.status == 200){
        if(this.testIdAvailable == true){
          this._router.navigate(['/results/highlight-reels'], {queryParams: {'project-id': this.selectedProjectId, 'test-id': this.selectedTestId}});
        } else {
          this._router.navigate(['/results/highlight-reels'], {queryParams: {'project-id': this.selectedProjectId}});
        }
      }
    });

  }

  saveReelAsDraft(){
    let tempJsonData;

    if(this.testIdAvailable == true){
      tempJsonData = {
        "highlight_reel_name": this.reelHeading,
        "project_id": this.selectedProjectId,
        "test_id": this.selectedTestId,
        "draft": true,
      }
    } else {
      tempJsonData = {
        "highlight_reel_name": this.reelHeading,
        "project_id": this.selectedProjectId,
        "test_id": null,
        "draft": true,
      }
    }

    let tempClipsList = this.getSelectedReelsId(this.sortClips);
    tempJsonData["clip_list"] = tempClipsList;
    this._highlightReelsServices.newCreateHighlightReel(tempJsonData).subscribe((res) => {
      if(res.status == 200){
        if(this.testIdAvailable == true){
          this._router.navigate(['/results/highlight-reels'], {queryParams: {'project-id': this.selectedProjectId, 'test-id': this.selectedTestId}});
        } else {
          this._router.navigate(['/results/highlight-reels'], {queryParams: {'project-id': this.selectedProjectId}});
        }
      }
    });

  }

  updateHighlightReel(){
    let tempJsonData;

    if(this.testIdAvailable == true){
      tempJsonData = {
        "highlight_reel_name": this.reelHeading,
        "project_id": this.selectedProjectId,
        "test_id": this.selectedTestId,
        "draft": false,
      }
    } else {
      tempJsonData = {
        "highlight_reel_name": this.reelHeading,
        "project_id": this.selectedProjectId,
        "test_id": null,
        "draft": false,
      }
    }

    let tempClipsList = this.getSelectedReelsId(this.sortClips);
    tempJsonData["highlight_reel_id"] = this.updateClipsData['id'];
    tempJsonData["clip_list"] = tempClipsList;
    this._highlightReelsServices.newUpdateHighlightReel(tempJsonData).subscribe((res) => {
      if(res.status == 200){
        if(this.testIdAvailable == true){
          this._router.navigate(['/results/highlight-reels'], {queryParams: {'project-id': this.selectedProjectId, 'test-id': this.selectedTestId}});
        } else {
          this._router.navigate(['/results/highlight-reels'], {queryParams: {'project-id': this.selectedProjectId}});
        }
      }
    });
    
  }

  getSelectedReelsId(selectedClipsList){
    let idList = [];

    selectedClipsList.forEach((item)=>{
    idList.push(item.testedtest_clip_id)
    })

    return idList;
  }

  viewProjectAndTestRelatedData(projectId, testId){
    this._testedTestServices.getClipsWithProjectIdAndTestId(projectId, testId).subscribe((res: any)=>{
      let tempClipList = res['data'];

      tempClipList.forEach((clipItem: any)=>{
        if(clipItem['clip_status'] == "COMPLETED"){
          this.clipsList.push(clipItem)
          this.unfilteredData.push(clipItem);
          this.filteredData.push(clipItem);
        }
      });
      this.sortClipsWithId();
      // this.clipsList = res['data'];
    });
  }

  viewProjectRelatedData(projectId){
    this._testedTestServices.getClipsWithProjectId(projectId).subscribe((res: any)=>{
      let tempClipList = res['data'];

      tempClipList.forEach((clipItem: any)=>{
        if(clipItem['clip_status'] == "COMPLETED"){
          this.clipsList.push(clipItem)
          this.unfilteredData.push(clipItem);
          this.filteredData.push(clipItem);
        }
      });

      // remove clips already exists in the selected reel
      let sortedArrayIdList = [];
      this.sortClips.forEach((item1)=>{
        sortedArrayIdList.push(item1.testedtest_clip_id);
      });
      this.clipsList = [];
      this.unfilteredData.forEach((item)=>{
        if(!sortedArrayIdList.includes(item.testedtest_clip_id)){
          this.clipsList.push(item);
        }
      });
      this.sortClipsWithId();
    });
  }
  

  handleDiscardBtn(){
    if(this.testIdAvailable == true){
      this._router.navigate(['/results/highlight-reels'], {queryParams: {'project-id': this.selectedProjectId, 'test-id': this.selectedTestId}});
    } else {
      this._router.navigate(['/results/highlight-reels'], {queryParams: {'project-id': this.selectedProjectId}});
    }
  }

  reelNameValidation(value){
    if(value != ""){
      if(value.indexOf(' ') >= 0){
        // space not present
        this.checkReelNameIsValid = true;
      } else{
         // space present
        this.checkReelNameIsValid = false;
      }
    }else{
      this.checkReelNameIsValid = true;
    }
  }

  closePopup(){
    document.getElementById("c-h-reel-popup-container").style.display = "none";
  }
  
  openPopup(){
    // document.getElementById("c-h-reel-popup-container").style.display = "block";
    this.tempReelHeading = this.reelHeading;
    this.showCustomPopupBox = !this.showCustomPopupBox; 
    this.customPopupBoxContent = 'reel-rename';
  }

  updateHeading(){
    this.reelHeading = this.tempReelHeading;
    this.showCustomPopupBox = false;
    this.customPopupBoxContent = "";
    // document.getElementById("c-h-reel-popup-container").style.display = "none";
  }

  createJsonBody(){
    this.bodyJson ={
      "clip_video_list" : this.sortClips,
      "highlight_reel_name" : this.reelHeading
    }
    // console.log(this.bodyJson["clip_video_list"].length);
  }

  updateJsonBody(){
    this.bodyJson ={
      "clip_video_list" : this.sortClips,
      "highlight_reel_name" : this.reelHeading,
      "created_date": this.updateClipsData["created_date"],
      "uuid": this.updateClipsData["uuid"]
    }
    // console.log(this.bodyJson["clip_video_list"].length);
  }



  handleSaveHightlightReel(){

    if(this._router.url == '/create-highlight-reel'){
      
      // extract data from sortclips array and prepare for post Api.
      this.createJsonBody()

      //  Validate Json Body | if there is no video selected, block save highlight reel
      if(this.bodyJson["clip_video_list"].length !== 0){

        // SEND POST API | Create Reel
        this._highlightReelsServices.createHighlightReel(this.bodyJson).subscribe((res) => {
        
          if(res.status == "200"){

            // redirect to highlight reel page
            this._router.navigate(["/highlight-reels-dashboard"]);
            // console.log("success");

          } else {

            // view error message on popup and close after 1 secend. 
            // console.log("Error reel not saved !", res);

          }

        });

      } else {
        // console.log("Please add some clips to create")
      }

    } else if (this._router.url == '/edit-highlight-reel'){
      
      // extract data from sortclips array and prepare for put Api.
      this.updateJsonBody()

      
      //  Validate Json Body | if there is no video selected, block save highlight reel
      if(this.bodyJson["clip_video_list"].length !== 0){

        // Send PUT Request | Update reel
        this._highlightReelsServices.updateHighlightReel(this.bodyJson).subscribe((res) => {
          
          if(res.status == "200" || res.status == "201"){

            this._router.navigate(["/highlight-reels-dashboard"]);

          } else{

            // view error message on popup and close after 1 secend. 
            // console.log("Error reel not saved !");

          }

        });

      } else {
        // console.log("please add some clips to update")
      }

    }

  }

  

  dragFunction(event){
    console.log('-----------------------')
    console.log(event.clientX)
    console.log('-----------------------')
  }

  // Draggable component
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
    this.calculateSeletedClipTotalDuration();
  }

  showPopupMenuFun(event){

    let tempElement = event.target.parentElement.parentElement.children[1].style.display;
    // console.log(tempElement)
    if(tempElement == "none"){

      // close previous opened popup menu
      let allElements = document.getElementsByClassName("reel-menu-option-container");
      for (let i = 0; i < allElements.length; i++) {
          allElements.item(i).setAttribute("style", "display: none");
      }

      // open popup menu on clicked card
      event.target.parentElement.parentElement.children[1].style.display = "block";
      // console.log("opening now.....")

    } else{

      // close popup menu after clicking again
      event.target.parentElement.parentElement.children[1].style.display = "none";
      // console.log("trying to close .....")
    }

  }

  showContextMenu(event){
    // let tempElement = event.target.parentElement.children[1];
    let tempElement = undefined;
    if(event.target.className == "dash-context-menu-wrapper"){
      tempElement = event.target.children[1];
    } else if(event.target.className == "dash-context-menu-icon"){
      tempElement = event.target.parentElement.children[1];
    }

    if(tempElement.style.display == "none"){
      // close previous opened popup menu
      let allElements = document.getElementsByClassName("dash-context-menu-cont");
      for (let i = 0; i < allElements.length; i++) {
          allElements.item(i).setAttribute("style", "display: none");
      }
      // open popup menu on clicked card
      tempElement.style.display = "block";
    } else{
      // close popup menu after clicking again
      tempElement.style.display = "none";
    }
  }


  removeClipFromList(index, item){
    this.sortClips.splice(index, 1);
    this.clipsList.unshift(item);
    this.calculateSeletedClipTotalDuration();
  }

  addClipToList(index, item){
    this.sortClips.unshift(item);
    this.clipsList.splice(index, 1);
    this.calculateSeletedClipTotalDuration();
  }

  calculateSeletedClipTotalDuration(){
    this.totalSortedClipsCount = 0;
    this.sortClips.forEach((item)=>{
      let timeArray = item.duration.split(':');
      let minute = Number(timeArray[0])
      let seconds = Number(timeArray[1])
      // minute calculate
      this.totalSortedClipsCount = this.totalSortedClipsCount + (minute * 60) ;
      // seconds calculate
      this.totalSortedClipsCount = this.totalSortedClipsCount + seconds;
    })
    this.convertSecondsToMinute();
  }

  convertSecondsToMinute(){
      let durationMinutes = Math.floor(this.totalSortedClipsCount / 60).toString();
      let durationSeconds = Math.floor(this.totalSortedClipsCount % 60).toString();
      let durationMinutesFormatted = durationMinutes.length > 1 ? durationMinutes : `0${durationMinutes}`;
      let durationSecondsFormatted = durationSeconds.length > 1 ? durationSeconds : `0${durationSeconds}`;
      this.displayTotalDuration = `${durationMinutesFormatted}:${durationSecondsFormatted}`;
  }

  downloadClip(clip_path){
    window.open(`${this.baseUrl}${clip_path}`, "_self");
  }

  handleClipsSearch(query){
    this.quiredData = [];
    let sortedArrayIdList = [];
    this.sortClips.forEach((item1)=>{
      sortedArrayIdList.push(item1.testedtest_clip_id);
    });

    this.unfilteredData.forEach((clipData)=>{
      if(clipData.tester_name.toLowerCase().includes(query.toLowerCase())){
        if(!sortedArrayIdList.includes(clipData.testedtest_clip_id)){
          this.quiredData.push(clipData);
        }
      }
      else if(clipData.testedtest_clip_id.toString().toLowerCase().includes(query.toString().toLowerCase())){
        if(!sortedArrayIdList.includes(clipData.testedtest_clip_id)){
          this.quiredData.push(clipData);
        }
      }
    });
    this.clipsList = [];
    this.clipsList = this.quiredData;

    if(query == ""){
      this.clipsList = [];
      this.unfilteredData.forEach((item)=>{
        if(!sortedArrayIdList.includes(item.testedtest_clip_id)){
          this.clipsList.push(item);
        }
      });
    }
    this.sortClipsWithId();
  }

  sortClipsWithId(){
    let tempArray = this.clipsList;
    this.clipsList = [];
    tempArray.sort(
      function(a, b){
        let a1 = Number(a.testedtest_clip_id);
        let b1 = Number(b.testedtest_clip_id);
        return a1 - b1;
      });
      tempArray.reverse();
      this.clipsList = tempArray;
  }



}
