<nav class="up-header">
  <a routerLink='/login'>
    <img src="assets/images/logo-color.svg" alt="logo">
  </a>
</nav>


<!-- <div class='container-fluid px-0 lg-screen-width'>
  <header class='top-header login-header'>
    <nav class='navbar navbar-expand-lg navbar-light primary-color'>
      <a class='navbar-brand'><img alt='logo' src='assets/images/logo.png'/></a>
      <div class='ml-auto signup-login'>
        Have an account?
        <a routerLink='/login' routerLinkActive='active'>Login</a>
      </div>
    </nav>
  </header>
</div> -->

<div *ngIf='!isCompleteProfileStep' class='login-container lg-screen-width up-signup-container'>
  <section class='section up-signup-content'>
    <div class='login-form-container'>
      <h1 class='signup title'>Sign up here!</h1>
      <p class="desc">Get started today.</p>
      <div class='text-danger'>
        <small>{{ errMsg }}</small>
      </div>

      <form #regForm1='ngForm' (ngSubmit)='onSignUp()' [formGroup]='regForm' class="up-signup-form">


        <label class='username'>
          <span class='name'>Username</span>
          <input id="username" formControlName='username' type='text'/>
        </label>
        
        <div *ngIf=" (submitted && regForm.controls.username.errors) || regForm.get('username').touched">
          <div *ngIf='regForm.controls.username.errors?.required'>
            <small class='text-danger'>Username is required.</small>
          </div>
        </div>
        
        <div *ngIf="usernameExistsStatus">
          <small class='text-danger'>Username already exists. Please try another one.</small>
        </div>


        <label class='email'>
          <span class='name'>Personal business email</span>
          <input  formControlName='email' type='email'/>
        </label>
        <ng-container *ngIf="gmailKeywordCheck">
          <small style="font-size: 12px; color: #D50000;"> Check spelling of @gmail.com </small>
        </ng-container>
        <div *ngIf="emailExistsStatus">
          <small class='text-danger'>Email already exists. Please try another one.</small>
        </div>
        <div *ngIf="personalEmailCheck">
          <small class='text-danger'>Please use your personal business email!
            <br/>
            <a target="_blank" *ngIf="baseUrl == 'https://stagapi.userpeek.io/' " href="https://stagtester.userpeek.io/register">As a tester sign up here</a>
            <a target="_blank" *ngIf="baseUrl == 'https://api1.userpeek.io/' " href="https://tester.userpeek.io/register">As a tester sign up here</a>
          </small>
        </div>

        <div *ngIf="
            (submitted && regForm.controls.email.errors) ||
            regForm.get('email').touched
          " class='invalidfeedback'>
          <div *ngIf='
              regForm.controls.email.errors?.required ||
              regForm.controls.email.errors?.email
            '>
            <small class='text-danger'> Must be a valid email address</small>
          </div>
        </div>

        <label class='password password-eye'>
          <div class="password-label-container">
            <span class='name'>Password</span>
            <div class="show-password" *ngIf="!fieldTextType" (click)="toggleFieldTextType()">
              <img src="assets/images/icons/eye-open.svg" alt="eye open icon"> <span>Show</span>
            </div>
            <div class="show-password" *ngIf="fieldTextType" (click)="toggleFieldTextType()">
              <img src="assets/images/icons/eye-close.svg" alt="eye close icon"> <span>Hide</span>
            </div>
          </div>
          <input [type]="fieldTextType ? 'text' : 'password'" formControlName='password'/>
          <!-- <div class='input-group-append'>
            <span class='input-group-text'>
              <i (click)='toggleFieldTextType()' [ngClass]="{
                  'fa-eye-slash': !fieldTextType,
                  'fa-eye': fieldTextType
                }" class='fa' style='margin-right: 18px;'></i>
            </span>
          </div> -->
          <app-password-strength (passwordStrength)='passwordValid($event)' [passwordToCheck]='regForm.value.password'>
          </app-password-strength>
          <span>
            <div *ngIf="
                submitted ||
                (regForm.get('password').invalid &&
                  (regForm.get('password').touched ||
                    regForm.get('password').dirty))
              " class='errors'>
              <div *ngIf="regForm.get('password').hasError('required')">
                <small class='text-danger'> Password is required </small>
              </div>

              <div *ngIf="regForm.get('password').errors?.minlength">
                <small class='text-danger'>
                  Password must contain at least 8 characters
                </small>
              </div>

              <div *ngIf="regForm.get('password').hasError('passwordStrength')" class='error-text'>
                <small class='text-danger'>
                  {{ regForm.get('password').errors['passwordStrength'] }}
                </small>
              </div>
            </div>
          </span>
        </label>

        <p class='registration policy registration-policy-checkbox'>
          <span class='policy_checkmark'>
            <span class='custom-checkbox' >
              <input formControlName='checkMarks' type='checkbox'/>
              <span>
              </span>
            </span>
          </span>
              By clicking, I agree to Userpeek's
          <a href='https://userpeek.com/privacy-policy/' target='_blank'>Privacy Policy</a>
          and
          <a href='https://userpeek.com/terms-of-service/' target='_blank'>Terms and Conditions</a>
        </p>

        <button class='up-signup-btn btn-sty-blue' type='submit'  [ngClass]="[regForm.valid && !personalEmailCheck && !emailExistsStatus ? 'up-signup-btn-active' : 'up-signup-btn-inactive']">
          Next
        </button>

        <div *ngIf="
            submitted ||
            (regForm.get('checkMarks').invalid &&
              (regForm.get('checkMarks').touched ||
                regForm.get('checkMarks').dirty))
          " class='invalidfeedback'>
          <div *ngIf="
              regForm.controls.checkMarks.errors?.required ||
              regForm.controls.checkMarks.errors?.checkMarks
            ">
            <small class='text-danger'> Please accept the Privacy Policy</small>
          </div>
        </div>

      </form>

      <p class="login-redirect">
        Already have an account? <a routerLink='/login'>Log in</a>
      </p>
    </div>

    <!-- <button class='feedback-btn'>Feedback</button> -->
  </section>
</div>
<app-complete-profile-section *ngIf='isCompleteProfileStep' [getRegisterSecondStepData]='regForm.value'>
</app-complete-profile-section>

<div style="background-color: var(--light-grey); height: calc(100vh - 670px); margin-top: 70px;"   class="supported-by-container">
  <p style="padding-top: 40px; margin-left: 80px; display: flex; align-items: center;" class="copyright">
    <img src="assets/images/icons/copyright.svg" alt="copyright"> 
    <a style="color: #000; font-family: var(--font-regular); font-size: 20px;" target="_blank" href="https://userpeek.com">UserPeek</a>
   </p>
</div>
