import { Component, OnInit, OnDestroy } from '@angular/core';
import { CreateNewTestService } from 'src/app/shared/services/create-new-test.service';
import { CustomPopupBoxService } from 'src/app/shared/services/custom-popup-box.service';
import { TesterTestedListService } from 'src/app/shared/services/tester-tested-list.service';
import { environment } from 'src/environments/environment';
import { UpStoreSelectedVidDetialsService } from 'src/app/shared/services/up-store-selected-vid-detials.service';
import {NavigationEnd, ActivatedRoute, Router, Params} from '@angular/router';
import { SearchBoxInputService } from 'src/app/shared/services/search-box-input.service';
import { AllFiltersShareQueryService } from 'src/app/shared/services/all-filters-share-query.service';

@Component({
  selector: 'app-videos-dashboard-new',
  templateUrl: './videos-dashboard-new.component.html',
  styleUrls: ['./videos-dashboard-new.component.scss']
})
export class VideosDashboardNewComponent implements OnInit, OnDestroy {

  filteredJsonData = [];
  unfilteredJsonData = [];
  queriedJsonData = [];

  paginateCount: number = 0;
  paginationDisplayedCount: number = 0; // dummy
  baseUrl = environment.apiUrl;
  buttonFilterData: any ={
    all: 0,
    newVideos: 0,
  };
  isDataAvailable:boolean = true;
  selectedChipFilter: string = "all";
  allFilterQueryDataObservable: any;

  constructor(
    private _createnewTestServices: CreateNewTestService,
    public _customPopupBoxService: CustomPopupBoxService,
    private _testedTestServices: TesterTestedListService,
    private _upStoreSelectedVidDetialsService: UpStoreSelectedVidDetialsService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _searchBoxInputService: SearchBoxInputService,
    private _allFiltersShareQueryService: AllFiltersShareQueryService,
  ) {
      this.allFilterQueryDataObservable = this._allFiltersShareQueryService.componentFilterQueryData.subscribe((res)=>{
        if(res.requestType != "none"){
          // console.log('res type is not none', res.requestType);
          if(res.component == this._router.url.split('?')[0]){
            this.processComponentFilterData(res);
          }
        }
      });
    }

  ngOnDestroy(): void {
    this.allFilterQueryDataObservable.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeComponentData();
    // this._allFiltersShareQueryService.componentFilterQueryData.subscribe((res)=>{
    //   console.log('video dashbaord >> rxjs subscribe call >> 1 time >> res value :: ', res);
    //   if(res.requestType != "none"){
    //     // console.log('res type is not none', res.requestType);
    //     if(res.component == this._router.url.split('?')[0]){
    //       this.processComponentFilterData(res);
    //     }
    //   }
    // })
    // search box filter logic
    this._searchBoxInputService.componentType$.subscribe((value) => {
      if(/\/results\/videos.*/i.test(value) ){
        this._searchBoxInputService.searchBoxValue$.subscribe((data) => {
          this.innokeSearchBoxFilter(data);
        });
      }
    });
  }

  initializeComponentData(){
    this._activatedRoute.queryParams.subscribe((params) => {
      if(params['project-id'] != undefined && params['test-id'] != undefined){
        this.viewProjectAndTestRelatedData(params['project-id'], params['test-id']);
      }
      else if(params['project-id'] != undefined){
        this.viewProjectRelatedVideos(params['project-id']);
      }
      else {        
        this._router.navigate(['/project-selector']);
      }
    });
  }

  processComponentFilterData(value){
    if(value.requestType == "getDataFromParent"){
      this._allFiltersShareQueryService.sendDataParentToChild(this.unfilteredJsonData);
    }
    else if(value.requestType == "dataChildToParent"){
      let tempClipList = [] 
      this._allFiltersShareQueryService.childToParentDataVariable.subscribe((res)=>{
        tempClipList = res;
      });
      this.queriedJsonData = tempClipList;
      console.log('aaaaaaaaaaaaaaaaaaaa')
      console.log(this.queriedJsonData )
      console.log('aaaaaaaaaaaaaaaaaa')
      this.initializePaginationFun();
    }
    else if(value.requestType == "loadOriginalData"){
      // console.log('loadOriginalData working');
      this.initializeComponentData();
    }
  }

  viewProjectRelatedVideos(projectId){
    this._testedTestServices.getVideosWithProjectId(projectId).subscribe((res: any)=>{

      this.isDataAvailableFun(res.data.video_list.length);
      

      this.unfilteredJsonData = [];
      this.queriedJsonData = [];
      res.data.video_list.forEach(item => {
        this.unfilteredJsonData.push(item);
        this.queriedJsonData.push(item);
      });

      this.validatePaginateCount();
      
      // sort with id
      this.queriedJsonData.sort(function(a: any, b: any){
        return a.id - b.id;
      })
      // decending/reverse order
      this.queriedJsonData.sort().reverse();
      
      // initially show only 10 items
      this.filteredJsonData = [];
      for(let i = 0; i < this.paginateCount; i++){
        this.filteredJsonData.push(this.queriedJsonData[i])
      }

    });

    this.ChipsFiltersInitialize();
  }

  viewProjectAndTestRelatedData(projectId, testId){
    this._testedTestServices.getVideosWithProjectIdAndTestId(projectId, testId).subscribe((res: any)=>{

      this.isDataAvailableFun(res.data.video_list.length);

      this.unfilteredJsonData = [];
      this.queriedJsonData = [];
      res.data.video_list.forEach(item => {
        this.unfilteredJsonData.push(item);
        this.queriedJsonData.push(item);
      });

      this.validatePaginateCount();
      
      // sort with id
      this.queriedJsonData.sort(function(a: any, b: any){
        return a.id - b.id;
      })
      // decending/reverse order
      this.queriedJsonData.sort().reverse();

      // initially show only 10 items
      this.filteredJsonData = [];
      for(let i = 0; i < this.paginateCount; i++){
        this.filteredJsonData.push(this.queriedJsonData[i])
      }

    });


    
    this.ChipsFiltersInitialize();
  }


  ChipsFiltersInitialize(){
    // for future use
  }


  isDataAvailableFun(value){
    // if json data is empty show empty message
    console.log(" console 21 dataavilable value", value);
    if(value > 0){
      this.isDataAvailable = true;
    } else {
      this.isDataAvailable = false;
    }
  }

  validatePaginateCount(){
    if(this.queriedJsonData.length < 10){
      this.paginateCount = this.queriedJsonData.length;
    } else {
      this.paginateCount = 10;
    }
  }



  incrementPaginationCountFun(value){

    let actualQueriedDataLength = this.queriedJsonData.length;

    if(value < actualQueriedDataLength){
      this.paginateCount = value;
    } else {
      this.paginateCount = actualQueriedDataLength;
    }

    this.restrictDataCount();
  }

  applyButtonFilterFun(value){
    if(value === "all"){
      // if filter applied is "all-videos"
      this.queriedJsonData = [];
      this.selectedChipFilter = "all";
      this.unfilteredJsonData.forEach((item)=>{
          this.queriedJsonData.push(item);
      })
    }
    else if(value === "newVideos"){
      // if filter applied is "new videos"
      this.queriedJsonData = [];
      this.selectedChipFilter = "newVideos";
      this.unfilteredJsonData.forEach((item)=>{
        if(item.project_status == 9){
          this.queriedJsonData.push(item);
        }
      })
    } 
    
    this.initializePaginationFun();
    this.isDataAvailableFun(this.queriedJsonData.length)
  }


  initializePaginationFun(){
    this.validatePaginateCount();
    this.filteredJsonData = [];
    for(let i = 0; i < this.paginateCount; i++){
      this.filteredJsonData.push(this.queriedJsonData[i])
    }
  }


  restrictDataCount(){
    this.filteredJsonData = [];
    for(let i = 0; i < this.paginateCount; i++){
      this.filteredJsonData.push(this.queriedJsonData[i])
    }
  }


  showContextMenu(event){
    let tempElement = event.target.parentElement.children[1];
    if(tempElement.style.display == "none"){
      // close previous opened popup menu
      let allElements = document.getElementsByClassName("dash-context-menu-cont");
      for (let i = 0; i < allElements.length; i++) {
          allElements.item(i).setAttribute("style", "display: none");
      }
      // open popup menu on clicked card
      tempElement.style.display = "block";
    }
    else{
      // close popup menu after clicking again
      tempElement.style.display = "none";
    }
  }


  viewSelectedVideo(videoDetials){
    console.log('11111111111111111111111111111111111111111111111111111111111111')
    this._upStoreSelectedVidDetialsService.setValue(videoDetials);
    localStorage.setItem('cacheSelectedVideoDetials', JSON.stringify(videoDetials));
    console.log('videoDetials', videoDetials)
    this._activatedRoute.queryParams.subscribe((params) => {
      if(params['project-id'] != undefined && params['test-id'] != undefined){
        this._router.navigateByUrl("/results/videos/view-video");
      }
      else if(params['project-id'] != undefined){
        localStorage.setItem('selectedTestId', "");
        this._router.navigateByUrl("/results/videos/view-video");
      } 
    });
    console.log('11111111111111111111111111111111111111111111111111111111111111')
  }

  hashtagLimit(item){
    let newArray = [];
    let oldArray = item;
    let charCount = 0;
    for(let i = 0; i <= oldArray.length -1; i++){
      let char = oldArray[i];
      charCount = charCount + char.length;
      if(charCount <= 15){
        newArray.unshift(oldArray[i]);
      }
      else{
        break;
      }
    }
    return newArray;
  }

  hashtagRemainingCount(item){
    let newArray = [];
    let oldArray = item;
    let charCount = 0;
    for(let i = 0; i <= oldArray.length -1; i++){
      let char = oldArray[i];
      charCount = charCount + char.length;
      if(charCount <= 15){
        newArray.unshift(oldArray[i]);
      } 
      else{
        break;
      }
    }
    let result = oldArray.length - newArray.length;
    return result;
  }

  showHashtagPopup(event){
    let tempElement = event.target.parentElement.lastChild;
    tempElement.style.display = "block";
  }

  closeHashtagPopup(event){
    let tempElement = event.target.parentElement.parentElement;
    console.log("event.target.parentElement.parentElement.className", event.target.parentElement.parentElement.className)
    tempElement.style.display = "none";
  }

  sendHashtagToAllFilterComponent(value, filterType){
    console.log("sendHashtagToAllFilterComponent  filteredJsonData final json data",this.filteredJsonData );
    console.log("sendHashtagToAllFilterComponent  hastag value console 1", value, "type",filterType);
    
    
    this._allFiltersShareQueryService.sendHashTagValue(value, filterType);
  }

  invokeMultiFilterSearch(inputValue) {
    let queryType = inputValue.queryType;
    let queryValue = inputValue.value;

    if(queryType === "test"){
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        if(item.test_name.toLowerCase().includes(queryValue)){
          this.queriedJsonData.push(item);
        }
      })
    } 
    else if(queryType === "cancel"){
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        this.queriedJsonData.push(item);
      })
    }
    else if(queryType === "tester"){
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        if(item.tester_name.toLowerCase().includes(queryValue)){
          this.queriedJsonData.push(item);
        }
      })
    }
    else if(queryType === "hashtag"){
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        item.hastags.forEach((item2)=>{
          if(item2.toLowerCase().includes(queryValue)){
            this.queriedJsonData.push(item);
          }
        })
      })
    }
    else if(queryType === "device"){
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        queryValue.forEach((deviceItem)=>{
          if(item.device_type.toLowerCase().includes(deviceItem)){
            this.queriedJsonData.push(item);
          }
        })
      })
    }
    else if(queryType === "date"){
      this.queriedJsonData = [];
      let startDateFormatted = new Date(queryValue.startDate);
      let endDateFormatted = new Date(queryValue.endDate);

      this.unfilteredJsonData.forEach((item)=>{
        let newDate = new Date(item.date_of_tested);
        if(newDate >= startDateFormatted && newDate <= endDateFormatted){
          this.queriedJsonData.push(item);
        }
      })
    }

    this.initializePaginationFun();
    // console.log("file: videos-dashboard-new.component.ts ~ line 298 ~ newItem", inputValue)
  }


  innokeSearchBoxFilter(queryValue){

    console.log('search query invoked');

    if(queryValue != ""){
      console.log("inside condition if ", queryValue);

      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        if(item.test_name.toLowerCase().includes(queryValue)){
          this.queriedJsonData.push(item);
        }
      })
      this.selectedChipFilter = "";
    }

    if(queryValue == ""){
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        this.queriedJsonData.push(item);
      })
      this.selectedChipFilter = "all";
    }

    this.initializePaginationFun();
  }




}
        