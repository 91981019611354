<form class="make-clip-container" #makeclipform (submit)="AddClipAndNote($event)">
    <div class="make-clip-input">
        <div class="make-clip-input-one">
            <img class="doc" src="/assets/images/icons/file-text-white.svg" />

            <input autocomplete="off"  (keypress)="ValidateInput($event)" (keyup)="AddColon($event)" name="starttime" 
                [defaultValue]="videocurrentbytime" type="text" maxlength="5" placeholder="00:00" class="time-input start-time" 
                [(ngModel)]="startTimeInputElementValue" (ngModelChange)="testValue()" />

            <input autocomplete="off" (keypress)="ValidateInput($event)" (keyup)="AddColon($event)" name="endtime" type="text" 
                maxlength="5" placeholder="00:00" #endtime class="time-input end-time"
                [disabled]="!toggleNotesAndClipsCheckbox"
                [ngClass]="[toggleNotesAndClipsCheckbox == true ? 'abled-end-time' : 'disabled-end-time' ]"
                [(ngModel)]="endTimeInputElementValue" (ngModelChange)="testValue()"/>
        </div>

        <div class="make-clip-input-two">
            <label class="checkbox-container">
                <input (change)="ToggleMakeClipAndNotes()" type="checkbox" (click)="makeClipOpen()" id="clips_and_notes_checkbox"/>
                <span class="checkmark"></span>
            </label>
            <p>Make clip</p>
        </div>
    </div>

    <textarea #notes (keyup)="ValidateNotes($event)" name="notes" class="make-clip-notes" placeholder="Add note or hashtag to a specific timestamp"></textarea>

    <div class="make-clip-error">
        <div [ngClass]="{'hidediv': error1 == false, 'showdiv': error1 == true}" class="make-clip-error1 " #starttimeerror>start-time required</div>
        <div [ngClass]="{'hidediv': error2 == false, 'showdiv': error2 == true}" class="make-clip-error2" #endtimeerror>End-time required</div>
        <div [ngClass]="{'hidediv': error3 == false, 'showdiv': error3 == true}" class="make-clip-error3" #noteserror>Notes is required</div>

        <!-- <div *ngIf="(endTimeInputElementValue.toString().length >= 5) && (validInputEndTime < selectedVideoEndTime)">End duration greater than video duration</div> -->

        <!-- <div *ngIf="(validInputStartTime > validInputEndTime) && (validInputEndTime <= validInputStartTime) && (startTimeInputElementValue.toString().length >= 5) && (endTimeInputElementValue.toString().length >= 5)">End duration must be greater than start duration</div> -->
        <!-- <div *ngIf="">End duration must be greater than start duration</div> -->
        <!-- <div *ngIf="(validInputStartTime > validInputEndTime) && (startTimeInputElementValue.toString().length >= 5) && (endTimeInputElementValue.toString().length >= 5)">start duration must be less than end duration</div> -->

        <div *ngIf="validEndDurationIsGreater === true" class="make-clip-error3">End duration must be greater than start suration</div>
        <div *ngIf="startAndEndDurationValidFormat === true" class="make-clip-error3">Start or End duration format invalid !</div>
        <div *ngIf="checkVideoLengthExceed === true" class="make-clip-error3">End duration must be less than video length</div>
        <div *ngIf="notesStartDurationLimitExceed === true" class="make-clip-error3">start duration must be less than video length</div>


        <!-- <div *ngIf="validInputStartTime.toString().length > 3 ">Start duration invalid</div>
        <div *ngIf="validInputEndTime.toString().length > 3">End duration invalid</div> -->
    </div>

    <button type="submit"  *ngIf="editButton == false" class="make-clip-submit">Add</button>
    <!-- <button (click)="testFun()" class="make-clip-submit">click</button> -->
    <button type="submit"  *ngIf="editButton == true" class="make-clip-submit">Edit</button>
</form>