<div
  [attr.data-backdrop]='backdrop'
  [ngStyle]='{ display: showAddModel }'
  aria-modal='true'
  class='modal show'
  id='screenrecording_Modal'>

  <div class='modal-dialog'>
    <div class='modal-content'>

      <!-- logo -->
      <div class='modal-header'>
        <h2 class='modal-title'>
          <span *ngIf='!_modelBoxService.isGettingError'>
            <img src='assets/images/success.png'/>
          </span>
          <span *ngIf='_modelBoxService.isGettingError'>
           <img src='assets/images/error.png'/>
          </span>
        </h2>
      </div>

      <div *ngIf='!_modelBoxService.isGettingError' class='modal-body up-modal-body'>
        <h3 class='successModelTitle'>{{ modelHeaderContent }}</h3>
        <p style='text-align: center'>
          {{ modelBodyContent }}
        </p>

        <button
          (click)='closeSuccessModel(true)'
          class='recording'
          type='button'>
          OK
        </button>
      </div>

      <div *ngIf='_modelBoxService.isGettingError' class='modal-body up-modal-body'>
        <h3 class='successModelTitle'>{{ modelHeaderContent }}</h3>
        <p style='text-align: center'>
          {{ modelBodyContent }}
        </p>

        <button
          (click)='closeSuccessModel(true)'
          class='recording'
          type='button'>
          OK
        </button>
      </div>

      <!-- <div *ngIf='_modelBoxService.isGettingError' class='modal-body up-modal-body'>
        <p>
          {{ modelBodyContent }}
        </p>

        <p>
          if you have troubles with your test, find help within our
          <a href='https://helpcenter.userpeek.com/' target='_blank'>
            <strong class='up-blue'>Helpcenter.</strong>
          </a>
        </p>

        <button
          (click)='closeSuccessModel(true)'
          class='recording'
          type='button'>
          Got it
        </button>
      </div> -->

    </div>
  </div>
</div>
