import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';


const routes: Routes = [

  // {
  //   path:'accountDetails',
  //   component:AccountDetailsComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path:'creditPlan',
  //   component:CreditsPlansComponent  ,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path:'billing',
  //   component:BillingComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path:'invoices',
  //   component:InvoicesComponent,
  //   canActivate: [AuthGuard]

  // }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {
}
