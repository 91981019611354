import {Injectable} from '@angular/core';
import {Billing} from 'src/app/core/models/billing.model';
import {Observable, Subject} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Session} from 'src/app/core/models/session';
import {appApiResources} from './api.constant';
import {BaseService} from '../../core/api-call/base.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends BaseService {
  public subject = new Subject<any>();



  // ============= new api =====================


  getNewPlanDetails(){
    return this.get(appApiResources.newPlanDetails)
    .pipe(
      catchError(this.handleError)
    );
  }

  //============== new api =====================

  sendEasyStartPlan(message) {
    this.subject.next(message);
  }

  getEasyStartPlan(): Observable<any> {
    return this.subject.asObservable();
  }

  /** POST: add a new customer  to the fastspring */
  addCustomer(customer: Billing): Observable<Billing> {
    return this.post<Billing>(appApiResources.paymentCustomer, customer)
      .pipe(
        catchError(this.handleError)
      );
  }
  /** POST: add a new customer  to the fastspring */
  downgradePlan(planDetails: any) {
    return this.post (appApiResources.downgradePlan, planDetails)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateCustomer(customer){
    return this.put(appApiResources.paymentCustomer, customer)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCustomer() {
    return this.get<Billing>(appApiResources.paymentCustomer)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllProducts() {
    return this.get(appApiResources.paymentProducts)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAvailableCoupon() {
    return this.get(appApiResources.paymentCoupon)
      .pipe(
        catchError(this.handleError)
      );
  }

  addSessionForPlan(session: Session) {
    return this.post(appApiResources.paymentSession, session)
      .pipe(
        catchError(this.handleError)
      );
  }


  postFastspringCallback(jsonData) {
    console.log('🏀🏀🏀🏀 : oder v2 api block');
    return this.post(appApiResources.fastspringCallback, jsonData)
      .pipe(
        catchError(this.handleError)
      );
  }

  saveOrder(saveData) {
    console.log('🏀🏀🏀🏀 : oder api block');
    return this.post(appApiResources.paymentOrder, saveData)
      .pipe(
        catchError(this.handleError)
      );
  }


  cancelOrder(subscription_id) {
    return this.delete(appApiResources.paymentOrder, {subscription_id: subscription_id})
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllOrder() {
    return this.get(appApiResources.paymentOrder)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCountryList(): Observable<any> {
    return this.get(appApiResources.countryLangList).pipe(
      catchError(this.handleError)
    );
  }
}
