import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {finalize, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {NgxSpinnerService} from 'ngx-spinner';
import {LoaderService} from './services/loader.service';
import {AuthService} from './services/auth.service';
import {appApiResources} from './services/api.constant';


@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  requestCount: number = 0;
  urlsToNotUseToken: Array<string>;

  constructor(
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private loaderServ: LoaderService,
    private authService: AuthService
  ) {
    this.urlsToNotUseToken = [
      appApiResources.login,
      appApiResources.registerClient,
      appApiResources.checkEmailExist,
      appApiResources.userVerifyEmail,
      appApiResources.activateEmail,
    ]
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    this.requestCount = this.requestCount + 1;
    this.loaderServ.isLoding.next(true);

    if (!this.isValidRequestForInterceptor(req.url)) {
      return next.handle(req.clone()).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && event.status === 200) {
            this.loaderServ.isLoding.next(false);
            if (event.body.status !== 200) {
              this.loaderServ.isLoding.next(false);
            }
          }
        })
      );
    } else if (this.authService.getToken()) {
      const request = req.clone({
        headers: req.headers.set('Authorization', 'Token ' + localStorage.getItem('token_id')),
      });
      return next.handle(request).pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && event.status === 200) {
              if (event.body.status !== 200) {
                this.loaderServ.isLoding.next(false);
              }
            }
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              }

              this.loaderServ.isLoding.next(false);
              Swal.fire({
                title: 'Your session has expired!',
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              })
              localStorage.clear();
              this.router.navigate(['login']);
              this.SpinnerService.hide()
            }
          }
        ),
        finalize(() => {

          this.requestCount--;
          this.loaderServ.isLoding.next(false);
        })
      );
    } else {
      this.loaderServ.isLoding.next(false);
      Swal.fire({
        title: 'Your session has expired!',
        showClass: {
          popup: 'animated fadeInDown faster'
        },
        hideClass: {
          popup: 'animated fadeOutUp faster'
        }
      })
      localStorage.clear();
      this.router.navigate(['login']);
      this.SpinnerService.hide()
    }
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    return this.urlsToNotUseToken.indexOf(requestUrl) <= -1;
  }
}
