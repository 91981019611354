<div>
  <div class="filter_parent">
    <div class="filter-icon">
      <div>
        <p (click)="showDropdownMenu()" class="add_filter">Add filter</p>
      </div>
      <div (click)="showDropdownMenu()" class="plus-icon">
        <img src="assets/images/icons/plus-black.svg" />
      </div>
    </div>
  

    <div class="drop_down_content">
      <ul
        class="list_items"
        [ngClass]="showMultiFilter == true ? 'show_content' : 'hide_content'"
      >
        <li (click)="showSubMenu('test')" id="test">
          <div class="bytest_icon">
            <p>By test</p>

            <div class="image-parent">
              <img src="assets/images/icons/chevron-right.svg" />
            </div>
          </div>
        </li>
        <li (click)="showSubMenu('tester')" id="tester">
          <div class="bytest_icon">
            <p>By tester</p>
            <div class="image-parent">
              <img src="assets/images/icons/chevron-right.svg" />
            </div>
          </div>
        </li>
        <li (click)="showSubMenu('device')" id="device">
          <div class="bytest_icon">
            <p>By device</p>
            <div class="image-parent">
              <img src="assets/images/icons/chevron-right.svg" />
            </div>
          </div>
        </li>
        <li (click)="showSubMenu('date')" id="date">
          <div class="bytest_icon">
            <p>By date</p>
            <div class="image-parent">
              <img src="assets/images/icons/chevron-right.svg" />
            </div>
          </div>
        </li>
        <li (click)="showSubMenu('hashtag')" id="hashtag">
          <div class="bytest_icon">
            <p>By hashtag</p>
            <div class="image-parent">
              <img src="assets/images/icons/chevron-right.svg" />
            </div>
          </div>
        </li>
        <li id="Delete_filter" class="Delete_filter">
          <p>Delete filter</p>
        </li>
      </ul>
    </div>
    <div class="submenu_parent">
      <!-- Test -->
      <div
        [ngClass]="showMultiFilterSubMenu == true && showMultiFilterValue == 'test' ? 'test_submenu_show': 'test_submenu_hide'">
        <div class="test_parent">
          <div class="search_parent">
            <img src="assets/images/icons/search-new-icon.svg" />
            <input class="search_input" type="text" placeholder="Search" #testInput/>
          </div>
          <div class="btn_parent">
            <button class="cancel_btn btn-sty-blue-outline" (click)="sendFilterQuery('cancel','none')">Cancel</button>
            <button class="apply_btn" (click)="sendFilterQuery('test',testInput.value)">Apply filter</button>
          </div>
        </div>
      </div>
      <!-- Tester -->
      <div
        [ngClass]="showMultiFilterSubMenu == true && showMultiFilterValue == 'tester'? 'test_submenu_show': 'test_submenu_hide'">
        <div class="test_parent">
          <div class="search_parent">
            <img src="assets/images/icons/search-new-icon.svg" />
            <input class="search_input" type="text" placeholder="Search" #testerInput/>
          </div>
          <div class="btn_parent">
            <button class="cancel_btn btn-sty-blue-outline" (click)="sendFilterQuery('cancel','none')">Cancel</button>
            <button class="apply_btn" (click)="sendFilterQuery('tester',testerInput.value)">Apply filter</button>
          </div>
        </div>
      </div>
      <!-- Device -->
      <div [ngClass]="showMultiFilterSubMenu == true && showMultiFilterValue == 'device' ? 'test_submenu_show' : 'test_submenu_hide' ">
        <div class="device_parent">
          <div class="checkinput_parent">
            <div class="check_label">
              <label>
                <input class="device-checkbox" type="checkbox" value="desktop"  />
                Windows PC
              </label>
            </div>
            <div class="check_label">
              <label>
                <input class="device-checkbox" type="checkbox" value="desktop" />
                Apple PC
                </label>
            </div>
            <div class="check_label">
              <label>
                <input class="device-checkbox" type="checkbox" value="desktop" />
                Linux PC
              </label>
            </div>
            <div class="check_label">
              <label>
                <input class="device-checkbox" type="checkbox" value="mobile" />
                Android Phone
              </label>
            </div>
            <div class="check_label">
              <label>
                <input class="device-checkbox" type="checkbox" value="tablet" />
                Android Tablet
              </label>
            </div>
            <div class="check_label">
              <label>
                <input class="device-checkbox" type="checkbox" value="mobile" />
                iPhone
              </label>
            </div>
            <div class="check_label">
              <label>
                <input class="device-checkbox" type="checkbox" value="tablet" />
                iPad</label
              >
            </div>
          </div>
          <div class="device_btn_parent">
            <button class="cancel_btn btn-sty-blue-outline" (click)="sendFilterQuery('cancel','none')">Cancel</button>
            <button class="apply_btn" (click)="getDeviceCheckboxValue()">Apply filter</button>
          </div>
        </div>
      </div>
      <!-- Date -->
      <div [ngClass]="showMultiFilterSubMenu == true && showMultiFilterValue == 'date' ? 'test_submenu_show' : 'test_submenu_hide' " >
        <div class="date_parent">
          <div>
            <div>
              <div class="from_date_label">
                <label>From</label>
              </div>
              <div class="from_date">
                <!-- onfocus="(this.type='date')" -->
                <input
                  type="date"
                  placeholder="type in date"
                  class="input-field date"
                  placeholder="type in date"
                  id="from-date"
                />
              </div>
            </div>

            <div>
              <div class="from_date_label">
                <label>To</label>
              </div>
              <div class="from_date">
                <!-- onfocus="(this.type='date')" -->
                <input
                  type="date"
                  placeholder="type in date"
                  class="input-field date"
                  placeholder="type in date"
                  id="to-date"
                />
              </div>
            </div>
          </div>
          <div class="date_btn_parent">
            <button class="cancel_btn btn-sty-blue-outline" (click)="sendFilterQuery('cancel','none')">Cancel</button>
            <button class="apply_btn" (click)="getDatevalue()">Apply filter</button>
          </div>
        </div>
      </div>
      <!-- Hashtag -->
      <div [ngClass]="showMultiFilterSubMenu == true && showMultiFilterValue == 'hashtag'  ? 'test_submenu_show' : 'test_submenu_hide' ">
      <div class="test_parent">
        <div class="search_parent">
          <img src="assets/images/icons/search-new-icon.svg" />
          <input class="search_input" type="text" placeholder="Search" #hashtagInput/>
        </div>
        <div class="btn_parent">
          <button class="cancel_btn btn-sty-blue-outline" (click)="sendFilterQuery('cancel','none')">Cancel</button>
          <button class="apply_btn" (click)="sendFilterQuery('hashtag',hashtagInput.value)">Apply filter</button>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
