import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/api-call/base.service';
import {appApiResources} from './api.constant';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Userv2Service extends BaseService{

  validateUsername(body: any){
    console.log('hi.......!!!!', body, appApiResources.createhighlightReel);
    return this.post(appApiResources.validateUsername, body);
    // return this.post(appApiResources.createhighlightReel, body);
  }



}