<div class="containr p-3">
  <div class="mt-3">
    <h2 style="margin-bottom: auto;">{{testNTesterDetails.test_name}}</h2>
    <p class="text_style">
      {{testNTesterDetails.start_url}}
    </p>
  </div>

  <div class="row mt-4">
    <div class="col-2">
      <img [src]="testNTesterDetails.image || './assets/images/user_img.png'" alt="img" class="rounded-circle"
           width="37" height="37" style="border:1px solid #A6A6A6;"/>
    </div>
    <div class="col-10">
      <div class="row" style="font-size: 16px;">
        <label class="profile_style m-0">{{testNTesterDetails.first_name}} {{testNTesterDetails.last_name}}</label>
        <label class="text_style m-0">({{testNTesterDetails.age}} y., {{testNTesterDetails.country}})</label>
      </div>
      <div class="row">
        <star-rating [value]="testNTesterDetails.rating" totalstars="5" checkedcolor="orange" uncheckedcolor="#919191"
                     size="24px"
                     readonly="false"></star-rating>
      </div>
      <div class="row">
        <label class="text_style">Please rate this tester</label>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-2 d-flex justify-content-end align-items-center">
      <img src="assets/images/icons/screen-icon.png" alt="screen-icon"/>
    </div>
    <div class="col-10">
      <div class="row">
        <label class="profile_style text_size mr-1">{{testNTesterDetails.device_type}}</label>
        <!-- TODO Will Implement Later -->
        <!--        <label class="text_style" style="font-size: 15px;">(Chrome OS, 1024x780)</label>-->
      </div>

    </div>
  </div>

  <div class="row mt-3">
    <div class="col-2 d-flex justify-content-end align-items-center">
      <img src="assets/images/icons/calender.png" alt="screen-icon" height="19">
    </div>
    <div class="col-9">
      <div class="row">
        <label class="profile_style text_size mr-1">{{testNTesterDetails.date_of_tested | date: 'dd MMM YYYY'}}</label>
        <label class="text_style">@ {{testNTesterDetails.date_of_tested | date: 'hh:mm a'}}</label>
      </div>
    </div>
  </div>


  <div class="mt-3">
    <form>
      <textarea matInput class="border w-100 bg-white p-2" placeholder="Add notes to a specific timestamp"
                rows="6"></textarea>
    </form>
  </div>

  <div class="mt-2">
    <button type="button" class="btn btn btnbg_color">Add Note</button>
  </div>


</div>
