import { Component, OnInit, OnDestroy } from '@angular/core';
import { CreateNewTestService } from 'src/app/shared/services/create-new-test.service';
import { CustomPopupBoxService } from 'src/app/shared/services/custom-popup-box.service';
import {ActivatedRoute, Router, Params} from '@angular/router';
import { AllFiltersShareQueryService } from 'src/app/shared/services/all-filters-share-query.service';
import { appApiResources } from 'src/app/shared/services/api.constant';

@Component({
  selector: 'app-reports-dashboard',
  templateUrl: './reports-dashboard.component.html',
  styleUrls: ['./reports-dashboard.component.scss']
})
export class ReportsDashboardComponent implements OnInit, OnDestroy {

  filteredJsonData = [];
  unfilteredJsonData = [];
  queriedJsonData = [];
  paginateCount: number = 0;
  buttonFilterData: any ={
    allVideos: 0,
    active: 0,
    archive: 0,
  };
  isDataAvailable:boolean = true;
  selectedChipFilter: string = "all";
  allFilterQueryDataObservable: any;
  
  openPopupId: any | null = null;  // Tracks the currently open popup's ID

  constructor(
    private _createnewTestServices: CreateNewTestService,
    public _customPopupBoxService: CustomPopupBoxService,
    private _router: Router,
    private _allFiltersShareQueryService: AllFiltersShareQueryService,
    private _activatedRoute: ActivatedRoute,
  ) { 
    this.allFilterQueryDataObservable = this._allFiltersShareQueryService.componentFilterQueryData.subscribe((res)=>{
      if(res.requestType != "none"){
        if(res.component == this._router.url.split('?')[0]){
          this.processComponentFilterData(res);
        }
      }
    });
  }


  ngOnDestroy(): void {
    this.allFilterQueryDataObservable.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeComponentData();

    // this._allFiltersShareQueryService.componentFilterQueryData.subscribe((res)=>{
    //   if(res.requestType != "none"){
    //     if(res.component == this._router.url.split('?')[0]){
    //       this.processComponentFilterData(res);
    //     }
    //   }
    // });
  }

  togglePopup(id: any) {
    console.log("789",id);
    
       this.openPopupId = this.openPopupId == id ? null : id;
     }
  initializeComponentData(){
    this._activatedRoute.queryParams.subscribe((params) => {
      if(params['project-id'] == undefined){
        this._router.navigate(['/project-selector']);
      } else {
        this.loadProjectData(params['project-id']);
      }
    });
  }




  processComponentFilterData(value){
    if(value.requestType == "getDataFromParent"){
      this._allFiltersShareQueryService.sendDataParentToChild(this.unfilteredJsonData);
    }
    else if(value.requestType == "dataChildToParent"){
      let tempClipList = [] 
      this._allFiltersShareQueryService.childToParentDataVariable.subscribe((res)=>{
        tempClipList = res;
      });
      this.queriedJsonData = tempClipList;
      this.initializePaginationFun();
    }
    else if(value.requestType == "loadOriginalData"){
      this.initializeComponentData();
    }
  }

  loadProjectData(project_id){
    this._createnewTestServices.getTestRelatedToProjectId(project_id).subscribe((res: any)=>{

      this.isDataAvailableFun(res.data.length);

      this.unfilteredJsonData = [];
      this.queriedJsonData = [];
      res.data.forEach(item => {
        this.unfilteredJsonData.push(item);
        this.queriedJsonData.push(item);
      });

      this.validatePaginateCount();

      // initially show only 10 items
      this.filteredJsonData = [];
      for(let i = 0; i < this.paginateCount; i++){
        this.filteredJsonData.push(this.queriedJsonData[i])
      }

    });
  }

  createProject(){
    this._customPopupBoxService.showPopup("create-project-popup");
  }

  isDataAvailableFun(value){
    // if json data is empty show empty message
    if(value > 0){
      this.isDataAvailable = true;
    } else {
      this.isDataAvailable = false;
    }
  }

  validatePaginateCount(){
    if(this.queriedJsonData.length < 10){
      this.paginateCount = this.queriedJsonData.length;
    } else {
      this.paginateCount = 10;
    }
  }

  incrementPaginationCountFun(value){
    let actualQueriedDataLength = this.queriedJsonData.length;
    if(value < actualQueriedDataLength){
      this.paginateCount = value;
    } else {
      this.paginateCount = actualQueriedDataLength;
    }
    this.restrictDataCount();
  }

  restrictDataCount(){
    this.filteredJsonData = [];
    for(let i = 0; i < this.paginateCount; i++){
      this.filteredJsonData.push(this.queriedJsonData[i])
    }
  }


  initializePaginationFun(){
    this.validatePaginateCount();
    this.filteredJsonData = [];
    for(let i = 0; i < this.paginateCount; i++){
      this.filteredJsonData.push(this.queriedJsonData[i])
    }
  }


  downloadTestReport(testId){
    window.open(`${appApiResources.testReport.toString()}?test_id=${testId}`, "_self");
  }

  showContextMenu(event){
    let tempElement = undefined;

    if(event.target.className == "dash-context-menu-wrapper"){
      tempElement = event.target.children[1];
    } else if(event.target.className == "dash-context-menu-icon"){
      tempElement = event.target.parentElement.children[1];
    }

    // let tempElement = event.target.parentElement.children[1];
    if(tempElement.style.display == "none"){
      // close previous opened popup menu
      let allElements = document.getElementsByClassName("dash-context-menu-cont");
      for (let i = 0; i < allElements.length; i++) {
          allElements.item(i).setAttribute("style", "display: none");
      }
      // open popup menu on clicked card
      tempElement.style.display = "block";
    }
    else{
      // close popup menu after clicking again
      tempElement.style.display = "none";
    }
  }




}
