<div class="breadcrumbs-container" *ngIf="showBreadCrumbs">
    <!-- videos page -->
    <ng-container *ngIf="currentRouteVariable === '/results/videos' ">
        <div routerLink='/projects' class="text-url">Projects</div>
        <img src="assets/images/icons/down-arrow.svg" class="right-arrow" alt="right arrow">
        <div (click)="navigateProject(projectId)" class="text-url">{{projectName}}</div>
        <img src="assets/images/icons/down-arrow.svg" class="right-arrow" alt="right arrow">
        <ng-container *ngIf="testSelected == false">
            <div class="text-url">Results</div>
            <img src="assets/images/icons/down-arrow.svg" class="right-arrow" alt="right arrow">
            <div class="text-url">Videos</div>
        </ng-container>
        <ng-container *ngIf="testSelected == true">
            <div class="text-url">{{testName}}</div>
        </ng-container>
    </ng-container>
    <!-- clips page -->
    <ng-container *ngIf="currentRouteVariable === '/results/clips' ">
        <div routerLink='/projects' class="text-url">Projects</div>
        <img src="assets/images/icons/down-arrow.svg" class="right-arrow" alt="right arrow">
        <div (click)="navigateProject(projectId)" class="text-url">{{projectName}}</div>
        <img src="assets/images/icons/down-arrow.svg" class="right-arrow" alt="right arrow">
        <ng-container *ngIf="testSelected == false">
            <div class="text-url">Results</div>
            <img src="assets/images/icons/down-arrow.svg" class="right-arrow" alt="right arrow">
            <div class="text-url">clips</div>
        </ng-container>
        <ng-container *ngIf="testSelected == true">
            <div class="text-url">{{testName}}</div>
        </ng-container>
    </ng-container>
    <!-- reels page -->
    <ng-container *ngIf="currentRouteVariable === '/results/highlight-reels' ">
        <div routerLink='/projects' class="text-url">Projects</div>
        <img src="assets/images/icons/down-arrow.svg" class="right-arrow" alt="right arrow">
        <div (click)="navigateProject(projectId)" class="text-url">{{projectName}}</div>
        <img src="assets/images/icons/down-arrow.svg" class="right-arrow" alt="right arrow">
        <ng-container *ngIf="testSelected == false">
            <div class="text-url">Results</div>
            <img src="assets/images/icons/down-arrow.svg" class="right-arrow" alt="right arrow">
            <div class="text-url">Highlight reels</div>
        </ng-container>
        <ng-container *ngIf="testSelected == true">
            <div class="text-url">{{testName}}</div>
        </ng-container>
    </ng-container>
    <!-- test page -->
    <ng-container *ngIf="currentRouteVariable === '/research/tests' ">
        <div routerLink='/projects' class="text-url">Projects</div>
        <img src="assets/images/icons/down-arrow.svg" class="right-arrow" alt="right arrow">
        <div class="text-url">{{projectName}}</div>
    </ng-container>
    <!-- reports page -->
    <ng-container *ngIf="currentRouteVariable === '/reports/test-reports' ">
        <div routerLink='/projects' class="text-url">Projects</div>
        <img src="assets/images/icons/down-arrow.svg" class="right-arrow" alt="right arrow">
        <div (click)="navigateProject(projectId)" class="text-url">{{projectName}}</div>
        <img src="assets/images/icons/down-arrow.svg" class="right-arrow" alt="right arrow">
        <div class="text-url">Reports</div>
        <img src="assets/images/icons/down-arrow.svg" class="right-arrow" alt="right arrow">
        <div class="text-url">Test reports</div>
    </ng-container>
</div>