
<section class="credit-plan-container">
    <div class="credit-plan-header">
        <div class="credit-plan-title">
            <h4 style="margin-bottom: 30px;">Credits and Plans</h4>
            <p style="margin-bottom: 55px; padding-bottom: 15px; border-bottom: 1px solid var(--border-line-color);">Buy Tester Credits or manage plans</p>
        </div>
        <div class="credit-plan-values">
            <h1 style="margin-bottom: 10px;">{{currentUserCredit}} credits</h1>
            <p style="margin-bottom: 40px;">Credits are used to order testers from the UserPeek panel (1 credit = 1 tester). Buy Pay-as-you-go credits or subscribe for more features and better pricing below:</p>
        </div>
    </div>
    <div class="credit-plan-body">
        <div class="billing-type-toggle-container">
            <p class="text1">Monthly Billing</p>
            <input type="checkbox" [checked]="userActivePlan == 'grow-yearly-subscription'" #checkbox1 class="plan-toggle-btn-input" id="plan-toggle-btn-id" (change)="toggleAnnualPrice = !toggleAnnualPrice">
            <label for="plan-toggle-btn-id" class="plan-toggle-btn-label"></label>
            <p class="text2" style="margin-left: 10px;">Annual Billing</p>
            <p class="offer-text">20% OFF</p>
        </div>
        <section class="billing-card-container">
            <div class="flex-billing-card plan-card">
                <div class="billing-card-header">
                    <h6 class="flex-title plan-name-text">Flex <span *ngIf="userActivePlan == 'flexible'">(Current plan)</span></h6>
                    <!-- <h2>$ {{flexPlan.perMonthPrice}}</h2> -->
                    <h2>$ 0</h2>
                    <p>No subscription needed</p>
                    <!-- <h4>$ {{flexPlan.perTestPrice}}</h4> -->
                    <h4>$ 55</h4>
                    <p>per tester</p>
                </div>

                <div class="upper-btn-container">
                    <ng-container *ngTemplateOutlet="flexBuyBtn"></ng-container>
                </div>

                <div class="billing-recommended-section">
                    <h6>Recommended for</h6>
                    <p>Individuals interested in light weight testing</p>
                </div>

                <div class="billing-features-section">
                    <h6>Flex includes:</h6>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> $55 per tester </p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Unlimited projects</p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Test templates</p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="falseIcon"></ng-container> Advanced test templates</p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="falseIcon"></ng-container> Annotation & tagging</p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="falseIcon"></ng-container> AI Speech-to-text transcripts</p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="falseIcon"></ng-container> Clips & Highlight reels</p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="falseIcon"></ng-container> Reporting</p>
                </div>
                <ng-container *ngTemplateOutlet="flexBuyBtn"></ng-container>
                <!-- <button class="flex-btn btn-sty-blue plan-btn" (click)="showUPCreditsPopup = true; creditsPopupDisplayContent = 'buy-credit' ">Buy credits</button> -->
            </div>

            <div class="grow-billing-card plan-card">
                <div class="most-popular-text">
                    Most popular
                </div>
                <div class="billing-card-header">
                    <h6 *ngIf="toggleAnnualPrice == true" class="grow-title plan-name-text">
                        Grow yearly
                        <span *ngIf="userActivePlan == 'grow-yearly-subscription'">(Current plan)</span>
                    </h6>
                    <h6 *ngIf="toggleAnnualPrice == false" class="grow-title plan-name-text">
                        Grow monthly
                        <span *ngIf="userActivePlan == 'grow-monthly-subscription'">(Current plan)</span>
                    </h6>
                    <h2>$ {{ toggleAnnualPrice ? upGrowYearlyPlanDetails.price || 0 : upGrowMonthlyPlanDetails.price }}</h2>
                    <p>/month, billed {{ toggleAnnualPrice ? 'annually' : 'monthly' }}</p>
                    <h4>$ {{ toggleAnnualPrice ? upGrowYearlyPlanDetails.additionalCredits : upGrowMonthlyPlanDetails.additionalCredits }}</h4>
                    <p>per additional tester</p>
                    <h6>5 tester/month included</h6>
                </div>
                <div class="upper-btn-container">
                    <ng-container *ngTemplateOutlet="growBuyBtn"></ng-container>
                </div>

                <div class="billing-recommended-section">
                    <h6>Recommended for</h6>
                    <p>Pros looking to grow with more testing power.</p>
                </div>

                <div class="billing-features-section">
                    <h6>Grow includes:</h6>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> 5 tester/month included </p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> ${{ toggleAnnualPrice ? upGrowYearlyPlanDetails.additionalCredits : upGrowMonthlyPlanDetails.additionalCredits }} per additional tester </p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Credit rollover </p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Unlimited projects </p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Advanced test templates</p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Annotation & tagging</p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> AI Speech-to-text transcripts</p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Clips & Highlight reels</p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Reporting</p>
                </div>

                <ng-container *ngTemplateOutlet="growBuyBtn"></ng-container>
                <!-- <button class="grow-btn  btn-sty-blue plan-btn">Get started</button> -->
            </div>


            <div class="scale-billing-card plan-card">
                <div class="billing-card-header">
                    <h6 class="scale-title plan-name-text">Scale</h6>
                    <h2>Coming soon</h2>
                    <p>/month, billed annually</p>
                    <h6 style="margin-bottom: 0px; margin-top: 80px;">10 tester + 3 seats/month included</h6>
                </div>

                <div class="upper-btn-container">
                    <ng-container *ngTemplateOutlet="scaleBuyBtn"></ng-container>
                </div>

                <div class="billing-recommended-section">
                    <h6>Recommended for</h6>
                    <p>Experienced teams with iterative testing and testing cycles.</p>
                </div>

                <div class="billing-features-section">
                    <h6>Scale includes:</h6>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> 10 tester/month included </p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> 3 seats included </p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Credit rollover </p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Unlimited projects </p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Advanced test templates </p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Annotation & tagging </p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> AI Speech-to-text transcripts </p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Clips & Highlight reels </p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Team collaboration features </p>
                    <p class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Advanced metrics & reporting </p>
                </div>

                <ng-container *ngTemplateOutlet="scaleBuyBtn"></ng-container>
            </div>
        </section>
    </div>
    <div class="faq-container">
        <div class="faq-title">
            <h4>Frequently Asked Questions</h4>
            <p>You have questions? Find answers here.</p>
        </div>
        <article class="question-container">
            <h4>What is a credit?</h4>
            <p>Credit is a currency used on UserPeek to source testers from our panel to use on your different projects. One credit equals one video from a participant. You can buy prepaid credits, or directly when ordering testers. The price of credits decreases according to our subscriptions.</p>
        </article>
        <article class="question-container">
            <h4>How many credits will I need?</h4>
            <p>The number of credits you need depends on how many testers you need to test. We recommend testing with at least 20 testers to have statistically relevant results. </p>
        </article>
        <article class="question-container">
            <h4>Do credits expire?</h4>
            <p>Credits you purchase roll over from month you month according to your chosen plan. Within Flex (Pay-as-you-go) your credits will expire after 90 days, within Grow after 180 days. </p>
        </article>
        <article class="question-container">
            <h4>How fast do results from testers come in?</h4>
            <p>You get the results as soon as your order is placed. Orders are usually fulfilled in hours — sometimes even minutes.</p>
        </article>
        <article class="question-container">
            <h4>Who is on the UserPeek panel?</h4>
            <p>UserPeek has its own carefully selected panel of testers to provide real-value insight. </p>
        </article>
        <article class="question-container">
            <h4>What demographics are covered?</h4>
            <p>All test participants are (currently) English-speaking. You can filter responses based on sex (all, male, female, diverse) and age (18-50+) when you order videos.</p>
        </article>
        <article class="question-container">
            <h4>Can I spend credits on multiple projects?</h4>
            <p>Yes, you can use the credits you purchase across all your projects, and for all your UserPeek tests.</p>
        </article>
        <article class="question-container">
            <h4>What payment options do you accept?</h4>
            <p>We accept all major credit cards types. You will be redirected to our payment provider Fastspring.</p>
        </article>

        <p style="padding-bottom: 50px;" class="bottom-link">Have more questions? <a href="https://helpcenter.userpeek.com/" target="_blank">Visit our HelpCenter</a> </p>
    </div>
</section>

<!-- Common elements -->
<ng-template #trueIcon>
    <img src="assets//images/icons/green-tickmark.svg" alt="userpeek icon">
</ng-template>

<ng-template #falseIcon>
    <img src="assets//images/icons/false-icon-red-circle.svg" alt="userpeek icon">
</ng-template>

<ng-template #flexBuyBtn>
    <!-- <button class="flex-btn btn-sty-blue plan-btn">Buy credits</button> -->
    <button *ngIf="userActivePlan == '' " class="flex-btn btn-sty-blue plan-btn" 
    (click)="showUPCreditsPopup = true; creditsPopupDisplayContent = 'buy-credit' ">Buy credits</button>

    <button *ngIf="userActivePlan == 'flexible'" class="flex-btn btn-sty-blue plan-btn" 
    (click)="showUPCreditsPopup = true; creditsPopupDisplayContent = 'buy-credit' ">Buy credits</button>
    
    <button *ngIf="(userActivePlan == 'grow-yearly-subscription' || userActivePlan == 'grow-monthly-subscription') && (userFuturePlan  === '')" 
        class="flex-btn btn-sty-blue-outline plan-btn" 
        (click)="showUPCreditsPopup = true; creditsPopupDisplayContent = 'downgrade-to-flex' ">
        Downgrade to Flex
    </button>

    <button
        *ngIf="(userActivePlan == 'grow-yearly-subscription' || userActivePlan == 'grow-monthly-subscription') && (userFuturePlan != '')"
        class="flex-btn btn-sty-blue plan-btn"
        style="opacity: 0.3; pointer-events: none;"
    >
        Buy credits
    </button>
</ng-template>

<ng-template #growBuyBtn>
    <!-- <button class="grow-btn  btn-sty-blue plan-btn">Get started</button> -->
    <button *ngIf="userActivePlan == ''" class="grow-btn  btn-sty-blue plan-btn" >Get started</button>
    <!-- <button *ngIf="(userActivePlan == 'flexible') && (toggleAnnualPrice == false)" class="grow-btn  btn-sty-blue plan-btn" (click)="buySubscription('grow-monthly-subscription')"> -->
    <button 
        *ngIf="(userActivePlan == 'flexible') && (toggleAnnualPrice == false)"
        class="grow-btn  btn-sty-blue plan-btn"
        (click)="handleBuySubscription('Grow monthly','grow_monthly')"
    >
        <!-- (click)="buySubscription('grow-monthly-subscription')" -->
        Get started
    </button>
    <button
        *ngIf="(userActivePlan == 'flexible') && (toggleAnnualPrice == true)" 
        class="grow-btn  btn-sty-blue plan-btn"
        (click)="handleBuySubscription('Grow yearly','grow_yearly')"
    >
        <!-- (click)="buySubscription('grow-yearly-subscription')" -->
        Get started
    </button>

    <button *ngIf="(toggleAnnualPrice == false) && ( userActivePlan == 'grow-monthly-subscription')" 
    class="grow-btn  btn-sty-blue plan-btn" (click)="showUPCreditsPopup = true; creditsPopupDisplayContent = 'buy-credit' ">
        Buy additional credits
    </button>
    <button 
        *ngIf="(toggleAnnualPrice == true) && (userActivePlan == 'grow-monthly-subscription')" 
        class="grow-btn  btn-sty-blue plan-btn"
        (click)="
            showUPCreditsPopup = true;
            creditsPopupDisplayContent = 'confirm-upgrade'
        "
    >
        <!-- (click)="buySubscription('grow-yearly-subscription')" -->
        Upgrade to Grow yearly plan
    </button>

    <button 
    *ngIf="(toggleAnnualPrice == false) && ( userActivePlan == 'grow-yearly-subscription')" 
    class="grow-btn  btn-sty-blue plan-btn" 
    (click)="handleBuySubscription('Grow monthly','grow_monthly')"
    style="opacity: 0.3; pointer-events: none;"
    >
        <!-- (click)="buySubscription('grow-monthly-subscription')" -->
        Get started
    </button>
    <button *ngIf="(toggleAnnualPrice == true) && (userActivePlan == 'grow-yearly-subscription')" 
    class="grow-btn  btn-sty-blue plan-btn" (click)="showUPCreditsPopup = true; creditsPopupDisplayContent = 'buy-credit'">
        Buy additional credits 
    </button>

    <!-- Cancel plan -->
    <p 
        *ngIf="(userActivePlan == 'grow-yearly-subscription') && (userFuturePlan == '') && (toggleAnnualPrice == true)"
        (click)="showUPCreditsPopup = true; creditsPopupDisplayContent = 'downgrade-to-flex' "
        style="
            font-size: 16px;
            color: #000;
            margin-top: 15px;
            text-align: center;
            text-decoration: underline;
            cursor: pointer;
            "
    >
        Cancel plan
    </p>
    <!-- upgrade to grow yearly -->
    <p 
        *ngIf="(userActivePlan == 'grow-monthly-subscription') && (userFuturePlan == '') && (toggleAnnualPrice == false)"
        (click)="
            toggleAnnualPrice  = true;
            checkbox1.checked = true;
            showUPCreditsPopup = true;
            creditsPopupDisplayContent = 'confirm-upgrade'
        "
        style="
            font-size: 16px;
            color: #000;
            margin-top: 15px;
            text-align: center;
            text-decoration: underline;
            cursor: pointer;
            "
    >
        <!-- buySubscription('grow-yearly-subscription') -->
        Upgrade to Grow yearly plan
    </p>


    <!-- end of current plan date -->
    <p 
        *ngIf="(userActivePlan == 'grow-yearly-subscription') && (userFuturePlan != '') && (toggleAnnualPrice == true)"
        style="font-size: 16px; color: #008040; margin-top: 15px; margin-bottom: 0px; text-align: center;"
    >
        {{"your plan will be downgraded on "+planDowngradeDate}}
    </p>
    <p 
        *ngIf="(userActivePlan == 'grow-monthly-subscription') && (userFuturePlan != '') && (toggleAnnualPrice == false)"
        style="font-size: 16px; color: #008040; margin-top: 15px; margin-bottom: 0px; text-align: center;"
    >
        {{"your plan will be downgraded on "+planDowngradeDate}}
    </p>    
</ng-template>

<ng-template #scaleBuyBtn>
    <button class="scale-btn  btn-sty-blue plan-btn">Coming soon</button>
</ng-template>

<ng-template #priceCalc>
    {{ upActivePlanDetails.additionalCredits * videoCount || 0 }}

    <!-- {{ userActivePlan == "flexible" ? currentPlanDetails.price * videoCount || 0: userActivePlan == "grow-monthly-subscription" ? 
                                    upGrowMonthlyPlanDetails.additionalCredits * videoCount || 0 : userActivePlan == "grow-yearly-subscription" ?
                                    upGrowYearlyPlanDetails.additionalCredits * videoCount || 0: 0 }} -->
</ng-template>


<!-- component specific popup box -->
<!-- <div *ngIf="true" class="credits-popup-backdrop"> -->
<div *ngIf="showUPCreditsPopup" class="credits-popup-backdrop">
    <div class="credits-popup-body">
        <img (click)="showUPCreditsPopup = !showUPCreditsPopup" class="credits-popup-close-icon" src="assets/images/icons/close-icon.svg" alt="close icon">
        <div class="credits-popup-content">

            <ng-container *ngIf=" creditsPopupDisplayContent == 'confirm-upgrade'">
                <h2 class="credits-popup-title">Upgrade subscription</h2>
                <p style="
                    width: 80%;
                    text-align: center;
                    margin: 100px auto 0px auto;
                    font-size: 20px;
                    line-height: 29px;
                    font-family: var(--font-regular);
                "
                >
                    You are upgrading to a yearly subscription and will be charged ${{upGrowYearlyPlanDetails.price * 12}}
                </p>
                <button style="margin-top: 70px;" (click)="upgradeToYearly()" class="credits-popup-main-btn btn-sty-blue">
                    Confirm
                </button>
            </ng-container>

            <ng-container *ngIf=" creditsPopupDisplayContent == 'rename'">
                <h2 class="credits-popup-title">Rename project</h2>

                <label style="margin-top: 50px;"> Project name</label>
                <input (ngModelChange)="handleRenameValueChanges($event)" [(ngModel)]="renameInputValue" type="text" class="credits-popup-text-input">
                <p *ngIf="(renameInputValue != renameExistingValue) && renameValueExist" style="margin-top:10px; color: var(--red); font-size: 14px; font-family: var(--font-regular);"> 
                    The project name already exists 
                </p>

                <button style="margin-top: 50px;" (click)="sendRenameRequest()" class="credits-popup-main-btn btn-sty-blue"
                    [ngClass]="[ (renameInputValue != renameExistingValue) && !renameValueExist ? 'up-credits-popup-btn-active' : 'up-credits-popup-btn-inactive']">
                    Rename
                </button>
            </ng-container>

            <ng-container *ngIf=" creditsPopupDisplayContent == 'buy-credit'">
            <!-- <ng-container *ngIf=" true "> -->
                <section class="header">
                    <h1>Buy Credits</h1>
                    <p style="font-size: 17px;">Buy credits to order videos with testers from the UserPeek panel</p>
                </section>
    
                <main style="margin-top: 35px">
    
                    <div class="no-of-testers-cont">
                        <div class="no-of-testers-txt">
                            <p>Number of testers: </p>
                            <img src="assets/images/icons/info.svg" alt="info icon">
                        </div>
                        <div class="no-of-testers-input">
                            <input [(ngModel)]="videoCount" type="number" min="0">
                        </div>
                    </div>
    
                    <div class="order-detials-table" style="margin-top: 30px">
                        <table class="order-videos-table">
                            <tr class="order-title-row">
                                <th style="padding: 10px;">Your order</th>
                                <th style="padding: 10px;">{{videoCount}} testers</th>
                            </tr>
                            <tr *ngIf="videoCount > 0" class="order-data-row">
                                <td style="padding: 10px;">
                                    $
                                    {{ upActivePlanDetails.additionalCredits }}
                                    
                                    <!-- {{ 
                                        userActivePlan == "flexible" 
                                        ? 
                                            currentPlanDetails.price 
                                        : 
                                            userActivePlan == "grow-monthly-subscription" 
                                            ? 
                                                upGrowMonthlyPlanDetails.additionalCredits 
                                            : 
                                                userActivePlan == "grow-yearly-subscription" 
                                                ?
                                                    upGrowYearlyPlanDetails.additionalCredits 
                                                : 0 
                                    }} -->
                                    per Tester
                                    <span *ngIf="userActivePlan == 'flexible'">(no subscription)</span>
                                    <span *ngIf="userActivePlan == 'grow-monthly-subscription'">(GROW monthly)</span>
                                    <span *ngIf="userActivePlan == 'grow-yearly-subscription'">(GROW yearly)</span>
                                </td>
                                <td style="padding: 10px;">
                                    $ <ng-container *ngTemplateOutlet="priceCalc"></ng-container>
                                </td>
                            </tr>
                            <tr *ngIf="videoCount > 0" class="order-footer-row">
                                <th style="padding: 10px;">Total</th>
                                <th style="padding: 10px;">$ <ng-container *ngTemplateOutlet="priceCalc"></ng-container></th>
                            </tr>
                        </table>
                    </div>
    
                    <div *ngIf="userActivePlan == 'flexible'" class="order-offer-section">
                        <div class="row1">
                            <div class="left">
                                <a class="a-sty" routerLink='/credits-plans'>Upgrade to Grow monthly?</a>
                                <span>11% OFF</span>
                            </div>
                            <div class="right"> 
                                <p>
                                    <span style="font-size: 22px; font-family: var(--font-medium);">${{getOfferPrice(upGrowMonthlyPlanDetails.price, 11)}}</span>
                                    <span style="font-size: 16px; font-family: var(--font-regular);">/month</span>
                                </p>
                            </div>
                        </div>
                        <div class="row2" style="display: flex; justify-content: space-between; margin-top: 5px;">
                            <div class="left">
                                <!-- <p>5 tester/month included</p> -->
                                <p style="margin-top: 5px;">5 credits included, à ${{upGrowMonthlyPlanDetails.additionalCredits}} per tester</p>
                            </div>
                            <div class="right">
                                <p style="font-size: 14px; font-family: var(--font-regular);">Cancel anytime</p>
                            </div>
                        </div>
                        <div class="row3">
                            <a class="a-sty" style="font-size: 14px; font-family: var(--font-medium);" routerLink='/credits-plans'
                                (click)="showUPCreditsPopup = !showUPCreditsPopup; toggleAnnualPrice = false; checkbox1.checked = false"
                            >
                                Yes, upgrade to Grow monthly plan
                            </a>
                        </div>
                    </div>
                    <div *ngIf="userActivePlan == 'grow-monthly-subscription'" class="order-offer-section">
                        <div class="row1">
                            <div class="left">
                                <a 
                                    class="a-sty"
                                    (click)="showUPCreditsPopup = !showUPCreditsPopup; toggleAnnualPrice = true; checkbox1.checked = true"
                                >
                                    Upgrade to Grow yearly?
                                </a>
                                <span>20% OFF</span>
                            </div>
                            <div class="right"> 
                                <p>
                                    <span style="font-size: 22px; font-family: var(--font-medium);">${{getOfferPrice(upGrowYearlyPlanDetails.price, 20)}}</span>
                                    <span style="font-size: 16px; font-family: var(--font-regular);">/month</span>
                                </p>
                            </div>
                        </div>
                        <div class="row2" style="display: flex; justify-content: space-between; margin-top: 5px;">
                            <div class="left">
                                <!-- <p>5 tester/month included</p> -->
                                <p style="margin-top: 5px;">5 tester/month included</p>
                                <p style="margin-top: 5px;">${{upGrowYearlyPlanDetails.additionalCredits}} per additional tester</p>
                            </div>
                            <div class="right">
                                <p style="font-size: 14px; font-family: var(--font-regular);">Annual billing</p>
                            </div>
                        </div>
                        <div class="row3">
                            <a class="a-sty" style="font-size: 14px; font-family: var(--font-medium);" routerLink='/credits-plans'
                                (click)="showUPCreditsPopup = false; toggleAnnualPrice = true; checkbox1.checked = true"
                            >
                                Yes, upgrade to Grow yearly plan
                            </a>
                        </div>
                    </div>
                    <div 
                        *ngIf="userActivePlan == 'grow-yearly-subscription'" 
                        class="order-offer-section"
                        style="background-color: #fff;"    
                    >
                    </div>
    
                </main>
    
                <button (click)="handleStripeCheckout('buy-credits')" class="custom-popup-main-btn btn-sty-blue"
                    [disabled]="!videoCount" [ngClass]="[ videoCount > 0 ? 'up-btn-active' : 'up-btn-inactive']">
                    Pay $ <ng-container *ngTemplateOutlet="priceCalc"></ng-container>
                    <span *ngIf="userActivePlan == 'flexible'">(no subscription)</span>
                    <span *ngIf="userActivePlan == 'grow-monthly-subscription'">(GROW monthly)</span>
                    <span *ngIf="userActivePlan == 'grow-yearly-subscription'">(GROW yearly)</span>
                </button>
            </ng-container>


            <ng-container *ngIf=" creditsPopupDisplayContent == 'downgrade-to-flex' ">
            <!-- <ng-container *ngIf="true"> -->
                <section class="header">
                    <h1>Downgrade to Flex</h1>
                    <P>Are you sure you want to downgrade to Flex?</P>
                </section>
    
                <main style="margin-top: 35px">

                    <div class="downgrade-flex-container">
                        <h6 style="margin-bottom: 20px;">Flex includes:</h6>
                        <section style="display: flex; justify-content: space-between;">
                            <div>
                                <p style="margin-bottom: 10px;" class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> $55 per tester </p>
                                <p style="margin-bottom: 10px;" class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Unlimited projects </p>
                                <p style="margin-bottom: 10px;" class="billing-features-points"> <ng-container *ngTemplateOutlet="trueIcon"></ng-container> Test templates </p>
                            </div>
                            <div>
                                <p style="margin-bottom: 10px;" class="billing-features-points"> <ng-container *ngTemplateOutlet="falseIcon"></ng-container> Advanced test templates </p>
                                <p style="margin-bottom: 10px;" class="billing-features-points"> <ng-container *ngTemplateOutlet="falseIcon"></ng-container> Annotation & tagging </p>
                                <p style="margin-bottom: 10px;" class="billing-features-points"> <ng-container *ngTemplateOutlet="falseIcon"></ng-container> AI Speech-to-text transcripts </p>
                                <p style="margin-bottom: 10px;" class="billing-features-points"> <ng-container *ngTemplateOutlet="falseIcon"></ng-container> Clips & Highlight reels </p>
                                <p style="margin-bottom: 10px;" class="billing-features-points"> <ng-container *ngTemplateOutlet="falseIcon"></ng-container> Reporting </p>
                            </div>
                        </section>
                    </div>
    
                    <div *ngIf="userActivePlan == 'grow-monthly-subscription'" class="order-offer-section">
                        <div class="row1">
                            <div class="left">
                                <a 
                                    class="a-sty" 
                                    (click)="showUPCreditsPopup = !showUPCreditsPopup;"
                                >
                                    Keep Grow monthly plan?
                                </a>
                                <span>11% OFF</span>
                            </div>
                            <div class="right"> 
                                <p>
                                    <span style="font-size: 22px; font-family: var(--font-medium);">${{getOfferPrice(upGrowMonthlyPlanDetails.price, 11)}}</span>
                                    <span style="font-size: 16px; font-family: var(--font-regular);">/month</span>
                                </p>
                            </div>
                        </div>
                        <div class="row2" style="display: flex; justify-content: space-between; margin-top: 5px;">
                            <div class="left">
                                <p>5 tester/month included</p>
                                <p style="margin-top: 5px;">${{upGrowMonthlyPlanDetails.additionalCredits}} per additional tester</p>
                            </div>
                            <div class="right">
                                <p style="font-size: 14px; font-family: var(--font-regular);">Cancel anytime</p>
                            </div>
                        </div>
                        <div class="row3">
                            <a 
                                class="a-sty" 
                                style="font-size: 14px; font-family: var(--font-regular); text-decoration: underline;"
                                (click)="showUPCreditsPopup = false; toggleAnnualPrice = false; checkbox1.checked = false"
                            >
                                Show me all features
                            </a>
                        </div>
                    </div>
                    <div *ngIf="userActivePlan == 'grow-yearly-subscription'" class="order-offer-section">
                        <div class="row1">
                            <div class="left">
                                <a 
                                    class="a-sty" 
                                    (click)="showUPCreditsPopup = !showUPCreditsPopup;"
                                >
                                    Keep Grow yearly plan?
                                </a>
                                <span>20% OFF</span>
                            </div>
                            <div class="right"> 
                                <p>
                                    <span style="font-size: 22px; font-family: var(--font-medium);">${{getOfferPrice(upGrowYearlyPlanDetails.price, 20)}}</span>
                                    <span style="font-size: 16px; font-family: var(--font-regular);">/month</span>
                                </p>
                            </div>
                        </div>
                        <div class="row2" style="display: flex; justify-content: space-between; margin-top: 5px;">
                            <div class="left">
                                <p>5 tester/month included</p>
                                <p style="margin-top: 5px;">${{upGrowYearlyPlanDetails.additionalCredits}} per additional tester</p>
                            </div>
                            <div class="right">
                                <p style="font-size: 14px; font-family: var(--font-regular);">billed annually</p>
                            </div>
                        </div>
                        <div class="row3">
                            <a 
                                class="a-sty" 
                                style="font-size: 14px; font-family: var(--font-regular); text-decoration: underline;"
                                (click)="showUPCreditsPopup = false; toggleAnnualPrice = true; checkbox1.checked = true"
                            >
                                Show me all features
                            </a>
                        </div>
                    </div>
    
                </main>
    
                <button (click)="downgradeToFlex()" class="custom-popup-main-btn btn-sty-blue">Yes, downgrade to Flex</button>
            </ng-container>

        </div>

        <p 
            style="font-size: 18px; font-family: var(--font-medium); text-align:center; margin-top: 25px;"
            (click)="showUPCreditsPopup = !showUPCreditsPopup" class="a-sty"
        >
            {{ 
                creditsPopupDisplayContent == 'downgrade-to-flex'
                ? 
                    userActivePlan == 'grow-monthly-subscription'
                    ?
                        "Keep Grow monthly plan"
                    :
                        userActivePlan == 'grow-yearly-subscription'
                        ?
                            "Keep Grow yearly plan"
                        :
                            "Cancel"
                :
                    "Cancel" 
            }}
        </p>
    </div>
</div>

<form #subscriptionForm action="{{backendAPIEndpoint}}payment/stripe/create-subscription/" method="POST">
    <input type="hidden" name="user_id" [value]="currentUserId">
    <input type="hidden" name="lookup_key" [value]="subscriptionFormInputs['lookup_key']" />
    <input type="hidden" name="plan" [value]="subscriptionFormInputs['plan_type']" />
</form>  

<form #checkoutForm action="{{backendAPIEndpoint}}payment/stripe/create-checkout/" method="POST">
    <input type="hidden" name="user_id" [value]="currentUserId">
    <input type="hidden" name="product_name" [value]="checkoutFormInputs['product_name']" />
    <input type="hidden" name="credits" [value]="checkoutFormInputs['credits']" />
    <input type="hidden" name="price" [value]="checkoutFormInputs['price']" />
    <ng-container *ngIf="isOrderVideoFlow">
        <input type="hidden" name="test_count" [value]="checkoutFormInputs['test_count']" />
        <input type="hidden" name="createtest_id" [value]="checkoutFormInputs['createtest_id']" />
        <input type="hidden" name="device_type" [value]="checkoutFormInputs['device_type']" />
        <input type="hidden" name="project_id" [value]="checkoutFormInputs['project_id']" />
    </ng-container>
</form>  
