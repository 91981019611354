import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {TesterTestedListService} from 'src/app/shared/services/tester-tested-list.service';
import {environment} from 'src/environments/environment';
import videojs, {VideoJsPlayerOptions} from 'video.js';
import 'videojs-markers';

import 'videojs-seek-buttons';
import 'videojs-vjsdownload';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  baseUrl = environment.apiUrl;
  // baseUrl = "https://userpeek-speech-to-text.s3.us-east-2.amazonaws.com/";
  showFiller = false;
   selectedText: string = '';
   resultText: any;
   jumpToVideoSecond: any;

   @Input() transcribedData: any;
   @Input() resultData: any;
  @Input() selectedTestDetails: any;
  @Input() selectedTesterProfile: any;
  @Output() triggredEventInfo = new EventEmitter<any>();
  @Output() transcriptionDisplay: EventEmitter<any> = new EventEmitter();

  @ViewChild('target', {static: true}) target: ElementRef;

  player: videojs.Player;

  constructor(private _testedTestServices: TesterTestedListService) {
  }

  ngOnInit() {
    if (this.selectedTestDetails) {
      setTimeout(() => {
        this.createVideoJS();
      }, 0);
    }
  }

  createVideoJS() {
    // dispose videojs instance if any

    // create new instance of videojs
    let options: VideoJsPlayerOptions = {
      playbackRates: [0.5, 1, 1.5, 2, 5],
      fluid: false,
      liveui: false,
      poster: this.poster,

      plugins: {
        seekButtons: {
          forward: 5,
          back: 5,
        },
        vjsdownload: {
          beforeElement: 'playbackRateMenuButton',
          textControl: 'Download video',
          name: 'downloadButton',
        }
      },
      controls: true,
      sources: [
        {src: this.src, type: 'video/mp4'},
        {src: this.src, type: 'video/webm'},
      ]
    };

    // this.player = videojs(this.target.nativeElement, options, () => {
    //   console.log('Init Player')
    // })

    this._testedTestServices.player = videojs('demo', options, () => {
      videojs.log('Your player is ready!');

      console.log('Callback video-js initiated');
      this._testedTestServices.player.on('downloadvideo', function (e) {
        console.log('downloadvideo triggered');
      });

      setTimeout(() => {
        // Set Video marker
        this._testedTestServices.player.markers({
          markerStyle: {
            width: '7px',
            'border-radius': '30%',
            'background-color': 'red',
          },
          markerTip: {
            display: true,
            text: function (marker) {
              return 'note: ' + marker.text;
            },
          },
          markers: [],
          breakOverlay: {
            display: true,
            displayTime: 3,
            text: function (marker) {
              return 'This is an break overlay: ' + marker.text;
            },
          },
        });
        this.triggredEventInfo.emit('true');

        setTimeout(() => {
          // this.getAllMarker(true);
        }, 0);
      }, 0);
      this._testedTestServices.player.controlBar.addClass('report-video-fn');
      let reportButton =
        this._testedTestServices.player.controlBar.addChild('button');
      let reportButtonDom = reportButton.el();
      let that = this;
      reportButtonDom.innerHTML =
        "<span style='font-size: medium;top: -21px;color: white;'><i class='fa fa-exclamation-triangle'></i></span>";
      reportButtonDom.onclick = function () {

        window.open(
          `https://userpeek.com/submit-video-for-review/?videoid=${
            that.selectedTestDetails.id
          }&first=${that.selectedTesterProfile.first_name}&last=${that.selectedTesterProfile.last_name}&email=${that.selectedTesterProfile.email}`,
          '_blank'
        );
      };
    });
  }

  ngOnDestroy() {
    if (this._testedTestServices.player) {
      this._testedTestServices.player.dispose();
    }
  }

  get src(): string {
    return `${this.baseUrl}${this.selectedTestDetails.file}`
  }

  get poster(): string {
    return `${this.baseUrl}${this.selectedTestDetails.thumbnail_image}`;
  }

  transcriptionTextDisplay() {
    this.transcriptionDisplay.emit();
    this.showFiller = true;
    }

  showSelectedText() {
    if (window.getSelection) {
      this.selectedText = window.getSelection().toString().split(" " || ".")[0];
      if (this.resultData != undefined) {
        this.resultText = this.resultData.items.forEach(element => {
          if (element.alternatives[0].content === this.selectedText) {
            this.jumpToVideoSecond = element.start_time;
          }
        });
      }
    }
    this.getCurrentTime();
  }

  getCurrentTime() {
    this._testedTestServices.player.currentTime(this.jumpToVideoSecond);
    return this._testedTestServices.player.currentTime();
  }
}
