import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {StarRatingComponent} from 'ng-starrating';
import {TesterTestedListService} from "../../../../shared/services/tester-tested-list.service";
import {TestAndTesterDetails} from "../../services/interface";

@Component({
  selector: 'app-info-tab',
  templateUrl: './info-tab.component.html',
  styleUrls: ['./info-tab.component.scss']
})
export class InfoTabComponent implements OnInit, OnChanges {

  @Input()
  selectedTestDetails: any;

  testNTesterDetails: TestAndTesterDetails = {} as TestAndTesterDetails;

  constructor(
    private testerTestedListService: TesterTestedListService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('selectedTestDetails') && this.selectedTestDetails) {
      this.getDetails();
    }
  }

  onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {
    alert(`Old Value:${$event.oldValue},
      New Value: ${$event.newValue},
      Checked Color: ${$event.starRating.checkedcolor},
      Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }

  private getDetails() {
    this.testerTestedListService.getTestAndTesterDetails(this.selectedTestDetails.files).subscribe(response => {
      this.testNTesterDetails = response;
    });
  }
}
