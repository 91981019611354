<app-breadcrumbs-navbar></app-breadcrumbs-navbar>
<div class="test-dash-container">
    <div class="test-dash-content">
        <div class="test-dash-header">
            <h2>Tests</h2>
            <div class="test-dash-header-btns">
                <button (click)="openCreateTestPage()" class="btn-sty-blue">
                    <img  src="assets/images/icons/plus.svg" alt="plus icon"> Create test
                </button>
                <a class="btn-sty-blue-outline" (click)="viewTemplate()">View templates</a>
            </div>
        </div>
        <div class="test-dash-menu">
            <div class="test-dash-menu-text">Get productive.</div>
            <div>
                <app-all-filters></app-all-filters>
            </div>
            <!-- <div class="test-dash-menu-icons">
                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'all' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                    (click)="applyButtonFilterFun('all')">All projects ({{unfilteredJsonData.length}})</button>

                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'running' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                    (click)="applyButtonFilterFun('running')">Running ({{buttonFilterData.running}})</button>

                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'finished' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                    (click)="applyButtonFilterFun('finished')">Finished ({{buttonFilterData.finished}})</button>

                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'draft' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                    (click)="applyButtonFilterFun('draft')">Draft ({{buttonFilterData.draft}})</button>

                <button [ngStyle]="{'background-color': ( selectedChipFilter == 'archive' ) ? 'var(--light-blue)' : 'var(--light-grey)'}"
                    (click)="applyButtonFilterFun('archive')">Archived ({{buttonFilterData.archive}})</button>
            </div> -->
        </div>

        <!-- Table -->
        <div class="test-dash-table-container">

            <table class="test-dash-table">
                
                <tr class="title-row">
                    <th class="test-name-title">Test name</th>
                    <th class="date-title">Date</th>
                    <th class="videos-title">Videos</th>
                    <th class="status-title">Status</th>
                    <th class="button-title"></th>
                    <th class="action-title">Action</th>
                </tr>

                <ng-container *ngIf="isDataAvailable">
                    <tr class="data-row" *ngFor="let testItem of filteredJsonData; index as i">
                        <td class="test-name-data" (click)="openVideosRelatedToTest(testItem)">
                            <span>
                                {{ testItem.test_name }} 
                                <!-- {{ testItem.id }} -->
                            </span>
                        </td>
                        <td class="date-data">{{testItem.creation_date | date}}</td>
                        <td class="videos-data">{{testItem.completed_videos}} / {{testItem.total_required_videos}}</td>
                        <td class="status-data">
                            <ng-container *ngIf="testItem.test_status === 0 "><div><img src="assets/images/icons/blue-clock-new.svg" alt="plus icon">Running</div></ng-container>
                            <ng-container *ngIf="testItem.test_status === 2 "><div>Draft</div></ng-container>
                            <ng-container *ngIf="testItem.test_status === 3 "><div><img src="assets/images/icons/green-tick-new.svg" alt="plus icon">Finished</div></ng-container>
                            <ng-container *ngIf="testItem.test_status === 5 "><div><img src="assets/images/icons/archive-new.svg" alt="plus icon">Archived</div></ng-container>
                        </td>
                        <td class="button-data">
                            <!-- <button *ngIf="testItem.test_status === 4 &&  testItem.test_status != 0" (click)="openOrderVideoPopup(testItem)"  -->
                            <button 
                                *ngIf="testItem.test_status == 2 || testItem.test_status == 3" 
                                (click)="openOrderVideoPopup(testItem)" 
                                class="btn-sty-blue"
                            >
                                Order videos
                            </button>
                        </td>
                        <td class="action-data">
                            <div  (click)="togglePopup(testItem.id)" class="dash-context-menu-wrapper" [id]=testItem.id>
                                <img class="dash-context-menu-icon" src="assets/images/icons/menu-icon-new.svg" alt="icon">

                                <div class="dash-context-menu-cont" *ngIf="testItem.id == openPopupId ">
                                    <div class="dash-context-menu-content">
                                        <button  class="dash-context-menu-archive" (click)="openCustomPopup('rename')" 
                                            (click)="getRenameTestDetials(testItem)">Rename</button>
                                        <!-- <button  class="dash-context-menu-archive">Archive</button>
                                        <button  class="dash-context-menu-delete">Delete</button> -->
                                    </div>
                                </div>
                            </div>  
                        </td>
                    </tr>
                </ng-container>

            </table>

            <div *ngIf="isDataAvailable" class="pagination-cont">
                <p class="pagination-txt">{{paginateCount}} / {{queriedJsonData.length}} tests</p>
                <p class="pagination-btn" (click)="incrementPaginationCountFun(paginateCount + 10)">Load more</p>
            </div>

        </div>

        <div class="empty-data-cont" *ngIf="!isDataAvailable">
            <div class="empty-data-icon" style="margin-bottom: 25px;">
                <img src="assets/images/icons/cube-new-icon.svg" alt="cube icon">
            </div>

            <div class="empty-data-text" style="margin-bottom: 35px;">
                <p>You don't have any tests yet. </p>
                <p>Let's create a test to get started.</p>
            </div>

            <div class="empty-data-btn" style="margin-bottom: 35px;">
                <button class="btn-sty-blue" (click)="openCreateTestPage()">
                    <img src="assets/images/icons/plus.svg" alt="plus icon"> Create test
                </button>
            </div>

            <div class="empty-data-link">
                or <a style="text-decoration: underline;" class="a-sty" (click)="viewTemplate()">view templates</a>
            </div>
        </div>

    </div>

    <div class="test-dash-sidebar">
    
    </div>
</div>


    <div *ngIf="showOrderVideoPopup" class="custom-popup-backdrop">
        <div class="custom-popup-body">
            <img (click)="showOrderVideoPopup = !showOrderVideoPopup" class="custom-popup-close-icon" src="assets/images/icons/close-icon.svg" alt="close icon">
    
            <div class="custom-popup-content">
            
                <!-- order videos -->
                <div class="order-videos-cont">
    
                    <section class="header">
                        <h1>UserPeek Testers</h1>
                        <P>Order videos with testers from the UserPeek panel</P>
                    </section>
    
                    <main style="margin-top: 35px">
    
                        <div class="no-of-testers-cont">
                            <div class="no-of-testers-txt">
                                <p>Number of testers: </p>
                                <img src="assets/images/icons/info.svg" alt="info icon">
                            </div>
                            <div class="no-of-testers-input">
                                <input [(ngModel)]="videoCount" type="number" min="0" >
                            </div>
                            <div class="device-list-container">
                                <p style="margin: 0px;">Device : {{deviceType}}</p>
                                <!-- <label for="device-list">Device : </label> -->
                                <!-- <select id="device-list" [(ngModel)]="deviceType" >
                                    <option value="desktop" selected>Desktop</option>
                                    <option value="mobile">Mobile</option>
                                    <option value="tablet">Tablet</option>
                                </select> -->
                            </div>
                        </div>
    
                        <div class="order-detials-table" style="margin-top: 30px">
                            <table class="order-videos-table">
                                <tr class="order-title-row">
                                    <th style="padding: 10px;">Your order</th>
                                    <th style="padding: 10px;">{{videoCount}} testers</th>
                                </tr>
                                <tr *ngIf="videoCount > 0" class="order-data-row">
                                    <td style="padding: 10px;">
                                        <p>
                                            <!-- $ {{additionalCreditPlanDetials.price}} per tester {{ userActivePlan == "flexible" ? '(no subscription)' : "" }} -->
                                            $
                                            {{ upActivePlanDetails.additionalCredits }}
                                            per Tester
                                            <span *ngIf="userActivePlan == 'flexible'">(no subscription)</span>
                                            <span *ngIf="userActivePlan == 'grow-monthly-subscription'">(GROW monthly)</span>
                                            <span *ngIf="userActivePlan == 'grow-yearly-subscription'">(GROW yearly)</span>
                                        </p>
                                        <p>Available credits : {{currentUserCredit}}</p>
                                    </td>
                                    <td style="padding: 10px;">
                                        <p>
                                            $ {{ videoCount * upActivePlanDetails.additionalCredits }}
                                        </p>
                                        <p>
                                            {{currentUserCredit > 0 ? "-" : ""}} $ {{ videoCount > currentUserCredit ? currentUserCredit * upActivePlanDetails.additionalCredits : videoCount * upActivePlanDetails.additionalCredits}}
                                        </p>
                                    </td>
                                </tr>
                                <tr *ngIf="videoCount > 0" class="order-footer-row">
                                    <th style="padding: 10px;">Total</th>
                                    <th style="padding: 10px;">$ <ng-container *ngTemplateOutlet="totalPriceCalc1"></ng-container></th>
                                </tr>
                            </table>
                        </div>
    
                        <!-- <div class="order-offer-section">
                            <div class="row1">
                                <div class="left">
                                    <a class="a-sty" routerLink='/credits-plans'>Upgrade to Grow?</a>
                                    <span>23% OFF</span>
                                </div>
                                <div class="right"> 
                                    <span style="font-size: 22px; font-family: var(--font-medium);">$211</span>
                                    <span style="font-size: 16px; font-family: var(--font-regular);">/month</span>
                                </div>
                            </div>
                            <div class="row2">
                                <div class="left">5 credits à ${{planDetailGrowYearly.price}} per tester</div>
                                <div class="right">billed annually</div>
                            </div>
                            <div class="row3">
                                <a class="a-sty" routerLink='/credits-plans'>Show me all features</a>
                            </div>
                        </div> -->
                        <div *ngIf="userActivePlan == 'flexible'" class="order-offer-section">
                            <div class="row1">
                                <div class="left">
                                    <a class="a-sty" routerLink='/credits-plans'>Upgrade to Grow monthly?</a>
                                    <span>11% OFF</span>
                                </div>
                                <div class="right"> 
                                    <p>
                                        <span style="font-size: 22px; font-family: var(--font-medium);">${{getOfferPrice(upGrowMonthlyPlanDetails.price, 11)}}</span>
                                        <span style="font-size: 16px; font-family: var(--font-regular);">/month</span>
                                    </p>
                                </div>
                            </div>
                            <div class="row2" style="display: flex; justify-content: space-between; margin-top: 5px;">
                                <div class="left">
                                    <!-- <p>5 tester/month included</p> -->
                                    <p style="margin-top: 5px;">5 credits included, à ${{upGrowMonthlyPlanDetails.additionalCredits}} per tester</p>
                                </div>
                                <div class="right">
                                    <p style="font-size: 14px; font-family: var(--font-regular);">Cancel anytime</p>
                                </div>
                            </div>
                            <div class="row3">
                                <a class="a-sty" style="font-size: 14px; font-family: var(--font-medium);" routerLink='/credits-plans'
                                    (click)="showUPCreditsPopup = !showUPCreditsPopup; toggleAnnualPrice = false; checkbox1.checked = false"
                                >
                                    Yes, upgrade to Grow monthly plan
                                </a>
                            </div>
                        </div>
                        <div *ngIf="userActivePlan == 'grow-monthly-subscription'" class="order-offer-section">
                            <div class="row1">
                                <div class="left">
                                    <a 
                                        class="a-sty"
                                        (click)="showUPCreditsPopup = !showUPCreditsPopup; toggleAnnualPrice = true; checkbox1.checked = true"
                                    >
                                        Upgrade to Grow yearly?
                                    </a>
                                    <span>20% OFF</span>
                                </div>
                                <div class="right"> 
                                    <p>
                                        <span style="font-size: 22px; font-family: var(--font-medium);">${{getOfferPrice(upGrowYearlyPlanDetails.price, 20)}}</span>
                                        <span style="font-size: 16px; font-family: var(--font-regular);">/month</span>
                                    </p>
                                </div>
                            </div>
                            <div class="row2" style="display: flex; justify-content: space-between; margin-top: 5px;">
                                <div class="left">
                                    <!-- <p>5 tester/month included</p> -->
                                    <p style="margin-top: 5px;">5 tester/month included</p>
                                    <p style="margin-top: 5px;">${{upGrowYearlyPlanDetails.additionalCredits}} per additional tester</p>
                                </div>
                                <div class="right">
                                    <p style="font-size: 14px; font-family: var(--font-regular);">Annual billing</p>
                                </div>
                            </div>
                            <div class="row3">
                                <a class="a-sty" style="font-size: 14px; font-family: var(--font-medium);" routerLink='/credits-plans'
                                    (click)="showUPCreditsPopup = false; toggleAnnualPrice = true; checkbox1.checked = true"
                                >
                                    Yes, upgrade to Grow yearly plan
                                </a>
                            </div>
                        </div>
                        <div 
                            *ngIf="userActivePlan == 'grow-yearly-subscription'" 
                            class="order-offer-section"
                            style="background-color: #fff;"    
                        >
                        </div>                        
    
                    </main>
    
                    <button (click)="handleOrderVideos()" class="custom-popup-main-btn btn-sty-blue"
                        [disabled]="!enableOrderBtnvar" [ngClass]="[ enableOrderBtn() ? 'up-btn-active' : 'up-btn-inactive']">
                        Pay $ <ng-container *ngIf="videoCount > 0"><ng-container *ngTemplateOutlet="totalPriceCalc1"></ng-container></ng-container> {{ userActivePlan == "flexible" ? '(no subscription)' : "" }}
                    </button>
    
                    <p style="font-size: 18px; font-family: var(--font-medium); text-align:center; margin-top: 25px;"
                    (click)="showOrderVideoPopup = !showOrderVideoPopup" class="a-sty">Cancel</p>
    
                </div>
                
            </div>
    
        </div>
    </div>



    <div *ngIf="showUPCustomPopup" class="custom-popup-backdrop">
        <div class="custom-popup-body">
            <img (click)="showUPCustomPopup = !showUPCustomPopup" class="custom-popup-close-icon" src="assets/images/icons/close-icon.svg" alt="close icon">
            <div class="custom-popup-content">
                <ng-container *ngIf=" customPopupDisplayContent == 'rename' ">
                    <h2 class="custom-popup-title">Rename test</h2>
    
                    <label style="margin-top: 50px;"> test name</label>
                    <input (ngModelChange)="handleRenameValueChanges($event)" [(ngModel)]="renameInputValue" type="text" class="custom-popup-text-input">
                    <p *ngIf="(renameInputValue != renameExistingValue) && renameValueExist" style="margin-top:10px; color: var(--red); font-size: 14px; font-family: var(--font-regular);"> 
                        The test name already exists 
                    </p>
    
                    <button style="margin-top: 50px;" (click)="sendRenameRequest()" class="custom-popup-main-btn btn-sty-blue"
                        [ngClass]="[ (renameInputValue != renameExistingValue) && !renameValueExist && (renameInputValue.trim().length > 2)? 'up-custom-popup-btn-active' : 'up-custom-popup-btn-inactive']">
                        Rename
                    </button>
                </ng-container>
            </div>
            <p style="font-size: 18px; font-family: var(--font-medium); text-align:center; margin-top: 25px;"
            (click)="showUPCustomPopup = !showUPCustomPopup" class="a-sty">Cancel</p>
        </div>
    </div>

    <!-- <ng-template #priceCalc>
        {{ userActivePlan == "flexible" ? currentPlanDetails.price * videoCount : userActivePlan == "grow-monthly-subscription" ? 
                                        planDetailGrowMonthly.price * videoCount : userActivePlan == "grow-yearly-subscription" ?
                                        planDetailGrowYearly.price * videoCount : 0 }}
    </ng-template>

    <ng-template #totalPriceCalc>
        {{ userActivePlan == "flexible" ? currentPlanDetails.price * videoCount : userActivePlan == "grow-monthly-subscription" ? 
                                        planDetailGrowMonthly.price * videoCount : userActivePlan == "grow-yearly-subscription" ?
                                        (planDetailGrowYearly.price * videoCount) > (currentUserCredit * planDetailGrowYearly.price)?
                                        ((planDetailGrowYearly.price * videoCount) - (currentUserCredit * planDetailGrowYearly.price)):
                                        (0) : 0 }}
    </ng-template> -->

    <ng-template #totalPriceCalc1>
        {{ (videoCount * upActivePlanDetails.additionalCredits) > (currentUserCredit * upActivePlanDetails.additionalCredits) ?
            (videoCount * upActivePlanDetails.additionalCredits) - (currentUserCredit * upActivePlanDetails.additionalCredits) :
            0 }}
    </ng-template>

<script src='assets/js/custom.js'></script>