import { Injectable } from '@angular/core';
import {BaseService} from "../../../core/api-call/base.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {appApiResources} from "../../../shared/services/api.constant";
import {ClippedVideo, ClipVideo} from "./interface";

@Injectable({
  providedIn: 'root'
})
export class VideosService extends BaseService {

  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  getClippedVideos(video_url: string): Observable<ClippedVideo[]> {
    return this.post<ClippedVideo[]>(appApiResources.getClipVideos, {video_url: `media/${video_url}`});
  }

  clipVideo(data: ClipVideo): Observable<any> {
    return this.post<any>(appApiResources.clipVideo, data);
  }

}
