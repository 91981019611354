import {environment} from 'src/environments/environment';
import videojs from 'video.js';

const baseUrl = environment.apiUrl;

function createUrl(actionName: string): string {
  return `${baseUrl}${actionName}`;
}


export const appApiResources = {
  login: createUrl('user/login/'),
  registerClient: createUrl('user/registration/'),
  loginAsAdmin: createUrl('admin/admin_view_as_user/'),
  checkEmailExist: createUrl('profile/is_email_exists/'),
  passwordReset: createUrl('password_reset/'),
  userLogout: createUrl('user/logout/'),
  testTestedVideo: createUrl('test/tested_test/'),
  countryLangList: createUrl('profile/get_lang_country_devices/'),
  createTesterProfile: createUrl('profile/tester_profile/'),
  updateEmail: createUrl('user/update_email/'),
  userVerifyEmail: createUrl('user/verify_emails/'),
  validateUsername: createUrl('user/is_username_exists/'),
  activateEmail: createUrl('user/user_activation/'),

  // new change email
  changeEmail: createUrl('user/change_email/'),
  validateOTP2: createUrl('user/update_email_v2/'),

  // create project
  createProject: createUrl('test/project/'),

  /** Test API */
  test: createUrl('test/newtest/'),
  testProjectList: createUrl('test/project_list/'),
  testArchive: createUrl('test/test_archive/'),
  testRestoreToDraft: createUrl('test/restore_status_to_draft/'),
  testVideoSetup: createUrl('test/video_setup/'),
  testNewVideo: createUrl('test/new_video/'),
  testByProject: createUrl('test/get_test_by_project/'),
  testReport: createUrl('test/reports/'),


  testVideoList: createUrl('test/video_list/'),
  testBookmarks: createUrl('test/bookmarks/'),
  testRating: createUrl('test/rating/'),
  testDescription: createUrl('test/test_description/'),
  testVideoByProject: createUrl('test/get_video_by_project/'),
  testMarkedWatched: createUrl('test/mark_watched/'),
  testAndTesterDetails: createUrl('test/test_and_tester_details/'),
  getVideoJob: createUrl('processor/speech_to_text/'),
	speechToText: createUrl('processor/transcribed_text/'),

  /** Admin Test */
  adminTemplateManagement: createUrl('admin/template_management/'),
  adminExampleVideo: createUrl('admin/example_video/'),

  /** Payment API's */
  paymentCustomer: createUrl('fastspring/customer'),
  paymentProducts: createUrl('fastspring/products'),
  paymentCoupon: createUrl('fastspring/coupon'),
  paymentSession: createUrl('fastspring/session'),
  paymentOrder: createUrl('fastspring/order'),
  downgradePlan: createUrl('fastspring/downgrade'),
  newPlanDetails: createUrl('fastspring/products'),

  /** Videos API's */
  getClipVideos: createUrl('processor/get_clip_videos/'),
  clipVideo: createUrl('processor/clips/'),
  clipStatus: createUrl('processor/clips/status/'),
  deleteClipVideo: createUrl('processor/clips/'),

  /** Highlight Reels*/
  createhighlightReel: createUrl('processor/highlight_reels'),
  getAllHighlightReels: createUrl('processor/highlight_reels'),
  getAllClips: createUrl('processor/get_all_clips/'),
  // new highlight reels
  highlightReelsWithProjectId: createUrl('processor/highlight_reels'),


  /** new changes */
  fastspringCallback: createUrl('fastspring/order_v2'),
  deductCredit: createUrl("fastspring/deduct_credit"),

  transcribeTaskList: createUrl('processor/transcribe/'),

  // New APIs
  testWithProjectId: createUrl('test/'),
  videosWithProjectId: createUrl('test/videos/'),
  clipsWithProjectId: createUrl('test/clips/'),

  //new highlight reel api
  newHighlightReel: createUrl('processor/highlight_reels'),


};


export const appGlobalModelMessage: any = {
  testCreateHeaderMsg: 'Test created!',
  testCreateBodyMsg: ' test name is Created.',
  testUpdateHeaderMsg: 'Test updated!',
  testUpdateBodyMsg: ' test name is updated.',
  requiredFieldErrorMsg: 'Please fill in the required fields',
  maximumTaskErrorMsg: 'You can not add more than 15 Task.',
};

export const convertToHHMMSS = (timeData?) => {
  let sec_num: any = parseInt(timeData, 10); // don't forget the second param
  let hours: any = Math.floor(sec_num / 3600);
  let minutes: any = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds: any = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return minutes + ':' + seconds;

}

export const parseTimeFunction = (s) => {
  let c = s.split(':');
  return parseInt(c[0]) * 60 + parseInt(c[1]);
}

// @note jump to current time
export const navigateToCurrentTimeStatmp = (time, isTaskTimestamp?) => {
  console.log('1️⃣1️⃣1️⃣1️⃣ time', time);
  var jump = videojs('demo');
  let converttimeFormat = time.substring(0, 2) + ':' + time.substring(2, 4);
  console.log('2️⃣2️⃣2️⃣2️⃣ converttimeFormat', converttimeFormat);
  var pieces: any = isTaskTimestamp ? time.split(':') : converttimeFormat.split(':');
  console.log('3️⃣3️⃣3️⃣3️⃣3️⃣ pieces', pieces);
  let result = Number(pieces[0]) * 60 + Number(pieces[1]);
  console.log('4️⃣4️⃣4️⃣4️⃣ result', result);
  jump.play();
  return jump.currentTime(result);
}

export const getTestStatusCode = (actionStatus: number): string => {
  let running = 'Running';
  let draft = 'pending';
  let cancel = 'CANCELLED';
  let pending = 'pending';
  let finish = 'FINISHED';
  let confirmatioRequired = 'CONFIRMATION REQUIRED';
  let archive = 'Archive';
  let statusCode = '';

  switch (actionStatus) {
    case 0:
      statusCode = running;
      break;
    case 1:
      statusCode = pending;
      break;
    case 2:
      statusCode = draft;
      break;
    case 3:
      statusCode = finish;
      break;
    case 5:
      statusCode = archive;
      break;
    case 6:
      statusCode = cancel;
      break;
    case 7:
      statusCode = confirmatioRequired;
      break;
    default:
      break;
  }

  return statusCode;
}






