import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'searchProject'
})
export class SearchProjectPipe implements PipeTransform {

  transform(list: any[], filterText: string): any {
    return list ? list.filter(item => item.project_name.search(new RegExp(filterText, 'i')) > -1) : [];
  }

}
