import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModelBoxService {
  isModelShow = new BehaviorSubject<boolean>(false);
  modelBoxHeader: string;
  modelBoxBody: string;
  componentStatus: string;
  isGettingError: boolean;

  constructor(
    private toastr: ToastrService
  ) {
  }

  showSuccessToster(tosterMsg?, status?) {
    this.toastr.success(tosterMsg, status);
  }

  showErrorToster(tosterMsg?, status?) {
    this.toastr.error(tosterMsg, status);
  }

  notesAndClipModelMsg(status, msg, isError?) {
    if (status) {
      this.showSuccessToster(`Clip ${msg}`);
    } else {
      this.showSuccessToster(`Note ${msg}`);
    }
  }

  showModelBox(isSuccess, modelBody?, modelBoxHeader?, modelBoxBody?, componentStatus?) {

    // this.destroyModelCurrentValue();
    if (isSuccess) {
      this.modelBoxHeader = modelBoxHeader
      this.modelBoxBody = modelBoxBody ? modelBody + modelBoxBody : modelBody
      this.componentStatus = componentStatus;
      this.isGettingError = false;
      this.isModelShow.next(true);

    } else {
      this.isGettingError = true;
      this.modelBoxBody = modelBody;
      this.componentStatus = '';
      this.isModelShow.next(true);
    }
  }
}
