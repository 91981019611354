<form (ngSubmit)="orderTestEventTrigger('orderTest')" [formGroup]='testerCount'>
  <h4>How many videos do I need?</h4>
  <div class='row test-details-row'>
    <div class='col-md-5'>
      <h5>Sessions</h5>
      <div class='range-container'>
        <div class='range-sub'>
          <img alt='icon' class='range-icon' src='assets/images/icons/screen-icon.png'/>
          <label class='value'><span id='screenvalue'>
                            <input (keyup)='DesktoponKey($event)' [(ngModel)]='Desktopvalue' class='value' formControlName='desktop_tester_count'
                                   style='border: 1px'
                                   type='text'/>
                        </span></label>
          <div class='range-input-sub'>
            
            <ng5-slider (userChange)='calculatePercent()' (userChangeEnd)='validateOrderCredits()'
                        (valueChange)='DesktopvalueChange($event)' [(value)]='Desktopvalue'
                        [options]='options' formControlName='desktop_tester_count' id='myRange'>
            </ng5-slider>

          </div>
        </div>

        <div class='range-sub'>
          <img alt='icon' class='range-icon' src='assets/images/icons/mobile-icon.png'/>
          <label class='value'>
            <input (keyup)='MobileonKey($event)' [(ngModel)]='Mobilevalue' class='value' formControlName='mobile_tester_count'
                   max='15' style='border: 0px'
                   type='text'/>

            <span id='screentablet'></span></label>
          <div class='range-input-sub'>
            <ng5-slider (userChange)='calculatePercent()' (userChangeEnd)='validateOrderCredits()' (valueChange)='MobilevalueChange($event)'
                        [(value)]='Mobilevalue' [options]='options'
                        formControlName='mobile_tester_count' id='tablet'>
            </ng5-slider>

          </div>
        </div>

        <div class='range-sub'>

          <img alt='icon' class='range-icon' src='assets/images/icons/tablet-icon.png'/>
          <label class='value'>
            <input (keyup)='onKey($event)' [(ngModel)]='value' class='value' formControlName='tab_tester_count'
                   max='15' style='border: 0px' type='text'/>
            <span id='screenmobile'></span></label>
          <div class='range-input-sub'>
            <ng5-slider (userChange)='calculatePercent()' (userChangeEnd)='validateOrderCredits()' (valueChange)='valueChange($event)' [(value)]='value'
                        [options]='options' formControlName='tab_tester_count'
                        id='mobile'>
            </ng5-slider>

          </div>
        </div>
      </div>
    </div>
    <div class='col-md-7'>
      <h5>Average percentage of problems revealed</h5>
      <div class='progress-sub'>
        <div class='progress'>
          <div [style.width]='averageDesktopPercentage' aria-valuemax='100' aria-valuemin='0' aria-valuenow='96'
               class='progress-bar' role='progressbar'></div>
        </div>
        <span class='sr-only'><span class='percentange'>{{
          averageDesktopPercentage
          }}</span>
                    Desktop</span>
      </div>
      <div class='progress-sub'>
        <div class='progress'>
          <div [style.width]='averageMobilePercentage' aria-valuemax='100' aria-valuemin='0' aria-valuenow='84'
               class='progress-bar' role='progressbar'></div>
        </div>
        <span class='sr-only'><span class='percentange'>{{
          averageMobilePercentage
          }}</span>
                    Mobile</span>
      </div>
      <div class='progress-sub'>
        <div class='progress'>
          <div [style.width]='averageTabPercentage' aria-valuemax='100' aria-valuemin='0' aria-valuenow='averageTabPercentage'
               class='progress-bar' role='progressbar'></div>
        </div>
        <span class='sr-only'><span class='percentange'>{{
          averageTabPercentage
          }}</span>
                    Tablet</span>
      </div>
    </div>
  </div>
  <div class='row'>
    <div class='col-12 test-details-button text-right'>
      <button (click)="orderTestEventTrigger('closeOpenModel')" class='detail-close' style='margin-right: 5px'>
        Close
      </button>

      <button class='order-count' type='submit'>
        Order {{ orderVideoCount }} videos
      </button>
    </div>
  </div>
</form>
