import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  role: string;

  constructor(private _authServices: AuthService, private _router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._authServices.loggedIn())
      return true;
    else {
      this._router.navigate(['/login'])
    }
  }

  // canActivate():boolean{
  //   if(this._authServices.loggedIn()){
  //     return true
  //   } else {
  //     this._router.navigate(['/login'])
  //     return false
  //   }
  // }

}
