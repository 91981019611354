import {Component, Input, OnInit} from '@angular/core';
import {TesterTestedListService} from 'src/app/shared/services/tester-tested-list.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-video-item',
  templateUrl: './video-item.component.html',
  styleUrls: ['./video-item.component.scss']
})
export class VideoItemComponent implements OnInit {
  baseURL = environment.apiUrl;

  @Input() videoItemDetails: any;
  @Input() testerProfileList: any;
  @Input() ItemRouterLink: any;

  headerProjectName: any;
  hashtagsList: any = [];

  constructor(
    private _testedTestServices: TesterTestedListService,
  ) {
  }

  get setVideoItemRouterLink() {
    let videoInfoURL = '';
    let videoId = this.videoItemDetails.id;
    let testId = this.videoItemDetails.test;
    let parmaArray = [];
    if (this.testerProfileList) {
      videoInfoURL = '/videos/videos_info';
      parmaArray.push(videoInfoURL, videoId, testId)
    } else {
      videoInfoURL = '/videos/example-video';
      parmaArray.push(videoInfoURL, videoId)
    }
    return parmaArray;
  }

  ngOnInit() {
    this.videoItemDetails
    this.testerProfileList;
    this.initHashTag();
  }

  initHashTag(){
    let tempHashtagList = this.videoItemDetails.hastags;
      for(let i = 0; i < tempHashtagList.length; i++){
        let tempvar: any = [];
        if (i == (tempHashtagList.length -1)){
            tempvar = tempHashtagList[i];
        } else{
            tempvar = tempHashtagList[i]+",";
        }
        this.hashtagsList.push(tempvar)
    }
  }

  getTestedVideoId(testId, projectName, id) {
    localStorage.setItem('testedTestId', id);
    this._testedTestServices.sendTestId(testId);
    this.headerProjectName = projectName;
    localStorage.setItem('projectName', projectName);
    this._testedTestServices.watchVideo(id).subscribe((res) => {
    });
  }

  getTestedVideoIdProjectName() {
    this._testedTestServices.sendTestIdProjectName(this.headerProjectName);
  }

}
