<div class='tests-container account lg-screen-width'>
  <aside class='tests-sidebar'>
    <app-sidebar></app-sidebar>

  </aside>
  <section class='section test-section' id='all-parent'>
    <router-outlet></router-outlet>
    <ng-template #noItems>
      <div class='invoices-container'>
        <div class='account-invoices-container'>
          <p style='text-align: center;'>You have no invoices yet. <a class='completedprofile-link'
                                                                      routerLink='/creditPlan'> You can easily buy
            a plan it here.</a></p>
        </div>
      </div>
    </ng-template>
    <ng-container *ngIf='noInvoice; else noItems'>
      <div *ngFor='let invoice of listOfInvoices' class='invoices-container'>
        <div class='account-invoices-container'>

          <h4>Invoices</h4>
          <div class='your-invoices'>Your invoices are sent to your account email. <span class='change'>change</span>
          </div>
          <!-- <div class='mastercard'>Credit card: XXXX XXXX XXXX 5405 <span class='cardtype'>MASTERCARD</span> update</div> -->
          <div class='row record'>
            <div class='col'><span class='date'>{{invoice.created_on | date}}</span> <span class='credit-name'>3x Test Credit</span>
            </div>
            <div class='col-auto'><span class='price'>{{invoice.subtotalDisplay}} </span> <span class='download'><a
              href='https://spring.test.onfastspring.com/account/order/{{invoice.reference}}/invoice' target='_blank'><i
              aria-hidden='true' class='fa fa-download'></i></a></span></div>
          </div>
        </div>
      </div>
    </ng-container>
  </section>
  <button class='feedback-btn'>Feedback</button>
</div>
