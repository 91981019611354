import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardClientService {
  private apiData = new BehaviorSubject<any>('Maria');
  public apiData$ = this.apiData.asObservable();

  constructor(private _authServices: AuthService) {


  }

  // this is for Login  Api Response Data
  setData(data) {

    this.apiData.next(data)

  }


}
