
import { Component, OnInit, OnChanges } from '@angular/core';
import { HighlightReelApiService } from 'src/app/shared/services/highlight-reel-api.service';
import { Router } from '@angular/router';
import { HighlightReelUpdateService } from 'src/app/shared/services/highlight-reel-update.service';
import { CreateNewTestService } from 'src/app/shared/services/create-new-test.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-highlight-reel',
  templateUrl: './highlight-reel.component.html',
  styleUrls: ['./highlight-reel.component.scss']
})

export class HighlightReelComponent implements OnInit {


  search3: any;
  searchText: string;
  baseUrl = environment.apiUrl;
  // apiPrefix: string = "https://userpeek-speech-to-text.s3.us-east-2.amazonaws.com/";
  // apiPrefix1: string = "https://userpeek-speech-to-text.s3.us-east-2.amazonaws.com/media/";
  showPopupMenu: string = "none";
  reelsList: object;
  setPreviewPopupDisplayProperty: string = "none";
  previewClipsListData: any;
  setPreviewVideoData: string;
  displayUI: any;
  allProjectList = [];
  selectedProjectId: any;
  singleIndex: any;
  filterProjectList: any;
  totaltest: any;
  filterTestNodata: any;


  constructor(private _highlightReelsServices: HighlightReelApiService,
              private _router: Router,
              private _createnewTestServices: CreateNewTestService,
              private _highlightReelUpdateService: HighlightReelUpdateService) {}

  ngOnInit(){
    this._highlightReelsServices.getAllHighlightReels().subscribe((res) => {
      this.reelsList = res;
      console.log(this.reelsList)
    });

    // store tab selection for page reload
    let displayUIVar = localStorage.getItem("displayUI");
    if( displayUIVar == null){
      localStorage.setItem("displayUI", "all-reels");
      this.displayUI =  localStorage.getItem("displayUI");
    } else {
      this.displayUI =  localStorage.getItem("displayUI");
    }

    this.getAllProjectList();
  }

  getAllProjectList() {
    this.allProjectList = [];
    this._createnewTestServices.gelAllProjectList().subscribe((res) => {
      res.data.filter((res) => {
        if (res.total_test >= 1) {
          this.allProjectList.push(res);
        }

  
      });
    });
  }

  showTestID(project_id, i) {
    this.selectedProjectId = project_id;
    this.singleIndex = i;
    this._createnewTestServices
      .getProjectRelatededList(project_id)
      .subscribe((res) => {
        this.filterProjectList = res['data'];
        this.totaltest = res['data'].length;
        if (res['data'].length === 0) {
          this.filterTestNodata = false;
        } else {
          this.filterTestNodata = true;
        }
      });
  }

  handleTabSelection(event){
    localStorage.setItem("displayUI", event);
    this.displayUI =  localStorage.getItem("displayUI");
  }

  showPopupMenuFun(event){

   

    let tempElement = event.target.parentElement.parentElement.children[1].style.display;
    if(tempElement == "none"){

      // close previous opened popup menu
      let allElements = document.getElementsByClassName("reel-menu-option-container");
      for (let i = 0; i < allElements.length; i++) {
          allElements.item(i).setAttribute("style", "display: none");
      }

      // open popup menu on clicked card
      event.target.parentElement.parentElement.children[1].style.display = "block";
      console.log("opening now.....")

    } else{

      // close popup menu after clicking again
      event.target.parentElement.parentElement.children[1].style.display = "none";
      console.log("trying to close .....")
    }

  }


  onEditClick(indexValue){
    let x = this.reelsList[indexValue];
    console.log(x);
    this._highlightReelUpdateService.storeData(x);
    this._router.navigate(["/highlight_reel/update_highlight_reel"]);

  }

  onPreviewClick(indexValue){
    if (this.setPreviewPopupDisplayProperty == "none"){
      
      // close popup menu
      let allElements = document.getElementsByClassName("reel-menu-option-container");
      for (let i = 0; i < allElements.length; i++) {
          allElements.item(i).setAttribute("style", "display: none");
      }

      // open preview video popup window
      this.setPreviewPopupDisplayProperty = "block";

      // pass clipslist from highlight reel page to preview popup
      this.previewClipsListData = this.reelsList[indexValue]["clip_video_list"];
      console.log("data in preview popup",this.previewClipsListData);

      // set video url
      let tempPreviewVideoData = this.previewClipsListData[0];
      this.setPreviewVideoData = this.baseUrl+tempPreviewVideoData["clip_path"];
      console.log(this.setPreviewVideoData);

      let videoContainer = document.getElementById("h-r-preview-video-main");
      let videoElement = document.createElement("video");
      videoElement.id = "preview-video-player";
      videoElement.controls = true;
      videoElement.src =  this.setPreviewVideoData;
      videoContainer.appendChild(videoElement);
      document.getElementById("preview-video-player").style.cssText = `
        width: 95%;
        height: auto;
        margin: 0px auto;
        display: block;
      `;
      videoElement.load();

    } else if(this.setPreviewPopupDisplayProperty == "block"){

      this.setPreviewPopupDisplayProperty = "none";
    
    }
  }

  handleClipClick(indexValue,event){
    let tempPreviewVideoData = this.previewClipsListData[indexValue]
    this.setPreviewVideoData = this.baseUrl+tempPreviewVideoData["clip_path"];
    console.log(this.setPreviewVideoData);


    document.getElementById("preview-video-player").remove();
    let videoContainer = document.getElementById("h-r-preview-video-main");
    let videoElement = document.createElement("video");
    videoElement.id = "preview-video-player";
    videoElement.controls = true;
    videoElement.src =  this.setPreviewVideoData;
    videoContainer.appendChild(videoElement);
    document.getElementById("preview-video-player").style.cssText = `
      width: 95%;
      height: auto;
      margin: 0px auto;
      display: block;
    `;
    videoElement.load();

  


    // remove highlight from all clips
    let allElements = document.getElementsByClassName("clip-list-item");
    for (let i = 0; i < allElements.length; i++) {
        allElements.item(i).setAttribute("style", "background-color: #f3f3f3");
    }
    // Hightlight on clicked clip
    event.currentTarget.setAttribute("style","background-color: rgb(217, 217, 217)");

    
  }

  closeVideoPopup(){
    this.setPreviewPopupDisplayProperty = "none";
    document.getElementById("preview-video-player").remove();
  }
  

}

