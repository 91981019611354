<div class='tests-container account lg-screen-width'>
  <aside class='tests-sidebar'>
    <app-sidebar></app-sidebar>
  </aside>
  <section class='section test-section'>
    <!-- <ng-template #noItems>
      <section class='section test-section p-0'>
        <div class='billing-container'>
          <div class='billing-sub mt-o'>
            <p class="text-center">
              {{isAccountCreateMsg[0]}}<a
              class='completedprofile-link'
              routerLink='/creditPlan'
            >
              {{isAccountCreateMsg[1]}}</a
            >
            </p>
          </div>
        </div>
      </section>
    </ng-template> -->

    <ng-container>
      <form
        (ngSubmit)='updateCustomer()'
        [formGroup]='creditCustomerPlan'
        action='credit'
        class='credit-form'
      >
        <div class='billing-container'>
          <h4>Billing</h4>

          <div class='billing-sub'>
            <h5>Personal Information</h5>
            <div formGroupName='contact'>
              <label>First name</label>
              <input
                formControlName='first'
                placeholder='Enter First Name'
                type='text'
              />
              <div
                *ngIf="
                  (submitted &&
                    creditCustomerPlan['controls'].contact['controls'].first
                      .errors) ||
                  creditCustomerPlan.get('contact.first').touched
                "
                class='invalidfeedback'
              >
                <div
                  *ngIf="
                    creditCustomerPlan['controls'].contact['controls'].first
                      .errors?.required ||
                    creditCustomerPlan['controls'].contact['controls'].first
                      .errors?.first
                  "
                >
                  <small class='text-danger'>First name is required</small>
                </div>
              </div>
              <label>Last name</label>
              <input
                formControlName='last'
                placeholder='Businesswoman'
                type='text'
              />
              <div
                *ngIf="
                  (submitted &&
                    creditCustomerPlan['controls'].contact['controls'].last
                      .errors) ||
                  creditCustomerPlan.get('contact.last').touched
                "
                class='invalidfeedback'
              >
                <div
                  *ngIf="
                    creditCustomerPlan['controls'].contact['controls'].last
                      .errors?.required ||
                    creditCustomerPlan['controls'].contact['controls'].last
                      .errors?.last
                  "
                >
                  <small class='text-danger'>Last name is required</small>
                </div>
              </div>

              <label>Email</label>
              <input
                formControlName='email'
                placeholder='Businesswoman'
                type='text'
              />
              <div
                *ngIf="
                  (submitted &&
                    creditCustomerPlan['controls'].contact['controls'].email
                      .errors) ||
                  creditCustomerPlan.get('contact.email').touched
                "
                class='invalidfeedback'
              >
                <div
                  *ngIf="
                    creditCustomerPlan['controls'].contact['controls'].email
                      .errors?.required ||
                    creditCustomerPlan['controls'].contact['controls'].email
                      .errors?.email
                  "
                >
                  <small class='text-danger'>Email is required</small>
                </div>
              </div>
              <label>Company</label>
              <input
                formControlName='company'
                placeholder='Userpeek'
                type='text'
              />
              <div
                *ngIf="
                  (submitted &&
                    creditCustomerPlan['controls'].contact['controls'].company
                      .errors) ||
                  creditCustomerPlan.get('contact.company').touched
                "
                class='invalidfeedback'
              >
                <div
                  *ngIf="
                    creditCustomerPlan['controls'].contact['controls'].company
                      .errors?.required ||
                    creditCustomerPlan['controls'].contact['controls'].company
                      .errors?.company
                  "
                >
                  <small class='text-danger'>Company name is required</small>
                </div>
              </div>
              <label>Phone</label>
              <input formControlName='phone' placeholder='Phone' type='text'/>

              <div
                *ngIf="
                  (submitted &&
                    creditCustomerPlan['controls'].contact['controls'].phone
                      .errors) ||
                  creditCustomerPlan.get('contact.phone').touched
                "
                class='invalidfeedback'
              >
                <div
                  *ngIf="
                    creditCustomerPlan['controls'].contact['controls'].phone
                      .errors?.required ||
                    creditCustomerPlan['controls'].contact['controls'].phone
                      .errors?.phone
                  "
                >
                  <small class='text-danger'>Phone is required</small>
                </div>
              </div>
            </div>

            <label>Country</label>
            <select class='country' formControlName='country' name='country'>
              <option disabled hidden value="">Select country</option>

              <option
                *ngFor='let residence of residences'
                [ngValue]='residence'
              >
                {{ residence }}
              </option>
            </select>
            <div
              *ngIf="
                (submitted && creditCustomerPlan['controls'].country.errors) ||
                creditCustomerPlan.get('country').touched
              "
              class='invalidfeedback'
            >
              <div
                *ngIf="
                  creditCustomerPlan['controls'].country.errors?.required ||
                  creditCustomerPlan['controls'].country.errors?.country
                "
              >
                <small class='text-danger'>country is required</small>
              </div>
            </div>

            <label>Language</label>
            <select class='country' formControlName='language' name='language'>
              <option disabled hidden value="">Select language</option>

              <option *ngFor='let lang of languagecode' [ngValue]='lang'>
                {{ lang }}
              </option>
            </select>
            <div
              *ngIf="
                (submitted && creditCustomerPlan['controls'].language.errors) ||
                creditCustomerPlan.get('language').touched
              "
              class='invalidfeedback'
            >
              <div
                *ngIf="
                  creditCustomerPlan['controls'].language.errors?.required ||
                  creditCustomerPlan['controls'].language.errors?.language
                "
              >
                <small class='text-danger'>language is required</small>
              </div>
            </div>
          </div>
          <div class='billing-sub' formGroupName='address'>
            <h5>Billing Address</h5>
            <label>Address1</label>
            <input
              formControlName='addressLine1'
              placeholder='Testingcompany LTD'
              type='text'
            />
            <div
              *ngIf="
                (submitted &&
                  creditCustomerPlan['controls'].address['controls']
                    .addressLine1.errors) ||
                creditCustomerPlan.get('address.addressLine1').touched
              "
              class='invalidfeedback'
            >
              <div
                *ngIf="
                  creditCustomerPlan['controls'].address['controls']
                    .addressLine1.errors?.required ||
                  creditCustomerPlan['controls'].address['controls']
                    .addressLine1.errors?.address['controls'].addressLine1
                "
              >
                <small class='text-danger'>AddressLine1 is required</small>
              </div>
            </div>
            <label class='optional'>optional</label>
            <label>Address2</label>
            <input
              formControlName='addressLine2'
              placeholder='Business Street 123'
              type='text'
            />
            <label>City</label>
            <input
              formControlName='city'
              placeholder='Enter your city'
              type='text'
            />

            <label>Postal Code</label>
            <input
              formControlName='postalCode'
              placeholder='12345'
              type='text'
            />
            <div
              *ngIf="
                (submitted &&
                  creditCustomerPlan['controls'].address['controls'].postalCode
                    .errors) ||
                creditCustomerPlan.get('address.postalCode').touched
              "
              class='invalidfeedback'
            >
              <div
                *ngIf="
                  creditCustomerPlan['controls'].address['controls'].postalCode
                    .errors?.required ||
                  creditCustomerPlan['controls'].address['controls'].postalCode
                    .errors?.address['controls'].postalCode
                "
              >
                <small class='text-danger'>Postal code is required</small>
              </div>
            </div>
            <label>Region</label>
            <input
              formControlName='region'
              placeholder='Enter your region'
              type='text'
            />
          </div>
          <div class='billing-sub'>
            <button
              class='save'
              data-target='#saveChange'
              data-toggle='modal'
              type='submit'
            >
              Update Account
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </section>

  <!-- <button class='feedback-btn'>Feedback</button> -->
</div>

