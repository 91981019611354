import { AfterViewInit, Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { SelectedVideoDetailsService } from '../selected-video-details.service';
import { UpStoreSelectedVidDetialsService } from 'src/app/shared/services/up-store-selected-vid-detials.service';
import { environment } from 'src/environments/environment';
import { TesterTestedListService } from 'src/app/shared/services/tester-tested-list.service';
import { GeneralApiService } from 'src/app/core/api-call/general-api.service';
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';
import { UpVideoPlayerService } from 'src/app/shared/services/up-video-player.service';

@Component({
  selector: 'app-view-video-details',
  templateUrl: './view-video-details.component.html',
  styleUrls: ['./view-video-details.component.scss']
})

export class ViewVideoDetailsComponent implements OnInit {

  // @ViewChild('sidebar') sidebar!: ElementRef;

  localstorageShowCreateClipPopup: boolean = true;
  showCreateHashtagPopup: boolean = false;
  showCreateNotePopup: boolean = false;
  showAddEmotionPopup: boolean = false;
  showCreateClipConfirmationPopup: boolean = false;
  showTranscriptContextMenu: boolean = false;
  showAdditionalHashtagPopup: boolean = false;
  showContextMenuDialogBox: string = "";


  hashtagInputVar: any = "";
  notesInputVar: any = "";
  videodetails: any = {};
  tab: number = 1;
  selectedVideoDetials: any;
  baseUrl = environment.apiUrl;
  ratingList: Array<number> = [1,2,3,4,5];
  rating: number = 0;
  tempRatingStore: any;
  contextMenuX: number = 0;
  contextMenuY: number = 0;
  hashtagOriginalArray: any = [];
  hashtagArrayToDisplay: any = [];
  temporarilySelectedHashtag: any = [];
  videoFindingsArray: any = [];
  additionalHashtagArray: any = [];
  selectedVideoDetails: any = [];
  transcriptDictionary:any = {};
  transcriptArray:any = [];
  userSelectedText: string = "";
  paraLineCountArray: any = [];
  start_and_end_dict: any = {};


  constructor(
    private _selectedVideoDetailsService: SelectedVideoDetailsService,
    private _upStoreSelectedVidDetialsService: UpStoreSelectedVidDetialsService,
    private testedTestListServices: TesterTestedListService,
    private generalApi: GeneralApiService,
    private toastMsgService: ToastMsgService,
    private _UpVideoPlayerService: UpVideoPlayerService,
    ) {
      this._UpVideoPlayerService.createClipListenerValue.subscribe((time_dict)=>{
        console.log("entry point time_dict: ",time_dict)
        this.start_and_end_dict = {
          "start_time": time_dict["start_time"],
          "end_time": time_dict["end_time"]
        };
        this.createClipFromPlayer(time_dict["start_time"]);
      })
  }

  ngOnInit(): void {
    let tempJsonData = localStorage.getItem("cacheSelectedVideoDetials");
    if(tempJsonData != undefined || tempJsonData != null){
      this.selectedVideoDetails = JSON.parse(tempJsonData);
      this.getTranscript();
      this.getFindings();
      this.handleHashtagArray();
    } else {
      this.toastMsgService.showMsg("failed", "video data not found!", 3000);
    }
  }



  // close view note popup if clicked outside.
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    // close view notes popup
    if((event.target as HTMLElement).closest(".view-note-popup") == null){
      if((event.target as HTMLElement).closest(".findings-card-note") == null){
        let view_notes_popup_list = document.getElementsByClassName("view-note-popup");
        Array.prototype.forEach.call(view_notes_popup_list, function(item) {
          if(item.style.display === "block"){
            item.style.display = "none";
          }
        });
      }
    }
    // close edit hashtag dialog
    if((event.target as HTMLElement).closest(".findings-card-hashtag") == null){
      if((event.target as HTMLElement).closest(".edit-hashtag-container") == null){
        if(((event.target as HTMLElement).className !== "edit-hashtag-span-btn")){
          console.log('event.target :', (event.target as HTMLElement).className)
          let hashtagDialog = document.getElementsByClassName("edit-hashtag-container");
          Array.prototype.forEach.call(hashtagDialog, function(item) {
            if(item.style.display === "block"){
              item.style.display = "none";
            }
          });
        }
      }
    }
    // close tester info popup
    if((event.target as HTMLElement).closest(".tester-name-title") == null){
      if((event.target as HTMLElement).closest(".tester-profile-popup-content") == null){
        let tester_profile_popup = document.getElementById("tester-profile-popup-content") as HTMLDivElement;
        if(tester_profile_popup !== null){
          if(tester_profile_popup.style.display === "block" || tester_profile_popup.style.display === ""){
            tester_profile_popup.style.display = "none";
          }
        }
      }
    }
    // close transcript text selection context menu
    if((event.target as HTMLElement).closest(".transcript-body") == null){
      if((event.target as HTMLElement).closest(".transcript-context-menu") == null){
        console.log('event.target clicked now : ',event.target)
        this.showTranscriptContextMenu = false;
      }
    }
    if((event.target as HTMLElement).closest(".findings-body") != null){
      console.log('event.target clicked now : ',event.target)
      this.showTranscriptContextMenu = false;
    }
  }

    // HostListener to detect keydown events
    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
      // console.log('keyboard event listener called')
      if (event.key === 'Enter') {
        if(this.temporarilySelectedHashtag.length > 0){
          // console.log('add hashtag called')
          this.addHashtag()
        }
      }
      else if(event.ctrlKey && event.key === 'p'){
        event.preventDefault();
        this.playVideo()
      }
    }


  showContextMenu(event: MouseEvent) {
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      // clear values
      // note 
      this.notesInputVar = "";
      this.showCreateNotePopup = false;
      // clip
      this.showCreateClipConfirmationPopup = false;
      // emotion
      this.showAddEmotionPopup = false;
      //hashtag
      this.showCreateHashtagPopup = false;
      this.temporarilySelectedHashtag = [];
      this.hashtagInputVar = "";
      this.showContextMenuDialogBox = "";
      // context menu btn and dialog box styles reset
      this.highlightSelectContextBtn();


      this.userSelectedText = window.getSelection().toString().trim();
      let user_selected_text_array = this.userSelectedText.split("\n");
      let user_selected_text_processed = user_selected_text_array.join(" ");
      this.userSelectedText = user_selected_text_processed;

      try{
        // set current video time
        // get start time from selection
        let video_start_time = this.getStartTime("video_current_time_set")
        console.log('video_start_time:', video_start_time)
        if(video_start_time !== undefined){
          var parts = video_start_time.split(":");
          var minutes = parseInt(parts[0], 10);
          var seconds = parseInt(parts[1], 10);
          var result = (minutes * 60) + seconds;
          console.log('before sending: ', result);
          this._UpVideoPlayerService.setVideoCurrentTime(result);
        }
      } catch(e){
        console.error("-----------------")
        console.error(e)
        console.error("-----------------")
      }


      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      this.showTranscriptContextMenu = true;
      this.contextMenuX = event.clientX - 50;
      this.contextMenuY = rect.top + window.scrollY - 40;
    } else {
      this.showTranscriptContextMenu = false;
      window.getSelection().removeAllRanges();
    }
  }



  getTranscript(){

    let body = {
      "id": this.selectedVideoDetails.id
    }
    this.generalApi.getSelectedVideoTranscript(body).subscribe((api_response)=>{
      this.transcriptDictionary = api_response.data;
      // store dict into array
      Object.values(this.transcriptDictionary ).map((item) => {
        this.transcriptArray.push(item);
      });
      // console.log('transcriptArray', this.transcriptArray)
      this.getNoOfLineInfo();
    });
  }

  breakString(inputString) {
      const maxLength = 49;
      const resultArray = [];
      
      while (inputString.length > maxLength) {
          let breakIndex = maxLength;
          while (breakIndex >= 0 && inputString[breakIndex] !== ' ') {
              breakIndex--;
          }
          
          if (breakIndex <= 0) {
              // If no space found before maxLength, break at maxLength
              breakIndex = maxLength;
          }
          
          resultArray.push(inputString.substring(0, breakIndex).trim());
          inputString = inputString.substring(breakIndex).trim();
      }
      
      if (inputString.length > 0) {
          resultArray.push(inputString.trim());
      }
      
      return resultArray;
  }


  getNoOfLineInfo(){
    this.paraLineCountArray = [];
    for(let i = 0; i < this.transcriptArray.length; i++){
      let text_content = this.transcriptArray[i]["text"]["transcript"];
      let textArray = [];
      textArray = this.breakString(text_content)
      let temp_json = {
        "task": i+1,
        "no_of_lines": textArray.length,
        "lines_in_array": textArray,
        "transcript": text_content
      };
      this.paraLineCountArray.push(temp_json);
    }
    console.log('+++++++++++++++++transcript with line info++++++++++++++++++++')
    console.log(this.paraLineCountArray)
    console.log('+++++++++++++++++++++++++++++++++++++')
  }

  getLineNoAndTaskNo(input_params){
    let result = {};
    let line_no = 0;
    let task_no = 0;
    let processed_input_str = ""
    let processed_str = "";
    
    if(input_params != ""){
      let input_str1 = input_params.replace('\n', ' ').replace('/\n/g', ' ').replace(/[,.?']/g, '').trim().toLowerCase();
      let input_str5 =  input_str1.split(" ");

      // shorter the user selected to 7 words
      if(input_str5.length <= 7){
        processed_input_str = input_str5.join(" ");
      } else {
        let temp_array1 = [];
        for(let i = 0;  i < 7; i++){
          temp_array1.push(input_str5[i]);
        }
        processed_input_str = temp_array1.join(" ");
      }
      processed_input_str = processed_input_str.trim().toLowerCase();

      // to find particular line no short the user selected text to 4 words
      let str_array =  processed_input_str.split(" ");
      if(str_array.length <= 4){
        processed_str = str_array.join(" ");
      } else {
        let temp_array1 = [];
        for(let i = 0;  i < 4; i++){
          temp_array1.push(str_array[i]);
        }
        processed_str = temp_array1.join(" ");
      }

      processed_str = processed_str.trim().toLowerCase()
      console.log('11111111111111111111111111111111111')
      console.log('processed_str:', processed_str)
      console.log('processed_input_str:', processed_input_str)
      console.log('11111111111111111111111111111111111')
  
  
      for(let i = 0; i < this.paraLineCountArray.length; i++){
        let paragraph = this.paraLineCountArray[i]["transcript"];
        let paragraph1 = paragraph.replace(/[,.']/g, '');
        let paragraph2 = paragraph1.toLowerCase();
        console.log('222222222222222222222222')
        console.log('paragraph2: ', paragraph2)
        console.log('222222222222222222222222')
          // find task
          if(paragraph2.includes(processed_input_str.toLowerCase())){
              // console.log('task: ', this.paraLineCountArray[i]["task"]);
              task_no = this.paraLineCountArray[i]["task"];
              console.log('3333333333333333333333')
              console.log('task_no: ', task_no)
              console.log('33333333333333333333333')
              // find line no
              for(let j = 0; j < this.paraLineCountArray[i]["lines_in_array"].length; j++){
                let line = this.paraLineCountArray[i]["lines_in_array"][j];
                let line1 = line.replace(/[,.']/g, '');
                let line2 = line1.toLowerCase();
                  if(line2.includes(processed_str)){
                      console.log('4444444444444444444')
                      console.log(line2)
                      console.log('line no: ', j+1);
                      console.log('4444444444444444444')
                      line_no = j+1;
                      break;
                  }
              }
              break;
          }
      }
      result = {
        "line_no": line_no,
        "task_no": task_no
      }
    } else {
      result = {
        "line_no": 0,
        "task_no": 0
      }
    }
    return result;
  }

  get_verified_line_and_task_no(input_var){
    let line_and_task_no = this.getLineNoAndTaskNo(input_var);
    return line_and_task_no;
    // if(line_and_task_no["line_no"] === 0){
    //     // Split the string by whitespace into an array of words
    //     let words = input_var.split(' ');
    //     // Remove the first word using slice()
    //     words = words.slice(1);
    //     // Join the remaining words back into a string
    //     let result_str = words.join(' ');
    //     let temp_result = this.getLineNoAndTaskNo(result_str);
    //     // // console.log(temp_result)
    //     return temp_result;
    // } else {
    //     // // console.log(line_and_task_no)
    //     return line_and_task_no;
    // }
}


  getFindings(){
    this.generalApi.getfindings(this.selectedVideoDetails.id).subscribe((api_response)=>{
      this.videoFindingsArray = api_response["data"];
    })
  }
  findingsDataAvailable(){
    let result = false;
    this.videoFindingsArray.forEach((item)=>{
      if(item.lines.length > 0){
        result = true;
      }
    })
    return result;
  }

  getStyleinfo(task=0, line_no=0){
    // // console.log(`task: ${task} | line_no: ${line_no}`)
    let div_margin_top = 70;
    let line_height = 23.8;
    let result = 0;
    if (task > 1){
      // let preDivLineCount = this.paraLineCountArray.filter((item1)=>{return item1["task"] == (task - 1)});
      let previous_tasks_line_count = 0;
      let previous_tasks_count = 1;
      for(let i = 0; i < this.paraLineCountArray.length; i++){
        // // console.log(this.paraLineCountArray[i]["task"])
        if(previous_tasks_count < task){
            if(this.paraLineCountArray[i]["task"] === previous_tasks_count){
                // // console.log(this.paraLineCountArray[i]["task"])
                previous_tasks_line_count = previous_tasks_line_count + this.paraLineCountArray[i]["lines_in_array"].length;
                previous_tasks_count++;
                // // console.log(previous_tasks_count)
            }
        }
      }
      result = (div_margin_top * task) + (line_height*(line_no-1)) + (line_height * previous_tasks_line_count);
    } else {
      result = (div_margin_top * task) + (line_height*(line_no-1));
    }
    let result1 = `${result}px`
    // // console.log('result1', result1)
    return result1;
  }


  sortVideoDetails(){
    this.videoFindingsArray.sort(function(a, b){
      let a1 = Number(a["start_time"].replace(/:/g, ""));
      let b1 = Number(b["start_time"].replace(/:/g, ""));
      return a1 - b1;
    });
  }


  ShowContent(value: number) {
    this.tab = value;
  }

  GetTestRatting(id: number) {
    // console.log(this.rating)
    this.testedTestListServices.getRating(id).subscribe((res) => {
      
      this.tempRatingStore = res;
      this.rating = this.tempRatingStore.data[0].rating;
      
    })
  }

  RateTheTest(value: number) {
    let body = {
      'feed_back': ' ',
      'id': this.selectedVideoDetials.id,
      'test': this.selectedVideoDetials.test,
      'rating': value
    }
    this.testedTestListServices.addRating(body).subscribe((res) => {
      // console.log(res.status)
      if(res.status == 200) {
        this.GetTestRatting(this.selectedVideoDetials.id);
      }
    })
  }

  loadClipsAndNotes(){
    this._selectedVideoDetailsService.initializeNotesAndClips("call function");
  }

  // Findings tab

  // ====== close the transcript and finding tab
  closeSidebar(){
    let sidebar = document.getElementById("right-section-content") as HTMLDivElement;
    sidebar.style.display = "none";
    let miniSidebar = document.getElementById("right-section-min-sidebar") as HTMLDivElement;
    miniSidebar.style.display = "block";
    let viewVideoDetailsContainer = document.getElementById("vid-dash-player-container") as HTMLDivElement;
    viewVideoDetailsContainer.style.alignItems = "center";
    let leftSection = document.getElementById("left-section") as HTMLDivElement;
    leftSection.style.width = "";
    leftSection.style.height = `${window.innerHeight - 150}px`;
    leftSection.style.marginLeft = "auto";
    leftSection.style.marginRight = "auto";
    leftSection.style.alignItems = "center";
    // let vid_dash_player_container = document.getElementById("vid-dash-player-container") as HTMLDivElement;
    // vid_dash_player_container.style.justifyContent = "center";
    // leftSection.classList.add("left-section-ratio-on");
    // let leftSection1 = document.getElementById("left-section");
    // leftSection1.style.aspectRatio = "16 / 9";
  }
  openSidebar(){
    let sidebar = document.getElementById("right-section-content") as HTMLDivElement;
    sidebar.style.display = "block";
    let miniSidebar = document.getElementById("right-section-min-sidebar") as HTMLDivElement;
    miniSidebar.style.display = "none";
    let viewVideoDetailsContainer = document.getElementById("vid-dash-player-container") as HTMLDivElement;
    viewVideoDetailsContainer.style.alignItems = "flex-start";
    let leftSection = document.getElementById("left-section") as HTMLDivElement;
    leftSection.style.width = "calc(100vw - 737px)";
    leftSection.style.height = "";
    leftSection.style.marginLeft = "";
    leftSection.style.marginRight = "";
    leftSection.style.alignItems = "flex-start";
    // let vid_dash_player_container = document.getElementById("vid-dash-player-container") as HTMLDivElement;
    // vid_dash_player_container.style.justifyContent = "flex-start";
    // leftSection.classList.remove("left-section-ratio-off");
    // let leftSection1 = document.getElementById("left-section");
    // leftSection1.style.aspectRatio = "unset";
  }

  // ------------ Hashtag functionality ------------------

  handleHashtagArray(){
    this.generalApi.getHashtagList(this.selectedVideoDetails.test).subscribe((api_response: any)=>{
      if(api_response["message"] === 200 || api_response["message"] === 201 ){
        let tempHashtagArray = [];
        if(api_response.data.length > 0){
          api_response.data.forEach((item)=>{
            if(!tempHashtagArray.some((obj) => obj.tag.trim().toLowerCase() === item.tag)){
              tempHashtagArray.push(item)
            }
          });
        }
        // console.log('tempHashtagArray', tempHashtagArray)
        this.hashtagOriginalArray = tempHashtagArray;
        this.hashtagArrayToDisplay = tempHashtagArray;
      } else {
        this.toastMsgService.showMsg("failed", "retrieve hashtag list failed", 3000);
      }
    })
  }

  handleHashtagSearch(search_item){
    console.log('search_item', search_item);
    let tempArray = [];
    this.hashtagOriginalArray.forEach((hashtag_item)=>{
      if(hashtag_item.tag.toLowerCase().includes(search_item.toLowerCase())){
        tempArray.push(hashtag_item);
      }
    });
    this.hashtagArrayToDisplay = tempArray;
  }

  storeSelectedHashtag(item){
    // if(!this.temporarilySelectedHashtag.includes(item)){
    //   this.temporarilySelectedHashtag.push(item);
    // }
    if(!this.temporarilySelectedHashtag.some((obj) => obj.tag.trim().toLowerCase() === item.tag)){
      this.temporarilySelectedHashtag.push(item);
    }
  }

  removeSelectedHashtag(item){
    let tempArray = [];
    tempArray = this.temporarilySelectedHashtag.filter((item2) => { return item2.tag !== item.tag});
    this.temporarilySelectedHashtag = tempArray;
  }

  addHashtag(){
    let line_and_task_number_json = this.get_verified_line_and_task_no(this.userSelectedText)
    let start_time  = this.getStartTime("hashtag");
    if(this.temporarilySelectedHashtag.length > 0){
      if(start_time !== undefined){
        if(line_and_task_number_json["line_no"] !== 0 && line_and_task_number_json["task_no"] !== 0){
          let hashtag_id_list = [];
          this.temporarilySelectedHashtag.forEach((hashtag_obj)=>{
            hashtag_id_list.push(hashtag_obj.id)
          })
          let body = {
            "start": start_time,
            "line_no": line_and_task_number_json["line_no"],
            "task": line_and_task_number_json["task_no"],
            "tested_test": this.selectedVideoDetails.id,
            "hastags_ids": hashtag_id_list,
            "transcript_text": this.userSelectedText,
          }
          this.generalApi.addHashtag(body).subscribe((api_response)=>{
            if(api_response.status === 200 || api_response.status === 201 ){
              this.toastMsgService.showMsg("success", "Hashtag added successfully.")
            } else {
              this.toastMsgService.showMsg("failed", "Hashtag creation failed", 3000);
            }
            this.getFindings();
            this.showTranscriptContextMenu = false;
          });
        } else {
          this.toastMsgService.showMsg("failed", "line no error, please try again", 3000);
        }
      } else {
        this.toastMsgService.showMsg("failed", "start time error, Please try again", 3000);
      }
    } else {
      this.toastMsgService.showMsg("failed", "Please select atleast one hashtag", 4000);
    }
  }

  createNewHashtag(){
    if(!this.hashtagArrayToDisplay.includes(this.hashtagInputVar)){
      if(this.hashtagInputVar !== ""){
        let body = {
          "test": this.selectedVideoDetails.test,
          "tag": this.hashtagInputVar
        }
        this.generalApi.createHashtag(body).subscribe((api_response)=>{
          if(api_response.message === 200 || api_response.message === 201 ){
            this.toastMsgService.showMsg("success", "New Hashtag created successfully.");
          } else {
            this.toastMsgService.showMsg("failed", "New Hashtag creation failed", 3000);
          }
          this.hashtagInputVar = "";
          this.handleHashtagArray()
        });
      } else {
        this.toastMsgService.showMsg("failed", "Please enter a new hashtag", 3000);
      }
    } else {
      this.toastMsgService.showMsg("failed", "Hashtag already exists", 3000);
    }
  }

  giveLimitedHashtag(item){
    let newArray = [];
    let oldArray = item;
    let charCount = 0;
    for(let i = 0; i <= oldArray.length -1; i++){
      let char = oldArray[i];
      charCount = charCount + char.length;
      if(charCount <= 12){
        newArray.unshift(oldArray[i]);
      }
      else{
        break;
      }
    }
    return newArray;
  }

  remainingHashtagCount(item){
    let newArray = [];
    let oldArray = item;
    let charCount = 0;
    for(let i = 0; i <= oldArray.length -1; i++){
      let char = oldArray[i];
      charCount = charCount + char.length;
      if(charCount <= 12){
        newArray.unshift(oldArray[i]);
      } 
      else{
        break;
      }
    }
    let result = oldArray.length - newArray.length;
    return result;
  }

  handleEditHashtag(id, event, hashtagList){
    // add select hashtag items to temporarilySelectedHashtag array
    let result_array = []
    hashtagList.forEach((hashtagText)=>{
      let tempList = this.hashtagOriginalArray.filter((item1) => { return item1.tag === hashtagText});
      result_array.push(tempList[0])
    });
    this.temporarilySelectedHashtag = result_array;

    let editHashtagElement = document.getElementById(id);
    if(editHashtagElement.style.display === "block"){
      // close selected dialog box
      editHashtagElement.style.display = "none";
    } else {
      // open dialog box
      // close all previously opened dialog box
      let view_note_list = document.getElementsByClassName("edit-hashtag-container");
      Array.prototype.forEach.call(view_note_list, function(item) {
        item.style.display = "none";
      });
      // check to display dialog on top or bottom of menu
      let boundryValues = event.target.getBoundingClientRect();
      if(boundryValues.top < 250 ){
        editHashtagElement.style.top = "150%"
      }
      // open selected dialog box
      editHashtagElement.style.display = "block";

      let text_input = document.getElementById("edit-hashtag-text-input") as HTMLInputElement;
      text_input.focus();
      text_input.focus();
      text_input.focus();
      text_input.focus();
      text_input.disabled = false;
      text_input.contentEditable = 'true';

    }
  }

  updateHashtag(hashtag_details){
    console.log('hashtag_details', hashtag_details)
    let start_time  = hashtag_details["start"];
    if(this.temporarilySelectedHashtag.length > 0){
      if(start_time !== undefined){
        let hashtag_id_list = [];
        this.temporarilySelectedHashtag.forEach((hashtag_obj)=>{
          hashtag_id_list.push(hashtag_obj.id)
        })
        let body = {
          "association_meta_id": hashtag_details["id"],
          "start": start_time,
          "tested_test": this.selectedVideoDetails.id,
          "hastags_ids": hashtag_id_list
        }
        this.generalApi.updateHashtag(body).subscribe((api_response)=>{
          if(api_response.status === 200 || api_response.status === 201 ){
            this.toastMsgService.showMsg("success", "Hashtag update successfully.")
          } else {
            this.toastMsgService.showMsg("failed", "Hashtag update failed", 3000);
          }
          // update findings
          this.getFindings();
          // close all edit hashtag popup
          let hashtagDialog = document.getElementsByClassName("edit-hashtag-container");
          Array.prototype.forEach.call(hashtagDialog, function(item) {
            if(item.style.display === "block"){
              item.style.display = "none";
            }
          });
        });
      } else {
        this.toastMsgService.showMsg("failed", "start time error, Please try again", 3000);
      }
    } else {
      this.toastMsgService.showMsg("failed", "Please select atleast one hashtag", 4000);
    }
  }


  // ------------------ Notes ---------------------

  handleNoteSave(){
    if(this.notesInputVar !== ""){
      let line_and_task_number_json = this.get_verified_line_and_task_no(this.userSelectedText)
      let start_time  = this.getStartTime("note");
      if(start_time !== undefined){
        if(line_and_task_number_json["line_no"] !== 0 && line_and_task_number_json["task_no"] !== 0){
          let body = {
            "text": this.notesInputVar,
            "start": start_time,
            "line_no": line_and_task_number_json["line_no"],
            "task": line_and_task_number_json["task_no"],
            "tested_test": this.selectedVideoDetails.id,
            "test": this.selectedVideoDetails.test,
            "transcript_text": this.userSelectedText,
          }
          this.generalApi.saveNotes(body).subscribe((api_response)=>{
            if(api_response.message === 200 || api_response.message === 201 ){
              this.toastMsgService.showMsg("success", "Notes added successfully.")
            } else {
              this.toastMsgService.showMsg("failed", "Notes creation failed", 3000);
            }
            this.showTranscriptContextMenu = false;
            this.getFindings();
          });
        } else {
          this.toastMsgService.showMsg("failed", "line no error, please try again", 3000);
        }
      } else {
        this.toastMsgService.showMsg("failed", "start time error, Please try again", 3000);
      }
    } else {
      this.toastMsgService.showMsg("failed", "Please enter some notes", 3000);
    }
    this.showTranscriptContextMenu = false;
    window.getSelection().removeAllRanges();
  }

  handleKeyPress(type,event){

    // console.log('event', event)
    if(event.key === 'Enter'){
      if(type === "note"){
        this.handleNoteSave()
      } 
      else if(type === "hashtag"){
        this.addHashtag()
      }
    }
  }

  handleEditNotes(i,j,task,line_no,start_time,note_id){
    // console.log(i,j, line_no, start_time, note_id)
    let textareaElement = document.getElementById(`view-note-textarea-${i}${j}`) as HTMLTextAreaElement;
    let buttonElement = document.getElementById(`view-note-btn-${i}${j}`);
    // console.log(buttonElement.innerHTML)
    if(buttonElement.innerHTML === "Edit"){
      buttonElement.innerHTML = "Update";
      // textareaElement.disabled = false;
      // textareaElement.style.pointerEvents = "auto";
      // textareaElement.contentEditable = "true";
      textareaElement.focus();
      textareaElement.focus();
    } 
    else {
      buttonElement.innerHTML = "Edit";
      textareaElement.focus();
      // textareaElement.disabled = true;
      // textareaElement.style.pointerEvents = "none";
      let updated_text = textareaElement.value;
      // update text call
      let body = {
        "test": this.selectedVideoDetails.test,
        "tested_test": this.selectedVideoDetails.id,
        "start": start_time,
        "text": updated_text,
        "line_no": line_no,
        "task": task,
      }
      this.generalApi.updateNote(body, note_id).subscribe((api_response)=>{
        if(api_response.message === 200){
          this.toastMsgService.showMsg("success", "Notes updated successfully.")
        } else {
          this.toastMsgService.showMsg("failed", "Notes updation failed", 3000);
        }
        this.showTranscriptContextMenu = false;
        this.getFindings();
      })
    }
  }

  handleViewNote(id, event){
    let boundryValues = event.target.getBoundingClientRect();

    let viewNotePopupElement = document.getElementById(id);
    let textareaElement = document.getElementById(`view-note-textarea-${id}`) as HTMLTextAreaElement;
    if(viewNotePopupElement.style.display === "block"){
      viewNotePopupElement.style.display = "none";
    } else {
      let view_note_list = document.getElementsByClassName("view-note-popup");
      Array.prototype.forEach.call(view_note_list, function(item) {
        item.style.display = "none";
      });
      if(boundryValues.top < 220 ){
        viewNotePopupElement.style.top = "150%"
      }
      viewNotePopupElement.style.display = "block";
    }
  }

  // Emotions
  handleAddEmotion(emotion_type){
    // console.log(emotion_type)
    let line_and_task_number_json = this.get_verified_line_and_task_no(this.userSelectedText)
    let start_time  = this.getStartTime("sentiment");
    if(start_time !== undefined){
      if(line_and_task_number_json["line_no"] !== 0 && line_and_task_number_json["task_no"] !== 0){
        let body = {
          "test": this.selectedVideoDetails.test,
          "tested_test": this.selectedVideoDetails.id,
          "type": emotion_type,
          "start": start_time,
          "task": line_and_task_number_json["task_no"],
          "line_no": line_and_task_number_json["line_no"],
          "transcript_text": this.userSelectedText
        }
        this.generalApi.addEmotion(body).subscribe((api_response)=>{
          if(api_response.message === 200 || api_response.message === 201 ){
            this.toastMsgService.showMsg("success", "Emotion added successfully.")
            // this.getFindings();
            // this.showTranscriptContextMenu = false;
          } else {
            this.toastMsgService.showMsg("failed", "Emotion creation failed", 3000);
          }          
          // this.toastMsgService.showMsg("success", "Emotion added successfully.");
          this.getFindings();
          this.showTranscriptContextMenu = false;
        });
      } else {
        this.toastMsgService.showMsg("failed", "line no error, please try again", 3000);
      }
    } else {
      this.toastMsgService.showMsg("failed", "start time error, Please try again", 3000);
    }
    this.showTranscriptContextMenu = false;
    window.getSelection().removeAllRanges();
  }

  //============================== create clip =============================

  handleCreateClip(input_type){
    if(input_type === "icon"){
      let show_dialog_box = localStorage.getItem("show_create_clip_dialog");

      if(show_dialog_box !== null || show_dialog_box !== undefined){
        if(show_dialog_box === "false"){
          this.createClipSendPostRequest()
        } else {
          let create_clip_dialog = document.getElementById("context-clip-dialog") as HTMLDivElement;
          create_clip_dialog.style.display = "block";
        }
      }
      // if(this.localstorageShowCreateClipPopup == true){

      //   this.showCreateClipConfirmationPopup = true;
      //   this.showContextMenuDialogBox = 'clip';
      // } else if(this.localstorageShowCreateClipPopup === false){
      //   this.createClipSendPostRequest()
      // }
    } else if(input_type === "popup"){
      let checkbox_state = document.getElementById("create-clip-confirmation-checkbox") as HTMLFormElement;
      if(checkbox_state.checked === true){
        // this.localstorageShowCreateClipPopup = false;
        localStorage.setItem("show_create_clip_dialog", "false");
      }
      this.createClipSendPostRequest()
    }
  }


  createClipSendPostRequest(){
    let line_and_task_number_json = this.get_verified_line_and_task_no(this.userSelectedText)
    let clip_duration_json = this.getStartAndEndTime();

    if(clip_duration_json["start"] !== undefined && clip_duration_json["end"] !== undefined){
      if(line_and_task_number_json["line_no"] !== 0 && line_and_task_number_json["task_no"] !== 0){
        let body = {
          "video_url": this.selectedVideoDetails.file,
          "clip": {
            "start": clip_duration_json["start"],
            "end": clip_duration_json["end"],
            "notes": ""
          },
          "line_no": line_and_task_number_json["line_no"],
          "task": line_and_task_number_json["task_no"],
          "transcript_text": this.userSelectedText,
        }
        console.log('body', body)
        this.generalApi.createClip(body).subscribe((api_response)=>{
          if(api_response.status === 200 || api_response.status === 201 ){
            this.toastMsgService.showMsg("success", "clip created successfully.")
          } else {
            this.toastMsgService.showMsg("failed", api_response.message, 3000);
          }
          this.getFindings();
          this.showTranscriptContextMenu = false;
        });
      } else {
        this.toastMsgService.showMsg("failed", "line no error, please try again", 3000);
      }
    } else {
      this.toastMsgService.showMsg("failed", "start time error, Please try again", 3000);
    }
    window.getSelection().removeAllRanges();
    this.showTranscriptContextMenu = false;
  }


  getStartAndEndTime(){
    // cleanup user selected text (remove . , " ' () \n )
    let cleanedUserSelectedText = this.userSelectedText.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase();
    console.log('cleanedUserSelectedText', cleanedUserSelectedText)

    // user selected text converted to array
    let userSelectedTextArrayFormat = cleanedUserSelectedText.split(" ");

    if(userSelectedTextArrayFormat.length >= 8){  // Checking word count for validation

      let clipStartDuration = undefined;
      let clipEndDuration = undefined;

      for(let key in this.transcriptDictionary){
        // cleanup every task transcript (remove . , " ' () \n )
        let task_transcript = this.transcriptDictionary[key].text.transcript.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase();
        
          if(task_transcript.includes(cleanedUserSelectedText)){ // if user selected text match the current task transcript.
            // words in array
              let transcriptWordsArray = this.transcriptDictionary[key].text.items;

              // start time calculate
              let startTimeSecondWordIndex = undefined;
              let startTimeThirdWordIndex = undefined;

              for(let indexItem = 0; indexItem < transcriptWordsArray.length; indexItem++){
                  // get 1st word
                  if(transcriptWordsArray[indexItem].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase() === userSelectedTextArrayFormat[0]){
                    console.log('start time 1st word found : ', transcriptWordsArray[indexItem].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
                    // 2nd word
                    if(transcriptWordsArray[indexItem+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[1])){
                      console.log('start time 2nd word found (1) : ', transcriptWordsArray[indexItem+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
                      startTimeSecondWordIndex = indexItem+1;
                    }
                    else if (transcriptWordsArray[indexItem+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[1])){
                      console.log('start time 2nd word found (2) : ', transcriptWordsArray[indexItem+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
                      startTimeSecondWordIndex = indexItem+2;
                    }
                    // 3rd word
                    if(startTimeSecondWordIndex != undefined){
                      if(transcriptWordsArray[startTimeSecondWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[2])){
                        console.log('start time 3rd word found (1) : ', transcriptWordsArray[startTimeSecondWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
                        startTimeThirdWordIndex = startTimeSecondWordIndex+1;
                      }
                      else if (transcriptWordsArray[startTimeSecondWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[2])){
                        console.log('start time 3rd word found (2) : ', transcriptWordsArray[startTimeSecondWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
                        startTimeThirdWordIndex = startTimeSecondWordIndex+2;
                      }
                    }
                    // 4th word
                    if(startTimeThirdWordIndex != undefined){
                      if(transcriptWordsArray[startTimeThirdWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[3])){
                        console.log('start time 4th word found (1) : ', transcriptWordsArray[startTimeThirdWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
                        clipStartDuration = new Date(Math.floor(parseFloat(transcriptWordsArray[indexItem].start_time)) * 1000).toISOString().slice(14, 19);
                        break;
                      }
                      else if (transcriptWordsArray[startTimeThirdWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[3])){
                        console.log('start time 4th word found (2) : ', transcriptWordsArray[startTimeThirdWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
                        clipStartDuration = new Date(Math.floor(parseFloat(transcriptWordsArray[indexItem].start_time)) * 1000).toISOString().slice(14, 19);
                        break;
                      }
                    }
                  }
              }



              // transcriptWordsArray.forEach((currentWord, index) => {
              //   // get 1st word
              //   if(currentWord.alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase() === userSelectedTextArrayFormat[0]){
              //     console.log('start time 1st word found : ', currentWord.alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
              //     // 2nd word
              //     if(transcriptWordsArray[index+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[1])){
              //       console.log('start time 2nd word found (1) : ', transcriptWordsArray[index+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
              //       startTimeSecondWordIndex = index+1;
              //     }
              //     else if (transcriptWordsArray[index+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[1])){
              //       console.log('start time 2nd word found (2) : ', transcriptWordsArray[index+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
              //       startTimeSecondWordIndex = index+2;
              //     }
              //     // 3rd word
              //     if(startTimeSecondWordIndex != undefined){
              //       if(transcriptWordsArray[startTimeSecondWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[2])){
              //         console.log('start time 3rd word found (1) : ', transcriptWordsArray[startTimeSecondWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
              //         startTimeThirdWordIndex = startTimeSecondWordIndex+1;
              //       }
              //       else if (transcriptWordsArray[startTimeSecondWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[2])){
              //         console.log('start time 3rd word found (2) : ', transcriptWordsArray[startTimeSecondWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
              //         startTimeThirdWordIndex = startTimeSecondWordIndex+2;
              //       }
              //     }
              //     // 4th word
              //     if(startTimeThirdWordIndex != undefined){
              //       if(transcriptWordsArray[startTimeThirdWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[3])){
              //         console.log('start time 4th word found (1) : ', transcriptWordsArray[startTimeThirdWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
              //         clipStartDuration = new Date(Math.floor(parseFloat(currentWord.start_time)) * 1000).toISOString().slice(14, 19);
              //       }
              //       else if (transcriptWordsArray[startTimeThirdWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[3])){
              //         console.log('start time 4th word found (2) : ', transcriptWordsArray[startTimeThirdWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
              //         clipStartDuration = new Date(Math.floor(parseFloat(currentWord.start_time)) * 1000).toISOString().slice(14, 19);
              //       }
              //     }
              //   }
              // });

              // end time calculate
              let endTimeThirdWordIndex = undefined;
              let endTimeSecondWordIndex = undefined;

              for(let indexItem = 0; indexItem < transcriptWordsArray.length; indexItem++){
                // 4th word
                if(transcriptWordsArray[indexItem].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase() === userSelectedTextArrayFormat[userSelectedTextArrayFormat.length - 1] ){
                  console.log('End time 4th word found: ', transcriptWordsArray[indexItem].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
                  // 3rd word
                  if(transcriptWordsArray[indexItem-1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[userSelectedTextArrayFormat.length - 2])){
                    console.log('End time 3rd word found (1) : ', transcriptWordsArray[indexItem-1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
                    endTimeThirdWordIndex = indexItem-1;
                  }
                  else if(transcriptWordsArray[indexItem-2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[userSelectedTextArrayFormat.length - 2])){
                    console.log('End time 3rd word found (2) : ', transcriptWordsArray[indexItem-2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
                    endTimeThirdWordIndex = indexItem-2;
                  }
                  // 2nd word
                  if(endTimeThirdWordIndex != undefined){
                    if(transcriptWordsArray[endTimeThirdWordIndex-1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[userSelectedTextArrayFormat.length - 3])){
                      console.log('End time 2nd word found (1) : ', transcriptWordsArray[endTimeThirdWordIndex-1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
                      endTimeSecondWordIndex = endTimeThirdWordIndex-1;
                    }
                    else if(transcriptWordsArray[endTimeThirdWordIndex-2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[userSelectedTextArrayFormat.length - 3])){
                      console.log('End time 2nd word found (2) : ', transcriptWordsArray[endTimeThirdWordIndex-2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
                      endTimeSecondWordIndex = endTimeThirdWordIndex-2;
                    }
                  }
                  // 1st word
                  if(endTimeSecondWordIndex != undefined){
                    if(transcriptWordsArray[endTimeSecondWordIndex-1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[userSelectedTextArrayFormat.length - 4])){
                      console.log('End time 1st word found (1) : ', transcriptWordsArray[endTimeSecondWordIndex-1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
                      clipEndDuration = new Date(Math.ceil(parseFloat(transcriptWordsArray[indexItem].end_time)) * 1000).toISOString().slice(14, 19);
                      break;
                    }
                    else if(transcriptWordsArray[endTimeSecondWordIndex-2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[userSelectedTextArrayFormat.length - 4])){
                      console.log('End time 1st word found (2) : ', transcriptWordsArray[endTimeSecondWordIndex-2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
                      clipEndDuration = new Date(Math.ceil(parseFloat(transcriptWordsArray[indexItem].end_time)) * 1000).toISOString().slice(14, 19);
                      break;
                    }
                  }
                }                
              }

              // transcriptWordsArray.forEach((currentWord, index) => {
              //   // 4th word
              //       if(currentWord.alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[userSelectedTextArrayFormat.length - 1])){
              //         console.log('End time 4th word found: ', currentWord.alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
              //         // 3rd word
              //         if(transcriptWordsArray[index-1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[userSelectedTextArrayFormat.length - 2])){
              //           console.log('End time 3rd word found (1) : ', transcriptWordsArray[index-1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
              //           endTimeThirdWordIndex = index-1;
              //         }
              //         else if(transcriptWordsArray[index-2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[userSelectedTextArrayFormat.length - 2])){
              //           console.log('End time 3rd word found (2) : ', transcriptWordsArray[index-2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
              //           endTimeThirdWordIndex = index-2;
              //         }
              //         // 2nd word
              //         if(endTimeThirdWordIndex != undefined){
              //           if(transcriptWordsArray[endTimeThirdWordIndex-1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[userSelectedTextArrayFormat.length - 3])){
              //             console.log('End time 2nd word found (1) : ', transcriptWordsArray[endTimeThirdWordIndex-1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
              //             endTimeSecondWordIndex = endTimeThirdWordIndex-1;
              //           }
              //           else if(transcriptWordsArray[endTimeThirdWordIndex-2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[userSelectedTextArrayFormat.length - 3])){
              //             console.log('End time 2nd word found (2) : ', transcriptWordsArray[endTimeThirdWordIndex-2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
              //             endTimeSecondWordIndex = endTimeThirdWordIndex-2;
              //           }
              //         }
              //         // 1st word
              //         if(endTimeSecondWordIndex != undefined){
              //           if(transcriptWordsArray[endTimeSecondWordIndex-1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[userSelectedTextArrayFormat.length - 4])){
              //             console.log('End time 1st word found (1) : ', transcriptWordsArray[endTimeSecondWordIndex-1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
              //             clipEndDuration = new Date(Math.ceil(parseFloat(currentWord.end_time)) * 1000).toISOString().slice(14, 19);
              //           }
              //           else if(transcriptWordsArray[endTimeSecondWordIndex-2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase().includes(userSelectedTextArrayFormat[userSelectedTextArrayFormat.length - 4])){
              //             console.log('End time 1st word found (2) : ', transcriptWordsArray[endTimeSecondWordIndex-2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.'?]/g, '').trim().toLowerCase());
              //             clipEndDuration = new Date(Math.ceil(parseFloat(currentWord.end_time)) * 1000).toISOString().slice(14, 19);
              //           }
              //         }
              //       }
              //   });
          }
      }

      // Result:
      console.log('clipStartDuration', clipStartDuration)
      console.log('clipEndDuration', clipEndDuration)
      let clip_duration = {
        "start": clipStartDuration,
        "end": clipEndDuration,
      };
      
      if(clipStartDuration !== undefined && clipEndDuration !== undefined){
        return clip_duration;
      } else {
        this.toastMsgService.showMsg("failed", "Start and end time error", 4000);
        return clip_duration;
      }

    } else {
        this.toastMsgService.showMsg("failed", "A clip must be at least 8 words", 4000);
        let clip_duration = {
          "start": undefined,
          "end": undefined,
        };
        return clip_duration;
    }
  }


  getStartTime(type=""){
    // cleanup user selected text (remove . , " ' () \n )
    let cleanedUserSelectedText = this.userSelectedText.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase();
    console.log('cleanedUserSelectedText', cleanedUserSelectedText)
    // user selected text converted to array
    let userSelectedTextArray = cleanedUserSelectedText.split(" ");
    console.log('userSelectedTextArray', userSelectedTextArray)
    if(userSelectedTextArray.length >= 4){
      let clipStartDuration = undefined;
      for(let key in this.transcriptDictionary){
          // cleanup every task transcript (remove . , " ' () \n )
          let task_transcript = this.transcriptDictionary[key].text.transcript.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase();
          if(task_transcript.includes(cleanedUserSelectedText)){ // if user selected text match the current task transcript.
            console.log('MATCHED TASK NO :', key)
            // words in array
            let transcriptWordsArray = this.transcriptDictionary[key].text.items;
            let secondWordIndex = undefined;
            let thirdWordIndex = undefined;
            // // start time calculate
            // transcriptWordsArray.forEach((currentWord, index) => {
            //     // get 1st word
            //     if(currentWord.alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase().includes(userSelectedTextArray[0])){
            //       console.log('1st word found:', currentWord.alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase())
            //       // 2nd word
            //       if(transcriptWordsArray[index+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase().includes(userSelectedTextArray[1])){
            //         console.log('2nd word found 1 :', transcriptWordsArray[index+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase())
            //         secondWordIndex = index+1;
            //       } 
            //       else if(transcriptWordsArray[index+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase().includes(userSelectedTextArray[1])){
            //         console.log('2nd word found 2 :', transcriptWordsArray[index+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase())
            //         secondWordIndex = index+2;
            //       }
            //       // 3rd word
            //       if(secondWordIndex != undefined){
            //         if (transcriptWordsArray[secondWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase().includes(userSelectedTextArray[2])){
            //           console.log('3rd word found 1 :', transcriptWordsArray[secondWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase())
            //           thirdWordIndex = secondWordIndex+1;
            //         }
            //         else if (transcriptWordsArray[secondWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase().includes(userSelectedTextArray[2])){
            //           console.log('3rd word found 2 :', transcriptWordsArray[secondWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase())
            //           thirdWordIndex = secondWordIndex+2;
            //         }
            //       }
            //       // 4th word
            //       if(thirdWordIndex != undefined){                    
            //         if (transcriptWordsArray[thirdWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase().includes(userSelectedTextArray[3])){
            //           console.log('4th word found 1 :', transcriptWordsArray[thirdWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase())
            //           clipStartDuration = new Date(Math.floor(parseFloat(currentWord.start_time)) * 1000).toISOString().slice(14, 19);
            //         }
            //         else if (transcriptWordsArray[thirdWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase().includes(userSelectedTextArray[3])){
            //           console.log('4th word found 2 :', transcriptWordsArray[thirdWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase())
            //           clipStartDuration = new Date(Math.floor(parseFloat(currentWord.start_time)) * 1000).toISOString().slice(14, 19);
            //         }
            //       }
            //     }
            // });
            for(let indexItem = 0; indexItem < transcriptWordsArray.length; indexItem++){
                // get 1st word
                if(transcriptWordsArray[indexItem].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase() === userSelectedTextArray[0]){
                  console.log('1st word found:', transcriptWordsArray[indexItem].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase())
                  // 2nd word
                  if(transcriptWordsArray[indexItem+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase().includes(userSelectedTextArray[1])){
                    console.log('2nd word found 1 :', transcriptWordsArray[indexItem+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase())
                    secondWordIndex = indexItem+1;
                  } 
                  else if(transcriptWordsArray[indexItem+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase().includes(userSelectedTextArray[1])){
                    console.log('2nd word found 2 :', transcriptWordsArray[indexItem+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase())
                    secondWordIndex = indexItem+2;
                  }
                  // 3rd word
                  if(secondWordIndex != undefined){
                    if (transcriptWordsArray[secondWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase().includes(userSelectedTextArray[2])){
                      console.log('3rd word found 1 :', transcriptWordsArray[secondWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase())
                      thirdWordIndex = secondWordIndex+1;
                    }
                    else if (transcriptWordsArray[secondWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase().includes(userSelectedTextArray[2])){
                      console.log('3rd word found 2 :', transcriptWordsArray[secondWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase())
                      thirdWordIndex = secondWordIndex+2;
                    }
                  }
                  // 4th word
                  if(thirdWordIndex != undefined){                    
                    if (transcriptWordsArray[thirdWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase().includes(userSelectedTextArray[3])){
                      console.log('4th word found 1 :', transcriptWordsArray[thirdWordIndex+1].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase())
                      console.log('break statement')
                      clipStartDuration = new Date(Math.floor(parseFloat(transcriptWordsArray[indexItem].start_time)) * 1000).toISOString().slice(14, 19);
                      console.log('break statement clipStartDuration', clipStartDuration)
                      break;
                    }
                    else if (transcriptWordsArray[thirdWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase().includes(userSelectedTextArray[3])){
                      console.log('4th word found 2 :', transcriptWordsArray[thirdWordIndex+2].alternatives[0].content.replace(/\n/g, " ").replace(/[,.?']/g, '').trim().toLowerCase())
                      clipStartDuration = new Date(Math.floor(parseFloat(transcriptWordsArray[indexItem].start_time)) * 1000).toISOString().slice(14, 19);
                      break;
                    }
                  }
                }
            }
          }
      }

      // Result section:
      if (clipStartDuration !== undefined){
        return clipStartDuration;
      } else {
        if(type === "video_current_time_set"){
          console.warn('error: Please try again');
        } else {
          this.toastMsgService.showMsg("failed", `Please try again`, 3000);
        }
        return clipStartDuration;
      }
    } else {
      if(type === ""){
        this.toastMsgService.showMsg("failed", `Please select at least 4 words in a line`, 3000);
      } 
      else if(type === "video_current_time_set"){
        console.warn('error: please select at least 4 words');
      }
      else {
        this.toastMsgService.showMsg("failed", `A ${type} must be at least 4 words`, 3000);
      }
      return undefined;
    }
  }


  // context menu play video option
  playVideo(){
    let time_dict = this.getStartAndEndTime();
    if(time_dict["start"] !== undefined && time_dict["end"] !== undefined){
      let parts1 = time_dict["start"].split(":");
      let minutes1 = parseInt(parts1[0], 10);
      let seconds1 = parseInt(parts1[1], 10);
      let result1 = (minutes1 * 60) + seconds1;

      let parts2 = time_dict["end"].split(":");
      let minutes2 = parseInt(parts2[0], 10);
      let seconds2 = parseInt(parts2[1], 10);
      let result2 = (minutes2 * 60) + seconds2;

      let result_dict = {
        "start_time": result1,
        "end_time": result2
      }

      this._UpVideoPlayerService.setVideoTimeRange(result_dict);
      this.showTranscriptContextMenu = false;
      window.getSelection().removeAllRanges();
    }
  }


  showTooltip(id){
    let element = document.getElementById(id) as HTMLElement;
    element.style.display= "block"
  }
  closeTooltip(id){
    let element = document.getElementById(id) as HTMLElement;
    element.style.display= "none"
  }


  highlightSelectContextBtn(tag_name=undefined, event=undefined){
    // also used to cleanup context menu action

    let contextBtnList = document.getElementsByClassName(`context-btn`) as HTMLCollection;
    Array.prototype.forEach.call(contextBtnList, function(item) {
      item.style.backgroundColor = "#fff";
    });
    let contextDialogList = document.getElementsByClassName(`context-dialog`) as HTMLCollection;
    Array.prototype.forEach.call(contextDialogList, function(item) {
      item.style.display = "none";
    });

    if(tag_name !== undefined){ // called on context icon click
      if(tag_name === "clip"){
        // validate selected text start and end time
        let clip_duration_json = this.getStartAndEndTime();
        if(clip_duration_json["start"] == undefined && clip_duration_json["end"] == undefined){
          this.showTranscriptContextMenu = false;
          return 0;
        }
      } else {
        // validate selected text start time
        let start_time = this.getStartTime(tag_name);
        if(start_time === undefined){
          this.showTranscriptContextMenu = false;
          return 0;
        }
      }
      // context menu btn
      let btn = document.getElementById(`context-${tag_name}-btn`) as HTMLElement;
      btn.style.backgroundColor = "#cfcfcf";
      let dialog = document.getElementById(`context-${tag_name}-dialog`) as HTMLElement;
      // if context menu opened in the bottom move dialog box up
      if(event !== undefined){
        let boundryValues = event.target.getBoundingClientRect();
        if(boundryValues.top > 550){
          // console.log('boundryValues.top', boundryValues.top)
          if(tag_name === "note"){
            dialog.style.top = "-170px";
          } 
          else if(tag_name === "hashtag"){
            dialog.style.top = "-180px";
          }
          else if(tag_name === "clip"){
            dialog.style.top = "-113px";
          }
        } else {
          if(tag_name !== "sentiment"){
            dialog.style.top = "0px"
          }
        }
      }
      // context menu dialog
      if(tag_name !== "clip"){
        dialog.style.display = "block";
      }
    }

  }

  // ========================= clip section ===============================

  playSelectedClip(data){

    console.log('data:', data)

    let parts1 = data["start"].split(":");
    let minutes1 = parseInt(parts1[0], 10);
    let seconds1 = parseInt(parts1[1], 10);
    let result1 = (minutes1 * 60) + seconds1;

    let parts2 = data["end"].split(":");
    let minutes2 = parseInt(parts2[0], 10);
    let seconds2 = parseInt(parts2[1], 10);
    let result2 = (minutes2 * 60) + seconds2;

    let result_dict = {
      "start_time": result1,
      "end_time": result2
    }

    this._UpVideoPlayerService.setVideoTimeRange(result_dict);
  }

  createClipFromPlayer(start_time=""){
    // start time from the video player
    let input_start_time_array = start_time.split(":");
    let input_start_time_seconds = (parseInt(input_start_time_array[0]) * 60 ) + parseInt(input_start_time_array[1]);
    console.warn('*********************')
    console.warn('input_start_time_seconds : ', input_start_time_seconds)
    console.warn('*********************')

    let time_to_text_array = [];
    let time_to_text_array_original = [];
    
    for(let key in this.transcriptDictionary){
        // current iteration task start time
        let current_iteration_start_time = this.transcriptDictionary[key]["start_time"].split(":");
        let start_time_in_seconds = (parseInt(current_iteration_start_time[0]) * 60 ) + parseInt(current_iteration_start_time[1])
        
        // current iteration task end time
        let current_iteration_end_time = this.transcriptDictionary[key]["end_time"].split(":");
        let end_time_in_seconds = (parseInt(current_iteration_end_time[0]) * 60 ) + parseInt(current_iteration_end_time[1])

        // check if its within time range
        if(input_start_time_seconds >= start_time_in_seconds && input_start_time_seconds <= end_time_in_seconds){
          console.warn('-------------- matched start and end time ----------------------')
          console.warn('start time: ', this.transcriptDictionary[key]["start_time"])
          console.warn('end time: ', this.transcriptDictionary[key]["end_time"])
          console.warn('----------------------------------------------------------------')

            this.transcriptDictionary[key]["text"]["items"].forEach((word_item)=>{
                let word_start_time = Math.floor(parseFloat(word_item["start_time"]))
                if(word_start_time === input_start_time_seconds){
                  time_to_text_array.push(word_item["alternatives"][0]["content"])
                  time_to_text_array_original.push(word_item["alternatives"][0]["content"])
                  console.warn('1st selection : ', word_item["start_time"])
                  console.warn('text : ', word_item["alternatives"][0]["content"])
                  console.warn('-----------------------------------')
                }
            });

            console.warn('================ after 1st selection =====================')
            console.warn('time_to_text_array', time_to_text_array)
            console.warn('================ =================== =====================')

            // check in forward order
            if(time_to_text_array.length <= 3){
              for(let i = 1; i <= 10; i++){
                input_start_time_seconds += 1;
                console.error(`iteration ${i} , input_start_time_seconds : ${input_start_time_seconds}`)
                this.transcriptDictionary[key]["text"]["items"].forEach((word_item)=>{
                  let word_start_time = Math.floor(parseFloat(word_item["start_time"]))
                  if(word_start_time === input_start_time_seconds){
                    time_to_text_array.push(word_item["alternatives"][0]["content"])
                    console.warn('2nd selection : ', word_item["start_time"])
                    console.warn('text : ', word_item["alternatives"][0]["content"])
                    console.warn('-----------------------------------')
                  }
                });
                if(time_to_text_array.length <= 3){
                  continue;
                } else {
                  break;
                }
              }
              console.warn('================ after 2nd selection | extract text in for loop =====================')
              console.warn('time_to_text_array', time_to_text_array)
              console.warn('================ =================== =====================')
            }


            let time_to_words_string = time_to_text_array.join(" ");
            this.userSelectedText = time_to_words_string ;
            let task_and_line_dict = this.get_verified_line_and_task_no(this.userSelectedText)
            
            // check in reverse order
            if(task_and_line_dict["line_no"] == 0){
              console.error('########### finding in reverse order #############')
              if(time_to_text_array_original.length <= 3){
                for(let i = 1; i <= 10; i++){
                  input_start_time_seconds -= 1;
                  console.error(`iteration ${i} , input_start_time_seconds : ${input_start_time_seconds}`)
                  this.transcriptDictionary[key]["text"]["items"].forEach((word_item)=>{
                    let word_start_time = Math.floor(parseFloat(word_item["start_time"]))
                    if(word_start_time === input_start_time_seconds){
                      time_to_text_array_original.push(word_item["alternatives"][0]["content"])
                      console.warn('2nd selection reverse order : ', word_item["start_time"])
                      console.warn('text : ', word_item["alternatives"][0]["content"])
                      console.warn('-----------------------------------')
                    }
                  });
                  if(time_to_text_array_original.length <= 3){
                    continue;
                  } else {
                    time_to_text_array = time_to_text_array_original
                    break;
                  }
                }
                console.warn('================ after 2nd selection | reverse order extract text in for loop =====================')
                console.warn('time_to_text_array', time_to_text_array)
                console.warn('================ =================== =====================')
              }
            }

            let time_to_words_string2 = time_to_text_array.join(" ");
            this.userSelectedText = time_to_words_string2 ;
            let task_and_line_dict2 = this.get_verified_line_and_task_no(this.userSelectedText)            

            // fallback select full text
            if(task_and_line_dict2["line_no"] == 0){
              time_to_text_array = [];
              this.transcriptDictionary[key]["text"]["items"].forEach((word_item)=>{
                let word_start_time = Math.floor(parseFloat(word_item["start_time"]))
                if(word_start_time <= input_start_time_seconds){
                    time_to_text_array.push(word_item["alternatives"][0]["content"])
                }
              });              
              console.warn('================ after 3rd selection | fallback full text selection =====================')
              console.warn('time_to_text_array', time_to_text_array)
              console.warn('================ =================== =====================')
            }

        }
    }

    let task_and_line_dict = undefined;
    let time_to_words_string = time_to_text_array.join(" ");

    // unable to find text data fullback logic
    if(time_to_words_string == ""){
      for(let key in this.transcriptDictionary){
        // current iteration task start time
        let current_iteration_start_time = this.transcriptDictionary[key]["start_time"].split(":");
        let start_time_in_seconds = (parseInt(current_iteration_start_time[0]) * 60 ) + parseInt(current_iteration_start_time[1])
        // current iteration task end time
        let current_iteration_end_time = this.transcriptDictionary[key]["end_time"].split(":");
        let end_time_in_seconds = (parseInt(current_iteration_end_time[0]) * 60 ) + parseInt(current_iteration_end_time[1])
        if(input_start_time_seconds >= start_time_in_seconds && input_start_time_seconds <= end_time_in_seconds){
          let task_number = parseInt(key.toString().split("_")[1])
          task_and_line_dict = {
            "line_no": 1,
            "task_no": task_number,
          }
          this.userSelectedText = this.transcriptDictionary[key]["text"]["transcript"];
        }
      }
    } else {
      this.userSelectedText = time_to_words_string ;
      task_and_line_dict = this.get_verified_line_and_task_no(this.userSelectedText)
    }
    
    // selected time range not in transcript data
    if(task_and_line_dict === undefined){
      let end_time_in_sec_array = []
      for(let key in this.transcriptDictionary){
        let current_iteration_end_time = this.transcriptDictionary[key]["end_time"].split(":");
        let end_time_in_seconds = (parseInt(current_iteration_end_time[0]) * 60 ) + parseInt(current_iteration_end_time[1])
        end_time_in_sec_array.push(end_time_in_seconds)
      }

      const closestNumber = ((arr, target) => arr.reduce((prev, curr) => 
        (Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev)
      ))(end_time_in_sec_array, input_start_time_seconds);

      let closestNumberIndex = end_time_in_sec_array.findIndex(element => element === closestNumber);
      task_and_line_dict = {
        "line_no": 1,
        "task_no": closestNumberIndex+1,
      }
      this.userSelectedText = this.transcriptDictionary[`task_${closestNumberIndex+1}`]["text"]["transcript"]

    }

    console.warn('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^')
    console.warn("time_to_text_array: ",time_to_text_array)
    console.warn("time_to_words_string: ",time_to_words_string)
    
    console.warn("line no info: ",task_and_line_dict)
    console.warn('start_and_end_dict', this.start_and_end_dict)
    console.warn('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^')


    if(task_and_line_dict !== undefined && task_and_line_dict["line_no"] !== 0){
      let body = {
        "video_url": this.selectedVideoDetails.file,
        "clip": {
          "start": this.start_and_end_dict["start_time"],
          "end": this.start_and_end_dict["end_time"],
          "notes": ""
        },
        "line_no": task_and_line_dict["line_no"],
        "task": task_and_line_dict["task_no"],
        "transcript_text": this.userSelectedText,
      }
      // create clip api request
      this.generalApi.createClip(body).subscribe((api_response)=>{
        if(api_response.status === 200 || api_response.status === 201 ){
          this.toastMsgService.showMsg("success", "clip created successfully.")
        } else {
          this.toastMsgService.showMsg("failed", api_response.message, 3000);
        }
        this.getFindings();
        this.showTranscriptContextMenu = false;
      });
    } else {
      this.toastMsgService.showMsg("failed", "text not found in the time range", 3000);
    }


  }


}

  // noteAPIRequest(request_type, text_var=""){
  //   let startDuration = this.getStartDuration();
  //   if(startDuration === "" || startDuration === undefined || startDuration === null){
  //     this.toastMsgService.showMsg("failed", "please select whole word!.");
  //     this.showTranscriptContextMenu = false;
  //     return 0;
  //   }
  //   let body = {
  //     "test": this.selectedVideoDetails.test,
  //     "tested_test": this.selectedVideoDetails.id,
  //     "start": startDuration,
  //   }
  //   if(request_type === "post"){
  //     body["text"] = this.notesInputVar;
  //     this.generalApi.saveNotes(body).subscribe((api_response)=>{
  //       if(api_response.status === 200){
  //         this.toastMsgService.showMsg("success", "Notes saved successfully.")
  //       } else {
  //         this.toastMsgService.showMsg("failed", "Notes creation failed", 3000);
  //       }
  //       this.showTranscriptContextMenu = false;
  //       this.getFindings();
  //     })
  //   } 
  //   else if(request_type === "put"){
  //     body["text"] = text_var
  //     this.generalApi.updateNote(body).subscribe((api_response)=>{
  //       if(api_response.status === 200){
  //         this.toastMsgService.showMsg("success", "Notes updated successfully.")
  //       } else {
  //         this.toastMsgService.showMsg("failed", "Notes updation failed", 3000);
  //       }
  //       this.showTranscriptContextMenu = false;
  //       this.getFindings();
  //     })
  //   }
  // }
