import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class StoreOrderedTestDataService {

  private testDetials = new BehaviorSubject({});
  private orderDetials = new BehaviorSubject({});

  currentTestData = this.testDetials.asObservable();
  currentOrderData = this.orderDetials.asObservable();
  
  constructor() { }

  storeTestData(param: any){
    this.testDetials.next(param)
  }

  storeOrderData(param: any){
    this.orderDetials.next(param)
  }

}
