import { Component, OnInit } from '@angular/core';
import { GetSelectedVideoDetialsService } from '../../services/get-selected-video-detials.service';
import {ActivatedRoute, Params} from '@angular/router';
import { TesterTestedListService } from 'src/app/shared/services/tester-tested-list.service';
import { navigateToCurrentTimeStatmp } from 'src/app/shared/services/api.constant';

@Component({
  selector: 'app-taskdetails-tab',
  templateUrl: './taskdetails-tab.component.html',
  styleUrls: ['./taskdetails-tab.component.scss']
})
export class TaskdetailsTabComponent implements OnInit {

  videoInformation: any;

  constructor(
    private getSelectedVideoDetialsService: GetSelectedVideoDetialsService,
    private activatedRoute: ActivatedRoute,
    private _testedTestServices: TesterTestedListService
  ) { }

  ngOnInit() {
    // info: get selected video detials by passing video id
    this.activatedRoute.params.subscribe((params: Params) => {
      this.videoInformation = this.getSelectedVideoDetialsService.getSelectedVideoDetials(params['id'])
    });
  }


  
  jumpTimestamp(time, isTaskTimestamp){
    navigateToCurrentTimeStatmp(time, isTaskTimestamp);
  }

}
