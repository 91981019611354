import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './core/auth/login/login.component';
import {RegisterComponent} from './core/auth/register/register.component';
import {AuthGuard} from './auth.guard';
import {CreateTestsComponent} from './core/tests-client/create-tests/create-tests.component';
import {PageNotFoundComponent} from './shared/page-not-found/page-not-found.component';
import {VideosComponent} from './modules/videos/videos.component';
import {DashboardTestComponent} from './core/dashboard-test/dashboard-test.component';
import {DashboardTestEditComponent} from './core/dashboard-test/dashboard-test-edit/dashboard-test-edit.component';
import {DashboardComponent} from './core/dashboard/dashboard.component';
import {AccountDetailsComponent} from './modules/account/account-details/account-details.component';
import {CreditsPlansComponent} from './modules/account/credits-plans/credits-plans.component';
import {BillingComponent} from './modules/account/billing/billing.component';
import {InvoicesComponent} from './modules/account/invoices/invoices.component';
import {VideosInfoComponent} from './modules/videos/videos-info/videos-info.component';
import {ExampleVideoComponent} from './modules/videos/example-video/example-video.component';
import { HighlightReelComponent } from './core/highlight-reel/highlight-reel.component';
import { CreateHighlightReelComponent } from './core/create-highlight-reel/create-highlight-reel.component';
import { ProjectDashboardComponent } from './core/project-dashboard/project-dashboard.component';
import { ActivateEmailLoginComponent } from './core/auth/activate-email-login/activate-email-login.component';
import { CreditsPlansNewComponent } from './modules/account/credits-plans-new/credits-plans-new.component';
import { TestDashboardNewComponent } from './core/test-dashboard-new/test-dashboard-new.component';
import {VideosDashboardNewComponent} from './core/videos-dashboard-new/videos-dashboard-new.component';
import {ClipsDashboardNewComponent} from './core/clips-dashboard-new/clips-dashboard-new.component';
import { ViewVideoDetailsComponent } from './modules/new-videos/view-video-details/view-video-details.component';
import { HighlightReelDashboardNewComponent } from './core/highlight-reel-dashboard-new/highlight-reel-dashboard-new.component';
import { BillingInvoicesNewComponent } from './modules/account/billing-invoices-new/billing-invoices-new.component';
import { SelectProjectWarningComponent } from './shared/select-project-warning/select-project-warning.component';
import { PluginVideoPlayerComponent } from './core/plugin-video-player/plugin-video-player.component';
import { ViewClipWrapperComponent } from './core/view-clip-wrapper/view-clip-wrapper.component';
import { ReportsDashboardComponent } from './core/reports-dashboard/reports-dashboard.component';
import { FindingsDashboardComponent } from './core/findings-dashboard/findings-dashboard.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },

  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'activate/:uid/:token',
    component: ActivateEmailLoginComponent,
  },
  {
    path: 'highlight_reel',
    component: HighlightReelComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'create-highlight-reel',
    component: CreateHighlightReelComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-highlight-reel',
    component: CreateHighlightReelComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'highlight_reel/update_highlight_reel',
    component: CreateHighlightReelComponent,
    canActivate: [AuthGuard]
  },


  // New routing start
  {
    path: 'videos/videos_info/:id/:id1',
    // patos/h:'videos1',
    component: VideosInfoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'videos/example-video/:id',
    // patos/h:'videos1',
    component: ExampleVideoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'videos',
    redirectTo: 'videos/all',
    pathMatch: 'full'
  },
  {
    path: 'videos/:type',
    component: VideosComponent,
    canActivate: [AuthGuard]
  },
  // new test dashboard
  {
    path: 'test-dashboard',
    component: TestDashboardNewComponent,
    canActivate: [AuthGuard]
  },
  // get test with project id
  {
    path: 'project/:project-id/tests',
    component: TestDashboardNewComponent,
    canActivate: [AuthGuard]
  },
  // new videos dashboard
  {
    path: 'videos-dashboard',
    component: VideosDashboardNewComponent,
    canActivate: [AuthGuard]
  },
  // new video player
  {
    path: 'videos-dashboard/view',
    component: ViewVideoDetailsComponent,
    canActivate: [AuthGuard]
  },
  // new clips dashboard
  {
    path: 'clips-dashboard',
    component: ClipsDashboardNewComponent,
    canActivate: [AuthGuard]
  },
  // new reels dashboard
  {
    path: 'highlight-reels-dashboard',
    component: HighlightReelDashboardNewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard_tests',
    redirectTo: 'dashboard_tests/all',
    pathMatch: 'full'
  },
  {
    path: 'dashboard_tests/create_test',
    component: CreateTestsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard_tests/:type',
    component: DashboardTestComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'dashboard_tests/edit/:id',
    component: CreateTestsComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'dashboard_tests/duplicatet/:id',
    component: DashboardTestEditComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'accountDetails',
    component: AccountDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'creditPlan',
    component: CreditsPlansComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'billing',
    component: BillingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'invoices',
    component: InvoicesComponent,
    canActivate: [AuthGuard]
  },
  // =========================================================================
  // dashboard page
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  // Projects page
  {
    path: 'projects',
    component: ProjectDashboardComponent,
    canActivate: [AuthGuard]
  },
  // Test page
  {
    path: 'research/tests',
    component: TestDashboardNewComponent,
    canActivate: [AuthGuard]
  },
  // Create test
  {
    path: 'research/tests/create-test',
    component: CreateTestsComponent,
    canActivate: [AuthGuard]
  },
  // videos page
  {
    path: 'results/videos',
    component: VideosDashboardNewComponent,
    canActivate: [AuthGuard]
  },
  // new - view test video component
  {
    path: 'results/videos/view-video',
    component: ViewVideoDetailsComponent,
    canActivate: [AuthGuard]
  },
  // findings page
  {
    path: 'results/findings',
    component: FindingsDashboardComponent,
    canActivate: [AuthGuard]
  },
  // clips page
  {
    path: 'results/clips',
    component: ClipsDashboardNewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'results/clips/view-clip',
    component: ViewClipWrapperComponent,
    canActivate: [AuthGuard]
  },
  // individual video player
  {
    path: 'plugin-video-player',
    component: PluginVideoPlayerComponent,
    canActivate: [AuthGuard]
  },
  // highlight reels page
  {
    path: 'results/highlight-reels',
    component: HighlightReelDashboardNewComponent,
    canActivate: [AuthGuard]
  },
  // create new highlight reel
  {
    path: 'results/highlight-reels/create',
    component: CreateHighlightReelComponent,
    canActivate: [AuthGuard]
  },
  // edit highlight reel
  {
    path: 'results/highlight-reels/edit',
    component: CreateHighlightReelComponent,
    canActivate: [AuthGuard]
  },
  // highlight reel view
  {
    path: 'results/highlight-reels/view',
    component: ViewClipWrapperComponent,
    canActivate: [AuthGuard]
  },
  // reports dashboard
  {
    path: 'reports/test-reports',
    component: ReportsDashboardComponent,
    canActivate: [AuthGuard]
  },
  // account detials page
  {
    path: 'account-details',
    component: AccountDetailsComponent,
    canActivate: [AuthGuard]
  },
  // credits plans page
  {
    path: 'credits-plans',
    component: CreditsPlansNewComponent,
    canActivate: [AuthGuard]
  },
  // billing page
  {
    path: 'billing-invoices',
    component: BillingInvoicesNewComponent,
    canActivate: [AuthGuard]
  },
  // if project not selected this compoenent is invoked.
  {
    path: 'project-selector',
    component: SelectProjectWarningComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [AuthGuard]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
