import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wordCount'
})
export class WordCountPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(value != ""){
      let count = 500 - value.length;
      return count;
    } else {
      return 500;
    }
  }

  // transform(value: any, ...args: any[]): any {
  //   if(value.length >= 0){
  //     let count = 500 - value.length;
  //     return count;
  //   } else {
  //     return 500;
  //   }
  // }

}
