<div class='tests-container account lg-screen-width'>

  <aside class='tests-sidebar'>
    <app-sidebar></app-sidebar>
  </aside>

  <section class='section test-section' id='all-parent'>
    <div class='account-wraper'>

      <div class='credit-wrap'>
        <div class='col-container account-credit-container'>
          <ng-container *ngIf='availablePlansForUser.flexible?.name'>
            <div class='col'>
              <article class='flexibel'>
                <div class='head'>
                  <h3>{{availablePlansForUser.flexible?.name}}</h3>
                  <p>Pay as you go-Credits</p>
                </div>
                <div class='plan'>
                  <div class='clearfix'>
                    <div class='left'>
                      {{availablePlansForUser.flexible?.totalCredit}} credits
                    </div>
                    <div class='right'>
                      <span
                        class='price'><sup>$</sup>{{availablePlansForUser.flexible.totalCredit * availablePlansForUser.flexible.perCreditCost}}</span>
                      <span class='credit'>1 Credit = ${{availablePlansForUser.flexible.perCreditCost}}</span>
                    </div>
                  </div>

                  <div class='dropdown no_credits'>
                    <div aria-expanded='false' aria-haspopup='true' class='more' data-toggle='dropdown'
                         id='dropdownMenuButton'>more
                    </div>
                    <i aria-expanded='false' aria-haspopup='true' class='fa fa-angle-down' data-toggle='dropdown'
                       id='dropdownMenuButton'></i>
                    <div aria-labelledby='dropdownMenuButton' class='dropdown-menu'>
                      <span class='number-text'>Number of credits:</span>
                      <input [(ngModel)]='customCredit' class='credits_input' min='1' name='credits' type='number'/>
                      <button (click)='updateFlexiblePlan()' style="background: #1200ff;border: #1200ff;">Update</button>
                    </div>
                  </div>

                </div>
                <div class='benefits'>
                  <ul>
                    <li class="bullet-points">No subscription</li>
                    <li class="bullet-points">Annotation and Tagging</li>
                  </ul>
                </div>
                <button (click)='addSession(availablePlansForUser.flexible)' class='credit'>
                  Get {{availablePlansForUser.flexible.totalCredit}} Credits
                </button>
                <p class="flex-plan-btn-des">You will be redirected to our payment provider fastspring.com</p>
              </article>
            </div>
          </ng-container>

          <ng-container *ngFor="let plan of availablePlansForUser.professional; let i =index;">
            <div *ngIf='plan.isShow' class='col'>
              <article class='professional'>
                <div class='head'>
                  <h3>
                    {{plan.name}} <span class='professional-head'>SAVE 30%</span>
                  </h3>
                  <p>More features to scale your business.</p>
                </div>
                <div class='plan'>
                  <div class='clearfix'>
                    <div class='left'>
                      {{plan.totalCredit}} credits <span class='left-month'>/ {{plan.type}}</span>
                    </div>
                    <div class='right'>
                      <span class='price'><sup>$</sup>{{plan.totalCredit * plan.perCreditCost}} </span>
                      <span class='credit'>1 Credit = ${{plan.perCreditCost}}</span>
                    </div>
                  </div>
                  <div *ngIf='!plan.isActive' class='more annually text-center'>
                    <span class="{{plan.type=='month' ? '':'monthly'}}">monthly</span>
                    <label class='switch'>
                      <input (change)='selctetProfessionalPlanType($event)' [checked]="plan.type=='year'"
                             type='checkbox'/>
                      <span class='slider round'></span>
                    </label>
                    <span class="{{plan.type=='month' ? 'monthly':''}}">annually</span>
                    <span class='profile-image-tooltips'>
                      <i class='fa fa-info-circle'></i>
                      <span class='profile-tooltips' style='left:42px;'>This is {{plan.name}} plan !</span>
                    </span>
                  </div>
                </div>
                <div class='benefits'>
                  <ul>
                    <li class="bullet-points">Highlight Reel / Clips</li>
                    <li class="bullet-points">Speech-to-Text Transcription</li>
                    <li class="bullet-points">Task Set Library</li>
                  </ul>
                </div>
                <button (click)='addSession(plan)' *ngIf='!plan.isActive' class='credit'>
                  Get {{plan.totalCredit}} Credits
                </button>
                <button *ngIf='plan.isActive' class='credit-plan' disabled>
                  Your current plan
                </button>
                <p class="flex-plan-btn-des">You will be redirected to our payment provider fastspring.com</p>
                <a (click)='showCancelPopup(plan.name)' *ngIf='plan.isActive' class='plan-cancel'>
                  Cancel this plan
                </a>
              </article>
            </div>
          </ng-container>

        </div>
<!--        <p class='allprice text-center'>All prices include taxes</p>-->
      </div>

      <div [style.display]='displayProper' class='modal test-credit-modal' id='session'>
        <div class='modal-dialog'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h4 class='modal-title'>Buy this Plan</h4>
              <button class='close' data-dismiss='modal' type='button'>
                &#10006;
              </button>
              <p class='credit-para'>5 Credits</p>
            </div>
            <div class='modal-body'>
              <button onclick="fastspring.builder.checkout('sessionId');">Buy Now</button>
              <button data-fsc-action='Add, Checkout' data-fsc-item-path-value=globalPlanName
                      id='fastSpringAccountCreationButton'>
                Buy Now1
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <button class='feedback-btn'>Feedback</button>
</div>
