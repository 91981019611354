import { Component, OnInit } from '@angular/core';
import {CreateNewTestService} from 'src/app/shared/services/create-new-test.service';
import {ActivatedRoute, Router, Params} from '@angular/router';

@Component({
  selector: 'app-select-project-warning',
  templateUrl: './select-project-warning.component.html',
  styleUrls: ['./select-project-warning.component.scss']
})
export class SelectProjectWarningComponent implements OnInit {

  allProjectList = [];
  selectedProjectId: number;
  projectsAvailable: string = "";
  // create project
  createProjectInputValue: string ="";
  doesProjectNameExist: boolean = false;

  constructor(
    private _createTestServices: CreateNewTestService,
    private router: Router,
  ) { }

  ngOnInit() {
    this._createTestServices.gelAllProjectList().subscribe((res) => {
      this.allProjectList = res.data;
      if(res.data.length > 0){
        this.projectsAvailable = "available";
      }
      else {
        this.projectsAvailable = "not-available";
      }
    });


  }
  
  changeProject(){
    // console.log(this.selectedProjectId);
    let selectProjectObject;

    this.allProjectList.forEach((item)=>{
      if(item.id == this.selectedProjectId){
        selectProjectObject = item;
      }
    })

    localStorage.setItem("headerProjectName", selectProjectObject.project_name);
    localStorage.setItem("selectedProjectId", selectProjectObject.id);
    let tempRoute = "";
    if (localStorage.getItem("selectedRoute") === null || localStorage.getItem("selectedRoute") === "") {
      tempRoute = "/projects";
      this.router.navigate([tempRoute]);
    }else{
      tempRoute = localStorage.getItem("selectedRoute");
      this.router.navigate([tempRoute], {queryParams: {'project-id': selectProjectObject.id}});
    }

  }



  checkProjectNameExist(event){
    let x = this.allProjectList.find((e) =>{ return(e.project_name.toLowerCase() === event.toLowerCase())})
    if(x == undefined){
      this.doesProjectNameExist = false;
    } else {
      this.doesProjectNameExist = true;
    }
  }

  popupCreateProject(){
    let tempProjectName = {
      "project_name": this.createProjectInputValue
    }
    this.createProjectInputValue = "";
    this._createTestServices.createProject(tempProjectName).subscribe((res) =>{
      if(res.status == 200){
        this.router.navigate(["/projects"]);
      }
    })
  }


}
