import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'clipTimesShow'
})
export class ClipTimesShowPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return value.substring(0, 2) + ':' + value.substring(2, 4);
  }

}
