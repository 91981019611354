import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CreateNewTestService } from 'src/app/shared/services/create-new-test.service';
import { CustomPopupBoxService } from 'src/app/shared/services/custom-popup-box.service';
import { TesterTestedListService } from 'src/app/shared/services/tester-tested-list.service';
import { environment } from 'src/environments/environment';
import { UpStoreSelectedVidDetialsService } from 'src/app/shared/services/up-store-selected-vid-detials.service';
import {NavigationEnd, ActivatedRoute, Router, Params} from '@angular/router';
import { HighlightReelApiService } from 'src/app/shared/services/highlight-reel-api.service';
import { AllFiltersShareQueryService } from 'src/app/shared/services/all-filters-share-query.service';

@Component({
  selector: 'app-clips-dashboard-new',
  templateUrl: './clips-dashboard-new.component.html',
  styleUrls: ['./clips-dashboard-new.component.scss']
})
export class ClipsDashboardNewComponent implements OnInit, OnDestroy {

  filteredJsonData = [];
  unfilteredJsonData = [];
  queriedJsonData = [];

  paginateCount: number = 0;
  paginationDisplayedCount: number = 0;
  baseUrl = environment.apiUrl;


  buttonFilterData: any ={
    all: 0,
    draft: 0,
  };

  isDataAvailable:boolean = true;
  selectedChipFilter: string = "all";
  // allFiltersResponseData$: any = { "requestType": "none" };
  localAppliedFiltersList: any = [];
  allFilterQueryDataObservable: any;

  constructor(
    private _createnewTestServices: CreateNewTestService,
    public _customPopupBoxService: CustomPopupBoxService,
    private _testedTestServices: TesterTestedListService,
    private _upStoreSelectedVidDetialsService: UpStoreSelectedVidDetialsService,
    private _router: Router,
    private _highlightReelApiService: HighlightReelApiService,
    private _activatedRoute: ActivatedRoute,
    private _allFiltersShareQueryService: AllFiltersShareQueryService,
  ) { 
    this.allFilterQueryDataObservable = this._allFiltersShareQueryService.componentFilterQueryData.subscribe((res)=>{
      if(res.requestType != "none"){
        if(res.component == this._router.url.split('?')[0]){
          this.processComponentFilterData(res);
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.allFilterQueryDataObservable.unsubscribe();
  }

  ngOnInit(): void {

    this.initializeComponentData();
    // this._allFiltersShareQueryService.componentFilterQueryData.subscribe((res)=>{
    //   if(res.requestType != "none"){
    //     if(res.component == this._router.url.split('?')[0]){
    //       this.processComponentFilterData(res);
    //     }
    //   }
    // })
  }

  initializeComponentData(){
    this._activatedRoute.queryParams.subscribe((params) => {
      if(params['project-id'] != undefined && params['test-id'] != undefined){
        this.viewProjectAndTestRelatedData(params['project-id'], params['test-id']);
      }
      else if(params['project-id'] != undefined){
        this.viewProjectRelatedData(params['project-id']);
      } 
      else {
        this._router.navigate(['/project-selector']);
      }
    });
  }

  processComponentFilterData(value){
    if(value.requestType == "getDataFromParent"){
      this._allFiltersShareQueryService.sendDataParentToChild(this.unfilteredJsonData);
    }
    else if(value.requestType == "dataChildToParent"){
      let tempClipList = [] 
      this._allFiltersShareQueryService.childToParentDataVariable.subscribe((res)=>{
        tempClipList = res;
      });
      this.queriedJsonData = tempClipList;
      this.initializePaginationFun();
    }
    else if(value.requestType == "loadOriginalData"){
      this.initializeComponentData();
    }
  }


  viewProjectAndTestRelatedData(projectId, testId){
    this._testedTestServices.getClipsWithProjectIdAndTestId(projectId, testId).subscribe((res: any)=>{
      this.isDataAvailableFun(res.data.length);


      this.unfilteredJsonData = [];
      this.queriedJsonData = [];
      res.data.forEach(item => {
        if(item['clip_status'] == "COMPLETED"){
          this.unfilteredJsonData.push(item);
          this.queriedJsonData.push(item);
        }
      });

      this.validatePaginateCount();

      // sort with id
      this.queriedJsonData.sort(function(a: any, b: any){
        return a.testedtest_clip_id - b.testedtest_clip_id;
      })
      // decending/reverse order
      this.queriedJsonData.sort().reverse();

      // initially show only 10 items
      this.filteredJsonData = [];
      for(let i = 0; i < this.paginateCount; i++){
        this.filteredJsonData.push(this.queriedJsonData[i])
      }
    });
  }

  viewProjectRelatedData(projectId){
    this._testedTestServices.getClipsWithProjectId(projectId).subscribe((res: any)=>{
      this.isDataAvailableFun(res.data.length);

      this.unfilteredJsonData = [];
      this.queriedJsonData = [];
      res.data.forEach(item => {
        if(item['clip_status'] == "COMPLETED"){
          this.unfilteredJsonData.push(item);
          this.queriedJsonData.push(item);
        }
      });

      this.validatePaginateCount();

      // sort with id
      this.queriedJsonData.sort(function(a: any, b: any){
        return a.testedtest_clip_id - b.testedtest_clip_id;
      })
      // decending/reverse order
      this.queriedJsonData.sort().reverse();

      // initially show only 10 items
      this.filteredJsonData = [];
      for(let i = 0; i < this.paginateCount; i++){
        this.filteredJsonData.push(this.queriedJsonData[i])
      }
    });
  }


  isDataAvailableFun(value){
    // if json data is empty show empty message
    if(value > 0){
      this.isDataAvailable = true;
    } else {
      this.isDataAvailable = false;
    }
  }


  validatePaginateCount(){
    if(this.queriedJsonData.length < 10){
      this.paginateCount = this.queriedJsonData.length;
    } else {
      this.paginateCount = 10;
    }
  }

  paginateDataFun(value){
    this.paginateCount = value;
    let iterationValue = 0;
    let actualDataLength = this.unfilteredJsonData.length;
    this.filteredJsonData = [];

    if(this.paginateCount < actualDataLength){
        iterationValue = this.paginateCount;
        this.paginationDisplayedCount = this.paginateCount;
    } else{
        iterationValue = actualDataLength;
        this.paginationDisplayedCount = actualDataLength;
    }

    for(let i = 0; i < iterationValue; i++){
      this.filteredJsonData.push(this.unfilteredJsonData[i]);
    }
  }

  incrementPaginationCountFun(value){

    let actualQueriedDataLength = this.queriedJsonData.length;

    // applied when no filters are applied
    if(actualQueriedDataLength == 0){

      let actualunfilteredDataLength = this.unfilteredJsonData.length;

      if(value < actualunfilteredDataLength){
        this.paginateCount = value;
        this.paginationDisplayedCount = value;
      } else {
        this.paginateCount = actualunfilteredDataLength;
        this.paginationDisplayedCount = actualunfilteredDataLength;
      }

    } else {
      // applied when filters are applied
      if(value < actualQueriedDataLength){
        this.paginateCount = value;
        this.paginationDisplayedCount = value;
      } else {
        this.paginateCount = actualQueriedDataLength;
        this.paginationDisplayedCount = actualQueriedDataLength;
      }

    }

    this.restrictDataCount()
  }

  applyButtonFilterFun(value){
    if(value === "all"){
      // if filter applied is "all-clips"
      this.selectedChipFilter = "all";
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
          this.queriedJsonData.push(item);
      })
    }
    else if(value === "archive"){
      // if filter applied is "archive"
      this.selectedChipFilter = "archive";
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        if(item.project_status == 0){
          this.queriedJsonData.push(item);
        }
      })
    } 
    
    this.initializePaginationFun();
    this.isDataAvailableFun(this.queriedJsonData.length)
  }


  initializePaginationFun(){
    this.validatePaginateCount();
    this.filteredJsonData = [];
    for(let i = 0; i < this.paginateCount; i++){
      this.filteredJsonData.push(this.queriedJsonData[i])
    }
  }


  restrictDataCount(){

    this.filteredJsonData = [];

    if(this.queriedJsonData.length == 0){
      for(let i = 0; i < this.paginateCount; i++){
        this.filteredJsonData.push(this.unfilteredJsonData[i])
      }
    } else {
      for(let i = 0; i < this.paginateCount; i++){
        this.filteredJsonData.push(this.queriedJsonData[i])
      }
    }

  }


  showContextMenu(event){
    let tempElement = event.target.parentElement.children[1];

    console.log('******', event.target.className);

    if(tempElement.style.display == "none"){

      // close previous opened popup menu
      let allElements = document.getElementsByClassName("dash-context-menu-cont");
      for (let i = 0; i < allElements.length; i++) {
          allElements.item(i).setAttribute("style", "display: none");
      }

      // open popup menu on clicked card
      tempElement.style.display = "block";

    } else{

      // close popup menu after clicking again
      tempElement.style.display = "none";
    }


  }

  storeSelectedVideoDetials(value){
    localStorage.setItem('cacheSelectedVideoDetials', JSON.stringify(value));
    this._router.navigate(["/videos-dashboard/view"]);
  }

  viewSelectedClip(clipDetials){
    localStorage.setItem('cacheSelectedVideoDetials', JSON.stringify(clipDetials));
    
    this._activatedRoute.queryParams.subscribe((params) => {
      if(params['project-id'] != undefined && params['test-id'] != undefined){
        this._router.navigateByUrl("results/clips/view-clip");
      }
      else if(params['project-id'] != undefined){
        localStorage.setItem('selectedTestId', "");
        this._router.navigateByUrl("results/clips/view-clip");
      } 
    });
  }

  // calculateDataCount(requestValue){
  //   if(requestValue.requestType == "getCount"){
  //     if(requestValue.queryType === "date"){
  //       let startDateFormatted = new Date(requestValue.value.startDate);
  //       let endDateFormatted = new Date(requestValue.value.endDate);
  //       let tempAvailableDataCount = 0;
  //       this.unfilteredJsonData.forEach((item)=>{
  //         let newDate = new Date(item.created_date);
  //         if(newDate >= startDateFormatted && newDate <= endDateFormatted){
  //           tempAvailableDataCount++;
  //         }
  //       })
  //       this._allFiltersShareQueryService.sendDataCount(tempAvailableDataCount);
  //       // this.allFiltersResponseData$ = tempJson;
  //     }
  //     else if(requestValue.queryType === "testName"){
  //       // let tempAvailableDataCount = 0;
  //       // this.unfilteredJsonData.forEach((item)=>{
  //       //   let newDate = new Date(item.created_date);
  //       //   if(newDate >= startDateFormatted && newDate <= endDateFormatted){
  //       //     tempAvailableDataCount++;
  //       //   }
  //       // })
  //       // this._allFiltersShareQueryService.sendDataCount(tempAvailableDataCount);
  //     }
  //   } 
  // }



  // gatherPreFilterData(requestValue){
  //   if(requestValue.queryType == "testName"){
  //     let tempResponseArray = [];
  //     if(requestValue.value != ""){
  //       let sourceArrayList = this.getAllFiltersAppliedData();
  //       sourceArrayList.forEach((item)=>{
  //         if(item.test_name.toLowerCase().includes(requestValue.value.toLowerCase())){
  //           if(!tempResponseArray.includes(item.test_name)){
  //             tempResponseArray.push(item.test_name);
  //           }
  //         }
  //       })
  //       this._allFiltersShareQueryService.sendPreFilterResult(tempResponseArray);
  //     } else{
  //       this._allFiltersShareQueryService.sendPreFilterResult([]);
  //     }
  //   }



  // }

  applyAllFiltersQuery(){
    if(this.localAppliedFiltersList.length > 0){ 
      this.queriedJsonData = [];
      this.localAppliedFiltersList.forEach((arrayItem)=>{
        if(arrayItem.queryType == "date"){
          let sourceArrayList = this.getAllFiltersAppliedData();
          this.queriedJsonData = [];
          let startDateFormatted = new Date(arrayItem.value.startDate);
          let endDateFormatted = new Date(arrayItem.value.endDate);
          sourceArrayList.forEach((clipItem)=>{
            let newDate = new Date(clipItem.created_date);
            if(newDate >= startDateFormatted && newDate <= endDateFormatted){
              this.queriedJsonData.push(clipItem);
            }
          })
        }
        else if(arrayItem.queryType == "testName"){
          let sourceArrayList = this.getAllFiltersAppliedData();
          this.queriedJsonData = [];
          sourceArrayList.forEach((clipItem)=>{
            if(clipItem.test_name.toLowerCase().includes(arrayItem.value.toLowerCase())){
              this.queriedJsonData.push(clipItem);
            }
          });
        }
      });
      this.initializePaginationFun();
    } else {
      this.initializeComponentData();
    }
  }

  getAllFiltersAppliedData(){
    if(this.queriedJsonData.length > 0){
      return this.queriedJsonData;
    } else{
      return this.unfilteredJsonData;
    }
  }









}



// else if(requestType == "viewData"){
//   if(queryType === "date"){
//     this.queriedJsonData = [];
//     let startDateFormatted = new Date(value.startDate);
//     let endDateFormatted = new Date(value.endDate);

//     this.unfilteredJsonData.forEach((item)=>{
//       let newDate = new Date(item.created_date);
//       if(newDate >= startDateFormatted && newDate <= endDateFormatted){
//         this.queriedJsonData.push(item);
//       }
//     })
//   }
//   this.initializePaginationFun();
// }

  // invokeAllFilter(event){
  //   let requestType = event.requestType;
  //   let queryType = event.queryType;
  //   let value = event.value;

  //   if(requestType == "getCount"){
  //     if(queryType === "date"){
  //       let startDateFormatted = new Date(value.startDate);
  //       let endDateFormatted = new Date(value.endDate);
  //       let tempAvailableDataCount = 0;
  //       this.unfilteredJsonData.forEach((item)=>{
  //         let newDate = new Date(item.created_date);
  //         if(newDate >= startDateFormatted && newDate <= endDateFormatted){
  //           tempAvailableDataCount++;
  //         }
  //       })
  //       let tempJson = {
  //         "requestType": "getCount",
  //         "value": tempAvailableDataCount
  //       }
  //       this.allFiltersResponseData$ = tempJson;
  //     }
  //   } 
    
  //   else if(requestType == "getTestNameResults"){
  //     this.sendTestnameData(event);
  //   }
  // }