import { Component, OnInit } from '@angular/core';
import { GetSelectedVideoDetialsService } from '../services/get-selected-video-detials.service';
import {ActivatedRoute, Params} from '@angular/router';
import { TesterTestedListService } from 'src/app/shared/services/tester-tested-list.service';
import {convertToHHMMSS, navigateToCurrentTimeStatmp, parseTimeFunction,} from 'src/app/shared/services/api.constant';
import { CreateNewTestService } from 'src/app/shared/services/create-new-test.service';
import {ModelBoxService} from 'src/app/shared/services/model-box.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notes-and-clips',
  templateUrl: './notes-and-clips.component.html',
  styleUrls: ['./notes-and-clips.component.scss']
})
export class NotesAndClipsComponent implements OnInit {

  baseUrl = environment.apiUrl;
  videoInformation: any;
  videoId: any;
  testId: any;
  selectVideoDeatils: any;
  makerUnsub: any;
  getMarkerList = [];
  subscription1: any;
  createTestList: any;
  draftStatus: any;
  testerTask = [];
  editMarkerId: any;
  showMarkerEdit: boolean = false;
  showEditId: any;
  editMode: boolean = false;
  styleProp = 'none';
  clipEdit1: boolean = false;
  clipList: any;


  constructor(
    private getSelectedVideoDetialsService: GetSelectedVideoDetialsService,
    private activatedRoute: ActivatedRoute,
    private _testedTestServices: TesterTestedListService,
    private _createnewTestServices: CreateNewTestService,
    private _modelBoxService: ModelBoxService,

  ) { }

  ngOnInit(): void {

    // info: get selected video detials by passing video id
    this.activatedRoute.params.subscribe((params: Params) => {
      this.videoInformation = this.getSelectedVideoDetialsService.getSelectedVideoDetials(params['id'])
    });
    this.setActiveParamsMap();
    this.getAllMarker();
    this.getAllClips();
    this.getAllTest();
    console.log('🛩️🛩️🛩️',this.getMarkerList);

  }

  onMyCustomEvent() {
    this.showMarkerEdit = false;
    this.editMode = false;
    this.styleProp = 'none';
  }

  jumpTimestamp(time, isTaskTimestamp?) {
    navigateToCurrentTimeStatmp(time, isTaskTimestamp);
  }

  setActiveParamsMap() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.videoId = +params['id'];
      this.testId = +params['id1'];
      this.selectVideoDeatils = {
        testId: this.testId,
        videoId: this.videoId,
      };
    });
  }

  getTriggredEvent(event) {
    switch (event) {
      case 'create':
        this.getAllMarker();
        this.getAllClips();
        break;
      case 'update':
        this.getAllMarker();
        this.getAllClips();
        this.editMarker(null);
        break;
      case 'cancel':
        this.editMarker(null);
        break;
      default:
        break;
    }
  }

  getAllMarker(isLoader = false) {
    this.makerUnsub = this._testedTestServices
      .getMarkerList(this.videoId)
      .subscribe((getMarkerRes) => {
        if (getMarkerRes['status'] == 200) {
          this.getMarkerList = getMarkerRes['data'];

          // this.getMarkerList.forEach((res) => {
          //   let startTime: any = convertToHHMMSS(res.start);
          //   let endTime: any = convertToHHMMSS(res.end);
          //   let convertedStartTime = parseTimeFunction(startTime);
          //   let convertedEndTime = parseTimeFunction(endTime);

          //   let duration = convertedEndTime - convertedStartTime;

          //   if (this._testedTestServices.player.markers.add) {
          //     this._testedTestServices.player.markers.add([
          //       {
          //         time: convertedStartTime,
          //         duration: duration,
          //         text: res.text,
          //       },
          //     ]);
          //   }
          // });
        }

        if (isLoader) {
        }
      });
  }

  getAllTest() {
    this.subscription1 = this._createnewTestServices
      .getCreateTest()
      .subscribe((res) => {
        this.createTestList = res.data;
        this.draftStatus = this.createTestList;
        this.draftStatus.filter((allTestRes) => {

          if (allTestRes.id === this.videoId) {
            allTestRes.test_instructions.forEach((res) => {
              this.testerTask.push(res);
            });
          }
        });
      });
    this.draftStatus = this.createTestList;
  }
  
  editMarker(id = null) {
    this.editMarkerId = id;
    this.showMarkerEdit = false;
    if (id != null) {
      let markerData = this.getMarkerList.find((list) => list.id == id);
      this.selectVideoDeatils.editNotesClip = markerData;
    }
  }
  
  editShow(index) {
    if (this.showEditId == index) {
      this.showMarkerEdit = !this.showMarkerEdit;
    } else {
      this.showMarkerEdit = true;
    }
    this.showEditId = index;
    this.editMode = true;
    this.styleProp = 'block';
  }

  cancelClip() {
    this.clipEdit1 = false;
  }

  deleteMarkerList(id, mark) {
    this._testedTestServices.deleteMarker(id).subscribe((delRes) => {
      if (delRes['status'] == 200) {
        this._modelBoxService.notesAndClipModelMsg(mark.clip, 'are deleted');
        // if (mark.clip == false) {
        //   this._modelBoxService.showSuccessToster('Note are deleted');
        // } else {
        //   this._modelBoxService.showSuccessToster('Clip are deleted');
        // }
        this.getAllMarker();
      }
    });
  }

  getAllClips() {
    console.log('1️⃣1️⃣1️⃣1️⃣1️⃣1️⃣1️⃣1️⃣');
    this._testedTestServices
      .getClipList(this.videoId)
      .subscribe((res) => {
        console.log('2️⃣2️⃣2️⃣2️⃣2️⃣2️⃣2️⃣2️⃣', res);
        if (res['status'] == 200) {
          this.clipList = res['data'];
          this.clipList.forEach((item) => {
            this.getMarkerList.push(item)
          })
          console.log('3️⃣3️⃣3️⃣3️⃣3️⃣3️⃣3️⃣3️⃣', this.getMarkerList);

        }
      });
  }






}
