import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpStoreSelectedTestDetialsService {

  public dataSource = new BehaviorSubject({});
  testDetialsVariable = this.dataSource.asObservable();

  constructor() { }

  storeTestDetials(value: any){
    this.dataSource.next(value)
  }

}
