import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PaymentService} from 'src/app/shared/services/payment.service';
import {ModelBoxService} from 'src/app/shared/services/model-box.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit {
  public submitted: boolean = false;
  residences = [];
  isAccountCreate: boolean;
  tab: any = 'tab1';
  tab1: any;
  creditCustomerPlan: FormGroup;
  language: any;
  newCountryList = [];
  customerLanguage = {English: 'en', Arbic: 'ar', Hindi: 'hi', Spanish: 'es'};
  languagecode = [];
  showLang = [];
  isAccountCreateMsg = [];

  constructor(
    private fb: FormBuilder,
    private _payement: PaymentService,
    private _modelBoxService: ModelBoxService
  ) {}

  ngOnInit() {
    this.initBillingSubForm();

    this.creditCustomerPlan.get('lookup').patchValue({
      custom: 'ueser_id_' + localStorage.getItem('user_id'),
    });
    this.languagecode = Object.values(this.customerLanguage);

    for (let lang of this.languagecode) {
      this.showLang.push(this.languagecode[lang]);
    }
    this.getCountryLangList();
    this.getBillingAddress();
  }

  initBillingSubForm() {
    this.creditCustomerPlan = this.fb.group({
      contact: this.fb.group({
        first: ["", Validators.required],
        last: ["", Validators.required],
        email: [
          "",
          [
            Validators.required,
            Validators.email,
            Validators.pattern('^[A-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          ],
        ],
        company: ["", Validators.required],
        phone: ["", Validators.required],
      }),
      language: ["", Validators.required],
      country: ["", Validators.required],
      lookup: this.fb.group({
        custom: [""],
      }),
      address: this.fb.group({
        addressLine1: ["", [Validators.required]],
        addressLine2: [""],
        city: [""],
        postalCode: ['23322', [Validators.required]],
        region: [""],
      }),
    });
  }

  getBillingAddress() {
    this._payement.getCustomer().subscribe((res) => {
      const data = res['data'];
      localStorage.setItem('customer_id', data.id);

      if (data.length <= 0) {
        this.isAccountCreate = false;
        this.isAccountCreateMsg.push(
          'First you create a customer account !',
          'You can easily create customer it here.'
        );
      } else {
        this.isAccountCreate = true;
        this.isAccountCreateMsg = [];
      }

      this.creditCustomerPlan.get('contact').patchValue({
        first: data.contact.first,
        last: data.contact.last,
        email: data.contact.email,
        company: data.contact.company,
        phone: data.contact.phone,
      });

      this.creditCustomerPlan.patchValue({
        country: data.country,
        language: data.language,
      });

      const address = data['address'];
      this.creditCustomerPlan.get('address').patchValue({
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        city: address.city,
        region: address.region,
        postalCode: address.postalCode,
      });
    });
  }

  getCountryLangList() {
    this._payement.getCountryList().subscribe((list) => {
      this.residences = Object.values(list.country_code_list);
      for (let country of this.residences) {
        this.newCountryList.push(this.residences[country]);
      }

      this.language = list.lang_list;
    });
  }

  updateCustomer() {
    this.submitted = true;
    if (this.creditCustomerPlan.invalid) {
      return;
    }
    this.submitted = false;
    this._payement
      .updateCustomer(this.creditCustomerPlan.value)
      .subscribe((update) => {
        if (update['status'] == 200) {
          let modelBody = 'Your profile was successfully updated.';
          let modelHeader = 'Profile Updated!';
          this._modelBoxService.showModelBox(true, modelBody, modelHeader);
        } else {
          this._modelBoxService.showModelBox(false, update['message']);
        }
      });
  }
}
