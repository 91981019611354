import { Component, OnInit } from '@angular/core';
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';

@Component({
  selector: 'app-toast-msg',
  templateUrl: './toast-msg.component.html',
  styleUrls: ['./toast-msg.component.scss']
})

export class ToastMsgComponent implements OnInit {

  showToast: boolean;
  iconType: boolean;
  toastText: any;

  constructor(public toastController: ToastMsgService) { }

  ngOnInit(): void {
    
  }

  dismiss(){
    this.toastController.dismissToast();
  }


}
