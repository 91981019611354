

import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HighlightReelUpdateService {


  private dataSource = new BehaviorSubject({});
  currentData = this.dataSource.asObservable();

  public reelTitle$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor() { }

  storeData(newData: any){
    this.dataSource.next(newData)
  }

  passReelTitle(title: any){
    this.reelTitle$.next(title);
  }



}

