import { Component, OnInit, OnDestroy } from '@angular/core';
import { CreateNewTestService } from 'src/app/shared/services/create-new-test.service';
import { CustomPopupBoxService } from 'src/app/shared/services/custom-popup-box.service';
import { TesterTestedListService } from 'src/app/shared/services/tester-tested-list.service';
import { environment } from 'src/environments/environment';
import { UpStoreSelectedVidDetialsService } from 'src/app/shared/services/up-store-selected-vid-detials.service';
import {NavigationEnd, ActivatedRoute, Router, Params} from '@angular/router';
import { HighlightReelApiService } from 'src/app/shared/services/highlight-reel-api.service';
import { HighlightReelUpdateService } from 'src/app/shared/services/highlight-reel-update.service';
import { AllFiltersShareQueryService } from 'src/app/shared/services/all-filters-share-query.service';

@Component({
  selector: 'app-highlight-reel-dashboard-new',
  templateUrl: './highlight-reel-dashboard-new.component.html',
  styleUrls: ['./highlight-reel-dashboard-new.component.scss']
})
export class HighlightReelDashboardNewComponent implements OnInit, OnDestroy {

  filteredJsonData = [];
  unfilteredJsonData = [];
  queriedJsonData = [];

  paginateCount: number = 0;
  paginationDisplayedCount: number = 0;
  baseUrl = environment.apiUrl;

  testIdAvailable: boolean = false;
  selectedProjectId: number;
  selectedTestId: number;

  buttonFilterData: any ={
    all: 0,
    draft: 0,
    archive: 0,
  };
  selectedChipFilter: string = "all";
  isDataAvailable:boolean = true;
  showCustomPopupBox: boolean = false;
  customPopupBoxContent: string = "";
  checkReelNameIsValid: boolean = false;
  tempReelHeading: string = "";

  // custom popup
  showUPCustomPopup: boolean = false;
  customPopupDisplayContent: string = "";
  // rename
  renameValueExist:boolean = false;
  renameExistingValue: string = "";
  renameInputValue: string = "";
  renameSelectedReel: any;
  allFilterQueryDataObservable: any;
  clearIntervalCallId: any;
  reelAutoRefreshClearTimeoutId = [];
  
  openPopupId: any | null = null;  // Tracks the currently open popup's ID

  constructor(
    private _createnewTestServices: CreateNewTestService,
    public _customPopupBoxService: CustomPopupBoxService,
    private _testedTestServices: TesterTestedListService,
    private _upStoreSelectedVidDetialsService: UpStoreSelectedVidDetialsService,
    private _router: Router,
    private _highlightReelApiService: HighlightReelApiService,
    private _highlightReelUpdateService: HighlightReelUpdateService,
    private _activatedRoute: ActivatedRoute,
    private _allFiltersShareQueryService: AllFiltersShareQueryService,
  ) {
    this.allFilterQueryDataObservable = this._allFiltersShareQueryService.componentFilterQueryData.subscribe((res)=>{
      if(res.requestType != "none"){
        if(res.component == this._router.url.split('?')[0]){
          this.processComponentFilterData(res);
        }
      }
    });
  }


  togglePopup(id: any) {
    
       this.openPopupId = this.openPopupId == id ? null : id;
     }
  ngOnDestroy(): void {
    this.allFilterQueryDataObservable.unsubscribe();
    this.reelAutoRefreshClearTimeoutId.forEach((item)=>{
      clearTimeout(item);
    });
  }

  ngOnInit(): void {
    this.initializeComponentData();

    // this._allFiltersShareQueryService.componentFilterQueryData.subscribe((res)=>{
    //   if(res.requestType != "none"){
    //     if(res.component == this._router.url.split('?')[0]){
    //       this.processComponentFilterData(res);
    //     }
    //   }
    // });
  }


  initializeComponentData(){
    this._activatedRoute.queryParams.subscribe((params) => {
      if(params['project-id'] != undefined && params['test-id'] != undefined){
        this.testIdAvailable = true;
        this.selectedProjectId = params['project-id'];
        this.selectedTestId = params['test-id'];

        this.viewProjectAndTestRelatedData(params['project-id'], params['test-id']);
      }
      else if(params['project-id'] != undefined){
        this.testIdAvailable = false;
        this.selectedProjectId = params['project-id'];

        this.viewProjectRelatedVideos(params['project-id']);
      }
      else {
        this._router.navigate(['/project-selector']);
      }
    });
  }

  processComponentFilterData(value){
    if(value.requestType == "getDataFromParent"){
      this._allFiltersShareQueryService.sendDataParentToChild(this.unfilteredJsonData);
    }
    else if(value.requestType == "dataChildToParent"){
      let tempClipList = [] 
      this._allFiltersShareQueryService.childToParentDataVariable.subscribe((res)=>{
        tempClipList = res;
      });
      this.queriedJsonData = tempClipList;
      this.initializePaginationFun();
    }
    else if(value.requestType == "loadOriginalData"){
      this.initializeComponentData();
    }
  }


  viewProjectRelatedVideos(projectId){
    this._highlightReelApiService.getHighlightReelsWithProjectId(projectId).subscribe((res: any)=>{

      this.isDataAvailableFun(res.data.length);

      
      this.unfilteredJsonData = [];
      this.queriedJsonData = [];
      res.data.forEach(item => {
        this.unfilteredJsonData.push(item);
        this.queriedJsonData.push(item);
        this.ChipsFiltersInitialize(item);
      });

      this.validatePaginateCount();

      // sort with id
      this.queriedJsonData.sort(function(a: any, b: any){
        return a.id - b.id;
      })
      // decending/reverse order
      this.queriedJsonData.sort().reverse();
      
      // initially show only 10 items
      this.filteredJsonData = [];
      for(let i = 0; i < this.paginateCount; i++){
        this.filteredJsonData.push(this.queriedJsonData[i])
      }

      // if reel status is in INPROGRESS reload every 5 seconds
      console.log('called from viewProjectRelatedVideos');
      this.checkReelStatusInProgress();
    });
  }

  viewProjectAndTestRelatedData(projectId, testId){
    this._highlightReelApiService.getHighlightReelsWithProjectIdAndTestId(projectId, testId).subscribe((res: any)=>{

      this.isDataAvailableFun(res.data.length);

      this.unfilteredJsonData = [];
      this.queriedJsonData = [];
      res.data.forEach(item => {
        this.unfilteredJsonData.push(item);
        this.queriedJsonData.push(item);
        this.ChipsFiltersInitialize(item);
      });

      this.validatePaginateCount();

      // sort with id
      this.queriedJsonData.sort(function(a: any, b: any){
        return a.id - b.id;
      })
      // decending/reverse order
      this.queriedJsonData.sort().reverse();

      // initially show only 10 items
      this.filteredJsonData = [];
      for(let i = 0; i < this.paginateCount; i++){
        this.filteredJsonData.push(this.queriedJsonData[i])
      }

      // if reel status is in INPROGRESS reload every 5 seconds
      console.log('called from viewProjectAndTestRelatedData');
      this.checkReelStatusInProgress();
    });

  }

  ChipsFiltersInitialize(value){
    // initialize chips filter data
    if(value.reel_status == 2){ // draft
      this.buttonFilterData.draft ++;
    }
    else if(value.reel_status == 5){ // archive
      this.buttonFilterData.archive ++;
    }
  }

  initializeView(){
    this._highlightReelApiService.getAllHighlightReels().subscribe((res)=>{

      this.isDataAvailableFun(res.length);

      res.forEach(item => {
        this.unfilteredJsonData.push(item);
        // Button Filter Count calculate
        // if(item.project_status == 0){
        //   this.buttonFilterData.draft = this.buttonFilterData.draft + 1;
        // }
      });

      this.validatePaginateCount();
      
      // initially show only 10 items
      for(let i = 0; i < this.paginateCount; i++){
        this.filteredJsonData.push(this.unfilteredJsonData[i])
      }

    });

  }

  isDataAvailableFun(value){
    // if json data is empty show empty message
    if(value > 0){
      this.isDataAvailable = true;
    } else {
      this.isDataAvailable = false;
    }
  }


  validatePaginateCount(){
    if(this.queriedJsonData.length < 10){
      this.paginateCount = this.queriedJsonData.length;
    } else {
      this.paginateCount = 10;
    }
  }

  paginateDataFun(value){
    this.paginateCount = value;
    let iterationValue = 0;
    let actualDataLength = this.unfilteredJsonData.length;
    this.filteredJsonData = [];

    if(this.paginateCount < actualDataLength){
        iterationValue = this.paginateCount;
        this.paginationDisplayedCount = this.paginateCount;
    } else{
        iterationValue = actualDataLength;
        this.paginationDisplayedCount = actualDataLength;
    }

    for(let i = 0; i < iterationValue; i++){
      this.filteredJsonData.push(this.unfilteredJsonData[i]);
    }
  }

  incrementPaginationCountFun(value){

    let actualQueriedDataLength = this.queriedJsonData.length;

    if(value < actualQueriedDataLength){
      this.paginateCount = value;
    } else {
      this.paginateCount = actualQueriedDataLength;
    }

    this.restrictDataCount()
  }

  applyButtonFilterFun(value){

    if(value === "all"){
      // if filter applied is "all-projects"
      this.selectedChipFilter = "all";
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
          this.queriedJsonData.push(item);
      })
    }
    // if filter applied is "draft"
    else if(value === "draft"){
      this.selectedChipFilter = "draft";
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        if(item.project_status == 0){
          this.queriedJsonData.push(item);
        }
      })
    }
    else if(value === "archive"){
      // if filter applied is "archive"
      this.selectedChipFilter = "archive";
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        if(item.project_status == 5){
          this.queriedJsonData.push(item);
        }
      })
    }
    this.initializePaginationFun();
    this.isDataAvailableFun(this.queriedJsonData.length);
  }


  initializePaginationFun(){
    this.validatePaginateCount();
    this.filteredJsonData = [];
    for(let i = 0; i < this.paginateCount; i++){
      this.filteredJsonData.push(this.queriedJsonData[i])
    }
  }


  restrictDataCount(){
    this.filteredJsonData = [];
    for(let i = 0; i < this.paginateCount; i++){
      this.filteredJsonData.push(this.queriedJsonData[i])
    }
  }


  showContextMenu(event){
    let tempElement = undefined;

    if(event.target.className == "dash-context-menu-wrapper"){
      tempElement = event.target.children[1];
    } else if(event.target.className == "dash-context-menu-icon"){
      tempElement = event.target.parentElement.children[1];
    }

    // let tempElement = event.target.parentElement.children[1];
    if(tempElement.style.display == "none"){

      // close previous opened popup menu
      let allElements = document.getElementsByClassName("dash-context-menu-cont");
      for (let i = 0; i < allElements.length; i++) {
          allElements.item(i).setAttribute("style", "display: none");
      }

      // open popup menu on clicked card
      tempElement.style.display = "block";

    } else{

      // close popup menu after clicking again
      tempElement.style.display = "none";
    }


  }

  storeSelectedVideoDetials(value){
    this._upStoreSelectedVidDetialsService.setValue(value);
    localStorage.setItem('cacheSelectedVideoDetials', JSON.stringify(value));
    this._router.navigate(["/videos-dashboard/view"]);
  }

  viewSelectedClip(reelDetials){
    // this._upStoreSelectedVidDetialsService.setValue(clipDetials);
    
    this._highlightReelApiService.getHighlightReelsWithReelId(reelDetials.id).subscribe((res: any) => {
      localStorage.setItem('cacheSelectedVideoDetials', JSON.stringify(res.data));

      this._activatedRoute.queryParams.subscribe((params) => {
        if(params['project-id'] != undefined && params['test-id'] != undefined){
          this._router.navigateByUrl("results/highlight-reels/view");
        }
        else if(params['project-id'] != undefined){
          localStorage.setItem('selectedTestId', "");
          this._router.navigateByUrl("results/highlight-reels/view");
        } 
      });
    });
  }


  reelNameValidation(value){
    if(value != ""){
      if(value.indexOf(' ') >= 0){
        // space not present
        this.checkReelNameIsValid = true;
      } else{
         // space present
        this.checkReelNameIsValid = false;
      }
    }
    else{
      this.checkReelNameIsValid = true;
    }
  }

  editHighlightReel(item){
    this._highlightReelUpdateService.storeData(item);

    if(this.testIdAvailable == true){
      this._router.navigate(['/results/highlight-reels/edit'], {queryParams: {'project-id': this.selectedProjectId, 'test-id': this.selectedTestId}});
      // this._router.navigate(["/edit-highlight-reel"]);
    } else {
      localStorage.setItem('selectedTestId', "");
      this._router.navigate(['/results/highlight-reels/edit'], {queryParams: {'project-id': this.selectedProjectId}});
    }
  }

  CreateReel(){
    this._highlightReelUpdateService.passReelTitle(this.tempReelHeading);

    if(this.testIdAvailable == true){
      this._router.navigate(['/results/highlight-reels/create'], {queryParams: {'project-id': this.selectedProjectId, 'test-id': this.selectedTestId}});
      // this._router.navigate(["/edit-highlight-reel"]);
    } else {
      localStorage.setItem('selectedTestId', "");
      this._router.navigate(['/results/highlight-reels/create'], {queryParams: {'project-id': this.selectedProjectId}});
    }
  }


  openCustomPopup(displayContent){
    this.customPopupDisplayContent = displayContent;
    this.showUPCustomPopup = true;
  }


  handleRenameValueChanges(event){
    // console.log("event", event)
    let x = this.unfilteredJsonData.find((e) =>{ return(e.highlight_reel_name.toLowerCase() === event.toLowerCase())})
    if(x == undefined){
      this.renameValueExist = false;
    } else {
      this.renameValueExist = true;
    }
  }

  sendRenameRequest(){
    let jsonData;

    if(this.testIdAvailable){
      jsonData = {
        "clip_list": this.renameSelectedReel.clip_list,
        "highlight_reel_name": this.renameInputValue,
        "project_id": this.selectedProjectId,
        "test_id": this.selectedTestId,
        "highlight_reel_id": this.renameSelectedReel.id
      }
    } else{
      jsonData = {
        "clip_list": this.renameSelectedReel.clip_list,
        "highlight_reel_name": this.renameInputValue,
        "project_id": this.selectedProjectId,
        "test_id": null,
        "highlight_reel_id": this.renameSelectedReel.id
      }
    }

    this._highlightReelApiService.newUpdateHighlightReel(jsonData).subscribe((res) => {
      this.showUPCustomPopup = false;
      this.initializeComponentData();
    });
  }

  selectedReelRename(reelDetials){
    this.renameExistingValue = reelDetials.highlight_reel_name; 
    this.renameInputValue = reelDetials.highlight_reel_name;
    this.renameSelectedReel = reelDetials;
  }

  createReelInputAutoFocus(){
    setTimeout(()=>{
      const x = document.getElementById("create-reel-input-box") as HTMLInputElement;
      x.focus();
    },100);
  }

  checkReelStatusInProgress(){
    console.log('checkReelStatusInProgress called 1');
    let reelInProgress = this.unfilteredJsonData.some(arrayItem=>arrayItem.reel_status.toString().toLowerCase() === "inprogress");

    if(reelInProgress){
      let that = this;
      console.log('inside if calling 1');
      let tempVarClearTimeoutId = setTimeout(()=>{that.initializeComponentData();},10000);
      this.reelAutoRefreshClearTimeoutId.push(tempVarClearTimeoutId);
      console.log('this.reelAutoRefreshClearTimeoutId :', this.reelAutoRefreshClearTimeoutId);
    }else{
      this.reelAutoRefreshClearTimeoutId = [];
    }
  }



}
