import { NumberFormatStyle } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ViewChild, HostListener, Injectable, OnDestroy } from '@angular/core';
import { SelectedVideoDetailsService } from 'src/app/modules/new-videos/selected-video-details.service';
import { UpStoreSelectedVidDetialsService } from 'src/app/shared/services/up-store-selected-vid-detials.service';
import { environment } from 'src/environments/environment';
import {NavigationEnd, Router, Params, ActivatedRoute} from '@angular/router';
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';

export enum KEY_CODE {
  SPACE = 'Space',
  ESC = 'Escape'
}

@Component({
  selector: 'app-plugin-video-player',
  templateUrl: './plugin-video-player.component.html',
  styleUrls: ['./plugin-video-player.component.scss']
})
export class PluginVideoPlayerComponent implements OnInit, OnDestroy {
  
  @ViewChild("videoplayer") videoplayer: any;
  @ViewChild("progess") progressbar: any;
  @ViewChild("videoplayercontainer") videoplayercontainer: any;
  @ViewChild("playercontrolls") playercontrolls: any;
  @ViewChild("range") range: any;
  @ViewChild("copyvideolink") copyvideolink: any;

  video_link: string;
  videoplaying: boolean = false;
  playbackspeed: any = [
    {
      id: 1,
      speed: 0.25
    },
    {
      id: 2,
      speed: 0.5
    },
    {
      id: 3,
      speed: 0.75
    },
    {
      id: 4,
      speed: 'Normal'
    },
    {
      id: 5,
      speed: 1.25
    },
    {
      id: 6,
      speed: 1.5
    },
    {
      id: 7,
      speed: 1.75
    },
    {
      id: 8,
      speed: 2
    },
  ];
  defaultspeed: any = this.playbackspeed[3];
  showspeedlist: boolean = false;
  videodurationbytime: string = '00:00'
  videodurationbyseconds: number = 0;
  videocurrentbytime: string = '00:00';
  videocurrentbyseconds: number = 0;
  fullscreen: boolean = false;
  timedelay: number = 1;
  videosound: boolean = true;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // if (event.code == KEY_CODE.SPACE) {
    //   this.PlayVideo();
    // } else 
    if (event.code == KEY_CODE.ESC) {
      document.exitFullscreen();
      this.fullscreen = false;
    }
  }

  // new variabes
  baseUrl = environment.apiUrl;
  selectedVideoDetials: any;

  constructor(
    private dataservice: SelectedVideoDetailsService,
    public _router: Router,
    private toastMsgService: ToastMsgService,
    private _upStoreSelectedVidDetialsService: UpStoreSelectedVidDetialsService
    ) {
    this.dataservice.videocurrentbytime.subscribe((sub) => {
      if (sub[1] == '0') {
        this.videocurrentbytime = sub.slice(3, sub.length);
      } else {
        this.videocurrentbytime = sub;
      }
    })

    this.dataservice.videoplaying.subscribe((sub) => {
      this.videoplaying = sub;
    })

    this.dataservice.videoplaying.subscribe((sub) => {
      if(sub){        
        this.videoplaying = sub;
        this.videoplayer.nativeElement.play();
      } else {
        this.videoplaying = sub
        this.videoplayer.nativeElement.pause();
      }
    })
  }

  ngOnInit(): void {
    this.selectedVideoDetials = JSON.parse(localStorage.getItem("cacheSelectedVideoDetials"));
    if(/\/results\/highlight-reels\/view.*/i.test(this._router.url)){
      this.video_link = `${this.baseUrl}${this.selectedVideoDetials.video_path}`;
    }
    else if(/\/results\/clips\/view-clip.*/i.test(this._router.url)){
      this.video_link = `${this.baseUrl}${this.selectedVideoDetials.clip_path}`;
      console.log("file: plugin-video-player.component.ts:125  this.video_link", this.video_link)
    }
  }

  ngOnDestroy() {
    const video = this.videoplayer.nativeElement;
    video.pause();
    video.src = '';
    video.load();
    video.remove();
  }


  ngAfterViewInit(): void {
    this.initializeVideoTime();
  }

  formatTime(timeInSeconds: any) {
    var dateObj = new Date(timeInSeconds * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getSeconds();

    var timeString = hours.toString().padStart(2, '0')
      + ':' + minutes.toString().padStart(2, '0')
      + ':' + seconds.toString().padStart(2, '0');

    return timeString;
  };

  initializeVideoTime() {
    const video = document.createElement('video');
    // video.src = this.video_link;
    video.src =  `${this.baseUrl}${this.selectedVideoDetials.clip_path}`;
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      const time = this.formatTime(video.duration);

      if (time[1] == '0') {
        this.videodurationbyseconds = video.duration;
        this.videodurationbytime = time.slice(3, time.length);
      } else {
        this.videodurationbyseconds = video.duration;
        this.videodurationbytime = time;
      }

    }
  }

  updateTimeElapsed() {
    var percentage = (this.videoplayer.nativeElement.currentTime / this.videoplayer.nativeElement.duration) * 100
    document.getElementById('fill').style.width = percentage + "%"
    const time = this.formatTime(this.videoplayer.nativeElement.currentTime);
    this.dataservice.SetVideoCurrentTime(time);
    if (time[1] == '0') {
      this.videocurrentbyseconds = this.videoplayer.nativeElement.currentTime;
    } else {
      this.videocurrentbyseconds = this.videoplayer.nativeElement.currentTime;
    }
  }

  PlayVideo() {
    if (this.videoplaying) {
      this.dataservice.SetVideoPlaying(false);
    } else {
      this.dataservice.SetVideoPlaying(true);
    }
  }

  SoundControll() {
    this.showspeedlist = false;
    if (this.videosound) {
      this.videoplayer.nativeElement.volume = 0;
      this.videosound = false;
    } else {
      this.videoplayer.nativeElement.volume = 1;
      this.videosound = true;
    }
  }

  ChangeTimeRange(value: any) {
    var time = (value.offsetX / document.getElementById('progess').offsetWidth) * this.videoplayer.nativeElement.duration
    this.videoplayer.nativeElement.currentTime = time;

    var percentage = (this.videoplayer.nativeElement.currentTime / this.videoplayer.nativeElement.duration) * 100
    document.getElementById('fill').style.width = percentage + "%"
    this.videoplayer.nativeElement.play();
    this.videoplaying = true;
    // document.getElementById('fill').style.transition = '0.5s'
  }

  PictureInPicture() {
    this.videoplayer.nativeElement.requestPictureInPicture();
    this.showspeedlist = false;
    this.fullscreen = false;
    document.getElementById('progess').style.width = ""
    document.getElementById('progess').style.position = "absolute"
    document.getElementById('progess').style.bottom = ""
  }

  DownloadVideo() {
    this.showspeedlist = false;
  }

  PlaybackSpeedList() {
    this.showspeedlist = true;
  }

  ChangePlaybackSpeed(speedvalue: any) {
    this.showspeedlist = false;
    this.videoplayer.nativeElement.playbackRate = speedvalue;
    for (const i of this.playbackspeed) {
      if (speedvalue === i.speed) {
        this.defaultspeed = i
      } else if (speedvalue === '1') {
        this.defaultspeed = this.playbackspeed[3];
      }
    }
  }

  FullScreen() {
    this.showspeedlist = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
      this.fullscreen = false;
      // this.stopTimer();
      document.getElementById('progess').style.width = ""
      document.getElementById('progess').style.position = "absolute"
      document.getElementById('progess').style.bottom = ""
      document.getElementById("div-hide").style.display = "block"

    } else if (this.videoplayercontainer.nativeElement.webkitFullscreenElement) {
      this.videoplayercontainer.nativeElement.webkitExitFullscreen();
      this.fullscreen = true;
      // this.startTimer();
      document.getElementById('progess').style.width = "100vw"
      document.getElementById('progess').style.position = "fixed"
      document.getElementById('progess').style.bottom = "57px"

    } else if (this.videoplayercontainer.nativeElement.webkitRequestFullscreen) {
      this.videoplayercontainer.nativeElement.webkitRequestFullscreen();
      this.fullscreen = true;
      document.getElementById('progess').style.width = "100vw"
      document.getElementById('progess').style.position = "fixed"
      document.getElementById('progess').style.bottom = "57px"

    } else {
      this.videoplayercontainer.nativeElement.requestFullscreen();
      this.fullscreen = true;
      document.getElementById('progess').style.width = "100vw"
      document.getElementById('progess').style.position = "fixed"
      document.getElementById('progess').style.bottom = "57px"

    }
  }
  
  startTimer() {
    var value = this
    document.getElementById("div-hide").style.display = 'block'
    document.getElementById("div-hide").style.transition = 'all 0.2s ease'
    setTimeout(function () {
      if(value.fullscreen){
        document.getElementById("div-hide").style.display = 'none'
        document.getElementById("div-hide").style.transition = 'all 0.2s ease'
      }
    }, 5000);
  }

  CopyLink() {
    let div = document.getElementById('copyvideolink');
    let text = div.innerText;
    let textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.append(textArea);
    textArea.select();
    document.execCommand('copy');   //No i18n
    document.body.removeChild(textArea);
  }

  BackwordVideo() {
    this.videoplayer.nativeElement.pause();
    this.videoplayer.nativeElement.currentTime = this.videoplayer.nativeElement.currentTime - 5
    this.videoplayer.nativeElement.play();
  }

  ForwordVideo() {
    this.videoplayer.nativeElement.pause();
    this.videoplayer.nativeElement.currentTime = this.videoplayer.nativeElement.currentTime + 5
    this.videoplayer.nativeElement.play();
  }

  copyVideoUrlToClipboard(){
    navigator.clipboard.writeText(this.video_link);
    this.toastMsgService.showMsg("success", "Video URL copied", 2000);
  }

}
