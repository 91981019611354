<div class='Userpeek-container lg-screen-width up-signup-2-container'>
  <div class='section'>
    <article class='header'>
      <h2>Hi there!</h2>
      <p>Tell us a bit about yourself.</p>
    </article>

    <div class='profile_container complete-container up-signup-2-form-container'>

      <form (ngSubmit)='onCompleteRegisterForm()' [formGroup]='clientProfileForm' class="up-signup-2-form">
        <div formGroupName='extra'>
              <label class='profile-input'>
                <span class='name'>First name</span>
                <input formControlName='first_name' type='text'/>
              </label>
              <div *ngIf="
                  (submitted &&
                  clientProfileForm.controls.extra.get('first_name').errors?.required) ||
                  clientProfileForm.get('extra.first_name').touched
                ">
                <div *ngIf="clientProfileForm.controls.extra.get('first_name').errors?.required">
                  <small class='text-danger'> First name is required</small>
                </div>
              </div>
              <label class='profile-input' style="margin-top: 18px;">
                <span class='name'>Last name</span>
                <input formControlName='last_name'  type='text'/>
              </label>
              <div *ngIf="
                  (submitted &&
                    clientProfileForm.controls.extra.get('last_name').errors?.required) ||
                  clientProfileForm.get('extra.last_name').touched
                ">
                <div *ngIf="clientProfileForm.controls.extra.get('last_name').errors?.required">
                  <small class='text-danger'> Last name is required</small>
                </div>
              </div>

          <div class='update_btn-container'>
            <button class='btn-sty-blue up-signup-create-btn' type='submit' [disabled]="!clientProfileForm.valid" [ngClass]="[clientProfileForm.valid ? 'up-signup-create-btn-active' : 'up-signup-create-btn-inactive']">
              Create your account
            </button>
            <!-- <span class='required'>*required</span> -->
          </div>
        </div>
      </form>

    </div>
    <button class='feedback-btn'>Feedback</button>
  </div>
</div>
