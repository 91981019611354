<div *ngIf="customPopupService.showPopup$ | async" class="custom-popup-backdrop">
<!-- <div *ngIf="true" class="custom-popup-backdrop"> -->
    <div class="custom-popup-body">
        <img (click)="closePoup()" class="custom-popup-close-icon" src="assets/images/icons/close-icon.svg" alt="close icon">

        <div class="custom-popup-content">
           
            <!-- after signup notify to confirm email -->
            <div class="signup-email-confirm" *ngIf='showContentType === "signup-confirm-email" '>
                <section class="header">
                    <h1>Welcome to UserPeek!</h1>
                    <P><img src="assets/images/icons/green-tickmark.svg" alt="tick icon"> Your account was successfully created.</P>
                </section>

                <main>
                    <p class="text1">
                        We just sent you an email with a confirmation link. Please confirm your email address to complete the registration.
                    </p>
                    <p class="text2">
                        Didn't get the email? Check your spam folder. Still not there? Contact UserPeek's 
                        <a class="a-sty" href="https://helpcenter.userpeek.com/contact-support/" target="_blank">Support.</a>
                    </p>
                </main>

                <button (click)="closePoup()" class="custom-popup-main-btn btn-sty-blue">Got it.</button>
            </div>
            
            <!-- create project popup -->
            <div class="create-project" *ngIf='showContentType === "create-project-popup" '>

                <section class="header">
                    <h1>Create project</h1>
                    <!-- <P><img src="assets/images/icons/green-tickmark.svg" alt="tick icon"> Your account was suc?cessfully created.</P> -->
                </section>

                <main style="margin-top: 60px">
                    <label for="project-name">Project name</label>
                    <input (ngModelChange)="checkProjectNameExist($event)" [(ngModel)]="createProjectInputValue" class="up-input-text" type="text" id="project-name">
                    <p class="error-txt" *ngIf="doesProjectNameExist" style="margin-top:10px;"> 
                        The project name already exists 
                    </p>
                </main>

                <button (click)="popupCreateProject()" class="custom-popup-main-btn btn-sty-blue"
                [disabled]="doesProjectNameExist" [ngClass]="[!doesProjectNameExist && (createProjectInputValue.trim().length > 2) ? 'up-btn-active' : 'up-btn-inactive']">
                    Create project
                </button>
                <p style="font-size: 18px; font-family: var(--font-medium); text-align:center;" (click)="closePoup()" class="a-sty">Cancel</p>
            </div>

            <!-- order videos -->
            <div class="create-project" *ngIf='showContentType === "order-videos-no-subscription" '>
            <!-- <div class="order-videos-cont" *ngIf='true'> -->

                <section class="header">
                    <h1>UserPeek Testers</h1>
                    <P>Order videos with testers from the UserPeek panel</P>
                </section>

                <main style="margin-top: 35px">

                    <div class="no-of-testers-cont">
                        <div class="no-of-testers-txt">
                            <p>Number of testers: </p>
                            <img src="assets/images/icons/info.svg" alt="info icon">
                        </div>
                        <div class="no-of-testers-input">
                            <input [(ngModel)]="videoCount" type="number">
                        </div>
                    </div>

                    <div class="order-detials-table" style="margin-top: 30px">
                        <table>
                            <tr class="order-title-row">
                                <th>Your order</th>
                                <th>{{videoCount}} testers</th>
                            </tr>
                            <tr *ngIf="videoCount > 0" class="order-data-row">
                                <td>$individualVideoPrice per tester (no subscription)</td>
                                <td>${{individualVideoPrice*videoCount}}</td>
                            </tr>
                            <tr *ngIf="videoCount > 0" class="order-footer-row">
                                <th>Total</th>
                                <th>${{individualVideoPrice*videoCount}}</th>
                            </tr>
                        </table>
                    </div>

                    <div class="order-offer-section">
                        <div class="row1">
                            <div class="left">
                                <a class="a-sty" routerLink='/credits-plans'>Upgrade to Grow?</a>
                                <span>23% OFF</span>
                            </div>
                            <div class="right"> 
                                <span style="font-size: 22px; font-family: var(--font-medium);">$211</span>
                                <span style="font-size: 16px; font-family: var(--font-regular);">/month</span>
                            </div>
                        </div>
                        <div class="row2">
                            <div class="left">5 credits à $35 per tester</div>
                            <div class="right">billed annually</div>
                        </div>
                        <div class="row3">
                            <a class="a-sty" routerLink='/credits-plans'>Show me all features</a>
                        </div>
                    </div>

                </main>

                <button (click)="popupCreateProject()" class="custom-popup-main-btn btn-sty-blue"
                    [disabled]="!videoCount" [ngClass]="[ videoCount > 0 ? 'up-btn-active' : 'up-btn-inactive']">
                    Pay ${{individualVideoPrice*videoCount}} (no subscription)
                </button>

                <p style="font-size: 18px; font-family: var(--font-medium); text-align:center; margin-top: 25px;"
                (click)="closePoup()" class="a-sty">Cancel</p>

            </div>



            
        </div>

    </div>
</div>