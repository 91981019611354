import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';

export class AppUtils {

  static getString(value: any): string {
    return this.getStringDefault(value, '');
  }

  static getStringDefault(value: any, defVal: string): string {
    return value ? value.toString() : defVal;
  }

  // This is use For Handle The Api Error Response
  static handleError(error: HttpErrorResponse) {
    let errorMessage = null;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = error.error;
    }
    // return an observable with a user-facing error message
    return throwError(errorMessage || 'Something bad happened; please try again later.');
  }
}
