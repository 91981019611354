<div *ngIf="projectsAvailable === 'available' " class="box-container">
  <h4>Select a project</h4>

  <p>Project name</p>

  <select class='test-list-input' [(ngModel)]="selectedProjectId">
    <option value="select project">---- select ----</option>
    <option *ngFor='let project of allProjectList' [ngValue]='project.id'>
      {{ project.project_name }}
    </option>
  </select>

  <button (click)="changeProject()" class="btn-sty-blue">Select project</button>
</div>


<div *ngIf="projectsAvailable === 'not-available' " class="box-container-2">
  <ng-container>
    <div class="create-project">

      <section class="header">
          <h1 style="font-family: var(--font-semibold);font-size: 30px;">Create project</h1>
      </section>

      <main style="margin-top: 60px">
          <label for="project-name" style="font-family: var(--font-regular);font-size: 18px;">Project name</label>
          <input (ngModelChange)="checkProjectNameExist($event)" [(ngModel)]="createProjectInputValue" class="up-input-text" type="text" id="project-name">
          <p class="error-txt" *ngIf="doesProjectNameExist" style="margin-top:10px;"> 
              The project name already exists 
          </p>
      </main>

      <button (click)="popupCreateProject()" class="custom-popup-main-btn btn-sty-blue"
        [ngClass]="[!doesProjectNameExist && createProjectInputValue.trim().length > 2 ? 'up-btn-active' : 'up-btn-inactive']">
          Create project
      </button>
  </div>
  </ng-container>
</div>

