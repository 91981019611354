import { Component, OnInit } from '@angular/core';
import { SelectedVideoDetailsService } from '../selected-video-details.service';
import { TesterTestedListService } from 'src/app/shared/services/tester-tested-list.service';
import { Clipboard } from '@angular/cdk/clipboard'
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';

@Component({
  selector: 'app-transcript',
  templateUrl: './transcript.component.html',
  styleUrls: ['./transcript.component.scss']
})
export class TranscriptComponent implements OnInit {

  tasklist: any = [];
  viewtask: number = 0;
  selectedText: string;
  contextmenu: boolean = false;
  menuTopLeftPosition = { x: '0', y: '0' };
  resultText: any;
  resultData: any;
  selectedVideoDetails: any;
  notescliplist: any = [];
  clips: any;
  notes: any;
  clipOrQuote: boolean = true;
  transcriptDataSource:any = {};

  constructor(private selectedvideodetailsservice: SelectedVideoDetailsService,
    private testedTestListServices: TesterTestedListService,
    private clipboard: Clipboard,
    public toastMsgService: ToastMsgService,
    ) {
    this.selectedvideodetailsservice.selectedTranscribeText.subscribe((sub) => {
      this.selectedText = sub;
    })
  }

  ngOnInit(): void {
    this.GetTranscriptData();
  }

  GetTranscriptData() {
    this.selectedVideoDetails = JSON.parse(localStorage.getItem("cacheSelectedVideoDetials"));
    let body = {
      "id": this.selectedVideoDetails.id
    }
    this.testedTestListServices.getTestedTestTranscribeList(body).subscribe((res) => {
      let tempTasklist = res.data;
      this.transcriptDataSource = {};
      this.transcriptDataSource = res.data;
      Object.values(tempTasklist).map((task) => {
        this.tasklist.push(task);
      })
    })


  }

  ShowDescription(id: number) {
    if (this.viewtask === id) {
      this.viewtask = null;
    } else {
      this.viewtask = id
    }
  }

  CopyText() {
    this.clipboard.copy(this.selectedText);
    document.getElementById('contextmenu').style.display = "none";
    this.toastMsgService.showMsg("success", "Text copied", 2800);
  }

  OpenMenu(event: any) {
    event.preventDefault();

    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
    var text = "";
    if (window.getSelection) {
      text = window.getSelection().toString();
    }
    this.selectedText = text;
    if (this.selectedText.length !== 0) {
      document.getElementById('contextmenu').style.display = "flex"

    } else {
      document.getElementById('contextmenu').style.display = "none"
    }

  }

  SetClick() {
    var text = "";
    if (window.getSelection) {
      text = window.getSelection().toString();
    }
    this.selectedText = text;
    if (this.selectedText.length !== 0) {
      document.getElementById('contextmenu').style.display = "flex"
    } else {
      document.getElementById('contextmenu').style.display = "none"
    }
  }

  createClipFromSelectedText(){
    let x:string = window.getSelection().toString();
    let selectedText = x.trim();

    let selectedTestArrayList = selectedText.toString().split(" " || ".");

    // Checking word count for validation
    console.log("selectedTestArrayList.length",selectedTestArrayList.length);
    if(selectedTestArrayList.length >= 4){
      let startTimeFirstWord = {
        word: selectedTestArrayList[0].replace(/[^a-zA-Z ']/g, ""),
        indexValue: 0,
        time: undefined,
      };
      let startTimeSecondWord = {
        word: selectedTestArrayList[1].replace(/[^a-zA-Z ']/g, ""),
        indexValue: 0,
        time: undefined,
      };
      let startTimeThirdWord = {
        word: selectedTestArrayList[2].replace(/[^a-zA-Z ']/g, ""),
        indexValue: 0,
        time: undefined,
      };

      let endTimeLastWord = {
        word: selectedTestArrayList[selectedTestArrayList.length - 1].replace(/[^a-zA-Z ']/g, ""),
        indexValue: 0,
        time: undefined,
      };
      let endTimeLastSecondWord = {
        word: selectedTestArrayList[selectedTestArrayList.length - 2].replace(/[^a-zA-Z ']/g, ""),
        indexValue: 0,
        time: undefined,
      };
      let endTimeLastThirdWord = {
        word: selectedTestArrayList[selectedTestArrayList.length - 3].replace(/[^a-zA-Z ']/g, ""),
        indexValue: 0,
        time: undefined,
      };

      let clipStartDuration = undefined;
      let clipEndDuration = undefined;

      for(let key in this.transcriptDataSource){
          if(this.transcriptDataSource[key].text.transcript.includes(selectedText)){

              let selectedWordsArray = [];
              selectedWordsArray = this.transcriptDataSource[key].text.items;

              selectedWordsArray.forEach((element, index) => {
                  // get 1st word start time
                  if(element.alternatives[0].content.toLowerCase() === startTimeFirstWord.word.toLowerCase()){
                    console.log('first word: ', selectedWordsArray[index].alternatives[0].content);
                    if(selectedWordsArray[index+1].alternatives[0].content.toLowerCase() === startTimeSecondWord.word.toLowerCase()){
                        console.log('second word: ', selectedWordsArray[index+1].alternatives[0].content);
                        if(selectedWordsArray[index+2].alternatives[0].content.toLowerCase() === startTimeThirdWord.word.toLowerCase()){
                            console.log('third word: ', selectedWordsArray[index+2].alternatives[0].content);
                            console.log('selected 1st word',element);
                            startTimeFirstWord.time = element.start_time;
                            startTimeFirstWord.indexValue = index;
                            clipStartDuration = new Date(Number(startTimeFirstWord.time) * 1000).toISOString().slice(14, 19);
                        }
                    }
                  }
              });

              selectedWordsArray.forEach((element, index) => {
                // get last word end time
                    if(element.alternatives[0].content.toLowerCase() === endTimeLastWord.word.toLowerCase()){
                      console.log('last 1st word',element.alternatives[0].content );
                        if(!(index-1 < 0)){
                            if(selectedWordsArray[index-1].alternatives[0].content.toLowerCase() === endTimeLastSecondWord.word.toLowerCase()){
                              console.log('last 2nd word',selectedWordsArray[index-1].alternatives[0].content);
                                if(selectedWordsArray[index-2].alternatives[0].content.toLowerCase() === endTimeLastThirdWord.word.toLowerCase()){
                                  console.log('last 3rd word',selectedWordsArray[index-2].alternatives[0].content);
                                  console.log('selected last word',element);
                                    endTimeLastWord.time = element.end_time;
                                    endTimeLastWord.indexValue = index;
                                    clipEndDuration = new Date(Number(endTimeLastWord.time) * 1000).toISOString().slice(14, 19);
                                }
                            }
                        }
                    }
                });

          }
      }

      if(typeof(clipStartDuration) !== "undefined" && typeof(clipEndDuration) !== "undefined"){
        // send post request to create clip
        let jsonBody = {
          "video_url": this.selectedVideoDetails.file,
          "clip": {
            "start": clipStartDuration,
            "end": clipEndDuration,
            "notes": selectedText
          }
        };
        console.log("jsonBody:", jsonBody)

        this.testedTestListServices.addClip(jsonBody).subscribe((res) => {
          if (res.status == 200) {
            this.toastMsgService.showMsg("success", "Clip created successfully", 3000);
            this.selectedvideodetailsservice.initializeNotesAndClips("call function");
            document.getElementById('contextmenu').style.display = "none";
            // this.GetClipNotesList()
          } else { 
            this.toastMsgService.showMsg("failed", "Clip creation failed", 3000);
            document.getElementById('contextmenu').style.display = "none";
          }
        });
      } else {
        this.toastMsgService.showMsg("failed", "Clip creation failed", 3000);
        document.getElementById('contextmenu').style.display = "none";
      }
    } else {
        this.toastMsgService.showMsg("failed", "Select atleast 4 word", 3000);
        document.getElementById('contextmenu').style.display = "none";
    }




  }

  createNotesFromSelectedText(){
    let x:string = window.getSelection().toString();
    let selectedText = x.trim();

    let selectedTestArrayList = selectedText.toString().split(" " || ".");




    // Checking word count for validation
    console.log("selectedTestArrayList.length",selectedTestArrayList.length);
    if(selectedTestArrayList.length >= 2){

      let startTimeFirstWord = {
        word: selectedTestArrayList[0].replace(/[^a-zA-Z ']/g, ""),
        indexValue: 0,
        time: undefined,
      };
      console.log("startTimeFirstWord:", startTimeFirstWord)
      
      let startTimeSecondWord = {
          word: selectedTestArrayList[1].replace(/[^a-zA-Z ']/g, ""),
          indexValue: 0,
          time: undefined,
      };
      console.log("startTimeSecondWord:", startTimeSecondWord)

      let clipStartDuration = undefined;

        for(let key in this.transcriptDataSource){
            if(this.transcriptDataSource[key].text.transcript.includes(selectedText)){
              console.log('selected text group finded');
                let selectedWordsArray = [];
                selectedWordsArray = this.transcriptDataSource[key].text.items;
                console.log("selectedWordsArray:", selectedWordsArray)
                console.log('startTimeFirstWord.word.toLowerCase', startTimeFirstWord.word.toLowerCase);
                selectedWordsArray.forEach((element, index) => {
                    // get 1st word start time
                    if(element.alternatives[0].content.toLowerCase() === startTimeFirstWord.word.toLowerCase()){
                      console.log('1st word matched ', element.alternatives[0].content.toLowerCase() === startTimeFirstWord.word.toLowerCase());
                      startTimeFirstWord.time = element.start_time;
                        startTimeFirstWord.indexValue = index;
                        console.log("get 1st word start time",startTimeFirstWord);
                        // get 2nd word start time
                        selectedWordsArray.forEach((element1, index) => {
                            if(element1.alternatives[0].content.toLowerCase() === startTimeSecondWord.word.toLowerCase()){
                                startTimeSecondWord.time = element1.start_time;
                                startTimeSecondWord.indexValue = index;
                                console.log("get 2nd word start time",startTimeSecondWord);
                                clipStartDuration = new Date(Number(startTimeFirstWord.time) * 1000).toISOString().slice(14, 19);
                                console.log("clipStartDuration:", clipStartDuration)
                            }
                        });
                    }
                });

            }
        }


      if(typeof(clipStartDuration) !== "undefined"){
          // send post request to create clip
          let jsonBody = {
            "test": this.selectedVideoDetails.test,
            "tested_test": this.selectedVideoDetails.id,
            "text": selectedText,
            "clip": false,
            "start": clipStartDuration,
            "end": ""
          }
          console.log("jsonBody:", jsonBody);

          this.testedTestListServices.addNotes(jsonBody).subscribe((res) => {
            if (res.status == 200) {
              this.toastMsgService.showMsg("success", "Notes created successfully", 3000);
              this.selectedvideodetailsservice.initializeNotesAndClips("call function");
              document.getElementById('contextmenu').style.display = "none";
            }
            else{
              this.toastMsgService.showMsg("failed", "Notes creation failed", 3000);
              document.getElementById('contextmenu').style.display = "none";
            }
          })
      } else{
          this.toastMsgService.showMsg("failed", "Notes creation failed", 3000);
          document.getElementById('contextmenu').style.display = "none";
      }


    }
    else{
        // error
        this.toastMsgService.showMsg("failed", "Select atleast 2 word", 3000);
        document.getElementById('contextmenu').style.display = "none";
    }
  }


}
