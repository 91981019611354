<div class='row'>
  <div class='col'>
    <h3>
      {{ testListItemDetails.test_name }}
      <span class='blue'>{{ testListItemDetails.project_name }}</span>
      <span class='{{ getBadgeLabel(testListItemDetails.test_status).className }}'>{{
        getBadgeLabel(testListItemDetails.test_status).labelText }}</span>
    </h3>
  </div>
  <div class='col-auto'>
    <div class='userpeek-dropdowns-sub'>
            <span (myCustomEvent)='onMyCustomEvent()' appShowHideEvent>
<!--              (click)='showOrderScreen(testListItemDetails.id, i)'-->
                <button
                        class='testedit-btn userpeek-dropdowns-btn'>
                    <i class='dots'></i><i class='dots'></i><i class='dots'></i>
                </button>
            </span>
<!--      <div *ngIf='-->
<!--              testListItemDetails.id == testId1 &&-->
<!--              showEditOption &&-->
<!--              i == showTestEditId-->
<!--            ' [ngSwitch]='testListItemDetails.test_status' class='userpeek-dropdowns' style='display: block'>-->
<!--        <ul *ngSwitchCase=''0' || '3''>-->
<!--          <li>-->
<!--            <a (click)='onChangeTestStatus(testListItemDetails,'duplicate')' class='cursor'-->
<!--               data-target='#test-credit' data-toggle='modal'><img alt='dubicate-icons'-->
<!--                                                              src='assets/images/icons/dublicate.png'/>-->
<!--              Duplicate</a>-->
<!--          </li>-->
<!--        </ul>-->
<!--        <ul *ngSwitchCase=''6''>-->
<!--          <li>-->
<!--            <a [routerLink]='['/dashboard_tests/edit', testListItemDetails.id]' class='cursor'><img-->
<!--              alt='edit-icons' src='assets/images/icons/edit.png'/>-->
<!--              Edit Test</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a (click)='onChangeTestStatus(testListItemDetails,'duplicate')' class='cursor'-->
<!--               data-target='#test-credit' data-toggle='modal'><img alt='dubicate-icons'-->
<!--                                                              src='assets/images/icons/dublicate.png'/>-->
<!--              Duplicate</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a (click)='-->
<!--                onChangeTestStatus(-->
<!--                    testListItemDetails,-->
<!--                    'archive'-->
<!--                  )-->
<!--                  ' class='cursor'><img alt='archive-icons' src='assets/images/icons/archive.png'/>-->
<!--              Archive</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a (click)='-->
<!--                onChangeTestStatus(-->
<!--                    testListItemDetails,-->
<!--                    'delete',-->
<!--                    i-->
<!--                  )-->
<!--                  ' class='cursor'><img alt='delete-icons' src='assets/images/icons/delete.png'/>-->
<!--              Delete</a>-->
<!--          </li>-->
<!--        </ul>-->
<!--        <ul *ngSwitchCase=''5''>-->
<!--          <li>-->
<!--            <a (click)='-->
<!--                onChangeTestStatus(-->
<!--                    testListItemDetails,-->
<!--                    'delete',-->
<!--                    i-->
<!--                  )-->
<!--                  ' class='cursor'><img alt='delete-icons' src='assets/images/icons/delete.png'/>-->
<!--              Delete</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a (click)='-->
<!--                onChangeTestStatus(-->
<!--                      testListItemDetails,-->
<!--                      'restore'-->
<!--                    )-->
<!--                  ' class='cursor'><img alt='restore-icons' src='assets/images/icons/restore.png'-->
<!--                                        style='margin-left: -4px'/>-->
<!--              Restore</a>-->
<!--          </li>-->
<!--        </ul>-->
<!--        <ul *ngSwitchCase=''3' || '0''>-->

<!--          <li (click)='onChangeTestStatus(testListItemDetails,'duplicate')'>-->
<!--            <a class='cursor' data-target='#test-credit' data-toggle='modal'><img-->
<!--              alt='dubicate-icons' src='assets/images/icons/dublicate.png'/>-->
<!--              Duplicate</a>-->
<!--          </li>-->

<!--        </ul>-->
<!--        <ul *ngSwitchDefault>-->
<!--          <li *ngIf='-->
<!--                  testListItemDetails.test_status != 0 && testListItemDetails.test_status != 3-->
<!--                '>-->
<!--            <a [routerLink]='['/dashboard_tests/edit', testListItemDetails.id]' class='cursor'><img-->
<!--              alt='edit-icons' src='assets/images/icons/edit.png'/>-->
<!--              Edit Test</a>-->
<!--          </li>-->
<!--          <li (click)='onChangeTestStatus(testListItemDetails,'duplicate')'>-->
<!--            <a class='cursor' data-target='#test-credit' data-toggle='modal'><img-->
<!--              alt='dubicate-icons' src='assets/images/icons/dublicate.png'/>-->
<!--              Duplicate</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a (click)='-->
<!--                onChangeTestStatus(-->
<!--                      testListItemDetails,-->
<!--                      'archive'-->
<!--                    )-->
<!--                  ' class='cursor'><img alt='archive-icons' src='assets/images/icons/archive.png'/>-->
<!--              Archive</a>-->
<!--          </li>-->

<!--          <li>-->
<!--            <a (click)='-->
<!--                onChangeTestStatus(-->
<!--                      testListItemDetails,-->
<!--                      'delete',-->
<!--                      i-->
<!--                    )-->
<!--                  ' class='cursor'><img alt='delete-icons' src='assets/images/icons/delete.png'/>-->
<!--              Delete</a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
    </div>
    <div aria-hidden='true' aria-labelledby='exampleModalLabel' class='modal fade' id='test-credit' role='dialog'
         tabindex='-1'>
      <div class='modal-dialog' role='document'>
        <form (ngSubmit)='callForDuplicateTest()' [formGroup]='changeTestName'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h5 class='modal-title' id='exampleModalLabel'>
                Change {{ oldTestName }}
              </h5>
              <button aria-label='Close' class='close' data-dismiss='modal' type='button'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div class='modal-body'>
              <div class='form-group'>
                <label class='col-form-label' for='recipient-name'>Test name*
                </label>
                <input class='form-control' formControlName='testname' required type='text'/>
                <div *ngIf="
                        (submitted &&
                          changeTestName.controls.testname.errors) ||
                        changeTestName.get('testname').touched
                      " class='invalidfeedback'>
                  <div *ngIf='
                          changeTestName.controls.testname.errors
                            ?.required ||
                          changeTestName.controls.testname.errors
                            ?.testname
                        '>
                    <small class='text-danger'>Please change a test name</small>
                  </div>
                </div>
              </div>
            </div>
            <div class='modal-footer'>
              <button class='btn btn-secondary' data-dismiss='modal' type='button'>
                Close
              </button>
              <button #closeAddExpenseModal class='btn btn-primary' style='background-color: #1200ff'
                      type='submit'>
                Save changes
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class='row'>
  <div class='col-sm'>
    <div class='test-link'>
      <label>
        <span class='checkbox'><input type='checkbox'/> <span class='checkbox-custom'></span></span>

        {{ testListItemDetails.start_url }}, Language: {{ testListItemDetails.language }}
        <span *ngIf='testListItemDetails.articipants_audience.age'>
                    , Age: {{ testListItemDetails.articipants_audience.age }}, Gender:
          {{ testListItemDetails.articipants_audience.gender }} , Region:
          {{ testListItemDetails.articipants_audience.region }}
                </span>
      </label>
    </div>

    <div class='test-edit'>
            <span *ngIf='testListItemDetails.test_status ==0 || testListItemDetails.test_status==3'
                  class='test-edit-success'><img alt='question'
                                                 src='assets/images/icons/question-mark.png'/>{{testListItemDetails.total_required_videos}}
              of {{testListItemDetails.completed_video_count}}
              done</span>
      <span *ngIf="testListItemDetails.test_status ==5 || testListItemDetails.test_status ==2">
                <a [routerLink]="['/dashboard_tests/edit', testListItemDetails.id]">{{
                  getBadgeLabel(testListItemDetails.test_status).editTest
                  }}</a>
            </span>

    </div>
  </div>

  <div [ngSwitch]='testListItemDetails.test_status' class='col-auto'>
    <!-- <div *ngIf='testId2 === testListItemDetails.id'> -->
    <div *ngIf='hideDraftbtn'>
      <button (click)='openOrderTesterScreen()' *ngSwitchCase='2'
              class='test-gray ml-auto detail-dropdown-btn show'>
        Get videos
      </button>
      <!-- </div> -->
    </div>
    <!-- <button  class='test-gray ml-auto detail-dropdown-btn show'  *ngSwitchCase='0'>
Edit Order
</button>  -->
    <button (click)='getNewVideos(testListItemDetails.id, testListItemDetails)'
            *ngSwitchCase='3' class='test-gray ml-auto detail-dropdown-btn show'>
      Get new video
    </button>
    <button *ngSwitchCase='7' class='test-gray ml-auto detail-dropdown-btn show'>
      Reniew video
    </button>
<!--    (click)='reactiveTest(testListItemDetails.id, testListItemDetails)'-->
    <button
            *ngSwitchCase='6' class='test-gray ml-auto detail-dropdown-btn show'>
      Reactive Test
    </button>
    <span *ngSwitchDefault></span>
    <!-- <button class='test-gray ml-auto detail-dropdown-btn show' *ngSwitchDefault>
Get videos
</button>  -->
    <!-- <button class='test-gray ml-auto' (click)='toggle(testListItemDetails.id,testListItemDetails.test_status)' >{{getBadgeLabel(testListItemDetails.test_status).statusBtn}}</button> -->
    <!-- <button class='test-gray ml-auto' (click)='getBadgeLabel(testListItemDetails.test_status).method' (click)='toggle(testListItemDetails.id)'>{{getBadgeLabel(testListItemDetails.test_status).statusBtn}}</button> -->
  </div>
</div>
<div *ngIf='isOrderTestScreenOpen' class='test-details detail-dropdown' style='display: block'>
  <app-test-order (emitTriggredOrderData)='orderTestTriggredData($event)'
                  [seletedTestOrderDetails]='testListItemDetails'></app-test-order>
  <!-- <form [formGroup]='testerCount' (ngSubmit)='orderTesterVideo()'>
      <h4>How many videos do I need?</h4>
      <div class='row test-details-row'>
        <div class='col-md-5'>
          <h5>Sessions</h5>
          <div class='range-container'>
            <div class='range-sub'>
              <img src='assets/images/icons/screen-icon.png' class='range-icon' alt='icon' />
              <label class='value'><span id='screenvalue'>
                  <input type='text' mask='00' class='value' style='border: 0px'
                    formControlName='desktop_tester_count' (keyup)='DesktoponKey($event)'
                    [(ngModel)]='Desktopvalue' />

                </span></label>
              <div class='range-input-sub'>
                <ng5-slider [options]='options' [(value)]='Desktopvalue'
                  (valueChange)='DesktopvalueChange($event)' formControlName='desktop_tester_count'
                  (userChange)='calculatePercent()' (userChangeEnd)='validateOrderCredits()' id='myRange'>
                </ng5-slider>

              </div>
            </div>

            <div class='range-sub'>
              <img src='assets/images/icons/mobile-icon.png' class='range-icon' alt='icon' />
              <label class='value'>
                <input type='text' mask='00' max='15' class='value' style='border: 0px'
                  formControlName='mobile_tester_count' (keyup)='MobileonKey($event)'
                  [(ngModel)]='Mobilevalue' />

                <span id='screentablet'></span></label>
              <div class='range-input-sub'>
                <ng5-slider [options]='options' id='tablet' [(value)]='Mobilevalue'
                  (valueChange)='MobilevalueChange($event)' formControlName='mobile_tester_count'
                  (userChange)='calculatePercent()' (userChangeEnd)='validateOrderCredits()'>
                </ng5-slider>

              </div>
            </div>

            <div class='range-sub'>

              <img src='assets/images/icons/tablet-icon.png' class='range-icon' alt='icon' />
              <label class='value'>
                <input type='text' mask='00' max='15' class='value' style='border: 0px'
                  formControlName='tab_tester_count' (keyup)='onKey($event)' [(ngModel)]='value' />
                <span id='screenmobile'></span></label>
              <div class='range-input-sub'>
                <ng5-slider [options]='options' [(value)]='value' id='mobile'
                  formControlName='tab_tester_count' (userChange)='calculatePercent()'
                  (valueChange)='valueChange($event)' (userChangeEnd)='validateOrderCredits()'>
                </ng5-slider>

              </div>
            </div>
          </div>
        </div>
        <div class='col-md-7'>
          <h5>Average percentage of problems revealed</h5>
          <div class='progress-sub'>
            <div class='progress'>
              <div class='progress-bar' role='progressbar' aria-valuenow='96' aria-valuemin='0'
                aria-valuemax='100' [style.width]='averageDesktopPercentage'></div>
            </div>
            <span class='sr-only'><span class='percentange'>{{
                averageDesktopPercentage
                }}</span>
              Desktop</span>
          </div>
          <div class='progress-sub'>
            <div class='progress'>
              <div class='progress-bar' role='progressbar' aria-valuenow='84' aria-valuemin='0'
                aria-valuemax='100' [style.width]='averageMobilePercentage'></div>
            </div>
            <span class='sr-only'><span class='percentange'>{{
                averageMobilePercentage
                }}</span>
              Mobile</span>
          </div>
          <div class='progress-sub'>
            <div class='progress'>
              <div class='progress-bar' role='progressbar' aria-valuenow='averageTabPercentage'
                aria-valuemin='0' aria-valuemax='100' [style.width]='averageTabPercentage'></div>
            </div>
            <span class='sr-only'><span class='percentange'>{{
                averageTabPercentage
                }}</span>
              Tablet</span>
          </div>
        </div>
      </div>
      <div class='row'>
        <div class='col-12 test-details-button text-right'>
          <button (click)='closeVideoCountTab()' class='detail-close' style='margin-right: 5px'>
            Close
          </button>

          <button class='order-count' type='submit'>
            Order {{ orderVideoCount }} videos
          </button>
        </div>
      </div>
    </form> -->
</div>
