import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Markers} from './../../core/models/video-marker';
import {Rating} from 'src/app/core/models/rating';
import {AddDescription} from 'src/app/core/models/addDescription';
import {catchError} from 'rxjs/operators';
import {BaseService} from '../../core/api-call/base.service';
import {appApiResources} from './api.constant';
import {TestAndTesterDetails} from "../../modules/videos/services/interface";

@Injectable({
  providedIn: 'root'
})
export class TesterTestedListService extends BaseService {

  player: any
  public _getQuickNote = new Subject<any>();
  marker = new Subject<[]>()
  private _testVideoListId = new Subject<string>();
  testedId$ = this._testVideoListId.asObservable();
  private _testVideoListProjectName = new Subject<string>();
  testedIdProjectName$ = this._testVideoListProjectName.asObservable();

  sendTestId(testId: any) {
    this._testVideoListId.next(testId)
  }

  sendTestIdProjectName(testProjectName: any) {
    this._testVideoListId.next(testProjectName)
  }

  getTestedTestVideoList(): Observable<any> {
    return this.get(appApiResources.testVideoList);
  }

  dummyVideoList(): Observable<any> {
    return this.get("https://62457f132cfed188172199b5.mockapi.io/videos");
  }

  getTestedTestTranscribeList(body: any): Observable<any> {
    return this.post(appApiResources.transcribeTaskList, body);
  }

  getExampleVideo() {
    return this.get(appApiResources.adminExampleVideo);
  }

  getTestedTestVideoListId(id): Observable<any> {
    return this.get(appApiResources.testVideoList + id);
  }

  addMarker(marker: Markers): Observable<any> {
    return this.post(appApiResources.testBookmarks, marker);
  }

  addClip(clip: any): Observable<any> {
    return this.post(appApiResources.clipVideo, clip);
  }

  checkClipStatus(id: any): Observable<any> {
    return this.get(appApiResources.clipStatus, {clip_id: id} );
  }

  addNotes(notes: any): Observable<any> {
    return this.post(appApiResources.testBookmarks, notes);
  }

  updateClip(clip: any): Observable<any> {
    return this.put(appApiResources.clipVideo, clip);
  }

  getClipList(testedTestId: any): Observable<any> {
    return this.get(appApiResources.getClipVideos, {test_id: testedTestId});
  }

  getClipListNew(testedTestId: any, video_id: any): Observable<any> {
    return this.get(appApiResources.getClipVideos, {test_id: testedTestId, test_video_id: video_id});
  }

  // getMarkerList(test) {
  //   return this.get(appApiResources.testBookmarks, {id: test});
  // }

  getMarkerList(test) {
    return this.get(appApiResources.testBookmarks, {project_id: test});
  }

  getMarkerList2(project, test) {
    return this.get(appApiResources.testBookmarks, {test_id: test,project_id: project});
  }

  getNotesListNew(project: any, test: any, video_id: any) {
    return this.get(appApiResources.testBookmarks, {test_id: test,project_id: project, test_video_id: video_id});
  }

  updateMarker(id: any, marker: Markers): Observable<any> {
    return this.put(appApiResources.testBookmarks, marker, {id: id});
  }

  deleteMarker(id) {
    return this.delete(appApiResources.testBookmarks, {id: id});
  }

  addRating(rate: Rating): Observable<any> {
    return this.post(appApiResources.testRating, rate);
  }


  getRating(test) {
    return this.get(appApiResources.testRating, {id: test});
  }

  addDescription(addDesc: AddDescription): Observable<any> {
    return this.post(appApiResources.testDescription, addDesc);
  }

  getDescription(test): Observable<any> {
    return this.get(appApiResources.testDescription, {id: test}).pipe(catchError(this.handleError));
  }

  // project all test




  watchVideo(id) {
    return this.get(appApiResources.testMarkedWatched, {id: id}).pipe(catchError(this.handleError));
  }

  getVideoJob(videoId) {
    return this.post(appApiResources.getVideoJob, {video_url: videoId}).pipe(catchError(this.handleError));
  }

 transcriptionTextDisplay(videoJob) {
   return this.post(appApiResources.speechToText, {job_name: videoJob}).pipe(catchError(this.handleError));
 }

 getTestAndTesterDetails(video_url: string): Observable<TestAndTesterDetails> {
    return this.post<TestAndTesterDetails>(appApiResources.testAndTesterDetails, {video_url: `media/${video_url}`});
 }

 addNote(jsonData){
    return this.put(appApiResources.testTestedVideo, jsonData);
 }

  getProjectRelatededVideo(project_id) {
    return this.get(appApiResources.testVideoByProject, {project_id: project_id}).pipe(catchError(this.handleError));
  }

  getVideosWithProjectId(project_id) {
    return this.get(appApiResources.videosWithProjectId, {project_id: project_id}).pipe(catchError(this.handleError));
  }

  getVideosWithProjectIdAndTestId(project_id, test_id) {
    return this.get(appApiResources.videosWithProjectId, {project_id: project_id, test_id: test_id}).pipe(catchError(this.handleError));
  }

  getClipsWithProjectId(project_id) {
    return this.get(appApiResources.clipsWithProjectId, {project_id: project_id}).pipe(catchError(this.handleError));
    // return this.get("https://62457f132cfed188172199b5.mockapi.io/clipList").pipe(catchError(this.handleError));
  }

  getClipsWithProjectIdAndTestId(project_id, test_id) {
    return this.get(appApiResources.clipsWithProjectId, {project_id: project_id, test_id: test_id}).pipe(catchError(this.handleError));
  }

  deleteClip(clip: any): Observable<any> {
    return this.deleteWithBody(appApiResources.deleteClipVideo, clip)
  }
}
