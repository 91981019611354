

<div class="create-test-comp">

  <!--  OLD UI -->
  <!-- <div *ngIf='TestInstructions' class='container instructions-container lg-screen-width create-test-comp-container1'> -->
  <div *ngIf='TestInstructions' class='container instructions-container lg-screen-width create-test-comp-container1'>
    
    <div class='instruction_content create-test-comp-content1'>
      <h2 class="create-test-title">1. Test instructions</h2>

      <!-- <h5 *ngIf="TestInstructions" class="back-btn-one" (click)="TestInstructions = !TestInstructions">
        <img src="assets/images/icons/arrow-left-black.svg" alt=""> ( 2. Select target group )
      </h5> -->
      <div style="margin-bottom: 60px;"></div>

      <form [formGroup]='createNewTests'  (ngSubmit)="onSubmit()">
  
        <article class='create-test-input-box content-shadow'>

          <div class='sub-row'>
            <h3>Test name*</h3>
            <input class='input create-test-txt-input' formControlName='test_name'  type='text'/>
            <div *ngIf="
                (submitted && createNewTests.controls.test_name.errors) ||
                createNewTests.get('test_name').touched
                " class='invalidfeedback'>
              <div *ngIf="
                  createNewTests.controls.test_name.errors?.required ||
                  createNewTests.controls.test_name.errors?.test_name
                  ">
                <small class='create-test-error-txt text-danger' >Please enter a test name</small>
              </div>
            </div>
            <label class="create-test-note-txt">For internal use. Your testers won't see this.</label>
            <ng-container *ngIf="testNameAlreadyExists === true">
              <br><small class='create-test-error-txt text-danger'>Test name already exists! Please choose a different name for your test.</small>
            </ng-container>
          </div>

          <div class='sub-row' style="margin-top: 56px !important">
            <h3>Project name*</h3>
            <input *ngIf='existingProjectToggle == false' class='create-test-txt-input input' formControlName='project_name'  type='text'/>
            <select *ngIf='existingProjectToggle == true' class='create-test-txt-input' formControlName='project_name'>
              <option *ngFor='let project of allProjectList' [ngValue]='project'>
                {{ project }}
              </option>
            </select>
            <!-- <input *ngIf='existingProjectToggle == true' [attr.disabled]='true' class='create-test-txt-input input' formControlName='project_name' type='text'/> -->
  
            <div *ngIf="
                (submitted && createNewTests.controls.project_name.errors) ||
                createNewTests.get('project_name').touched
              " class='invalidfeedback'>
              <div *ngIf="
                  createNewTests.controls.project_name.errors?.required ||
                  createNewTests.controls.project_name.errors?.project_name
                ">
                <small class='text-danger create-test-error-txt'>Please enter a project name</small>
              </div>
            </div>
            <label class='addproject'>
              <input (change)='existingProjectToggle = !existingProjectToggle' [checked]='existingProjectToggle' formControlName='project_flag' type='checkbox'/>
              <span class='checkbox-span'></span>
              Add to existing project
            </label>
          </div>
        </article>
  
        <article class='create-test-input-box content-shadow '>
          <div class='sub-row' style="margin-top: 48px; position: relative;">
            <h3 style="margin: 0px !important;">Test URL</h3>
            <label class='addproject app-test'>
              <input disabled="true" formControlName='is_app' type='checkbox'/>
              <span class='checkbox-span'></span>This is an app test
            </label>
            <span class='profile-image-tooltips'>
              <i class='fa fa-info-circle' style='margin-left: 10px'></i>
              <!-- <span class='profile-tooltips' style='margin-left: -60px; margin-top: -12px'>For app you must fill app url link !</span> -->
              <span class='profile-tooltips' style='margin-left: -60px; margin-top: -12px'>Coming soon!</span>
            </span>
            <img class="app-url-link-logo" src="assets/images/icons/link-new-icon.svg" alt="link-logo">
            <input style="margin-top: 10px; padding-left: 45px !important;" class='create-test-txt-input input' formControlName='start_url' placeholder='https://www.website.com' type='text'/>
  
            <div *ngIf="
                (submitted && createNewTests.controls.start_url.errors) ||
                createNewTests.get('start_url').touched
              " class='invalidfeedback'>
              <div *ngIf='createNewTests.controls.start_url.errors?.required'>
                <small class='create-test-error-txt text-danger'> Please enter a URL</small>
              </div>
              <div *ngIf='createNewTests.controls.start_url.errors?.pattern'>
                <small class='create-test-error-txt text-danger'> Must be a valid url</small>
              </div>
            </div>
            <label style="margin-top: 10px !important;" class='create-test-note-txt typein'>Test takers start the test here. This URL will be opened automatically.</label>
            <label style="margin-top: 6px !important;" class='create-test-note-txt typein'>For app tests only: Type in the download URL to your app.</label>
          </div>
        </article>
  
        <article class='create-test-input-box content-shadow' style="margin-top: 42px !important;">
          <div class='sub-row'>
            <h3>Language</h3>
            <select class='create-test-txt-input' formControlName='language' name='language'>
              <option value='' selected>Select...</option>
              <option value='English'>English</option>
            </select>
<!-- 
            <label class='language-label'>
              <input formControlName='language' name='language' type='radio' value='English'/>
  
              <span class='radio-container'>
                <span class='radio-span'></span>
              </span>
              English
            </label> -->
            <div *ngIf="(submitted && createNewTests.controls.language.errors?.required) || createNewTests.get('language').touched">
              <div *ngIf='createNewTests.controls.language.errors?.required'>
                <small class='create-test-error-txt text-danger'> Please select a language</small>
              </div>
            </div>

            <label style="margin-top: 14px !important;" class='create-test-note-txt typein'>Select language that you want the tester to answer.</label>
          </div>
        </article>

        <article class='create-test-input-box content-shadow' style="margin-top: 102px !important;">
          <div class='sub-row'>
            <h3 #templates>Templates</h3>
            <select class='create-test-txt-input' name='language' (change)="handleTemplateChange($event.target.value)" >
              <option value='empty' selected>Select template</option>
              <option *ngFor='let task of taskTemplatesList' [value]='task.title'>{{ task.title }}</option>
            </select>

              <!-- <div *ngIf="(submitted && createNewTests.controls.language.errors?.required) || createNewTests.get('language').touched">
              <div *ngIf='createNewTests.controls.language.errors?.required'>
                <small class='text-danger'> Please select a language</small>
              </div>
            </div> -->

          </div>
        </article>
  
        <article class='create-test-input-box content-shadow instructions-article'>

            <div class='test-intro-title'>
            
               
                  <h3>Test instructions</h3>
                  <a href='https://helpcenter.userpeek.com/kb/how-to-create-good-test-instructions/' target='_blank'>How to create good test instructions</a>
  
                <!-- <div class='col-sm-auto'>
                  <div (myCustomEvent)='onMyCustomEvent()' appShowHideEvent class='userpeek-dropdowns-sub templates'>
                    <button (click)='toggle()' class='testedit-btn userpeek-dropdowns-btn show' type='button'>
                      Templates
                    </button>
                    <div *ngIf='showRadio' class='userpeek-dropdowns' style='display: block'>
                      <ul>
                        <li (click)='toggle()' *ngFor='
                            let testInstructionType of templates;
                            let i = index
                          '>
                          <span *ngIf='i > 0'>
                            <a (click)='
                                changeTestInstructionType(testInstructionType)
                              ' style='cursor: pointer'>
                              {{ testInstructionType }}
                            </a>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> -->
           
            </div>

          <!-- @note test intro -->
    
          <div class="sub-row test-intro-textarea">
            <h4>Scenario</h4>
            <p>This will be shown to the testers before the test begins.</p>
            <textarea maxlength="500" class='instructions-textarea' formControlName='test_introduction'></textarea>
            <div *ngIf="(submitted && createNewTests.controls.test_introduction.errors?.required) || createNewTests.get('test_introduction').touched">
              
              <div *ngIf='createNewTests.controls.test_introduction.errors?.required'>
                <small class='text-danger create-test-error-txt'> Please fill the test introduction.</small>
              </div>
              

              <div *ngIf="submitted && createNewTests.get('test_instructions')['controls']['length'] <= 1">
                <small class='text-danger create-test-error-txt'>Please add at least one task.</small>
              </div>
            </div>
            <p class="test-intro-limit">Characters left: {{ createNewTests.value.test_introduction | wordCount }}</p>
          </div>        

         
          <div class='sub-row' formArrayName='test_instructions' cdkDropList cdkDropListData="test_instructions" (cdkDropListDropped)="drop($event)"  >
            <div *ngFor="let tests of createNewTests.get('test_instructions')['controls']; index as i" class="drag-item" cdkDrag 
            cdkDragHandle
            [attr.data-index]="i">
              <div class="test-instructions-btns-cont" *ngIf="createNewTests.get('test_instructions')['controls']['length'] >= 1">
                <div class="test-instructions-title-cont">
                  <img src="assets/images/icons/list.svg" alt="link-logo">
                  <select class=' create-test-txt-input'>
                    <option selected value='Task' selected>Task</option>
                  </select>
                </div>
                <div class="test-instructions-btns">
                  <img (click)="copyToClipboard(i+1)" class="test-inst-duplicate-btn" src="assets/images/icons/duplicate-new-icon.svg" alt="duplicate btn">
                  <img (click)='removeTest(i)' class="test-inst-delete-btn" src="assets/images/icons/delete-new-icon.svg" alt="remove btn">
                </div>
              </div>
              <textarea maxlength="500" class='instructions-textarea test-inst-textbox-input' formControlName='{{ i }}' id='test_instructions_{{ i + 1 }}'   name='instructions'></textarea>
              <div *ngIf="submitted && createNewTests.get('test_instructions')['controls'][i].errors?.required">
                <small class='text-danger'>
                  Please fill the test instruction
                </small>
              </div>
              <p class="test-instructions-limit">Characters left: {{ (createNewTests.value.test_instructions[i] | wordCount) || 500 }}</p>
            </div>
            </div>

          <div class="add-task-btn-container">
            <!-- <p class='selected-template'>
              {{ selectedInstructionType }} Template
            </p> -->
  
            <button (click)='onAddTests()' class='btn-sty-blue-outline' type='button'>
              <p class="icon-cont"></p> Add task
            </button>
          </div>

        </article>
  
        <div class='pg1-submit-btn-cont'>
          <button *ngIf='editCreatedTest.length > 0' class='btn-sty-blue-outline update-close-btn ' type='submit'>
            Update and close
          </button>
  
          <button (click)="createTests() ;  " class='select-audience-btn btn-sty-blue' type='submit'>
            Select target group 
          </button>

          <button id="openTemplateSection" style="visibility: hidden;" (click)="templates.scrollIntoView()" (click)="dummyFun()"></button>
        </div>
      </form>
    </div>
  
    <!-- <button class='feedback-btn'>Feedback</button> -->
  </div>
  

  <!-- @note Page 2 starts -->
  <div *ngIf='!TestInstructions' class='container instructions-container lg-screen-width create-test-pg2'>

  <div class="create-test-comp-content2">

    <h2 class="create-test-title">2. Select target group</h2>

    <!-- <h5 *ngIf="!TestInstructions" class="back-btn-two" (click)="TestInstructions = !TestInstructions">
      <img src="assets/images/icons/arrow-left-black.svg" alt=""> ( 1. Test instructions )
    </h5> -->
    <div style="margin-bottom: 60px;"></div>

    <form [formGroup]='createNewTests'>

      <!-- sub group -->
      <div class="pg2-sub-form-group" formGroupName='articipants_audience'>

        <div class='sub-row'>
          <ng-container *ngFor="
              let tests of createNewTests.get('test_instructions')['controls'];
              index as i
            ">
            <div *ngIf="
                submitted &&
                createNewTests.get('test_instructions')['controls'][i].errors
                  ?.required
              ">
              <small class='text-danger create-test-error-txt'> Please fill the test instruction </small>
            </div>
          </ng-container>
        </div>

        <label class="up-radio-btn-cont" style="font-family: var(--font-semibold);">
          <input type="radio" name="pannel" checked="checked"> 
          From UserPeek panel
          <span class="up-radio-btn-icon"></span>
        </label>
    
        <p class="info-title-1">
          Basic demographics
        </p>
    
        <div class="pg2-input-cont">
          <h3>Gender</h3>
          <select class='create-test-txt-input' formControlName='gender'>
            <option value='All' selected>All</option>
            <option value='Female'>Female</option>
            <option value='Male'>Male</option>
            <option value='Other'>Other</option>
          </select>
        </div>

        <div class="pg2-input-cont  countryDiv" style="margin-top: 30px;">
          <h3>Region</h3>
          <div id="countries-container" #toggleButton class="dropdown-container" formControlName="region"   (click)="toggleDropdown($event); setFocus(true)"   [class.focused]="isFocused"
          tabindex="0" >
            <input
              type="text"
              placeholder="Select..."
              readonly
              
              
              [value]="getSelectedCountriesDisplay()"
              class="countriesInput"
              style="border: gray; "
            />
            <svg height="48" class="dropdown-icon" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z" />
              <path d="M0-.75h48v48h-48z" fill="none" />
            </svg>
          </div>
         
          <div *ngIf="showError === true">
            <span style="color: red;">Please select country</span>
          </div>
          
          <!-- Dropdown List -->
          

          
          <div #menu *ngIf="isDropdownOpen" class="dropdown-list" id="dropdown-list" >
            <!-- Display first 4 countries initially -->
            <label *ngFor="let country of countries.slice(0, 4)" class="countriesList">
              <input
                type="checkbox"
                
                [checked]="selectedCountries.includes(country)"
                (change)="onCountryToggle(country, $event.target.checked)"
              />
              {{ country }}
            </label>
        
            <!-- "More countries" label -->
            <p class="more-label" >
              <span class="more-label-title">

                More countries
              </span>
              <span class='country-profile-image-tooltips' style="position: relative ;">
                <!-- <i class='fa fa-info-circle' style='margin-left: 10px; position: relative; ;' (click)="toggleTooltip()"></i> -->
                <img src="/assets/images/more-info.svg" alt="more-info-icon" style="width: 20px; height: 20px;  cursor: pointer;" (click)="toggleTooltip()">
                <!-- Tooltip Content -->
                <span *ngIf="isTooltipVisible" class='country-profile-tooltips' style='position: absolute; left: 40px; top: -5px; font-size: small;'>
                  We have smaller panels in these countries. Consider removing all other demographic criteria.
                  <i class="fa fa-times" style="margin-left: 5px; cursor: pointer;" (click)="closeTooltip($event)"></i>
                   
                  <img src="assets/images/triangle.png" alt="triangle-icon" style="position: absolute; top: 5px; left: -20px; width: 25px; height: 25px;"> 
                </span>
              </span>
              
            </p>
        
            <!-- Additional countries displayed after "More countries" is clicked -->
            <ng-container  >
              <label *ngFor="let country of countries.slice(4)" class="countriesList">
                <input
                  type="checkbox"
                  [checked]="selectedCountries.includes(country)"
                  (change)="onCountryToggle(country, $event.target.checked)"
                />
                {{ country }}
              </label>
            </ng-container>
          </div>
   
      </div>
        

        <div class="pg2-input-cont" style="margin-top: 30px;">
          <h3>Age</h3>
          <select class='create-test-txt-input' formControlName='age'>
            <option value='All' selected>All</option>
            <option value='18-24'>18-24</option>
            <option value='25-34'>25-34</option>
            <option value='35-50'>35-50</option>
            <option value='51 and Other'>51 and Other</option>
          </select>
        </div>

        <div class="pg2-input-cont" style="margin-top: 30px;">
          <h3 style="margin-bottom: 25px !important;">Device</h3>

          <!-- <label class="up-radio-btn-cont">
            <input type="radio" value="Any" formControlName='device_type'> 
            Any
            <span class="up-radio-btn-icon"></span>
          </label> -->

          <label class="up-radio-btn-cont" style="margin-top: 15px !important;">
            <input type="radio" value="Desktop" formControlName='device_type'> 
            Desktop
            <span class="up-radio-btn-icon"></span>
          </label>

          <!-- <label class="up-radio-btn-cont" style="margin-top: 15px !important;">
            <input type="radio" value="Mobile" formControlName='device_type'> 
            Mobile
            <span class="up-radio-btn-icon"></span>
          </label> -->

          <!-- <label class="up-radio-btn-cont" style="margin-top: 15px !important;">
            <input type="radio" value="Tablet" formControlName='device_type'> 
            Tablet
            <span class="up-radio-btn-icon"></span>
          </label> -->
          
        </div>

      </div>

      <!-- @note save button -->
      <div class='pg2-submit-btn-cont'>

        <button class='btn-sty-blue-outline save-close-btn' (click)="onCreateNewTest('save')" type='submit'>
          <span>{{editCreatedTest.length > 0 ? 'Update and close' : 'Save and close'}}</span>
        </button>

        <button type='submit' (click)="onCreateNewTest('order')" class='btn-sty-blue order-vid-btn' type="button">Order videos</button>

      </div>

    </form>
  
    <script src='assets/js/custom.js'></script>
  </div>




  </div>


</div>

<!-- 
  <div cdkDropList cdkDropListData="movies" (cdkDropListDropped)="drop($event)">
    <div *ngFor="let item of movies" cdkDrag>{{ item }}</div>
  </div> -->






