import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs';
import videojs from 'video.js';
import {TesterTestedListService} from 'src/app/shared/services/tester-tested-list.service';
import {CreateNewTestService} from 'src/app/shared/services/create-new-test.service';

import {environment} from 'src/environments/environment';
import {ModelBoxService} from 'src/app/shared/services/model-box.service';
import {convertToHHMMSS, navigateToCurrentTimeStatmp, parseTimeFunction,} from 'src/app/shared/services/api.constant';

@Component({
  selector: 'app-videos-info',
  templateUrl: './videos-info.component.html',
  styleUrls: ['./videos-info.component.scss'],
})
export class VideosInfoComponent implements OnInit, AfterViewInit, OnDestroy {
  baseUrl = environment.apiUrl;
  @ViewChild('closeAddExpenseModal', {static: false})
  closeAddExpenseModal: ElementRef;

  show: boolean = false;

  starRating: FormGroup;
  currentTime: any;
  testedVideoList: Subscription;
  testerTestedList = [];
  id: number;
  test: boolean;
  showRate: boolean = false;
  rating: boolean = false;
  properties: string = 'none';
  icon: boolean = true;
  icon1: boolean = false;
  icon2: boolean = false;
  icon3: boolean = false;
  descProp = 'none';
  duration: any;
  clipEdit1: boolean = false;
  tabDescription: boolean = false;
  starList: boolean[] = [true, true, true, true, true]; // create a list which contains status of 5 stars
  rating1: number;
  recordList: any[] = [
    {Id: 1, Framework: 'Angular JS', myList: [true, true, true, true, true]},
    {Id: 2, Framework: 'Angular 2', myList: [true, true, true, true, true]},
    {Id: 3, Framework: 'Angular 4', myList: [true, true, true, true, true]},
    {Id: 4, Framework: 'Angular 5', myList: [true, true, true, true, true]},
    {Id: 5, Framework: 'Angular 6', myList: [true, true, true, true, true]},
  ];
  subscription1: any;
  draftStatus: any;
  createTestList: any;
  testerTask = [];
  allMarkersList: any = [];
  ratingRate: any;
  remaingRating: any;
  getMarkerList = [];
  clipList: any;
  makerUnsub: Subscription;
  styleProp = 'none';
  showEditId: any;
  editMode: boolean = false;
  showMarkerEdit: boolean = false;
  testerTestedProfile = [];
  userTest: any;
  editMarkerId: any;
  totalRating: any;
  submitted = false;
  form: FormGroup;
  addQuickNoteForm: FormGroup;
  testedVideos: string = "";
  showDescrption: boolean = false;
  description: any;
  ratingMsg: string;
  arrow;
  testId: number;
  selectVideoDeatils: any;
  selectedTesterProfile: any;
  selectedTestDetails: any;
  quickNoteSubmitBtnTxt: any;

  constructor(
    private _testedTestServices: TesterTestedListService,
    private route: ActivatedRoute,
    private _createnewTestServices: CreateNewTestService,
    private fb: FormBuilder,
    private _modelBoxService: ModelBoxService
  ) {
  }

  ngOnInit() {
    // this.getAllTest();
    this.initRatingForm();
    this.initQuickNotesForm();
    this.setActiveParamsMap();

    // this.getDesc();
    // this.getAllMarker();
    this.starRating.patchValue({rating: this.rating1});
    this.getRateing();
    this.updateQuicknotesValues();
  }

  initRatingForm() {
    this.starRating = new FormGroup({
      id: new FormControl(this.id),
      test: new FormControl(this.testId),
      rating: new FormControl(this.rating1),
      feed_back: new FormControl(),
    });
  }

  initQuickNotesForm() {
    this.addQuickNoteForm = this.fb.group({
      note: ["", Validators.required],
    });
  }

  updateQuicknotesValues(){
    // get quicknotes values on component init
    this._testedTestServices.getTestedTestVideoList().subscribe(res => {
      let tempVideoDetials = res.data.video_list
      let selectedVideoDetails = tempVideoDetials.find(o => o.id == this.id);
      if(selectedVideoDetails.comments != null){
        this.addQuickNoteForm.patchValue({
          note: selectedVideoDetails.comments
        });
        this.quickNoteSubmitBtnTxt = "Update note"
      } else{
        this.quickNoteSubmitBtnTxt = "Add note"
      }
    })
  }

  setActiveParamsMap() {
    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.testId = +params['id1'];
      this.selectVideoDeatils = {
        testId: this.testId,
        videoId: this.id,
      };
    });
    
  }

  addQuickNotes() {

    this.submitted = true;

    if (this.addQuickNoteForm.invalid) {
      return;
    }

    this.submitted = false;

    let quickNotestDetails = {
      tested_test_id: 105,
      note: this.addQuickNoteForm.value.note,
    };

    this._testedTestServices.addNote(quickNotestDetails).subscribe((res)=>{
      if(res.status == 200){
        this.updateQuicknotesValues();
      }
    })

    // currentTime: convertToHHMMSS(this.getCurrentTime()),
    // this._testedTestServices._getQuickNote.next(quickNotestDetails);
  }

  getCurrentTime() {
    let videoCurrentTime = videojs('demo').currentTime();
    return videoCurrentTime;
  }

  // getTriggredEvent(event) {
  //   switch (event) {
  //     case 'create':
  //       this.getAllMarker();
  //       break;
  //     case 'update':
  //       this.getAllMarker();
  //       this.editMarker(null);
  //       break;
  //     case 'cancel':
  //       this.editMarker(null);
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // onMyCustomEvent() {
  //   this.showMarkerEdit = false;
  //   this.editMode = false;
  //   this.styleProp = 'none';
  // }

  setStar(data: any) {
    this.rating1 = data + 1;
    for (var i = 0; i <= 4; i++) {
      if (i <= data) {
        this.starList[i] = false;
      } else {
        this.starList[i] = true;
      }
    }
    this.starRating.patchValue({
      rating: this.rating1,
      test: this.testId,
      id: this.id,
    });
  }

  setStarTable(record: any, data: any) {
    this.rating1 = data + 1;
    var tableList = this.recordList.find(function (obj: any) {
      return obj.Id === record.Id;
    });
    for (var i = 0; i <= 4; i++) {
      if (i <= data) {
        tableList.myList[i] = false;
      } else {
        tableList.myList[i] = true;
      }
    }
  }

  // get f() {
  //   return this.clientNotes.controls;
  // }

  addRating1() {
    this._testedTestServices
      .addRating(this.starRating.value)
      .subscribe((rateRes) => {
        document.getElementById('tester-rating').click();
        this.getRateing();
      });
  }

  // jumpTimestamp(time, isTaskTimestamp?) {
  //   navigateToCurrentTimeStatmp(time, isTaskTimestamp);
  // }

  getRateing() {
    this._testedTestServices.getRating(this.id).subscribe((getRes) => {
      if (!getRes['data']) {
        this.ratingMsg = 'Please rate this tester';
      } else {
        this.ratingMsg = 'Edit rating';
      }
      getRes['data'].forEach((res) => {
        this.totalRating = res.rating;
        this.ratingRate = Array.from({length: res.rating});
        this.remaingRating = Array.from({length: 5 - res.rating});
        this.starRating.patchValue({feed_back: res.feed_back});
      });
    });
  }

  rateShow() {
    for (var i = 0; i < this.totalRating; i++) {
      if (i <= this.totalRating) {
        this.starList[i] = false;
      } else {
        this.starList[i] = true;
      }
    }
    this.rating = !this.rating;
    this.properties = 'block';
  }

  iconShow() {
    this.icon = true;
    this. icon1= false;
    this.icon2 = false;
    this.icon3 = false;
  }

  iconShow1() {
    this.icon1 = true;
    this.icon = false;
    this.icon2 = false;
    this.icon3 = false;
  }

  iconShow2() {
    this.icon1 = false;
    this.icon = false;
    this.icon2 = true;
    this.icon3 = false;
  }

  iconShow3() {
    this.icon1 = false;
    this.icon = false;
    this.icon2 = false;
    this.icon3 = true;
  }

  closeRate() {
    this.rating = false;
    this.properties = 'none';
  }

  editDescription() {
    this.tabDescription = false;
  }

  toSeconds(str) {
    var pieces = str.split(':');
    var result = Number(pieces[0]) * 60 + Number(pieces[1]);
    return result.toFixed(3);
  }

  rateTester() {
    this.showRate = !this.showRate;
  }

  // triggredEventInfo(event) {
  //   if (event) {
  //     this.getAllMarker(true);
  //     this.getAllClips();
  //   }
  // }

  // getAllMarker(isLoader = false) {
  //   this.makerUnsub = this._testedTestServices
  //     .getMarkerList(this.id)
  //     .subscribe((getMarkerRes) => {
  //       if (getMarkerRes['status'] == 200) {
  //         this.getMarkerList = getMarkerRes['data'];

  //         this.getMarkerList.forEach((res) => {
  //           let startTime: any = convertToHHMMSS(res.start);
  //           let endTime: any = convertToHHMMSS(res.end);
  //           let convertedStartTime = parseTimeFunction(startTime);
  //           let convertedEndTime = parseTimeFunction(endTime);

  //           let duration = convertedEndTime - convertedStartTime;

  //           if (this._testedTestServices.player.markers.add) {
  //             this._testedTestServices.player.markers.add([
  //               {
  //                 time: convertedStartTime,
  //                 duration: duration,
  //                 text: res.text,
  //               },
  //             ]);
  //           }
  //         });
  //       }

  //       if (isLoader) {
  //       }
  //     });
  // }

  // getAllClips() {
  //   this._testedTestServices
  //     .getClipList(this.id)
  //     .subscribe((res) => {
  //       if (res['status'] == 200) {
  //         this.clipList = res['data'];

  //         this.clipList.forEach((res) => {
  //           let startTime: any = convertToHHMMSS(res.start);
  //           let endTime: any = convertToHHMMSS(res.end);
  //           let convertedStartTime = parseTimeFunction(startTime);
  //           let convertedEndTime = parseTimeFunction(endTime);

  //           let duration = convertedEndTime - convertedStartTime;

  //           if (this._testedTestServices.player.markers.add) {
  //             this._testedTestServices.player.markers.add([
  //               {
  //                 time: convertedStartTime,
  //                 duration: duration,
  //                 text: res.text,
  //               },
  //             ]);
  //           }
  //         });
  //       }
  //     });
  // }

  // deleteMarkerList(id, mark) {
  //   this._testedTestServices.deleteMarker(id).subscribe((delRes) => {
  //     if (delRes['status'] == 200) {
  //       this._modelBoxService.notesAndClipModelMsg(mark.clip, 'are deleted');
  //       // if (mark.clip == false) {
  //       //   this._modelBoxService.showSuccessToster('Note are deleted');
  //       // } else {
  //       //   this._modelBoxService.showSuccessToster('Clip are deleted');
  //       // }
  //       this.getAllMarker();
  //     }
  //   });
  // }

  getTestedVideoList() {
    this.testedVideoList = this._testedTestServices
      .getTestedTestVideoList()
      .subscribe((testedListRes) => {
        if (testedListRes.status == 200) {

          this.testerTestedList = testedListRes.data.video_list;
          this.testerTestedProfile = testedListRes.data.tester_profile;
          var result = this.testerTestedList.filter((obj) => {
            return obj.id === this.id;
          });
          let testerUser = result[0].user;
          this.testerTestedList.filter((dataRes) => {
            this.userTest = dataRes.user;
            if (dataRes.id === this.id) {
              this.selectedTestDetails = dataRes;
            }
          });
          this.testerTestedProfile.filter((dataRes) => {
            if (dataRes.user === testerUser) {
              this.selectedTesterProfile = dataRes;
            }
          });
        }
      });
  }

  // getAllTest() {
  //   this.subscription1 = this._createnewTestServices
  //     .getCreateTest()
  //     .subscribe((res) => {
  //       this.createTestList = res.data;
  //       this.draftStatus = this.createTestList;
  //       this.draftStatus.filter((allTestRes) => {

  //         if (allTestRes.id === this.id) {
  //           allTestRes.test_instructions.forEach((res) => {
  //             this.testerTask.push(res);
  //           });
  //         }
  //       });
  //     });
  //   this.draftStatus = this.createTestList;
  // }

  showDesc() {
    this.descProp = 'block';
    this.showDescrption = !this.showDescrption;
  }

  // editMarker(id = null) {
  //   this.editMarkerId = id;
  //   this.showMarkerEdit = false;
  //   if (id != null) {
  //     let markerData = this.getMarkerList.find((list) => list.id == id);
  //     this.selectVideoDeatils.editNotesClip = markerData;
  //   }
  // }

  // editShow(index) {
  //   if (this.showEditId == index) {
  //     this.showMarkerEdit = !this.showMarkerEdit;
  //   } else {
  //     this.showMarkerEdit = true;
  //   }
  //   this.showEditId = index;
  //   this.editMode = true;
  //   this.styleProp = 'block';
  // }

  // cancelClip() {
  //   this.clipEdit1 = false;
  // }

  ngAfterViewInit() {
    this.getTestedVideoList();

  }
  ngOnDestroy() {
    // this.makerUnsub.unsubscribe();
  }
}
