import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/app/shared/services/auth.service';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {ModelBoxService} from 'src/app/shared/services/model-box.service';
import { ModelBox2Service } from 'src/app/shared/services/model-box2.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit {
  resetURL: any;
  urlReset: any;
  tab: any = 'tab1';
  tab1: any;
  tab2: any;
  tab3: any;
  Clicked: boolean;
  emailShow: FormGroup;
  updateProfileForm: FormGroup;
  usernameForm: FormGroup;
  submmited: boolean;
  isShowOtpBox: boolean = false;
  isShowOtpBox2: boolean = false;
  oldEmail: any;
  validatePassCodeForm: FormGroup;
  validateOTPForm: FormGroup;
  updateEmailMessgaText: any;

  constructor(
    private _authServices: AuthService,
    private router: Router,
    private _fb: FormBuilder,
    private _modelBoxService: ModelBoxService,
    private _modelBox2Service: ModelBox2Service,
  ) {
    this.initProfileForm();
    this.isShowOtpBox = false;
  }

  ngOnInit() {
    this.urlReset = this._authServices.url;

    this.emailShow = new FormGroup({
      email: new FormControl("", [
        Validators.required,
        Validators.email,
        Validators.pattern('^[A-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
    });
    this.getClientProfile();
    this.initValidateOTPForm();
    this.initValidateOTPForm2();

  }

  initValidateOTPForm() {
    this.validatePassCodeForm = this._fb.group({
      oldPassCode: ["", Validators.required],
      newPassCode: ["", Validators.required],
    });
  }

  initValidateOTPForm2() {
    this.validateOTPForm = this._fb.group({
      otp: ["", Validators.required],
    });
    this.usernameForm = this._fb.group({
      username: ["", ],
    });
  }

  initProfileForm() {
    this.updateProfileForm = this._fb.group({
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
    });
  }

  //  Reset Password

  onClick(check) {
    if (check == 1) {
      this.tab = 'tab1';
    } else if (check == 2) {
      this.tab = 'tab2';
    } else {
      this.tab = 'tab3';
    }
  }

  onSwitch(check) {
    switch (check) {
      case 1:
        return 'tab1';
      case 2:
        return 'tab2';
      case 3:
        return 'tab3';
    }
  }

  resetpass() {
    this._authServices.forgetPassword().subscribe((res) => {
      this.resetURL = res;

      this.router.navigate(['res']);
    });
  }

  getClientProfile() {
    this._authServices.getClientProfile().subscribe((res) => {
      console.log(res);
      const editData = res.data[0];
      if (editData) {
        this.oldEmail = editData.email;
        this.updateProfileForm.patchValue({
          first_name: editData.first_name,
          last_name: editData.last_name,
        });
        this.emailShow.patchValue({
          email: editData.email,
        });
        this.usernameForm.patchValue({
          username: editData.username,
        });
      }
    });
  }

  onUpdateProfile() {
    this.submmited = true;
    if (this.updateProfileForm.invalid) {
      return;
    }
    this.submmited = false;
    let formValue = this.updateProfileForm.value;
    let formData = new FormData();
    formData.append('first_name', formValue.first_name);
    formData.append('last_name', formValue.last_name);
    formData.append('token_id', localStorage.getItem('token_id'));

    this._authServices.updateClientProfile(formData).subscribe((res) => {
      if (res['status'] == 200) {
        let modelBody = 'Your profile was successfully updated.';
        let modelHeader = 'Profile Updated!';
        this._modelBoxService.showModelBox(true, modelBody, modelHeader);
      } else {
        this._modelBoxService.showModelBox(false, res['message']);
      }
    });
  }

  updateEmailVerfiy() {
    this.updateEmailMessgaText = "";
    this.submmited = true;
    if (this.emailShow.invalid) {
      return
    }
    this.submmited = false;
    let new_email = this.emailShow.value.email;
    this._authServices.verifyCLientEmail(this.oldEmail, new_email).subscribe(
      (res) => {
        if (res['status'] == 200) {
          this.isShowOtpBox = true;
          localStorage.setItem('newEmail', new_email);
          this.updateEmailMessgaText = res['message'];
        } else {
          this.updateEmailMessgaText = res['message'];
          this._modelBoxService.showErrorToster(res['message']);
        }
      },
      (error) => {
        this._modelBoxService.showModelBox(false, error);
      }
    );
  }

  changeEmail(){
    let newEmail = this.emailShow.value.email;
    if (this.oldEmail != newEmail){
      this.updateEmailMessgaText = "";
      this.submmited = true;
      if (this.emailShow.invalid) {
        return
      }
      this.submmited = false;
      // let newEmail = this.emailShow.value.email;
      let body = {
        "old_email" : this.oldEmail,
        "new_email" : newEmail
      }
      this._authServices.changeEmail(body).subscribe(
        (res) => {
          if (res['status'] == 200) {
            this.isShowOtpBox2 = true;
            localStorage.setItem('newEmail', newEmail);
            this.updateEmailMessgaText = res['message'];
          } else {
            this.updateEmailMessgaText = res['message'];
            this._modelBoxService.showErrorToster(res['message']);
          }
        },
        (error) => {
          this._modelBoxService.showModelBox(false, error);
        }
      );  
    } else {
      this.updateEmailMessgaText = "Please enter your new email";
    }
    
  }


  validateOTP2(){
    this.updateEmailMessgaText = "";
    this.submmited = true;
    let body = {
      "otp" : this.validateOTPForm.value.otp,
      "old_email" : this.oldEmail,
      "new_email" : localStorage.getItem('newEmail')
    }
    this._authServices.validateOTP2(body).subscribe(
      (res) => {
        if (res['status'] == 200) {
          let modelBody = 'Your email was successfully updated 2.';
          let modelHeader = 'Email updated!';
          this._modelBoxService.showModelBox(true, modelBody, modelHeader);
          this.isShowOtpBox2 = false;
          this.getClientProfile();
          this.validateOTPForm.patchValue({
            otp: "",
          });
        } else {
          this.updateEmailMessgaText = res['message'];
          let modelBody = 'Email address not updated!';
          let modelHeader = 'OTP incorrect';
          this._modelBox2Service.showModelBox(false, modelBody, modelHeader);
        }
      },
      (error) => {
        this._modelBoxService.showModelBox(false, error);
      }
    )
  }

  validateUpdateEmailOTP() {
    this.updateEmailMessgaText = "";
    this.submmited = true;
    if (this.validatePassCodeForm.invalid) {
      return
    }
    this.submmited = false;
    let newEmail = localStorage.getItem('newEmail');
    let validateForm = this.validatePassCodeForm.value;
    this._authServices
      .validateUpdateOTP(
        validateForm.oldPassCode,
        validateForm.newPassCode,
        this.oldEmail,
        newEmail
      )
      .subscribe(
        (res) => {
          if (res['status'] == 200) {
            let modelBody = 'Your email was successfully updated 1.';
            let modelHeader = 'Email updated!';
            this._modelBoxService.showModelBox(true, modelBody, modelHeader);
            this.isShowOtpBox = false;
          } else {
            this.updateEmailMessgaText = res['message'];
            this._modelBoxService.showErrorToster(res['message']);
          }
        },
        (error) => {
          this._modelBoxService.showModelBox(false, error);
        }
      );
  }
}
