<!-- <app-model-box *ngIf='_modelBoxService.isModelShow'></app-model-box> -->

<div *ngIf='TestInstructions' class='container instructions-container lg-screen-width'>
  <div class='instructions_head'>
    <h2>Set up your test within minutes!!</h2>
    <ul class='d-flex'>
      <li class='active'>1. Test instructions</li>
      <li class='angle'>
        <img alt='left-angle' src='assets/images/icons/leftangle_icon.png'/>
      </li>
      <li (click)='createTests()' class='cursor'>2. Select audience</li>
    </ul>
  </div>

  <div class='instruction_content'>
    <form (ngSubmit)='updatetest()' [formGroup]='createNewTests'>
      <article class='content-shadow'>
        <div class='sub-row'>
          <h3>Test name</h3>
          <input [(ngModel)]='testNameView' class='input' formControlName='test_name' placeholder='e.g. My First Usertest'
                 type='text'/>
          <div *ngIf="
            (submitted && createNewTests.controls.test_name.errors) ||
            createNewTests.get('test_name').touched
          " class='invalidfeedback'>
            <div *ngIf="
              createNewTests.controls.test_name.errors?.required ||
              createNewTests.controls.test_name.errors?.test_name
            ">
              <small class='text-danger'>Please fill a test name</small>
            </div>

          </div>
          <label>This is only for you. Your testers won&#39;t see this</label>

        </div>
        <div class='sub-row'>
          <h3>Project name</h3>
          <input *ngIf='myVar1 ==false' class='input' formControlName='project_name' placeholder='e.g. My First Project'
                 type='text'/>

          <input *ngIf='myVar1 ==true' class='input' disabled formControlName='project_name' placeholder='e.g. My First Project'
                 type='text'/>
          <div *ngIf="
            (submitted && createNewTests.controls.project_name.errors) ||
            createNewTests.get('project_name').touched || createNewTests
          " class='invalidfeedback'>
            <div *ngIf='
              createNewTests.controls.project_name.errors?.required ||
              createNewTests.controls.project_name.errors?.project_name
            '>
              <small class='text-danger'>Please fill a project name</small>
            </div>

          </div>
        </div>
        <div class='sub-row'>
          <label class='addproject'>
            <input (change)='myVar1 = !myVar1' [checked]='myVar1' formControlName='project_flag' type='checkbox'/>
            <span class='checkbox-span'></span>
            Add to existing project
          </label>
          <select *ngIf='myVar1 ==true' class='instruction-select' formControlName='project_name'>
            <option *ngFor='let project of allProjectList' [ngValue]='project'>{{project}}</option>
            <!-- <option>Select a project</option>
            <option>Select a project</option>
            <option>Select a project</option>
            <option>Select a project</option>
            <option>Select a project</option> -->
          </select>
        </div>
      </article>

      <article class='content-shadow'>
        <div class='sub-row'>
          <h3>Start-URL</h3>
          <label class='addproject app-test'>
            <input formControlName='is_app' type='checkbox'/>
            <span class='checkbox-span'></span>
            This is an App Test
          </label>
        </div>
        <div class='sub-row'>
          <input class='input' formControlName='start_url' placeholder='www.website.de' type='text'/>

          <div *ngIf="
            (submitted && createNewTests.controls.start_url.errors) ||
            createNewTests.get('start_url').touched
          " class='invalidfeedback'>
            <div *ngIf='createNewTests.controls.start_url.errors?.required'>
              <small class='text-danger'> Start URL is required</small>
            </div>
            <div *ngIf='
              createNewTests.controls.start_url.errors?.pattern
            '>
              <small class='text-danger'> Must Be a Valid URL</small>
            </div>

          </div>
          <label class='typein'>Type in the exact URL on which the testers shall start testing. e.g.
            www.website.com if you want them to test the homepage.</label>
        </div>
      </article>

      <article class='content-shadow pb-5'>
        <div class='sub-row'>
          <h3>Language</h3>
          <!-- <label class='language-label' *ngFor='let lang of language'>
            <input type='radio' name='language' [value]='lang' formControlName='language' />

            <span class='radio-container'>
              <span class='radio-span'></span>
            </span>
            {{lang}}
          </label> -->
          <label class='language-label'>
            <input formControlName='language' name='language' type='radio' value='English'/>

            <span class='radio-container'>
              <span class='radio-span'></span>
            </span>
            English
            <!-- {{lang}} -->
          </label>
          <span class='profile-image-tooltips'>

            <label class='language-label invalidlanguage'>

              <input disabled name='language' type='radio' value='German'/>
              <!-- <input type='radio' name='language'  [value]='lang' formControlName='language' /> -->

              <span class='radio-container'>
                <span class='radio-span '></span>
              </span>


              German
              <!-- {{lang}} -->
              <span class='profile-tooltips'>Implemented will be soon !</span>


            </label>
          </span>
          <div
            *ngIf="submitted && createNewTests.controls.language.errors?.required || createNewTests.get('language').touched">
            <div *ngIf='createNewTests.controls.language.errors?.required'>
              <small class='text-danger'> Please select a language</small>
            </div>
          </div>
          <!-- <label class='language-label'>
            <input type='radio' name='language' />
            <span class='radio-container'>
              <span class='radio-span'></span>
            </span>
            German
          </label> -->
        </div>
      </article>

      <article class='content-shadow instructions-article'>
        <div class='sub-row'>
          <div class='instructions-head'>
            <div class='row'>
              <div class='col-sm'>
                <h3>Test instructions</h3>
              </div>
              <div class='col-sm-auto'>
                <!-- <div *ngIf='submitted && createNewTests.controls.template_name.errors?.required'>
                    <div *ngIf='createNewTests.controls.template_name.errors?.required'>
                      <small class='text-danger'>Please Choose a Template Name</small>

                    </div>
                  </div> -->
                <div (myCustomEvent)='onMyCustomEvent()' appShowHideEvent class='userpeek-dropdowns-sub templates'>
                  <button (click)='toggle()' class='testedit-btn userpeek-dropdowns-btn show' type='button'>
                    Templates
                  </button>
                  <div *ngIf='showRadio' class='userpeek-dropdowns' style='display: block;'>
                    <ul>

                      <li (click)='toggle()' *ngFor='let testInstructionType of templatesEdit let i=index'>
                        <span *ngIf='i>0'>
                        <a
                          (click)='changeTestInstructionType(testInstructionType)' style='cursor: pointer;'>
                          {{testInstructionType}}
                        </a>
                      </span>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class='sub-row'>
          <textarea id='story' name='instructions' formControlName='test_instructions' class='instructions-textarea'>
      Take a look at this site and tell us what you think it is. (You can scroll if you want but please don&#39;t click on anything yet.)
      -- What&#39;s the first thing you notice&#63;
      -- What can you do on this site&#63;
      -- What products or services are offered on this site&#63;
      -- Who is this site intended for&#63;

      Now imagine the products or services offered on this site are intended for you and go ahead to do whatever you think you&#39;re supposed to do on this site (e.g. buy a product&#44; make a booking&#44; create an account&#44; etc.).

      Please remember to think out loud during your test and tell us if there&#39;s any information missing. You can input fake information if you need to sign up or anything.

      Thank you for your help&#32;</textarea>
          <div
            *ngIf='submitted && createNewTests.controls.test_instructions.errors?.required || createNewTests.get('test_instructions').touched'>
            <div *ngIf='createNewTests.controls.test_instructions.errors?.required'>
              <small class='text-danger'>
                Fill the test instruction
              </small> </div>

          </div>
        </div> -->
        <div class='sub-row' formArrayName='test_instructions'>
          <ng-container *ngFor="let tests of createNewTests.get('test_instructions')['controls']; index as i">

            <div *ngIf="createNewTests.get('test_instructions')['length']>1">
              <button (click)='removeTest(i)' aria-label='Close' class='close' class='btn btn-danger btn-sm pull-right'
                      type='button'>
                <span aria-hidden='true' class='fa fa-trash'></span>
              </button>
            </div>

            <textarea class='instructions-textarea' formControlName='{{i}}' id='test_instructions_{{i+1}}'
                      name='instructions'></textarea>

            <div *ngIf="submitted && createNewTests.get('test_instructions')['controls'][i].errors?.required">
              <small class='text-danger'>
                Please fill the test instruction
              </small>
            </div>

            <!-- <div
   *ngIf='submitted && createNewTests.controls.test_instructions.errors?.required || createNewTests.get('test_instructions').controls.touched'>
   <div *ngIf='createNewTests.controls.test_instructions.errors?.required'>
     <small class='text-danger'>
       Please fill the test instruction
     </small> </div>

 </div> -->
          </ng-container>

        </div>
        <div class='sub-row'>
          <p class='selected-template'>{{selectedInstructionType}} Template</p>
          <button (click)='onAddTests()' class='add-btn' type='button'><i class='fa fa-plus'></i> Add task</button>
        </div>
      </article>

      <div class='bottom-button'>
        <button class='saveandclose' type='submit'>Update and close</button>
        <button (click)='createTests()' class='selectaudience' type='button'>Select audience</button>
      </div>

    </form>

  </div>

  <button class='feedback-btn'>Feedback</button>
</div>

<!-- This is For Select Audieence HTML -->

<div *ngIf='!TestInstructions' class='container instructions-container lg-screen-width'>
  <div class='instructions_head'>
    <h2>{{createNewTests.value['test_name']}}</h2>
    <ul class='d-flex'>
      <li (click)='createTests()' class='cursor'>1. Test instructions</li>
      <li class='angle'>
        <img alt='left-angle' src='assets/images/icons/leftangle_icon.png'/>
      </li>
      <li (click)='createTests()' class='active'>2. Select audience</li>
    </ul>
  </div>
  <div>

    <div *ngIf="
    (submitted && createNewTests.controls.test_name.errors) ||
    createNewTests.get('test_name').touched
  " class='invalidfeedback'>
      <div *ngIf='
      createNewTests.controls.test_name.errors?.required ||
      createNewTests.controls.test_name.errors?.test_name
    '>
        <small class='text-danger'>Please fill a test name</small>
      </div>

    </div>
    <div *ngIf="
    (submitted && createNewTests.controls.project_name.errors) ||
    createNewTests.get('project_name').touched || createNewTests
  " class='invalidfeedback'>
      <div *ngIf='
      createNewTests.controls.project_name.errors?.required ||
      createNewTests.controls.project_name.errors?.project_name
    '>
        <small class='text-danger'>Please fill a project name</small>
      </div>

    </div>
    <div *ngIf="
    (submitted && createNewTests.controls.start_url.errors) ||
    createNewTests.get('start_url').touched
  " class='invalidfeedback'>
      <div *ngIf='createNewTests.controls.start_url.errors?.required'>
        <small class='text-danger'> Start URL is required</small>
      </div>
      <div *ngIf='
      createNewTests.controls.start_url.errors?.pattern
    '>
        <small class='text-danger'> Must be a valid URL</small>
      </div>

    </div>
    <div
      *ngIf="submitted && createNewTests.controls.language.errors?.required || createNewTests.get('language').touched">
      <div *ngIf='createNewTests.controls.language.errors?.required'>
        <small class='text-danger'> Please select a language</small>
      </div>
    </div>

    <!-- <div
            *ngIf='submitted && createNewTests.controls.test_instructions.errors?.required || createNewTests.get('test_instructions').touched'>
            <div *ngIf='createNewTests.controls.test_instructions.errors?.required'>
              <small class='text-danger'>
                Please fill the test instruction
              </small> </div>

          </div> -->
    <div class='sub-row'>
      <ng-container *ngFor="let tests of createNewTests.get('test_instructions')['controls']; index as i">

        <div *ngIf="submitted && createNewTests.get('test_instructions')['controls'][i].errors?.required">
          <small class='text-danger'>
            Please fill the test instruction
          </small>
        </div>
      </ng-container>

    </div>
  </div>

  <div class='instruction_content'>
    <form (ngSubmit)='updatetest()' [formGroup]='createNewTests'>
      <article class='content-shadow' formGroupName='articipants_audience'>
        <div class='sub-row'>
          <h3>Participants</h3>
          <span class='profile-image-tooltips'>

          <label (click)='hideStyle()' class='language-label invalidlanguage'>
            <input checked class='participants-input' disabled formControlName='articipants_type'
                   type='radio'
                   value='Fatest Result'/>
            <span class='radio-container'>
              <span class='radio-span'></span>
            </span>
            Fastest results
            <span class='profile-tooltips' style='left: 130px;'>Implemented will be soon !</span>

          </label>
        </span>


          <label class='language-label'>
            <input (click)='addStyle()' class='participants-input demographics-checkbox' formControlName='articipants_type'
                   type='radio' value='Choose specific demographics'/>
            <span class='radio-container'>
              <span class='radio-span'></span>
            </span>
            Choose specific demographics

          </label>
          <span class='profile-image-tooltips'>

          <label (click)='hideStyle()' class='language-label invalidlanguage'>
            <input class='participants-input' disabled formControlName='articipants_type'
                   type='radio' value='Get certified UX and User Research Experts to take the test'/>
            <span class='radio-container'>
              <span class='radio-span'></span>
            </span>
            Get certified UX and User Research Experts to take the test
            <span class='profile-tooltips' style='left: 430px;'>Implemented will be soon !</span>

          </label>
          </span>

          <p class='expert-info'>
            <i class='fa fa-info-circle'></i> 60&euro; per UX and User Research
            Expert test
          </p>
        </div>
        <div [style.display]='mystyle1' class='specific-demograhics-container pb-4'>
          <div class='sub-row'>
            <h3>Gender</h3>
            <label *ngFor='let gender1 of testerGender' class='language-label'>
              <input [value]='gender1' checked formControlName='gender' name='gender' type='radio'/>
              <span class='radio-container'>
                <span class='radio-span'></span>
              </span>
              {{gender1}}
            </label>

          </div>
          <div class='sub-row'>
            <h3>Region</h3>
            <label *ngFor='let region1 of testerRegion' class='language-label'>
              <input [value]='region1' checked formControlName='region' name='region' type='radio'/>
              <span class='radio-container'>
                <span class='radio-span'></span>
              </span>
              {{region1}}
            </label>
          </div>
          <div class='sub-row'>
            <h3>Age</h3>
            <label *ngFor='let age of testerAge' class='language-label'>
              <input [value]='age' checked formControlName='age' name='age' type='radio'/>
              <span class='radio-container'>
                <span class='radio-span'></span>
              </span>
              {{age}}
            </label>
          </div>
        </div>
      </article>

      <div class='bottom-button'>
        <button class='saveandclose' type='submit'>Update and close</button>
        <!-- <button class='selectaudience'>Select audience</button> -->
      </div>
    </form>


  </div>

  <!-- <button class='feedback-btn'>Feedback</button> -->
</div>

<script src='assets/js/newCustomScript.js'></script>
