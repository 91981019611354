import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectedVideoDetailsService } from 'src/app/modules/new-videos/selected-video-details.service';

@Component({
  selector: 'app-view-clip-wrapper',
  templateUrl: './view-clip-wrapper.component.html',
  styleUrls: ['./view-clip-wrapper.component.scss']
})
export class ViewClipWrapperComponent implements OnInit, OnDestroy {

  constructor(
    private selectedVideoDetailsService: SelectedVideoDetailsService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    // this.selectedVideoDetailsService.SetVideoPlaying(false);
  }

}
