<nav class="up-navbar-container">
    <section class="up-navbar-content">
        <div class="up-navbar-img">
            <a routerLink='/login'>
                <img src="assets/images/favicon.svg" alt="logo">
            </a>
        </div>
        <div class="up-navbar-links-section">
            <p routerLink='/dashboard' routerLinkActive="highlight-link" class="up-navbar-link">
                <img src="assets/images/icons/home-new.svg" alt="logo">
                Dashboard
            </p>
            <p routerLink='/videos' routerLinkActive="highlight-link" class="up-navbar-link">
                <img src="assets/images/icons/project-icon.svg" alt="logo">
                Projects
            </p>
            <p routerLink='/dashboard_tests' routerLinkActive="highlight-link" class="up-navbar-link">
                <img src="assets/images/icons/research-icon.svg" alt="logo">
                Research
            </p>
            <p class="up-navbar-link">
                <img src="assets/images/icons/result-icon.svg" alt="logo">
                Results
            </p>
            <p class="up-navbar-link">
                <img style="height: 17px;" src="assets/images/icons/report-icon.svg" alt="logo">
                Reports
            </p>
        </div>
        <div class="up-navbar-profile-section">
            <img class="help-icon" src="assets/images/icons/new-help-icon.svg" alt="logo">
            <img class="profile-icon" src="assets/images/icons/profile-user.png" alt="logo">
        </div>
    </section>
</nav>