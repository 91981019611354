import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {appApiResources} from './api.constant';
import {BaseService} from '../../core/api-call/base.service';
import {TokenParms} from '../../core/models/token-parms';
import {catchError} from 'rxjs/operators';
import {User} from '../../core/models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }

  register(user: User): Observable<any> {
    return this.post(appApiResources.registerClient, user).pipe(catchError(this.handleError));
  }

  validateUsername(body: any): Observable<any> {
    console.log('username', body, appApiResources.validateUsername);
    return this.post("https://api1.userpeek.io/user/registration/validate_username/", body).pipe(catchError(this.handleError));
  }

  login(tokenParms: TokenParms): Observable<any> {
    return this.post(appApiResources.login, tokenParms).pipe(catchError(this.handleError));
  }

  activateEmail( jsonData ){
    return this.post(appApiResources.activateEmail, jsonData).pipe(catchError(this.handleError));
  }

  // validateUsername(username) {
  //   console.log('username', username, appApiResources.validateUsername);
  //   return this.post(appApiResources.validateUsername, username);
  // }

}
