<div *ngIf="toastController.showToast$ | async " class="toast-msg-container">
<!-- <div *ngIf="true " class="toast-msg-container"> -->
    <div class="toast-msg-content">

        <img *ngIf="toastController.iconType$.value == 'success'" class="msgStatus-icon" src="assets/images/icons/new-success-icon.svg" alt="success icon">
        <img *ngIf="toastController.iconType$.value == 'failed'" class="msgStatus-icon" src="assets//images/icons/false-icon-red-circle.svg" alt="failed icon">
        
        <p class="toast-msg-text">{{ toastController.toastText$ | async  }}</p>
        
        <img (click)="dismiss()" class="toast-msg-close-icon" src="assets/images/icons/small-close-icon.svg" alt="close icon">
    
    </div>
</div>