import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, Params, ActivatedRoute } from '@angular/router';
import { Output, EventEmitter, Input } from '@angular/core';
import { AllFiltersShareQueryService } from 'src/app/shared/services/all-filters-share-query.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-all-filters',
  templateUrl: './all-filters.component.html',
  styleUrls: ['./all-filters.component.scss']
})
export class AllFiltersComponent implements OnInit {
  // route
  currentRouteVariable: any;
  // UI variables
  showSidebar: boolean = false;
  activeFilterField: any = "none";
  availableDataCount: any = "none";
  // search list array
  localPreFilterDataArray: any = []; //common variable for all filters search list
  clipIdSearchList: any = [];
  testerSearchList: any = [];
  testNameSearchList: any = [];
  hashtagSearchList: any = [];
  reelNameSearchList: any = [];
  projectNameSearchList: any = [];
  // Json data array
  unFilteredJsonData = [];
  queriedJsonData = [];
  filteredJsonData = [];
  // Template Variables
  startDateTemplateVar: any = "";
  endDateTemplateVar: any = "";
  testNameTemplateInput: String;
  clipIdTemplateInput: any;
  testerTemplateInput: any = "";
  hashtagTemplateInput: any = "";
  reelNameTemplateInput: any = "";
  projectNameTemplateInput: any = "";
  // main filter condition array
  secondaryFilterConditions = [];
  mainFilterConditionsRxjs: BehaviorSubject<any> = new BehaviorSubject([]);
  // deviceType
  deviceTypeFilterCount: number = 0;
  // testStatus
  testStatusFilterCount: number = 0;
  // hashtag
  hashtagFilterCount: number = 0;

  constructor(
    public _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _allFiltersShareQueryService: AllFiltersShareQueryService,
  ) { }

  ngOnInit(): void {
    this.currentRouteVariable = this._router.url.split('?')[0];
    this._allFiltersShareQueryService.parentToChildDataVariable.subscribe((res) => {
      console.log('res', res)
      this.unFilteredJsonData = res;
    })
    console.log("applyFilterConditions called ngOnint",);

    this.handleChangesInMainFilterConditions();

    this._allFiltersShareQueryService.videosHashtagValue.subscribe((test) => {
      console.log(" ngOninit console 3", test);
      
      this.getSelectedComponentData();
      this.storeFilterCondition(test["value"], test["filterType"]);
    });
  }

  handleChangesInMainFilterConditions() {
    this.mainFilterConditionsRxjs.subscribe((res) => {
      console.log(" applyFilterConditions ,handleChangesInMainFilterConditions",);
      console.log(" applyFilterConditions ,handleChangesInMainFilterConditions", this.secondaryFilterConditions, this.secondaryFilterConditions.length <= 0);

      if (this.secondaryFilterConditions.length <= 0) {
        this.availableDataCount = "none";
        this._allFiltersShareQueryService.sendFilterQueryRequest("loadOriginalData", this._router.url.split('?')[0]);
      } else {
        console.log("applyFilterConditions called 1 console 6");

        this.applyFilterConditions();
      }

      if (this.currentRouteVariable == "/results/clips" || this.currentRouteVariable == "/results/videos") {
        this.initializeTestNameSearchList();
        this.initializeTesterSearchList();
      }

      if (this.currentRouteVariable == "/results/clips") {
        this.initializeClipIdOptionsList();
      }
      else if (this.currentRouteVariable == "/results/highlight-reels") {
        this.initializeReelNameSearchList();
      }
      else if (this.currentRouteVariable == "/results/videos") {
        this.initializeHashtagSearchList();
      }
      else if (this.currentRouteVariable == "/research/tests") {
        this.initializeTestNameSearchList();
      }
      else if (this.currentRouteVariable == "/projects") {
        this.initializeProjectNameSearchList();
      }

      this.initializeFilterUI();
    });
  }

  // @note reset input values
  initializeFilterUI() {
    console.log('Called initial ui');
    // 1st clear all fields
    this.startDateTemplateVar = "";
    this.endDateTemplateVar = "";
    // device type
    let deviceTypeElements = document.getElementsByClassName("device-type-checkbox-input");
    Array.prototype.forEach.call(deviceTypeElements, function (item) {
      item.checked = false;
    });
    // test status
    let testStatusElements = document.getElementsByClassName("test-status-checkbox-input");
    Array.prototype.forEach.call(testStatusElements, function (item) {
      item.checked = false;
    });
    // 2nd Fill the selected filter conditions
    this.secondaryFilterConditions.forEach((selectedFilter) => {
      if (selectedFilter.queryType == "date") {
        this.startDateTemplateVar = selectedFilter.value.startDate;
        this.endDateTemplateVar = selectedFilter.value.endDate;
      }
      else if (selectedFilter.queryType == "deviceType") {
        let deviceTypeElements = document.getElementsByClassName("device-type-checkbox-input");
        Array.prototype.forEach.call(deviceTypeElements, function (item) {
          if (item.value == selectedFilter.value) {
            item.checked = true;
          }
        });
      }
      else if (selectedFilter.queryType == "testStatus") {
        let testStatusElements = document.getElementsByClassName("test-status-checkbox-input");
        Array.prototype.forEach.call(testStatusElements, function (item) {
          let tempEventValueString = ""
          let tempEventValueNumber = Number(item.value);
          if (tempEventValueNumber == 0) {
            tempEventValueString = "Running"
          }
          else if (tempEventValueNumber == 3) {
            tempEventValueString = "Finished"
          }
          else if (tempEventValueNumber == 2) {
            tempEventValueString = "Draft"
          }
          // =======================================
          if (tempEventValueString == selectedFilter.value) {
            item.checked = true;
          }
        });
      }
    });
  }



  getSelectedComponentData() {
    this._allFiltersShareQueryService.sendFilterQueryRequest("getDataFromParent", this._router.url.split('?')[0]);
    // this.initializeFilterUI();

    // test status checkbox are in sync (present in quick menu and sidebar) :
    // this.verifyTestStatusCheckboxAreSync();
  }

  verifyTestStatusCheckboxAreInSync() {
    let testStatusCheckbox = document.getElementsByClassName("test-status-checkbox-input");
    Array.prototype.forEach.call(testStatusCheckbox, function (item) {

      if (item.checked) {
        if (item.className === "test-status-checkbox-input test-status-running") {
          let testStatusRunningCheckbox = document.getElementsByClassName("test-status-running");
          Array.prototype.forEach.call(testStatusRunningCheckbox, function (item1) {
            item1.checked = true;
          });
        }
        if (item.className === "test-status-checkbox-input test-status-draft") {
          let testStatusRunningCheckbox = document.getElementsByClassName("test-status-draft");
          Array.prototype.forEach.call(testStatusRunningCheckbox, function (item1) {
            item1.checked = true;
          });
        }
        if (item.className === "test-status-checkbox-input test-status-finished") {
          let testStatusRunningCheckbox = document.getElementsByClassName("test-status-finished");
          Array.prototype.forEach.call(testStatusRunningCheckbox, function (item1) {
            item1.checked = true;
          });
        }
      }
    });
  }

  // @note $- UI Function
  expandFilterBody(event) {
    let selectedArrowIcon = undefined;
    let selectedFilterBody = undefined;

    if (event.target.className.includes("filter-title")) {
      selectedArrowIcon = event.target.childNodes[1];
      selectedFilterBody = event.target.nextSibling;
    }
    else if (event.target.className == "filter-name") {
      selectedArrowIcon = event.target.nextSibling;
      selectedFilterBody = event.target.parentElement.nextSibling;
    }
    else if (event.target.className == "all-filters-right-arrow-icon") {
      selectedArrowIcon = event.target;
      selectedFilterBody = event.target.parentElement.nextSibling;
    }

    // let selectedElement = event.target.parentElement.nextSibling;

    if (selectedFilterBody.style.display == "block") {
      selectedFilterBody.style.display = "none";
      selectedArrowIcon.style.transform = "rotate(0deg)";
      // this.activeFilterField = "none";
    } else {
      // close all filter body
      let filterBodyList = document.getElementsByClassName("filter-body");
      Array.prototype.forEach.call(filterBodyList, function (item) {
        item.style.display = "none";
      });
      // rotate all icon
      let arrowIconList = document.getElementsByClassName("all-filters-right-arrow-icon");
      Array.prototype.forEach.call(arrowIconList, function (item) {
        item.style.transform = "rotate(0deg)";
      });
      // --------------------
      // this.activeFilterField = event.target.id;  
      selectedFilterBody.style.display = "block";
      selectedArrowIcon.style.transform = "rotate(90deg)";
      // if(event.target.id == "test-name"){
      //   this.initializeTestNameSearchList();
      // }
    }
  }

  // $-UI Function
  showDefaultFilterContent(event) {

    console.log("sendHashtagToAllFilterComponent  showDefaultFilterContent event",event);
    
    this.getSelectedComponentData();

    if (event.target.className.includes("default-filter-container")) {
      let selectedFilterContentWrapper = event.target.childNodes[2];
      let selectedFilterContent = event.target.childNodes[2].childNodes[0];
      let selectedFilterArrowIcons = event.target.childNodes[1];

      if (selectedFilterContentWrapper.style.display == "block") {
        selectedFilterContentWrapper.style.display = "none";
        selectedFilterContent.style.display = "none";
        selectedFilterArrowIcons.style.transform = "rotate(0deg)";
      } else {
        // close all content wrapper
        let defaultFilterContentWrapperList = document.getElementsByClassName("default-filter-content");
        Array.prototype.forEach.call(defaultFilterContentWrapperList, function (item) {
          item.style.display = "none";
        });
        // close all content
        let defaultFilterContentWrapper = document.getElementsByClassName("filter-body");
        Array.prototype.forEach.call(defaultFilterContentWrapper, function (item) {
          item.style.display = "none";
        });
        // rotate all icon
        let defaultFilterArrowIconsList = document.getElementsByClassName("default-filter-arrow-icon");
        Array.prototype.forEach.call(defaultFilterArrowIconsList, function (item) {
          item.style.transform = "rotate(0deg)";
        });
        // ---------
        selectedFilterContentWrapper.style.display = "block";
        selectedFilterContent.style.display = "block";
        selectedFilterArrowIcons.style.transform = "rotate(180deg)";
      }
    }
    else if (event.target.className.includes("default-filter-arrow-icon")) {
      let selectedFilterContentWrapper = event.target.nextElementSibling;
      let selectedFilterContent = event.target.nextElementSibling.childNodes[0];
      let selectedFilterArrowIcons = event.target;

      if (selectedFilterContentWrapper.style.display == "block") {
        selectedFilterContentWrapper.style.display = "none";
        selectedFilterContent.style.display = "none";
        selectedFilterArrowIcons.style.transform = "rotate(0deg)";
      } else {
        // close all content wrapper
        let defaultFilterContentWrapperList = document.getElementsByClassName("default-filter-content");
        Array.prototype.forEach.call(defaultFilterContentWrapperList, function (item) {
          item.style.display = "none";
        });
        // close all content
        let defaultFilterContentWrapper = document.getElementsByClassName("filter-body");
        Array.prototype.forEach.call(defaultFilterContentWrapper, function (item) {
          item.style.display = "none";
        });
        // rotate all icon
        let defaultFilterArrowIconsList = document.getElementsByClassName("default-filter-arrow-icon");
        Array.prototype.forEach.call(defaultFilterArrowIconsList, function (item) {
          item.style.transform = "rotate(0deg)";
        });
        // ---------
        selectedFilterContentWrapper.style.display = "block";
        selectedFilterContent.style.display = "block";
        selectedFilterArrowIcons.style.transform = "rotate(180deg)";
      }
    }

  }

  handleDeleteBtn() {
    // this.queriedJsonData = [];
    // this.startDateTemplateVar = "";
    // this.endDateTemplateVar = "";
    // this.testNameTemplateInput = "";
    // this.availableDataCount = "none";
    // this.secondaryFilterConditions = [];
    // this._allFiltersShareQueryService.sendComponentData(this.unFilteredJsonData);
    this.secondaryFilterConditions = [];
    this.mainFilterConditionsRxjs.next(this.secondaryFilterConditions);
  }

  // @note Common funtions
  getJsonDataKeyword(filterType) {
    let keyword = undefined;
    if (this.currentRouteVariable == "/results/clips") {
      if (filterType == "testName") {
        keyword = "test_name";
      }
      else if (filterType == "tester") {
        keyword = "tester_name";
      }
      else if (filterType == "clipId") {
        keyword = "testedtest_clip_id";
      }
      else if (filterType == "deviceType") {
        keyword = "device_type";
      }
      else if (filterType == "date") {
        keyword = "created_date";
      }
    }
    else if (this.currentRouteVariable == "/results/videos") {
      if (filterType == "testName") {
        keyword = "test_name";
      }
      else if (filterType == "tester") {
        keyword = "tester_name";
      }
      else if (filterType == "hashtag") {
        keyword = "hastags";
      }
      else if (filterType == "deviceType") {
        keyword = "device_type";
      }
      else if (filterType == "date") {
        keyword = "date_of_tested";
      }
    }
    else if (this.currentRouteVariable == "/results/findings") {
      if (filterType == "testName") {
        keyword = "test_name";
      }
      else if (filterType == "tester") {
        keyword = "tester_name";
      }
      else if (filterType == "hashtag") {
        keyword = "hashtags";
      }
      else if (filterType == "deviceType") {
        keyword = "device_type";
      }
      else if (filterType == "date") {
        keyword = "created_at";
      }
    }
    else if (this.currentRouteVariable == "/results/highlight-reels") {
      if (filterType == "reelName") {
        keyword = "highlight_reel_name";
      }
      else if (filterType == "date") {
        keyword = "created_date";
      }
    }
    else if (this.currentRouteVariable == "/research/tests") {
      if (filterType == "testName") {
        keyword = "test_name";
      }
      else if (filterType == "date") {
        keyword = "creation_date";
      }
      else if (filterType == "deviceType") {
        keyword = "device_type";
      }
      else if (filterType == "testStatus") {
        keyword = "test_status";
      }
    }
    else if (this.currentRouteVariable == "/projects") {
      if (filterType == "projectName") {
        keyword = "project_name";
      }
    }
    else if (this.currentRouteVariable == "/reports/test-reports") {
      if (filterType == "testName") {
        keyword = "test_name";
      }
    }
    return keyword;
  }

  getFiltersAppliedData() {
    if (this.queriedJsonData.length > 0) {
      return this.queriedJsonData;
    } else {
      return this.unFilteredJsonData;
    }
  }

  getFiltersAppliedData2() {
    if (this.secondaryFilterConditions.length > 0) {
      return this.queriedJsonData;
    } else {
      return this.unFilteredJsonData;
    }
  }

  getDeviceTypeData() {
    let otherFiltersCount = 0;
    this.secondaryFilterConditions.forEach((filterItem) => {
      if (filterItem.queryType != "deviceType") {
        otherFiltersCount = otherFiltersCount + 1;
      }
    });

    if (otherFiltersCount > 0) {
      let tempDeviceTypeSourceData = [];
      console.log('before llop -> secondaryFilterConditions', this.secondaryFilterConditions);
      this.secondaryFilterConditions.forEach((filterItem) => {
        if (filterItem.queryType != "deviceType") {
          if (filterItem.queryType == "date") {
            let startDateFormatted = new Date(filterItem.value.startDate);
            let endDateFormatted = new Date(filterItem.value.endDate);
            let sourceArrayList = tempDeviceTypeSourceData.length > 0 ? tempDeviceTypeSourceData : this.unFilteredJsonData;
            tempDeviceTypeSourceData = [];
            let jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
            sourceArrayList.forEach((clipItem) => {
              let newDate = new Date(clipItem[jsonkeyword]);
              if ((newDate >= startDateFormatted) && (newDate <= endDateFormatted)) {
                tempDeviceTypeSourceData.push(clipItem);
              }
            })
            console.log("inside date if block -> tempDeviceTypeSourceData", tempDeviceTypeSourceData)
          }
          else if (filterItem.queryType == "testName" || filterItem.queryType == "tester") {
            let Jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
            console.log("inside test name if block -> Jsonkeyword", Jsonkeyword)
            let sourceArrayList = tempDeviceTypeSourceData.length > 0 ? tempDeviceTypeSourceData : this.unFilteredJsonData;
            tempDeviceTypeSourceData = [];
            sourceArrayList.forEach((clipItem) => {
              if (clipItem[Jsonkeyword].toLowerCase() == filterItem.value.toLowerCase()) {
                console.log("inside test name if block -> clipItem[Jsonkeyword]", clipItem[Jsonkeyword])
                tempDeviceTypeSourceData.push(clipItem);
              }
            })
            console.log("inside test name if block -> tempDeviceTypeSourceData", tempDeviceTypeSourceData)
          }
          else if (filterItem.queryType == "hashtag") {
            let jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
            let sourceArrayList = tempDeviceTypeSourceData.length > 0 ? tempDeviceTypeSourceData : this.unFilteredJsonData;
            tempDeviceTypeSourceData = [];

            // = ["#one", "#two", "#three"]
            sourceArrayList.forEach((hashtagList) => {
              if (hashtagList[jsonkeyword].length > 0) {
                hashtagList[jsonkeyword].forEach((hashtragItem) => {
                  if (hashtragItem.toLowerCase() == filterItem.value.toLowerCase()) {
                    tempDeviceTypeSourceData.push(hashtagList);
                  }
                });
              }
            })
          }
          else if (filterItem.queryType == "clipId") {
            let Jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
            let sourceArrayList = tempDeviceTypeSourceData.length > 0 ? tempDeviceTypeSourceData : this.unFilteredJsonData;
            tempDeviceTypeSourceData = [];
            sourceArrayList.forEach((clipItem) => {
              if (clipItem[Jsonkeyword] == filterItem.value) {
                tempDeviceTypeSourceData.push(clipItem);
              }
            })
          }
        }
      })

      console.log("retrun -> tempDeviceTypeSourceData", tempDeviceTypeSourceData)
      return tempDeviceTypeSourceData;
    } else {
      console.log("return -> this.unFilteredJsonData", this.unFilteredJsonData)
      return this.unFilteredJsonData;
    }
  }

  getHashtagData() {
    console.log('entering getHashtagData function');
    let otherFiltersCount = 0;
    this.secondaryFilterConditions.forEach((filterItem) => {
      if (filterItem.queryType != "hashtag") {
        otherFiltersCount = otherFiltersCount + 1;
      }
    });

    if (otherFiltersCount > 0) {
      let tempDeviceTypeSourceData = [];
      console.log('before loop -> secondaryFilterConditions', this.secondaryFilterConditions);
      this.secondaryFilterConditions.forEach((filterItem) => {
        if (filterItem.queryType != "hashtag") {
          if (filterItem.queryType == "date") {
            let startDateFormatted = new Date(filterItem.value.startDate);
            let endDateFormatted = new Date(filterItem.value.endDate);
            let sourceArrayList = tempDeviceTypeSourceData.length > 0 ? tempDeviceTypeSourceData : this.unFilteredJsonData;
            tempDeviceTypeSourceData = [];
            let jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
            sourceArrayList.forEach((clipItem) => {
              let newDate = new Date(clipItem[jsonkeyword]);
              if ((newDate >= startDateFormatted) && (newDate <= endDateFormatted)) {
                tempDeviceTypeSourceData.push(clipItem);
              }
            })
            // console.log("inside date if block -> tempDeviceTypeSourceData", tempDeviceTypeSourceData)
          }
          else if (filterItem.queryType == "testName" || filterItem.queryType == "tester") {
            let Jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
            // console.log("inside tester if block -> Jsonkeyword", Jsonkeyword)
            let sourceArrayList = tempDeviceTypeSourceData.length > 0 ? tempDeviceTypeSourceData : this.unFilteredJsonData;
            tempDeviceTypeSourceData = [];
            sourceArrayList.forEach((clipItem) => {
              if (clipItem[Jsonkeyword].toLowerCase() == filterItem.value.toLowerCase()) {
                // console.log("inside test name if block -> clipItem[Jsonkeyword]", clipItem[Jsonkeyword])
                tempDeviceTypeSourceData.push(clipItem);
              }
            })
            // console.log("inside test name if block -> tempDeviceTypeSourceData", tempDeviceTypeSourceData)
          }
          else if (filterItem.queryType == "clipId") {
            let Jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
            let sourceArrayList = tempDeviceTypeSourceData.length > 0 ? tempDeviceTypeSourceData : this.unFilteredJsonData;
            tempDeviceTypeSourceData = [];
            sourceArrayList.forEach((clipItem) => {
              if (clipItem[Jsonkeyword] == filterItem.value) {
                tempDeviceTypeSourceData.push(clipItem);
              }
            })
          }
          else if (filterItem.queryType == "deviceType") {
            this.deviceTypeFilterCount = this.deviceTypeFilterCount + 1;

            if (this.deviceTypeFilterCount > 1) {
              let Jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
              let sourceArrayList = this.getDeviceTypeData();
              sourceArrayList.forEach((clipItem) => {
                if (clipItem[Jsonkeyword].toLowerCase().includes(filterItem.value.toLowerCase())) {
                  tempDeviceTypeSourceData.push(clipItem);
                }
              })
            } else {
              let Jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
              let sourceArrayList = this.getFiltersAppliedData();
              tempDeviceTypeSourceData = [];
              sourceArrayList.forEach((clipItem) => {
                if (clipItem[Jsonkeyword].toLowerCase().includes(filterItem.value.toLowerCase())) {
                  tempDeviceTypeSourceData.push(clipItem);
                }
              })
            }
          }
        }
      })
      // console.log("hashtagData retrun -> tempDeviceTypeSourceData", tempDeviceTypeSourceData)
      return tempDeviceTypeSourceData;
    } else {
      // console.log("hashtagData return -> this.unFilteredJsonData", this.unFilteredJsonData)
      return this.unFilteredJsonData;
    }
  }

  getTestStatusData() {
    let otherFiltersCount = 0;
    this.secondaryFilterConditions.forEach((filterItem) => {
      if (filterItem.queryType != "testStatus") {
        otherFiltersCount = otherFiltersCount + 1;
      }
    });

    if (otherFiltersCount > 0) {
      let tempDeviceTypeSourceData = [];
      this.secondaryFilterConditions.forEach((filterItem) => {
        if (filterItem.queryType != "testStatus") {
          if (filterItem.queryType == "date") {
            let startDateFormatted = new Date(filterItem.value.startDate);
            let endDateFormatted = new Date(filterItem.value.endDate);
            let Jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
            let sourceArrayList = tempDeviceTypeSourceData.length > 0 ? tempDeviceTypeSourceData : this.unFilteredJsonData;
            tempDeviceTypeSourceData = [];
            sourceArrayList.forEach((clipItem) => {
              let newDate = new Date(clipItem[Jsonkeyword]);
              if ((newDate >= startDateFormatted) && (newDate <= endDateFormatted)) {
                tempDeviceTypeSourceData.push(clipItem);
              }
            })
          }
          else if (filterItem.queryType == "testName") {
            let Jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
            let sourceArrayList = tempDeviceTypeSourceData.length > 0 ? tempDeviceTypeSourceData : this.unFilteredJsonData;
            tempDeviceTypeSourceData = [];
            sourceArrayList.forEach((clipItem) => {
              if (clipItem[Jsonkeyword].toLowerCase() == filterItem.value.toLowerCase()) {
                tempDeviceTypeSourceData.push(clipItem);
              }
            })
          }
        }
      })
      return tempDeviceTypeSourceData;
    } else {
      return this.unFilteredJsonData;
    }
  }



  removeFilterCondition(filterDetials, index) {
    this.secondaryFilterConditions.splice(index, 1);
    this.mainFilterConditionsRxjs.next(this.secondaryFilterConditions);
  }

  storeFilterCondition(filterCondition, filterType) {
    console.log(" store filtercondition console 4", filterCondition, filterType);
    
    let tempMainFilterConditionArray = [];
    
    console.log(" store filtercondition console 5", this.secondaryFilterConditions);
    this.secondaryFilterConditions.forEach((filterItem) => {
      if (filterItem.queryType == filterType) {
        tempMainFilterConditionArray.push(filterItem.value);
      }
    });

    if (!tempMainFilterConditionArray.includes(filterCondition)) {
      this.secondaryFilterConditions.push({ queryType: filterType, value: filterCondition });
    }
    this.mainFilterConditionsRxjs.next(this.secondaryFilterConditions);

  }

  applyFilterConditions() {
    console.log('applyFilterConditions called console 7')
    this.queriedJsonData = [];

    this.deviceTypeFilterCount = 0;
    this.testStatusFilterCount = 0;
    this.hashtagFilterCount = 0;
    console.log(" console 8 secondary filter condition", this.secondaryFilterConditions);
    
    this.secondaryFilterConditions?.forEach((filterItem) => {
      if (filterItem.queryType == "date") {
        let Jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
        let startDateFormatted = new Date(filterItem.value.startDate);
        let endDateFormatted = new Date(filterItem.value.endDate);
        let sourceArrayList = this.getFiltersAppliedData();
        this.queriedJsonData = [];
        sourceArrayList.forEach((clipItem) => {
          let newDate = new Date(clipItem[Jsonkeyword]);
          if ((newDate >= startDateFormatted) && (newDate <= endDateFormatted)) {
            this.queriedJsonData.push(clipItem);
          }
        })
      }
      else if (filterItem.queryType == "testName" || filterItem.queryType == "tester" || filterItem.queryType == "reelName" || filterItem.queryType == "projectName") {
        let Jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
        let sourceArrayList = this.getFiltersAppliedData();
        this.queriedJsonData = [];
        sourceArrayList.forEach((clipItem) => {
          if (clipItem[Jsonkeyword].toLowerCase() == filterItem.value.toLowerCase()) {
            this.queriedJsonData.push(clipItem);
          }
        })
      }
      else if (filterItem.queryType == "hashtag") {
          console.log("console 9 filteritem", filterItem, "type", filterItem.queryType, );
          
        this.hashtagFilterCount = this.hashtagFilterCount + 1;
        

        if (this.hashtagFilterCount > 1) {
          console.log("applyFilterConditions line- 695 call if part console 10  ");
          let jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
          console.log(" console 15 if part jsonkeyword", jsonkeyword);
          
          let sourceArrayList = this.getHashtagData();
          console.log(" console 16 if part sourcearraylist", sourceArrayList);
          if (this.hashtagFilterCount <= 1) {
            this.queriedJsonData = [];
          }
          sourceArrayList.forEach((hashtagList) => {
            if (hashtagList[jsonkeyword].length > 0) {
              console.log("console 17  called ");
              hashtagList[jsonkeyword].forEach((hashtragItem) => {
                if (hashtragItem.toLowerCase() == filterItem.value.toLowerCase()) {
                  console.log("console 18  called ");
                  this.queriedJsonData.push(hashtagList);
                  console.log("applyFilterConditions line- 707 ");
                }
                console.log("copnsole 19 quried jsondata", this.queriedJsonData);
                
              });

            }
            console.log("console 20 quriedjsondatq", this.queriedJsonData);

          })
        } else {
          console.log("applyFilterConditions line- 715 called else part console 11 ");
          let jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
          console.log("console 12  else part json keyword",jsonkeyword);
          
          let sourceArrayList = this.getFiltersAppliedData();
          console.log("console 13   esle part source array list", sourceArrayList);
          
          this.queriedJsonData = [];
          sourceArrayList.forEach((hashtagList) => {
            console.log("applyFilterConditions line- 720  else part",hashtagList);
            if (hashtagList[jsonkeyword].length > 0) {
              hashtagList[jsonkeyword].forEach((hashtragItem) => {
                if (hashtragItem.toLowerCase() == filterItem.value.toLowerCase()) {
                  this.queriedJsonData.push(hashtagList);
                  console.log("applyFilterConditions line- 725 else part",hashtragItem.toLowerCase() == filterItem.value.toLowerCase());
                  console.log("applyFilterConditions 725 else part", this.queriedJsonData);
                }
              });
            }
          })
          console.log(" console 14 quried json data else part", this.queriedJsonData);
          
        }
      }
      else if (filterItem.queryType == "deviceType") {
        this.deviceTypeFilterCount = this.deviceTypeFilterCount + 1;

        if (this.deviceTypeFilterCount > 1) {
          let Jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
          let sourceArrayList = this.getDeviceTypeData();
          sourceArrayList.forEach((clipItem) => {
            if (clipItem[Jsonkeyword].toLowerCase().includes(filterItem.value.toLowerCase())) {
              this.queriedJsonData.push(clipItem);
            }
          })
        } else {
          let Jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
          let sourceArrayList = this.getFiltersAppliedData();
          this.queriedJsonData = [];
          sourceArrayList.forEach((clipItem) => {
            if (clipItem[Jsonkeyword].toLowerCase().includes(filterItem.value.toLowerCase())) {
              this.queriedJsonData.push(clipItem);
            }
          })
        }
      }
      else if (filterItem.queryType == "testStatus") {
        this.testStatusFilterCount = this.testStatusFilterCount + 1;
        if (this.testStatusFilterCount > 1) {
          let Jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
          let sourceArrayList = this.getTestStatusData();
          // ============================================
          sourceArrayList.forEach((arrayItem) => {
            let tempEventValueString = ""
            let tempEventValueNumber = Number(arrayItem[Jsonkeyword]);
            if (tempEventValueNumber == 0) {
              tempEventValueString = "Running"
            }
            else if (tempEventValueNumber == 3) {
              tempEventValueString = "Finished"
            }
            else if (tempEventValueNumber == 2) {
              tempEventValueString = "Draft"
            }
            // =======================================
            if (tempEventValueString == filterItem.value) {
              this.queriedJsonData.push(arrayItem);
            }
          })
        } else {
          let Jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
          let sourceArrayList = this.getFiltersAppliedData();
          this.queriedJsonData = [];
          // ========================================
          sourceArrayList.forEach((arrayItem) => {
            let tempEventValueString = ""
            let tempEventValueNumber = Number(arrayItem[Jsonkeyword]);
            if (tempEventValueNumber == 0) {
              tempEventValueString = "Running"
            }
            else if (tempEventValueNumber == 3) {
              tempEventValueString = "Finished"
            }
            else if (tempEventValueNumber == 2) {
              tempEventValueString = "Draft"
            }
            // =======================================
            if (tempEventValueString == filterItem.value) {
              this.queriedJsonData.push(arrayItem);
            }
          })
        }
      }
      else if (filterItem.queryType == "clipId") {
        let Jsonkeyword = this.getJsonDataKeyword(filterItem.queryType);
        let sourceArrayList = this.getFiltersAppliedData();
        this.queriedJsonData = [];
        sourceArrayList.forEach((clipItem) => {
          if (clipItem[Jsonkeyword] == filterItem.value) {
            this.queriedJsonData.push(clipItem);
          }
        })
      }
    })

    //Remove duplicates in videos page
    if (this.currentRouteVariable == "/results/videos") {
      const ids = this.queriedJsonData.map(o => o.id)
      const filtered = this.queriedJsonData.filter(({ id }, index) => !ids.includes(id, index + 1))
      this.queriedJsonData = filtered;
    }
    //Remove duplicates in findings page
    if (this.currentRouteVariable == "/results/findings") {
      const ids = this.queriedJsonData.map(o => o.id)
      const filtered = this.queriedJsonData.filter(({ id }, index) => !ids.includes(id, index + 1))
      this.queriedJsonData = filtered;
    }

    console.log('̥inserted data ::', this.queriedJsonData);
    this.availableDataCount = this.queriedJsonData.length;
    this._allFiltersShareQueryService.sendDataChildtoParent(this.queriedJsonData);
    this._allFiltersShareQueryService.sendFilterQueryRequest("dataChildToParent", this._router.url.split('?')[0]);
  }





  // @note Date filter
  handleDateFilterValueChange() {
    if (this.startDateTemplateVar != "" && this.endDateTemplateVar != "") {

      let tempJson = {
        queryType: "date",
        value: {
          startDate: this.startDateTemplateVar,
          endDate: this.endDateTemplateVar,
        }
      }
      this.secondaryFilterConditions.forEach((filterItem, index) => {
        if (filterItem.queryType == "date") {
          this.secondaryFilterConditions.splice(index, 1);
        }
      })
      this.secondaryFilterConditions.push(tempJson);
      this.mainFilterConditionsRxjs.next(this.secondaryFilterConditions);
    }
  }



  //@note test name filter
  handleTestNameValueChange(event) {
    let jsonKeyword = this.getJsonDataKeyword("testName");
    if (event == "") {
      this.initializeTestNameSearchList();
    }
    else {
      let tempResponseArray = [];
      let sourceArrayList = this.getFiltersAppliedData2();
      sourceArrayList.forEach((item) => {
        if (item[jsonKeyword].toLowerCase().includes(event.toLowerCase())) {
          if (!tempResponseArray.includes(item[jsonKeyword])) {
            tempResponseArray.push(item[jsonKeyword]);
          }
        }
      })
      this.testNameSearchList = tempResponseArray;
    }
  }
  initializeTestNameSearchList() {
    let jsonKeyword = this.getJsonDataKeyword("testName");

    let tempResponseArray = [];
    let sourceArrayList = this.getFiltersAppliedData2();
    sourceArrayList.forEach((item) => {
      if (!tempResponseArray.includes(item[jsonKeyword])) {
        tempResponseArray.push(item[jsonKeyword]);
      }
    })
    this.testNameSearchList = tempResponseArray;
  }

  //@note Project name filter
  handleProjectNameValueChange(event) {
    let jsonKeyword = this.getJsonDataKeyword("projectName");
    if (event == "") {
      this.initializeProjectNameSearchList();
    }
    else {
      let tempResponseArray = [];
      let sourceArrayList = this.getFiltersAppliedData2();
      sourceArrayList.forEach((item) => {
        if (item[jsonKeyword].toLowerCase().includes(event.toLowerCase())) {
          if (!tempResponseArray.includes(item[jsonKeyword])) {
            tempResponseArray.push(item[jsonKeyword]);
          }
        }
      })
      this.projectNameSearchList = tempResponseArray;
    }
  }
  initializeProjectNameSearchList() {
    let jsonKeyword = this.getJsonDataKeyword("projectName");

    let tempResponseArray = [];
    let sourceArrayList = this.getFiltersAppliedData2();
    sourceArrayList.forEach((item) => {
      if (!tempResponseArray.includes(item[jsonKeyword])) {
        tempResponseArray.push(item[jsonKeyword]);
      }
    })
    this.projectNameSearchList = tempResponseArray;
  }


  //@note reel name filter
  handleReelNameValueChange(event) {
    let jsonKeyword = this.getJsonDataKeyword("reelName");
    if (event == "") {
      this.initializeReelNameSearchList();
    }
    else {
      let tempResponseArray = [];
      let sourceArrayList = this.getFiltersAppliedData2();
      sourceArrayList.forEach((item) => {
        if (item[jsonKeyword].toLowerCase().includes(event.toLowerCase())) {
          if (!tempResponseArray.includes(item[jsonKeyword])) {
            tempResponseArray.push(item[jsonKeyword]);
          }
        }
      })
      this.reelNameSearchList = tempResponseArray;
    }
  }
  initializeReelNameSearchList() {
    let jsonKeyword = this.getJsonDataKeyword("reelName");
    // console.log("jsonKeyword", jsonKeyword)

    let tempResponseArray = [];
    let sourceArrayList = this.getFiltersAppliedData2();
    // console.log("sourceArrayList", sourceArrayList)
    sourceArrayList.forEach((item) => {
      if (!tempResponseArray.includes(item[jsonKeyword])) {
        tempResponseArray.push(item[jsonKeyword]);
      }
    })
    // console.log("tempResponseArray", tempResponseArray)
    this.reelNameSearchList = tempResponseArray;
  }


  //@note hashtag filter
  handleHashtagValueChange(event) {
    console.log(" sendHashtagToAllFilterComponent1 handleHashtagValueChange event ",event);
    
    let jsonKeyword = this.getJsonDataKeyword("hashtag");
    if (event == "") {
      console.log("sendHashtagToAllFilterComponent1  handleHashtagValueChange if condition ");
      
      this.initializeHashtagSearchList();
    }
    else {
      console.log("sendHashtagToAllFilterComponent1  handleHashtagValueChange else condition ");
      let tempResponseArray = [];
      let sourceArrayList = this.getFiltersAppliedData2();
      console.log("sendHashtagToAllFilterComponent1  handleHashtagValueChange source array list ",sourceArrayList);
      // = ["#one", "#two", "#three"]
      sourceArrayList.forEach((hashtagList) => {
        if (hashtagList[jsonKeyword].length > 0) {
          hashtagList[jsonKeyword].forEach((hashtragItem) => {
            if (!tempResponseArray.includes(hashtragItem)) {
              if (hashtragItem.toLowerCase().includes(event.toLowerCase())) {
                tempResponseArray.push(hashtragItem);
              }
            }
          });
        }
      })
      this.hashtagSearchList = tempResponseArray;
      console.log("sendHashtagToAllFilterComponent1  handleHashtagValueChange hashtagSearchList ",this.hashtagSearchList);
    }
  }
  initializeHashtagSearchList() {
    let jsonKeyword = this.getJsonDataKeyword("hashtag");
    console.log(" sendHashtagToAllFilterComponent jsonKeyword", jsonKeyword)

    let tempResponseArray = [];
    // console.log('getHashtagData called from bubble menu function');

    let sourceArrayList = this.getHashtagData();
    console.log(" sendHashtagToAllFilterComponent sourceArrayList", sourceArrayList)

    sourceArrayList.forEach((hashtagList) => {
      if (hashtagList[jsonKeyword].length > 0) {
        hashtagList[jsonKeyword].forEach((hashtragItem) => {
          if (!tempResponseArray.includes(hashtragItem)) {
            tempResponseArray.push(hashtragItem);
          }
        });
      }
    })
    console.log(" sendHashtagToAllFilterComponent initializeHashtagSearchList", tempResponseArray);

    this.hashtagSearchList = tempResponseArray;
  }



  // @note tester Filter block =======================
  initializeTesterSearchList() {
    let jsonKeyword = this.getJsonDataKeyword("tester");

    let tempResponseArray = [];
    let sourceArrayList = this.getFiltersAppliedData2();
    sourceArrayList.forEach((item) => {
      if (!tempResponseArray.includes(item[jsonKeyword])) {
        tempResponseArray.push(item[jsonKeyword]);
      }
    })
    this.testerSearchList = tempResponseArray;
  }
  handleTesterValueChange(event) {
    let jsonKeyword = this.getJsonDataKeyword("tester");
    if (event == "") {
      this.initializeTesterSearchList();
    }
    else {
      let tempResponseArray = [];
      let sourceArrayList = this.getFiltersAppliedData2();
      sourceArrayList.forEach((item) => {
        if (item[jsonKeyword].toLowerCase().includes(event.toLowerCase())) {
          if (!tempResponseArray.includes(item[jsonKeyword])) {
            tempResponseArray.push(item[jsonKeyword]);
          }
        }
      })
      this.testerSearchList = tempResponseArray;
    }
  }


  // @note Clip Id Filter block =======================
  initializeClipIdOptionsList() {
    let jsonKeyword = this.getJsonDataKeyword("clipId");

    let tempResponseArray = [];
    let sourceArrayList = this.getFiltersAppliedData2();
    sourceArrayList.forEach((item) => {
      if (!tempResponseArray.includes(item[jsonKeyword])) {
        tempResponseArray.push(item[jsonKeyword]);
      }
    })
    this.clipIdSearchList = tempResponseArray;
  }
  handleClipIdValueChange(event) {
    // console.log("event", event)
    let jsonKeyword = this.getJsonDataKeyword("clipId");
    // console.log("jsonKeyword", jsonKeyword)
    if (event == "") {
      // console.log('̥inside if');
      this.initializeClipIdOptionsList();
    }
    else {
      // console.log('̥inside else');
      let tempResponseArray = [];
      let sourceArrayList = this.getFiltersAppliedData2();
      // console.log("sourceArrayList", sourceArrayList)
      sourceArrayList.forEach((item) => {
        if (item[jsonKeyword] == event) {
          if (!tempResponseArray.includes(item[jsonKeyword])) {
            tempResponseArray.push(item[jsonKeyword]);
          }
        }
      })
      // console.log("tempResponseArray", tempResponseArray)

      this.clipIdSearchList = tempResponseArray;
    }
  }



  // @note checkbox Filter block =======================
  handleCheckBoxClicked(event, eventQueryType) {
    if (eventQueryType == "deviceType") {
      if (event.target.checked) {
        // add elements
        this.secondaryFilterConditions.push({ queryType: eventQueryType, value: event.target.value })
      } else {
        // remove elements
        this.secondaryFilterConditions.forEach((filterItem, index) => {
          if (filterItem.queryType == eventQueryType) {
            if (filterItem.value == event.target.value) {
              this.secondaryFilterConditions.splice(index, 1);
            }
          }
        });
      }
    }
    else if (eventQueryType == "testStatus") {
      // console.log("event.target.value", event.target.value)
      // console.log("event.target.checked", event.target.checked)
      let tempEventValueString = ""
      let tempEventValueNumber = Number(event.target.value);
      if (tempEventValueNumber == 0) {
        tempEventValueString = "Running"
      }
      else if (tempEventValueNumber == 3) {
        tempEventValueString = "Finished"
      }
      else if (tempEventValueNumber == 2) {
        tempEventValueString = "Draft"
      }
      // console.log("tempEventValueString", tempEventValueString)
      // =======================================
      if (event.target.checked) {
        // add elements
        // console.log('inside if');
        this.secondaryFilterConditions.push({ queryType: eventQueryType, value: tempEventValueString })
      } else {
        // remove elements
        // console.log('inside else');
        this.secondaryFilterConditions.forEach((filterItem, index) => {
          if (filterItem.queryType == eventQueryType) {
            if (filterItem.value == tempEventValueString) {
              this.secondaryFilterConditions.splice(index, 1);
            }
          }
        });
      }
    }
    // console.log("this.secondaryFilterConditions", this.secondaryFilterConditions)

    this.mainFilterConditionsRxjs.next(this.secondaryFilterConditions);
  }
}

