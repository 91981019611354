import {Component, OnInit} from '@angular/core';
import {PaymentService} from 'src/app/shared/services/payment.service';
import {AuthService} from 'src/app/shared/services/auth.service';
import Swal from 'sweetalert2'
import {ActivatedRoute, Params, Router} from '@angular/router';
import { StoreOrderedTestDataService } from 'src/app/shared/services/store-ordered-test-data.service';
import { ModelBoxService } from 'src/app/shared/services/model-box.service';

@Component({
  selector: 'app-credits-plans',
  templateUrl: './credits-plans.component.html',
  styleUrls: ['./credits-plans.component.scss'],
})
export class CreditsPlansComponent implements OnInit {
  
  displayProper;

  allPlans: any;
  filteredPlan: any;
  availablePlansForUser: any = {
    flexible: {},
    professional: []
  };
  userCurrentSubscriptionType: any;

  isFastSpringAccountCreated: boolean = false;
  accountID: any;
  sessionId: any;
  name: any;
  customCredit: any = 5;
  totalCredit: number = 0;
  globalPlanName: any;
  subID: any;
  totalCreditsall: any;
  dataSession: any;

  availableCoupons: any = [];
  couponAvl: any;

  constructor(
    private _paymentService: PaymentService,
    private _authService: AuthService,
    private _route: ActivatedRoute,
    public router: Router,
    private _storeOrderedTestDataService: StoreOrderedTestDataService,
    private _modelBoxService: ModelBoxService
  ) {
  }

  ngOnInit() {
    window['onPaymentReceived'] = (data) => {
      this.onPaymentReceived(data)
    }
    this.getBillingAddress();
    this.getAvailableCoupon();

    // @note step 1 
    this._route.queryParamMap.subscribe((reqparams: Params) => {
      if (reqparams.params['totalCount']) {

        //TODO: if credit available available credit - required Credit
        let availableCredit: any;

        this._authService.changesInCredits.subscribe((res)=>{
          availableCredit = res
        });
        
        let calculatedCredit = reqparams.params.totalCount - availableCredit;

        this.getAllProducts(calculatedCredit);
        // this.customCredit = reqparams.params.totalCount;
      } else {
        this.getAllProducts();
      }
    });
  }

  getBillingAddress() {
    this._paymentService.getCustomer()
      .subscribe((res) => {
        this.accountID = res['data'].account;
        this.isFastSpringAccountCreated = !!res['data'].id;
      });
  }

  getAvailableCoupon() {
    this._paymentService
      .getAvailableCoupon()
      .subscribe(res => {
        this.availableCoupons = res['data'] || []
      });
  }

  getAllProducts(totalDefaultCredit = 5) {
    this._paymentService
      .getAllProducts()
      .subscribe((allPlans: any) => {
        this.allPlans = allPlans.map(plan => {
          let totalCredit, perCreditCost, type;
          if (plan.name.includes('flexible')) {
            type = 'flexible';
            totalCredit = totalDefaultCredit;
            perCreditCost = plan.data.products[0].pricing.price.USD;
          } else {
            if (plan.name == 'professional-yearly') {
              type = 'year';
              totalCredit = 60;
            } else {
              type = 'month';
              totalCredit = 5;
            }
            perCreditCost = plan.data.products[0].pricing.price.USD / totalCredit;
          }
          return { name: plan.name, totalCredit, perCreditCost, type };
        });

        this._paymentService
          .getAllOrder()
          .subscribe((allOrder: any = []) => {
            this._paymentService.getCustomer().subscribe(allordercredit => {
              this.couponAvl = allOrder && allOrder.length > 0 ? allOrder[0].items[0].coupon : '';
              this._authService.couponAvlData.next(this.couponAvl)
              if (allordercredit['data'].current_credit) {
                this.totalCreditsall = allordercredit['data'].current_credit;
                // update total credits
              } else {
                this.totalCreditsall = 0
              }
              this._authService.changesInCredits.next(this.totalCreditsall);
            });

            // check if user is subscribed any professional plan
            let subscribedPlan = allOrder.find(or => or['product'].includes('professional'))
            if (subscribedPlan && subscribedPlan.product) {
              this.userCurrentSubscriptionType = subscribedPlan.product.split('-')[1]
              this.filteredPlan = this.allPlans
                .filter(plan => plan.name.includes(this.userCurrentSubscriptionType))
                .map(plan => {
                  if (plan.name == subscribedPlan.product) {
                    plan['isActive'] = true
                    plan['isShow'] = true
                    plan['order_id'] = subscribedPlan.order_id
                  }
                  return plan;
                });
            } else {
              this.userCurrentSubscriptionType = ''
              this.filteredPlan = this.allPlans
                .filter(plan => !plan.name.includes('flexible-'))
                .map(plan => {
                  if (plan.name == 'professional-monthly') {
                    plan['isShow'] = true
                  }
                  return plan
                });
            }
            this.availablePlansForUser = {
              flexible: this.filteredPlan.find(plan => plan.name.includes('flexible')),
              professional: this.filteredPlan.filter(plan => plan.name.includes('professional'))
            }
          });
      });
  }

  updateFlexiblePlan() {
    this.availablePlansForUser.flexible['totalCredit'] = this.customCredit
  }

  selctetProfessionalPlanType(e) {
    this.availablePlansForUser.professional = this.availablePlansForUser.professional
      .map(plan => {
        plan['isShow'] = (plan.type == 'year') ? e.target.checked : !e.target.checked
        return plan
      });
  }

  addSession(plan) {

    console.log("step 1");
    

    this.globalPlanName = plan.name;
    if (this.isFastSpringAccountCreated) {
      console.log("step2");
      
      if (plan.name.includes('flexible', 'flexible-monthly', 'flexible-yearly')) {
        this.dataSession = {
          account: this.accountID,
          items: [
            {
              product: plan.name,
              // quantity: 1
              quantity: plan.name.includes('flexible')
                ? plan.totalCredit
                : 1,
              pricing: {
                price: {
                  USD: plan.name.includes('flexible')
                    ? plan.perCreditCost
                    : (plan.totalCredit * plan.perCreditCost)
                }
              }
            }
          ]
        };
      } else {
        this.dataSession = {
          account: this.accountID,
          items: [
            {
              product: plan.name,
              quantity: 1
            }
          ]
        };
      }
      // @note buy extra credit fastspring
      this._paymentService
        .addSessionForPlan(this.dataSession)
        .subscribe(
          (res) => {
            this.sessionId = res['data'].id;
            window['fastspring'].builder.checkout(this.sessionId);
          },
          (err) => {
          });
    } else {
      console.log("step3");
      
      let s = {
        'reset': true,
        'products': [
          {
            'path': plan.name,
            'quantity': plan.name.includes('flexible') ? plan.totalCredit : 1,
          }
        ],
        'checkout': true
      }
      window['fastspring'].builder.push(s);
    }
  }

  onPaymentReceived(data) {

    console.log("step 4");
    
    
    let testDetials: any;
    let fastspringRequestData = {};
    this._storeOrderedTestDataService.currentTestData.subscribe(param => { testDetials = param ;})
    fastspringRequestData["order_id"]= data["id"];

    //TODO:  Based on URL Para, restric Api request payload
    this._route.queryParamMap.subscribe((reqparams: Params) => {
      if (reqparams.params['totalCount']) {
        fastspringRequestData["createtest_id"]= testDetials["test_id"];
        fastspringRequestData["required_videos"]= {
            "desktop" : testDetials["desktop_tester_count"],
            "mobile" : testDetials["mobile_tester_count"], 
            "tablet" : testDetials["tab_tester_count"]
        }
      }
    });

    //TODO:  Fastspring order v2 API
    this._paymentService.postFastspringCallback(fastspringRequestData).subscribe((res)=>{
      console.log("step 5");
      
      //TODO: order videos flow
      this._route.queryParamMap.subscribe((reqparams: Params) => {
        if (reqparams.params['totalCount']) {

          this.router.navigate(['/dashboard_tests/all'], { queryParams:{ creditPlansComponent:true }}).then((e)=>{
            let headerText = "Order Successful"
            let bodyText = 'Your videos order are confirmed.';
            this._modelBoxService.showModelBox(
              true,
              bodyText,
              headerText
            );
          });
          
        } else{
          window.location.reload();
        }
      });

    })

    //TODO:  Fastspring old order API 
    // this._paymentService.saveOrder(data)
    // .subscribe((res) => {
    //   this.getAvailableCoupon()
    //   this.getBillingAddress();
    //   this.getAllProducts();
    // });

    
  }

  showCancelPopup(subscription_id) {
    this._paymentService.getAllOrder().subscribe((res: Array<any>) => {
      res.filter(res => {
        let data = []
        data.push(res.product)
        data.filter(res1 => {
          if (res1 === subscription_id) {
            this.subID = res.subscription_id
          }
        });
      });
    });
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to cancel this plan!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel it!'
    }).then((result) => {
      if (result.value) {
        this._paymentService.cancelOrder(this.subID)
          .subscribe(
            res => {
              Swal.fire(
                'Cancelled!',
                'Your subscription has been cancelled.',
                'success'
              )
              this.getBillingAddress();
              this.getAllProducts();
            },
            err => {
              Swal.fire(
                'Error!',
                err,
                'error'
              );
            });
      }
    });
  }
}
