import { Component, HostListener, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { UpVideoPlayerService } from 'src/app/shared/services/up-video-player.service';
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';
import { environment } from 'src/environments/environment';
import {AuthService} from 'src/app/shared/services/auth.service';
import { GeneralApiService } from 'src/app/core/api-call/general-api.service';

@Component({
  selector: 'app-userpeek-video-player',
  templateUrl: './userpeek-video-player.component.html',
  styleUrls: ['./userpeek-video-player.component.scss']
})

export class UserpeekVideoPlayerComponent implements OnInit {

  @ViewChild("videoPlayer") videoPlayer: any;
  @ViewChild("progressBarInner") progressBarInner: any;
  @ViewChild("progressBarOuter") progressBarOuter: any;

  showPlaybackSpeedPopup: boolean = false;
  showPlayButton: boolean = true;
  showMuteButton: boolean = true;
  showCreateClipPopup: boolean = false;

  progressBarValue: number = 0;
  videoTotalDuration: string = "00:00";
  videoCurrentDuration: string =  "00:00";
  createClipStartTime: string = "00:00";
  createClipEndTime: string =  "00:00";
  clipDuration: number = 0;
  currentVideoSpeed: any = {
    "value": 1,
    "key": "1x"
  }
  playbackSpeedList: any = [
    {
      "value": 0.5,
      "key": "0,5x"
    },
    {
      "value": 1,
      "key": "1x"
    },
    {
      "value": 1.25,
      "key": "1,25x"
    },
    {
      "value": 1.5,
      "key": "1,5x"
    },
    {
      "value": 2,
      "key": "2x"
    },
  ]
  startPosition:any = 0; 
  endPosition:any = 0;
  isDraggingStart:boolean = false;
  isDraggingEnd:boolean = false;
  clipStartTime:any = "00:00";
  clipEndTime:any = "00:00";
  player_video_link: string;
  selectedVideoDetials:any = {};
  videoEndTimeRange: any = 0;
  autoVideoPause: boolean = false;



  constructor(
    private _upVideoPlayerService: UpVideoPlayerService,
    private _toastMsgService: ToastMsgService,
    private _authServices: AuthService,
    private el: ElementRef,
    private _generalApiService: GeneralApiService
  ) {
    // Note: set video start time using the selected text on transcript section. content menu play button
    this._upVideoPlayerService.videoTimeRange.subscribe((time_dict)=>{
      console.log('time_dict', time_dict)
      this.videoPlayer.nativeElement.currentTime = time_dict["start_time"];
      this.videoEndTimeRange = time_dict["end_time"];
      this.clipStartTime = this.secondsToTime(time_dict["start_time"])
      this.clipEndTime = this.secondsToTime(time_dict["end_time"])
      this.autoVideoPause = true;
      this.handlePlayVideo("play");
      // this.setTrimHandlesPosition(time_dict);
    });
    // when text is selected on the transcript tab, set video current time
    this._upVideoPlayerService.videoCurrentTime.subscribe((start_time)=>{
      this.videoPlayer.nativeElement.currentTime = start_time;
      if(this.showPlayButton === false){
        this.handlePlayVideo("play");
      }
    });
  }

  ngOnInit() {
    try{
      let tempJsonData = localStorage.getItem("cacheSelectedVideoDetials");
      if(tempJsonData != undefined || tempJsonData != null){
        this.selectedVideoDetials = JSON.parse(tempJsonData);
        this.player_video_link = `${environment.apiUrl}${this.selectedVideoDetials.file}`;
        this.videoPlayer.nativeElement.load();
        this.initializeVideoTime()
        this.attachEventListeners()
      } else {
        this._toastMsgService.showMsg("failed", "video data not found!", 3000);
      }
    } catch(e){
      console.log(e)
    }
    console.log('call function')
    this.checkResponsiveness()
  }
  ngOnDestroy() {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  // ======= Event Listeners ========
  // keyboard event listeners
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.code === 'Space') {
      this.handlePlayVideo()
    }
  }
  // mouse click event listeners
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    // playback speed popup
    if((event.target as HTMLElement).closest(".up-speed") == null){
      if((event.target as HTMLElement).closest(".up-speed-popup") == null){
        if(this.showPlaybackSpeedPopup === true){
          this.showPlaybackSpeedPopup = false;
        }
      }
    }
  }
  // window resize event listeners
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    console.log('Window resized:', window.innerWidth);
    this.checkResponsiveness()
  }
  checkResponsiveness(){
    setTimeout(() => {      
      // create clip button responsiveness
      let create_clip_btn_text = document.getElementById("create-clip-btn-text") as HTMLSpanElement;
      // console.log('checking responsiveness: ', window.innerWidth)
      if(window.innerWidth < 1530){
        // console.log('checking responsiveness: display none',)
        create_clip_btn_text.style.display = "none";
      } else {
        // console.log('checking responsiveness: display inline',)
        create_clip_btn_text.style.display = "inline";
      }
    }, 500);
  }


  // =========== create clip, video trim bar ==============
  private attachEventListeners() {
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
  }
  handleStartDrag(){
    this.isDraggingStart = true;
  }
  handleEndDrag(){
    this.isDraggingEnd = true;
    // console.log('handleEndDrag function called, this.isDraggingEnd: ', this.isDraggingEnd )
  }
  handleMouseMove(e){
    // console.log('handleMouseMove function called')
		if (this.isDraggingStart) {
			this.updatePosition(e.clientX, true)
		}
		if (this.isDraggingEnd) {
      // console.log('handleMouseMove function | going to call the this.updatePosition| value of this.isDraggingEnd :', this.isDraggingEnd)
			this.updatePosition(e.clientX, false)
		}
  }
  setTrimHandlesPosition(time_dict){
    // start time
    // let temp_start_time = time_dict["start_time"];
    // let start_time_array = temp_start_time.split(":");
    // let start_time_seconds = (parseInt(start_time_array[0])*60) + parseInt(start_time_array[1]);

    const start_percentage = ((time_dict["start_time"] / this.videoPlayer.nativeElement.duration) * 100) / 100;
    const end_percentage = ((time_dict["end_time"] / this.videoPlayer.nativeElement.duration) * 100) / 100;

    console.log('start_percentage', start_percentage)
    console.log('end_percentage', end_percentage)
    this.startPosition = start_percentage;
    this.endPosition = end_percentage;

  }
  updatePosition(clientX, isStartHandle){
    const videoDuration = this.videoPlayer.nativeElement.duration;
    // console.log('updatePosition function called | value of clientX: ', clientX)
		const { left, width } = this.videoPlayer.nativeElement.getBoundingClientRect()
    // console.log('clientX:', clientX)
    // console.log('left:', left)
    // console.log('width:', width)
		let position = (clientX - left) / width
    // console.log('position:', position)
		position = Math.max(0, Math.min(1, position)) // Clamp position between 0 and 1
    console.log('position:', position)
		if (isStartHandle) {
      const newStartPosition = Math.min(position, this.endPosition);
      if((this.endPosition * videoDuration) - (newStartPosition*videoDuration) >= 5){
        this.startPosition = newStartPosition
      } else {
        this.startPosition = this.startPosition;
      }
      console.log('this.startPosition', this.startPosition)
		} else {
      const newEndPosition = Math.max(position, this.startPosition);
      if((newEndPosition * videoDuration) - (this.startPosition * videoDuration) >= 5){
        this.endPosition = newEndPosition;
      } else {
        this.endPosition = this.endPosition;
      }
		}    

    this.getVideoDuration()
  }
  handleMouseUp = () => {
    this.isDraggingStart = false;
    this.isDraggingEnd = false;
    // console.log(`handleMouseUp function called this.isDraggingStart: ${this.isDraggingStart} | this.isDraggingEnd: ${this.isDraggingEnd}`)
	}
  getVideoDuration() {
    const videoDuration = this.videoPlayer.nativeElement.duration;
    const startTime = this.startPosition * videoDuration;
    const endTime = this.endPosition * videoDuration;
    this.clipStartTime = this.secondsToTime(startTime)
    this.clipEndTime = this.secondsToTime(endTime)
    this.clipDuration = Math.round(endTime - startTime);
    // return { startTime, endTime };
    // console.log('startTime', startTime.toFixed(4))
  }

// ========= on page load initailize the video with URL ===========
  initializeVideoTime() {
    this.videoTotalDuration = this.secondsToTime(this.videoPlayer.nativeElement.duration);
    this.createClipEndTime = this.videoTotalDuration;
    this.endPosition = 1;
    // const video = document.createElement('video');
    // video.src =  `https://stagapi.userpeek.io/media/250/167/199/159/videos/1710919488570_demo1.webm`;
    // video.preload = 'metadata';
    // console.log('video', video)
    // video.onloadedmetadata = () => {
    //   console.log('called')
    //   this.videoTotalDuration = this.secondsToTime(video.duration);
    //   this.createClipEndTime = this.videoTotalDuration;
    //   this.endPosition = 1;
    // }
  }

  secondsToTime(seconds) {
    seconds = Math.round(seconds)
    var minutes = Math.floor(seconds / 60);
    var remainingSeconds = seconds % 60;
    // Formatting minutes and seconds to have leading zeros if needed
    var formattedMinutes = ('0' + minutes).slice(-2);
    var formattedSeconds = ('0' + remainingSeconds).slice(-2);
    return formattedMinutes + ":" + formattedSeconds;
  }

  handleVideoTimeChanged(){
    // this.videoCurrentUpdatedTime = this.videoPlayer.nativeElement.currentTime;
    let currentDuration = this.videoPlayer.nativeElement.currentTime;
    let totalDuration = this.videoPlayer.nativeElement.duration;
    // console.log('currentDuration', Math.round(currentDuration))
    // video duration to display
    this.videoCurrentDuration = this.secondsToTime(this.videoPlayer.nativeElement.currentTime);

    // video progress bar update value dynamically
    this.progressBarValue = (currentDuration / totalDuration) * 100;
    this.progressBarInner.nativeElement.style.width = this.progressBarValue + "%";

    // console.log('currentDuration:', currentDuration)
    // console.log('this.videoEndTimeRange:', this.videoEndTimeRange)

    if(currentDuration >= totalDuration){
      this.handlePlayVideo("pause"); 
    }
    // stop video on specific time range
    if(currentDuration >= this.videoEndTimeRange && this.autoVideoPause === true){
      console.log('video paused')
      this.handlePlayVideo("pause");
      this.autoVideoPause = false;
    }
  }

  // video progress bar chnage time on click
  ChangeTimeRange(value: any) {
    var time = (value.offsetX / document.getElementById('progess').offsetWidth) * this.videoPlayer.nativeElement.duration
    this.videoPlayer.nativeElement.currentTime = time;

    var percentage = (this.videoPlayer.nativeElement.currentTime / this.videoPlayer.nativeElement.duration) * 100
    document.getElementById('fill').style.width = percentage + "%"

    if(this.showPlayButton === false){
      // play state
      this.handlePlayVideo("play");
    } 

    // this.showPlayButton = false;
    // this.videoPlayer.nativeElement.play();
    // this.showPlayButton = true;
    // document.getElementById('fill').style.transition = '0.5s'
  }



  // ======== video player controls ===============
  handlePlayVideo(event = null){
    if(event === null){
      // clicked on the icon
      if(this.showPlayButton === true){
        // play state
        this.showPlayButton = false;
        this.videoPlayer.nativeElement.play();
      } else {
        // pause state
        this.showPlayButton = true;
        this.videoPlayer.nativeElement.pause();
      }
    }
    else if(event === "play"){
        // play state
        this.showPlayButton = false;
        this.videoPlayer.nativeElement.play();
    }
    else if(event === "pause"){
      // pause state
      this.showPlayButton = true;
      this.videoPlayer.nativeElement.pause();
    }
    else if(event.target.tagName.toLowerCase() === "video") {
      // clicked on the video
      if(this.showPlayButton === true){
        // play state
        this.showPlayButton = false;
        this.videoPlayer.nativeElement.play();
      } else {
        // pause state
        this.showPlayButton = true;
        this.videoPlayer.nativeElement.pause();
      }
    }
  }

  handleSkipTenSecondsBackward(){
    var newDuration = this.videoPlayer.nativeElement.currentTime;
    newDuration -= 10;
    if(newDuration < 0){
      this.videoPlayer.nativeElement.currentTime = 0;
      if(this.showPlayButton === false){
        this.handlePlayVideo("play");
      }
    } else {
      this.videoPlayer.nativeElement.currentTime = newDuration;
      if(this.showPlayButton === false){
        this.handlePlayVideo("play");
      }
    }
  }

  handleSkipTenSecondsForward(){
    var newDuration = this.videoPlayer.nativeElement.currentTime;
    var duration = this.videoPlayer.nativeElement.duration;
    newDuration += 10;
    if(newDuration > duration){
      this.videoPlayer.nativeElement.currentTime = duration;
      if(this.showPlayButton === false){
        this.handlePlayVideo("play");
      }
    } else {
      this.videoPlayer.nativeElement.currentTime = newDuration;
      if(this.showPlayButton === false){
        this.handlePlayVideo("play");
      }
    }
  }

  handlePlayPreviousVideo(){
    this.switchTest(this.selectedVideoDetials.id, "previous");
  }

  handlePlayNextVideo(){
    this.switchTest(this.selectedVideoDetials.id, "next");
  }

  handleVideoSound(){
    let video_mute_state = this.videoPlayer.nativeElement.muted;
    if(video_mute_state ===  false){
      this.showMuteButton = false;
      this.videoPlayer.nativeElement.muted = true;
    } else {
      this.showMuteButton = true;
      this.videoPlayer.nativeElement.muted = false;
    }
  }

  handleVideoSpeed(dict_value){
    this.currentVideoSpeed = dict_value;
    this.videoPlayer.nativeElement.playbackRate = dict_value["value"];
    this.showPlaybackSpeedPopup = false;
  }

  handleVideoFullscreen(){
    // need to add in future
  }

  handleReportVideo(){
    this._authServices.getClientProfile().subscribe((res) => {
      window.open(`https://userpeek.com/submit-video-for-review/?videoid=${this.selectedVideoDetials.id}&first=${res.data[0].first_name}&last=${res.data[0].last_name}&email=${res.data[0].email}`,"_blank");
    });
  }

  handleShareVideo(){
    navigator.clipboard.writeText("sample video link");
    this._toastMsgService.showMsg("success", "Video URL copied", 2000);
  }

  handlePictureInPicture(){
    this.videoPlayer.nativeElement.requestPictureInPicture();
  }

  // create clip functions
  handleCreateClip(){
    let tempDict = {
      "start_time":this.clipStartTime,
      "end_time":this.clipEndTime
    }
    this._upVideoPlayerService.createClipFromPlayer(tempDict);
    // this.showCreateClipPopup = false;
  }
  openCreateClipBox(){
    // open create clip dialog box
    this.showCreateClipPopup = !this.showCreateClipPopup;
    // pause video
    this.handlePlayVideo("pause")
    // set Start & End time in create clip dialog box
    let currentTime = this.videoPlayer.nativeElement.currentTime;
    this.clipStartTime = this.secondsToTime(currentTime);
    this.clipEndTime = this.secondsToTime(currentTime + 10);

    const start_percentage = ((currentTime / this.videoPlayer.nativeElement.duration) * 100) / 100;
    const end_percentage = (((currentTime + 10) / this.videoPlayer.nativeElement.duration) * 100) / 100;
    console.log('start_percentage', start_percentage)
    console.log('end_percentage', end_percentage)
    this.startPosition = start_percentage;
    this.endPosition = end_percentage;
  }
  changeInValue(){
    var startTime = this.clipStartTime.split(":");
    var minutes1 = parseInt(startTime[0]);
    var seconds1 = parseInt(startTime[1]);
    var startTimeResult = (minutes1 * 60) + seconds1;
    console.log('startTimeResult', startTimeResult)

    var endTime = this.clipEndTime.split(":");
    var minutes = parseInt(endTime[0]);
    var seconds = parseInt(endTime[1]);
    var endTimeResult = (minutes * 60) + seconds;
    console.log('endTimeResult', endTimeResult)
    
    let a = endTimeResult - startTimeResult;
    console.log('a',a);

    this.clipDuration = a;
  }

  createClipDialogTooltip(){
    let tooltipBox = document.getElementById("create-clip-popup-tooltip") as HTMLDivElement;
    if(tooltipBox.style.display == "none" || tooltipBox.style.display == ""){
      tooltipBox.style.display = "block";
    } else {
      tooltipBox.style.display = "none";
    }
  }








  // show tooltip on hover
  showTooltip(id){
    let element = document.getElementById(id) as HTMLElement;
    element.style.display= "block"
  }
  closeTooltip(id){
    let element = document.getElementById(id) as HTMLElement;
    element.style.display= "none"
  }

  // Move to next or previous test
  switchTest(test_id, action){
    let tempArray = this.selectedVideoDetials.file.split("/")
    let project_id = parseInt(tempArray[2]);
    let list_of_test_array = [];
    this._generalApiService.getTestDetails(project_id, this.selectedVideoDetials.test).subscribe((res: any)=>{
      list_of_test_array = res.data.video_list;
      console.log('list_of_test_array', list_of_test_array)
      let arrayIndexLength = list_of_test_array.length - 1;
      let current_index = list_of_test_array.findIndex(obj => obj.id === test_id)
      console.log('test_id', test_id)
      console.log('current_index', current_index)
      if(action === "next"){
          let nextItemIndex = current_index + 1;
          if(nextItemIndex > arrayIndexLength){
            this._toastMsgService.showMsg("failed", "End of list", 3000);
          } else {
            console.log(list_of_test_array[nextItemIndex])
            localStorage.removeItem("cacheSelectedVideoDetials");
            localStorage.setItem('cacheSelectedVideoDetials', JSON.stringify(list_of_test_array[nextItemIndex]));
            window.location.reload();
          }
      }
      else if(action === "previous"){
          let previousItemIndex = current_index - 1;
          if(previousItemIndex < 0){
            this._toastMsgService.showMsg("failed", "End of list", 3000);
          } else {
            localStorage.removeItem("cacheSelectedVideoDetials");
            localStorage.setItem('cacheSelectedVideoDetials', JSON.stringify(list_of_test_array[previousItemIndex]));
            window.location.reload();
          }
      }
    });
  }

//   switchTest(test_id=0,action=''){
//     let arrayIndexLength = arrayOne.length - 1;
//     let current_index = arrayOne.findIndex(obj => obj.test === test_id)
//     if(action === "next"){
//         let nextItemIndex = current_index + 1;
//         if(nextItemIndex > arrayIndexLength){
//             console.log('error')
//         } else {
//             console.log(arrayOne[nextItemIndex])
//         }
//     }
//     else if(action === "previous"){
//         let previousItemIndex = current_index - 1;
//         if(previousItemIndex < 0){
//             console.log('error')
//         } else {
//             console.log(arrayOne[previousItemIndex])
//         }
//     }
// }





}
