import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SplitWordsPipe} from './pipes/split-words.pipe';
import {ToggleDirective} from './directives/toggle.directive';
import {UsernameShowPipe} from './pipes/username-show.pipe';
import {SearchFilterPipe} from './pipes/search-filter.pipe';
import {TestFilterPipe} from './pipes/test-filter.pipe';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {RouterModule} from '@angular/router';
import {SearchProjectPipe} from './pipes/search-project.pipe';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ShowHideEventDirective} from './directives/show-hide-event.directive';
import {ModelBoxComponent} from './model-box/model-box.component';
import {LoaderComponent} from './loader/loader.component';
import { ModelBox2Component } from './model-box2/model-box2.component';
import { SupportedByComponent } from './supported-by/supported-by.component';
import { CustomPopupBoxComponent } from './custom-popup-box/custom-popup-box.component';
import { NavbarOneComponent } from './navbar-one/navbar-one.component';
import { WordCountPipe } from './pipes/word-count.pipe';
import { MultiFilterMenuComponent } from './multi-filter-menu/multi-filter-menu.component';
import { SelectProjectWarningComponent } from './select-project-warning/select-project-warning.component';
import { AllFiltersComponent } from './all-filters/all-filters.component';
import { BreadcrumbsNavbarComponent } from './breadcrumbs-navbar/breadcrumbs-navbar.component';

@NgModule({
  declarations: [
    SplitWordsPipe,
    ToggleDirective,
    UsernameShowPipe,
    SearchFilterPipe,
    TestFilterPipe,
    PageNotFoundComponent,
    SearchProjectPipe,
    ShowHideEventDirective,
    ModelBoxComponent,
    LoaderComponent,
    ModelBox2Component,
    SupportedByComponent,
    CustomPopupBoxComponent,
    NavbarOneComponent,
    WordCountPipe,
    MultiFilterMenuComponent,
    SelectProjectWarningComponent,
    AllFiltersComponent,
    BreadcrumbsNavbarComponent,
    // ClipTimesShowPipe,


  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxSpinnerModule,
    FormsModule,

  ],
  exports: [
    SplitWordsPipe,
    UsernameShowPipe,
    SearchFilterPipe,
    NgxSpinnerModule,
    TestFilterPipe,
    SearchProjectPipe,
    ShowHideEventDirective,
    ModelBoxComponent,
    ModelBox2Component,
    LoaderComponent,
    SupportedByComponent,
    CustomPopupBoxComponent,
    NavbarOneComponent,
    WordCountPipe,
    MultiFilterMenuComponent,
    SelectProjectWarningComponent,
    AllFiltersComponent,
    BreadcrumbsNavbarComponent,
    // ClipTimesShowPipe
  ]


})
export class SharedModule {
}
