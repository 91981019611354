import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CreateNewTest} from '../models/create-new-test';
import {ModelBoxService} from 'src/app/shared/services/model-box.service';
import Swal from 'sweetalert2';
import {AuthService} from 'src/app/shared/services/auth.service';
import {CreateNewTestService} from 'src/app/shared/services/create-new-test.service';
import {PaymentService} from 'src/app/shared/services/payment.service';
import {NavigationExtras, Router} from '@angular/router';
import {getTestStatusCode} from 'src/app/shared/services/api.constant';
import {StoreOrderedTestDataService} from 'src/app/shared/services/store-ordered-test-data.service';

@Component({
  selector: 'app-test-item',
  templateUrl: './test-item.component.html',
  styleUrls: ['./test-item.component.scss']
})
export class TestItemComponent implements OnInit {

  @Input() testListItemDetails: any;
  @Output() triggredOrderTest = new EventEmitter<any>();

  createTestList: CreateNewTest[] = [];
  isOrderTestScreenOpen: any = false;
  draftStatus = [];
  showEditOption: boolean = false;
  running: boolean;
  draft: boolean;
  archive: boolean;
  cancel: boolean;
  alltest: boolean;
  testId: any;
  testId1: any;
  finish;
  project;
  hideDraftbtn: boolean = true;
  testsShow = false;
  barColorFilled: any = '60%';
  projectTestShow: boolean = true;

  changeTestName: FormGroup;
  noTestAvlMessage: String;
  @ViewChild('closeAddExpenseModal', {static: false})
  closeAddExpenseModal: ElementRef;
  signleTestStatus: any;
  submitted: boolean = false;
  oldTestName: any;

  totalCredits: any;
  showTestEditId: any;

  constructor( private _fb: FormBuilder, 
              private _modelBoxService: ModelBoxService, 
              private _authService: AuthService, 
              private _createnewTestServices: CreateNewTestService, 
              private _payement: PaymentService, 
              private _router: Router,
              private _storeOrderedTestDataService: StoreOrderedTestDataService,
              ) {}

  ngOnInit() {
    this.initChangeTestNameForm();
    this.subscribesChangesInCredits();
  }

  initChangeTestNameForm() {
    this.changeTestName = this._fb.group({
      testname: ["", Validators.required],
    });
  }

  getBadgeLabel(statusCode) {
    let labelText = "";
    let className = "";
    let statusBtn = "";
    let editTest = "";
    let btnHide = "";
    switch (statusCode) {
      case 0:
        labelText = 'Running';
        className = 'draft blue';
        statusBtn = 'Get videos';
        editTest = "";
        btnHide = 'none';
        break;
      case 1:
        labelText = 'pending';
        className = 'draft';
        statusBtn = 'Get videos';
        editTest = 'Edit Test';
        btnHide = 'block';
        break;
      case 2:
        labelText = 'Draft';
        className = 'draft';
        statusBtn = 'Get videos';
        editTest = 'Edit Test';
        btnHide = 'none';
        break;
      case 6:
        labelText = 'CANCELLED';
        className = 'draft';
        statusBtn = 'Reactive Test';
        editTest = 'Edit Test';
        btnHide = 'block';
        break;
      case 3:
        labelText = 'FINISHED';
        className = 'draft lime';
        statusBtn = 'Get new video';
        editTest = "";
        btnHide = 'none';
        break;
      case 7:
        labelText = 'CONFIRMATION REQUIRED';
        className = 'draft red';
        statusBtn = 'Reniew video';
        editTest = 'Edit Test';
        btnHide = 'block';
        break;
      case 5:
        labelText = 'Archive';
        className = 'draft';
        statusBtn = 'Get videos';
        editTest = 'Edit Test';
        btnHide = 'block';
        break;
      default:
        break;
    }
    return {
      labelText,
      className,
      statusBtn,
      editTest,
      btnHide,
    };
  }

  closeVideoCountTab() {
    // this.testerCount.reset();
    // this.hideDraftbtn = true
    // this.testId2 = "";
    // this.showDetails = this.showDetails;
    // this.hideDraftbtn = true;
    // this.showDetails = !this.showDetails;
  }

  openOrderTesterScreen() {
    this.isOrderTestScreenOpen = true;
    this.hideDraftbtn = false;
  }

  orderTestTriggredData(eventData) {

    let e = eventData;
    switch (eventData.status) {
      case 'closeOpenModel':
        this.isOrderTestScreenOpen = false;
        this.hideDraftbtn = true;
        break;
      case 'orderTest':
        this.orderTesterVideo(e.orderVideoCount, e.enbleOrderButton, e.orderData);
        break;
      default:
        break;
    }
  }

  subscribesChangesInCredits() {
    this._authService.changesInCredits.subscribe((credits) => {
      this.totalCredits = credits;
    });
  }

  // @note : order videos final block
  orderTesterVideo(orderVideoCount, isValidOrder, orderValueJSON) {

  console.log("file: test-item.component.ts ~ line 179 ~ orderValueJSON", orderValueJSON)


    

    
    if (orderVideoCount > this.totalCredits) {
      this._storeOrderedTestDataService.storeTestData(orderValueJSON);
      // this.enbleOrderButton = false;
      this._router.navigate(['/creditPlan',],{ queryParams:{ totalCount: orderVideoCount }});
    }

    if (isValidOrder && orderVideoCount > 0) {
      let createOrderJsonData = {}
      createOrderJsonData["createtest_id"] = orderValueJSON["test_id"]
      createOrderJsonData["required_videos"] = {
                                                "desktop": orderValueJSON["desktop_tester_count"], 
                                                "mobile": orderValueJSON["mobile_tester_count"], 
                                                "tablet": orderValueJSON["tab_tester_count"], 
                                              }
      this._createnewTestServices
        .deductCredit(createOrderJsonData)
        .subscribe((res) => {
          if (res.status == 200) {
            // this.testId2 = "";
            // this.showDetails = this.showDetails;
            this.hideDraftbtn = true;
            let headerText = 'Order successful!';
            let bodyText = 'Your videos order are confirmed.';
            this._modelBoxService.showModelBox(true, bodyText, headerText);
            this._payement.getCustomer().subscribe((allOrder) => {
              // get total credits
              if (allOrder['data'].current_credit) {
                let totalCredits = allOrder['data'].current_credit;
                // update total credits
                this._authService.changesInCredits.next(totalCredits);
              } else {
                let totalCredits = 0;
                this._authService.changesInCredits.next(totalCredits);
              }
            });
            this.isOrderTestScreenOpen = false;
            this.triggredOrderTest.emit(true);
            // this.getAllTest();

          } else {

            Swal.fire({
              title: 'Order unsuccessful!',
              text: res.message ,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Add credit',
            }).then((result) => {
              if (result.value) {
                // this._router.navigate(['/creditPlan']);
                
              }
            });
          }
        });
    }
  }


  showOrderScreen(tesList, index) {
    if (this.showTestEditId == index) {
      this.showEditOption = !this.showEditOption;
    } else {
      this.showEditOption = true;
    }
    this.showTestEditId = index;
    this.testId1 = tesList;
  }

  onMyCustomEvent() {
    this.showEditOption = false;
  }

  onChangeTestStatus(testDetails: any, changeStatus, index?) {
    let statusCode = getTestStatusCode(testDetails.test_status)
    let testId = testDetails.id;
    let testStatus = testDetails.test_status;
    // this.showEditOption = false;
    // this.alltest = true;
    // this.archive = false;
    // this.cancel = false;
    // this.finish = false;
    // this.draft = false;
    // this.running = false;

    switch (changeStatus) {
      case 'archive':
        this.callForArchieve(testDetails, testId, testStatus)
        break;
      case 'delete':
        this.callForDeleteTest(testDetails, testId, testStatus, index)
        break;
      case 'restore':
        this.callForRestoreTest(testDetails, testId);
        break;
      case 'duplicate':
        this.signleTestStatus = testDetails;
        this.oldTestName = testDetails.test_name;
        break;
      default:
        break;
    }

  }

  callForArchieve(testDetails?, testId?, testStatus?) {
    this._createnewTestServices.archieveTest(testId).subscribe((resArchieve) => {
      if (resArchieve.status == 200) {
        this._modelBoxService.showSuccessToster(
          testDetails.test_name + ' Test is archived'
        );
        this.triggredOrderTest.emit(true);
      } else if (testStatus === 0 && testStatus == 3) {
        this._modelBoxService.showErrorToster('Test status is Running ! you can not Archive this Test');
      } else {
        this._modelBoxService.showErrorToster('Somthing went wrong');
      }
    });
  }

  callForDeleteTest(testDetails, testId, testStatus?, index?) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to delete ' + testDetails.test_name + ' !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1200ff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete test!',
    }).then((result) => {
      if (result.value) {
        this._createnewTestServices.deleteTest(testId).subscribe(
          (delRes) => {
            if (delRes.status == 200) {
              this.draftStatus.splice(index, 1);
              this._modelBoxService.showSuccessToster(
                testDetails.test_name + ' Test is deleted',
                'Deleted!'
              );
              this.triggredOrderTest.emit(true);
            } else if (testStatus === 0 || testStatus === 3) {
              this._modelBoxService.showErrorToster(
                ' you can not Delete this Test'
              );
            } else {
              this._modelBoxService.showErrorToster('Somthing went wrong');

            }
          }
        );
      }
    });
  }

  callForRestoreTest(testDetails?, testId?) {
    this._createnewTestServices.restoreTest(testId).subscribe((resRestore) => {

      if (resRestore.status == 200) {
        this._modelBoxService.showSuccessToster(
          testDetails.test_name + ' Test is restore'
        );
        this.triggredOrderTest.emit(true);
      } else {
        this._modelBoxService.showErrorToster('Somthing went wrong');
      }
    });
  }

  callForDuplicateTest() {
    this.submitted = true;
    if (this.changeTestName.invalid) {
      return;
    }
    this.submitted = false;
    let chnTestName = this.changeTestName.value.testname;

    let testList1 = this.signleTestStatus;
    testList1['project_flag'] = true;
    delete testList1.test_name;

    testList1['test_name'] = chnTestName;

    delete testList1.creation_date;
    delete testList1.test_status;
    delete testList1.user_id;
    delete testList1.id;
    delete testList1.rating;
    delete testList1.feed_back;

    document.getElementById('test-credit').click();

    this._createnewTestServices.createTest(testList1).subscribe((res) => {
      this.changeTestName.reset();
      if (res.status == 200) {
        this.triggredOrderTest.emit(true);
        let headerText = 'Test duplicated!';
        let bodyText = ' test name is duplicated!';
        this._modelBoxService.showModelBox(
          true,
          testList1.test_name,
          headerText,
          bodyText
        );
      } else {
        this._modelBoxService.showModelBox(false, res.message);
      }
    });
  }

  getNewVideos(getIVideod, testList) {
    this._createnewTestServices.getNewVideo(getIVideod).subscribe((res) => {
      if (res['status'] == 200) {
        this.triggredOrderTest.emit(true);
        this._modelBoxService.showSuccessToster(
          testList.test_name + ' Test is running'
        );
      } else {
        this._modelBoxService.showErrorToster('Somthing went wrong');
      }
    });
  }



}
