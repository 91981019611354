import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  whichView: string;

  constructor(private router: Router) {

    // if (this.router.url.endsWith('account/accountDeatils')) {
    //   this.whichView = 'accountDetails';
    // } else if (this.router.url.endsWith('account/billing')) {
    //   this.whichView = 'billing'
    // } else {
    //   this.whichView = 'accountDetails'
    // }
  }

  ngOnInit() {
  }

}
