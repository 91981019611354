import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TesterTestedListService } from 'src/app/shared/services/tester-tested-list.service';
import { timer } from 'rxjs';
import {ActivatedRoute, Params} from '@angular/router';
import { appApiResources } from 'src/app/shared/services/api.constant';
@Component({
  selector: 'app-transcript',
  templateUrl: './transcript.component.html',
  styleUrls: ['./transcript.component.scss']
})

export class TranscriptComponent implements OnInit {
             
  selectedTestDetails: any;
  videoJob: any;
  transcribedData: any = "Loading...";
  resultData: any;
  selectedText: string = '';
  resultText: any;
  jumpToVideoSecond: any;
  selectedVideoId: any;
  constructor(private _testedTestServices: TesterTestedListService,
              private route: ActivatedRoute,
            ) { }

  ngOnInit(){
    this.getSelectedVideoDetials();
  }

  
  getSelectedVideoDetials() {
    // get video id from url param
    this.route.params.subscribe((params: Params) => {
      this.selectedVideoId = params['id']
    });
    
    // get selected video detials
    this._testedTestServices.getTestedTestVideoList().subscribe(res => {
      let tempVideoDetials = res.data.video_list
      this.selectedTestDetails = tempVideoDetials.find(o => o.id == this.selectedVideoId);
      console.log('Transcript component', this.selectedTestDetails);
      this.getVideoJobDetails();
    })
  }

  getVideoJobDetails() {
    let videoUrl = {"video_url":this.selectedTestDetails.file};

    async function getVideoJobId(){
      let token = localStorage.getItem('token_id');
      let options ={
        headers:{
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        method: "POST",
        body: JSON.stringify(videoUrl)
      }
      const response = await fetch(appApiResources.getVideoJob, options);
      const result = await response.json();
      return result;
    }

    getVideoJobId().then((res)=>{
      let data = res['data'];
      this.videoJob = data;
      // call transcriptionDisplay() after 30 sec
      const source = timer(30000);
      const subscribe = source.subscribe((val) => this.transcriptionDisplay());
    })
  }

  async transcriptionDisplay(){
    let token = localStorage.getItem('token_id');
    let videoJobId = {"job_name": this.videoJob};
    let options ={
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      method: "POST",
      body:  JSON.stringify(videoJobId)
    }

    const response = await fetch(appApiResources.speechToText, options);
    const res = await response.json();
    let speechToTextdata = res["data"].results.transcripts[0].transcript;
    this. resultData = res["data"].results;
    this.transcribedData = speechToTextdata;
    console.log('transcribe data >>>>',this.transcribedData);
  }
  
  showSelectedText() {
    if (window.getSelection) {
      this.selectedText = window.getSelection().toString().split(" " || ".")[0];
      if (this.resultData != undefined) {
        this.resultText = this.resultData.items.forEach(element => {
          if (element.alternatives[0].content === this.selectedText) {
            this.jumpToVideoSecond = element.start_time;
          }
        });;
      }
    }
    this.getCurrentTime();
  }
  
  getCurrentTime() {
    this._testedTestServices.player.currentTime(this.jumpToVideoSecond);
    let jumpToVideoTime = this._testedTestServices.player.currentTime();
    return jumpToVideoTime;
  }
  
}




// ngOnChanges(changes: SimpleChanges): void {
//   if (changes.hasOwnProperty('selectedTestDetails') && this.selectedTestDetails) {
//     this.getVideoJobDetails();
//   }
// }


    // console.log('transcript comp 2',this.selectedTestDetails);
    // this._testedTestServices.getVideoJob(this.selectedTestDetails.file).subscribe(res => {
    //   let data = res['data'];
    //   this.videoJob = data;
    //   // call transcriptionDisplay() after 30 sec
    //   // const source = timer(30000);
    //   // const subscribe = source.subscribe((val) => this.transcriptionDisplay());
    // })



      // transcriptionDisplay(){
  //   this._testedTestServices.transcriptionTextDisplay(this.videoJob).subscribe(res => {
  //     let speechToTextdata = res["data"].results.transcripts[0].transcript;
  //     this. resultData = res["data"].results;
  //     this.transcribedData = speechToTextdata;
  //     console.log('transcribe data >>>>',this.transcribedData);
  //   })
  // }