import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {VideosService} from "../../services/videos.service";
import {ClippedVideo} from "../../services/interface";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ModelBoxService} from "../../../../shared/services/model-box.service";

@Component({
  selector: 'app-notesandclips-tab',
  templateUrl: './notesandclips-tab.component.html',
  styleUrls: ['./notesandclips-tab.component.scss']
})
export class NotesandclipsTabComponent implements OnInit, OnChanges {
  readonly EDIT = 'EDIT';
  readonly DELETE = 'DELETE';
  readonly DOWNLOAD = 'DOWNLOAD';

  @Input()
  selectedTestDetails: any;

  clippedVideos: any[];
  videoLength = 0;
  clipDetailsFG = new FormGroup({
    start: new FormControl('', Validators.required),
    end: new FormControl('', Validators.required)
  });
  formGroup = new FormGroup({
    video_url: new FormControl('', Validators.required),
    clip: this.clipDetailsFG,
    notes: new FormControl('')
  });

  constructor(
    private videosService: VideosService,
    private modelBoxService: ModelBoxService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('selectedTestDetails') && this.selectedTestDetails) {
      this.getClipVideos();
    }
  }

  validate(e: KeyboardEvent, ctrlName: string) {
    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 || (e.keyCode == 65 && e.ctrlKey) || (e.keyCode == 67 && e.ctrlKey) || (e.keyCode == 86 && e.ctrlKey) || (e.keyCode == 88 && e.ctrlKey) ||
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      return;
    }
    let value = (e.target as HTMLInputElement).value;
    value = (value ? value.toString() + e.key : '').replace("_", '');
    const minute = parseInt(value.split(':')[0]) || 0;
    const seconds = parseInt(value.split(':')[1]) || 0;
    let duration = (minute * 60) + seconds;
    if (duration > this.videoLength) {
      e.preventDefault();
      this.clipDetailsFG.get(ctrlName)?.setErrors({lengthExceed: true});
    } else {
      this.clipDetailsFG.get(ctrlName)?.setErrors(null);
    }
  }

  clickClippedNotes($event: ClippedVideo, action: string) {
    if (action === this.EDIT) {
      this.formGroup.patchValue($event);
      this.clipDetailsFG.get('start').setValue($event.start_duration);
      this.clipDetailsFG.get('end').setValue($event.end_duration);
      // const
    }
  }

  clipVideo() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.formGroup.updateValueAndValidity();
      return;
    }
    const data = this.formGroup.value;
    this.videosService.clipVideo(data).subscribe(response => {
      if (response) {
        this.getClipVideos();
        this.modelBoxService.showSuccessToster('Video Clipped Successfully.', 'Success!');
        this.formGroup.reset();
      }
    }, error => {
      this.modelBoxService.showErrorToster('Something went wrong.');
    });
  }

  private getClipVideos() {
    this.videoLength = this.selectedTestDetails.duration;
    this.formGroup.get('video_url').setValue(this.selectedTestDetails.file);
    this.videosService.getClippedVideos(this.selectedTestDetails.file).subscribe(response => {
      this.clippedVideos = response;
    });
  }
}
