    <h5>Notes & Clips</h5>
    <p *ngIf='getMarkerList.length <= 0' class='empty'>
        There are currently no notes and clips.
    </p>
    <app-notes-clip-box (emitValuetoVideoInfo)='getTriggredEvent($event)'
    [seletedDetailsObject]='selectVideoDeatils'></app-notes-clip-box>

    <div (myCustomEvent)='onMyCustomEvent()' *ngIf='!(getMarkerList?.length <= 0)' appShowHideEvent>
        <div *ngFor='let mark of getMarkerList; let i = index'>
            <div *ngIf='editMarkerId != mark.id' class='tab-pane-sub notes-box2'>
                <div class='edit-row'>
                    <div *ngIf='mark.clip == true' class='edit'>
                        <div class='icon'>
                            <img alt='edit' src='assets/images/icons/video-media.png'/>
                        </div>
                        <div (click)='jumpTimestamp(mark.start, true)' class='duration'>
                            {{ mark.start }} - {{ mark.end }}
                        </div>
                    </div>
                    <div *ngIf='mark.clip == false' class='edit'>
                        <div (click)='editMarker(mark.id)' class='icon'>
                            <img alt='edit' src='assets/images/icons/video-edit.png'/>
                        </div>
                        <div (click)='jumpTimestamp(mark.start, true)' class='duration'>
                            {{ mark.start }}
                        </div>
                    </div>

                    <div (click)='editShow(i)' class='right-dropdown'>
                        <div class='right-icon'>
                            <span class='dots'></span>
                            <span class='dots'></span>
                            <span class='dots'></span>
                        </div>

                        <div *ngIf='showMarkerEdit && i == showEditId' [style.display]='styleProp' class='dropdowns'>
                            <ul>
                                <li>
                                    <a (click)='editMarker(mark.id)'>
                                        <!-- <img alt='edit-icons' src='assets/images/icons/edit.png'/> -->
                                        Edit
                                    </a>
                                </li>
                                <li>
                                    <a (click)='deleteMarkerList(mark.id, mark)'>
                                        <!-- <img alt='delete-icons' src='assets/images/icons/delete.png'/> -->
                                        Delete
                                    </a>
                                </li>
                                <li *ngIf='mark.clip == true'>
                                    <a href="{{baseUrl+mark.test_video_clip_name}}" download>
                                        <!-- <img alt='delete-icons' src='assets/images/icons/'/> -->
                                        Download
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            <p class='text'>{{ mark.text }}</p>
            </div>
            <app-notes-clip-box (emitValuetoVideoInfo)='getTriggredEvent($event)' *ngIf='editMarkerId == mark.id'
                [seletedDetailsObject]='selectVideoDeatils'></app-notes-clip-box>
        </div>
    </div>