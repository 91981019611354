<div (click)='account=!account' class='mobile-head'>
    <span class='mobile-hamburger'>
      <i class='hamburger one'></i>
      <i class='hamburger two'></i>
      <i class='hamburger three'></i>
    </span>
  <h2><span>Account</span> <i class='fa fa-angle-down'></i></h2>
</div>
<ul (click)='account=!account' [style.display]="account?'block':'none'" class='side-menu'>
  <li (click)='button.isClicked = !button.isClicked' *ngFor='let button of buttons' [ngClass]="{'active': button.isClicked}" [routerLinkActiveOptions]='{exact:true}'
      [routerLinkActive]="['active']" class='menu-btn'
      id='detail-container'>
    <a routerLink='/{{ button.link }}' routerLinkActive='active'>{{button.text}}</a>
  </li>
</ul>
<!-- </aside> -->
