<div class="vid-clip-list-container">
    <div class="vid-clip-list-title">Notes and Clips</div>

    <div class="vid-clip-list-scroll">
        <div class="vid-clip-list" *ngFor="let item of notescliplist; let i = index">
            <div class="clip-notes-item" *ngIf="item.clip === true">
                <div class="flex">
                    <div class="flex-menu">
                        <img class="doc" src="../../../assets/images/icons/scissors-grey.svg" />
                        <div class="time">{{item.start_duration}} - {{item.end_duration}}     
                            {{item.clip_status == 'INPROGRESS' ? '(INPROGRESS) ': ''}}
                            {{item.clip_status == 'FAILED' ? '(FAILED)' : ''}}
                        </div>
                    </div>
                    <!-- clip -->
                    <div>
                        <button *ngIf="item.clip_status !== 'INPROGRESS'" (click)="ChangeMenuView(i)" class="optionsbtn">
                            <img class="doc" src="../../../../assets/images/icons/dot-menu-white-icon.svg" />
                        </button>
                        <div *ngIf="menuView === i" class="vid-clip-menu-options">
                            <button class="menubtn" (click)="EditClipsOrNotes(item)">Edit</button>
                            <button class="menubtn" (click)="DeleteClipOrNotes(item)">Delete</button>
                            <!-- <button class="menubtn">Download</button> -->
                            <a href="{{baseUrl + item.clip_path}}" class="menubtn" download>Download</a>
                        </div>
                    </div>
                </div>
                <p class="description">{{item.notes}}</p>
            </div>

            <div *ngIf="item.hashtag.length != 0 && item.clip == true" class="vid-hastags">
                <p class="img">#</p>
                <div class="hastag-list" *ngFor="let hastag of item.hashtag">
                    <div>{{hastag}}</div>
                </div>
            </div>
            <!-- notes -->
            <div id="{{'#'+item.text}}" class="clip-notes-item" *ngIf="item.clip === false">
                <div class="flex">
                    <div class="flex-menu">
                        <img class="doc" src="../../../assets/images/icons/file-text-white.svg" />
                        <div class="time">{{item.start}}</div>
                    </div>
                    <div>
                        <button (click)="ChangeMenuView(i)" class="optionsbtn">
                            <img class="doc" src="../../../../assets/images/icons/dot-menu-white-icon.svg" />
                        </button>
                        <div *ngIf="menuView === i" class="vid-clip-menu-options">
                            <button class="menubtn" (click)="EditClipsOrNotes(item)">Edit</button>
                            <button class="menubtn" (click)="DeleteClipOrNotes(item)">Delete</button>
                            <button (click)="copyToClipboard(item.notes); ChangeMenuView(i)" class="menubtn">Copy</button>
                        </div>
                    </div>
                </div>
                <p class="description">{{item.notes}}</p>
            </div>
            <div *ngIf="item.hashtag.length != 0  && item.clip == false" class="vid-hastags">
                <p class="img">#</p>
                <div class="hastag-list" *ngFor="let hastag of item.hashtag">
                    <div>{{hastag}}</div>
                </div>
            </div>
        </div>
        
    </div>


</div>