import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppUtils} from '../../shared/utils/app-utils';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  private _header = new HttpHeaders({'Content-Type': 'application/json', 'usertype': 'client'});

  constructor(public http: HttpClient) {
  }

  get<T>(url: string, queryParams?: { [x: string]: any }): Observable<T> {
    return this.getWithHeader(url, queryParams, this.header);
  }

  post<T>(url: string, body: any | string): Observable<T | any> {
    return this.postWithHeader(url, body, this.header);
  }

  delete(url: string, queryParams?: { [x: string]: any }): Observable<any> {
    return this.http.delete<any>(url, {params: this.getHttpParamFromObj(queryParams)});
  }

  put<T>(url: string, body: any | string, queryParams?: { [x: string]: any }): Observable<T | any> {
    return this.http.put<any>(url, body, {params: this.getHttpParamFromObj(queryParams)});
  }

  getWithHeader<T>(url: string, queryParams?: { [x: string]: any }, headers?: HttpHeaders): Observable<T> {
    return this.http.get<T>(url, {headers: headers, params: this.getHttpParamFromObj(queryParams)});
  }

  postWithHeader<T>(url: string, body: any | string, headers?: HttpHeaders): Observable<any> {
    return this.http.post(url, body, {headers: headers});
  }

  deleteWithBody(url: string, body: any): Observable<any> {
    return this.http.delete<any>(url, {body: body});
  }

  /** GET CURRENT USER LOGIN TOKEN */
  getToken(): string {
    const token = localStorage.getItem('token_id');
    return AppUtils.getString(token);
  }

  protected getHttpParamFromObj(queryParams: { [x: string]: any } | undefined): HttpParams {
    let qParams = new HttpParams();
    if (queryParams) {
      for (const key of Object.keys(queryParams)) {
        if (queryParams.hasOwnProperty(key) && queryParams[key]) {
          qParams = qParams.set(key, queryParams[key]);
        }
      }
    }
    return qParams;
  }

  /** THROW ERROR */
  protected handleError(error: HttpErrorResponse) {
    return AppUtils.handleError(error);
  }

  get header(): HttpHeaders {
    return this._header;
  }
}
