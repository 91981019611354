<!-- current UI -->

<div class="home-container">
  <div class="home-left-box">
    <article>
      <h2 class="home-title">Welcome, {{ userName | splitWords: [10] }}!</h2>

      <p class="home-text-1">Get started by creating your first user test.</p>
    </article>

    <article>
      <p class="home-text-2">Ready to test?</p>

      <p class="home-text-3">Start running tests to gather actionable user data.</p>
    </article>

    <button class="create-btn" routerLink='/research/tests/create-test' routerLinkActive='active'>
      <img src="assets/images/icons/plus.svg" alt="plus-icon"/>
      <span>Create test</span>
    </button>    

    <article>
      <p class="home-text-4">Or get an overview by watching a <a href="https://helpcenter.userpeek.com/kb/quick-demo/" target="_blank">quick demo</a>.</p>
    </article>
  </div>

  <div class="home-right-box">
    <article>
      <p class="home-text-5">Need inspiration?</p>

      <p class="home-text-6">View example templates.</p>
    </article>

    <button class="templates-btn" (click)="viewTemplate()">View templates</button>
  </div>
</div>











<!-- old ui -->
<div class='Userpeek-container lg-screen-width' style="display: none;">
  <div class='section'>
    <article class='head'>
      <h2>Welcome, {{ userName | splitWords: [10] }}!</h2>
      <p>
        Let's start securing your business decisions with real customer
        feedback.
      </p>
    </article>
    <article class='grayborder'>
      <h3>Start with creating a user test!</h3>
      <ul>
        <li>Recruit your target audience</li>
        <li>Get fast test results with written video transcripts</li>
        <li>
          Compare customer feedback and strengthen your business decisions
        </li>
      </ul>
      <button
        routerLink='/dashboard_tests/create_test'
        routerLinkActive='active'
      >
        Create new test
      </button>
    </article>

    <!-- dashboard new component code start -->

    <ng-template #isAvailableVideo>
      <article class='grayborder'>
        <h3>{{ isVideoAvilableText }}</h3>
        <button class='gray' routerLink='/videos'>{{ btnText }}</button>
      </article>
    </ng-template>
    <ng-container *ngIf='!testedvideoShow; else isAvailableVideo'>
      <article class='grayborder'>
        <h3>{{ isVideoAvilableText }}</h3>
        <button class='gray' routerLink='/videos'>{{ btnText }}</button>
      </article>
    </ng-container>
    <!-- dashboard new component code end -->
    <button class='feedback-btn'>Feedback</button>
  </div>
</div>
