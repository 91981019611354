import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { CreateNewTestService } from 'src/app/shared/services/create-new-test.service';
import { CustomPopupBoxService } from 'src/app/shared/services/custom-popup-box.service';
import {NavigationEnd, ActivatedRoute, Router, Params} from '@angular/router';
import { PaymentService } from 'src/app/shared/services/payment.service';
import {AuthService} from 'src/app/shared/services/auth.service';
import { UpStoreSelectedTestDetialsService } from 'src/app/shared/services/up-store-selected-test-detials.service';
import Swal from 'sweetalert2';
import { AllFiltersShareQueryService } from 'src/app/shared/services/all-filters-share-query.service';
import { ToastMsgService } from 'src/app/shared/services/toast-msg.service';
import { GeneralApiService } from 'src/app/core/api-call/general-api.service';

@Component({
  selector: 'app-test-dashboard-new',
  templateUrl: './test-dashboard-new.component.html',
  styleUrls: ['./test-dashboard-new.component.scss']
})
export class TestDashboardNewComponent implements OnInit, OnDestroy {

  testList = [];

  filteredJsonData = [];
  unfilteredJsonData = [];
  queriedJsonData = [];
  paginateCount: number = 0;
  buttonFilterData: any ={
    all: 0,
    running: 0,
    finished: 0,
    draft: 0,
    archive: 0,
  };
  isDataAvailable:boolean = true;
  selectedChipFilter: string = "all";


  // order videos
  showOrderVideoPopup: boolean = false;
  videoCount: number = 5;
  individualVideoPrice: number = 55;
  deviceType: string = "desktop";
  selectedTestDetials: any;
  enableOrderBtnvar: boolean = false;

  // custom popup
  showUPCustomPopup: boolean = false;
  customPopupDisplayContent: string = "";
  // rename
  renameValueExist:boolean = false;
  renameExistingValue: string = "";
  renameInputValue: string = "";
  renameSelectedTestDetials: any;

  // plan details
  userActivePlan: string = "";
  currentPlanDetails: any = {};
  currentUserCredit: number = 0;
  projectId: any;
  planDetailFlexible: any = {};
  planDetailGrowYearly: any = {};
  planDetailGrowMonthly: any = {};
  planDetailGrowYearlySubscription: any = {};
  planDetailGrowMonthlySubscription: any = {};
  additionalCreditPlanDetials: any = {};
  allFilterQueryDataObservable: any;

  
  openPopupId: any | null = null;  // Tracks the currently open popup's ID
  // plan details
  // plan details
  upFlexPlanDetails: any = {
    name: "flex",
    price:0,
    additionalCredits: 55
  }
  upGrowMonthlyPlanDetails: any = {
    name: "grow_monthly",
    price:265,
    additionalCredits: 49
  }
  upGrowYearlyPlanDetails: any = {
    name: "grow_yearly",
    price:211,
    additionalCredits: 39
  }
  upActivePlanDetails: any = {
    name: "",
    price:0,
    additionalCredits: 0    
  }  

  constructor(
    private _createnewTestServices: CreateNewTestService,
    public _customPopupBoxService: CustomPopupBoxService,
    private _activatedRoute: ActivatedRoute,
    private _paymentService: PaymentService,
    private _authService: AuthService, 
    private _router: Router,
    private _upStoreSelectedTestDetialsService: UpStoreSelectedTestDetialsService,
    private _allFiltersShareQueryService: AllFiltersShareQueryService,
    public toastMsgService: ToastMsgService,
    public _generalApiService: GeneralApiService,
  ) {
    this.allFilterQueryDataObservable = this._allFiltersShareQueryService.componentFilterQueryData.subscribe((res)=>{
      if(res.requestType != "none"){
        if(res.component == this._router.url.split('?')[0]){
          this.processComponentFilterData(res);
        }
      }
    });
  }

  togglePopup(id: any) {
    console.log("789",id);
    
       this.openPopupId = this.openPopupId == id ? null : id;
     }
  ngOnDestroy(): void {
    this.allFilterQueryDataObservable.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeComponentData();
    this.getUserPaymentDetails();
    setTimeout(()=>{
      this.orderTestFromCreateTestFlow()
    },1000);
  }


  initializeComponentData(){
    this._activatedRoute.queryParams.subscribe((params) => {
      if(params['project-id'] == undefined){
        this._router.navigate(['/project-selector']);
      } else {
        this.projectId = params['project-id'];
        this.loadProjectData(params['project-id']);
      }
    });
  }

  processComponentFilterData(value){
    if(value.requestType == "getDataFromParent"){
      this._allFiltersShareQueryService.sendDataParentToChild(this.unfilteredJsonData);
    }
    else if(value.requestType == "dataChildToParent"){
      let tempClipList = [] 
      this._allFiltersShareQueryService.childToParentDataVariable.subscribe((res)=>{
        tempClipList = res;
      });
      this.queriedJsonData = tempClipList;
      this.initializePaginationFun();
    }
    else if(value.requestType == "loadOriginalData"){
      this.initializeComponentData();
    }
  }


  loadProjectData(project_id){
    this._createnewTestServices.getTestRelatedToProjectId(project_id).subscribe((res: any)=>{

      this.isDataAvailableFun(res.data.length);

      this.unfilteredJsonData = [];
      this.queriedJsonData = [];
      res.data.forEach(item => {
        this.unfilteredJsonData.push(item);
        this.queriedJsonData.push(item);

        // // initialize chips filter data
        // if(item.project_status == 0){ // running
        //   this.buttonFilterData.running ++;
        // }
        // else if(item.project_status == 3){ // finished
        //   this.buttonFilterData.finished ++;
        // }
        // else if(item.project_status == 2){ // draft
        //   this.buttonFilterData.draft ++;
        // }
        // else if(item.project_status == 5){ // archive
        //   this.buttonFilterData.archive ++;
        // }
      });

      this.validatePaginateCount();

      // sort with id
      this.queriedJsonData.sort(function(a: any, b: any){
        return a.id - b.id;
      })
      // decending/reverse order
      this.queriedJsonData.sort().reverse();

      // initially show only 10 items
      this.filteredJsonData = [];
      for(let i = 0; i < this.paginateCount; i++){
        this.filteredJsonData.push(this.queriedJsonData[i])
      }

      // this.orderTestFromCreateTestFlow();
    });
  }


  isDataAvailableFun(value){
    // if json data is empty show empty message
    if(value > 0){
      this.isDataAvailable = true;
    } else {
      this.isDataAvailable = false;
    }
  }

  validatePaginateCount(){
    if(this.queriedJsonData.length < 10){
      this.paginateCount = this.queriedJsonData.length;
    } else {
      this.paginateCount = 10;
    }
  }


  incrementPaginationCountFun(value){
    let actualQueriedDataLength = this.queriedJsonData.length;
    if(value < actualQueriedDataLength){
      this.paginateCount = value;
    } else {
      this.paginateCount = actualQueriedDataLength;
    }
    this.restrictDataCount();
  }

  restrictDataCount(){
    this.filteredJsonData = [];
    for(let i = 0; i < this.paginateCount; i++){
      this.filteredJsonData.push(this.queriedJsonData[i])
    }
  }

  applyButtonFilterFun(value){
    // if filter applied is "active"
    if(value === "running"){
      this.selectedChipFilter = "running";
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        if(item.project_status == 0){
          this.queriedJsonData.push(item);
        }
      })
    } 
    else if(value === "all"){
      // if filter applied is "all-projects"
      this.selectedChipFilter = "all";
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
          this.queriedJsonData.push(item);
      })
    }
    else if(value === "archive"){
      // if filter applied is "archive"
      this.selectedChipFilter = "archive";
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        if(item.project_status == 5){
          this.queriedJsonData.push(item);
        }
      })
    }
    else if(value === "finished"){
      // if filter applied is "finished"
      this.selectedChipFilter = "finished";
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        if(item.project_status == 3){
          this.queriedJsonData.push(item);
        }
      })
    }
    else if(value === "draft"){
      // if filter applied is "draft"
      this.selectedChipFilter = "draft";
      this.queriedJsonData = [];
      this.unfilteredJsonData.forEach((item)=>{
        if(item.project_status == 2){
          this.queriedJsonData.push(item);
        }
      })
    }
    this.initializePaginationFun();
    this.isDataAvailableFun(this.queriedJsonData.length);
  }

  initializePaginationFun(){
    this.validatePaginateCount();
    this.filteredJsonData = [];
    for(let i = 0; i < this.paginateCount; i++){
      this.filteredJsonData.push(this.queriedJsonData[i])
    }
  }

  openOrderVideoPopup(selectedTestObj){
    this.showOrderVideoPopup = !this.showOrderVideoPopup;
    this.selectedTestDetials = selectedTestObj;
  }


  handleOrderVideos(){


    if(this.videoCount > this.currentUserCredit){
      // if videoCount is greater than available credits redirect to credits-plans page
      this.showOrderVideoPopup = false;
      this._router.navigate(['/credits-plans'],
      {
        queryParams: {
          'redirect-type': "order-videos",
          'plan-type': this.upActivePlanDetails.name,
          'video-count': this.videoCount,
          'project-id': this.projectId,
          'selected-test-id': this.selectedTestDetials["id"],
          'device-type': this.deviceType,
          'credits-required': this.videoCount - this.currentUserCredit,
        }
      }
      );
    } else {
      let requestJson = {
        "createtest_id": this.selectedTestDetials["id"], 
        "device_type": this.deviceType.toLowerCase(),
        "test_count": this.videoCount,
      };
      this._generalApiService.deductCreditsAPI(requestJson).subscribe((res)=>{
        if(res['status'] == 200){
          this.toastMsgService.showMsg("success", "Videos ordered", 3500);
          this.showOrderVideoPopup = !this.showOrderVideoPopup;
          this.getUserPaymentDetails()
        }
        else{
          this.toastMsgService.showMsg("failed", "Videos not ordered!", 3500);
          this.showOrderVideoPopup = !this.showOrderVideoPopup;
        }
        this.initializeComponentData();
      })
    }
  }


  enableOrderBtn(){
    if(this.videoCount > 0){
      this.enableOrderBtnvar = true;
      return true;
    } else {
      this.enableOrderBtnvar = false;
      return false;
    }
    // old logic for referance
    // if((this.videoCount > 0) && (this.deviceType != "")){
    //   this.enableOrderBtnvar = true;
    //   return true;
    // } else {
    //   this.enableOrderBtnvar = false;
    //   return false;
    // }
  }

  openVideosRelatedToTest(testDetials){
    localStorage.setItem("selectedTestId", testDetials.id);
    let selectedProjectId = Number(localStorage.getItem("selectedProjectId"));
    this._router.navigate(['/results/videos'], {queryParams: {'project-id': selectedProjectId, 'test-id': testDetials.id}});
  }

  showContextMenu(event){
    let tempElement = undefined;

    if(event.target.className == "dash-context-menu-wrapper"){
      tempElement = event.target.children[1];
    } else if(event.target.className == "dash-context-menu-icon"){
      tempElement = event.target.parentElement.children[1];
    }

    // let tempElement = event.target.parentElement.children[1];
    if(tempElement.style.display == "none"){
      // close previous opened popup menu
      let allElements = document.getElementsByClassName("dash-context-menu-cont");
      for (let i = 0; i < allElements.length; i++) {
          allElements.item(i).setAttribute("style", "display: none");
      }
      // open popup menu on clicked card
      tempElement.style.display = "block";
    }
    else{
      // close popup menu after clicking again
      tempElement.style.display = "none";
    }
  }

  openCustomPopup(displayContent){
    this.customPopupDisplayContent = displayContent;
    this.showUPCustomPopup = true;
  }


  handleRenameValueChanges(event){
    let x = this.unfilteredJsonData.find((e) =>{ return(e.test_name.toLowerCase() === event.toLowerCase())})
    if(x == undefined){
      this.renameValueExist = false;
    } else {
      this.renameValueExist = true;
    }
  }

  sendRenameRequest(){

    let jsonData = {
      "project_flag": true,
      "test_name": this.renameInputValue,
      "project_name": this.renameSelectedTestDetials.project_name
    }
    // this._createnewTestServices.renameProject(jsonData).subscribe((res) =>{
    //   this.showUPCustomPopup = false;
    //   this.initializeComponentData();
    // })

    this._createnewTestServices.renameTest(jsonData, this.renameSelectedTestDetials.id).subscribe((res: any)=>{
      this.showUPCustomPopup = false;
      this.initializeComponentData();
    });
  }


  getRenameTestDetials(testDetials){
    this.renameExistingValue = testDetials.test_name; 
    this.renameInputValue = testDetials.test_name;
    this.renameSelectedTestDetials = testDetials;
  }



  orderTestFromCreateTestFlow(){
    // Note: direct order test flow from create test page
    this._activatedRoute.queryParams.subscribe((params2) => {
      if(params2['order-test-id'] != undefined){
        this.unfilteredJsonData.forEach((testItem)=>{
          if(testItem.id == params2['order-test-id']){
            this.deviceType = testItem.device_type;
            this.openOrderVideoPopup(testItem);
          }
        });
      }
    });
  }

  viewTemplate(){
    this._router.navigate(['/research/tests/create-test'], {queryParams: {'show-templates': true}});
  }

  openCreateTestPage(){
    this._activatedRoute.queryParams.subscribe((params) => {
      if(params['project-id'] != undefined){
        this._router.navigate(['/research/tests/create-test'], {queryParams: {'pre-selected-project': params['project-id']}});
      }
    });
  }

  getUserPaymentDetails(){
    console.log('getUserPaymentDetails called ')
    this._generalApiService.stripeCustomer().subscribe((apiResponse)=>{
      console.log('getUserPaymentDetails', apiResponse)
      if(apiResponse["data"]){

        this.currentUserCredit = parseInt(apiResponse["data"]["current_credit"]);
        this._generalApiService.updateUserCreditScore(this.currentUserCredit);

        if(apiResponse['data']["credit_plan"] === "flexible"){
          this.upActivePlanDetails = this.upFlexPlanDetails;
          this.userActivePlan = "flexible";
        } 
        else if(apiResponse['data']["credit_plan"] === "Grow monthly"){
          this.upActivePlanDetails = this.upGrowMonthlyPlanDetails;
          this.userActivePlan = "grow-monthly-subscription";
        }
        else if(apiResponse['data']["credit_plan"] === "Grow yearly"){
          this.upActivePlanDetails = this.upGrowYearlyPlanDetails;
          this.userActivePlan = "grow-yearly-subscription";
        }
      }
    })
  }

  getOfferPrice(price, offer){
    const reductionFactor = 1 - (offer / 100);
    const offerPrice = price * reductionFactor;
    const perMonth = Math.round(offerPrice)
    return perMonth;
  }






}





  // getUserSubscriptionDetails(){
  //   this._paymentService.getPlanDetails().subscribe((response)=>{
  //     let res = response[0];
  //     this.userActivePlan = res['active-plan'];
  //     if(this.userActivePlan == "flex"){
  //       this.currentPlanDetails = res['plan-details']['flex'];
  //     }
  //     else if(this.userActivePlan == "grow"){
  //       this.currentPlanDetails = res['plan-details']['grow'];
  //     }
  //     else if(this.userActivePlan == "scale"){
  //       this.currentPlanDetails = res['plan-details']['scale'];
  //     }
  //   });
  // }

  // getFastSpringAccountDetails(){
  //   console.log('get fastspring account detials started');
  //   this._paymentService.getCustomer()
  //   .subscribe((res) => {
  //     // get user active fastspring subscription 
  //     if(Object.keys(res['data']).length === 0){ // customer data not available 1st time fastspring register
  //       this.userActivePlan = "flexible";
  //     } else{ // customer data available
  //       this.userActivePlan = res['data']["credit_plan"];
  //     }
  //     // this.userActivePlan = "grow-monthly";

  //     this.getPlanDetails();
  //     this.getUserCreditDetails();
  //   });
  // }

  // getPlanDetails(){
  //   console.log('get plan detials function started');
  //   this._paymentService.getNewPlanDetails().subscribe((response)=>{
  //     let res: any = response;
  //     // this.userActivePlan = res['active-plan'];
  //     let planListArray = res;

  //     planListArray.forEach((planItem: any)=>{
  //       // active plan
  //       if(planItem.plan_name == this.userActivePlan){
  //           this.currentPlanDetails = planItem;
  //       } 
  //       // store plan details in seperate variables
  //       if(planItem.plan_name == "flexible"){
  //         this.planDetailFlexible = planItem;
  //       }
  //       else if(planItem.plan_name == "grow-monthly-subscription"){
  //           this.planDetailGrowMonthlySubscription = planItem;
  //       }
  //       else if(planItem.plan_name == "grow-monthly"){
  //           this.planDetailGrowMonthly = planItem;
  //       }
  //       else if(planItem.plan_name == "grow-yearly-subscription"){
  //           this.planDetailGrowYearlySubscription = planItem;
  //       }
  //       else if(planItem.plan_name == "grow-yearly"){
  //           this.planDetailGrowYearly = planItem;
  //       }
  //     });
  //     // grow-yearly-subscription == grow-yearly | grow-monthly-subscription = grow-monthly
  //     this.getCurrentAdditionalCreditsPlanDetails();
  //   });

  // }


  // getCurrentAdditionalCreditsPlanDetails(){
  //   if(this.userActivePlan == "flexible"){
  //     this.additionalCreditPlanDetials = this.planDetailFlexible;
  //   }
  //   else if(this.userActivePlan == "grow-monthly-subscription"){
  //     this.additionalCreditPlanDetials = this.planDetailGrowMonthly;
  //   }
  //   else if(this.userActivePlan == "grow-yearly-subscription"){
  //     this.additionalCreditPlanDetials = this.planDetailGrowYearly;
  //   }
  //   console.log('    this.additionalCreditPlanDetials',     this.additionalCreditPlanDetials);
  // }

  // getUserCreditDetails(){
  //   this._paymentService.getCustomer().subscribe((creditDetials)=>{
  //     if(creditDetials['data'].length <= 0){
  //       this.currentUserCredit = 0;
  //     }
  //     else {
  //       this.currentUserCredit = creditDetials['data'].current_credit;
  //     }
  //   });
  // }