<section class="vid-dash-player-container" id="vid-dash-player-container">
    <div class="left-section" id="left-section" style="width: calc(100vw - 737px)">
        <app-userpeek-video-player></app-userpeek-video-player>
    </div>
    <div class="right-section" >
        <div class="right-section-content" id="right-section-content">
            <div class="right-section-title">
                <div class="transcript-title">
                    <img src="assets/images/icons/align-left-white.svg" alt="">
                    <p>Transcript</p>
                </div>
                <div class="findings-title">
                    <img class="finding-title-icon" src="assets/images/icons/file-text-white.svg" alt="">
                    <p>Findings</p>
                    <img (click)="closeSidebar()" class="finding-close-icon" src="assets/images/icons/close-white.svg" alt="icon">
                </div>
            </div>
            <div class="right-section-body">
                <div class="transcript-body">
                    <ng-container *ngFor="let item of paraLineCountArray; index as i">
                        <div class="task-card" (mouseup)="showContextMenu($event)">
                            <p class="task-title">
                                Task {{item["task"]}}
                            </p>
                            <div class="task-content" [id]="'task-content-'+i">
                                <ng-container *ngFor="let item1 of item.lines_in_array">
                                    <span style="margin-bottom: 0px;">{{item1+" "}}</span><br/>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <div class="findings-body">
                        <div class="findings-body-wrapper">
                            <ng-container *ngIf="videoFindingsArray.length > 0">
                                <ng-container *ngIf="findingsDataAvailable() === true">
                                    <ng-container *ngFor="let task_item of videoFindingsArray; index as i">
                                        <ng-container *ngFor="let line_item of task_item.lines; index as j">
                                            <article class="findings-card" [style.top]="getStyleinfo(task_item.task, line_item.line_no)">
                                                <!-- <p style="color: #fff;">
                                                    {{getStyleinfo(task_item.task, line_item.line_no)}}
                                                </p> -->
                                                <img src="assets/images/icons/dot-white.svg" class="findings-card-dot">
                                                <!-- clip icon -->
                                                <div class="findings-card-clip">
                                                    <ng-container *ngIf="line_item.clip">
                                                        <ng-container *ngIf="line_item.clip.length > 0">
                                                            <img (click)="playSelectedClip(line_item.clip[0])" src="assets/images/icons/purple-clip.svg" >
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                                <!-- emotion icon -->
                                                <div class="findings-card-emotion">
                                                    <ng-container *ngIf="line_item.sentiments">
                                                        <ng-container *ngIf="line_item.sentiments.length > 0">
                                                            <!-- positive -->
                                                            <ng-container *ngIf="line_item.sentiments[0].type === 'positive' ">
                                                                <img src="assets/images/icons/positive.svg">
                                                            </ng-container>
                                                            <!-- negative -->
                                                            <ng-container *ngIf="line_item.sentiments[0].type === 'negative' ">
                                                                <img src="assets/images/icons/negative.svg">
                                                            </ng-container>
                                                            <!-- neutral -->
                                                            <ng-container *ngIf="line_item.sentiments[0].type === 'neutral' ">
                                                                <img src="assets/images/icons/neutral.svg">
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                                <!-- note -->
                                                <div class="findings-card-note" (click)="handleViewNote('view-note-popup-'+i+j, $event)">
                                                    <ng-container *ngIf="line_item.notes">
                                                        <ng-container *ngIf="line_item.notes.length > 0">
                                                            <img src="assets/images/icons/notes.svg" >
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                                <!-- View notes popup -->
                                                <ng-container *ngIf="line_item.notes">
                                                    <ng-container *ngIf="line_item.notes.length > 0">
                                                        <section class="view-note-popup" [id]="'view-note-popup-'+i+j">
                                                            <div class="view-note-content">
                                                                <textarea [id]="'view-note-textarea-'+i+j" placeholder="Type note">
                                                                    {{line_item.notes[0].text}}
                                                                </textarea>
                                                                <div class="view-note-footer">
                                                                    <p [id]="'view-note-btn-'+i+j" (click)="handleEditNotes(i,j,task_item.task, line_item.line_no, line_item.notes[0].start, line_item.notes[0].id)">Edit</p>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </ng-container>
                                                </ng-container>
                                                <!-- hashtag -->
                                                <ng-container *ngIf="line_item.hashtags">
                                                    <ng-container *ngIf="line_item.hashtags.length > 0">
                                                        <div class="findings-card-hashtag">
                                                            <div class="findings-card-hashtag-content">
                                                                <ng-container *ngFor="let hashtag_item of giveLimitedHashtag(line_item.hashtags[0].hashtag_list)">
                                                                    <span (click)="handleEditHashtag('edit-hashtag-'+i+j, $event, line_item.hashtags[0].hashtag_list)" class="findings-card-hashtag-item">{{hashtag_item}}</span>
                                                                </ng-container>
                                                                <span class="findings-card-hashtag-item-count"
                                                                    *ngIf="remainingHashtagCount(line_item.hashtags[0].hashtag_list) > 0"
                                                                    (click)="handleEditHashtag('edit-hashtag-'+i+j, $event, line_item.hashtags[0].hashtag_list)">
                                                                    +{{remainingHashtagCount(line_item.hashtags[0].hashtag_list)}}
                                                                </span>
                                                            </div>
                                                            <!-- edit hashtag -->
                                                            <section 
                                                                class="edit-hashtag-container"
                                                                (keydown.enter)="handleKeyPress('',$event)"
                                                                [id]="'edit-hashtag-'+i+j"
                                                            >
                                                                <div class="edit-hashtag-content">
                                                                    <div class="edit-hashtag-header">
                                                                        <input placeholder="Type or search tag" type="text" 
                                                                            [(ngModel)]="hashtagInputVar"
                                                                            (ngModelChange)="handleHashtagSearch($event)"
                                                                            id="edit-hashtag-text-input"
                                                                        />
                                                                        <p (click)="createNewHashtag()">Create</p>
                                                                    </div>
                                                                    <div class="edit-hashtag-body">
                                                                        <ng-container *ngFor="let hashtagItem of hashtagArrayToDisplay">
                                                                            <div class="hashtag-items" (click)="storeSelectedHashtag(hashtagItem)">
                                                                                {{hashtagItem.tag}}
                                                                            </div>
                                                                            <br/>
                                                                        </ng-container>
                                                                    </div>
                                                                    <div class="edit-hashtag-selected-hashtags">
                                                                        <ng-container *ngIf="temporarilySelectedHashtag.length > 0">
                                                                            <ng-container *ngFor="let item of temporarilySelectedHashtag">
                                                                                <span class="edit-hashtag-span">
                                                                                    {{item.tag}}
                                                                                    <img 
                                                                                        class="edit-hashtag-span-btn"
                                                                                        src="assets/images/icons/close-icon.svg"
                                                                                        alt="icon"
                                                                                        (click)="removeSelectedHashtag(item)"
                                                                                    />
                                                                                </span>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </div>
                                                                    <div class="edit-hashtag-footer">
                                                                        <!-- <span>Press Enter or&nbsp;&nbsp;</span> -->
                                                                        <p (click)="updateHashtag(line_item.hashtags[0])">Update</p>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </article>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="findingsDataAvailable() === false">
                                    <div class="finding-empty-content">
                                        Add tags, notes, sentiments or create clips by highlighting sentences or words within the transcript 
                                        <a href="https://helpcenter.userpeek.com/" target="_blank">View tutorial</a>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-section-min-sidebar" id="right-section-min-sidebar">
            <img src="assets/images/icons/align-left-white.svg" alt="icon" (click)="openSidebar()">
        </div>
    </div>
</section>
<!-- text select context menu -->
<div class="transcript-context-menu" *ngIf="showTranscriptContextMenu" [style.left.px]="contextMenuX" [style.top.px]="contextMenuY">
    <div class="transcript-context-menu-content">
        <div class="transcript-context-icons">
            <!-- play video -->
            <article 
                (mouseenter)="showTooltip('tooltip-play')" 
                (mouseleave)="closeTooltip('tooltip-play')" 
                style="position: relative;"
                (click)="playVideo();"
                class="context-btn"
                id="context-play-btn"
            >
                <img src="assets/images/icons/clip.svg" alt="icon">
                <aside class="common-toolip" id="tooltip-play">Play . ctrl + p</aside>
            </article>
            <!-- create note -->
            <article
                (mouseenter)="showTooltip('tooltip-note')" 
                (mouseleave)="closeTooltip('tooltip-note')" 
                style="position: relative;"            
                (click)="highlightSelectContextBtn('note', $event)"
                class="context-btn"
                id="context-note-btn"
            >
                <img src="assets/images/icons/notes.svg" alt="icon"/>
                <aside class="common-toolip" id="tooltip-note">Create a note</aside>
            </article>
            <!-- create hashtag -->
            <article 
                (click)="highlightSelectContextBtn('hashtag', $event)"
                (mouseenter)="showTooltip('tooltip-hashtag')" 
                (mouseleave)="closeTooltip('tooltip-hashtag')" 
                style="position: relative;"
                class="context-btn"
                id="context-hashtag-btn"
            >
                <img src="assets/images/icons/hashtag.svg" alt="icon">
                <aside class="common-toolip" id="tooltip-hashtag">Create a tag</aside>
            </article>
            <!-- create clip -->
            <article 
                (click)="highlightSelectContextBtn('clip', $event); handleCreateClip('icon')"
                (mouseenter)="showTooltip('tooltip-clip')" 
                (mouseleave)="closeTooltip('tooltip-clip')" 
                style="position: relative;"
                class="context-btn"
                id="context-clip-btn"
            >
                <img src="assets/images/icons/scissors-dark-grey.svg" alt="icon">
                <aside class="common-toolip" id="tooltip-clip">Create a clip</aside>
            </article>
            <!-- create emotion -->
            <article 
                (click)="highlightSelectContextBtn('sentiment', $event)"
                (mouseenter)="showTooltip('tooltip-sentiment')" 
                (mouseleave)="closeTooltip('tooltip-sentiment')" 
                style="position: relative;"
                class="context-btn"
                id="context-sentiment-btn"
            >
                <img src="assets/images/icons/smile.svg" alt="icon">
                <aside class="common-toolip" id="tooltip-sentiment">Create a sentiment</aside>
            </article>
            <!-- more menu -->
            <article (click)="testFunction()">
                <img style="width: 20px; height: auto;" src="assets/images/icons/more-menu.svg" alt="icon">
            </article>
        </div>
        <!--========= create hashtag popup ==========-->
        <div 
            class="hashtag-dialog-box context-dialog"
            id="context-hashtag-dialog"
            (keydown.enter)="handleKeyPress('hashtag',$event)"
        >
            <div class="hashtag-dialog-box-header">
                <input placeholder="Type or search tag" type="text" [(ngModel)]="hashtagInputVar" (ngModelChange)="handleHashtagSearch($event)"/>
                <p (click)="createNewHashtag()">Create</p>
            </div>
            <div class="hashtag-dialog-box-body">
                <ng-container *ngFor="let hashtagItem of hashtagArrayToDisplay">
                    <div class="hashtag-items" (click)="storeSelectedHashtag(hashtagItem)">
                        {{hashtagItem.tag}}
                    </div>
                    <br/>
                </ng-container>
            </div>
            <div class="selected-hashtags">
                <ng-container *ngFor="let item of temporarilySelectedHashtag">
                    <span>
                        {{item.tag}}
                        <img (click)="removeSelectedHashtag(item)" src="assets/images/icons/close-icon.svg" alt="icon">
                    </span>
                </ng-container>
            </div>
            <div class="hashtag-footer">
                <span>Press Enter or&nbsp;&nbsp;</span>
                <p (click)="addHashtag()">Save</p>
            </div>
        </div>
        <!-- create notes -->
        <div 
            class="create-notes-popup context-dialog" 
            (keydown)="handleKeyPress('note',$event)"
            id="context-note-dialog"
        >
            <div class="create-notes-content">
                <textarea placeholder="Type note" class="create-notes-textbox" [(ngModel)]="notesInputVar">
                </textarea>
                <div class="create-notes-footer">
                    <span>Press Enter or&nbsp;&nbsp;</span>
                    <p (click)="handleNoteSave()">Save</p>
                </div>
            </div>
        </div>
        <!-- add emotion -->
        <div 
            class="add-emotion-popup context-dialog"
            id="context-sentiment-dialog"
        >
            <div class="add-emotion-popup-content">
                <img src="assets/images/icons/positive.svg" alt="icons"
                    (click)="handleAddEmotion('positive')"/>
                <img  src="assets/images/icons/neutral.svg" alt="icons"
                    style="margin-left: 10px; margin-right: 10px;" (click)="handleAddEmotion('neutral')"/>
                <img src="assets/images/icons/negative.svg" alt="icons"
                    (click)="handleAddEmotion('negative')"/>
            </div>
        </div>
        <!-- create clip -->
        <div
            class="create-clip-confirmation-popup context-dialog"
            id="context-clip-dialog"
        >
            <div class="create-clip-confirmation-content">
                <article class="create-clip-confirmation-header">
                    Are you sure you want to create a clip?
                </article>
                <article class="create-clip-confirmation-body">
                    <input type="checkbox" id="create-clip-confirmation-checkbox">
                    <label for="create-clip-confirmation-checkbox">
                        Don’t ask this again
                    </label>
                </article>
                <article class="create-clip-confirmation-footer">
                    <span>Press Enter or&nbsp;&nbsp;</span>
                    <p (click)="handleCreateClip('popup')">Confirm</p>
                </article>
            </div>
        </div>
    </div>
</div>
<!-- show additional hashtag item popup -->
<div *ngIf="showAdditionalHashtagPopup" class="addtional-hashtag-backdrop">
    <div class="additional-hashtag-popup">
        <img src="assets/images/icons/close-icon.svg" alt="icon" 
            (click)="showAdditionalHashtagPopup = false"    class="additional-hashtag-close-btn">
        <div class="additional-hashtag-content">
            <ng-container *ngFor="let item of additionalHashtagArray">
                <span>{{item}}</span>
            </ng-container>
        </div>
    </div>
</div>







<!-- <div class="vid-det-container">
    <div class="vid-det-player">
        <app-video-player></app-video-player>
    </div>

    <div class="vid-det-all">
        <div>
            <div class="vid-det-tabs">
                <div>
                    <button [ngClass]="{'border-tab': tab === 1, 'unborder-tab': tab !== 1}" (click)="ShowContent(1)" class="vid-det-btn">
                        <img class="info" src="assets/images/icons/info-white.svg" />
                    </button>
                    <button [ngClass]="{'border-tab': tab === 2, 'unborder-tab': tab !== 2}" (click)="ShowContent(2)" class="vid-det-btn">
                        <img class="align" src="assets/images/icons/align-left-white.svg" />
                    </button>
                    <button [ngClass]="{'border-tab': tab === 3, 'unborder-tab': tab !== 3}" (click)="ShowContent(3)" (click)="loadClipsAndNotes()" class="vid-det-btn">
                        <img class="doc" src="assets/images/icons/file-text-white.svg" />
                    </button>
                </div>
                <div>
                    <button class="close-sidebar">
                        <img class="chevron" src="assets/images/icons/chevron-right-white.svg" />
                    </button>
                </div>
            </div>

            <div [ngClass]="{'show-tab': tab === 1, 'hide-tab': tab !== 1}">
                <div class="vid-det-tab-contents1">
                    <p class="test-name">{{selectedVideoDetials.test_name}}</p>
                    <p class="start-url">{{selectedVideoDetials.start_url}}</p>

                    <div class="profile">
                        <div class="profile-div-one">
                            <img *ngIf="selectedVideoDetials.tester_image === '' " class="profile-img" src="assets/images/icons/profile-user.png" />
                            <img style="border-radius: 50%;"  *ngIf="selectedVideoDetials.tester_image != '' " class="profile-img" [src]="baseUrl+selectedVideoDetials.tester_image" />
                            <div style="display: flex; flex-direction: column;">
                                <p style="margin: 0px;" class="full-name">{{selectedVideoDetials.tester_name}}</p>
                                <div class="profile-div-two">
                                    <p>{{selectedVideoDetials.age}}</p>
                                    <img class="dot" src="assets/images/icons/dot-white.svg" />
                                    <p>{{selectedVideoDetials.gender}}</p>
                                    <img class="dot" src="assets/images/icons/dot-white.svg" />
                                    <p>{{selectedVideoDetials.country}}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="vid-det-rating">
                        <div class="rating-stars" *ngFor="let i of ratingList">
                            <img (click)="RateTheTest(i)" *ngIf="i > rating || rating == 0" class="star" src="assets/images/icons/star-grey.svg" />
                            <img (click)="RateTheTest(i)" class="star" *ngIf="i <= rating" src="assets/images/icons/star-yellow.svg" />
                        </div>
                        <p>Please rate this tester</p>
                    </div>

                    <div class="vid-det-test-info">
                        <div class="date">
                            <img class="calendar" src="assets/images/icons/calendar-grey.svg" />
                            <p><span>{{selectedVideoDetials.date_of_tested | date }}</span> @{{selectedVideoDetials.date_of_tested | date:'shortTime'}}</p>
                        </div>
                    </div>
                </div>

            </div>

            <div [ngClass]="{'show-tab': tab === 2, 'hide-tab': tab !== 2}" class="vid-det-transcript">
                <app-transcript></app-transcript>
            </div>

            <div [ngClass]="{'show-tab': tab === 3, 'hide-tab': tab !== 3}">
                <app-video-clip-list></app-video-clip-list>
            </div>

            <div [ngClass]="{'show-tab': tab !== 2, 'hide-tab': tab === 2}" class="vid-det-make-clip">
                <app-video-clip></app-video-clip>
            </div>
        </div>
    </div>
</div> -->