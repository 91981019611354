import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TesterTestedListService} from 'src/app/shared/services/tester-tested-list.service';
import {ActivatedRoute, Params} from '@angular/router';
import {VideosService} from "../services/videos.service";

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-example-video',
  templateUrl: './example-video.component.html',
  styleUrls: ['./example-video.component.scss'],
})
export class ExampleVideoComponent implements OnInit {
  @ViewChild('video', {static: false}) videoElement: ElementRef;

  video: any;
  id: number;
  arrow: any;
  videoJob: any;
  transcribedData: any;
  resultData: any;

  selectedTestDetails: any;

  constructor(
    private _testedTestServices: TesterTestedListService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
    });
    this.getexampleVideo()
  }

  getexampleVideo() {
    this._testedTestServices.getExampleVideo().subscribe(response => {
      let data = response as any[];
      data.filter(res => {
        if (res.id === this.id) {
          this.selectedTestDetails = res;
          this.getVideoJobDetails();
        }
      });
    });
  }

  getVideoJobDetails() {
    this._testedTestServices.getVideoJob(this.selectedTestDetails.file).subscribe(res => {
      this.videoJob = res['data'];
    })
  }

  transcriptionDisplay() {
    this._testedTestServices.transcriptionTextDisplay(this.videoJob).subscribe(res => {
      let speechToTextdata = res["data"].results.transcripts[0].transcript;
      this.resultData = res["data"].results;
      this.transcribedData = speechToTextdata;
    })
  }
}

