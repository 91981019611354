import {DOCUMENT} from '@angular/common';
import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ModelBoxService} from '../services/model-box.service';

@Component({
  selector: 'app-model-box',
  templateUrl: './model-box.component.html',
  styleUrls: ['./model-box.component.scss'],
})
export class ModelBoxComponent implements OnInit, OnDestroy {
  onOk: EventEmitter<any> = new EventEmitter();
  onCancel: EventEmitter<any> = new EventEmitter();
  public backdrop: HTMLElement;
  showAddModel: string = 'none';
  isModelBoxShow: boolean;
  @Input() modelBoxData = [];
  modelHeaderContent: string;
  modelBodyContent: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public _modelBoxService: ModelBoxService,
    private _router: Router
  ) {
  }

  ngOnInit() {

    this._modelBoxService.isModelShow.subscribe(res => {
      if (res) {
        this.modelBoxShow();

      }
    })

  }

  modelBoxShow() {
    this.document.body.classList.add('modal-open');
    this.showAddModel = 'block';

    this.modelBodyContent = this._modelBoxService.modelBoxBody;
    this.modelHeaderContent = this._modelBoxService.modelBoxHeader;
    this.showBackdrop();
  }

  showBackdrop() {
    this.backdrop = this.document.createElement('div');
    this.backdrop.classList.add('modal-backdrop');
    this.backdrop.classList.add('show');
    this.document.body.appendChild(this.backdrop);
  }

  hideBackdrop() {
    this.backdrop.remove();
  }

  closeSuccessModel(isOk) {
    if (isOk) {
      this.hideBackdrop();
      this.showAddModel = 'none';
      if (this._modelBoxService.componentStatus == 'testCreation') {
        this._router.navigate(['dashboard_tests']);
      }
    }
  }

  ngOnDestroy() {
    this._modelBoxService.isModelShow.unsubscribe();
  }
}
